import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Arrow from "assets/icons/Arrow-down-gray-png.png";
import { Counter } from "components/Counter/Counter";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formSlice } from "services/api/forms";
import { SolidLine } from "../Thems";
import {
  Amount,
  FirstDiv,
  FlexItem,
  FlexItemsContainer,
  ProductContent,
  SecondDiv,
  Title,
  Wrapper,
} from "./Thems";

const useStyles = makeStyles(() => ({
  formControl: {
    "& .MuiInputBase-root": {
      display: "flex",
      justifyContent: "left",
      width: "100%",
      border: 0,
      marginTop: "10px",
    },

    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px",
    },
  },
  select: {
    display: "flex",
    justifyContent: "left",
    width: "100% !importent",
    fontSize: "12px",

    "&:focus": {
      border: 0,
    },
    "&:hover": {
      border: 0,
    },
  },
  selectIcon: {
    position: "relative",
    color: "#002E5C",
    fontSize: "14px",
    opacity: 0.4,
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      color: "#002E5C",
      background: "##F4F6F9",
    },
    "& li.Mui-selected:hover": {
      background: "##F4F6F9",
    },
  },
}));

export const Products = React.memo(({
  productsData,
  index,
  incrementCounter,
  decrementCounter,
  formik,
  ticketUniqueId,
}) => {
  const { varients,selectedValue } = useSelector((state) => state?.Forms);
  const [newData,setNewData] = useState([])
  const dispatch = useDispatch();
  const  formDataReservation  = formik?.values?.registrationForm || [];

  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  const handleSelect = (
    e,
    ticketIndex,
    productIndex,
    dropdownIndex,
    dropdownObj,
    product
  ) => {
    // find if the selected object include the array of selected items or not
    const selectedValueIndex = selectedValue?.map((item) => {
      if (product.variant && dropdownIndex) {
        return item[product.title]?.find(
          (item) =>
            item.indexSelected === dropdownIndex &&
            item.ticketIndex === ticketIndex
        );
      
      } else {
        return item[product.title]?.find(
          (item) => item.ticketIndex === ticketIndex
        );
      }
    });
    // Crete array of objects for dropDown selected values ,if data exist update the object
    // Update
    if (selectedValueIndex?.length > 0 && selectedValueIndex[0] !== undefined) {
      const newArray = [...selectedValue];
      const newData = newArray[0][product.title]?.map((item, index) => {
        if (!product.variant && !dropdownIndex) {
          if (item.ticketIndex === selectedValueIndex[0].ticketIndex) {
            return {
              value: e,
              ticketIndex: item.ticketIndex,
              amount: product?.amount,
              productId:product?._id
            };
          } else {
            return { ...item };
          }
        } else {
          if (
            item.indexSelected === selectedValueIndex[0].indexSelected &&
            item.ticketIndex === selectedValueIndex[0].ticketIndex
          ) {
            return {
              value: e.target?.value,
              indexSelected: item.indexSelected,
              previousValue: item.value,
              ticketIndex: item.ticketIndex,
              amount: product?.amount,
              productId:product?._id
            };
          } else {
            return { ...item };
          }
        }
      });
      const data = [{ ...newArray[0], [product.title]: [...newData] }];
      dispatch(
        formSlice.actions.setSelectedValue({
          data: data,
          productIndex: productIndex,
          productTitle: product.title,
          dropdownIndex: dropdownIndex,
          type: "",
          ticketIndex: ticketIndex,
        })
      );
    }
    // Add new
    else {
      let newArray = selectedValue ? [...selectedValue] : [];
      if (newArray?.length === 0) {
        // if the array empty , in the first select we don't have selected value
        if (!product.variant && !dropdownIndex) {
          newArray.push({
            [product.title]: [
              { value: e, ticketIndex: ticketIndex, amount: product?.amount,productId:product?._id },
            ],
          });
        } else {
          newArray.push({
            [product.title]: [
              {
                value: e.target?.value,
                indexSelected: dropdownIndex,
                previousValue: "",
                ticketIndex: ticketIndex,
                amount: product?.amount
                ,productId:product?._id 
              },
            ],
          });
        }
        dispatch(
          formSlice.actions.setSelectedValue({
            data: newArray,
            productIndex: productIndex,
            productTitle: product.title,
            dropdownIndex: dropdownIndex,
            type: "",
            ticketIndex: ticketIndex,
          })
        );
      } else if (!Object.keys(newArray[0]).includes(product.title)) {
        //if the obj name doesn't exist , we don't have selected value becuse it's new product with new drop and there is no previousValue
        if (!product.variant && !dropdownIndex) {
          const data = [
            {
              ...newArray[0],
              [product.title]: [
                { value: e, ticketIndex: ticketIndex, amount: product?.amount,productId:product?._id  },
              ],
            },
          ];
          dispatch(
            formSlice.actions.setSelectedValue({
              data: data,
              productIndex: productIndex,
              productTitle: product.title,
              dropdownIndex: null,
              type: "",
              ticketIndex: ticketIndex,
            })
          );
        } else {
          const data = [
            {
              ...newArray[0],
              [product.title]: [
                {
                  value: e.target?.value,
                  indexSelected: dropdownIndex,
                  previousValue: "",
                  ticketIndex: ticketIndex,
                  amount: product?.amount
                  ,productId:product?._id 
                },
              ],
            },
          ];
          dispatch(
            formSlice.actions.setSelectedValue({
              data: data,
              productIndex: productIndex,
              productTitle: product.title,
              dropdownIndex: dropdownIndex,
              type: "",
              ticketIndex: ticketIndex,
            })
          );
        }
      } else {
        let data;
        // if the obj name are exist we need to add inside it
        if (!product.variant && !dropdownIndex) {
          data = [
            {
              ...newArray[0],
              [product.title]: [
                ...newArray[0][product.title],
                { value: e, ticketIndex: ticketIndex, amount: product?.amount ,productId:product?._id },
              ],
            },
          ];
        } else {
          data = [
            {
              ...newArray[0],
              [product.title]: [
                ...newArray[0][product.title],
                {
                  value: e.target?.value,
                  indexSelected: dropdownIndex,
                  previousValue: "",
                  ticketIndex: ticketIndex,
                  amount: product?.amount
                  ,productId:product?._id 
                },
              ],
            },
          ];
        }
        dispatch(
          formSlice.actions.setSelectedValue({
            data: data,
            productIndex: productIndex,
            productTitle: product.title,
            dropdownIndex: dropdownIndex,
            type: "",
            ticketIndex: ticketIndex,
          })
        );
      }
    }

    if (product.variant)
      formik.setFieldValue(
        `ticketsData.[${index}].[${productIndex}].dropdowns[${dropdownIndex}]`,
        { ...dropdownObj, selectedValue: e.target?.value }
      );
  };

  useEffect(() => {
    const newArr = []
    // Handle product data inside the local storage
  if(selectedValue !== undefined && selectedValue?.length !==0){
    const Data =
      selectedValue &&
      Object.keys(selectedValue[0]).map((key) => {
        const formatData = selectedValue[0][key]?.map((item) => {
          if(item.ticketIndex ===  ticketUniqueId){
          if (item.hasOwnProperty("indexSelected")) {
            const isExistObj = newArr.find((item)=>item?.title === key)
            const findProductIndex = newArr.findIndex(product => product.title === key);
            if(isExistObj && isExistObj !== undefined) {
              if(newArr[findProductIndex]?.variant?.hasOwnProperty(item.value)){
                newArr[findProductIndex].variant[item.value] = newArr[findProductIndex].variant[item.value] + 1 
              }else{
                newArr[findProductIndex].variant[item.value] = 1
              }
            }else{
              newArr.push({
                id:item?.productId,
                title:key,
                variant: {
                  [item.value]: 1,
                },
              })
            }
          } else {
            newArr.push({
              id:item?.productId,
              title: key,
              quantity: item.value,
            })
            
          }
          setNewData(newArr)
        }
        });
      });
    
     
    }
  }, [selectedValue]);

  useEffect(()=>{

    const currentTicketIndex = formDataReservation?.findIndex((item) => item.id === ticketUniqueId);
    const formDataReservationCopy = [...formDataReservation];
  
    if (currentTicketIndex !== -1) {
      formDataReservationCopy[currentTicketIndex] = {
        ...formDataReservationCopy[currentTicketIndex],
        products: newData,
      } 
      console.log('formDataReservationCopy', formDataReservationCopy,newData)
      formik.setFieldValue(`registrationForm`, formDataReservationCopy);  
    }
  
  },[newData])

  return (
    <Wrapper>
      {productsData?.map((product, productIndex) => {
        return (
          <>
            <ProductContent>
              <FirstDiv>
                <FlexItemsContainer>
                  <FlexItem>
                    <Title>{product?.title}</Title>
                  </FlexItem>
                  <FlexItem>
                    <Counter
                      key={product._id}
                      product={product}
                      counter={product?.counter}
                      handleClickIncrement={() => {
                        incrementCounter(
                          product._id,
                          product,
                          product?.counter,
                          productIndex
                        );
                        !product.variant &&
                          handleSelect(
                            product?.counter + 1,
                            ticketUniqueId,
                            productIndex,
                            null,
                            null,
                            product,
                            product?.title
                          );
                      }}
                      handleClickDecrement={() => {
                        product?.counter > 0 &&
                          decrementCounter(
                            product._id,
                            product,
                            product?.counter,
                            productIndex
                          );
                          !product.variant &&
                          handleSelect(
                            product?.counter - 1,
                            ticketUniqueId,
                            productIndex,
                            null,
                            null,
                            product,
                            product?.title
                          );
                      }}
                      disabled={
                        varients?.[productIndex]?.varientCapacity !=="noLimit" &&
                        (varients?.[productIndex]?.varientCapacity ===
                          varients?.[productIndex]?.numberOfDrops ||
                          varients?.[productIndex]?.varientCapacity ===
                            varients?.[productIndex]?.counterValue )
                      }
                    />
                  </FlexItem>
                  <FlexItem>
                    <Amount>
                      {product?.tax_type}
                      {product?.amount}
                    </Amount>
                  </FlexItem>
                </FlexItemsContainer>
              </FirstDiv>

              <SecondDiv>
                {product?.counter > 0 && product?.variant && (
                  <FormControl fullWidth className={classes.formControl}>
                    {product?.dropdowns?.map((dropdown, dropdownIndex) => (
                      <Select
                        displayEmpty
                        onChange={(e) => {
                          handleSelect(
                            e,
                            ticketUniqueId,
                            productIndex,
                            dropdownIndex,
                            dropdown,
                            product,
                            product?.title
                          );
                        }}
                        value={dropdown?.selectedValue}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        IconComponent={() => (
                          <img
                            src={Arrow} // Path to your PNG icon in the public directory
                            alt="Custom Arrow"
                            style={{
                              width: "12px",
                              height: "6.4px",
                              marginRight: 20,
                              position: "absolute",
                              right: 0,
                            }} // Adjust the size as needed
                          />
                        )}
                        sx={{ backgroundColor: "#F4F6F9" }}
                        MenuProps={menuProps}
                        classes={{
                          select: classes.select,
                          icon: classes.selectIcon,
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>Select</em>
                        </MenuItem>
                        {varients?.[productIndex]?.variantsInfo?.map(
                          (option, index) => (
                            <MenuItem
                              key={index}
                              value={option?.option}
                              disabled={
                                option?.quantity === option?.counterValue
                              }
                            >
                              {option?.option}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    ))}
                  </FormControl>
                )}
              </SecondDiv>
            </ProductContent>
            <SolidLine />
          </>
        );
      })}
    </Wrapper>
  );
})
