import styled from "styled-components";


export const Wrapper = styled.div`
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (width: 1920px) and (height: 1081px) {
    margin-left:200px ;
    margin-right:200px ;
  }
    margin-left:30px;
    margin-top: 30px;


`

export const FormWrapper = styled.div`
    margin-top: 49px;
    padding-right:70px;
    @media only screen and (min-width: 1920px) and (min-height: 1081px) {
    width: 100%;
    margin-left: 100px;
  }
`
export const FormHeader = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
export const Title = styled.div`
width: 100%;
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
color: #002E5C;
text-align: center;
margin-bottom: 15px;
`
export const Description = styled.div`
width: 454px;
height: 27px;
text-align: center;
color: #002E5C;
`
export const Label = styled.label`
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #002E5C;
padding-left: 20px;

`
export const LabelCheckBox = styled.label`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #404253;
`
