import React from "react";

export const PriceType = ({
  value,
  handleClick,
  setFieldValue,
  index,
  type,
  name,
}) => {
  return (
    <div className="price-type-contant">
      <span
        className={value === "%" ? "selected-price-type" : ""}
        onClick={() => {
          {
            setFieldValue(type, "%");
          }

          handleClick("%");
        }}
      >
        %
      </span>
      <span
        className={value === "$" ? "selected-price-type" : ""}
        onClick={() => {
          {
            setFieldValue(type, "$");
          }
          handleClick("$");
        }}
      >
        $
      </span>
    </div>
  );
};
