import { TableCell } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import { convertUTCToLocalDate } from 'components/UTC/UtcConverter';
import { formatDate } from "helper/dateFormat";
import { numberFormat } from "helper/numberFormat";
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from "react-router";


export const BodyForList = ({ classes }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const getClasseById = async (Item) => {
        // The user should not be able to register in a not started class
        const startDateDiff = moment().diff(moment(Item?.class_start_date), 'days');
        const endDateDiff = moment().diff(Item?.class_end_date ? moment(Item?.class_end_date) : moment(), 'days');
        if (startDateDiff < 0) {
            enqueueSnackbar(`This class has not yet begun. Please check back closer to the start date for access`, {
                variant: 'error',
            });
        }
        else if (endDateDiff > 0) {
            enqueueSnackbar('This class is no longer available due to its expiration date', {
                variant: 'error',
            });
        }
        else {
            history.push(`/school/${Item?._id}`);

        }
    };

    const checkClassIsFull = (row) => {
        const totalParticipants = row?.participantsDetails?.total;
        const maximumParticipants = row?.class_details?.maximum_participants
        return !row?.infiniteParticipants ? totalParticipants >= maximumParticipants : false
      }
    const displayRegistrationBtnText = (row) => {
        let text = " Sign up";
        const isClassFull = checkClassIsFull(row);
        
        if (isClassFull && row?.allow_waiting_List && row?.isFull) {
            text = "Join Wait List";
        } else if (isClassFull) {
            text = "Class is Full";
        } else {
            text = "Sign up";
        }
        return text;
    }


    return (
        <>
            {
                classes.map((Item) => (
                    <TableRow onClick={() => {
                        if(checkClassIsFull(Item)) return;
                        getClasseById(Item);
                    }} style={{ cursor: 'pointer' }} hover={true}>
                        <TableCell className="charged-value" align="left" >
                            {Item?.class_name}
                        </TableCell>
                        <TableCell className="charged-value" align="left" >
                            {Item?.year}
                        </TableCell>
                        <TableCell className="charged-value" align="left" >
                            {`${formatDate({date:Item?.class_start_date, formatType: "defaultWithUTC"})}
                            - ${Item?.always_available ? 'Always Available' : formatDate({date:Item?.class_end_date, formatType: "defaultWithUTC"})}`}
                        </TableCell>
                        <TableCell className="charged-value" align="left" >
                            {`${Item?.class_details?.ageStart}${Item.class_details?.ageEnd ? "-" + Item.class_details?.ageEnd : ''}`}
                        </TableCell>
                        <TableCell className="charged-value" align="left" >
                            {
                                `${numberFormat({number:+Item?.class_pricing?.total_cost, decimal:2, style: "currency"})}`
                            }

                        </TableCell>
                        <TableCell className="charged-value" align="left" >
                            <button className='btn btn-primary class-list-registration-btn' 
                              disabled={checkClassIsFull(Item)}
                              onClick={(e) => {
                                e.stopPropagation();
                                getClasseById(Item);
                              }}
                              type='button'
                            >
                              {displayRegistrationBtnText(Item)}
                            </button>
                        </TableCell>

                    </TableRow>
                ))
            }
        </>
    );
};
