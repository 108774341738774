import * as React from "react";

function RightArrowSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Icon"
      width="17.095"
      height="17.095"
      viewBox="0 0 17.095 17.095"
    >
      <rect
        id="Area"
        width="17.095"
        height="17.095"
        fill="#fcfcfc"
        opacity="0"
      />
      <g id="Icon-2" data-name="Icon" transform="translate(3.561 3.562)">
        <line
          id="Line"
          x2="9.972"
          transform="translate(0 4.986)"
          fill="none"
          stroke="#b5b5c3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.667"
        />
        <path
          id="Path"
          d="M4.167,14.138,9.153,9.153,4.167,4.167"
          transform="translate(0.819 -4.167)"
          fill="none"
          stroke="#b5b5c3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.667"
        />
      </g>
    </svg>
  );
}

export default RightArrowSvg;
