import { useCallback } from "react";
import { debounce } from "lodash";

export const useSearch = ({ action }) => {
  const searchAction = useCallback(
    debounce((...rest) => action.bind(this, ...rest)(), 1000),
    []
  );

  return { searchAction };
};
