import React, { useState } from "react";
import MyButton from "components/MyButton";
import AddMourners from "../NewYahrzeit/AddMourners";
import MournersList from "../NewYahrzeit/MournersList";
const DrawerMournerList = ({
  handleDelete,
  mourners,
  onSubmit,
  isEdit,
  ...props
}) => {
  const [disable, setDisable] = useState(true);

  return (
    <div className="">
      <AddMourners
        drawerDisplay={true}
        {...props}
        setDisable={setDisable}
        isEdit={isEdit}
      />
      <hr />
      <MournersList
        drawerDisplay={true}
        {...props}
        handleDelete={handleDelete}
        handleClick={() => {}}
        mourners={mourners}
        isEdit={isEdit}
        setDisable={setDisable}
      />
      <div className="mt-4 btn-flex d-flex justify-content-center">
        <MyButton
          type={"primary"}
          content={"Save"}
          onClick={onSubmit}
          disabled={disable}
        />
      </div>
    </div>
  );
};

export default DrawerMournerList;
