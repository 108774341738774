import { Button, ListItem, ListItemText } from '@material-ui/core';
import { ReactComponent as ArrowdownSVG } from 'assets/icons/arrow-down.svg';
import { HintField, LabelField } from 'components';
import { useSelect } from 'downshift';
import React, { memo, useEffect } from 'react';
import './SelectedFields.scss';

export const SelectField = memo(
  ({
    disabled,
    title,
    name,
    style,
    type,
    label,
    error,
    formik,
    errors,
    items,
    onClick,
    selectfieldColor,
    className,
    placeholder,
    mandatory,
    checkValidation = false,
    value,
    selectedItemColor,
    batchBillingFilterInputs,
    deceasedValue,
    loading,
    ...rest
  }) => {
    const itemToString = (item) => item || '';
    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getItemProps,
    } = useSelect({ items: items, itemToString });

    useEffect(() => {
      if (selectedItem) onClick(selectedItem);
    }, [selectedItem]);

    const getInputClasses = () => {
      if (!checkValidation) {
        return '';
      }

      if (formik?.touched[name] && formik?.errors[name]) {
        return 'is-invalid';
      }

      if (formik?.touched[name] && !formik?.errors[name]) {
        return 'is-valid';
      }

      return '';
    };

    return (
      <div
        className={
          errors
            ? 'form-group fv-plugins-icon-container mb-0'
            : 'form-group fv-plugins-icon-container'
        }
      >
        <LabelField
          htmlFor={name}
          title={title}
          labelClassName={className}
          mandatory={mandatory}
          {...getLabelProps()}
        />
        <Button
          disabled={disabled}
          style={
            batchBillingFilterInputs
              ? { ...style, height: '48px', background: 'rgb(243, 246, 249)' }
              : { ...style, height: '48px' }
          }
          className={`form-control form-control-solid  py-5 px-6 ${getInputClasses()}`}
          {...getToggleButtonProps()}
        >
          <div
            className="flex-grow-1 dropdown-text"
            style={{
              color: selectedItemColor
                ? selectedItemColor
                : selectedItem
                ? selectedItemColor
                : '#B5B5C3',
              fontSize: 14,
              textTransform: 'capitalize',
              textAlign: 'left',
              fontFamily: 'Poppins',
              letterSpacing: 0.1,
            }}
          >
            {/* {selectedItem ? value : placeholder
              ? placeholder : items[0]} */}
            {value && selectfieldColor == true ? (
              <span style={{ color: 'black' }}> {value}</span>
            ) : value &&
              (selectfieldColor === undefined || selectfieldColor == false) ? (
              value
            ) : selectedItem == undefined || selectedItem === '' ? (
              deceasedValue
            ) : (
              <span style={{ color: '#b5b5c3' }}> Select</span>
            )}
          </div>
          {loading ? (
            <div className="spinner-border spinner-border-sm" role="status" />
          ) : (
            <ArrowdownSVG
              style={{ height: 15, width: 15, marginRight: 10 }}
              fill={selectedItemColor || '#B5B5C3'}
            />
          )}
        </Button>
        <div className="dropdown">
          <div {...getMenuProps()} className="dropdown-content">
            {isOpen &&
              items.map((item, index) => {
                return (
                  <ListItem
                    key={`${item}-${index}`}
                    {...getItemProps({
                      item,
                      index,
                    })}
                  >
                    <ListItemText
                      primary={item}
                      className={'dropdown-content-text cursor-pointer'}
                    />
                  </ListItem>
                );
              })}
          </div>
        </div>
        {formik?.touched[name] && formik?.errors[name] ? (
          <HintField error={formik?.errors[name]} />
        ) : null}
      </div>
    );
  }
);
