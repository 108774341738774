import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Spinner = ({ ...props }) => {
  const style = {
    color: "#2555EF",
    width: "80px",
    height: "80px",
    position: "absolute",
    right: "50%",
    marginTop: "200px",
  };
  return <CircularProgress style={props.styles ? props.styles : style} />;
};

export default Spinner;
