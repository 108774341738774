import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { forwardRef } from 'react';
import StripeCardBox from './StripeCardBox';
import "./stripeCard.scss";
const StripeCard = forwardRef(({ onSaveCard, loading, setLoading, saveCard = false }, ref) => {
  let organizationData =
    localStorage.getItem("organization") !== undefined
      ? JSON.parse(localStorage.getItem("organization"))
      : [];
  const { publicKey } = organizationData?.provider_information?.keys || {};
  const stripePromise = loadStripe(publicKey || "");

  return (
    <Elements stripe={stripePromise}>
      <StripeCardBox
        onSaveCard={onSaveCard}
        loading={loading}
        setLoading={setLoading}
        saveCard={saveCard}
        ref={ref}
      />
    </Elements>
  )
})

export default StripeCard