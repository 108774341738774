import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    classDetails: {
        // class_details: {
        //     maximum_participants: 0,
        //     ageEnd: 0,
        //     ageStart: 0,
        //     gender: ""

        // },
        // class_pricing:
        // {
        //     total_cost: 0,
        //     total_to_pay: 0,
        //     deposit: {
        //         amount: 0,
        //         type: "",
        //         ledger: {
        //             id: "",
        //             name: ""
        //         },
        //         subLedger: {
        //             id: "",
        //             name: ""
        //         }
        //     }
        // },
        // class_discount:
        //     []
    },
    resgistrationForm: [],
    numberOfTickets:[]
}

export const Class = createSlice({
    name: "class",
    initialState,
    reducers: {

        resetAction: () => {
            return initialState;
        },
        setClassDetails: (state, action) => {
            state.classDetails = action.payload;
            return state;
        },
        setRegistartionDetails: (state, action) => {
            state.resgistrationForm = action.payload;
            return state;
        },
        setCounterTicket: (state, action) => {
            state.numberOfTickets = action.payload;
            return state;
        },
    },
});

export const { setClassDetails,setCounterTicket } = Class.actions;

export default Class.reducer;
