import styled from "styled-components"

export const Wrapper = styled.div`
.form-group label {
    color: #002E5C;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.form-group {
  padding-left: 20px;
}
.form-control {
  border-radius: 6px;
  background: #f4f6f9;
  width: 469.002px;
  height: 48px;
  @media only screen and (min-width: 280px) and (max-width: 767px) {
    width: 100%
  }
}
.image-upload {
  overflow: hidden;
}
.badge-danger {
  margin-left: 10px;
  color: #002E5C;
  background-color: transparent;
  padding-left: 0;
}
.image-upload-control {
  margin-top: 10px;
  overflow: hidden;
  display: none;
}
.btn-file-upload-clear{
    background-color: #F4F6F9;
}


.btn-toolbar-disable{
  float: right;
  height: 48px;
  width:150px;
  border-radius: 6px;
  border: 0;
  background: #F0F0F3;  
  display: flex;
  justify-content: center;
  align-items: center;
  color: #B5B5C3;
  input{
    color: var(--White, var(--White-100, #FFF));
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; 
  }

}
.btn-toolbar-disable:hover{
  color: #B5B5C3;
   background: #F0F0F3;  
}


.custom-radio{
  padding-left: 0;
}
`
