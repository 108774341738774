import * as React from "react"

function CheckSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17.184}
            height={12.26}
            viewBox="0 0 17.184 12.26"
            {...props}
        >
            <path
                d="M2.121 6.458l4.3 4.3 8.639-8.639"
                fill="none"
                stroke={props.color || "#2555ef"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
            />
        </svg>
    )
}

export default CheckSvg
