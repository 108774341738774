import { axios, clientAction } from "./axios";
import { createAsyncThunk, nanoid } from "@reduxjs/toolkit";
let prevRequestToken = undefined;

export const generalApiTemplate = ({
  storeName,
  _url,
  exact,
  ...axiosConfig
}) => {
  return createAsyncThunk(
    _url + exact,
    async (params, { getState, requestId, rejectWithValue }) => {
      const { currentRequestId, loading } = getState()[storeName];

      let url = _url;
      if (params?.urlParams) {
        url += params?.urlParams;
        delete params?.urlParams;
      }

      if (loading === "pending" && requestId !== currentRequestId) {
        prevRequestToken.cancel({
          cancelToken: true,
        });
      }

      prevRequestToken = clientAction?.CancelToken?.source();

      try {
        const result = await axios({
          params,
          url,
          ...axiosConfig,
          cancelToken: prevRequestToken.token,
        });

        return result;
      } catch (err) {
        const { response, message } = err;

        return rejectWithValue({
          status: response?.status,
          message: response?.data,
          cancelToken: message?.cancelToken,
          id: nanoid(),
        });
      }
    }
  );
};

export const generalApiResponseTemplate = (Request) => {
  return {
    [Request.pending.type]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [Request.fulfilled.type]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.entities = {
          ...action.payload.data,
          status: action.payload.status,
        };
        state.status = action.payload.status;
        state.error = undefined;
        state.currentRequestId = undefined;
      }
    },
    [Request.rejected.type]: (state, action) => {
      const { cancelToken } = action.payload;

      if (state.loading === "pending" && !cancelToken) {
        state.loading = "idle";
        state.error =
          action?.payload?.message?.errors ||
          action?.payload?.message ||
          action?.error?.message;
        state.entities = undefined;
        state.status = action.payload.status;
        state.currentRequestId = undefined;
      }
    },
  };
};
