import * as React from "react";

function DeleteSvgComponent(props) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.87305 6.27051H19.1266"
        stroke="#575757"
        stroke-width="0.92"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.10953 6.27V4.5266C8.10928 4.06685 8.28737 3.62492 8.60633 3.2938C8.76209 3.13268 8.94862 3.00447 9.15485 2.91676C9.36108 2.82905 9.58282 2.78364 9.80693 2.7832H13.1879C13.411 2.78304 13.6317 2.82843 13.8365 2.9166C14.0439 3.00417 14.2316 3.13243 14.3885 3.2938C14.5459 3.45742 14.6708 3.64942 14.7565 3.8596C14.8429 4.07131 14.8867 4.29796 14.8853 4.5266V6.27H8.10953ZM17.4337 6.27V18.4738C17.434 18.9336 17.2559 19.3755 16.9369 19.7066C16.7831 19.8719 16.5975 20.0044 16.3912 20.0961C16.1848 20.1878 15.9621 20.2368 15.7363 20.2402H7.26313C7.0391 20.2393 6.81752 20.1936 6.61136 20.1059C6.4052 20.0183 6.21861 19.8903 6.06253 19.7296C5.74484 19.398 5.56836 18.956 5.57033 18.4968V6.27H17.4337Z"
        stroke="#575757"
        stroke-width="0.92"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.80713 10.626V15.8562"
        stroke="#575757"
        stroke-width="0.92"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.1929 10.626V15.8562"
        stroke="#575757"
        stroke-width="0.92"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DeleteSvgComponent;
