import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from 'react-router';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Form } from "./Form";
import { ReservationFormPage } from "./FormComponents/ReservationFormPage/ReservationFormPage";
export const FormLinkRouting = () => {
  const { isAuthenticated, isLoading: isAuthenticatedLoading } = useAuth0();
	const { formOnlineData } = useSelector((state) => state?.Forms);


  const isReservedNotAllowed = !formOnlineData?.allowNonMembers && !isAuthenticated && !isAuthenticatedLoading 

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/form/:id/reservation" 
					render={() => (
						isReservedNotAllowed ?
						<Redirect to={`/form/${formOnlineData?._id}`} /> :
						<ReservationFormPage />
					)} 
				/>
				<Route exact path="/form/:id" component={Form} />
			</Switch>
		</BrowserRouter>
	);
};
