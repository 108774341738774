/* eslint-disable no-useless-catch */
import Service from "../../service";

const MemberService = {
  getFilter: async (token) => {
    try {
      return await Service.get(`/accounts/filter`, token);
    } catch (error) {
      throw error;
    }
  },
  getContacts: async (token, search) => {
    try {
      return await Service.get(
        `/accounts/contact?${search ? `search=${search}` : ""}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  removeContact: async (data, token) => {
    try {
      return await Service.remove({ url: "/accounts/contact", data, token });
    } catch (error) {
      throw error;
    }
  },
  // get all batch documents
  getClassesList: async (
    token,
    currentPage = 1,
    items_per_page = 10,
    id,
    data,
  ) => {
    try {
      return await Service.post({
        url: `schools/v1/class/list?current_page=${currentPage}&items_per_page=${items_per_page}&orgId=${id}`,
        token,
        data,
      });
    } catch (error) {
      throw error;
    }
  },
  getClassById: async (token, id) => {
    try {
      return await Service.get(`schools/v1/class?id=${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  getFormById: async (token, id, orgId) => {
    try {
      return await Service.get(`schools/v1/form?id=${id}&orgId=${orgId}`, token);
    } catch (error) {
      throw error;
    }
  },
  CreateParticipant: async (formData, token) => {
    try {
      return await Service.upload({
        url: `schools/v1/participant`,
        data: formData,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  
  // get events list
  getEventsList: async (token, orgId) => {
    try {
      return await Service.post({
        url: `schools/v1/event/list?orgId=${orgId}`,
        token,
        
      });
    } catch (error) {
      throw error;
    }
  },
  // Get Class Participants by account
  getClassParticipantsByAccounts: async ({token, payload = {}, page = 1, limit = 10}) => {
  try {
    return await Service.post({
      url: `/schools/v1/participant/account?current_page=${page}&items_per_page=${limit}`,
      token,
      data: payload,
    });
  } catch (error) {
    throw error;
  }
  },
};

export default MemberService;
