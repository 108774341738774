import styled from "styled-components";


export const Wrapper = styled.div`

`
export const TimerContent = styled.div`
font-size: 25px;
color: #002E5C;
width: 50px;
`