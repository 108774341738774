import React from "react";
import "./index.scss"

const ComingSoon = ({ icon, title }) => {
    return (
        <div className="page-content w-100">
            <div className="white-space d-flex justify-content-center">
                <div className="white-c">
                    <div className="icon">{icon}</div>
                    <div className="title">{title}</div>
                </div>
            </div>
            <div className="soon align-self-end">COMING SOON</div>
            {/* <div className="soon-p">
                This page is under construction we will serve it to you shortly
            </div> */}
        </div>
    )
}

export default ComingSoon