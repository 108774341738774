import "./index.scss";

import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "components/Spinner";
import FinanceService from "Lib/API/Finance";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { Charge } from "./Charge";
import { Donation } from "./Donation";
import { Payment } from "./Payment";

const FinanceDrawer = ({
  data,
  setsavetribute,
  setTributeList,
  onReversalClick,
  viewDocumentHandler,
  downloadFileHandler,
  ...props
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [receiptData, setReceiptData] = useState({});

  
  const fetchMyAPI = async (id, type) => {
    const token = await getAccessTokenSilently();
    await FinanceService.getDocumentByTransactionId(token, id, type)
      .then((res) => {
        const result = res?.result?.[0];
        if (type === "invoice") {
          setInvoiceData(result);
        }
        if (type === "receipt") {
          setReceiptData(result);
        }
      })
      .catch((err) => {
        toastr.error("Error", err?.data?.message);
      });
  };


  useEffect(() => {
    const { status } = data?.charge || {};
    if (status === "payment") {
      fetchMyAPI(data?._id, "invoice");
      fetchMyAPI(data?.charge?._id, "receipt");
    }
  }, [data?.charge?.status]);


  const downloadFileClickHandler = (type) => {
    if (type === "invoice") {
      downloadFileHandler(invoiceData)
    }
    if (type === "receipt") {
      downloadFileHandler(receiptData)
    }
  }

  const viewFileClickHandler = (type) => {
    if (type === "invoice") {
      viewDocumentHandler(invoiceData?._id)
    }
    if (type === "receipt") {
      viewDocumentHandler(receiptData?._id)
    }
  }


  return (
    <div className="finance-drawer">
      {loading ? (
        <Spinner />
      ) : data?.charge_type === "charge" &&
        data?.charge?.status === "payment" ? (
        <Payment 
          data={data} 
          onReversalClick={onReversalClick} 
          downloadFileClickHandler={downloadFileClickHandler}
          viewFileClickHandler={viewFileClickHandler} 
          hideInvoiceButtons={!invoiceData}
          hideReceiptButtons={!receiptData}
          {...props} 
        />
      ) : data?.charge_type === "donation" &&
        data?.charge?.status === "payment" ? (
        <Payment 
          data={data} 
          onReversalClick={onReversalClick} 
          downloadFileClickHandler={downloadFileClickHandler} 
          viewFileClickHandler={viewFileClickHandler} 
          hideInvoiceButtons={!invoiceData}
          hideReceiptButtons={!receiptData}
          {...props} />
      ) : data?.charge_type === "credit_on_account" &&
        data?.charge?.status === "payment" ? (
        <Payment 
          data={data} 
          onReversalClick={onReversalClick} 
          downloadFileClickHandler={downloadFileClickHandler} 
          viewFileClickHandler={viewFileClickHandler} 
          hideInvoiceButtons={!invoiceData}
          hideReceiptButtons={!receiptData}
          {...props} />
      ) : data?.charge_type === "donation" ? (
        <Donation
          data={data}
          downloadFileClickHandler={downloadFileClickHandler} 
          viewFileClickHandler={viewFileClickHandler}   
          setsavetribute={setsavetribute}
          setTributeList={setTributeList}
          onReversalClick={onReversalClick}
          hideInvoiceButtons={!invoiceData}
          hideReceiptButtons={!receiptData}
          {...props}
        />
      ) : data?.charge_type === "credit_on_account" ? (
        <Donation
          data={data}
          onReversalClick={onReversalClick}
          setTributeList={setTributeList}
          setsavetribute={setsavetribute}
          {...props}
        />
      ) : (
        <Charge data={data} onReversalClick={onReversalClick} {...props} />
      )}
    </div>
  );
};
export default FinanceDrawer;
