import styled from "styled-components";
import { ReactComponent as ValIcon } from "assets/icons/ErrorIcon.svg"

export const TicketWrapper = styled.div`
    margin-top: 30px;


`
export const TicketName = styled.h1`
  color: var(--dark-blue, #002E5C);
  font-size: 15px;
  font-weight: 600;
`
export const TicketTitle = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
`
export const DeleteTicket = styled.button`
display: flex;
justify-content: center;
align-items: normal;
color: var(--blue, #2555EF);
font-size: 12px;
font-weight: 400;
border: 0;
background-color: transparent;
border-right:1px solid rgba(0, 46, 92, 0.05); 
margin-right: 10px;
padding-right: 10px;
align-items: center;
svg{
    margin-right: 5px;
}
`
export const DashLine = styled.hr`
  border-top: dashed 1px #002E5C;
  opacity: 0.1;
  width: 90%;
`
export const SolidLine = styled.hr`
  border-top: solid 1px #002E5C;
  opacity: 0.1;
  width: 100%;
`
export const TicketContent = styled.div`
 padding-top: 30px;
 padding-left:40px ;
 padding-right:40px;
 width: 100%;
 @media only screen and (min-width: 280px) and (max-width: 767px) {
  padding-left:0px ;
  }
`
export const ProductWrapper =styled.div`
h3{
  color: #002E5C;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;  
opacity: 0.7;
margin-top: 94px;
@media only screen and (min-width: 280px) and (max-width: 767px) {
  margin-top: auto;
  }
}

`
export const ValidationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Pink-20, #FFE5ED);
  background: var(--Pink-10, #FFF5F8);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 30px;
`
export const ValidationItems = styled.ul`
  color:#002E5C;
  padding-top: 8px;

  li{

  }
`
export const ValidationIcon = styled(ValIcon)`
  

`