import Service from "../../service";

const FinanceSettingService = {
  getReversalTypes: async (id, token) => {
    try {
      return await Service.get(`/setting/v1/finance/reversal`, token);
    } catch (error) {
      throw error;
    }
  },
  getReversalTypesById: async (id, token) => {
    try {
      return await Service.get(`/setting/v1/finance/reversal/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  getLedgerSubLedgerList: async (token) => {
    try {
      return await Service.get(`/setting/v1/finance/ledger/list`, token);
    } catch (error) {
      throw error;
    }
  },
  getAccountBillingTypes: async (token) => {
    try {
      return await Service.get(`/setting/v1/finance/billing`, token);
    } catch (error) {
      throw error;
    }
  },

  getBankAccountList: async (token) => {
    try {
      return await Service.get(`/setting/v1/finance/bank`, token);
    } catch (error) {
      throw error;
    }
  },

  searchLedgerByName: async (search, token) => {
    try {
      return await Service.get(
        `/setting/v1/finance/ledger/list?search=${search}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  searchAccountBillingType: async (search, token) => {
    try {
      return await Service.get(
        `/setting/v1/finance/billing?search=${search}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  searchBankAccounts: async (search, token) => {
    try {
      return await Service.get(
        `/setting/v1/finance/bank?search=${search}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  getLedgerSubLedgerById: async (type, id, token) => {
    try {
      return await Service.get(
        `/setting/v1/finance/ledger?type=${type}&id=${id}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  getAccountBillingTypesById: async (id, token) => {
    try {
      return await Service.get(`/setting/v1/finance/billing/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  getBankAccountsById: async (id, token) => {
    try {
      return await Service.get(`/setting/v1/finance/bank/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  updateLedgerSubLedgerById: (data, token) => {
    return Service.update({
      url: `/setting/v1/finance/ledger`,
      data,
      token,
    });
  },
  createNewLedgerSubledger: async (data, token) => {
    try {
      return await Service.post({
        url: `/setting/v1/finance/ledger`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  updateBankAccountById: (id, data, token) => {
    return Service.update({
      url: `/setting/v1/finance/bank/${id}`,
      data,
      token,
    });
  },
  updateReversalById: (id, data, token) => {
    return Service.update({
      url: `/setting/v1/finance/reversal/${id}`,
      data,
      token,
    });
  },
  updateAccountBillingById: (id, data, token) => {
    return Service.update({
      url: `/setting/v1/finance/billing/${id}`,
      data,
      token,
    });
  },
  createNewAccountBillingType: async (data, token) => {
    try {
      return await Service.post({
        url: `/setting/v1/finance/billing`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  createNewReversalType: async (data, token) => {
    try {
      return await Service.post({
        url: `/setting/v1/finance/reversal`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  createNewBankAccount: async (data, token) => {
    try {
      return await Service.post({
        url: `/setting/v1/finance/bank`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  deleteReversalById: (token, id) => {
    try {
      return Service.remove({
        url: `/setting/v1/finance/reversal/${id}`,
        token,
      });
    } catch (err) {
      throw err;
    }
  },
  deleteBankAccountById: (id, token) => {
    try {
      return Service.remove({
        url: `/setting/v1/finance/bank/${id}`,
        token,
      });
    } catch (err) {
      throw err;
    }
  },
  deleteAccountBillingById: (id, token) => {
    try {
      return Service.remove({
        url: `/setting/v1/finance/billing/${id}`,
        token,
      });
    } catch (err) {
      throw err;
    }
  },
  deleteLedgerSubledgerById: (token, type, id) => {
    try {
      return Service.remove({
        url: `/setting/v1/finance/ledger?type=${type}&id=${id}`,
        token,
      });
    } catch (err) {
      throw err;
    }
  },
  // paymnet types
  getAllPaymentTypes: async (token) => {
    try {
      return await Service.get(`/setting/v1/finance/payment`, token);
    } catch (error) {
      throw error;
    }
  },
  getAllAccountReceivable: async (token) => {
    try {
      return await Service.get(
        `/setting/v1/finance/accountReceivable
      `,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  createNewPaymentType: async (data, token) => {
    try {
      return await Service.post({
        url: `/setting/v1/finance/payment`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  createNewAccountReceivable: async (data, token) => {
    try {
      return await Service.post({
        url: `/setting/v1/finance/accountReceivable`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  getPaymentTypeById: async (token, id) => {
    try {
      return Service.get(`/setting/v1/finance/payment/${id}`, token);
    } catch (err) {
      throw err;
    }
  },
  getReceivableAccountsByID: async (token, id) => {
    try {
      return Service.get(`/setting/v1/finance/accountReceivable/${id}`, token);
    } catch (err) {
      throw err;
    }
  },
  updatePaymentTypeById: async (token, id, data) => {
    try {
      return Service.update({
        url: `/setting/v1/finance/payment/${id}`,
        token,
        data,
      });
    } catch (err) {
      throw err;
    }
  },
  updateReceivableAccountId: async (token, id, data) => {
    try {
      return Service.update({
        url: `/setting/v1/finance/accountReceivable/${id}`,
        token,
        data,
      });
    } catch (err) {
      throw err;
    }
  },
  deletePaymentTypeById: (token, id) => {
    try {
      return Service.remove({
        url: `/setting/v1/finance/payment/${id}`,
        token,
      });
    } catch (err) {
      throw err;
    }
  },
  disableReceivableAccount: (token, id) => {
    try {
      return Service.remove({
        url: `/setting/v1/finance/accountReceivable/${id}`,
        token,
      });
    } catch (err) {
      throw err;
    }
  },
  searchPaymentType: async (search, token) => {
    try {
      return await Service.get(
        `/setting/v1/finance/payment?search=${search}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  searchReceivableAccounts: async (search, token) => {
    try {
      return await Service.get(
        `/setting/v1/finance/accountReceivable?search=${search}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
};

export default FinanceSettingService;
