import { useAuth0 } from "@auth0/auth0-react";
import DeleteSvgComponent from "assets/icons/svg/DeleteSvg/Delete";
import { InputField, LabelField } from "components";
import MyButton from "components/MyButton";
import { getCurrency } from "helper/numberFormat";
import useGetUserAccount from "hook/useGetUserAccount";
import React, { useCallback, useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import FinanceService from "../../../../Lib/API/Finance";

export const ACH = ({ formik, onPaymentValueChange, title }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [accountDetail, setAccountDetail] = useState({});
  const [achListing, setAchListing] = useState([]);
  const [selectedAch, setSelectedAch] = useState("");

  
  let accountID = localStorage.getItem("accountID")
  ? localStorage.getItem("accountID")
  : "";


  const accountData = useGetUserAccount({member: true});

  useEffect(() => {
    if (selectedAch) {
      onPaymentValueChange(title, {
        target: {
          value: title,
          name: "paymentMethod",
        },
      });
      onPaymentValueChange(title, {
        target: {
          value: selectedAch.id,
          name: "ACH",
        },
      });
    }
    //eslint-disable-next-line
  }, [selectedAch]);

  const onAccountChange = (event, name) => {
    setAccountDetail({
      ...accountDetail,
      [name]: event.target.value,
    });
    formik.setFieldValue(`account.${name}`, event.target.value);
  };

  const getCreditCards = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const res = await FinanceService.getACHCardsByAccountId(
      token,
      accountID ?? ""
    );

    setAchListing(res.ach.data);
    formik.setFieldValue("ACHDetail", res.ach.data);
  }, [getAccessTokenSilently, accountID]);

  React.useEffect(() => {
    if (accountData) {
      accountID = accountData?.user?.permissions?.linked_account?._id;
      getCreditCards();
    } else {
      getCreditCards();
    }
  }, [getCreditCards,accountData]);
  

  React.useEffect(() => {
    formik.setFieldValue("selectedACH", selectedAch);
  }, [selectedAch]);

  //Handle Submit ACH Account
  const onSaveAccount = async (e) => {
    const token = await getAccessTokenSilently();

    FinanceService.addACHAccount(
      token,
      accountID ?? "",
      accountDetail
    )
      .then(() => {
        getCreditCards();
        formik.setFieldValue(`account.number`, "");
        formik.setFieldValue("account.routing", "");
        formik.setFieldValue("account.type", "");
        formik.setFieldValue("account.name", "");
        // setCardDetail({});
        // setAddNewCard(false);
        toastr.success("Success", "ACH Added Successfully");
      })
      .catch((err) => {
        toastr.error("Error", "Please enter valid card details");
      });
    e.preventDefault();
  };

  //handle delete ACH

  const onDeleteAch = async (idx) => {
    const token = await getAccessTokenSilently();
    await FinanceService.deleteAchCard(
      token,
      idx,
      accountID ?? ""
    )
      .then(() => {
        getCreditCards();
      })
      .catch((error) => {
        console.log(error, "deleteError");
        toastr.error('Error', error?.data?.message);
      });
  };

  //ACH VERIFIED
  const [achNumberOne, setAchNumberOne] = useState("");
  const [achNumberTwo, setAchNumberTwo] = useState("");

  const handelverifyAch = async (item) => {
    const data = {
      accountId: accountID ?? "",
      achId: item?.id,
      firstAmount: achNumberOne,
      secondAmount: achNumberTwo,
    };
    const token = await getAccessTokenSilently();
    const res = await FinanceService.verifyAch(data, token)
      .then(() => {
        getCreditCards();
        toastr.success("Success", "Account verified");
      })
      .catch((err) => {
        toastr.error(
          "Error",
          err?.data?.message ?? "Please enter valid details"
        );
      });
  };

  return (
    <div>
      <div className="cards-radio font-16">
        {/* <div className="row my-3">
          {achListing?.map((item) => (
            <div
              className="col-sm-3"
              style={{
                background: "#e4dfdf",
                padding: "3px 20px",
                borderRadius: "3%",
                marginRight: "1rem",
              }}
              data-id={item.id}
              key={item.id}
              onClick={() => setSelectedAch(item)}
              class={selectedAch?.id === item.id ? "selected-card" : ""}
            >
              <p>
                {`${item.nameOnAccount ? item.nameOnAccount : ""} ${
                  item.accountNumber ? "/ " + item.accountNumber : ""
                }`}
                {selectedAch?.id !== item.id ? (
                  <DeleteSvgComponent
                    color={"gray"}
                    onClick={() => onDeleteAch(item.id)}
                  />
                ) : null}
              </p>
            </div>
          ))}
         
        </div> */}
        {achListing?.map((item) => (
          <div className="row my-3">
            <div
              className="col-sm-3"
              style={{
                background: "#e4dfdf",
                padding: "3px 20px",
                borderRadius: "3%",
                marginRight: "1rem",
              }}
              data-id={item.id}
              key={item.id}
              onClick={() => setSelectedAch(item)}
              class={selectedAch?.id === item.id ? "selected-card" : ""}
            >
              <p>
                {`${item.nameOnAccount ? item.nameOnAccount : ""} ${
                  item.accountNumber ? "/ " + item.accountNumber : ""
                }`}
                {selectedAch?.id !== item.id ? (
                  <DeleteSvgComponent
                    color={"gray"}
                    onClick={() => onDeleteAch(item.id)}
                  />
                ) : null}
              </p>
            </div>
            {item?.status !== "verified" && (
              <div>
                <div
                  style={{
                    display: "flex",
                    height: "auto",
                  }}
                >
                  <input
                    style={{
                      width: "40px",
                      height: "30px",
                      marginRight: "1rem",
                      border: "0.9px gray",
                      background: "gray",
                      color: "white",
                      textAlign: "center",
                      marginTop: "0.5rem",
                    }}
                    type={"tel"}
                    maxlength="2"
                    onChange={(event) => setAchNumberOne(event.target.value)}
                  ></input>
                  <input
                    style={{
                      width: "40px",
                      height: "30px",
                      marginRight: "1rem",
                      border: "0.9px gray",
                      marginTop: "0.5rem",
                      background: "gray",
                      color: "white",
                      textAlign: "center",
                    }}
                    type={"tel"}
                    maxlength="2"
                    onChange={(event) => setAchNumberTwo(event.target.value)}
                  ></input>
                  <MyButton
                    style={{
                      height: "30px",
                    }}
                    content={"Verify"}
                    onClick={() => handelverifyAch(item)}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="row my-5">
        <div className="col-sm-4 pr-0 mt-2">
          <InputField
            mandatory
            {...{
              formik,
              title: "Account type",
              name: "account.accountType",
              type: "string",
              value: formik?.values?.account?.type,
            }}
            id="myInput"
            handleChange={(event) => onAccountChange(event, "type")}
          />
        </div>
        <div className="col-sm-4 pr-0 mt-2">
          <InputField
            mandatory
            {...{
              formik,
              title: "Account number",
              name: "account.accountNumber",
              type: "string",
              minlength: "16",
              maxLength: "16",
              value: formik?.values?.account?.number,
            }}
            id="myInput"
            handleChange={(event) => onAccountChange(event, "number")}
          />
        </div>
        <div className="col-sm-4 pr-0 mt-2">
          <InputField
            mandatory
            {...{
              formik,
              title: "Routing number",
              name: "account.routingNumber",
              type: "string",
              minlength: "16",
              maxLength: "16",
              value: formik?.values?.account?.routing,
            }}
            handleChange={(event) => onAccountChange(event, "routing")}
            id="myInput"
          />
        </div>
        <div className="col-sm-4 pr-0 mt-2">
          <InputField
            mandatory
            {...{
              formik,
              title: "Name on account",
              name: `account.nameOnAccount`,
              placeholder: "",
              value: formik?.values?.account?.name,
            }}
            handleChange={(event) => onAccountChange(event, "name")}
          />
        </div>
        <div
          className="col-sm-4"
          style={{
            marginTop: "3rem",
          }}
        >
          <MyButton
            content={"Save Account"}
            style={{ width: 130, height: 40 }}
            onClick={onSaveAccount}
          />
        </div>
      </div>
    </div>
  );
};
export const ACHHeader = ({ formik, ...props }) => {
  const { expanded, current } = props;
  const currency = getCurrency();

  const preventMinus = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  const handlefieldClick = (e) => {
    if (e.stopPropagation && expanded === true) {
      e.stopPropagation();
    }
  };

  const handleOnChange = (event) => {
    formik.setFieldValue("ach", event.target.value);
  };

  return (
    <div
      className={
        expanded === false && current?.includes("ach") === true
          ? "accordion-header achPayment"
          : expanded === false
          ? "accordion-payment-header achPayment"
          : expanded === true
          ? "accordion-header achPayment"
          : "accordion-header-payment achPayment"
      }
    >
      <div className="d-flex">
        <LabelField title="ACH" />
      </div>
      <div
        className={
          expanded === false && current?.includes("ach")
            ? "custom-expand dollor-icon col-sm-3 nonexpanded-amount "
            : "col-sm-2"
        }
      >
        {expanded === false &&
        current?.includes("ach") &&
        formik?.values?.ach ? (
          <div className="cash-non-expanded">
            <span>{currency}</span>
            <span>{formik.values?.ach}.00</span>
          </div>
        ) : current?.includes("ach") && expanded === true ? (
          <InputField
            {...{
              formik,
              title: "none",
              name: `val`,
              style: { background: "#ffff", border: "none" },
              value: formik.values?.ach,
              placeholderIcon: (
                <span
                  className="align-self-center px-4"
                  style={{ color: "black" }}
                >
                  {currency}
                </span>
              ),
            }}
            handleFocus={(e) => handlefieldClick(e)}
            paymentInput="paymentInput"
            // onKeyDown={preventMinus}
            handleChange={(event) => handleOnChange(event)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
