import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@material-ui/core';
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as AddIcon } from "assets/icons/add-plus.svg";
import BackSvgComponent from "assets/icons/svg/back";
import { InputField, MyCheckbox } from "components";
import MyButton from "components/MyButton";
import StripeCard from 'components/StripeCard/StripeCard';
import { postalCodeFormat, postalCodeLabel } from "helper/postalCodeFormat";
import FinanceService from 'Lib/API/Finance';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';

const ManageCreditsCard = ({ checkIsStripe, setSelectedCard, selectedCard }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  let accountID = localStorage.getItem("accountID")
  ? localStorage.getItem("accountID")
  : "";

  
  const addCardStripeRef = useRef(null);
  const postalLabel = postalCodeLabel();


  const [cardLoading, setCardLoading] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [addNewCard, setAddNewCard] = useState(false);
  const [addCardLoading, setAddCardLoading] = useState(false);

  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cvc: "",
    zip: "",
  });


  const onCardDetailChange = (e, name) => {
    setCardDetails((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };


  const fetchCards = async () => {
    setCardLoading(true);
    const token = localStorage.getItem("token");
    await FinanceService.getCreditCardsByAccountId(token, accountID)
      .then((res) => {
        setCardList(res?.card?.data);
        if(res?.card?.data?.length === 1) setSelectedCard(res?.card?.data[0]?.id || "");
        if(!res?.card?.data?.length) setAddNewCard(true);
      })
      .catch((err) => {
        enqueueSnackbar(err?.data?.message, { variant: "error" });
        setAddNewCard(true);
      })
      .finally(() => {
        setCardLoading(false);
      });
  }

  useEffect(() => {
    if(!accountID) return;
    fetchCards();
  }, [accountID]);



  const handleAddNewCard = async (tokenId = "") => {
    setAddCardLoading(true);
    
    const cardsDetails = {
      object: "card",
      number: cardDetails?.cardNumber,
      exp_month: cardDetails?.expMonth,
      exp_year: cardDetails?.expYear,
      cvc: cardDetails?.cvc,
      zip: cardDetails?.zip,
    };
    if(!checkIsStripe()) {
      if (
        (!cardsDetails?.number ||
          !cardsDetails?.exp_year ||
          !cardsDetails?.cvc)
        ) {
        return enqueueSnackbar("please fill all mandatory fields", { variant: "error" });
      }

      if(!cardsDetails?.zip?.length) {
        return enqueueSnackbar(`Please enter ${postalCodeLabel()}.`, { variant: "error" });
      }

      const validZipCode = postalCodeFormat(cardsDetails?.zip);
      if (!validZipCode) {
        enqueueSnackbar(`Invalid ${postalLabel}`, { variant: "info" });
      }
   }
   const bodyData = (checkIsStripe() && tokenId) ? { token: tokenId } : { cardsDetails };
   const token = await getAccessTokenSilently();
    FinanceService.addCreditCard(token, accountID, bodyData)
      .then((res) => {
        fetchCards();
        setAddNewCard(false);
        setCardDetails({
          cardNumber: "",
          expMonth: "",
          expYear: "",
          cvc: "",
          zip: "",
        });
        setSelectedCard(res?.card?.id || "");
      })
      .catch((err) => {
        enqueueSnackbar(err?.data?.message, { variant: "error" });
      }).finally(() => {
        setAddCardLoading(false);
      });
  };


  return (
    <div className="card_num_field">
      {cardLoading ? (
        <div className="text-center">
        <CircularProgress />
        </div>
      ) : (
        <>
          {!!cardList?.length && (
            <div className="cards-radio font-16">
              {cardList?.map((item, index) => (
                <div
                  data-id={item.id}
                  key={item.id}
                  onClick={() => {
                    if (selectedCard === item.id) {
                      setSelectedCard("");
                    } else {
                      setSelectedCard(item?.id);
                    }
                  }}
                  className={
                    selectedCard === item.id
                      ? "selected-card"
                      : ""
                  }
                  style={{ width: "fit-content" }}
                >
                  <p>
                    {`${item.country ? item.country : ""} ${item.brand ? item.brand : ""
                      } (${item.last4 ? item.last4 : ""}), Exp: ${item.exp_month
                      }/${item.exp_year}`}
                    <div className="checkbox checkbox-lg checkbox-single mr-4">
                      <input
                        type="checkbox"
                        name={`card_number${index}`}
                        id={index}
                        onChange={(e) => {
                          setSelectedCard(e.target?.checked ? item?.id : "");
                        }}
                        checked={
                          selectedCard === item?.id
                        }
                      />
                      <span />
                    </div>
                  </p>
                </div>
              ))}
            </div>
          )}
          {!!cardList?.length && (
            <div className="card__header">
              <div
                className="add-new-payment-info my-5"
                onClick={() => setAddNewCard(!addNewCard)}
              >
                <span className="add-icon">
                  <AddIcon />
                </span>
                <span className="add">Add New Card</span>
              </div>
              {addNewCard && (
                <div
                  className="cancel-add-button"
                >
                  <BackSvgComponent
                    onClick={() => setAddNewCard(false)}
                    role="button"
                  />
                </div>
              )}
            </div>
          )}
          {addNewCard && (
            <>
              {checkIsStripe() ? (
                <Grid item xs={12}>
                  <StripeCard
                    loading={addCardLoading}
                    setLoading={setAddCardLoading}
                    saveCard={true}
                    ref={addCardStripeRef}
                    onSaveCard={handleAddNewCard}
                  />
                </Grid>
              ) : (
                <div className="row">
                  <div className="col-6">
                    <InputField
                      mandatory
                      {...{
                        title: "Card Number",
                        name: "card_number",
                        value: cardDetails?.cardNumber,
                        type: "string",
                        maxLength: 16,
                        minlength: 16,
                        pattern: "[0-9]{10}",
                        placeholderIcon: (
                          <CreditCardOutlinedIcon
                            className="card-icon"
                            fontSize="large"
                          />
                        ),
                      }}
                      id="myInput"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      handleChange={(event) => {
                        onCardDetailChange(event, "cardNumber");
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <InputField
                      mandatory
                      {...{
                        title: "Expiry Month",
                        name: `expMonth`,
                        placeholder: "MM",
                        minlength: "1",
                        maxLength: "2",
                        type: "string",
                        value: cardDetails?.expMonth,
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      handleChange={(event) =>
                        onCardDetailChange(event, "expMonth")
                      }
                    />
                  </div>
                  <div className="col-3">
                    <InputField
                      mandatory
                      {...{
                        title: "Expiry Year",
                        type: "string",
                        minlength: "4",
                        maxLength: "4",
                        name: `expYear`,
                        placeholder: "YYYY",
                        value: cardDetails?.expYear,
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      handleChange={(event) =>
                        onCardDetailChange(event, "expYear")
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <InputField
                      mandatory
                      {...{
                        title: "CVC",
                        type: "string",
                        minlength: "3",
                        maxLength: "4",
                        name: `cvc`,
                        placeholder: "3-4 digits",
                        value: cardDetails?.cvc,
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      handleChange={(event) =>
                        onCardDetailChange(event, "cvc")
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <InputField
                      mandatory
                      {...{
                        title: postalLabel,
                        type: "string",
                        name: `zip`,
                        placeholder: "12345",
                        value: cardDetails?.zip,
                      }}
                      handleChange={(event) =>
                        onCardDetailChange(event, "zip")
                      }
                    />
                  </div>
                  <div className="col-sm-4 save-card-button">
                    <MyButton
                      content={"Add Card"}
                      onClick={handleAddNewCard}
                      className=""
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ManageCreditsCard