import React from "react";
import {
  Wrapper,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "./Theme";
export const Button = ({
  type,
  width,
  height,
  children,
  icon,
  isDisabled,
  style,
  ...rest
}) => {
  const buttonType = () => {
    switch (type) {
      case "Primary":
        return (
          <PrimaryButton
            width={width}
            height={height}
            isDisabled={isDisabled}
            style={style}
            {...rest}
          >
            {children}
          </PrimaryButton>
        );
      case "Secondary":
        return (
          <SecondaryButton
            width={width}
            height={height}
            isDisabled={isDisabled}
            style={style}
            {...rest}
          >
            {children}
          </SecondaryButton>
        );
      case "Tertiary":
        return (
          <TertiaryButton
            width={width}
            height={height}
            isDisabled={isDisabled}
            style={style}
            {...rest}
          >
            {children}
          </TertiaryButton>
        );
      default:
        return <button>{children}</button>;
    }
  };
  return <Wrapper>{buttonType()}</Wrapper>;
};
