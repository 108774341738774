import { useAuth0 } from '@auth0/auth0-react';
import { SubHeader } from "layout/SubHeader";
import MemberService from "Lib/API/Member";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { withUserExist } from "../CustomHooks";
import EventsCalendar from "../EventsCalendar";

const Events = () => {
    const { getAccessTokenSilently } = useAuth0();

    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);

    function convertUTCToLocalDate(date,type) {
        if (!date) {
          return new Date();
        }
        date = new Date(date);
        date = new Date(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours() + (type === "end" ? 2 : 0),
          date.getUTCMinutes()
        );
        return date;
      }
    

    const getEventsHandler = async () => {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const orgId = localStorage.getItem('orgId')
        try {
    
          const eventsRes = await MemberService.getEventsList(token, orgId);
          setEvents(eventsRes?.payload?.data?.map((event) => {
            return {
              ...event,
              start: convertUTCToLocalDate(event?.date),
              end: convertUTCToLocalDate(event?.date, "end"),
              title: event?.eventName,
              id: event?._id,
            }
          }));
        
        } catch (error) {
        console.log('error :', error);
          toastr.error('Error', error?.data?.message);
        } finally {
          setLoading(false);
        }
      };
      useEffect(() => {
        getEventsHandler()
      }, []);


    return (
        <div className='w-100 m-0 p-5'>
             <div className="col-12 m-0 d-flex justify-content-between align-items-center borderBottom py-5">
                <div className="header-tittle">
                <SubHeader
                    className="class-tab subheader"
                    {...{
                    title: "Events",
                    }}
                />
                </div>
            </div>
            <div>
                <EventsCalendar events={events} withoutTitle={true} width={"100%"}/>
            </div>
        </div>
    )
}

export default withUserExist(Events)