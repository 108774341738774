import React, { useState } from "react";
import { ReactComponent as FamilySvg } from "assets/icons/family.svg";
import { ReactComponent as UpdateSvg } from "assets/icons/update.svg";
import { ReactComponent as CurveSvg } from "assets/icons/curve.svg";
import { ReactComponent as DashboardSvg } from "assets/icons/dashboard.svg";
import { useHistory } from "react-router";

export const FirstTime_4 = (props) => {
  const [selectId, setSelectId] = useState(-1);
  const history = useHistory();

  const handelOnClickToggle = ({ id }) => {
    setSelectId(id);
    return 0;
  };

  return (
    <div className="login-form login-signin">
      <div className="row mt-4 mb-20">
        <div className="col-md-6 pr-md-2 mt-3">
          <div
            className="blue-box p-2"
            style={{
              border: selectId === 0 ? "2px solid #2555EF" : "",
              cursor: "pointer",
            }}
            onMouseOver={() => handelOnClickToggle({ id: 0 })}
            onClick={() => history.push("add-new-member")}
          >
            <div>
              <div className="non-active-img">
                <FamilySvg fill={selectId === 0 ? "#2555EF" : "#fff"} />
              </div>
              <div className="mt-md-5 mt-3">
                <h3
                  className="text-in-box"
                  style={{ color: selectId === 0 ? "#2555EF" : "" }}
                >
                  Add a family member
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 pl-md-3 mt-3">
          <div
            className="blue-box p-2"
            style={{
              border: selectId === 1 ? "2px solid #2555EF" : "",
              cursor: "pointer",
            }}
            onMouseOver={() => handelOnClickToggle({ id: 1 })}
            onClick={() => history.push("finance")}
          >
            <div>
              <div className="non-active-img">
                <UpdateSvg fill={selectId === 1 ? "#2555EF" : "#ffff"} />
              </div>
              <div className="mt-md-5 mt-3">
                <h3
                  className="text-in-box"
                  style={{ color: selectId === 1 ? "#2555EF" : "" }}
                >
                  Update your billing information
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 pr-md-2 mt-5">
          <div
            className="blue-box p-2"
            style={{
              border: selectId === 2 ? "2px solid #2555EF" : "",
              cursor: "pointer",
            }}
            onMouseOver={() => handelOnClickToggle({ id: 2 })}
            onClick={() => history.push("add-new-yahrzeit")}
          >
            <div>
              <div className="non-active-img">
                <CurveSvg fill={selectId === 2 ? "#2555EF" : "#fff"} />
              </div>
              <div className="mt-md-5 mt-3">
                <h3
                  className="text-in-box"
                  style={{ color: selectId === 2 ? "#2555EF" : "" }}
                >
                  Add a Yahrzeit
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 pl-md-3 mt-5">
          <div
            className="blue-box p-2"
            style={{
              border: selectId === 3 ? "2px solid #2555EF" : "",
              cursor: "pointer",
            }}
            onMouseOver={() => handelOnClickToggle({ id: 3 })}
            onClick={() => history.push("/home")}
          >
            <div>
              <div className="non-active-img">
                <DashboardSvg fill={selectId === 3 ? "#2555EF" : "#fff"} />
              </div>

              <div className="mt-md-5 mt-3">
                <h3
                  className="text-in-box"
                  style={{ color: selectId === 3 ? "#2555EF" : "" }}
                >
                  Take me to the Dashboard
                </h3>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
