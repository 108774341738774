import Grid from "@material-ui/core/Grid";
import BackSvgComponent from "assets/icons/svg/back";
import { HintField, InputField, LabelField, MyCheckbox } from "components";
import MyFilterButton from "components/MyFilterButton/MyFilterButton";
import { SelectField } from "components/SelectFields/SelectField";
import { getCurrency, numberFormat } from "helper/numberFormat";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { emailValidation } from "utils/validation";
import { ReactComponent as ClockWiseRefresh } from "../../../assets/icons/clockWise_refresh.svg";
import TributeInformation from "../DonationPay/TributeInformation";
import DonationStepper from "../DonationStepper";
import "./DonationCause.scss";

const DonationCause = ({ formik, ledgers, setLedgers, loading,external,setBillMyAccount }) => {
  const currency = getCurrency();
  const { push } = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const addCauseInfo = () => {
    
    if (
      !formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]
        ?.cause) {
          return enqueueSnackbar("please select cause", { variant: "error" });
    }

    if(formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]
      ?.amount === null || 
      formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]
      ?.amount === "") {
        return enqueueSnackbar("please enter amount", { variant: "error" });
    }

    if(formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]
      ?.note === "" && formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]?.required_note) {
        return enqueueSnackbar("please enter note", { variant: "error" });
    }

    formik.setFieldValue("causeInfo", [
      ...formik?.values?.causeInfo,
      {
        cause: "",
        amount: null,
        note: "",
        required_note: false,
        contribution: { isRecurring: false, frequency: "monthly" },
      },
    ]);
  };

  useEffect(() => {
    formik.setFieldValue(
      "causeInfo",
      formik?.values?.causeInfo ?? [
        {
          cause: "",
          amount: null,
          note: "",
          required_note: false,
          contribution: { isRecurring: false, frequency: "monthly" },
        },
      ]
    );
  }, []);

  const getValueByPath = (obj, path) => {
    return path.split('.').reduce((acc, curr) => acc && acc[curr], obj);
  };

  const displayValidation = (msg) => {
    enqueueSnackbar(msg, { variant: "error" });
  };

  const checkTributeValidation = (values) => {
    const validationRules = [
      { field: 'tribute_type.name', message: 'Please select tribute type' },
      { field: 'donor_name', message: 'Please enter donor name' },
    ];
    let valid = true;
    
    validationRules.some(rule => {
      const value = getValueByPath(values, rule.field);

      if (!value || (typeof value === 'string' && value.trim() === '')) {
        displayValidation(rule.message);
        valid = false;
        return true;
      }

      if (rule.validationFunction && !rule.validationFunction(value)) {
        displayValidation(rule.message);
        valid = false;
        return true; 
      }

      return false;
    });

    return valid;
  };


  const checkTributeAcknowledgementValidation = (values) => {
    const validationRules = [
      { field: 'recipient_name', message: 'Please enter recipient name' },
      { field: 'email', message: 'Please enter valid email', validationFunction: emailValidation },
    ];
    let valid = true;
    
    const acknowledgementsCheck = values?.acknowledgements?.some(ack => {
      validationRules.some(rule => {
        const value = getValueByPath(ack, rule.field);

        if (!value || (typeof value === 'string' && value.trim() === '')) {
          displayValidation(rule.message);
          valid = false;
          return true;
        }
        if (rule.validationFunction && !rule.validationFunction(value)) {
          displayValidation(rule.message);
          valid = false;
          return true; 
        }
        return false;
      });

      return !valid;
    });

    return valid;
  
  }





  const handleNext = () => {
    if (formik?.values?.causeInfo?.length === 1) {

      if(!formik?.values?.causeInfo[0]?.cause) {
        return enqueueSnackbar("please select cause", { variant: "error" });
      }

      if(formik?.values?.causeInfo[0]?.amount === null ||
        formik?.values?.causeInfo[0]?.amount === "") {
        return enqueueSnackbar("please enter amount", { variant: "error" });
      }

      if (
        !formik?.values?.causeInfo[0]?.note &&
        formik?.values?.causeInfo[0]?.required_note
      ) {
        return enqueueSnackbar("please enter note", { variant: "error" });
      }
    }

    if (formik?.values?.causeInfo?.length > 1) {

      if(!formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]
        ?.cause) {
          return enqueueSnackbar("please select cause", { variant: "error" });
        }

        if( formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]
          ?.amount === null ||
        formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]
          ?.amount === "")  {
          return enqueueSnackbar("please enter amount", { variant: "error" });
        }

        if (
          !formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]
            ?.note &&
          formik?.values?.causeInfo[formik?.values?.causeInfo?.length - 1]
            ?.required_note
        ) {
          return enqueueSnackbar("please enter note", { variant: "error" });
        }
    }

    if(formik?.values?.tributeCheck && !checkTributeValidation(formik?.values)) return;
    if(formik?.values?.tributeCheck && formik?.values?.showAcknowledgement && !checkTributeAcknowledgementValidation(formik?.values)) return;

    push(external ? "/donation/details" : "/donation-view/details");
  };
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;

  const deleteCause = (index) => {
    if (formik?.values?.causeInfo?.length === 1) {
      enqueueSnackbar("please add at least one cause", { variant: "error" });
      return;
    }
    formik.setFieldValue(
      "causeInfo",
      formik?.values?.causeInfo?.filter((item, i) => i !== index)
    );
  };
  
  
  const filteredLedgersHandler = () => {
    const selectedLedgers = formik?.values?.causeInfo?.map((item) => item?.cause)
    const filteredLedgers = ledgers?.filter((item) => {
      const find = selectedLedgers?.find(
        (selected) =>
          selected && ((selected?.ledger_name && selected?.ledger_name === item?.ledger_name) ||
          (selected?.sub_ledger_name && selected?.sub_ledger_name === item?.sub_ledger_name))
      );
      return !find;
    });
    return filteredLedgers;
  };
  

  const filteredLedgers = filteredLedgersHandler();

  
  return (
    <>
      <div className="donation__content">
        <DonationStepper activeStep={0} />
        <div className="DonationCause__content">
          <Grid container spacing={2}>
            {/* cause */}
            {formik.values?.causeInfo?.map((item, index) => (
              <Grid key={index} container spacing={2} className="mb-3">
                {/* cause select field */}
                <Grid item xs={12} sm={6}>
                  <div className="selectField_cause">
                    <SelectField
                      {...{
                        formik,
                        title: "Cause",
                        mandatory: true,
                        name: `causeInfo[${index}].cause`,
                        items: filteredLedgers?.map(
                          (item) => item?.ledger_name || item?.sub_ledger_name
                        ) ?? ["cause1", "cause2"],
                        value:
                          (formik?.values?.causeInfo?.[index]?.cause
                            ?.sub_ledger_name ||
                            formik?.values?.causeInfo?.[index]?.cause
                              ?.ledger_name) ??
                          "",
                        placeholder: "Cause",
                      }}
                      onClick={(v) => {
                        formik.setFieldValue(
                          `causeInfo[${index}].cause`,
                          filteredLedgers?.find(
                            (item) =>
                              item?.ledger_name === v ||
                              item?.sub_ledger_name === v
                          )
                        );
                        formik.setFieldValue(
                          `causeInfo[${index}].amount`,
                          filteredLedgers?.find(
                            (item) =>
                              item?.ledger_name === v ||
                              item?.sub_ledger_name === v
                          )?.default_amount
                        );
                        formik.setFieldValue(
                          `causeInfo[${index}].required_note`,
                          filteredLedgers?.find(
                            (item) =>
                              item?.ledger_name === v ||
                              item?.sub_ledger_name === v
                          )?.notes
                        );
                      }}
                      selectedItemColor={"#002E5C"}
                      loading={loading}
                    />
                  </div>
                </Grid>
                {/* amount field */}
                <Grid item xs={12} sm={2}>
                  <div className="cause_amount_field">
                    <InputField
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      step="any"
                      title="Amount"
                      {...{
                        formik,
                        mandatory: true,
                        name: `causeInfo[${index}].amount`,
                        value: formik?.values?.causeInfo?.[index]?.amount ?? "",
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                        }
                      }}
                      handleChange={(e) => {
                        formik.setFieldValue(
                          `causeInfo[${index}].amount`,
                          e?.target?.value.match(regex)[0]
                        );
                      }}
                      placeholderIcon={
                        <span className="amount__couse-placeholder-icon">
                          {currency}
                        </span>
                      }
                      style={{ margin: "0" }}
                      inputClassName="amountCouse__input"
                      min="0"
                    />
                  </div>
                </Grid>
                <Grid  item xs={12} sm={1}  className="recurring-wrapper">
                  <div
                    className="causeCheckbox"
                  >
                    <MyCheckbox
                      handlechange={(e) =>{
                        formik.setFieldValue(
                          `causeInfo[${index}].contribution.isRecurring`,
                          e.target.checked
                        )
                        setBillMyAccount(false);
                      }}
                      checked={
                        formik?.values?.causeInfo?.[index]?.contribution
                          ?.isRecurring ?? false
                      }
                      name={`causeInfo[${index}].contribution`}
                    />
                  </div>
                <div>
                  {index === 0 ? (
                    <label className="causeLabel">Recurring Contribution</label>
                  ) : (
                    <LabelField title="" style={{ margin: "0" }} />
                  )}
                  <MyFilterButton
                    value={
                      formik?.values?.causeInfo?.[index]?.contribution
                        ?.frequency === "yearly"
                        ? "Yearly"
                        : "Monthly"
                    }
                    items={[
                      // { label: 'One-Time', value: 'one_time' },
                      { label: "Monthly", value: "monthly" },
                      { label: "Yearly", value: "yearly" },
                    ]}
                    // icon={<ArrowDropDownIcon />}
                    name={`causeInfo[${index}].contribution`}
                    onChange={(v) => {
                      formik.setFieldValue(
                        `causeInfo[${index}].contribution.frequency`,
                        v
                      );
                    }}
                    disabled={
                      !formik?.values?.causeInfo?.[index]?.contribution
                        ?.isRecurring
                    }
                  />
                  {formik.values?.causeInfo?.length > 1 && (
                    <div
                      className="delete_cause_btn"
                      onClick={() => deleteCause(index)}
                    >
                      <BackSvgComponent />
                    </div>
                  )}
                  </div>
                {/* </Grid> */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="cause_note_field">
                  <InputField
                    type="text"
                    title="Note"
                    mandatory={formik.values?.causeInfo?.[index]?.cause?.notes}
                    {...{
                      name: `causeInfo[${index}].note`,
                      placeholder: "Note",
                      value: formik?.values?.causeInfo?.[index]?.note ?? "",
                    }}
                    handleChange={(e) => {
                      formik.setFieldValue(
                        `causeInfo[${index}].note`,
                        e?.target?.value
                      );
                    }}
                    inputClassName="donation__input"
                  />
                  </div>
                </Grid>
              </Grid>
            ))}
            {/* add cause button */}
            <Grid item xs={12}>
              <div className="addCauseText" onClick={addCauseInfo}>
                + Add Cause
              </div>
            </Grid>
            {/* summary */}
            <Grid item xs={12}>
              <div className="summaryText">Summary</div>
            </Grid>
            {formik?.values?.causeInfo?.map((item, idx) => (
              <Grid item xs={12} key={idx}>
                <div className="couses__amount d-flex align-items-center justify-content-between">
                  <div>{item?.cause?.sub_ledger_name || item?.cause?.ledger_name}</div>
                  <div className="d-flex align-items-center">
                    {item?.contribution?.isRecurring && (
                      <span className="recurring_value">
                        <ClockWiseRefresh className="mr-1" /> Recurring{" "}
                        {item?.contribution?.frequency}
                      </span>
                    )}
                    {numberFormat({number:+item?.amount, decimal: 2, style: "currency"})}
                  </div>
                </div>
              </Grid>
            ))}
            <hr className="hr-line"
            />
            <Grid item xs={12}>
              <div className="total__amount d-flex alien-items-center justify-content-between">
                <div>Total</div>
                <div>
                  {numberFormat({number:
                        (+formik?.values?.causeInfo?.reduce(
                          (acc, c) => acc + +c?.amount,
                          0
                        )), decimal: 2, style: "currency"})}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            height: "48px",
            marginTop: "15px",
            width: "100%",
          }}
        >
          <label
            htmlFor="honor"
            className="d-flex align-items-center h-100 checkbox__label"
            style={{ fontWeight: '700'  }}
          >
            <MyCheckbox
              handlechange={(e) =>
                formik.setFieldValue("tributeCheck", e?.target?.checked)
              }
              checked={formik?.values?.tributeCheck}
              name="checkboxHonor"
              id="honor"
            />
            Give in honor or memory of someone
          </label>
        </div>
        {formik?.values?.tributeCheck && (
            <div className="tribute__container">
              <div className="tribute_information_text">
                Tribute Information
              </div>
              <div className="tribute__content__extDo">
                <TributeInformation formik={formik} />
              </div>
            </div>
          )}

        <div className="d-flex justify-content-end w-100 ">
        <button className="btn btn-primary next__btn" onClick={handleNext} style={{marginRight:0,marginLeft:10}}>
          Next
        </button>
      </div>
       
      </div>

      
    </>
  );
};

export default DonationCause;
