import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from 'react-router';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { School } from "./School";
import { ListOfClasses } from "./SchoolComponents/ListOfClasses/ListOfClasses";
import { ReservationSchoolPage } from "./SchoolComponents/ReservationSchoolPage/ReservationSchoolPage";
export const SchoolLinkRouting = () => {
	const { isAuthenticated, isLoading: isAuthenticatedLoading } = useAuth0();
	const { classDetails, numberOfTickets } = useSelector((state) => state?.Class)

	const isReservedNotAllowed = !classDetails?.allow_non_members_register && !isAuthenticated && !isAuthenticatedLoading 


	return (
		<>
			<BrowserRouter>
				<Switch>
					<Route exact path="/school/" component={ListOfClasses} />
					<Route exact path="/school/:id/registration" 
						render={() => (
							isReservedNotAllowed ?
							<Redirect to={`/school/${classDetails?._id}`} /> :
							<ReservationSchoolPage />
						)} 
					/>
					<Route exact path="/school/:id" component={School} />
				</Switch>
			</BrowserRouter>
		</>
	);
};
