import React from "react";
// import { ReactComponent as SearchSVG } from "assets/media/svg/icons/General/Search.svg";
import { ReactComponent as SearchSVG } from "assets/icons/new-search-finance.svg";

export default function SearchComp({
  value,
  setSearchText,
  placeholder,
  style,
  batchbilling,
}) {
  return (
    <div
      className={
        batchbilling
          ? "input-icon d-sm-block batch-billing-search-component"
          : "input-icon d-sm-block search-container "
      }
      style={{ width: "100%" }}
    >
      <input
        style={
          batchbilling
            ? {
                borderColor: "#f3f6f9",
                ...style,
              }
            : {
                borderColor: "#B5B5C3",
                ...style,
              }
        }
        type="text"
        className="form-control form-control-lg form-control-solid h-100 search-input"
        placeholder={placeholder ? placeholder : "Search"}
        id="generalSearch"
        autoComplete="false"
        value={value}
        onChange={setSearchText}
      />
      <span>
        <span className="">
          <SearchSVG />
        </span>
      </span>
    </div>
  );
}
