import { Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import FinanceService from "Lib/API/Finance";
import { ReactComponent as AddIcon } from "assets/icons/add-plus.svg";
import BackSvgComponent from "assets/icons/svg/back";
import { InputField, MyCheckbox } from "components";
import MyButton from "components/MyButton";
import RadioButtons from "components/RadioButtons/RadioButtons";
import { numberFormat } from "helper/numberFormat";
import { postalCodeFormat, postalCodeLabel } from "helper/postalCodeFormat";
import useGetUserAccount from "hook/useGetUserAccount";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import thousands from "thousands";
import { ReactComponent as ClockWiseRefresh } from "../../../assets/icons/clockWise_refresh.svg";
import StripeCard from "../../../components/StripeCard/StripeCard";
import DonationStepper from "../DonationStepper";
import { PaymentResultPage } from "../PaymentResultPage/PaymentResultPage";
import "./DonationPay.scss";


const DonationPay = ({ formik, convenienceFeeInfo, coverConvenienceFee, enableCoverConvenienceFee, setEnableCoverConvenienceFee,external,enableBillMyAccount,setBillMyAccount,billMyAccount }) => {
  const { provider_information } = localStorage.getItem("organization")
    ? JSON.parse(localStorage.getItem("organization"))
    : {};
  const addCardStripeRef = useRef(null);

  const postalLabel = postalCodeLabel();


  const { provider } = provider_information || {};
  const { publicKey } = provider_information?.keys || {};
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [cardList, setCardList] = useState([]);
  const [addNewCard, setAddNewCard] = useState(false);
  const [newCardDetails, setNewCardDetails] = useState({});
  const [addCardLoading, setAddCardLoading] = useState(false);
  const { push } = useHistory();

  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  let accountID = localStorage.getItem("accountID")
  ? localStorage.getItem("accountID")
  : "";


  const accountData = useGetUserAccount({member: !external});
  




  const handleAddNewCard = async (tokenId = "") => {
    setAddCardLoading(true);
    const token = localStorage.getItem("token");
    
    const cardsDetails = {
      object: "card",
      number: newCardDetails?.["cardDetails.number"],
      exp_month: newCardDetails?.["cardDetails.exp_month"],
      exp_year: newCardDetails?.["cardDetails.exp_year"],
      cvc: newCardDetails?.["cardDetails.cvc"],
      zip: newCardDetails?.["cardDetails.zip"],
    };
    if(!checkIsStripe()) {

      if (
        (!cardsDetails?.number ||
          !cardsDetails?.exp_year ||
          !cardsDetails?.cvc)
        ) {
      return enqueueSnackbar("please fill all mandatory fields", { variant: "error" });
    }

    if(!cardsDetails?.zip?.length) {
      return enqueueSnackbar(`Please enter ${postalCodeLabel()}.`, { variant: "error" });
    }

    const validZipCode = postalCodeFormat(cardsDetails?.zip);
    if (!validZipCode) {
      enqueueSnackbar(`Invalid ${postalLabel}`, { variant: "info" });
    }

   }
    const bodyData = (checkIsStripe() && tokenId) ? { token: tokenId } : { cardsDetails };

    FinanceService.addCreditCard(token, accountID, bodyData)
      .then((res) => {
        fetchCards();
        setAddNewCard(false);
        setNewCardDetails({});
      })
      .catch((err) => {
      console.log('err100 :', err);
        enqueueSnackbar(err?.data?.message, { variant: "error" });
      }).finally(() => {
        setAddCardLoading(false);
      }
      );
  };

  async function fetchCards() {
    setCardLoading(true);
    const token = localStorage.getItem("token");
    await FinanceService.getCreditCardsByAccountId(token, accountID)
      .then((res) => {
        setCardLoading(false);
        setCardList(res?.card?.data);
      })
      .catch((err) => {
        setCardLoading(false);
        enqueueSnackbar(err?.data?.message, { variant: "error" });
      });
  }
  useEffect(() => {
    if (!accountData) return;
    accountID = !external ? accountData?.user?.permissions?.linked_account?._id : "";
    if (!external) {
      fetchCards();
    }
    window.onload = function () {
      push("/donation");
    };
    return () => {
      window.onload = null;
    };
  }, [accountData]);


  const handleGetStripeToken = async () => {
    const tokenId = await addCardStripeRef?.current?.handleGetToken();
    if (tokenId && tokenId !== null) {
      handleDonate(tokenId)
    }
  }

  const handleSubmitDonation = async () => {
    const token = !external;
    if (checkIsStripe() && !token && !billMyAccount) {
      handleGetStripeToken()
      return
    }
    handleDonate()
  }




  const getLedgerSubLedger = (ledger) => {
    let Ledger;
    let SubLedger;
    if (ledger?.cause?.name) {
      Ledger = {
        id: ledger?.cause?.ledger_id,
        name: ledger?.cause?.name,
        account_receivable: ledger?.cause?.ledger_account_receivable,
      };
      SubLedger = {
        id: ledger?.cause?.sub_ledger_id,
        name: ledger?.cause?.sub_ledger_name,
        account_receivable: ledger?.cause?.sub_account_receivable,
      };
    } else {
      Ledger = {
        id: ledger?.cause?._id,
        name: ledger?.cause?.ledger_name,
        account_receivable: ledger?.cause?.account_receivable,
      };
    }

    return { Ledger, SubLedger };
  }


  const handleDonate = (tokenId = "") => {
    const token = localStorage.getItem("token"); // login users
    const externalToken = localStorage.getItem("externalToken"); // non login users

    if (localStorage.getItem("hasPayment") === "false") {
      enqueueSnackbar("This user cannot donate", { variant: "error" });
      return;
    }
    const isStripeAccount = checkIsStripe();
    if (
      external && !billMyAccount &&
      !isStripeAccount &&
      (!formik?.values?.card_number ||
        !formik?.values?.expiry ||
        !formik?.values?.cvc)
    ) {
      enqueueSnackbar("please fill all mandatory fields", { variant: "error" });
      return;
    }

    
    if(!billMyAccount && external && !isStripeAccount && !formik?.values?.zip?.length) {
      return enqueueSnackbar(`Please enter ${postalCodeLabel()}.`, { variant: "error" });
    }


    const validZipCode = postalCodeFormat(formik?.values?.zip);
    if (!billMyAccount && external && !isStripeAccount && !validZipCode) {
       enqueueSnackbar(`Invalid ${postalLabel}`, { variant: "info" });
    }
    const data = {
      isUser: !external ? true : false,
      accountDetails: !external
        ? undefined
        : {
          first_name: formik?.values?.first_name,
          last_name: formik?.values?.last_name,
          primary_email_address: formik?.values?.receipt_email,
          addresses: [
            {
              location: {
                formattedAddress:
                  formik?.values?.addressInfo?.formattedAddress,
                place_id: formik?.values?.addressInfo?.place_id,
              },
              city: formik?.values?.city,
              state: formik?.values?.state,
              zip_code: formik?.values?.zip_code,
              addressLine2: formik?.values?.address2 ?? undefined,
            },
          ],
        },
      Account: !external
        ? {
          id: accountID,
          name: localStorage.getItem("accountName"),
        }
        : undefined,
      organization: external ? localStorage.getItem("orgId") : undefined,
      cardsDetails: (!checkIsStripe() && external && !billMyAccount)
        ? {
          number: formik?.values?.card_number,
          exp_month: formik?.values?.expiry?.split("/")[0],
          exp_year: formik?.values?.expiry?.split("/")[1],
          cvc: formik?.values?.cvc,
          zip: formik?.values?.zip,
        }
        : undefined,
      token: (external && checkIsStripe()  && !billMyAccount) ? tokenId : undefined,
      cardId: (!external && !billMyAccount) ? formik?.values?.card_number : undefined,
      transactions: formik?.values?.causeInfo?.map((item,index) => ({
        Ledger: getLedgerSubLedger(item).Ledger,
        SubLedger: getLedgerSubLedger(item).SubLedger,
        isRecurring: item?.contribution?.isRecurring,
        frequency:
          item?.contribution?.isRecurring &&
            item?.contribution?.frequency === "yearly"
            ? "year"
            : item?.contribution?.isRecurring &&
              item?.contribution?.frequency === "monthly"
              ? "month"
              : undefined,
        charge_type: "donation",
        total_amount: item?.amount,
        note: item?.note ? item?.note : undefined,
      })),
      ...(formik?.values?.tributeCheck ? {tribute_info: {
        type: formik?.values?.tribute_type?.name,
        name: formik?.values?.donor_name,
        message: formik?.values?.note ? formik?.values?.note : undefined,
        acknowledgment: formik?.values?.showAcknowledgement,
        recipients: formik?.values?.showAcknowledgement ? formik?.values?.acknowledgements : undefined,
      }} : {}),
      coverFee: !billMyAccount ? enableCoverConvenienceFee : false,
      billMyAccount: billMyAccount,
    };
    const makeDonation = async () => {
      setLoading(true);
      await FinanceService.makeDonation(data, external ? externalToken : token)
        .then((res) => {
          setLoading(false);
          formik.resetForm();
          enqueueSnackbar("Donation Done successfully!", {
            variant: "success",
          });
          if (!external) {
            formik.setFieldValue({})
            // window.location.replace(
            //   `${window.location.origin}/family-member-table`
            // );
            // window.location.reload();
            formik.setFieldValue('resultPage', 'success');
          } else {
            // localStorage.getItem("shul_website")
            //   ? localStorage.getItem("shul_website") !== ""
            //     ? window.location.replace(localStorage.getItem("shul_website"))
            //     : push("/donation")
            //   : push("/donation");
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          setLoading(false);
          enqueueSnackbar(err?.data?.message, { variant: "error" });
        });
    };
    makeDonation();
  };


  const handleExpiryChange = (text) => {
    formik.setFieldValue("expiry", text);
    if (text.length > 2) {
      if (text[2] !== "/") {
        formik.setFieldValue(
          "expiry",
          text.substring(0, 2) + "/" + text.substring(2, 4)
        );
      }
    }
  };
  const onCardDetailChange = (e) => {
    setNewCardDetails({
      ...newCardDetails,
      [e.target.name]: e.target.value,
    });
  };





  const calculateTotal = () => {
    const total = formik?.values?.causeInfo?.reduce(
      (acc, c) => acc + +c?.amount,
      0
    )
      ? thousands(
        (+formik?.values?.causeInfo?.reduce(
          (acc, c) => acc + +c?.amount,
          0
        )).toFixed(2)
      )
      : "0.00"
    return total;
  }
  const convenienceFeeAmount = enableCoverConvenienceFee ? convenienceFeeInfo?.type === "%"
    ? (calculateTotal().replace(/,/g, '') * convenienceFeeInfo?.amount) / 100 :
    convenienceFeeInfo?.amount : 0


  const calculateTotalPayment = () => {
    const totalPayment = calculateTotal().replace(/,/g, '')
    if (enableCoverConvenienceFee && !billMyAccount) {
      return numberFormat({number:
        (+totalPayment + +convenienceFeeAmount), decimal: 2, style: 'currency'}
      )
    } else {
      return numberFormat({number:
        (+totalPayment), decimal: 2, style: 'currency'}
      )
    }
  }
  const checkIsStripe = () => {
    return provider === "Stripe" && publicKey;
  }

  const checkIfAnyCauseRecurringPayment = useCallback(() => {
      const causes = formik.values?.causeInfo;
      if(!causes) return false;
      return causes.some(cause => cause?.contribution?.isRecurring);
  },[formik.values?.causeInfo]);

  const isRecurringPayment = useMemo(() => checkIfAnyCauseRecurringPayment(), [checkIfAnyCauseRecurringPayment]);

  return (
    <>
     {formik.values?.resultPage !== "" ? (
      <div style={{marginTop:20,marginBottom:20}}>
        <PaymentResultPage
          result={formik.values?.resultPage}
          formik={formik}
          showThanksMsg={billMyAccount || false}
        />
        </div>
      ) : (
        <>
      <div className="donation__content">
        <DonationStepper activeStep={2} />
        <div className="DonationPay__content w-100">
          <Grid container>
          {enableBillMyAccount && (
            <RadioButtons
                value={billMyAccount ? "bill-my-account" : "credit-card"}
                name={"address-labels"}
                row={true}
                onChange={(value) => {
                  setBillMyAccount(value === "bill-my-account");
                }}
                defaultValue={"credit-card"}
                size={"small"}
                options={[
                  {
                    label: "Pay with credit card",
                    value: "credit-card"
                  },
                  {
                    label: "Bill my account",
                    value: "bill-my-account",
                    disabled: isRecurringPayment,
                    showTooltip: isRecurringPayment,
                    tooltip: "Bill my account is not available for recurring donation",
                  }
                ]}
              />
            )}
            {!billMyAccount && (
              <>
              {!external ? (
                <Grid item xs={12}>
                  <div className="card_num_field">
                    {cardLoading ? (
                      <CircularProgress />
                    ) : cardList?.length === 0 ? (
                      <div className="cards-radio font-16">
                        <p>No cards found</p>
                        <div
                          className="add-new-payment-info my-5"
                          onClick={() => setAddNewCard(!addNewCard)}
                        >
                          <span className="add-icon">
                            <AddIcon />
                          </span>
                          <span className="add">Add New Card</span>
                        </div>
                        {addNewCard && (
                          <>
                            {checkIsStripe() ? (
                              <Grid item xs={12}>
                                <StripeCard
                                  loading={addCardLoading}
                                  setLoading={setAddCardLoading}
                                  saveCard={true}
                                  ref={addCardStripeRef}
                                  onSaveCard={handleAddNewCard}
                                />
                              </Grid>
                            ) : (
                              <div className="row">
                                <div className="col-sm-4 pr-0">
                                  <InputField
                                    mandatory
                                    {...{
                                      formik,
                                      title: "Card Number",
                                      name: "cardDetails.number",
                                      value: newCardDetails?.["cardDetails.number"],
                                      type: "string",
                                      maxLength: 16,
                                      minlength: 16,
                                      pattern: "[0-9]{10}",
                                      placeholderIcon: (
                                        <CreditCardOutlinedIcon
                                          className="card-icon"
                                          fontSize="large"
                                        />
                                      ),
                                    }}
                                    id="myInput"
                                    onKeyPress={(event) => {
                                      // handleOnload();
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    // onpaste={handleOnload}
                                    handleChange={(event) => {
                                      onCardDetailChange(event, "number");
                                    }}
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <InputField
                                    mandatory
                                    {...{
                                      formik,
                                      title: "Expiry Month",
                                      name: `cardDetails.exp_month`,
                                      placeholder: "MM",
                                      minlength: "1",
                                      maxLength: "2",
                                      type: "string",
                                      value:
                                        newCardDetails?.["cardDetails.exp_month"],
                                    }}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    handleChange={(event) =>
                                      onCardDetailChange(event, "exp_month")
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <InputField
                                    mandatory
                                    {...{
                                      formik,
                                      title: "Expiry Year",
                                      type: "string",
                                      minlength: "4",
                                      maxLength: "4",
                                      name: `cardDetails.exp_year`,
                                      placeholder: "YYYY",
                                      value: newCardDetails?.["cardDetails.exp_year"],
                                    }}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    handleChange={(event) =>
                                      onCardDetailChange(event, "exp_year")
                                    }
                                  />
                                </div>
                                <div className="col-sm-2 px-0">
                                  <InputField
                                    mandatory
                                    {...{
                                      formik,
                                      title: "CVC",
                                      type: "string",
                                      minlength: "3",
                                      maxLength: "4",
                                      name: `cardDetails.cvc`,
                                      placeholder: "3-4 digits",
                                      value: newCardDetails?.["cardDetails.cvc"],
                                    }}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    handleChange={(event) =>
                                      onCardDetailChange(event, "cvc")
                                    }
                                  />
                                </div>
                                <div className="col-sm-4 mb-5 pb-5 pt-5">
                                  <MyButton
                                    content={"Save Card"}
                                    style={{ width: "120 !important" }}
                                    onClick={handleAddNewCard}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="cards-radio font-16">
                          {cardList?.map((item, index) => (
                            <div
                              data-id={item.id}
                              key={item.id}
                              onClick={() => {
                                if (formik.values.card_number === item.id) {
                                  formik.setFieldValue("card_number", "");
                                } else {
                                  formik.setFieldValue("card_number", item?.id);
                                }
                              }}
                              className={
                                formik?.values?.card_number === item.id
                                  ? "selected-card"
                                  : ""
                              }
                              style={{ width: "fit-content" }}
                            >
                              <p>
                                {`${item.country ? item.country : ""} ${item.brand ? item.brand : ""
                                  } (${item.last4 ? item.last4 : ""}), Exp: ${item.exp_month
                                  }/${item.exp_year}`}
                                <div className="checkbox checkbox-lg checkbox-single mr-4">
                                  <input
                                    type="checkbox"
                                    name={`card_number${index}`}
                                    id={index}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "card_number",
                                        e.target?.checked ? item?.id : ""
                                      );
                                    }}
                                    checked={
                                      formik?.values?.card_number === item?.id
                                    }
                                  />
                                  <span />
                                </div>
                              </p>
                            </div>
                          ))}
                        </div>
                        <div className="card__header">
                          <div
                            className="add-new-payment-info my-5"
                            onClick={() => setAddNewCard(!addNewCard)}
                            >
                            <span className="add-icon">
                              <AddIcon />
                            </span>
                            <span className="add">Add New Card</span>
                            </div>
                            {addNewCard && (
                            <div
                              className="cancel-add-button"
                              >
                              <BackSvgComponent
                                onClick={() => setAddNewCard(false)}
                                role="button"
                              />
                            </div>
                            )}
                        </div>
                        {addNewCard && (
                          <>
                            {checkIsStripe() ? (
                              <Grid item xs={12}>
                                <StripeCard
                                  loading={addCardLoading}
                                  setLoading={setAddCardLoading}
                                  saveCard={true}
                                  ref={addCardStripeRef}
                                  onSaveCard={handleAddNewCard}
                                />
                              </Grid>
                            ) : (
                              <div className="row">
                                <div className="col-sm-4">
                                  <InputField
                                    mandatory
                                    {...{
                                      formik,
                                      title: "Card Number",
                                      name: "cardDetails.number",
                                      value: newCardDetails?.["cardDetails.number"],
                                      type: "string",
                                      maxLength: 16,
                                      minlength: 16,
                                      pattern: "[0-9]{10}",
                                      placeholderIcon: (
                                        <CreditCardOutlinedIcon
                                          className="card-icon"
                                          fontSize="large"
                                        />
                                      ),
                                    }}
                                    id="myInput"
                                    onKeyPress={(event) => {
                                      // handleOnload();
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    // onpaste={handleOnload}
                                    handleChange={(event) => {
                                      onCardDetailChange(event, "number");
                                    }}
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <InputField
                                    mandatory
                                    {...{
                                      formik,
                                      title: "Expiry Month",
                                      name: `cardDetails.exp_month`,
                                      placeholder: "MM",
                                      minlength: "1",
                                      maxLength: "2",
                                      type: "string",
                                      value:
                                        newCardDetails?.["cardDetails.exp_month"],
                                    }}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    handleChange={(event) =>
                                      onCardDetailChange(event, "exp_month")
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <InputField
                                    mandatory
                                    {...{
                                      formik,
                                      title: "Expiry Year",
                                      type: "string",
                                      minlength: "4",
                                      maxLength: "4",
                                      name: `cardDetails.exp_year`,
                                      placeholder: "YYYY",
                                      value: newCardDetails?.["cardDetails.exp_year"],
                                    }}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    handleChange={(event) =>
                                      onCardDetailChange(event, "exp_year")
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <InputField
                                    mandatory
                                    {...{
                                      formik,
                                      title: "CVC",
                                      type: "string",
                                      minlength: "3",
                                      maxLength: "4",
                                      name: `cardDetails.cvc`,
                                      placeholder: "3-4 digits",
                                      value: newCardDetails?.["cardDetails.cvc"],
                                    }}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    handleChange={(event) =>
                                      onCardDetailChange(event, "cvc")
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <InputField
                                    mandatory
                                    {...{
                                      formik,
                                      title: postalLabel,
                                      type: "string",
                                      name: `cardDetails.zip`,
                                      placeholder: "12345",
                                      value: newCardDetails?.["cardDetails.zip"],
                                    }}
                                    handleChange={(event) =>
                                      onCardDetailChange(event, "zip")
                                    }
                                  />
                                </div>
                                <div className="col-sm-4 mb-5 pb-5 pt-5">
                                  <MyButton
                                    content={"Save Card"}
                                    style={{ width: 120 }}
                                    onClick={handleAddNewCard}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
              ) : (
                <>
                  {checkIsStripe() ? (
                    <Grid item xs={12}>
                      <StripeCard
                        loading={loading}
                        setLoading={setLoading}
                        saveCard={false}
                        ref={addCardStripeRef}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <div className="card_num_field">
                          <InputField
                            title="Card Number"
                            mandatory
                            type="string"
                            minLength="16"
                            maxLength="16"
                            {...{
                              // formik,
                              name: "card_number",
                              placeholder: "Card Number",
                              value: formik?.values?.card_number,
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            handleChange={(e) => {
                              formik.setFieldValue("card_number", e?.target?.value);
                            }}
                            inputClassName="donation__input"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="exp_pay_field">
                          <InputField
                            title="Expiry"
                            mandatory
                            type="string"
                            maxLength="7"
                            {...{
                              // formik,
                              name: "expiry",
                              placeholder: "Expiry (MM/YY)",
                              value: formik?.values?.expiry,
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            handleChange={(e) => handleExpiryChange(e?.target?.value)}
                            inputClassName="donation__input"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="cvc_pay_field">
                          <InputField
                            title="CVC"
                            mandatory
                            type="string"
                            minLength="3"
                            maxLength="4"
                            {...{
                              // formik,
                              name: "cvc",
                              placeholder: "3-4 digits",
                              value: formik?.values?.cvc,
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            handleChange={(e) => {
                              formik.setFieldValue("cvc", e?.target?.value);
                            }}
                            inputClassName="donation__input"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="zip_pay_field">
                        <InputField
                            title={postalLabel}
                            mandatory
                            type="string"
                            {...{
                              // formik,
                              name: "Zip",
                              placeholder: "12345",
                              value: formik?.values?.zip,
                            }}
                            handleChange={(e) => {
                              formik.setFieldValue("zip", e?.target?.value);
                            }}
                            inputClassName="donation__input"
                          />
                        </div>
                      </Grid>
                    </>
                  )}


                </>
              )}
              </>
            )}

            {(coverConvenienceFee && !billMyAccount) && (
              <Grid item xs={12}>
                <div
                  style={{
                    height: "48px",
                    marginTop: "15px",
                  }}
                >
                  <label
                    htmlFor="coverConvenienceFee"
                    className="d-flex align-items-center h-100 checkbox__label"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    <MyCheckbox
                      handlechange={(e) =>
                        setEnableCoverConvenienceFee(e?.target?.checked)
                      }
                      checked={enableCoverConvenienceFee}
                      name="checkboxHonor"
                      id="coverConvenienceFee"
                    />
                    I’d like to pay the convenience fee so that the synagogue gets 100% of my payment
                  </label>
                </div>
              </Grid>
            )}
            {/* summary */}
            <Grid item xs={12}>
              <div className="summaryPayText">Summary</div>
            </Grid>
            {formik?.values?.causeInfo?.map((item, idx) => (
              <>
                <Grid item xs={12} key={idx}>
                  <div className="couses__amount d-flex align-items-center justify-content-between">
                    <div>{item?.cause?.sub_ledger_name || item?.cause?.ledger_name}</div>
                    <div className="d-flex align-items-center">
                      {item?.contribution?.isRecurring && (
                        <span className="recurring_value">
                          <ClockWiseRefresh className="mr-1" /> Recurring{" "}
                          {item?.contribution?.frequency}
                        </span>
                      )}
                      {numberFormat({number:+item?.amount, decimal: 2, style: 'currency'})}
                    </div>
                  </div>
                </Grid>
              </>
            ))}
            {(enableCoverConvenienceFee && !billMyAccount) && (
              <Grid item xs={12}>
                <div className="convenience__fee d-flex align-items-center justify-content-between">
                  <div>Convenience fee</div>
                  <div className="d-flex align-items-center">
                    {numberFormat({number: convenienceFeeAmount, decimal: 2, style: 'currency'})}
                  </div>
                </div>
              </Grid>
            )}
            <hr
              style={{ width: "100%", color: "#404253", marginTop: "25px" }}
            />
            <Grid item xs={12}>
              <div className="total__amount d-flex alien-items-center justify-content-between">
                <div>Total</div>
                <div>
                  {calculateTotalPayment()}
                </div>
              </div>
            </Grid>           
          </Grid>
        </div>
      <div className="donation-pay-buttons">
        <button
          className="btn btn-primary prev__btn"
          onClick={() => {
            push(external ? "/donation/details" : "/donation-view/details");
          }}
        >
          Previous
        </button>
        <button
          className="btn btn-primary donate__btn"
          onClick={handleSubmitDonation}
          disabled={loading}
          style={{width: "auto"}}
        >
          {billMyAccount ? "Bill My Account" : "Donate"}
          {loading && <span className="mx-3 spinner spinner-white"></span>}
        </button>
      </div>
      </div>
      </>
    )}
    </>
  );
};

export default DonationPay;
