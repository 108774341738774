import { useAuth0 } from "@auth0/auth0-react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import AuthService from "Lib/API/Auth";
import EvevntService from 'Lib/API/Events';
import {
  getLocalStorageRegistration,
  setLocalStorageRegistration,
} from "_metronic/_helpers";
import { setLocalizationMoment } from "helper/dateFormat";
import { useSnackbar } from "notistack";
import EventsCalenderLink from 'pages/EventsCalendarLink/EventsCalendarLink';
import Header from 'pages/MemberLink/Header/Header';
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { BodyForList } from "./BodyForList";
import {
  Content,
  HeaderWrapper,
  SpinnerForList,
  Wrapper,
} from "./Theme";
import './index.scss';

export const ListOfEvents = () => {
  const { isAuthenticated, logout,getAccessTokenSilently,isLoading: isAuthenticatedLoading } = useAuth0();
  
    const memberPortal = localStorage.getItem('memberPortal') === 'true' || false;

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([])
    const HeaderTitles = ["EVENT NAME", "EVENT DATE", "REGISTRATION PERIOD", "EVENT TYPE", "NON MEMBER","TOTAL COST"]
    const [count, setCount] = useState([])
    const [page, setPage] = useState(0)
    const [value, setValue] = useState("0");
    const {logoImage} = useSelector((state) => state.organization);

    const Logo = isAuthenticated ? logoImage : localStorage.getItem('logoImgEvent');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getEventList = async (externalToken) => {
    const payload = {
      isMember: !memberPortal,
    };
    try {
      const res = await EvevntService.getEventList(
        externalToken,
        page + 1,
        10,
        localStorage.getItem("eventOrgId"),
        payload
      );

      setEvents(res.payload.data);
      setCount(res?.payload?.pagination?.count);
    } catch (error) {
      enqueueSnackbar(error?.data?.message, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  const generateToken = async () => {
    setLoading(true);
    const subDomain = window.location.hostname.split(".")[0];
    const hostName = subDomain === "localhost" ? "shalom" : subDomain;
    
    try {
      const res = await AuthService.generateToken(hostName);
      const token = isAuthenticated ? await getAccessTokenSilently() : res?.token
      localStorage.setItem("eventToken", res?.token);
      localStorage.setItem("eventOrgId", res?._id);
      localStorage.setItem("logoImgEvent", res?.logoImg);
      localStorage.setItem('orgName', res?.orgName);
      localStorage.setItem('country', JSON.stringify(res?.country || {}));
      localStorage.setItem('memberPortal', res?.memberPortal || false);

      setLocalizationMoment(res?.country?.local || "en");
      document.title = `${res?.orgName}`;
      getEventList(token);
    } catch (error) {
      enqueueSnackbar(error?.data?.message, {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    localStorage.removeItem('redirectUrl');
}, [])
  useEffect(() => {
    if(isAuthenticatedLoading) return;
    localStorage.setItem("EventLayout", true);
    setLocalStorageRegistration({
      activeStep: 0,
      registerFormId: getLocalStorageRegistration()?.registerFormId,
      RegisterData: getLocalStorageRegistration()?.RegisterData,
      RegisterRequiredFelids: getLocalStorageRegistration()
        ?.RegisterRequiredFelids,
    });
    generateToken();
  }, [page,isAuthenticated,isAuthenticatedLoading]);
    const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        textTransform: 'none',
     
      }));

      const handleReserveLogin = () => {
        if(isAuthenticated)  {
            handleReserveLogout();
            return;
        }
        localStorage.setItem("redirectUrl", `/events`);
        const originUrl = window.location.origin;
        logout({ returnTo: originUrl });
    }

    const handleReserveLogout =  () => {
     logout({ 
        localOnly: true,
        openUrl: false
      });
    }

      
    const reserveLoginButton = useMemo(() => isAuthenticated ? "Logout" : "Login" , [isAuthenticated]);
    return (
        <>
            <Wrapper>
                <HeaderWrapper>
                  <Header Logo={Logo} reserveLoginButton={memberPortal ? reserveLoginButton : false} handleReserveLogin={handleReserveLogin}/>
                </HeaderWrapper>
                <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider',marginLeft:12 }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <AntTab label="Events List View" value="0" />
                            <AntTab label="Events Calender View" value="1" />
                            </Tabs>
                        </Box>
                        <TabPanel value={"0"}>
                        <Content>
                            <div className="d-flex justify-content-left h-40px w-100">
                                {/* <Title >List Of Events</Title> */}
                            </div>
                            <div className="family-table-container p-7 pb-15">
                                <div className="home-table-container mt-0 no-box-shadow">
                            {loading ? <SpinnerForList><PulseLoader color={'#2555EF'} size={'15px'} /></SpinnerForList> :

                                <TableContainer component={Paper} >
                                    <Table style={{ width: '-webkit-fill-available' }}>
                                        <TableHead className="charges-table" >
                                            <TableRow>
                                                {HeaderTitles.map((item, index) => (
                                                    <TableCell className="charged-value" align="left" key={index}>
                                                        {item}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            className="tran-table-body"
                                            style={{
                                                height: events?.length === 10 ? "500px" : "",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <BodyForList events={events} />
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }

                        </div>
                    </div>
                    {events?.length !== 0 && (
                        <div className="pagination-custom mt-2">
                            <Pagination
                                count={Math.ceil(count / 10)}
                                color="primary"
                                variant="outlined"
                                onChange={(event, value) => {
                                    setPage(value - 1);
                                }}
                            />
                        </div>
                    )}
                         </Content>
                        </TabPanel>
                        <TabPanel value={"1"} >
                           <EventsCalenderLink withoutTitle={true} width={'90%'}/>
                        </TabPanel>
                    </TabContext>
                
            </Wrapper>

        </>
    );
};
