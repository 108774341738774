import * as React from "react"

function EditSvgComponent (props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.805}
      height={18.711}
      {...props}
    >
      <g fill="#2555ef">
        <path
          data-name="Path 4296"
          d="M17.357 11.742a.468.468 0 00-.468.468v4.158a1.406 1.406 0 01-1.405 1.405H2.342a1.406 1.406 0 01-1.405-1.405v-12.2a1.407 1.407 0 011.405-1.405H6.5a.469.469 0 100-.937H2.342A2.344 2.344 0 000 4.164v12.2a2.344 2.344 0 002.342 2.346h13.141a2.344 2.344 0 002.342-2.342V12.21a.468.468 0 00-.468-.468zm0 0"
        />
        <path
          data-name="Path 4297"
          d="M17.639.617a2.108 2.108 0 00-2.981 0L6.302 8.973a.468.468 0 00-.12.206l-1.1 3.967a.468.468 0 00.576.576l3.967-1.1a.468.468 0 00.206-.12l8.355-8.356a2.11 2.11 0 000-2.981zM7.323 9.277l6.839-6.838 2.205 2.2-6.839 6.839zm-.441.884l1.762 1.762-2.437.675zm10.644-6.676l-.5.5-2.206-2.206.5-.5a1.171 1.171 0 011.656 0l.55.55a1.173 1.173 0 010 1.656zm0 0"
        />
      </g>
    </svg>
  )
}

export default EditSvgComponent
