import AuthService from "Lib/API/Auth";
import EvevntService from "Lib/API/Events";
import { ReactComponent as CopyIconGray } from "assets/icons/CopyIconGray.svg";
import { ReactComponent as Instagram } from "assets/icons/InstagramColorful.svg";
import { ReactComponent as Facebook } from "assets/icons/facebookColorful.svg";
import { ReactComponent as Twitter } from "assets/icons/twitter-link.svg";
import { Counter } from "components/Counter/Counter";
import { convertUTCToLocalDate } from "components/UTC/UtcConverter";
import * as calculation from "helper/CalculateEvent";
import { useMobileView } from 'hook/mobileView/useMobileView';
import moment from "moment";
import { useSnackbar } from "notistack";
import { EventContext } from "pages/ExternalLinkEvents/Event";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { setCounterTicket, setTicketTypeList, setTicketTypeVariants } from "services/api/events";
import thousands from "thousands";
import {
    ContactInfo, ContactInfoDetails,
    ContactTitle, CounterWrapper, DashLine, Description, DescriptionText, EventCaption, EventLogin, EventName, EventTitle, EventType, EventTypeDetails, EventTypeName,
    Note,
    PayDetails,
    ReserveAction,
    ReserveButton,
    ReserveLoginButton,
    RightSideContent,
    RightSideWrappr, ShowMoreText, SocialIcons, TicketContainer, TotalPay
} from "./Theme";

import { useAuth0 } from "@auth0/auth0-react";
import cuid from "cuid";
import { formatDate, setLocalizationMoment } from "helper/dateFormat";
import { getCurrency, numberFormat } from "helper/numberFormat";
import TicketCards from "./TicketCards";
export const RightSide = () => {
    const { isAuthenticated, logout,getAccessTokenSilently } = useAuth0();
    const currency = getCurrency();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [ticketData, setTicketData] = useState({});
    const [ticketsTypeList, setTicketsTypeList] = useState([]);
    const { numberOfTickets } = useSelector((state) => state?.Events);
    const { eventData } = useSelector((state) => state?.Events);
    const totalPayforAllTickets = eventData?.eventPricing?.total_to_pay * numberOfTickets.length
    const paymentMethod = JSON.parse(localStorage.getItem('paymentMethodEvents')) || {};
    const token = localStorage.getItem('token');
    const { isMobile } = useMobileView()

    const memberPortal = localStorage.getItem('memberPortal') === 'true' || false;

    const totalTicketVariant = ticketData?.variantInfo || [];
    const viewPayDetails = thousands(calculation.calculateTotalPaymentWithCVFee(paymentMethod, eventData, totalTicketVariant, "event", isAuthenticated)) !== '0.00'
        && !eventData.freeEvent
    const checkAndModifyUrl = (url) => {
        // Check if the URL starts with 'http://' or is missing both 'http://' and 'https://'
        if (url.startsWith('http://')) {
            // Replace 'http://' with 'https://'
            return url.replace('http://', 'https://');
        } else if (!url.startsWith('https://')) {
            // If the URL doesn't start with either 'http://' or 'https://', add 'https://' at the beginning
            return `https://${url}`;
        }
        // If the URL already starts with 'https://', no modification needed
        return url;
    };

    const isExpiredClass = () => {
        const startDateDiff = moment().diff(moment(eventData?.eventStartDate), 'days');
        const endDateDiff = moment().diff(eventData?.eventEndDate ? moment(eventData?.eventEndDate) : moment(), 'days');

        return startDateDiff < 0 || endDateDiff > 0
    }
    const params = useParams();
    const dispatch = useDispatch();

    const handleDifferanceDate = () => {
        const date1 = new Date();
        const date2 = new Date(eventData?.eventEndDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays === 1 ? <span>{diffDays} Day  left!</span>
            : <span>{diffDays} Days  left!</span>
    }
    const [isShowMore, setIsShowMore] = useState(true);
    const toggleReadMore = () => setIsShowMore((show) => !show);
    const isExpiredEvent = () => {
        const startDateDiff = moment().diff(moment(eventData?.eventStartDate), 'days');
        const endDateDiff = moment().diff(eventData?.eventEndDate ? moment(eventData?.eventEndDate) : moment(), 'days');

        return startDateDiff < 0 || endDateDiff > 0
    }
    const convenienceFeeAmount = paymentMethod?.convenienceFeeInfo?.type === "%"
        ? (calculation.calculateDiscount(eventData, totalTicketVariant, "event", isAuthenticated)?.totalAfterDiscount * paymentMethod?.convenienceFeeInfo?.amount) / 100 :
        paymentMethod?.convenienceFeeInfo?.amount
    const getToken = async () => {
        try {
            const subDomain = window.location.hostname.split('.')[0];
            const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
            await AuthService.generateToken(hostName).then(res => {
                localStorage.setItem('eventToken', res?.token);
                localStorage.setItem('logoImgEvent', res?.logoImg);
                localStorage.setItem('paymentMethodEvents', JSON.stringify(res?.paymentMethod))
                localStorage.setItem('orgName', res?.orgName);
                localStorage.setItem('country', JSON.stringify(res?.country || {}));
                localStorage.setItem('memberPortal', res?.memberPortal || false);

                setLocalizationMoment(res?.country?.local || "en");

                document.title = `${res?.orgName}`;
            })
        } catch (error) {
            enqueueSnackbar(error?.eventData?.message, {
                variant: 'error',
            });
        }
    }

    const getTicketData = () => {
        if (!ticketData?.variantInfo) return [];
        let data = [];

        ticketData.variantInfo.map((variant) => {
            if (variant.selectedQuantity) {
                data = [...data, {
                    id: ticketData?._id,
                    title: variant.name,
                    numberOfTickets: variant.selectedQuantity,
                }];
            }
        })


        return data;
    }
    const handleReserveButton = async () => {
        if (!numberOfTickets?.length || numberOfTickets?.length > 20 || (!eventData?.allowNonMembers && !isAuthenticated)) return;
        const body = {
            eventId: eventData?._id,
            ...(!eventData?.freeEvent ? { tickets: getTicketData() } : {}),
            ...(eventData?.freeEvent ? { numberOfTickets: numberOfTickets.length } : {})
        }
        setLoading(true)
        try {
            const token = isAuthenticated ? await getAccessTokenSilently() : localStorage.getItem("eventToken")
            await EvevntService.reserveSeats(token, body).then((res) => {
                setLoading(false)
                dispatch(setTicketTypeVariants(ticketData?.variantInfo || []));
                localStorage.setItem("reserveId", res?.result?._id)
                history.push(`/events/${params?.id}/reservation`);
            })
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
            throw error;
        }

    }
    useEffect(() => {
        if (!localStorage.getItem("eventToken")) getToken()
    }, [])
    const handleClickIncrement = () => {
        dispatch(setCounterTicket([...numberOfTickets, cuid()]));
    };
    const handleClickDecrement = () => {
        const removeLastIndex = numberOfTickets?.slice(0, -1)
        if (numberOfTickets.length > 1) dispatch(setCounterTicket(removeLastIndex));

    };


    useEffect(() => {
        let ticketData = {};
        if (eventData?.freeEvent) {
            ticketData = {
                title: "Ticket",
                variantInfo: [
                    {
                        name: "Ticket",
                        infiniteQuantity: true,
                        amount: 0,
                        selectedQuantity: 0,
                        infiniteQuantity: eventData?.eventDetails?.infiniteQuantity || false,
                        quantity: eventData?.eventDetails?.maximumCapacity || undefined,
                    }
                ]
            }
        }
        else {
            if (!eventData?.eventPricing?.prices?.length) return;
            const eventVariantData = eventData?.eventPricing?.prices?.map((item) => {
                if (item?.type === "onRegistration") {
                    const variantInfo = item?.variant ? transformVariantInfoToArray(item?.variantInfo || {}) : [
                        {
                            name: item?.title,
                            infiniteQuantity: item?.infiniteQuantity,
                            amount: item?.amount,
                            selectedQuantity: 0,
                            infiniteQuantity: eventData?.eventDetails?.infiniteQuantity || false,
                            quantity: eventData?.eventDetails?.maximumCapacity || undefined,
                            forMemberOnly: item?.forMemberOnly || false,
                        }
                    ];
                    ticketData = {
                        ...item,
                        variantInfo,
                    }
                }
            });
        }
        setTicketData(ticketData);

    }, [eventData?.eventPricing?.prices]);

    const handleQuantityChange = useCallback((variantIndex, value, type) => {
        const newTicketData = { ...ticketData };
        newTicketData.variantInfo[variantIndex].selectedQuantity = value;
        setTicketData(newTicketData);

        if (type === "increment") {
            handleAddTicketId(variantIndex);
        }

        if (type === "decrement") {
            handleRemoveTicketId(variantIndex, ticketData.variantInfo[variantIndex].id);
        }

    }, [ticketData]);


    const handleAddTicketId = (variantIndex) => {
        const newTicketTypeList = [...ticketsTypeList];
        const ticketId = cuid();
        newTicketTypeList.push({
            id: ticketId,
            name: ticketData?.variantInfo[variantIndex]?.name,
            quantity: ticketData?.variantInfo[variantIndex]?.selectedQuantity,
            amount: ticketData?.variantInfo[variantIndex]?.amount,
            ticketId: ticketData?._id,
        });
        setTicketsTypeList(newTicketTypeList);
        dispatch(setCounterTicket([...numberOfTickets, ticketId]));
        dispatch(setTicketTypeList(newTicketTypeList));

    };

    const handleRemoveTicketId = (variantIndex, ticketId) => {
        const newTicketTypeList = [...ticketsTypeList];
        const ticketTypeIndex = newTicketTypeList.findIndex((ticket) => ticket.id === ticketId);
        newTicketTypeList.splice(ticketTypeIndex, 1);
        setTicketsTypeList(newTicketTypeList);
        const cloneNumberOfTickets = [...numberOfTickets];
        const ticketIndex = cloneNumberOfTickets.indexOf(ticketId);
        cloneNumberOfTickets.splice(ticketIndex, 1);
        dispatch(setCounterTicket(cloneNumberOfTickets));
        dispatch(setTicketTypeList(newTicketTypeList));
    };

    const transformVariantInfoToArray = useCallback((variantInfo) => {
        const newVariantInfo = [];
        if (Object.keys(variantInfo).length === 0) return newVariantInfo;
        for (const key in variantInfo) {
            newVariantInfo.push({
                name: key,
                quantity: variantInfo[key]?.quantity || "",
                infiniteQuantity: variantInfo[key]?.infiniteQuantity || false,
                reserveProduct: variantInfo[key]?.reserveProduct || 0,
                amount: variantInfo[key]?.amount || "",
                selectedQuantity: 0,
                forMemberOnly: variantInfo[key]?.forMemberOnly || false,
            })
        }
        return newVariantInfo;
    }, []);


    const handleReserveLogin = () => {
        if(isAuthenticated)  {
            handleReserveLogout();
            return;
        }
        localStorage.setItem("redirectUrl", `/events/${params?.id}`);
        const originUrl = window.location.origin;
        logout({ returnTo: originUrl });
    }

    const handleReserveLogout =  () => {
     logout({ 
        localOnly: true,
        openUrl: false
      });
    }


    const reserveLoginButton = useMemo(() => isAuthenticated ? "Logout" : eventData?.allowNonMembers ? "Log in for members pricing" : "Log in to sign up" , [isAuthenticated, eventData?.allowNonMembers]);

    
    const eventLogin = useMemo(() => isAuthenticated ? "Logout" : "Login" , [isAuthenticated]);


    return (
        <RightSideWrappr>
            <RightSideContent>
                {memberPortal && (
                    <EventLogin>
                        <ReserveLoginButton onClick={handleReserveLogin}>{eventLogin}</ReserveLoginButton>
                    </EventLogin>
                )}

                <EventTitle>
                    <EventName>{eventData?.eventName}</EventName>
                    <EventCaption>{eventData?.alwaysAvailable ? 'Always Available' : <span>Registration ends  {formatDate({ date: eventData?.eventEndDate, formatType: "defaultWithUTC" })}
                    </span>}
                        {isExpiredEvent() ? '' : !eventData?.alwaysAvailable && <div>{handleDifferanceDate()} </div>}
                        {(!eventData?.allowNonMembers && !isAuthenticated) && <div className="members-only-indicator">Members Only</div>}
                        </EventCaption>

                </EventTitle>

                <EventType>
                    <EventTypeName>
                        {eventData?.eventDetails?.eventType}
                        <span>Event type</span>
                    </EventTypeName>
                    <EventTypeDetails>
                        <div>
                            <span style={{ paddingRight: 10 }}>Date</span> {formatDate({ date: eventData?.date, formatType: "defaultWithUTC" })}
                            <span style={{ paddingRight: 10, paddingLeft: 20 }}>Hour
                            </span>{formatDate({ date: eventData?.date, formatType: "timeUTC" })}
                        </div><br />
                        <span>{eventData?.eventDetails?.eventType === "online" ? "Virtual Address" : "Location"}</span><p>{eventData?.eventDetails?.eventAddress?.formattedAddress}</p>
                    </EventTypeDetails>
                    {!isMobile && <CopyIconGray style={{ position: 'absolute', right: '7%', opacity: '0.3', top: '42%' }} />}
                </EventType>
                <Description>
                    <span>Description</span>
                    <DescriptionText >
                        {eventData?.description?.replace(/&lt;/g, '<') !== '<p></p>\n' && (
                            <div className="drawer_description">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: eventData?.description?.replace(/&lt;/g, '<'),
                                    }}
                                ></p>
                            </div>
                        )}

                    </DescriptionText>
                </Description>
                <ContactTitle>Contact Info</ContactTitle>
                <ContactInfo>
                    <ContactInfoDetails>
                        <span style={{ paddingRight: 10 }}>Name</span><p style={{ width: '150px' }}>{eventData?.eventDetails?.contactInfo?.firstName} {eventData?.eventDetails?.contactInfo?.lastName}</p>
                        {eventData?.eventDetails?.contactInfo
                            ?.showEmail &&
                            <><span style={{ paddingRight: 10, paddingLeft: !isMobile && 20 }}>Email</span><p>{eventData?.eventDetails?.contactInfo?.email}</p></>
                        }
                    </ContactInfoDetails>
                    <SocialIcons>
                        {eventData?.eventDetails?.contactInfo?.facebook &&
                            <a href={`${checkAndModifyUrl(eventData?.eventDetails?.contactInfo?.facebook)}`} rel="noreferrer" >
                                <Facebook />
                            </a>
                        }
                        {eventData?.eventDetails?.contactInfo?.instagram &&
                            <a href={`${checkAndModifyUrl(eventData?.eventDetails?.contactInfo?.instagram)}`} rel="noreferrer" >
                                <Instagram />
                            </a>
                        }
                        {eventData?.eventDetails?.contactInfo?.twitter &&
                            <a href={`${checkAndModifyUrl(eventData?.eventDetails?.contactInfo?.twitter)}`} rel="noreferrer" >
                                <Twitter />
                            </a>
                        }
                    </SocialIcons>
                </ContactInfo>
                <DashLine />
                {!eventData.freeEvent && <PayDetails>
                    {eventData?.eventPricing?.prices?.map((item, index) => item?.type === "onRegistration" ? (
                        !eventData.freeEvent && <>
                            <li key={index}>{item?.title}
                                <span>{item?.variantAmountRange ? item?.variantAmountRange?.replaceAll("$", currency) : numberFormat({ number: +item.amount, decimal: 2, style: "currency" })}
                                </span></li>
                            <Note>{item?.note}</Note>
                            <hr />
                        </>
                    )
                        : <>
                            <li key={index}>{item?.title} {item?.type === 'optional' && `(optional)`}
                                <span>{item?.amount > 0 ? `${numberFormat({ number: +item.amount, decimal: 2, style: "currency" })}`
                                    : item?.variantAmountRange ? item?.variantAmountRange?.replaceAll("$", currency) : "0.00"}</span></li>
                            <Note>{item?.note}</Note>
                            <hr />
                        </>
                    )}
                    {viewPayDetails && eventData?.eventDiscount?.map((item, index) => (
                        <>
                            <li 
                                key={index}
                                className={`d-flex flex-column gap-2 ${(item?.forMemberOnly && !isAuthenticated) ? 'opacity-50' : ''}`}
                            >
                                <div className="d-flex align-items-center justify-content-between">
                                    {item?.discount_name}
                                    {item?.discount_type === 'date' && ` | ${formatDate({ date: item?.start_date, formatType: "defaultWithUTC" })}
                                    -${formatDate({ date: item?.end_date, formatType: "defaultWithUTC" })}`}
                                        <span>
                                            {item?.price_type === 'percentage' ? `${item?.value}%` : `${numberFormat({ number: +item?.value, decimal: 2, style: "currency" })}`}
                                        </span>
                                </div>
                                {(item?.forMemberOnly  && !isAuthenticated) && (
                                    <span style={{ fontSize: '12px' }}>
                                        (Members Only)
                                    </span>
                                )}
                                </li>
                            <hr />
                        </>

                    ))}
                    {viewPayDetails && paymentMethod?.coverConvenienceFee && calculation.calculateDiscount(eventData, totalTicketVariant, "event",isAuthenticated)?.totalAfterDiscount > 0 && (
                        <>
                            <li>Convenience fee
                                <span>
                                    {numberFormat({ number: +convenienceFeeAmount, decimal: 2, style: "currency" })}
                                </span>
                            </li>
                            <hr />
                        </>
                    )}
                    {viewPayDetails &&
                        <>
                            <TotalPay>TOTAL PAYMENT
                                <span>{numberFormat({ number: calculation.calculateTotalPaymentWithCVFee(paymentMethod, eventData, totalTicketVariant, "event",isAuthenticated), decimal: 2, style: "currency" })}
                                </span></TotalPay><hr />
                        </>}

                </PayDetails>}

                <br />



                <TicketContainer>
                    {ticketData?.variantInfo &&
                        <TicketCards
                            ticketList={ticketData}
                            handleQuantityChange={(variantIndex, value, type) => handleQuantityChange(variantIndex, value, type)}
                            totalTicket={numberOfTickets?.length}
                            allowNonMembers={eventData?.allowNonMembers}
                        />
                    }
                </TicketContainer>
            </RightSideContent>
            <ReserveAction>
                {memberPortal ? (
                    <ReserveLoginButton onClick={handleReserveLogin}>
                        {reserveLoginButton}
                    </ReserveLoginButton>
                ) : <div></div>}
                <ReserveButton
                    onClick={handleReserveButton}
                    disabledButton={(!eventData?.allowNonMembers && !isAuthenticated) || isExpiredClass() || !numberOfTickets?.length || numberOfTickets?.length > 20 || loading}
                >
                    {loading ? <PulseLoader color={'#fff'} size={'7px'} /> : 'Reserve Seats'}
                </ReserveButton>
            </ReserveAction>

        </RightSideWrappr>
    );
};
