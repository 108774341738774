import moment from "moment";
import thousands from "thousands";

export const calculateDiscount = (data, numberOfTickets, type = "event",isAuthenticated) => {
    const pricing = type === "form" ? data?.formPricing :type === "school" ? data?.class_pricing:data?.eventPricing;
    const discount = type === "form" ? data?.formDiscount : type === "school" ? data?.class_discount :data?.eventDiscount;
    const allTicketsAmount = pricing?.total_to_pay * numberOfTickets.length;
    let discountAmount = 0;
   const dataforEach = discount?.forEach((item) => {
        if(item?.forMemberOnly && !isAuthenticated) return;
        if (item?.discount_type === 'date') {
            const endDate = moment(item?.end_date).format("MM/DD/YYYY");
            const startDate = moment(item?.start_date).format("MM/DD/YYYY");
            const currentDate = moment(new Date()).format("MM/DD/YYYY");

            if (startDate <= currentDate && endDate >= currentDate) {
                if (item?.price_type === "fixed") {
                    discountAmount += item?.value;
                } else {
                    discountAmount = ((allTicketsAmount * item?.value) / 100) + discountAmount ; 
                }
            } else {
                console.log("The discount date is expired ");
            }
        } else if (item?.discount_type === 'amount' && allTicketsAmount >= item?.amount) {
            if (item?.price_type === "fixed") {
                discountAmount += item?.value;
            } else {
                discountAmount = (allTicketsAmount * item?.value) / 100 + discountAmount;
            }
        } else if (item?.discount_type === 'quantity' && item?.quantity && numberOfTickets?.length >= item?.quantity) {
            if (item?.price_type === "fixed") {
                discountAmount += item?.value;
            } else {
                discountAmount = (allTicketsAmount * item?.value) / 100 + discountAmount;
            }
        }
    });


    const totalAfterDiscount = allTicketsAmount > 0 ? (+allTicketsAmount - +discountAmount).toFixed(2) : "0.00"
    return {
        totalAfterDiscount,
        discountTotal: discountAmount < 0 ? `-$${(Math.abs(discountAmount)).toFixed(2)}` : `-$${(discountAmount).toFixed(2)}`
    };
};
export const calculateTotalPayment = (paymentMethod,data,numberOfTickets,type,isAuthenticated) => {
    const totalPayment = calculateDiscount(data,numberOfTickets, type,isAuthenticated)?.totalAfterDiscount?.replace(/,/g, '')
    return (+totalPayment).toFixed(2)
}    

export const calculateTotalPaymentWithCVFee = (paymentMethod,data,numberOfTickets,type,isAuthenticated) => {
    console.log('calculateTotalPaymentWithCVFee', paymentMethod,data,numberOfTickets,type,isAuthenticated)
    const totalPayment = calculateDiscount(data,numberOfTickets,type)?.totalAfterDiscount?.replace(/,/g, '')
    console.log('totalPayment', totalPayment)
    if(paymentMethod?.coverConvenienceFee && totalPayment > 0) {
        const convenienceFeeAmount = paymentMethod?.convenienceFeeInfo?.type === "%" 
        ? (totalPayment * paymentMethod?.convenienceFeeInfo?.amount) / 100 : 
        paymentMethod?.convenienceFeeInfo?.amount
        return (+totalPayment + +convenienceFeeAmount).toFixed(2)
    } else {
        return (+totalPayment).toFixed(2)
    }
}   

