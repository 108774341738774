import { InputField, LabelField, MyRadioGroup } from "components";
import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearch } from "hook";
import { AsyncAutoCompleteField } from "components/autoCompleteField/AsyncAutoField";
import AuthService from "Lib/API/Auth";
import MemberService from "Lib/API/Member";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";

import "./index.scss";

const AddNewUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .matches(/^(?![\s-])[\w\s-]+$/, "Name can only contain letters.")
    .max(40)
    .required("Required"),
  last_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required("Required"),
  permission: Yup.string().required("Required"),
  account: Yup.string().required("Required"),
});

const AddNewUser = ({ setOpen, refresh, setRefresh }) => {
  const [search, setSearch] = useState(undefined);
  const { getAccessTokenSilently } = useAuth0();
  const { searchAction } = useSearch({ action: setSearch });
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [contactsList, setContactsList] = React.useState([]);

  const userId = localStorage.getItem("userId");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      account: userId ? userId : "",
      permission: "member",
    },
    validationSchema: AddNewUserSchema,
    onSubmit: async (values) => {
      setSubLoading(true);
      const token = await getAccessTokenSilently();
      const input = {
        first_name: values.first_name,
        last_name: values.last_name,
        account_id: formik?.values?.account ? values.account : values.account,
        account_permission:
          values.permission === "member" ? "family_member" : "admin",
      };
      await AuthService.addNewMember(input, token)
        .then((res) => {
          setSubLoading(false);
          setOpen(false);
          setRefresh(!refresh);
          toastr.success("success", "Created New Member Successfulyy");
        })
        .catch((err) => {
          setSubLoading(false);
          console.log(err, "err");
          toastr.error('Error', err?.data?.message);
        });
    },
  });

  useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);
      const token = await getAccessTokenSilently();
      await MemberService.getContacts(token, search).then((res) => {
        if (res?.contacts) {
          res.contacts.map((item) => {
            item.id = item.member_id;
            item.label = item?.first_name + " " + item?.last_name;
            item.value = item._id;
            return item;
          });
          setContactsList(res?.contacts);
        }
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        toastr.error('Error', err?.data?.message);
      });
    }

    if (!formik?.values?.account) fetchMyAPI();
  }, [search]);

  return (
    <div className="add-new-user">
      <div className="px-40">
        <p className="new-user-header mb-5">Add a family member</p>
        <InputField
          mandatory={true}
          {...{
            formik,
            title: "First Name",
            name: "first_name",
            placeholder: "David",
            checkValidation: true,
          }}
        />
        <InputField
          mandatory={true}
          {...{
            formik,
            title: "Last Name",
            name: "last_name",
            placeholder: "Cohen",
            checkValidation: true,
          }}
        />
        {!formik?.values?.account && (
          <div className="sm-12">
            <LabelField title="Account" mandatory={true} />
            <AsyncAutoCompleteField
              mandatory={true}
              {...{
                formik,
                name: "account",
              }}
              error={formik?.errors["account"]}
              touched={formik?.touched["account"]}
              onInputChange={(text) => searchAction(text)}
              classNamePrefix="react-select"
              options={contactsList}
              isLoading={loading}
              handleChange={(e) => formik.setFieldValue("account", e)}
              value={formik.values.account}
            />
          </div>
        )}
        <div className="py-5">
          <LabelField title="Account permission" />
          <MyRadioGroup
            row
            handleChange={(val) =>
              formik.setFieldValue &&
              formik.setFieldValue("permission", val.target.value)
            }
            value={formik.values.permission}
            name="permission"
            className="my-5"
            formsControls={[
              { value: "member", label: "Member" },
              { value: "admin", label: "Admin" },
            ]}
          />
        </div>
        <div
          className="form-group"
          style={{ textAlign: "center", marginBottom: "0px" }}
        >
          <button
            disabled={!formik.isValid}
            type="submit"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            onClick={() => formik.handleSubmit()}
          >
            <span style={{ fontSize: 13, fontWeight: "600" }}>
              Add to family
            </span>
            {subLoading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddNewUser;
