import * as Yup from "yup";

const NewChargeSchema = Yup.object().shape({
  account_name: Yup.object().shape({
    id: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
  }),
  values: Yup.array().of(
    Yup.object().shape({
      charge_type: Yup.string().oneOf([
        null,
        "charge",
        "donation",
        "credit on account",
      ]),
      is_splitted: Yup.boolean(),
      payment_date: Yup.date().required("Required"),
      due_date: Yup.date().required("Required"),
      Ledger: Yup.object().shape({
        id: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
      }),
      discount_type: Yup.string().oneOf([null, "%", "$"]),
      tax_type: Yup.string().oneOf([null, "%", "$"]),
      tax: Yup.number(),
      discount: Yup.number(),
      total_amount: Yup.number().required("Required"),
    })
  ),
});

export default NewChargeSchema;
