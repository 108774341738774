import { useAuth0 } from "@auth0/auth0-react";
import { Table, TableBody, TableCell, TableContainer, TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@mui/material/Paper";
import AuthService from 'Lib/API/Auth';
import MemberService from 'Lib/API/Member';
import { getLocalStorageRegistration, setLocalStorageRegistration } from '_metronic/_helpers';
import { setLocalizationMoment } from "helper/dateFormat";
import { useSnackbar } from 'notistack';
import Header from "pages/MemberLink/Header/Header";
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { PulseLoader } from 'react-spinners';
import { BodyForList } from './BodyForList';
import { Content, HeaderWrapper, SpinnerForList, Title, Wrapper } from './Theme';

export const ListOfClasses = () => {
    const { isAuthenticated, logout,getAccessTokenSilently,isLoading: isAuthenticatedLoading } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [classes, setClasses] = useState([])
    const HeaderTitles = ["CLASS NAME", "DATE", "REGISTRATION", "AGE RANGE", "TOTAL COST"]
    const [count, setCount] = useState([])
    const [page, setPage] = useState(0)
    const {logoImage} = useSelector((state) => state.organization);

    const Logo = isAuthenticated ? logoImage : localStorage.getItem('logoImgSchool');
    const memberPortal = localStorage.getItem('memberPortal') === 'true' || false;


    const getClassesList = async (externalToken) => {
        const payload = {
            isMember: !memberPortal
          };
        try {
            const res = await MemberService.getClassesList(externalToken, page + 1, 10, localStorage.getItem('orgId'), payload)
            setClasses(res.payload.data)
            setCount(res?.payload?.pagination?.count);
        } catch (error) {
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };
    const generateToken = async () => {
        setLoading(true);
        const subDomain = window.location.hostname.split('.')[0];
        const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
        try {
            const res = await AuthService.generateToken(hostName);
            const token = isAuthenticated ? await getAccessTokenSilently() : res?.token
            localStorage.setItem('externalToken', res?.token);
            localStorage.setItem('orgId', res?._id);
            localStorage.setItem('logoImgSchool', res?.logoImg);
            localStorage.setItem('orgName', res?.orgName);
            localStorage.setItem('country', JSON.stringify(res?.country || {}));
            localStorage.setItem('memberPortal', res?.memberPortal || false);

            setLocalizationMoment(res?.country?.local || "en");
            document.title = `${res?.orgName}`;
            getClassesList(token);
        } catch (error) {
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
        }
    };

    useEffect(() => {
        localStorage.removeItem('redirectUrl');
    }, [])


    useEffect(() => {
        if(isAuthenticatedLoading) return;
        localStorage.setItem('SchoolLayout', true);
        setLocalStorageRegistration({
            activeStep: 0,
            registerFormId: getLocalStorageRegistration()?.registerFormId,
            RegisterData: getLocalStorageRegistration()?.RegisterData,
            RegisterRequiredFelids: getLocalStorageRegistration()?.RegisterRequiredFelids
        });
        generateToken()

    }, [page,isAuthenticatedLoading,isAuthenticated]);


    const handleReserveLogin = () => {
        if(isAuthenticated)  {
            handleReserveLogout();
            return;
        }
        localStorage.setItem("redirectUrl", `/school`);
        const originUrl = window.location.origin;
        logout({ returnTo: originUrl });
    }

    const handleReserveLogout =  () => {
     logout({ 
        localOnly: true,
        openUrl: false
      });
    }


    const reserveLoginButton = useMemo(() => isAuthenticated ? "Logout" : "Login" , [isAuthenticated]);

    return (
        <>
            <Wrapper>
                <HeaderWrapper>
                  <Header Logo={Logo} reserveLoginButton={memberPortal ? reserveLoginButton : false} handleReserveLogin={handleReserveLogin}/>
                </HeaderWrapper>
                <Content>
                    <div >
                        <Title >List Of Classes</Title>
                    </div>

                    <div className="family-table-container p-7 pb-15">
                        <div className="home-table-container mt-0 no-box-shadow">
                            {loading ? <SpinnerForList><PulseLoader color={'#2555EF'} size={'15px'} /></SpinnerForList> :

                                <TableContainer component={Paper}>
                                    <Table class="main-table" style={{ width: '-webkit-fill-available' }}>
                                        <TableHead className="charges-table" >
                                            <TableRow>
                                                <>
                                                    {HeaderTitles.map((item, index) => (
                                                        <TableCell className="charged-value" align="left" key={index}>
                                                            {item}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell className="charged-value" align="left"></TableCell>
                                                </>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            className="tran-table-body"
                                            style={{
                                                height: classes?.length === 10 ? "500px" : "",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            {/* {loading ?
                                            <SpinnerForList><PulseLoader color={'#2555EF'} size={'15px'} /></SpinnerForList>
                                            :  */}
                                            <BodyForList classes={classes} />
                                            {/* } */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }

                        </div>
                    </div>
                    {classes?.length !== 0 && (
                        <div className="pagination-custom mt-2">
                            <Pagination
                                count={Math.ceil(count / 10)}
                                color="primary"
                                variant="outlined"
                                onChange={(event, value) => {
                                    setPage(value - 1);
                                }}
                            />
                        </div>
                    )}
                </Content>
            </Wrapper>

        </>
    );
};
