import BackSvgComponent from "assets/icons/svg/back";
import BottomArrowSvg from 'assets/icons/svg/BottomArrowSvg';
import { InputField, LabelField, MyCheckbox, SelectField } from 'components';
import { useSearch } from 'hook';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select, { components } from 'react-select-v2';
import { emailValidation } from 'utils/validation';
import { TextareaField } from '../../../components/textareaField';
import FinanceService from '../../../Lib/API/Auth';

const TributeInformation = ({ formik, data }) => {
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = useState('');
  const { searchAction } = useSearch({ action: setSearch });
  const [recipients, setRecipients] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const enableLoading = (value) => {
    setLoading(value);
  };


  useEffect(() => {
    const getAllFamilyMember = async () => {
      enableLoading(true);
      const token = localStorage.getItem('token');
      const res = await FinanceService.getAllFamilyMember(token)
        .then((res) => {
          const result = res?.contacts?.map((item) => ({
            id: item._id,
            label: item.full_name,
          }))
          setRecipients(result);
          enableLoading(false);
        })
        .catch((err) => {
          console.log(err);
          enableLoading(false);
        });
    };
    getAllFamilyMember();
  }, []);

  const selectControlStyles = {
    backgroundColor: '#F3F6F9',
    borderColor: '#F3F6F9',
    color: '#3F4254',
    transition:
      'color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease',
    paddingTop: '6px',
    paddingBottom: '6px',
    cursor: 'pointer',
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      ...selectControlStyles,
    }),
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <BottomArrowSvg label="Emoji" />
      </components.DropdownIndicator>
    );
  };
  const formatOptionLabel = ({ label, account_id }) => {
    return (
      <div className="dropdown-container" key={account_id}>
        <div className="icon-name-container d-flex align-items-center justify-content-start">
          <div className="name-container" style={{ marginInlineStart: 10 }}>
            <div>{label}</div>
          </div>
        </div>
      </div>
    );
  };


  console.log('formik?.values', formik?.values)

  const addRecipientHandler = () => {
   const error = formik?.values?.acknowledgements?.some((item) => {
      if (!item?.recipient_name) {
        enqueueSnackbar("Donation Recipient Name is required", { variant: "error" });
        return true;
      }
      if (!item?.email) {
        enqueueSnackbar("Email is required", { variant: "error" });
        return true;
      }
      if (!emailValidation(item?.email)) {
        enqueueSnackbar("Email is not valid", { variant: "error" });
        return true;
      }
    });


    if (error) {
      return;
    }

    formik.setFieldValue(`acknowledgements`, [
      ...formik?.values?.acknowledgements,
      {
        recipient_name: '',
        email: '',
      },
    ]);
  }


  const deleteRecipientHandler = (index) => {
    if (formik?.values?.acknowledgements?.length === 1) {
      return enqueueSnackbar("At least one recipient is required", { variant: "error" });
    }

    const filteredArray = formik?.values?.acknowledgements.filter((item, i) => i !== index);

    formik.setFieldValue(
      `acknowledgements`,
      filteredArray
    );
  }


  const toggleAcknowledgement = (value) => {
    formik.setFieldValue(
      `showAcknowledgement`,
      value
    )
    if (!formik?.values?.acknowledgements || formik?.values?.acknowledgements?.length === 0) {
      formik.setFieldValue(`acknowledgements`, [{
        recipient_name: '',
        email: '',
      }])
    }
  }

  return (
    <div className="">
      <form
        onSubmit={formik?.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="pt-5 pb-5 tribute-info__wrapper">
        <Row className="form-row sm-12">
            <Col className="col-12 col-md-6  tribute-text">
              <LabelField title="Tribute Type" 
                mandatory={true}
              />
              <Select
                {...{
                  formik,
                  name: `tribute_type`,
                  checkValidation: true,
                }}
                onInputChange={(text) => searchAction(text)}
                classNamePrefix="react-select"
                formatOptionLabel={formatOptionLabel}
                options={[
                  { id: 'In honor of', label: 'In honor of' },
                  { id: 'In memory of', label: 'In memory of' },
                ]}
                isLoading={loading}
                styles={colourStyles}
                onChange={(value) => {
                  formik.setFieldValue(`tribute_type`, {
                    id: value.id,
                    name: value.label,
                  });
                }}
                value={
                  formik?.values?.tribute_type
                    ? {
                        label: formik.values?.tribute_type?.name,
                        value: formik.values?.tribute_type?.id,
                      }
                    : ''
                }
                components={{ DropdownIndicator }}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <InputField
                {...{
                  formik,
                  title: 'Name',
                  name: 'donor_name',
                  placeholder: 'Name',
                  mandatory: true,
                  value: formik?.values?.donor_name
                    ? formik?.values?.donor_name
                    : '',
                }}
                onChange={(value) => {
                  formik.setFieldValue(`donor_name`, value);
                }}
              />
            </Col>
          </Row>
          <Row className="form-row sm-12">
            <Col className="col-12 col-md-12">
              <TextareaField
                {...{
                  formik,
                  rows: 4,
                  title: 'Tribute Message',
                  name: 'note',
                  placeholder: 'Tribute text',
                  style: { resize: 'none' },
                  value: formik.values?.note ? formik.values?.note : '',
                }}
                onChange={(value) => {
                  formik.setFieldValue(`note`, value);
                }}
              />
            </Col>
          </Row>
          <div>
            <hr />
          <div
            className="acknowledgement__checkbox mb-5"
            >
              <label
                htmlFor="showAcknowledgement"
                className="d-flex align-items-center h-100 checkbox__label"
                style={{ fontSize: '16px', fontWeight: '700' }}
              >
                <MyCheckbox
                  handlechange={(e) => {
                    toggleAcknowledgement(e.target.checked);
                  }}
                  checked={
                    formik?.values?.showAcknowledgement
                  }
                  name={`showAcknowledgement`}
                />
                Send acknowledgement email to
                </label>
            </div>
            {formik?.values?.showAcknowledgement && (
              <div>
                {formik?.values?.acknowledgements?.map((item, index) => (
                <Row className="form-row sm-12 mt-5 flex-nowrap" key={index}>
                  <Col className="col-12 col-md-6">
                    <InputField
                      {...{
                        formik,
                        title: 'Donation Recipient Name',
                        name: `acknowledgements[${index}].recipient_name`, 
                        placeholder: 'Donation Recipient Name',
                        mandatory: true,
                        value: formik?.values?.acknowledgements[index]?.recipient_name ? formik?.values?.acknowledgements[index]?.recipient_name : '',
                      }}
                      onChange={(value) => {
                        formik.setFieldValue(`acknowledgements[${index}].recipient_name`, value);
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-md-6 tribute-text">
                    <InputField
                      {...{
                        formik,
                        title: 'Email',
                        name: `acknowledgements[${index}].email`, 
                        placeholder: 'Email',
                        mandatory: true,
                        value: formik?.values?.acknowledgements[index]?.email ? formik?.values?.acknowledgements[index]?.email : '',
                      }}
                      onChange={(value) => {
                        formik.setFieldValue(`acknowledgements[${index}].email`, value);
                      }}
                    />
                  </Col>
                  {formik.values?.acknowledgements?.length > 1 && (
                    <div
                      className="delete_donation_recipient"
                      onClick={() => deleteRecipientHandler(index)}
                    >
                      <BackSvgComponent />
                    </div>
                  )}
                </Row>
                ))}
                <Row className="form-row sm-12 ">
                   <Col className="col-12 mt-5">
                    <div className="addRecipient" onClick={addRecipientHandler}>
                      + Add Recipient
                    </div>
                  </Col>
                </Row>
              </div>
            )}

          </div>
        </div>
      </form>
    </div>
  );
};

export default TributeInformation;
