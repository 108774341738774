import { Auth0Provider } from "@auth0/auth0-react";
import App from "app";
import "bootstrap/scss/bootstrap.scss";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./app/store";
import { InputField } from "./components";
import "./index.scss";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "_metronic/_assets/plugins/flaticon/flaticon.css";
import "_metronic/_assets/plugins/flaticon2/flaticon.css";
import "_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import * as axios from "axios";
import "socicon/css/socicon.css";
import { setLocalizationMoment } from "./helper/dateFormat";
import { setLogoImage } from "./services/api/organization";
import history from "./utils/history";

const InitOrg = () => {
  const dispatch = useDispatch();
  const redirectUri = window.location.protocol + "//" + window.location.host;
  const subDomain = window.location.hostname.split(".")[0];
  localStorage.setItem("subDomain", subDomain);
  const organisationKey = localStorage.getItem("subDomain") === "localhost" ? "shalom" : localStorage.getItem("subDomain");
  const [origin, setOrigin] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);
      // GET request using axios with error handling
      axios
        .get(`${process.env.REACT_APP_API}/public/orgbykey/${organisationKey}`)
        .then((res) => {
          setOrigin(res);
          localStorage.setItem(
            "getOrginasitionBySubDomain",
            JSON.stringify(res.data)
          );
          localStorage.setItem('allowBillMyAccountOnDonation', res?.data?.allowBillMyAccountOnDonation || false);
          localStorage.setItem('country', JSON.stringify(res?.data?.country || {}));
          setLocalizationMoment(res?.data?.country?.local || "en");
          res?.data?.logoImg && dispatch(setLogoImage(res?.data?.logoImg))
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }

    fetchMyAPI();
  }, [organisationKey]);
  useEffect(() => { console.log("origin", origin); }, [])
  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.pathname
    );
  };
  if (loading) return <div>Loading...</div>;
  return origin ? (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      issuer={process.env.REACT_APP_ISSUER}
      audience={process.env.REACT_APP_AUDIANCE}
      scope={process.env.REACT_APP_SCOPE}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={6000}
          >
            <App />
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </Auth0Provider>
  ) : (
    "This Organisation Does not exist..."
  );
};

export default InitOrg;
