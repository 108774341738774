import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@material-ui/core/Grid";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DatePickerField } from "_metronic/_partials/controls";
import BottomArrowSvg from "assets/icons/svg/BottomArrowSvg";
import CrossSvg from "assets/icons/svg/cross";
import { InputField, LabelField, SelectField } from "components";
import MyButton from "components/MyButton";
import SearchComp from "components/Searchcomponent";
import { useFormik } from "formik";
import { getCurrency } from "helper/numberFormat";
import { useSearch } from "hook";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import FinanceService from "../../Lib/API/Finance";

const selectControlStyles = {
  backgroundColor: "#FFFFFF",
  borderColor: "#FFFFFF",
  color: "#b5b5c3",
  transition:
    "color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease",
  paddingTop: "6px",
  paddingBottom: "6px",
  cursor: "pointer",
};
const colourStyles = {
  control: (styles) => ({
    ...styles,
    ...selectControlStyles,
  }),
};

export const FinanceFilter = ({
  filter,
  onSearchChange,
  onChargeDateChange,
  onLedgerAccountChange,
  onPaidDateChange,
  onAmountChange,
  onChargedStatusChange,
  onPaymentStatusChange,
  onOverdueDaysChange,
  onClear,
  onStatusChange,
  setTabClick,
  setFilters,
  initialFilters,
  toggle,
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [ledgers, setLedgers] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [onSearch, setOnSearch] = useState("");
  const { searchAction } = useSearch({ action: setOnSearch });
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,1})/s;

  const currency = getCurrency();

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: {},
    validationSchema: "",
    onSubmit: async (values) => { },
  });
  const exceptThisSymbols = ["e", "E", "+", "-"];

  const preventArrows = (e) => {
    if (e.which === 38 || e.which === 40 || exceptThisSymbols.includes(e.key))
      e.preventDefault();
  };

  useEffect(() => {
    onChargeDateChange(formik.values?.charged_date);
    onLedgerAccountChange(formik.values?.ledger);
    onPaidDateChange(formik.values?.paid_at);
    onAmountChange(formik.values?.amount);
    onChargedStatusChange(formik.values?.charge_status);
    onPaymentStatusChange(formik.values?.payment_status);
    onOverdueDaysChange(formik.values?.overdue);
    // eslint-disable-next-line
  }, [formik.values]);
  useEffect(() => {
    async function getLedgeraccounts() {
      const token = await getAccessTokenSilently();
      const res = await FinanceService.getLedgerSubLedgerList(token);
      if (res?.ledgers) {
        setLedgers(res?.ledgers);
      }
    }
    getLedgeraccounts();
  }, [getAccessTokenSilently]);

  const onLedgerChange = (e) => {
    formik.setFieldValue("ledger", e);
    onLedgerAccountChange(e);
  };

  const onFilterClear = () => {
    formik.setFieldValue("charged_date", undefined);
    formik.setFieldValue("ledger", {});
    formik.setFieldValue("paid_at", undefined);
    formik.setFieldValue("amount", "");
    formik.setFieldValue("charge_status", undefined);
    formik.setFieldValue("payment_status", undefined);
    formik.setFieldValue("overdue", undefined);
  };

  const options =
    ledgers &&
    ledgers?.map((item) => {
      return {
        label: item.ledger_name,
        value: item._id,
      };
    });

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <BottomArrowSvg label="Emoji" />
      </components.DropdownIndicator>
    );
  };
  const filter_by = ["All", "charge", "payment"];
  useEffect(() => {
    if (toggle === "Transaction") {
      setFilters(initialFilters);
    }
  }, [toggle]);

  return (
    <div className="filteration">
      <div className="activated-ledger d-flex flex-column flex-lg-row justify-content-between mb-5 w-100">
        <div className="sub-header-functions">
          <span className="finance-filter-serach-bar">
            <SearchComp
              value={filter?.search ? filter?.search : ""}
              setSearchText={onSearchChange}
              placeholder="Search by account name, ledger account, amount"
            />
          </span>
          <div className="pl-5">
            <MyButton
              type="secondary"
              content={
                <div className="">
                  <FilterListIcon style={{ marginRight: "5px" }} />
                  Filter
                </div>
              }
              style={openFilter ? { opacity: 0.3 } : {}}
              onClick={() => setOpenFilter(!openFilter)}
            />
          </div>
        </div>
        <div className="finance-filter">
          {filter_by.map((item, index) => {
            const currentStatus = filter?.status || "All";
            return (
              <div
                key={index}
                className={`filter-tab ml-3 ${item === currentStatus &&
                  "selected-filter"}`}
                onClick={() => {
                  onStatusChange(item);
                }}
              >
                <div>
                  {index !== 0 && (
                    <div
                      className="circle"
                      style={
                        index !== 1
                          ? { background: "#81DC91" }
                          : { background: "#FBA12C" }
                      }
                    />
                  )}
                  <span className={index !== 0 && "ml-2"}>{item}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {openFilter && (
        <div className="filter mb-10">
          <Link></Link>
          <div className=" text-right px-5 pt-5">
            <span
              className="cursor-pointer"
              onClick={() => setOpenFilter(false)}
            >
              <CrossSvg />
            </span>
          </div>
          <div className="filter-wrapper"> 
          <Grid container spacing={5} >
              <Grid item xs={12} sm={6} md={3}>
              {/* <div className="col-sm-4 remove-tick finance-filter-date"> */}
                <DatePickerField
                  {...{
                    formik,
                    name: "charged_date",
                    title: "Charged Date",
                    value: filter?.filter?.charge_date,
                  }}
                  onChange={onChargeDateChange}
                />
                </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <DatePickerField
                    {...{
                      formik,
                      name: "paid_at",
                      title: "Paid Date",
                      value: filter?.filter?.paid_at,
                    }}
                    onChange={onPaidDateChange}
                  />
                </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <LabelField title={"Ledger Account"} />
                <Select
                  {...{
                    formik,
                    name: "ledger",
                    checkValidation: false,
                  }}
                  onInputChange={(text) => searchAction(text)}
                  classNamePrefix="react-select"
                  options={options}
                  styles={colourStyles}
                  onChange={onLedgerChange}
                  value={{
                    label: filter?.filter?.ledger ? (
                      filter?.filter?.ledger
                    ) : (
                      <span style={{ color: "#b5b5c3" }}> Select</span>
                    ),
                    value: filter?.filter?.ledger,
                  }}
                  components={{ DropdownIndicator }}
                />
                </Grid>
              <Grid item xs={12} sm={6} md={3}>
              <InputField
                type="number"
                {...{
                  formik,
                  title: "Amount",
                  name: "amount",
                  placeholderIcon: (
                    <span className="filter-amount-placeholder">{currency}</span>
                  ),
                  placeholder: "Amount",
                  value: formik.values?.amount,
                }}
                onKeyPress={(e) =>
                  formik.setFieldValue(`amount`, e.target.value.match(regex)[0])
                }
                onKeyDown={(e) => preventArrows(e)}
                min="0"
                onChange={onAmountChange}
              />
                </Grid>
          </Grid>
          <Grid container spacing={5} >
            <Grid item xs={12} sm={6} md={3}>
                <SelectField
                  {...{
                    formik,
                    title: "Overdue Days",
                    name: "overdue",
                    items: ["day", "week", "month", "quarter", "year"],
                    placeholder: "Select",
                    value: filter?.filter?.overdue,
                  }}
                  onClick={(data) => formik.setFieldValue("overdue", data)}
                  onChange={onOverdueDaysChange}
                />
              </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SelectField
                  {...{
                    formik,
                    title: "Charged Status",
                    name: "charge_status",
                    items: ["open", "closed", "partially paid", "reversal"],
                    value:
                      filter?.filter?.charge_status === "partially_paid"
                        ? "Partially paid"
                        : filter?.filter?.charge_status,
                    placeholder: "Select",
                  }}
                  onClick={(data) =>
                    formik.setFieldValue("charge_status", data)
                  }
                  onChange={onChargedStatusChange}
                />
              </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SelectField
                  {...{
                    formik,
                    title: "Payment Status",
                    name: "payment_status",
                    items: [
                      "prepaid",
                      "refunded",
                      "reversal",
                      "partialy applied",
                      "close",
                    ],
                    value:
                      filter?.filter?.payment_status === "partially_applied"
                        ? "Partially applied"
                        : filter?.filter?.payment_status,
                    placeholder: "Select",
                  }}
                  onClick={(data) =>
                    formik.setFieldValue("payment_status", data)
                  }
                  onChange={onPaymentStatusChange}
                />
              </Grid>
          </Grid>
          </div>
          <div className="pr-12 pl-9 pb-10 justify-content-end d-flex">
            <MyButton
              type="secondary"
              content={<span className="mx-8">Clear</span>}
              onClick={onFilterClear}
              style={{ marginRight: 10 }}
            />
            <button
              className="btn btn-primary"
              style={{ width: 70 }}
              onClick={() => setOpenFilter(false)}
            >
              Go
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
