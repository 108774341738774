import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from 'react-router';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Event } from "./Event";
import { ListOfEvents } from "./EventComponents/ListOfEvents/ListOfEvents";
import { ReservationEventPage } from "./EventComponents/ReservationEventPage/ReservationEventPage";

export const EventLinkRouting = () => {
  const { isAuthenticated, isLoading: isAuthenticatedLoading } = useAuth0();
  const { eventData } = useSelector(
    (state) => state?.Events
  );


  const isReservedNotAllowed = !eventData?.allowNonMembers && !isAuthenticated && !isAuthenticatedLoading 


	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/events/" component={ListOfEvents} />
				<Route exact path="/events/:id/reservation" render={() => (
          isReservedNotAllowed ?
          <Redirect to={`/events/${eventData?._id}`} /> :
          <ReservationEventPage />
        )} />
				<Route exact path="/events/:id" component={Event} />
			</Switch>
		</BrowserRouter>
	);
};
