import React, { useState } from "react";
import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { Text, SelectField, InputField, IconButton } from "components";
import { ReactComponent as ArrowLeftSVG } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightSVG } from "assets/icons/arrow-right.svg";
import { colors } from "theme";
import { setStepThree } from "../../services/api/formSteps";
import { useSelector, useDispatch } from "react-redux";
import { Step3Schema } from "../ValidationSchema/index";
import AuthService from "Lib/API/Auth";
import FindMyCity from "components/FindMyCity";
import { toastr } from "react-redux-toastr";

const styles = {
  circleButtonBody: {
    background: "#FFFFFF",
    borderRadius: "50%",
    width: 20,
    height: 20,
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  textIconButton: {
    color: colors.primaryColor,
    fontWeight: "600",
    fontSize: 13,
  },
};

export const FirstTime_3 = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const stepThreeData = useSelector((state) => state.stepForms.step_three);
  const { getAccessTokenSilently } = useAuth0();

  const initialValues = {
    pronounce: stepThreeData.pronounce,
    occupation: stepThreeData.occupation,
    location: stepThreeData?.location,
    city: stepThreeData.city,
    zip_code: stepThreeData.zip_code,
    appear_in_members_directory: false,
  };

  const handelNextPage = () => {
    props.setCurrentStepCheck({ type: "next" });
  };
  const handelBackPage = () => {
    props.setCurrentStepCheck({ type: "back" });
  };
  const enableLoading = (value) => {
    setLoading(value);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Step3Schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      dispatch(setStepThree(values));
      enableLoading(true);
      const token = await getAccessTokenSilently();
      const input = {};

      if (values.pronounce) {
        input.pronounce = values.pronounce;
      }
      if (values.occupation) {
        input.occupation = values.occupation;
      }
      if (values.location) {
        input.location = values.location;
      }
      if (values.city) {
        input.city = values.city;
      }
      if (values.zip_code) {
        input.zip_code = values.zip_code;
      }
      if (values.appear_in_members_directory) {
        input.appear_in_members_directory = values.appear_in_members_directory;
      }

      await AuthService.updateStep(input, token)
        .then((res) => {
          enableLoading(false);
          handelNextPage();
        })
        .catch((err) => {
          enableLoading(false);
          toastr.error('Error', err?.data?.message);
        });
    },
  });

  return (
    <div className="login-form login-signin login-signin-d">
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <SelectField
          {...{
            formik,
            title: "Preffered Pronoun",
            name: "pronounce",
            items: ["He/Him", "She/Her", "They/Them"],
            value: formik.values.pronounce,
            placeholder: "Select pronoun",
          }}
          onClick={(data) => formik.setFieldValue("pronounce", data)}
        />
        <InputField
          {...{
            formik,
            title: "Occupation",
            name: "occupation",
            placeholder: "Occupation",
          }}
        />

        {/* <InputField {...{ formik, title: "Address", name: "location", placeholder: 'Enter location' }} /> */}
        <div className="form-group">
          <FindMyCity
            handleChange={(address) => {
              formik.setFieldValue("location", {
                formattedAddress: address?.label,
                place_id: address?.value?.place_id,
              });
            }}
            title="Address"
            value={{
              label: `${
                formik.values?.location?.formattedAddress
                  ? formik.values?.location?.formattedAddress
                  : ""
              }`,
              value: `${formik.values?.location?.place_id}`,
            }}
          />
        </div>
        <InputField
          {...{
            formik,
            title: "City",
            name: "city",
            placeholder: "Enter city",
          }}
        />
        <InputField
          {...{
            formik,
            title: "Zip code",
            name: "zip_code",
            placeholder: "Enter zip code",
          }}
        />
        <div className="row ml-1 mb-10">
          <label className="checkbox checkbox-md checkbox-single mr-2">
            <input type="checkbox" name="createUser" />
            <span />
          </label>
          <label className="mt-2">I want to appear in members directory</label>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <IconButton onClick={handelBackPage}>
            <ArrowLeftSVG className="mx-2" />
            <Text
              style={{
                ...styles.textIconButton,
              }}
            >
              Back
            </Text>
          </IconButton>
          <div className="form-group mx-5 my-4 col-sm-6 col-lg-6">
            <button
              type="submit"
              disabled={!formik.isValid}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3 w-100 mx-auto`}
            >
              <span style={{ fontSize: 13, fontWeight: "600" }}>Next</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
          <IconButton onClick={handelNextPage}>
            <Text
              style={{
                ...styles.textIconButton,
              }}
            >
              Skip
            </Text>
            <ArrowRightSVG className="mx-2" />
          </IconButton>
        </div>
      </form>
    </div>
  );
};
