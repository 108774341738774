import { TableCell } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { convertUTCToLocalDate } from 'components/UTC/UtcConverter';
import { formatDate } from "helper/dateFormat";
import { numberFormat } from "helper/numberFormat";
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from "react-router";
import thousands from "thousands";


export const BodyForList = ({ events }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const getEventById = async (Item) => {
        // The user should not be able to register in a not started class
        const startDateDiff = moment().diff(moment(Item?.eventStartDate), 'days');
        const endDateDiff = moment().diff(Item?.eventEndDate ? moment(Item?.eventEndDate) : moment(), 'days');
        if (startDateDiff < 0) {
            enqueueSnackbar(`This event has not yet begun. Please check back closer to the start date for access`, {
                variant: 'error',
            });
        }
        else if (endDateDiff > 0) {
            enqueueSnackbar('This event is no longer available due to its expiration date', {
                variant: 'error',
            });
        }
        else {
            console.log("Item?._id",Item?._id);
            history.push(`/events/${Item?._id}`);

        }
    };

    return (
        <>
            {
                events.map((row) => (
                    <TableRow onClick={() => getEventById(row)} style={{ cursor: 'pointer' }} hover={true}>
                        <TableCell className="charged-value" align="left" >
                            {row?.eventName}
                        </TableCell>
                        <TableCell className="charged-value" align="left" >
                        {formatDate({date:row?.date, formatType: "defaultWithUTC"})}
                        </TableCell>
                        <TableCell className="charged-value" align="left" >
                            {`${formatDate({date:row?.eventStartDate, formatType: "defaultWithUTC"})}
                            - ${row?.alwaysAvailable ? 'Always Available' : formatDate({date:row?.eventEndDate, formatType: "defaultWithUTC"})}`}
                        </TableCell>

                        <TableCell className="charged-value" align="left" >
                        {row?.eventDetails?.eventType}

                        </TableCell>
                        <TableCell className="charged-value" align="left" >
                        {row?.allowNonMembers === true ? (
                                <CheckCircleIcon
                                  color="primary"
                                  className="ml-8"
                                />
                              ) : (
                                ""
                              )}

                        </TableCell>
                        <TableCell className="charged-value" align="left" >
                            {numberFormat({number:+row?.eventPricing?.total_cost, decimal: 2, style: "currency"})}
                        </TableCell>
                    </TableRow>
                ))
            }
        </>
    );
};
