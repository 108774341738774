import request from "./request";
// ref:https://gist.github.com/sheharyarn/7f43ef98c5363a34652e60259370d2cb

function get(url, token) {
    return request({
        method: "GET",
        url,
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
}
function getWithBody(url, data, token) {
    return request({
        method: "GET",
        url,
        data,
        // headers: {
        //     "Authorization": `Bearer ${token}`
        // }
    });
}

function post({ url, data, token }) {
    return request({
        method: "POST",
        url,
        data,
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
}
function upload({ url, data, token }) {
    console.log("datadatadatadata", data);
    return request({
        method: "POST",
        url,
        data,
        headers: {
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    });
}
function update({ url, data, token }) {
    return request({
        method: "PUT",
        url,
        data,
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
}

function remove({ url, data, token }) {
    return request({
        method: "DELETE",
        url,
        data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

const Service = {
    get,
    post,
    update,
    remove,
    upload,
    getWithBody
};
export default Service;
