import { ReserveLoginButton } from "pages/ExternalLinkEvents/EventComponents/EventInfo/Theme";
import React from "react";
import { Wrapper } from "./Theme";

const Header = ({ Logo,reserveLoginButton,handleReserveLogin }) => {
	if (!Logo) return;
	return (
		<Wrapper>
			<header>
				<img src={Logo} alt="Logo" />
			</header>
			{reserveLoginButton && <ReserveLoginButton onClick={handleReserveLogin}>{reserveLoginButton}</ReserveLoginButton>}
		</Wrapper>
	);
};

export default Header;
