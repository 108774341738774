import React, { useCallback, useEffect, useState } from 'react';
import { TicketsCardsContainer, TicketsCardsList, TicketsCardsTitle } from './Theme';
import TicketsCard from './TicketsCard';

const TicketCards = ({ticketList,handleQuantityChange,totalTicket,allowNonMembers}) => {
  if(!ticketList || !ticketList?.variantInfo) return null;

  return (
    <TicketsCardsContainer>
      <TicketsCardsTitle>{ticketList?.title}</TicketsCardsTitle>
      <TicketsCardsList>
        {ticketList?.variantInfo?.map((variant, index) => (
          <TicketsCard key={index} 
            variant={variant} 
            handleQuantityChange={(value,type) => handleQuantityChange(index, value,type)}
            totalTicket={totalTicket}
            allowNonMembers={allowNonMembers}
          />
        ))}
      </TicketsCardsList>
    </TicketsCardsContainer>
  )
}

export default TicketCards