import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export const uploadFile = async (file, fileData, token) => {
    const res = await new Promise(async (resolve, reject) => {
        try {
            const fileName = `${uuidv4()}_${fileData.name}`;
            const contentType = fileData.type;
            const generatePutUrl = `${process.env.REACT_APP_BASEURL}/accounts/setup/generate-put-url`;
            const options = {
                params: {
                    Key: fileName,
                    ContentType: contentType,
                },
                headers: {
                    "Content-Type": contentType,
                    Authorization: `Bearer ${token}`,
                },
            };
            axios.get(generatePutUrl, options).then((res) => {
                const {
                    data: { generatedUploadLink },
                } = res;
                const optionsPut = {
                    params: {
                        Key: fileName,
                        ContentType: contentType,
                    },
                    headers: {
                        "Content-Type": contentType,
                    },
                };
                axios
                    .put(generatedUploadLink, file, optionsPut)
                    .then((res) => {
                        const URL = `${process.env.REACT_APP_BASEURL}/accounts/setup/generate-get-url?Key=${fileName}`;
                        axios
                            .get(URL, {
                                headers: { Authorization: `Bearer ${token}` },
                            })
                            .then((res) => {
                                if (res?.data?.generatedDownloadLink)
                                    resolve({ link: res.data.generatedDownloadLink, fileName });
                                else reject(false);
                            });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        } catch (error) {
            reject(error);
        }
    });
    return res;
};

export const downloadFile = async (fileName, token) => {
    const res = await new Promise(async (resolve, reject) => {
        try {
            const URL = `${process.env.REACT_APP_BASEURL}/accounts/setup/generate-get-url?Key=${fileName}`;

            axios
                .get(URL, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => {
                    if (res?.data?.generatedDownloadLink)
                        resolve({ link: res.data.generatedDownloadLink, fileName });
                    else reject(false);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });
    return res;
};
