import React from 'react';
import { Wrapper, ThanksContent, ThanksImage, ThanksTitle, ThanksDescription, ThanksButtonGroups, RegisterButton, DontReciveButton } from './Theme'
import ThanksImg from 'assets/images/ThanksImg.svg'
import { useHistory } from 'react-router-dom';


const Thanks = () => {
    const { push } = useHistory();
    const handleRegisterButton = () => {
        push(`/school/`)
    }
    return (
        <Wrapper>
            <ThanksContent>
                <ThanksImage src={ThanksImg} alt='Thanks-image' />
                <ThanksTitle>Thank you for registering</ThanksTitle>
                <ThanksDescription>Please check your email. <br />A confirmation email should be arriving in your inbox shortly.</ThanksDescription>
                <ThanksButtonGroups>
                    <RegisterButton onClick={handleRegisterButton}>New Registration</RegisterButton>
                    {/* <DontReciveButton>I Didn’t Receive it</DontReciveButton> */}
                </ThanksButtonGroups>
            </ThanksContent>
        </Wrapper>
    );
};

export default Thanks;