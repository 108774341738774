import { useAuth0 } from "@auth0/auth0-react";
import { InputField, LabelField } from "components";
import FinanceService from "Lib/API/Finance";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";

const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
export const CreditOnAccount = () => {
  return null;
};
export const CreditOnAccountHeader = ({ formik, ...props }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { expanded, current } = props;
  const [creditOnAccountAmount, setCreditOnAccountAmount] = useState("");

  let accountID = localStorage.getItem("accountID")
  ? localStorage.getItem("accountID")
  : "";


  const preventMinus = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  const handlefieldClick = (e) => {
    if (e.stopPropagation && expanded === true) {
      e.stopPropagation();
    }
  };
  const getCreditOnAccountAmount = async (id) => {
    const token = await getAccessTokenSilently();
    await FinanceService.getCreditOnAccountAmount(id, token).then((res) => {
      setCreditOnAccountAmount(res?.result?.balance);
    }).catch((err) =>{
      toastr.error('Error', err?.data?.message);
    })
  };
  const getCommaSeperatedValue = (num) => {
    return parseFloat(num?.toString().replace(/,/g, ""))
      .toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    getCreditOnAccountAmount(
      accountID ?? formik?.values?.accountIds
    );
  }, [accountID, formik?.values?.accountIds]);

  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  return (
    <div
      className={
        expanded === false && current?.includes("credit on account") === true
          ? "accordion-header"
          : expanded === false
          ? "accordion-payment-header"
          : expanded === true
          ? "accordion-header"
          : "accordion-header-payment"
      }
    >
      <div className="d-flex credit-on-account-spacing">
        <LabelField title="Credit On Account" />
        <span
          className={
            expanded === false &&
            current?.includes("credit on account") === true
              ? "creditBalance"
              : "creditOnAccount-title"
          }
        >
          (balance: $
          {creditOnAccountAmount
            ? getCommaSeperatedValue(creditOnAccountAmount)
            : 0}
          )
        </span>
      </div>
      <div
        className={
          expanded === false && current?.includes("credit on account")
            ? "custom-expand dollor-icon col-sm-3 nonexpanded-amount"
            : expanded === false &&
              current?.includes("credit on account") === false &&
              formik?.values?.onAccountCredit_payment
            ? "custom-expand dollor-icon col-sm-3 nonexpanded"
            : "col-sm-3"
        }
      >
        {expanded === false &&
        current?.includes("credit on account") &&
        formik?.values?.onAccountCredit_payment ? (
          <div className="cash-non-expanded">
            <span>$</span>
            <span>
              {getCommaSeperatedValue(formik.values?.onAccountCredit_payment)}
            </span>
          </div>
        ) : current?.includes("credit on account") && expanded === true ? (
          <InputField
            {...{
              formik,
              title: "none",
              name: `val`,
              style: { background: "#ffff", border: "none" },
              value: formik.values?.onAccountCredit_payment,
              placeholderIcon: (
                <span
                  className="align-self-center px-4"
                  style={{ color: "black" }}
                >
                  $
                </span>
              ),
            }}
            max={parseInt(creditOnAccountAmount)}
            paymentInput="paymentInput"
            handleFocus={(e) => handlefieldClick(e)}
            // onKeyDown={preventMinus}
            handleChange={(event) => {
              formik.setFieldValue(
                "onAccountCredit_payment",
                event.target.value.match(regex)[0]
              );
              formik.setFieldValue(
                "creditOnAccountAmount",
                creditOnAccountAmount
              );
            }}
          />
        ) : expanded === false &&
          current?.includes("credit on account") === false &&
          formik?.values?.onAccountCredit_payment ? (
          <div className="cash-non-expanded">
            <span>$</span>
            <span>
              {getCommaSeperatedValue(formik.values?.onAccountCredit_payment)}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
