import React from "react";
import { Text } from "components";
import "./style.css";
import { useAuth0 } from "@auth0/auth0-react";

export const SubHeader = ({ style, title, endSection, ...rest }) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) return null;

  return (
    <header className="d-flex w-100 mt-3 mb-6" {...rest} style={{ height: 40 }}>
      <div className="me-auto" style={{ marginInlineEnd: "auto" }}>
        <Text
          style={{
            color: "rgb(0 0 0)",
            fontSize: 28,
            fontWeight: 500,
          }}
        >
          {title}
        </Text>
      </div>
      {endSection}
    </header>
  );
};
