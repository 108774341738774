import * as React from "react"

function LocationSvg (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={14.529}
            height={17.812}
            viewBox="0 0 14.529 17.812"
            {...props}
        >
            <path
                data-name="Path 26191"
                d="M7.255 0a7.282 7.282 0 017.166 6.051 7.078 7.078 0 01-2.974 7.183 10.717 10.717 0 00-2.874 3.154q-.283.481-.573.958a.808.808 0 01-1.462 0 15.315 15.315 0 00-1.9-2.679 18.151 18.151 0 00-1.919-1.712A7.046 7.046 0 01.031 7.906 7.248 7.248 0 015.964.137C6.385.061 6.823.045 7.255 0zM4.037 7.3a3.228 3.228 0 103.256-3.238 3.236 3.236 0 00-3.256 3.243z"
                fill="#7edc90"
            />
        </svg>
    )
}

export default LocationSvg
