import { ReactComponent as InfoIcon } from "assets/icons/InfoIcon.svg";
import { ReactComponent as PencilSVG } from "assets/icons/pencil.svg";
import { InputField } from "components/InputField/InputField";
import { getCurrency, numberFormat } from "helper/numberFormat";
import React, { useCallback, useMemo } from "react";
import {
	DataFoundWrapper,
	DataNotFoundWrapper,
	NumberOfSelections,
	PaymentAmount,
	SummaryItem,
	SummaryTitle,
	TotalPayment,
	TotalPaymentEditBtn,
	TotalPaymentEditIcon,
	TotalPaymentField,
	TotalPaymentFieldSign,
	TotalPaymentLeftSide,
	Wrapper,
} from "./Theme";
const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;

export const SummaryCard = ({
	chargeSelections,
	TotalForPay,
	convenienceFeeInfo,
	coverConvenienceFee,
	formik,
	setEditedTotalPayment,
	editedTotalPayment,
	calculateConvenienceFeeAmount,
	reverseCalculationConvenienceFee,
	calculationTotalPaymentWithCV,
	allowEditTotalPayment
}) => {
  const currency = getCurrency();

	const [editTotalPayment, setEditTotalPayment] = React.useState(false);
	const totalPaymentForCV = editedTotalPayment || TotalForPay;
	const convenienceFeeAmount = useMemo(() => calculateConvenienceFeeAmount(totalPaymentForCV),[calculateConvenienceFeeAmount, totalPaymentForCV]);

	const onTotalPaymentChange = useCallback((e) => {
		const { value } = e.target;
		const maxValue = value > calculationTotalPaymentWithCV()
		if(value < 1 || maxValue) {
			return;
		}
		formik.setFieldValue("totalPayment", value.match(regex)[0]);
	}, [formik, calculationTotalPaymentWithCV]);

	const onTotalPaymentBlur = useCallback((e) => {
		const totalPayment = formik?.values?.totalPayment;
		const minValue = convenienceFeeInfo?.type === "$" ? totalPayment <= convenienceFeeInfo?.amount : totalPayment < 1;
		if(minValue) {
			formik.setFieldValue("totalPayment",  convenienceFeeInfo?.amount + 1);
		}
		const totalWithoutConvenience = reverseCalculationConvenienceFee(totalPayment);
		setEditedTotalPayment(totalWithoutConvenience);
		setEditTotalPayment(false);
	}, [formik, convenienceFeeInfo, reverseCalculationConvenienceFee, setEditedTotalPayment]);

	const editTotalPaymentHandler = useCallback(() => {
		setEditTotalPayment(true);
	}, []);

	return (
		<Wrapper>
			<SummaryTitle>
				<span>Summary</span>
				<InfoIcon />
			</SummaryTitle>
			{chargeSelections?.length === 0 ? (
				<DataNotFoundWrapper>
					<span>No payments were selected yet</span>
				</DataNotFoundWrapper>
			) : (
				<DataFoundWrapper>
					<NumberOfSelections>
						<span>{`You selected ${chargeSelections?.length} payments to pay`}</span>
					</NumberOfSelections>
					{chargeSelections?.map((item) => (
						<>
							<SummaryItem>
								<div>
									<span>{item?.Ledger?.name}</span>
									<p>{item?.SubLedger?.name}</p>
								</div>
								<PaymentAmount>
									{editedTotalPayment ? (
										<del>{`${numberFormat({number:+item?.amount, decimal: 2, style: "currency"})}`}</del>
									) : (
										<>{`${numberFormat({number:+item?.amount, decimal: 2, style: "currency"})}`}</>
									)}
								</PaymentAmount>
							</SummaryItem>
						</>
					))}
					{coverConvenienceFee && (
						<SummaryItem>
							<div>
								<span>Convenience fee</span>
							</div>
							<PaymentAmount>{numberFormat({number:+convenienceFeeAmount, decimal: 2, style: "currency"})}</PaymentAmount>
						</SummaryItem>
					)}
					<TotalPayment>
						<p>Payment Total</p>
						{allowEditTotalPayment && editTotalPayment ? (
							<TotalPaymentField>
								<InputField
									placeholder="Payment Total"
									styleWrapper={{ width: "100%" }}
									style={{
										width: "100%",
										paddingLeft: "20px",
										paddingRight: "5px",
										color: "#002E5C",
										fontWeight: "500",
										lineHeight: "13px",
										textAlign: "right",
									}}
									isOptional={false}
									value={formik?.values?.totalPayment}
									name="first_name"
									handleChange={onTotalPaymentChange}
									onBlur={onTotalPaymentBlur}
								/>
								<TotalPaymentFieldSign>{currency}</TotalPaymentFieldSign>
							</TotalPaymentField>
						) : (
							<TotalPaymentLeftSide>
								{allowEditTotalPayment  && (
									<TotalPaymentEditBtn onClick={editTotalPaymentHandler}>
											Edit Amount
										<TotalPaymentEditIcon >
											<PencilSVG
												style={{
													width: 12,
													height: 12,
												}}
											/>
										</TotalPaymentEditIcon>
									</TotalPaymentEditBtn>
								)}

								<span>{numberFormat({number:+formik?.values?.totalPayment, decimal: 2, style: "currency"})}</span>
							</TotalPaymentLeftSide>
						)}
					</TotalPayment>
				</DataFoundWrapper>
			)}
		</Wrapper>
	);
};
