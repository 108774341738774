import styled from "styled-components"


export const Wrapper = styled.div`
padding-top:30px ;
display: flex;
justify-content: center;
align-items: center;
  `
export const Ledger = styled.div`
display: flex;
flex-direction: column;
overflow: hidden;
white-space: nowrap;
color: var(--black, #4F4F4F);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
`
export const SubLedger = styled.span`
color: var(--dark-blue, #002E5C);
font-size: 12px;
font-weight: 400;
opacity: 0.800000011920929;
`
export const Spinner = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`
export const NoOpenCharges = styled.div`
width:100%;
height: inherit;
display: flex;
justify-content: center;
align-items: center;
font-weight: bold;
font-size: 18px;

`
export const Header = styled.div`
width: 100%;
height: 91px;
display: flex;
justify-content: left;
align-items: center;
`

export const ImageContainer = styled.div`
    height:${(props) => (props.isMobile ? '100%' : '227.966px')};
    overflow: hidden;
    display: flex;
    justify-content: space-between;
`
export const ImageCard = styled.img`
    float: right;
    margin-right:${(props) => (props.isMobile ? '-147px' : '20px')};
`
export const CardContent = styled.div`
    margin-left: 35px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color:#fff;
    font-style: normal;
    line-height: 161.5%;
    width: 100%;
    h1{
    font-weight: bold;
    font-size:${(props) => (props.isMobile ? '18px' : '28px')};
    width: 100%;
    }
    span{
        width: fit-content;
    padding: 3px 5px;
        height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: var(--Blue-20, #EAEEFC); 
    color:rgba(37, 85, 239, 1);
    margin-top: ${(props) => (props.isMobile ? '6px' : '50px')};
    }
`
export const PaymentButtonSubmit = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 44px;
/* margin-top: -65px; */
margin-bottom: 20px;
button{
display: flex;
border-radius: 5px;
justify-content: center;
align-items: center;
background:${(props) => (props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : 'var(--blues-blue-main, #2555EF)')};
padding: 8px 20px;
width:40% ;
height: 100%;
border: 0;
color: ${(props) => (props.isDisabled ? 'var(--Gray-100, #B5B5C3)' : 'var(--White, var(--White-100, #FFF))')};
font-size: 14px;
font-weight: 700;
line-height: 32px; 
:hover{
    background:${(props) => (props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : '#214DD7')};
}
}

`