import styled, { css } from 'styled-components';

export const Body = styled.div`
width: 100%;
background-color: #FFFF;
overflow-x: hidden;
height: 100%;
display: flex;
`
export const ImageContainber = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SchoolImage = styled.img`
width: 100%;
height: 100%; 
position: relative;
border-top-right-radius:5%;
border-bottom-right-radius:5%;
`

export const LogoImage = styled.img`
position: absolute;
top:32px;
left: 53px;
z-index: 5;
width: 10%;
background-color: #ffff;
`
export const Content = styled.div`
position: absolute;
top: 170px;
left: 4%;
width:fit-content;
line-break: auto;
`
export const Title = styled.h1`
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 39px;
color: #FFFFFF;

`
export const Description = styled.p`
width: 660.18px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 26px;
text-transform: capitalize;
color: #FFFFFF;
max-width: 80%;
`
export const AboutUs = styled.button`
background-color: #FFFFFF;
color: #2555EF;
border:none;
padding:5px;
padding-left: 10px;
padding-right: 10px;
font-weight: 500;
font-size: 13px;
`
export const RightSide = styled.div`
   @media only screen and (max-width: 1920px) and (max-height: 1080px) {
    margin: 31px 40px ;
    margin-left:61px ;
    width:40%;
  }
  width:45%;
  margin-top: 77px;
`
export const LeftSide = styled.div`
  flex: 1;
  width: 60%;
  margin-right:30px;

`

export const Spinner = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`