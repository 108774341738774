import { useAuth0 } from "@auth0/auth0-react";
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import { MyCheckbox } from "components";
import Action from "components/ActionPopOver";
import { formatDate } from "helper/dateFormat";
import { numberFormat } from "helper/numberFormat";
import React, { memo, useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import FinanceService from "../../../Lib/API/Finance/index";
import { DateFormetContext } from "../../../app/App.js";
import AlertDialog from "../../../components/Dialog";
import { CheckFloatValue } from "../../../utils/functions";
const ExpandableRow = (props) => {
  const {
    row,
    index,
    modifyRowData,
    onCheckboxChange,
    check,
    onPayNowClick,
    onReversalClick,
    setDrawerData,
    setOpenModal,
    batchTable,
    financeCheckIds,
    paymentExpandable,
    setPaymentExpandable,
    setBatchList,
    page,
    transactionId,
    checkData,
  } = props;
  const [disabled, setDisable] = useState(true);
  const dateFormet = React.useContext(DateFormetContext);
  const [open, setOpen] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [reversalActionTypes, setReversalActionTypes] = useState(false);
  const [getTypes, setActionTypes] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const [chargeId, setchargeId] = useState();
  const [reversalDialog, setReversalDialog] = useState(false);


  const fetchReversalTypes = async (type, Id1, Id2) => {
    setchargeId(Id1);
    setPaymentId(Id2);
    const token = await getAccessTokenSilently();
    await FinanceService.getAllReversalTypes(type, token).then((res) => {
      setActionTypes(res?.result);
    }).catch((err) => {
      toastr.error('Error', err?.data?.message);
    })
    setReversalActionTypes(true);
  };

  const handleCloseDialog = () => {
    setReversalActionTypes(false);
  };

  useEffect(() => {
    if (paymentExpandable === false) {
      setOpen(false);
      setPaymentExpandable(true);
    }
  }, [paymentExpandable]);

  const filteredCharge = checkData?.map((item) => {
    return transactionId?.includes(item?._id);
  });

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={
          (row.charge?.payment.History.length === 1 ||
            row.charge?.payment.History.length > 1) &&
          open === true
            ? "exp_head"
            : ""
        }
      >
        {batchTable === "batchtable" ? (
          <></>
        ) : (
          <TableCell
            width="10%"
            className={
              financeCheckIds &&
              !financeCheckIds?.includes(row?.Account?.id) &&
              check?.length >= 1
                ? "current-check main-check main-width-class "
                : "main-check main-width-class"
            }
            style={{
              pointerEvents:
                (disabled &&
                  financeCheckIds &&
                  !financeCheckIds?.includes(row?.Account?.id) &&
                  check.length >= 1) ||
                row?.charge?.status === "payment"
                  ? "none"
                  : "initial",
            }}
            padding="checkbox"
          >
            <MyCheckbox
              name={"visible"}
              handlechange={(e) => onCheckboxChange(e, row, index, page)}
              checked={
                !!check.includes(index) ||
                !!(
                  filteredCharge?.[0] &&
                  check &&
                  check?.includes(row?.charge?._id) &&
                  financeCheckIds?.includes(row?.Account?.id)
                )
              }
            />
          </TableCell>
        )}
        {row.charge?.payment?.History?.length >= 1 ? (
          <TableCell width="10%" className="expand-button main-width-class">
            <IconButton
              aria-label="expand ro w"
              size="large"
              onClick={() => setOpen(!open)}
              className="expandale-icon"
            >
              {open ? (
                <IndeterminateCheckBoxOutlinedIcon />
              ) : (
                <AddBoxOutlinedIcon />
              )}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell
            width="10%"
            className="table-cell-remove main-width-class"
          />
        )}

        <TableCell
          className="status-head main-width-class"
          width="15%"
          component="th"
          scope="row"
          onClick={() => {
            setOpen(true);
            setDrawerData(row);
            setOpenModal(true);
          }}
        >
          <div className="transaction-status">
            {row.payment_status === "Pending" ? (
              <div className="add-div">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.8808 6.49945H6.5002V10.8801C6.5002 11.2255 6.21984 11.5059 5.8744 11.5059C5.52896 11.5059 5.2486 11.2255 5.2486 10.8801V6.49945H0.867989C0.522546 6.49945 0.242188 6.21909 0.242188 5.87365C0.242188 5.52821 0.522546 5.24785 0.867989 5.24785H5.2486V0.867239C5.2486 0.521796 5.52896 0.241438 5.8744 0.241438C6.21984 0.241438 6.5002 0.521796 6.5002 0.867239V5.24785H10.8808C11.2263 5.24785 11.5066 5.52821 11.5066 5.87365C11.5066 6.21909 11.2263 6.49945 10.8808 6.49945Z"
                    fill="#3F4255"
                  />
                </svg>
              </div>
            ) : (
              <div style={{ width: 22 }}></div>
            )}
            <div className="pl-0">
              <div
                className="status"
                style={
                  row.charge_type === "charge" ||
                  row.charge_type === "donation" ||
                  row.charge_type === "credit_on_account"
                    ? { color: "#FBA12C" }
                    : row.charge_type === "payment"
                    ? { color: "#81DC91" }
                    : {}
                }
              >
                {row?.charge_type?.toUpperCase()}
              </div>
              <div className="transaction-account-name table-row ">
                {row?.charge?.status?.toUpperCase()}
              </div>
              <div className="sub-status">
                {row?.charge?.status === "payment" &&
                row?.charge?.payment?.status === "partialy_applied"
                  ? "PARTIALLY APPLIED"
                  : row?.charge?.status === "payment" &&
                    row?.charge?.payment?.status !== "partialy_applied"
                  ? row?.charge?.payment?.status.toUpperCase()
                  : row?.charge?.sub_status?.toUpperCase()}
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell
          className="date-head main-width-class"
          align="left"
          onClick={() => {
            setDrawerData(row);
            setOpenModal(true);
          }}
        >
          <div className="transaction-led-sub table-row">
            <p className="sub">
              {formatDate({date:row?.createdAt, formatType: "defaultWithUTC"})}
            </p>
          </div>
        </TableCell>
        <TableCell
          className="date-head main-width-class"
          align="left"
          onClick={() => {
            setDrawerData(row);
            setOpenModal(true);
          }}
        >
          <div className="transaction-led-sub table-row">
            <p className="sub">
              {row?.charge?.due_date
                ? formatDate({date:row?.charge?.due_date, formatType: "defaultWithUTC"})
                : ""}
            </p>
          </div>
        </TableCell>
        <TableCell
          className="charged-head main-width-class"
          align="left"
          onClick={() => {
            setDrawerData(row);
            setOpenModal(true);
          }}
        >
          <div
            style={{ wordBreak: "break-all", whiteSpace: "normal" }}
            className="transaction-charged table-row"
            title={row?.charge?.amount ?? "N/A"}
          >
            {numberFormat({number:row?.charge?.amount, decimal:2, style: "currency"})}
          </div>
        </TableCell>
        <TableCell
          style={{ wordBreak: "break-all", whiteSpace: "normal" }}
          className="paid-head main-width-class"
          align="left"
          onClick={() => {
            setDrawerData(row);
            setOpenModal(true);
          }}
        >
          <div
            className="transaction-paid table-row"
            title={row?.charge?.paid ?? "N/A"}
          >
            {row?.charge?.paid === 0
              ? "-"
              : `${numberFormat({number:row?.charge?.paid, decimal:2, style: "currency"})}`}
          </div>{" "}
        </TableCell>
        
          <TableCell
          style={{ wordBreak: "break-all", whiteSpace: "normal" }}
          className="paid-head main-width-class"
          align="left"
        >
          <div className="ellipsDueAmount">
            {isNaN(+row?.charge?.amount +
              row?.charge?.discount -
              row?.charge?.paid) ? numberFormat({number: 0, decimal:2, style: "currency"}) :  numberFormat({number:
                (
                  +row?.charge?.amount +
                  row?.charge?.discount -
                  row?.charge?.paid
                ), decimal:2, style: "currency"}
              )}
          </div>
        </TableCell>

        <TableCell
          className="ledger-head main-width-class"
          align="left"
          onClick={() => {
            setDrawerData(row);
            setOpenModal(true);
          }}
        >
          <div className="transaction-led-sub table-row">
            <div className="sub">{row?.Ledger?.name}</div>
            <div
              className="led ellipseText"
              title={
                row?.SubLedger === null &&
                row?.comment_invoice?.visible === true &&
                row?.comment_invoice?.note !== null
                  ? row?.comment_invoice?.note
                  : row?.SubLedger?.name
                  ? row?.SubLedger?.name
                  : "-"
              }
            >
              {row?.SubLedger === null &&
              row?.comment_invoice?.visible === true &&
              row?.comment_invoice?.note !== null
                ? row?.comment_invoice?.note
                : row?.SubLedger?.name
                ? row?.SubLedger?.name
                : "-"}
            </div>
          </div>{" "}
        </TableCell>
        <TableCell
          className="transaction-head main-width-class"
          align="left"
          onClick={() => {
            setDrawerData(row);
            setOpenModal(true);
          }}
        >
          <div className="transaction-trans table-row" title={row._id ?? "N/A"}>
            {row._id ? row._id.slice(0, 5) + "..." : "-"}
          </div>{" "}
        </TableCell>
        <TableCell className="exp-action-head main-width-class " align="right">
          <div style={{ cursor: "pointer", paddingleft: "0px" }}>
            <Action
              style={{ width: "16px" }}
              action="finance"
              onEdit="edit"
              chargeStatus={row?.charge?.payment?.status}
              onPayClick={() => {
                onPayNowClick(row);
              }}
              onReversalClick={() => {
                setReversalDialog(true);

                fetchReversalTypes(
                  row?.charge?.status === "charge"
                    ? "charge"
                    : row?.charge?.status === "payment"
                    ? "payment"
                    : ""
                );
              }}
              setBatchList={setBatchList}
              row={row}
            />
          </div>
          <AlertDialog
            reversalDialog={reversalDialog}
            open={reversalActionTypes}
            getTypes={getTypes}
            handleClose={handleCloseDialog}
            onDecline={() => {
              setReversalActionTypes(false);
            }}
            onAccept={(id, type, hidden) =>
              onReversalClick(
                row?.charge?.status === "charge"
                  ? {
                      reversal_type: {
                        id: id,
                        type: type,
                        hidden: hidden,
                      },
                      chargeId: row?.charge?._id,
                    }
                  : "",
                setReversalActionTypes
              )
            }
            content={"Choose a reversal type "}
          />
        </TableCell>
      </TableRow>
      <TableRow className="myexpendRow">
        <TableCell style={{ padding: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" />
              <Table className="expandable-table" aria-label="purchases">
                <TableHead></TableHead>
                <TableBody>
                  {row?.charge?.payment?.History?.map((item) => (
                    <TableRow key={item.id} className="child_exp_row">
                      <TableCell
                        className="checkbox-cell"
                        component="td"
                        scope="row"
                      />
                      <TableCell
                        className="button-cell exp-button-cell"
                        component="td"
                        scope="row"
                      />
                      <TableCell
                        component="th"
                        scope="row"
                        className="exp-status-cell"
                        onClick={() => {
                          modifyRowData(row, item);
                        }}
                      >
                        <div className="transaction-status">
                          {row.payment_status === "Pending" ? (
                            <div className="add-div">
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.8808 6.49945H6.5002V10.8801C6.5002 11.2255 6.21984 11.5059 5.8744 11.5059C5.52896 11.5059 5.2486 11.2255 5.2486 10.8801V6.49945H0.867989C0.522546 6.49945 0.242188 6.21909 0.242188 5.87365C0.242188 5.52821 0.522546 5.24785 0.867989 5.24785H5.2486V0.867239C5.2486 0.521796 5.52896 0.241438 5.8744 0.241438C6.21984 0.241438 6.5002 0.521796 6.5002 0.867239V5.24785H10.8808C11.2263 5.24785 11.5066 5.52821 11.5066 5.87365C11.5066 6.21909 11.2263 6.49945 10.8808 6.49945Z"
                                  fill="#3F4255"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div style={{ width: 20 }}></div>
                          )}

                          <div className="">
                            <div
                              className="status"
                              style={
                                row?.charge?.status === "charged"
                                  ? { color: "#FBA12C" }
                                  : row?.charge?.status === "payment"
                                  ? { color: "#81DC91" }
                                  : {}
                              }
                            >
                              {row?.charge?.status.toUpperCase()}
                            </div>
                            <div className="transaction-account-name table-row">
                              {row.charge?.payment?.status ===
                              "partialy_applied"
                                ? "partially applied"
                                : row?.charge?.payment.status}
                            </div>
                            <div className="sub-status">
                              {row.charge?.payment?.status ===
                              "partialy_applied"
                                ? "partially applied "
                                : row?.charge?.payment.status ??
                                  row?.charge?.sub_status}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="exp-date-cell"
                        onClick={() => {
                          modifyRowData(row, item);
                        }}
                      >
                        <div className="transaction-led-sub table-row">
                          <div className="sub">
                            {formatDate({date:item?.created_at, formatType: "defaultWithUTC"})}
                          </div>
                          <div className="led">
                            {row?.charge?.due_date
                              ? formatDate({date:row?.charge?.due_date, formatType: "defaultWithUTC"})
                              : ""}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{ wordBreak: "break-all", whiteSpace: "normal" }}
                        component="th"
                        scope="row"
                        className="exp-charge-cell"
                        onClick={() => {
                          modifyRowData(row, item);
                        }}
                      >
                        {" "}
                        <div className="transaction-charged table-row charge-expandable">
                          {row?.charge?.amount === 0
                            ? "-"
                            : `${numberFormat({number:row?.charge?.amount, decimal:2, style: "currency"})}`}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{ wordBreak: "break-all", whiteSpace: "normal" }}
                        className="exp-paid-cell"
                        onClick={() => {
                          modifyRowData(row, item);
                        }}
                      >
                        {" "}
                        <div className="transaction-paid table-row paid-expandable">
                          {item?.amount === 0
                            ? "-"
                            : `${numberFormat({number:
                                item?.amount, decimal:2, style: "currency"}
                              )}`}
                        </div>{" "}
                      </TableCell>
                      <TableCell
                        className="exp-ledger-cell "
                        onClick={() => {
                          modifyRowData(row, item);
                        }}
                      >
                        <div className="transaction-led-sub table-row">
                          <div className="sub">{row?.Ledger?.name}</div>
                          <div className="led">
                            {row?.SubLedger?.name ?? ""}
                          </div>
                        </div>{" "}
                      </TableCell>
                      <TableCell
                        className={
                          batchTable === "batchtable"
                            ? "exp-id-cell"
                            : "exp-id-cell"
                        }
                        onClick={() => {
                          modifyRowData(row, item);
                        }}
                      >
                        <div
                          className="transaction-trans table-row"
                          title={item._id}
                        >
                          {item._id ? item._id.slice(0, 5) + "..." : "-"}
                        </div>{" "}
                      </TableCell>
                      <TableCell className="icon-cell">
                        {row?.charge?.status === "payment" ? (
                          <div>{""}</div>
                        ) : (
                          <div
                            style={{ cursor: "pointer", paddingleft: "0px" }}
                          >
                            <Action
                              style={{ width: "16px" }}
                              action="finance"
                              onPayClick={() => onPayNowClick(row)}
                              onReversalClick={() => {
                                setReversalDialog(true);
                                fetchReversalTypes(
                                  row?.charge?.status === "charge"
                                    ? "charge"
                                    : row?.charge?.status === "payment"
                                    ? "payment"
                                    : "",
                                  row?.charge?._id,
                                  item._id
                                );
                              }}
                              row={item}
                              rowId={item?.is_reversed}
                              mainChargeStatus={row?.charge?.status}
                            />
                          </div>
                        )}

                        <AlertDialog
                          reversalDialog={reversalDialog}
                          open={reversalActionTypes}
                          getTypes={getTypes}
                          handleClose={handleCloseDialog}
                          onDecline={() => {
                            setReversalActionTypes(false);
                          }}
                          onAccept={(id, type, hidden) =>
                            onReversalClick(
                              row?.charge?.status === "charge"
                                ? { chargeId: row?.charge?._id }
                                : row?.charge?.status === "payment"
                                ? {
                                    reversal_type: {
                                      id: id,
                                      type: type,
                                      hidden: hidden,
                                    },
                                    chargeId: chargeId,
                                    paymentId: paymentId,
                                  }
                                : "",
                              setReversalActionTypes
                            )
                          }
                          content={"Choose a reversal type "}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(ExpandableRow);
