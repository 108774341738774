import { Auth0Provider } from "@auth0/auth0-react";
import { setLocalizationMoment } from "helper/dateFormat";
import AuthService from 'Lib/API/Auth';
import MemberService from "Lib/API/Member";
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import history from "../../utils/history";
import EventsCalendar from "../EventsCalendar";

const EventsCalenderLink = ({withoutTitle,width}) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  function convertUTCToLocalDate(date,duration,type) {    
    if (!date) {
      return new Date();
    }
    date = new Date(date);
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes() + (type === "end" ? duration || 120 : 0),
    );
    return date;
  }


  const generateToken = async () => {
    const subDomain = window.location.hostname.split('.')[0];
    const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
    console.log("hostName",hostName)
    setLoading(true);
    try {
      const res = await AuthService.generateToken(hostName);
      if (!res.hasPayment) {
        window.location.replace(res?.shul_website);
        return;
      }
      localStorage.setItem('externalToken', res?.token);
      localStorage.setItem('orgId', res?._id);
      localStorage.setItem('shul_website', res?.shul_website);
      localStorage.setItem('hasPayment', res?.hasPayment);
      localStorage.setItem('logoImg', res?.logoImg);
      localStorage.setItem('orgName', res?.orgName);
      document.title = `${res?.orgName}`;
      localStorage.setItem('country', JSON.stringify(res?.country || {}));

      setLocalizationMoment(res?.country?.local || "en");

      
      const eventsRes = await MemberService.getEventsList(res?.token, res?._id);
      setEvents(eventsRes?.payload?.data?.map((event) => {
        return {
          ...event,
          start: convertUTCToLocalDate(event?.date),
          end: convertUTCToLocalDate(event?.date, event?.eventDuration?.value, "end"),
          title: event?.eventName,
          id: event?._id,
        }
      }));
    
    } catch (error) {
      toastr.error('Error', error?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    generateToken()
  }, []);

  return (
    <EventsCalendar events={events} withoutTitle={withoutTitle} width={width}/>
  )
}

export default EventsCalenderLink