import React, { useEffect, useState } from "react";

import { ReactComponent as AddIcon } from "assets/icons/add-plus.svg";
import Charge from "./ChargeType/Charge";
import CreditOnAccount from "./ChargeType/CreditOnAccount";
import Donation from "./ChargeType/Donation";
import { LabelField } from "components";
import moment from "moment";
import { toastr } from "react-redux-toastr";
// import { Loader } from "components/Loader";
import { DateFormetContext } from "../../../app/App.js";

const types = ["Charge", "Donation", "Credit On Account"];

const ChargeDetails = ({ ...props }) => {
  const [state, setState] = useState([]);
  const [show, setShow] = useState(
    props?.values?.values[0]?.charge_type === "donation"
      ? "Donation"
      : props?.values?.values[0]?.charge_type === "credit on account"
      ? "Credit On Account"
      : "Charge"
  );
  useEffect(() => {
    setState((prev) => [...prev, show]);
  }, [show]);
  const dateFormet = React.useContext(DateFormetContext);
  const getAddCharge = () => {
    const enableHandleNext = props.values?.values?.every((item) => {
      const {
        Ledger,
        due_date,
        payment_date,
        total_amount,
        charge_type,
      } = item;
      return !!(
        Ledger &&
        (charge_type === "credit on account" || due_date) &&
        payment_date &&
        total_amount
      );
    });
    if (enableHandleNext) {
      props.setValues({
        ...props.values,
        values: [
          ...props.values.values,
          {
            account_name: props?.values?.account_name?.name,
            tax_type: "%",
            discount_type: "%",
            payment_date: moment(new Date())
              .utcOffset("+0100")
              .format(dateFormet?.replaceAll("/", "-")),
            due_date: moment(new Date())
              .add(1)
              .utcOffset("+0100")
              .format(dateFormet?.replaceAll("/", "-")),
            is_splitted: false,
          },
        ],
      });
    } else {
      toastr.info("Validation", "Please fill all the required fields first");
    }
  };
  useEffect(() => {
    if (!props.values.tribute_information)
      props.setFieldValue("tribute_information", props.values.account_name);
  }, [props.values.account_name]);

  const removeCharge = (idx) => {
    const checksList = [...props.values?.values];
    checksList.splice(idx, 1);
    props.setValues({
      ...props.values,
      values: [...checksList],
    });
  };

  const renderComponent = (index) => {
    switch (show) {
      case "Charge":
        return (
          <Charge
            index={index}
            types={types}
            setShow={setShow}
            show={show}
            removeCharge={removeCharge}
            {...props}
          />
        );
      case "Donation":
        return (
          <Donation
            index={index}
            types={types}
            setShow={setShow}
            show={show}
            {...props}
          />
        );
      case "Credit On Account":
        return (
          <CreditOnAccount
            index={index}
            types={types}
            setShow={setShow}
            show={show}
            {...props}
          />
        );
      default:
        return (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            {/* <Loader /> */} loading...
          </div>
        );
    }
  };

  return (
    <div className="">
      {props.values?.values?.map((_, index) => {
        return <p>{renderComponent(index)}</p>;
      })}
      <div>
        {props.values?.isTransactionSave ? null : (
          <div
            className={`d-flex pl-30 pt-3 ${
              props?.values?.account_name?.name ? "" : "grey-color"
            }`}
            onClick={getAddCharge}
          >
            <span className="cursor-pointer px-3">
              <AddIcon />
            </span>
            <span className="add-show">
              <LabelField
                title={`Add ${show}`}
                color={`#2555ef`}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChargeDetails;
