import React, { useRef, useState, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import hebrew from "simple-keyboard-layouts/build/layouts/hebrew";
import "./index.scss";
export function VirtualKeyboard({ handleChange, setOpenKeyboard, value }) {
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
    if (button === "{enter}") setOpenKeyboard(false);
  };
  const check = () => {
    console.log(keyboard?.current?.setInput(value));
    return keyboard?.current?.setInput(value);
  };
  useEffect(() => {
    check();
  }, [value]);

  return (
    <div className="my-keyboard">
      <Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        layoutName={layout}
        rtl={true}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        {...hebrew}
      />
    </div>
  );
}
