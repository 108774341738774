import { useAuth0 } from '@auth0/auth0-react';
import AuthService from 'Lib/API/Auth';
import MemberService from 'Lib/API/Member';
import { getLocalStorageRegistration, setLocalStorageDateRange, setLocalStorageRegistration } from '_metronic/_helpers';
import schoolImg from 'assets/images/SchoolImag.png';
import { setLocalizationMoment } from 'helper/dateFormat';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Class } from 'services/api/class';
import ClassInfo from '../ClassInfo/ClassInfo';
import { AboutUs, Body, Content, Description, LeftSide, LogoImage, RightSide, SchoolImage, Spinner, Title } from './Theme';

const JoinSchool = () => {
    const { getAccessTokenSilently,isAuthenticated, isLoading: isAuthenticatedLoading } = useAuth0();

    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [isloading, setIsLoading] = useState(false);
    const [classDetailsLoading, setClassDetailsLoading] = useState(true);
    const [coverImage, setCoverImage] = useState(null);
    const dispatch = useDispatch()
    const Logo = localStorage.getItem('logoImgSchool');
    const token = localStorage.getItem('token');

    const calculateYearsBetwenAgeRanges = (StartYear, EndYear) => {
        const currentYear = new Date().getFullYear()
        const startYear = currentYear - StartYear
        const endYear = currentYear - EndYear
        setLocalStorageDateRange({
            startDate: `12-31-${startYear}`,
            endDate: `01-01-${endYear}`
        });
    }
    const getClasseById = async (Token) => {
        setIsLoading(true)
        setClassDetailsLoading(true)
        try {
            const res = await MemberService.getClassById(Token, params?.id)
            setCoverImage(res?.result?.background || "")
            dispatch(Class.actions.setClassDetails(res?.result));
            setLocalStorageRegistration({
                activeStep: getLocalStorageRegistration()?.activeStep,
                registerFormId: res?.result?.form,
                RegisterData: getLocalStorageRegistration()?.RegisterData,
                RegisterRequiredFelids: getLocalStorageRegistration()?.RegisterRequiredFelids,
                classDetails: res?.result
            });
            calculateYearsBetwenAgeRanges(res?.result?.class_details?.ageStart, res?.result?.class_details?.ageEnd)
            const startDateDiff = moment().diff(moment(res?.result?.class_start_date), 'days');
            const endDateDiff = moment().diff(res?.result?.class_end_date ? moment(res?.result?.class_end_date) : moment(), 'days');
            if (!res?.result?.allow_non_members_register && !token) {
                console.log("token inside check", token);
                enqueueSnackbar('This class is available exclusively for members', {
                    variant: 'error',
                });
            }
            else if (endDateDiff > 0) {
                enqueueSnackbar('This class is no longer available due to its expiration date', {
                    variant: 'error',
                });
            }
            else if (startDateDiff < 0) {
                enqueueSnackbar(`This class has not yet begun. Please check back closer to the start date for access`, {
                    variant: 'error',
                });
            }
        } catch (error) {
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
            dispatch(Class.actions.setClassDetails({}));

        } finally {
            setIsLoading(false)
            setClassDetailsLoading(false)
        }
    };
    const generateToken = async () => {
        setIsLoading(true)
        const subDomain = window.location.hostname.split('.')[0];
        const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
        try {
            const res = await AuthService.generateToken(hostName);
            const token = isAuthenticated ? await getAccessTokenSilently() : res?.token
            localStorage.setItem('paymentMethod', JSON.stringify(res?.paymentMethod));
            localStorage.setItem('externalToken', res?.token);
            localStorage.setItem('orgId', res?._id);
            localStorage.setItem('logoImgSchool', res?.logoImg);
            localStorage.setItem('orgName', res?.orgName);
            localStorage.setItem('country', JSON.stringify(res?.country || {}));

            setLocalizationMoment(res?.country?.local || "en");
            document.title = `${res?.orgName}`;

            const organization = {
                provider_information: {
                    provider: res?.provider,
                    keys: {
                    ...(res?.publicKey ? {publicKey: res?.publicKey} : {}) 
                    }
                }
            }
            localStorage.setItem("organization",JSON.stringify(organization));


            getClasseById(token);
        } catch (error) {
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
        } finally {
            setIsLoading(false)
        }
    };


    useEffect(() => {
        localStorage.removeItem('redirectUrl');
    }, [])
    useEffect(() => {
        if(isAuthenticatedLoading) return;
        generateToken()
    }, [isAuthenticatedLoading, isAuthenticated])
    return (
        <Body>
            {
                isloading ? <Spinner><PulseLoader color={'#2555EF'} size={'30px'} /></Spinner> :
                    <>
                        <LeftSide>
                            <LogoImage src={Logo} alt='Logo' />
                            {!classDetailsLoading && <SchoolImage src={coverImage || schoolImg} alt='notFound' />}
                            <Content>
                                {/* <Title>Join Our School</Title> */}
                                {/* <Description>Lorem ipsum dolor sit amet consectetur. Malesuada eleifend sapien dui viverra elit. Porttitor et non odio odio amet placerat tincidunt aliquet.
                                    About Us</Description> */}
                                {/* <AboutUs>About us</AboutUs> */}
                            </Content>
                        </LeftSide>
                        <RightSide >
                            <ClassInfo steps={false} param={params?.id} />
                        </RightSide>
                    </>
            }

        </Body>
    );
};

export default JoinSchool;
