import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@material-ui/core";
import { Step } from "@material-ui/core";
import { StepLabel } from "@material-ui/core";
import { Stepper } from "@material-ui/core";
import { Text } from "components";
import { FormikWizard } from "formik-wizard-form";
import AuthService from "Lib/API/Auth";
import { withUserExist } from "../../CustomHooks";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AddittionalInformation from "./AddittionalInformation";
import AddMourners from "./AddMourners";
import DeceacedDetails from "./DeceacedDetails";
import "./index.scss";
import MournersList from "./MournersList";
import { DeceacedDetailsSchema } from "./StepsValidation";
import { toastr } from "react-redux-toastr";
import { Button } from "components/NewButton/Button";
import { PulseLoader } from "react-spinners";

const AddNewYahrzeit = ({
  setIsOpenMergePopUp,
  isOpenMergePopUp,
  setRefresh,
  refresh,
  ...props
}) => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const [finalValues, setFinalValues] = useState({});
  const [finished, setFinished] = useState(false);
  const [mourners, setMourners] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mourner, setMourner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = { ...props };

  const handleDeleteMourner = (index) => {
    var array = [...mourners];
    if (index !== -1) {
      array.splice(index, 1);
      setMourners(array);
    }
  };

  useEffect(() => {
    //when finished call api to create yaherzit
    async function handleCreate() {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      await AuthService.postAddDeceased(finalValues, token)
        .then((res) => {
          setLoading(false);
          setIsLoading(false);
          toastr.success("Success", "Yahrzeit has been created successfully");
          setIsOpenMergePopUp(!isOpenMergePopUp);
          setRefresh(!refresh);
        })
        .catch((err) => {
          setLoading(false);
          setIsLoading(false);
          console.log("Error", err?.data);
          // toastr.error("Error", err?.data?.message);
        });
    }
    if (finalValues?.firstName && finalValues?.lastName) handleCreate();
  }, [finished]);
  return (
    <div className="w-100">
      <FormikWizard
        initialValues={{
          functions: { setMourners: setMourners },
          firstName: "",
          lastName: "",
          hebrew_name: "",
          date_of_death: "",
          hebrew_date: "",
          before_sunset: false,
          has_plaque: false,
          doesnt_have_mourner: false,

          mourners_list: [],
          plaques_number: "",
          cemetery: "",
          about_deceased: "",
        }}
        onSubmit={(values) => {
          // setOpenModal(true);
          setTimeout(() => {
            setFinished(true);
          }, 2000);
          setMourner(values?.doesnt_have_mourner);
          const mourners_list = mourners.map((mourner) => {
            const { firstName, lastName, ...data } = { ...mourner };
            return data;
          });
          const input = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            date_of_death: values?.date_of_death,
            hebrew_date_of_death: values?.hebrew_date,
            before_sunset: values?.before_sunset,
            has_plaque: values?.has_plaque === true ? "Yes" : "No",
            doesnt_have_mourner:
              values?.doesnt_have_mourner === true ? "Yes" : "No",
            mourners_list: mourners_list,
          };
          if (values?.plaques_number)
            input.plaque_number = values?.plaques_number;
          if (values?.cemetery) input.cemetery = values?.cemetery;
          if (values?.hebrew_name)
            input.hebrew_name_of_Death = values?.hebrew_name;
          if (values?.about_deceased) {
            input.about_the_deceased = values?.about_deceased;
          }

          setFinalValues(input);
          console.log(input, "input");
        }}
        validateOnNext
        activeStepIndex={0}
        steps={[
          {
            component: DeceacedDetails,
            validationSchema: DeceacedDetailsSchema,
          },
          {
            component: AddMourners,
          },
          {
            component: AddittionalInformation,
          },
        ]}
      >
        {({
          currentStepIndex,
          renderComponent,
          handlePrev,
          handleNext,
          isNextDisabled,
          isPrevDisabled,
          ...props
        }) => {
          return (
            <div className="first-time">
              <div className="new-yahrzeit">
                <div className="yahrzeit-header py-7">
                  <div className="fom-steps">
                    <Stepper activeStep={currentStepIndex}>
                      <Step completed={currentStepIndex > 0} className="step">
                        <StepLabel className="step-label">
                          Deceased details
                        </StepLabel>
                      </Step>
                      <Step completed={currentStepIndex > 1}>
                        <StepLabel>Add Mourners</StepLabel>
                      </Step>
                      <Step completed={finished}>
                        <StepLabel>Additional information</StepLabel>
                      </Step>
                    </Stepper>
                  </div>
                </div>
                <div className="d-flex pb-5">
                  <div className="form-content-yahrzeit pr-10">
                    <div className="form-details">{renderComponent()}</div>
                    <div
                      className={
                        currentStepIndex !== 0
                          ? "form-footer-prev"
                          : "form-footer"
                      }
                    >
                      {currentStepIndex !== 0 && (
                        <Button
                          width="150px"
                          height="40px"
                          children="Previous"
                          style={{ border: 0 }}
                          onClick={handlePrev}
                          isDisabled={isPrevDisabled}
                          type="Primary"
                        />
                      )}
                      {currentStepIndex !== 1 && (
                        <Button
                          width="150px"
                          height="40px"
                          style={{ border: 0 }}
                          children={
                            isLoading ? (
                              <PulseLoader
                                color={"#fff"}
                                size={"7px"}
                                style={{ marginTop: "-2px" }}
                              />
                            ) : currentStepIndex === 2 ? (
                              "Create yahrzeit"
                            ) : (
                              "Continue"
                            )
                          }
                          onClick={handleNext}
                          isDisabled={isNextDisabled}
                          type="Primary"
                        />
                      )}
                    </div>
                  </div>
                  {currentStepIndex === 1 && (
                    <MournersList
                      handleDelete={handleDeleteMourner}
                      handleClick={handleNext}
                      mourners={mourners}
                      {...props}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </FormikWizard>
    </div>
  );
};

export default withUserExist(AddNewYahrzeit);
