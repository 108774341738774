import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip
} from "@mui/material";
import { useRadioGroup } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import React from 'react';


const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
  ".MuiFormControlLabel-label": {
    fontFamily: 'Poppins, Helvetica, "sans-serif"'
  },
}));


function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}


const RadioButtons = ({
  value,
  name,
  onChange,
  defaultValue,
  options,
  size,
  row = false
}) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="Members"
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        defaultValue={defaultValue}
        row={row}
      >
        {options?.map((option) => (
          <Tooltip
            key={option.value}
            title={option?.showTooltip ? option?.tooltip : ""}
            placement={option?.tooltipPlacement ?? "top-start"}
          >
            <span>
              <MyFormControlLabel
                value={option.value}
                control={<Radio size={size} />}
                label={option.label}
                disabled={option.disabled}
              />
            </span>
          </Tooltip>
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioButtons