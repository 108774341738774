/* eslint-disable jsx-a11y/alt-text */
import { useAuth0 } from '@auth0/auth0-react';
import { TableContainer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { CircularProgress } from '@mui/material';
import PaidIcon from "assets/icons/PaidIcon.svg";
import PendingIcon from "assets/icons/PendingIcon.svg";
import { numberFormat } from 'helper/numberFormat';
import MemberService from 'Lib/API/Member';
import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from "react-router-dom";
import './index.scss';
import { Status } from './theme';
const TableCellHead = withStyles((theme) => ({
  root: {
    height: '39px',
    padding: '0px 20px',
    borderBottom: 'none',
    fontFamily: 'Poppins',
  },
}))(TableCell);

const TableCellBody = withStyles((theme) => ({
  root: {
    height: '65px',
    padding: '0px 20px',
    fontFamily: 'Poppins',
  },
}))(TableCell);

const ClassList = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [count, setCount] = useState();

  const [page, setPage] = useState(0);

  const enableLoading = (value) => {
    setLoading(value);
  };
  async function getClassesList() {
    enableLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const payload = {
      };
      const result = await MemberService.getClassParticipantsByAccounts({
        token,
        payload,
        page: page + 1,
        limit: 10,
      });
      setList(result?.payload?.data);
      setCount(result?.payload?.pagination?.count);
    } catch (error) {
      toastr.error('Error', error?.data?.message || 'Something went wrong!');
    } finally {
      enableLoading(false);
    }
  }
  React.useEffect(() => {
    getClassesList();
  }, [page]);



const paidAmount = (row) => {
  const approveTotalPaid = row?.payment?.approve?.totalPaid || 0;
  const registerTotalPaid = row?.payment?.register?.totalPaid || 0;
  const paid = approveTotalPaid || registerTotalPaid ? (+approveTotalPaid + +registerTotalPaid) : 0;
  const amount = numberFormat({number: +paid, decimal: 2, style: 'currency'});
  return amount;

}



  return (
    <div
      className="w-100 school_class_list mt-4"
      style={{
        padding: '0rem 0rem 25rem 0rem',
      }}
    >
      <div className={'family-table-container'}>
        <div className="current-class-table-container">
          <div style={{ padding: '17px' }}>
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead className="charges-table">
                  <TableRow>
                    <TableCellHead className="class__header__cell className_head">
                      <div>CLASS NAME</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell className_head"
                    >
                      <div>STUDENT FIRST NAME</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell className_head"
                    >
                      <div>STUDENT LAST NAME</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell year__head"
                    >
                      <div>YEAR</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell className_head"
                    >
                      <div>SCHOOL GRADE</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell total__cost"
                    >
                      <div>REGISTRATION STATUS</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell total__cost"
                    >
                      <div>PAYMENT STATUS</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell total__cost"
                    >
                      <div>PAID AMOUNT</div>
                    </TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <div
                          className="user__loader"
                          style={{ height: '320px' }}
                        >
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : list?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <h6 style={{ height: '320px', marginTop: '100px' }}>
                          No Classes Found
                        </h6>
                      </TableCell>
                    </TableRow>
                  ) : (
                    list?.map((row, i) => (
                      <React.Fragment>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className='class_tableRow'

                        >
                          <TableCellBody
                            className="status-head main-width-class class-list-table-cell"
                            component="th"
                            scope="row"
                           
                          >
                            <div className="transaction-status">
                              <div style={{ width: 22 }}></div>

                              <div className="pl-0">
                                <div className="school-table-className">
                                  {row?.class?.class_name}
                                </div>
                              </div>
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="main-width-class class-list-table-cell"
                            align="left"
                          
                          >
                            <div className="table-row">
                              <div className="school-table-year">{row?.firstName}</div>
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="main-width-class class-list-table-cell"
                            align="left"
                          
                          >
                            <div className="table-row">
                              <div className="school-table-year">{row?.lastName}</div>
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="main-width-class class-list-table-cell"
                            align="left"
                          
                          >
                            <div className="table-row">
                              <div className="school-table-year">{row?.class?.year}</div>
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="main-width-class class-list-table-cell"
                            align="left"
                          
                          >
                            <div className="table-row">
                              <div className="school-table-year">{row?.class?.class_details?.grade}</div>
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="main-width-class class-list-table-cell"
                            align="left"
                          
                          >
                            <div className="table-row">
                              <div className="school-table-year">{row?.registrationStatus !== "not-approve" ? row?.registrationStatus : "-"}</div>
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="main-width-class class-list-table-cell"
                            align="left"
                          
                          >
                            <div className="table-row">
                            <Status type={row?.payment?.approve?.status}>
                              <img src={row?.payment?.approve?.status === "paid" ? PaidIcon : PendingIcon} alt="paid-icon"></img>{" "}
                              {row?.payment?.approve?.status || "pending"}
                            </Status>
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="main-width-class class-list-table-cell"
                            align="left"
                          
                          >
                            <div className="table-row">
                              <div className="school-table-year">
                              {paidAmount(row)}
                              </div>
                            </div>
                          </TableCellBody>
                        </TableRow>
                      </React.Fragment>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="pagination-custom">
            <Pagination
              count={Math.ceil(count / 10)}
              color="primary"
              variant="outlined"
              page={page + 1}
              onChange={(event, value) => {
                setPage(value - 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassList;
