import React from "react";

const styles = {
  body: {},
};

export const Container = ({ children, style, ...rest }) => {
  return (
    <div
      className="d-flex flex-column flex-grow-1"
      style={{
        ...styles.body,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
