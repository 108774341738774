import { InputField, LabelField, MyCheckbox, SelectField } from "components";
import { TextareaField } from "components/textareaField";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Row } from "react-bootstrap";
import { DatePickerField } from "_metronic/_partials/controls";
import { PriceType } from "./PercentageFixed";
import Select, { components } from "react-select";
import { useSearch } from "hook";
import RightArrowSvg from "../../../../assets/icons/svg/myRightSvg";
import FinanceService from "../../../../Lib/API/Finance";
import { toastr } from "react-redux-toastr";

const CreditOnAccount = ({ types, setShow, show, index, ...props }) => {
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  const formik = props;
  const [loading, setLoading] = React.useState(false);
  const [taxType, setTaxType] = useState("%");
  const [search, setSearch] = useState("");
  const { searchAction } = useSearch({ action: setSearch });
  const [ledgers, setLedgers] = useState();
  const [data, setData] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const [subLedgerOptions, setSubLedgerOptions] = useState();

  useEffect(() => {
    formik.setFieldValue("type_of_charge", "credit_on_account");
    if (formik.values?.nextPayment === false) {
      formik.setFieldValue(`account_name`, {
        id: "",
        name: "",
      });
      formik.setFieldValue(`values.${index}.Ledger`, {
        id: "",
        name: "",
      });
      formik.setFieldValue(`values.${index}.SubLedger`, {
        id: "",
        name: "",
      });
      formik.setFieldValue(`values.${index}.tax`, "");
      formik.setFieldValue(`values.${index}.total_amount`, "");
      formik.setFieldValue(`values.${index}.discount`, "");
      formik.setFieldValue(`values.${index}.note`, "");
    }
  }, []);

  const total = useMemo(() => {
    const acutalTotal = formik.values.values.reduce((a, b) => {
      return a + (b?.total_amount ?? 0);
    }, 0);
    const totalAmount = formik.values.values.reduce((a, b) => {
      const getTotalAmount = a + (b?.total_amount ?? 0);

      // const getFinalAmount =
      //   b?.tax_type === "%" && getTaxValue > 1
      //     ? getTotalAmount +
      //       (getTotalAmount / 100) * (getTaxValue < 1 ? 0 : getTaxValue)
      //     : getTotalAmount + getTaxValue;

      return getTotalAmount;
    }, 0);
    formik.setFieldValue("current_total", totalAmount);
    formik.setFieldValue("actual_total", acutalTotal);
    return Number.isNaN(totalAmount) ? 0 : totalAmount;
  }, [formik.values.values]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      ...selectControlStyles,
    }),
  };
  const getCommaSeperatedValue = (num) => {
    return (
      parseFloat(num?.toString().replace(/,/g, ""))
        .toFixed(2)
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0.0
    );
  };

  const selectControlStyles = {
    backgroundColor: "#F3F6F9",
    borderColor: "#F3F6F9",
    color: "#3F4254",
    transition:
      "color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease",
    paddingTop: "6px",
    paddingBottom: "6px",
    cursor: "pointer",
  };
  const exceptThisSymbols = ["e", "E", "+", "-"];
  const formatOptionLabel = ({ label, account_id }) => {
    return (
      <div className="dropdown-container" key={account_id}>
        <div className="icon-name-container d-flex align-items-center justify-content-start">
          <div className="name-container" style={{ marginInlineStart: 10 }}>
            <div>{label}</div>
          </div>
        </div>
      </div>
    );
  };
  // commits
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <RightArrowSvg label="Emoji" />
      </components.DropdownIndicator>
    );
  };
  const enableLoading = (value) => {
    setLoading(value);
  };
  useEffect(() => {
    async function fetchMyAPI() {
      enableLoading(true);
      const token = await getAccessTokenSilently();
      await FinanceService.getACcountNames(token, search).then((res) => {
        if (res?.accounts) {
          res.accounts.map((item) => {
            item.label = item.family_name;
            item.value = item.account_id;
            return item;
          });
          setData(res?.accounts);
        }
        enableLoading(false);
      }).catch((err) => {
        enableLoading(false);
        toastr.error('Error', err?.data?.message);
      })
    }
    async function getLedgeraccounts() {
      enableLoading(true);
      const token = await getAccessTokenSilently();
      const res = await FinanceService.getLedgerSubLedgerList(token);
      if (res?.ledgers) {
        setLedgers(res?.ledgers);
      }
      enableLoading(false);
    }
    getLedgeraccounts();
    fetchMyAPI();
  }, [search, getAccessTokenSilently]);
  const getLedgerSubLedgerListById = async (event) => {
    formik.setFieldValue(`values.${index}.Ledger`, {
      id: event.value,
      name: event.label,
      account_receivable: event?.account_receivable,
    });
    const ksubLedgers = ledgers?.find((item) => event.value === item._id);
    setSubLedgerOptions(ksubLedgers?.sub_ledgers);
    formik.setFieldValue(`values.${index}.SubLedger`, {
      id: "",
      name: "",
    });
    formik.setFieldValue(`values.${index}.tax`, event?.amount_detuctible);
    formik.setFieldValue(`values.${index}.total_amount`, event?.default_amount);
    formik.setFieldValue(`convenient_fee`, event?.gateway_fee);
    formik.setFieldValue(`require_notes`, event?.notes);
  };
  const onSubledgerChange = (e) => {
    formik.setFieldValue(`values.${index}.SubLedger`, {
      id: e.value,
      name: e.label,
    });
    formik.setFieldValue(`values.${index}.tax`, e?.amount_detuctible);
    formik.setFieldValue(`values.${index}.total_amount`, e?.default_amount);
    formik.setFieldValue(`convenient_fee`, e?.gateway_fee);
    formik.setFieldValue(`require_notes`, e?.notes);
  };

  const options =
    ledgers &&
    ledgers?.map((item) => {
      return {
        label: item?.ledger_name,
        value: item?._id,
        tex: item?.amount_detuctible,
        default_amount: item?.default_amount,
        gateway_fee: item?.gateway_fee,
        notes: item?.notes,
        account_receivable: item?.account_receivable,
      };
    });
  const subLedgersList =
    subLedgerOptions &&
    subLedgerOptions?.map((item) => {
      return {
        label: item.sub_ledger_name,
        value: item._id,
        tex: item?.amount_detuctible,
        default_amount: item?.default_amount,
        gateway_fee: item?.gateway_fee,
        notes: item?.notes,
      };
    });

  const [calculatedAmount, setCalculatedAmount] = useState("00");
  let commingAmount = formik.values?.values?.[index]?.charge?.amount
    ? formik.values?.values?.[index]?.charge?.amount
    : formik?.values?.allData
    ? formik?.values?.allData[0].total_amount
    : formik.values?.values?.[index]?.total_amount
    ? formik.values?.values?.[index]?.total_amount
    : "";
  useEffect(() => {
    let finalTax =
      formik?.values?.values?.[index]?.tax_type == "%"
        ? ((formik?.values?.values?.[index]?.tax / 100) * commingAmount)
            .toFixed(2)
            .replace("-", "")
        : (formik?.values?.values?.[index]?.tax).toFixed(2).replace("-", "");
    let finalAmount = formik?.values?.values?.[index]?.tax
      ? Number(finalTax)
      : 0 + commingAmount
      ? commingAmount
      : 0;
    setCalculatedAmount(finalAmount);
  }, [formik.values]);

  return (
    <div className="renderd-comp">
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="charge-tab col-sm-12 px-sm-15 pt-10 pb-3 ">
          <Row className="form-row sm-12 mb-4">
            <Col className="col-12 col-md-5">
              <div className="form-group">
                <LabelField mandatory title={"Account Name"} />
                <Select
                  {...{
                    formik,
                    name: `account_name`,
                    checkValidation: false,
                  }}
                  onInputChange={(text) => searchAction(text)}
                  classNamePrefix="react-select"
                  formatOptionLabel={formatOptionLabel}
                  options={data}
                  isLoading={loading}
                  styles={colourStyles}
                  onChange={(value) => {
                    formik.setFieldValue(`account_name`, {
                      id: value._id,
                      name: value.label,
                    });
                  }}
                  value={
                    formik.values?.account_name
                      ? {
                          label: formik.values?.account_name?.name,
                          value: formik.values?.account_name?.id,
                        }
                      : ""
                  }
                  components={{ DropdownIndicator }}
                  isDisabled={index > 0}
                />
              </div>
            </Col>
            <Col className="col-12 col-md-5">
              <div className="form-group">
                <LabelField title="Charge Type" />
                <div className="charge-type">
                  {types.map((type, idx) => (
                    <span
                      className={
                        type === "Credit On Account" ? "col-sm-6" : "col-sm-3"
                      }
                      style={
                        type === show
                          ? { background: "#2555ef", color: "white" }
                          : {}
                      }
                      key={idx}
                      onClick={() => {
                        setShow(type);
                        formik.setFieldValue(
                          `values.${index}.charge_type`,
                          "credit_on_account"
                        );
                      }}
                      {...{
                        formik,
                        value: formik.values.values?.[index]?.charge_type,
                      }}
                    >
                      {type}
                    </span>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="form-row sm-12 mb-4">
            <Col className="col-12 col-md-5">
              <DatePickerField
                disabled={true}
                mandatory
                {...{
                  formik,
                  name: `values.${index}.payment_date`,
                  title: "Date",
                  checkValidation: false,
                  value: formik.values.values?.[index]?.payment_date
                    ? formik.values.values?.[index]?.payment_date
                    : "",
                }}
                financeDateFields="finance_datefield"
              />
            </Col>
            <Col className="col-12 col-md-5">
              <div className="form-group">
                <LabelField mandatory title={"Ledger Amount"} />
                <Select
                  {...{
                    formik,
                    name: `values.${index}.Ledger`,
                    checkValidation: false,
                  }}
                  onInputChange={(text) => searchAction(text)}
                  classNamePrefix="react-select"
                  formatOptionLabel={formatOptionLabel}
                  options={options}
                  isLoading={loading}
                  styles={colourStyles}
                  onChange={getLedgerSubLedgerListById}
                  value={{
                    label: formik.values?.values?.[index]?.Ledger ? (
                      formik.values?.values?.[index]?.Ledger?.name
                    ) : (
                      <span style={{ color: "#b5b5c3" }}> Select</span>
                    ),
                    value: formik.values?.values?.[index]?.Ledger?.id,
                  }}
                  components={{ DropdownIndicator }}
                />
              </div>
            </Col>
          </Row>
          <Row className="form-row sm-12 mb-4">
            <Col className="col-12 col-md-5">
              <div className="form-group">
                <LabelField title={"Sub-Ledger Amount"} />
                <Select
                  {...{
                    formik,
                    name: `values.${index}.SubLedger`,
                    checkValidation: false,
                  }}
                  onInputChange={(text) => searchAction(text)}
                  classNamePrefix="react-select"
                  formatOptionLabel={formatOptionLabel}
                  options={subLedgersList}
                  isLoading={loading}
                  styles={colourStyles}
                  onChange={onSubledgerChange}
                  value={{
                    label: formik.values?.values?.[index]?.SubLedger ? (
                      formik.values?.values?.[index]?.SubLedger?.name
                    ) : (
                      <span style={{ color: "#b5b5c3" }}> Select</span>
                    ),
                    value: formik.values?.values?.[index]?.SubLedger?.id,
                  }}
                  components={{ DropdownIndicator }}
                />
              </div>
            </Col>
            <Col className="col-12 col-md-5">
              <InputField
                mandatory
                type="number"
                {...{
                  formik,
                  title: "Amount",
                  name: `values.${index}.total_amount`,
                  placeholder: "Charge Amount $",
                  value: formik.values?.values?.[index]?.total_amount,
                }}
                min="0"
                onKeyDown={(e) => {
                  exceptThisSymbols.includes(e.key) && e.preventDefault();
                  if (e.which === 38 || e.which === 40) e.preventDefault();
                }}
                handleChange={(e) => {
                  formik.setFieldValue(
                    `values.${index}.total_amount`,
                    e.target.value.match(regex)[0]
                  );
                }}
              />
            </Col>
          </Row>
          <Row className="form-row sm-12 mb-4">
            <Col className="col-12 col-md-5 d-flex">
              <Col className="col-sm-6 px-0">
                <InputField
                  type="number"
                  {...{
                    formik,
                    title: "Tax Deductible",
                    name: `values.${index}.tax`,
                    placeholderIcon: (
                      <span className="placeholder-icon">
                        {formik.values?.values?.[index]?.tax_type ||
                        formik?.values?.allData[0]?.discount_type === "%"
                          ? "%"
                          : "$"}
                      </span>
                    ),
                    placeholder: "Tax",
                    value: formik.values?.values?.[index]?.tax ?? "0",
                  }}
                  min="0"
                  onKeyDown={(e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                    if (e.which === 38 || e.which === 40) e.preventDefault();
                  }}
                  handleChange={(e) => {
                    formik.setFieldValue(
                      `values.${index}.tax`,
                      e.target.value.match(regex)[0]
                    );
                  }}
                />
              </Col>

              <Col className="col-12 col-md-5 price-type">
                <PriceType
                  value={
                    formik.values?.values?.[index]?.tax_type
                      ? formik.values?.values?.[index]?.tax_type
                      : formik?.values?.allData[0].tax_type
                  }
                  handleClick={setTaxType}
                  setFieldValue={formik.setFieldValue}
                  index={index}
                  type={`values.${index}.tax_type`}
                />
              </Col>
            </Col>
          </Row>
          <Col className="col-sm-12 px-0">
            <TextareaField
              {...{
                formik,
                title: "Add Note",
                name: `values.${index}.note`,
                placeholder: "Note text",
                style: { resize: "none" },
                value: formik?.values?.values?.[index]?.note,
              }}
            />
          </Col>
          <hr className="mt-15 mb-7" />
          <p className="charge-total">
            Total: $
            {/* {calculatedAmount
              ? getCommaSeperatedValue(calculatedAmount)
              :  */
            formik.values?.values?.[index]?.total_amount
              ? getCommaSeperatedValue(formik.values?.current_total)
              : "0.00"}
          </p>
        </div>
      </form>
    </div>
  );
};

export default CreditOnAccount;
