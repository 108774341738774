import * as React from "react"

function UserSettingsComponent (props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22.876}
      height={22.877}
      {...props}
    >
      <g
        transform="translate(.832 .832)"
        fill="none"
        stroke="#b5b5c3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
      >
        <circle
          cx={2.892}
          cy={2.892}
          r={2.892}
          transform="translate(7.713 7.713)"
        />
        <path
          data-name="Path"
          d="M17.739 13.497a1.591 1.591 0 00.318 1.755l.058.058a1.93 1.93 0 11-2.729 2.728l-.058-.058a1.6 1.6 0 00-2.719 1.137v.164a1.928 1.928 0 11-3.856 0v-.087a1.59 1.59 0 00-1.041-1.455 1.591 1.591 0 00-1.755.318l-.058.058a1.93 1.93 0 11-2.728-2.729l.058-.058a1.6 1.6 0 00-1.137-2.719h-.164a1.928 1.928 0 110-3.856h.087a1.59 1.59 0 001.452-1.04 1.591 1.591 0 00-.318-1.755L3.091 5.9a1.93 1.93 0 112.733-2.728l.058.058a1.589 1.589 0 001.754.318h.078a1.589 1.589 0 00.964-1.455v-.165a1.928 1.928 0 113.857 0v.087a1.6 1.6 0 002.719 1.137l.058-.058a1.93 1.93 0 112.728 2.729l-.058.058a1.589 1.589 0 00-.318 1.754v.078a1.589 1.589 0 001.455.964h.164a1.928 1.928 0 010 3.857h-.087a1.589 1.589 0 00-1.457.963z"
        />
      </g>
    </svg>
  )
}

export default UserSettingsComponent
