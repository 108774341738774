import moment from "moment";
import * as Yup from "yup";
// import { useTranslation } from "react-i18next";

export const LoginSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Required")
    .matches(/^(?![\s-])[\w\s-]+$/, "Name can only contain letters."),
  last_name: Yup.string().required("Required"),
  // hebrew_name: Yup.string().required('Required'),
  // middle_name: Yup.string().required('Required'),
});

export const Step2Schema = Yup.object().shape({
  email_address: Yup.string().email("Wrong email format"),

  phone_number: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(16, "Maximum 16 symbols"),

  date_of_birth: Yup.string().test(
    "DOB",
    "Please select valid date of birth",
    (value) => {
      if (!value) return true;
      return moment().diff(moment(value), "days") >= new Date().getDay();
    }
  ),
});

export const Step3Schema = Yup.object().shape({
  //   address: Yup.string().required("Required"),
  //   city: Yup.string().required("Required"),
  //   zip_code: Yup.string().required("Required"),
});

export const AddNewMemberSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Required")
    .matches(/^(?![\s-])[\w\s-]+$/, "Name can only contain letters."),
  // middle_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  // hebrew_name: Yup.string().required("Required"),
  // title: Yup.string().required("Required"),
  // profile_img: Yup.string().required("Required"),
  date_of_birth: Yup.string().test(
    "DOB",
    "Please select valid date of birth",
    (value) => {
      if (!value) return true;
      return moment().diff(moment(value)) >= new Date().getDay();
    }
  ),
  email_address: Yup.string()
    // .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols"),
  phone_number: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(16, "Maximum 16 symbols"),
  // address: Yup.string().required("Required"),
  // city: Yup.string().required("Required"),
  // zip_code: Yup.string().required("Required"),
});
export const MemberPersonalDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .matches(/^(?![\s-])[\w\s-]+$/, "Name can only contain letters."),
  // middle_name: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  // hebrew_name: Yup.string().required("Required"),
  // title: Yup.string().required("Required"),
  // profile_img: Yup.string().required("Required"),

  email: Yup.string()
    // .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols"),

});

export const AddNewYahrzeitSchema = Yup.object().shape({
  // mother_name: Yup.string().required("Required"),
  // relation: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  // date_of_death: Yup.string().test(
  //     "DOB",
  //     "Please select valid date of death",
  //     value => {
  //         return moment().diff(moment(value), 'days') >= new Date().getDay();
  //     }
  // ),
});
