import { TableContainer } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ReactComponent as InfoIcon } from 'assets/icons/InfoIcon.svg';
import { CheckBox } from 'components/CheckBox/CheckBox';
import { convertUTCToLocalDate } from 'components/UTC/UtcConverter';
import { formatDate } from 'helper/dateFormat';
import { numberFormat } from 'helper/numberFormat';
import { useMobileView } from 'hook/mobileView/useMobileView';
import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Spinner } from '../Theme';
import {
    Cell,
    ContentCard,
    DueDate,
    Ledger,
    LedgerGroup,
    LedgerMobileView,
    Ledgers,
    NoPayment,
    Payment,
    SelectAll,
    SubLedger,
    TableTitle,
    Wrapper
} from './Theme';

const TableCellHead = withStyles((theme) => ({
    root: {
        borderBottom: 'none',
        borderRadius: '6px !importent',
        background: 'var(--Blue-10, #F3F6F9)',

    },
}))(TableCell);

const TableCellBody = withStyles((theme) => ({
    root: {
        height: '65px',
        fontFamily: 'Poppins',

    },
}))(TableCell);
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-root': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export const ChargeTable = ({ loading, list, handleCheck, checkedList, handleCheckAll, TotalForPay }) => {
    const isListExist = list?.charges?.length === 0
    const tableHeader = ['', 'DUE DATE', 'CHARGE DETAILS', 'TOTAL AMOUNT', 'TOTAL AMOUNT PAID', 'TOTAL AMOUNT DUE ']

    const [checkAll, setCheckAll] = useState(false)
    const handleOnChangeSelectAll = (e) => {
        if (e?.target?.checked) {
            
            handleCheckAll(list?.charges)
            setCheckAll(true)
        } else {
            handleCheckAll([])
            setCheckAll(false)
        }
    }
    const { isMobile } = useMobileView()
    const useStyles = makeStyles((theme) => ({
        tableRowClicked: {
            '&.MuiTableRow-root': {
                backgroundColor: " background: '#fff' !important",
            },
        },
        tableRowUnClicked: {
            '&.MuiTableRow-root': {
                backgroundColor: "#fff !important",
            },
        },
    }));
    const classes = useStyles()
    useEffect(() => {
       
        if (checkedList.length > 0) {
            if (list?.charges?.length === checkedList?.length) {
                setCheckAll(true)
            } else{
                setCheckAll(false)  
            }
        }else if(checkedList.length === 0){
            setCheckAll(false)
        }
    }, [checkedList])

    return (
        <Wrapper>
            <TableTitle isMobile={isMobile}><span>Select Charges to Pay</span><InfoIcon />{isMobile && <><hr /></>}</TableTitle>
            {isMobile && !loading && !isListExist ?
                <ContentCard>
                    <SelectAll>
                        <CheckBox onChange={(e) => handleOnChangeSelectAll(e)}
                            checked={isListExist ? false : CheckBox}
                            disabled={isListExist}
                        />
                        <span>Select All</span>
                    </SelectAll>
                    {list?.charges?.map((item, index) => (
                        <>
                            <div style={{ marginLeft: '10px' }}>
                                <DueDate>
                                    <span>Due Date {formatDate({date:item?.due_date, formatType: "defaultWithUTC"})}</span>
                                </DueDate>
                            </div>
                            <LedgerMobileView>
                                <Ledgers>
                                    <div>
                                        <CheckBox onChange={(e) => handleCheck(e, item.chargeId, item.amount, item)}
                                            checked={checkedList?.includes(item.chargeId)} />
                                    </div>
                                    <LedgerGroup>
                                        <span>{item?.Ledger?.name}</span><br />
                                        <SubLedger>{item?.SubLedger?.name}</SubLedger>
                                        {item?.comment_invoice?.visible && (
                                            <SubLedger>{item?.comment_invoice?.note}</SubLedger>
                                        )}
                                    </LedgerGroup>
                                    <div>
                                        {numberFormat({number:item?.amount, decimal:2, style: "currency"})}
                                    </div>
                                </Ledgers>
                                <Payment>
                                    <div>
                                        Due <span>{numberFormat({number:item?.totalAmount, decimal:2, style: "currency"})}</span>
                                    </div>
                                    <div>
                                        Paid <span>{numberFormat({number:item?.totalPaid, decimal:2, style: "currency"})}</span>
                                    </div>
                                </Payment>
                            </LedgerMobileView>

                        </>
                    ))
                    }
                </ContentCard>
                : loading ? (<div
                    className="user__loader"
                    style={{ height: list?.charges?.length === 0 ? '70px' : '320px' }}
                >
                    <Spinner><PulseLoader color={'#2555EF'} size={'10px'} /></Spinner>
                </div>) :
                    isListExist ? (
                        <NoPayment>
                            <span>No open charges</span>
                        </NoPayment>) :
                        <TableContainer component={Paper} style={{ maxHeight: '536px', overflowY: 'auto' }}>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        {tableHeader?.map((item, index) => (
                                            <TableCellHead>
                                                {index === 0 && <CheckBox onChange={(e) => handleOnChangeSelectAll(e)}
                                                    checked={isListExist ? false : checkAll}
                                                    disabled={isListExist}
                                                />}
                                                <Cell style={{ marginTop: index === 0 ? '10px' : '0' }}>
                                                    {item}
                                                </Cell>
                                            </TableCellHead>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    style={{
                                        backgroundColor: 'white',
                                    }}
                                >
                                    {loading ? (
                                        <TableRow>
                                            <TableCell colSpan={10} align="center">
                                                <div
                                                    className="user__loader"
                                                    style={{ height: '320px' }}
                                                >
                                                    <Spinner><PulseLoader color={'#2555EF'} size={'10px'} /></Spinner>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ) : isListExist ? (
                                        <TableRow>
                                            <TableCell colSpan={10} align="center">
                                                <h6 style={{ height: '320px', marginTop: '100px' }}>
                                                    There is no payment needed now please contact admin
                                                </h6>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <>
                                            {list?.charges?.length > 0 && <>
                                                {loading ? <Spinner><PulseLoader color={'#2555EF'} size={'10px'} /></Spinner> : list?.charges?.map((item, index) => (
                                                    <StyledTableRow sx={{
                                                        verticalAlign: 'top',
                                                        '& > *': {
                                                            borderBottom: 'unset'
                                                        }
                                                    }}
                                                        classes={{ root: checkedList?.indexOf(item.chargeId) !== -1 ? classes.tableRowClicked : classes.tableRowUnClicked }}
                                                    >
                                                        <TableCellBody
                                                            component="th"
                                                            scope="row"
                                                            style={{ paddingTop: '24px' }}
                                                        >
                                                            <CheckBox onChange={(e) => handleCheck(e, item.chargeId, item.amount, item)} checked={checkedList?.includes(item.chargeId)} />
                                                        </TableCellBody>
                                                        <TableCellBody
                                                            className="main-width-class"
                                                            align="left"
                                                        >
                                                            {formatDate({date:item?.due_date, formatType: "defaultWithUTC"})}
                                                        </TableCellBody>
                                                        <TableCellBody
                                                            className="main-width-class"
                                                            align="left"
                                                        >
                                                            <Ledger>{item?.Ledger?.name}</Ledger>
                                                            <SubLedger>{item?.SubLedger?.name}</SubLedger>
                                                            {item?.comment_invoice?.visible && (
                                                                <SubLedger>{item?.comment_invoice?.note}</SubLedger>
                                                            )}
                                                        </TableCellBody>

                                                        <TableCellBody
                                                            className="main-width-class"
                                                            align="left"
                                                        >
                                                            {numberFormat({number:+item?.totalAmount, decimal:2, style: "currency"})}
                                                        </TableCellBody>
                                                        <TableCellBody
                                                            className="main-width-class"
                                                            align="left"
                                                        >
                                                            {numberFormat({number:+item?.totalPaid, decimal:2, style: "currency"})}
                                                        </TableCellBody>
                                                        <TableCellBody
                                                            className="main-width-class"
                                                            align="left"
                                                        >
                                                            {numberFormat({number:+item?.amount, decimal:2, style: "currency"})}
                                                        </TableCellBody>
                                                    </StyledTableRow>
                                                ))}

                                            </>
                                            }
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
            }

        </Wrapper>
    );
};
