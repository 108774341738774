import CircularProgress from "@mui/material/CircularProgress";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

const StripeCardBox = forwardRef(({onSaveCard,loading, setLoading,saveCard},ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    async handleGetToken() {
      setLoading(true);
      const { error, token } = await stripe.createToken(elements.getElement(CardElement))
      if (error) {
        setError(error.message);
        setLoading(false);
        return null;
      } else {
        setError(null);
        return token?.id
      }
    },
  }));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); 
    const { error, token } = await stripe.createToken(elements.getElement(CardElement))
    if (error) {
      setError(error.message);
      setLoading(false);
    } else {
      setError(null);
      onSaveCard(token?.id)
    }
  };
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },

      },
      invalid: {
        color: '#9e2146',
      },
    },

  };
  

  return (
    <div  className="stripe-add-card">
      <div className="stripe-add-card__container">
        <div className="stripe-add-card__box">
          <CardElement options={cardElementOptions} />
        </div>
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
      {saveCard && (
        <div className="stripe-add-card__action" onClick={handleSubmit}>
          <button type="button" disabled={!stripe | loading} className="primary-button">
            {loading ? (
              <CircularProgress
              size={22}
              style={{
                color: "#ffffff",
              }}
            />
            ) : "Add Card"}
          </button>
        </div>
      )}
    </div>
  );
});

export default StripeCardBox;