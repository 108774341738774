import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as DirectoryIcon } from "assets/icons/DirectoryIcon.svg";
import { ReactComponent as DisabledDirectoryIcon } from "assets/icons/DisableDirectoryIcon.svg";
import { ReactComponent as DonationSVG } from "assets/icons/donation.svg";
import { ReactComponent as FinanceIcon } from "assets/icons/financeIcon.svg";
import { ReactComponent as GroupsSVG } from "assets/icons/groups.svg";
import { ReactComponent as StartSVG } from "assets/icons/start.svg";
import { screens } from "constants/enums";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import CustomerSvg from "assets/icons/svg/customer";
import TombSvgComponent from "assets/icons/svg/tomb";
import "./LeftDrawerCss.scss";

export const LeftDrawer = ({ children, style, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const [isSelected, setSelected] = useState(1);
  const { isAuthenticated } = useAuth0();
 const  memberPortalDirectoryPermission=  JSON.parse(localStorage.getItem("organization"))?.memberPortalDirectory
  const drawerList = [
    // {
    //   id: 0,
    //   title: "Dashboard",
    //   icon: <HomeSVG />,
    //   url: "/home",
    // },
    {
      id: 1,
      title: "Family Members",
      icon: <CustomerSvg />,
      url: "/family-member-table",
    },
    {
      id: 2,
      title: "Yahrzeit",
      icon: <TombSvgComponent fillColor={"#2555ef"} />,
      url: "/yahrzeit",
    },
    {
      id: 3,
      title: "Education",
      icon: <GroupsSVG fillColor={"#2555ef"} />,
      url: "/classes",
    },
    {
      id: 4,
      title: "Billing",
      icon: <FinanceIcon />,
      url: "/finance",
    },
    {
      id: 5,
      title: "Events",
      icon: <StartSVG />,
      url: "/events-view",
    },
    {
      id: 6,
      title: "Donation",
      icon: <DonationSVG />,
      url: "/donation-view",
    },
    {
      id: 7,
      title: "Directory",
      icon:<DirectoryIcon/>,
      disabledIcon:<DisabledDirectoryIcon/>,
      url: "/directory",
    },
    {
      id: 8,
      title: "Settings",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-gear"
          viewBox="0 0 16 16"
        >
          <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
          <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
        </svg>
      ),
      url: "/settings", 
    },
    
    // {
    //   id: 7,
    //   title: "Schools",
    //   icon: <DrawerSchool />,
    //   url: '/schools'
    // },
    // {
    //   id: 7,
    //   title: "Help",
    //   icon: <SupportSVG />,
    //   url: "help",
    // },
  ];
  const { showLeftDrawer } = useSelector((state) => {
    return {
      token: state?.token,
      showLeftDrawer: state?.drawer?.showLeftDrawer,
    };
  });
  const handleTabChange = (item) => {
    setSelected(item?.id);
    // if (item?.url === "/donation") {
    //   window.location.replace(`${window.location.origin}/donation`)
    //   return;
    // }
    history.push(item.url);
  };
  const getActiveClass = ({ id }) => {
    console.log('id', id,isSelected === id)
    if (isSelected === id) {
      return "active";
    }
    return "";
  };
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      marginBottom: "15px !important",
      color: theme.palette.common.black,
    },
    tooltip: {
      marginBottom: "9px",
      backgroundColor: theme.palette.common.black,
    },
  }));
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  }
  if (
    !isAuthenticated ||
    (!location.pathname.includes(screens.HOME) &&
      !location.pathname.includes(screens.ADD_NEW_MEMBER) &&
      !location.pathname.includes(screens.YAHRZEIT) &&
      !location.pathname.includes(screens.ADD_NEW_YAHRZEIT) &&
      !location.pathname.includes(screens.FINANCE) &&
      !location.pathname.includes(screens.EVENTS) &&
      !location.pathname.includes(screens.DONATION) &&
      !location.pathname.includes(screens.DIRECTORY) &&
      !location.pathname.includes(screens.SCHOOLS) &&
      !location.pathname.includes(screens.HELP) &&
      !location.pathname.includes(screens.FAMILYMEMBERSTAB) &&
      !location.pathname.includes(screens.SETTINGS)) && 
      !location.pathname.includes(screens.CLASSES)
  )
    return null;
  return (
    <div className={`aside d-flex mobile-view ${showLeftDrawer ? "aside-on" : ""}` } >
      <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
        <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
          <ul className="list-unstyled flex-column" role="tablist">
            <li
              className="nav-item mb-15 pb-5"
              data-toggle="tooltip"
              data-placement="rigth"
              data-container="body"
              data-boundary="window"
            >
              {/* <img src={LogoPNG} alt="" /> */}
            </li>
            {drawerList?.map((item, index) => {
              if(item.id === 7 && !memberPortalDirectoryPermission){
                return  null;
              }else{
              return (
                <li
                  key={index}
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                >
                  <BootstrapTooltip title={item?.title} placement="right">
                    <div
                      className="d-flex w-100 m-0 p-0"
                      onClick={() => handleTabChange(item)}
                    >
                      
                      <span
                        className={`nav-link btn btn-icon btn-clean btn-lg ${getActiveClass(item)} svg-icon-hover${getActiveClass(item)}`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg ">
                          {item.id === 7 ? getActiveClass(item) ? item?.icon :  item.disabledIcon :item?.icon}
                          {/* {item?.icon} */}
                        </span>
                      </span>
                    </div>
                  </BootstrapTooltip>
                </li>
              );
            }
            })}
          </ul>
        </div>
      </div>

    </div>
  );
};
