import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import "./index.scss";
import Spinner from "components/Spinner";

const MyButton = ({
  className,
  buttonTextStyle,
  type,
  disabled,
  sendData,
  onClick,
  content,
  symbol,
  loading,
  ...props
}) => {
  if (loading)
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );

  return (
    <button
      disabled={disabled}
      className={
        disabled === true
          ? "disabled"
          : type === "secondary"
            ? "secondary-button"
            : type === "primary"
              ? "primary-button"
              : type === "notpaid"
                ? "next-review-button"
                : "primary-button"
      }
      onClick={onClick}
      type={type}
      {...props}
    >
      {/* <p className="button-text " style={buttonTextStyle}> */}
      {symbol}
      {content}
      {/* </p> */}
    </button>
  );
};

export default MyButton;
