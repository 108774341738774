import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import "./style.scss";

export const MyRadioGroup = ({
  formsControls,
  handleChange,
  value,
  name,
  className,
  disabled,
  ...rest
}) => {
  return (
    <RadioGroup
      {...rest}
      name={name}
      value={value}
      className={`${className} ${!disabled && "my-control-label"}`}
      onChange={handleChange}
    >
      {formsControls?.map((formControl, index) => {
        return (
          <FormControlLabel
            key={index}
            disabled={disabled}
            value={formControl.value}
            onClick={() => { }}
            control={<Radio color="primary" />}
            label={formControl.label}
          />
        );
      })}
    </RadioGroup>
  );
};
