import React from 'react';
import './index.scss'
export const CheckBox = ({ checked, isDisabled, text, onChange,disabled, ...rest }) => {
    return (
        <div className="wrapper">
            <label className="check-box-container">
                <input type="checkbox"
                    style={{ marginRight: 8 }}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    {...rest}
                />
                <span className={isDisabled ? "checkbox-checkmark-all" : "checkbox-checkmark"}></span>
            </label>
            {text && <span className="text">{text}</span>}
        </div >
    );
};
