
import { useAuth0 } from "@auth0/auth0-react";
import AuthService from "Lib/API/Auth";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { setLogoImage } from "services/api/organization";

const withUserAccount = (Component) => {
	let accountIdFromStorage = localStorage.getItem("accountID")
	? localStorage.getItem("accountID")
	: "";
	return (props) => {
		const dispatch = useDispatch();
		const [accountId, setAccountId] = useState(accountIdFromStorage || "");
		const { getAccessTokenSilently } = useAuth0();

		useEffect(() => {
			const getUserAccount = async () => {
				const token = await getAccessTokenSilently();
				await AuthService.getUserExist(token)
					.then((res) => {
						if (res) {
							setAccountId(res?.user?.permissions?.linked_account?._id);
							localStorage.setItem("accountID", res?.user?.permissions?.linked_account?._id);
							localStorage.setItem("paymentMethod", JSON.stringify(res?.user?.paymentMethod));
							localStorage.setItem("checkUser", JSON.stringify(res?.exists));
							localStorage.setItem("accountName", res?.user?.permissions?.linked_account?.family_name);
							localStorage.setItem(
								"accountInfo",
								JSON.stringify({
									firstName: res?.user?.permissions?.linked_contact?.first_name,
									lastName: res?.user?.permissions?.linked_contact?.last_name,
									primary_email_address: res?.user?.permissions?.linked_account?.primary_email_address,
								})
							);
							localStorage.setItem("organization", JSON.stringify(res?.user?.permissions?.organization));
							localStorage.setItem("linkedAccount", JSON.stringify(res?.user?.permissions?.linked_account));
							localStorage.setItem("hasPayment", res?.user?.hasPayment);
							localStorage.setItem("logoImg", res?.user?.permissions?.organization?.logoImg);
							res?.user?.permissions?.organization?.logoImg &&
								dispatch(setLogoImage(res?.user?.permissions?.organization?.logoImg));
							localStorage.setItem("orgName", res?.user?.permissions?.organization?.orgName);
							document.title = `${res?.user?.permissions?.organization?.orgName}`;
						}
					})
					.catch((err) => {
						localStorage.setItem("checkUser", err?.data?.exists);
						toastr.error('Error', err?.data?.message);
					});
			};
			if(!accountId) {
				getUserAccount();
			}
		}, []);

		return <Component {...props} accountId={accountId} />;
	};
};

export default withUserAccount;