
export const numberFormat = ({number,decimal=0,style='decimal'}) => {
  let country = JSON.parse(
    localStorage.getItem("country") !== "undefined"
    ? localStorage.getItem("country")
    : "{}"
  );
  const currency = country?.currency || 'USD';
  const local = country?.local || 'en-US';
  return new Intl.NumberFormat(local, {
    style: style,
    currency: currency,
    ...(style === "decimal" ? {minimumFractionDigits: decimal} : {}),
    maximumFractionDigits: decimal
  }).format(+number || 0);
  
}
 

export const getCurrency = () => {
  let country = JSON.parse(
    localStorage.getItem("country") !== "undefined"
    ? localStorage.getItem("country")
    : "{}"
  );
  const currency = country?.currencySymbol || '$';
  return currency;
}