import "./index.scss";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState, useContext, createContext } from "react";

import Typography from "@material-ui/core/Typography";

export default function FinanceAccordion({
  body,
  header,
  title,
  expanded,
  onChange,
  bodyClassName,
  onPaymentValueChange,
  id,
  formik,
  current,
  setCurrent,
  isDefault,
  type,
  setExpanded,
  addToMember,
  index,
}) {
  const UserContext = createContext();

  const [selected, setSelected] = useState(null);
  const [expandVIew, setExpandView] = useState(expanded);
  const [openInput, setOpenInput] = useState(false);
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;

  const onCreditCardAmountChange = (e) => {
    // formik.setFieldValue("creditCardAmount", +e.target.value);
    formik.setFieldValue("creditCardAmount", e.target.value.match(regex)[0]);
  };
  return (
    <div
      className={`finance-accordian-container ${
        addToMember === false ? "d-none" : ""
      }`}
    >
      <Accordion
        className={"finance-accordian"}
        expanded={isDefault === true ? expanded === false : expanded}
        onChange={(index, newExpanded) => {
          setCurrent && setCurrent((prev) => [...prev, title]);
          onChange(index, newExpanded);
        }}
      >
        <AccordionSummary
          className={
            (isDefault === true && expanded === false) || expanded === true
              ? "finance-accordian-header-expanded"
              : current?.includes(title)
              ? "finance-accordian-header-selected"
              : "finance-accordian-header"
          }
          onClick={() => setOpenInput(!openInput)}
          expandIcon={
            <ExpandMoreIcon
              style={!expanded ? { color: "#b5b5c3" } : { color: "#ffff" }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {header
            ? React.cloneElement(header, {
                formik,
                id,
                title,
                onPaymentValueChange,
                selected,
                setSelected,
                onCreditCardAmountChange,
                expanded,
                current,
                setExpanded,
                index,
              })
            : null}
          {/* {expanded ? header : <LabelField title={title} />}
          {!header && expanded && <LabelField title={title} />} */}
        </AccordionSummary>
        {body && (
          <AccordionDetails className="finance-accordian-body">
            <Typography className={bodyClassName}>
              {React.cloneElement(body, {
                onPaymentValueChange,
                id,
                formik,
                title,
                selected,
                setSelected,
              })}
            </Typography>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}
