import React from 'react'
import Cropper from 'react-easy-crop'
import { Button } from './Button'
import MyModal from './MyModal'
import './style.scss'
const ImageCropper = ({ open, modelclassName, saveButton, cancelButton, handleClose, cropperClassName, image, crop, rotation, aspect, onCropChange, onCropComplete, onZoomChange, zoom, onRotationChange, title, closeAllModal }) => {
    return (
        <MyModal open={open} onClose={handleClose} className={modelclassName}>
            <div className="main-image-cropper">
                {title && <h1>{title}</h1>}
                <div className="image-cropper-main-div">
                    <Cropper
                        image={image}
                        crop={crop}
                        rotation={rotation}
                        aspect={aspect}
                        zoom={zoom}
                        onRotationChange={onRotationChange}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                    />
                </div>
                <div className="model-footer-container">
                    <div>
                        <Button onClick={closeAllModal}>{saveButton}</Button>
                    </div>
                    {cancelButton && <button
                        onClick={handleClose}
                        type="button"
                        className={`btn btn-transparent text-primary px-2 py-4 my-3 w-100`}
                    >
                        <span style={{ fontSize: 13, fontWeight: 900 }} className="cancel-button-style" >
                            {cancelButton}
                        </span>
                    </button>}
                </div>
            </div>
        </MyModal>)
}
export default ImageCropper