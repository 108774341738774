import styled from "styled-components";

export const Wrapper = styled.div`
padding-left: 17px;
padding-right: 17px;
height: 100%;
`
export const TableTitle = styled.div`
width: 100%;
margin-top: 28px;
margin-bottom: 17px;
color: var(--Dark-Blue-100, #002E5C);
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px;
padding-top:10px;
span{
padding-right: 5px;
}
`
export const Cell = styled.div`
font-size: 11px;
color: #9FA0A2;
line-height: 32px;
display: flex;
align-items: center;
`

export const LedgerMobileView = styled.div`
color: var(--black, #4F4F4F);
font-size: 14px;
font-weight: 400;
display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;

`
export const Ledger = styled.span`
color: var(--black, #4F4F4F);
font-size: 0.875rem;
font-weight: 400;
display: block;

    width: 120px;

`
export const SubLedger = styled.span`
color: rgba(79, 79, 79, 0.60);
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
display: block;

    width: 120px;

`
export const ContentCard = styled.div`
height: 85%;
overflow-y: auto;
`

export const DueDate = styled.div`
width: 100%;
color: var(--black, #4F4F4F);
font-weight: 600;
font-size: 14px;
padding-left: 32px;
`

export const Ledgers = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 12px 10px;
margin-bottom: 12px;
`
export const LedgerGroup = styled.div`
    text-align: left;
    width: 100%;
    padding-left: 33px;

;`
export const Payment = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
height: 32px;
border-radius: 4px;
background: #F0F0F3;
padding-left: 20px;
padding-right: 20px;
margin-bottom: 30px;
span{
    font-size: 16px;
    font-weight: 600;

}
`
export const SelectAll = styled.div`
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
color: var(--black, #4F4F4F);
font-weight: 600;
font-size: 14px;
margin-bottom: 15px;
text-align: center;
span{
    padding-left: 20px;
    padding-top: 10px;
    }
`
export const NoPayment = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50%;
    color: #B5B5C3;
    font-style: normal;
    
    font-weight: 500;
    font-size: 16px;
`