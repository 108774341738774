import React from "react";
import { useTimer } from "react-timer-hook";
import { TimerContent, Wrapper } from "./Theme";
export default function Timer({ expiryTimestamp,expiryFunction }) {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: expiryFunction,
  });

  return (
    <Wrapper >
      {/* <h1>react-timer-hook </h1>
      <p>Timer Demo</p> */}
      <TimerContent >
        <span>{minutes}</span>:
        <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
      </TimerContent>
      {/* <p>{isRunning ? "Running" : "Not running"}</p> */}
      {/* <button onClick={start}>Start</button>
      <button onClick={pause}>Pause</button>
      <button onClick={resume}>Resume</button>
      <button
        onClick={() => {
          // Restarts to 5 minutes timer
          const time = new Date();
          time.setSeconds(time.getSeconds() + 300);
          restart(time);
        }}
      >
        Restart
      </button> */}
    </Wrapper>
  );
}

