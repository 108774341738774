import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    register: "about",
    classId: "",
    classForm: {},
    classFormById: {},
    participantInfo: {},
}

export const getRegisterState = createSlice({
    name: 'getRegisterState',
    initialState,
    reducers: {
        registerState: (state, action) => {
            state.register = action.payload;
        },
        preClassId: (state, action) => {
            state.classId = action.payload
        },
        classFormData: (state, action) => {
            state.classForm = action.payload
        },
        classFormDetails: (state, action) => {
            state.classFormById = action.payload
        },
        ParticipantInformation: (state, action) => {
            state.participantInfo = action.payload
        },
        deleteParticipantInformation: (state, action) => {
            state.classForm = { "register": state.classForm.register?.filter((item, index) => index !== action.payload) }
        },
        reset: (state) => initialState,
    }
});

export const { registerState, ParticipantInformation, deleteParticipantInformation, preClassId, classFormData, classFormDetails, reset } = getRegisterState.actions;
export default getRegisterState.reducer