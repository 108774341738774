import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { EventLinkRouting } from './EventLinkRouting';

export const EventLink = () => {
    const redirectUri = window.location.protocol + "//" + window.location.host;
    const { isAuthenticated } = useAuth0();
    
    const onRedirectCallback = (appState) => {
      // history.push(
        //   appState && appState.returnTo
        //     ? appState.returnTo
        //     : window.location.pathname
        // );
      };
    return (
        <>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH0_DOMAIN}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
              redirectUri={redirectUri}
              onRedirectCallback={onRedirectCallback}
              cacheLocation="localstorage"
              issuer={process.env.REACT_APP_ISSUER}
              audience={process.env.REACT_APP_AUDIANCE}
              scope={process.env.REACT_APP_SCOPE}
            >
              <SnackbarProvider
                maxSnack={1}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                style={{ fontSize: '1.3rem' }}
              >
                <EventLinkRouting />
              </SnackbarProvider>
            </Auth0Provider>
        </>
    );
};
