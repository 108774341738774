import { axios } from "services/axios";
import Service from "../../service";

const FinanceService = {
  verifyAch: async (data, token) => {
    try {
      return await Service.update({
        url: `finance/v1/payment/verify-ach`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  // get account names
  getACcountNames: async (token, search, page = 1, size = 20) => {
    try {
      return await Service.get(
        `/accounts/account?page=${page}&size=${size}&search=${search}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },

  //pay all selected charges
  payAllSelectedCharges: async (data, token) => {
    try {
      return await Service.post({
        url: `finance/v1/payment/pay-all`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  updateTransaction: async (data, token) => {
    try {
      return await Service.update({
        url: `finance/v1/transaction`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },

  // get all transactions
  getAllTransactions: async (
    token,
    currentPage = 1,
    items_per_page = 10,
    body = null
  ) => {
    try {
      return await Service.post({
        url: `finance/v1/transaction/all?current_page=${currentPage}&items_per_page=${items_per_page}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  // Get transaction by ID
  getTransactionsByID: async (
    token,
    currentPage = 1,
    items_per_page = 10,
    body = null
  ) => {
    try {
      return await Service.post({
        url: `finance/v1/transaction/all?current_page=${currentPage}&items_per_page=${items_per_page}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },

  // get document list by transaction id
  getDocumentByTransactionId: (token, id, type) => {
    try {
      return Service.get(
        `finance/v1/document/transaction?id=${id}&type=${type}`,
        token
      );
    } catch (err) {
      throw err;
    }
  },

  // get all transactions
  getAllBatchLists: async (
    token,
    currentPage = 1,
    items_per_page = 10,
    body = null
  ) => {
    try {
      return await Service.post({
        url: `finance/v1/deposit/batchList?current_page=${currentPage}&items_per_page=${items_per_page}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  // get all deposit
  getAllDepositLists: async (
    token,
    currentPage = 1,
    items_per_page = 10,
    body = null
  ) => {
    try {
      return await Service.post({
        url: `finance/v1/deposit/depositList?current_page=${currentPage}&items_per_page=${items_per_page}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  // get list of batch billings
  getListofBatchBilling: async (
    token,
    currentPage = 1,
    items_per_page = 10,
    body = null
  ) => {
    try {
      return await Service.post({
        url: `finance/v1/batchBilling/list?current_page=${currentPage}&items_per_page=${items_per_page}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },

  // get list of batch billings
  getListofAccounts: async (
    token,
    currentPage = 1,
    items_per_page = 50,
    body = null
  ) => {
    try {
      return await Service.post({
        url: `accounts/account/batchBilling?current_page=${currentPage}&items_per_page=${items_per_page}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  //Add Account ACH
  addACHAccount: async (token, account_id, accountDetail) => {
    try {
      return await Service.post({
        url: `finance/v1/payment/ach?accountId=${account_id}`,
        token,
        data: {
          accountType: accountDetail?.type,
          routingNumber: accountDetail?.routing,
          accountNumber: accountDetail?.number,
          nameOnAccount: accountDetail?.name,
        },
      });
    } catch (error) {
      throw error;
    }
  },
  //Get list of ACH Account
  getACHCardsByAccountId: (token, accountId) => {
    try {
      return Service.get(
        `/finance/v1/payment/ach/list?accountId=${accountId}`,
        token
      );
    } catch (err) {
      throw err;
    }
  },
  deleteAchCard: (token, achId, accountId) => {
    try {
      return Service.remove(
        `finance/v1/payment/ach`,
        {
          accountId,
          achId,
        },
        token
      );
    } catch (err) {
      throw err;
    }
  },
  //Add credit card
  addCreditCard: async (token, account_id, data) => {
    try {
      return await Service.post({
        url: `finance/v1/payment/card?accountId=${account_id}`,
        token,
        data,
      });
    } catch (error) {
      throw error;
    }
  },

  //Get list of credit cards
  getCreditCardsByAccountId: (token, accountId, limit = 10) => {
    try {
      return Service.get(
        `finance/v1/payment/card/list?accountId=${accountId}&limit=${limit}`,
        token
      );
    } catch (err) {
      throw err;
    }
  },
  getCreditOnAccountAmount: async (id, token) => {
    try {
      return await Service.get(`finance/v1/payment/balance?id=${id}`, token);
    } catch (error) {
      throw error;
    }
  },

  deleteCreditCard: async(token, cardId, accountId) => {
    try {
      return await Service.remove({
        url: `finance/v1/payment/card`,
        token,
        data:{
          accountId,
          cardId,
        },
      });
    } catch (err) {
      throw err;
    }
  },

  //Ledgeraccounts
  getLedgeraccounts: async (filter, token) => {
    try {
      return await Service.get(`/finance/ledger/${filter}`, token);
    } catch (error) {
      throw error;
    }
  },

  getLedgerAccountsForDonation: async (donation, token) => {
    try {
      return await Service.get(
        `setting/v1/finance/ledger/list?filter=${donation}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  getLedgersByOrgId: async (token, orgId) => {
    try {
      return await Service.get(`setting/v1/finance/ledger/list?orgId=${orgId}&filter=donation`, token);
    } catch (error) {
      throw error;
    }
  },
  getLedgersForDonations: async (token, orgId) => {
    try {
      return await Service.get(`setting/v1/finance/ledger/donation/list?orgId=${orgId}`, token);
    } catch (error) {
      throw error;
    }
  },
  getLedgerSubLedgerList: async (token) => {
    try {
      return await Service.get(`/setting/v1/finance/ledger/list`, token);
    } catch (error) {
      throw error;
    }
  },

  getAllLedgeraccounts: async (token) => {
    try {
      return await Service.post({ url: `/accounts/ledger/all`, token });
    } catch (error) {
      throw error;
    }
  },
  getSubLedgerAccount: async (token, id) => {
    try {
      return await Service.post({
        url: `/accounts/subLedger/ledger/${id}`,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  addLedgerAccount: async (data, token) => {
    try {
      return await Service.post({ url: "/finance/ledger", data, token });
    } catch (error) {
      throw error;
    }
  },
  updateLedgerAccount: async (data, token) => {
    try {
      return await Service.update({ url: `/finance/ledger/`, data, token });
    } catch (error) {
      throw error;
    }
  },
  //Subcategories

  getSubcategoriesByLedgerAccount: async (id, token) => {
    try {
      return await Service.get(`/finance/subcat/ledacc/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  getFilteredSubcaegories: async (filter, token) => {
    try {
      return await Service.get(`/finance/subcat/${filter}`, token);
    } catch (error) {
      throw error;
    }
  },
  getSubcategoriesByLedgerAccountFiltered: async (filter, id, token) => {
    try {
      return await Service.get(`/finance/subcat/ledacc/${id}${filter}`, token);
    } catch (error) {
      throw error;
    }
  },
  addSubcategorie: async (data, token) => {
    try {
      return await Service.post({ url: "/finance/subcat", data, token });
    } catch (error) {
      throw error;
    }
  },
  removeSubcateggorie: async (data, token) => {
    try {
      return await Service.remove({ url: `/finance/subcat/`, data, token });
    } catch (error) {
      throw error;
    }
  },
  updateSubcategorie: async (data, token) => {
    try {
      return await Service.update({ url: `/finance/subcat/`, data, token });
    } catch (error) {
      throw error;
    }
  },

  //PRODUCTS
  getAllProducts: async (token) => {
    try {
      return await Service.get(`/finance/prod`, token);
    } catch (error) {
      throw error;
    }
  },
  getFilteredProducts: async (filter, token) => {
    try {
      return await Service.get(`/finance/prod/${filter}`, token);
    } catch (error) {
      throw error;
    }
  },
  getProductsByLedgerAccount: async (filter, id, token) => {
    try {
      return await Service.get(`/finance/prod/ledger/${id}${filter}`, token);
    } catch (error) {
      throw error;
    }
  },
  getProductsBySubCat: async (filter, id, token) => {
    try {
      return await Service.get(`/finance/prod/subcat/${id}${filter}`, token);
    } catch (error) {
      throw error;
    }
  },
  removeProduct: async (data, token) => {
    try {
      return await Service.remove({ url: `/finance/prod/`, data, token });
    } catch (error) {
      throw error;
    }
  },
  addProduct: async (data, token) => {
    try {
      return await Service.post({ url: `/finance/prod/`, data, token });
    } catch (error) {
      throw error;
    }
  },
  updateProduct: async (data, token) => {
    try {
      return await Service.update({ url: `/finance/prod/`, data, token });
    } catch (error) {
      throw error;
    }
  },
  // payment requests
  getPaymentRequests: async (filter, page, size, token) => {
    try {
      return await Service.get(
        `/finance/paymentreq${filter}&page=${page + 1}&size=${size}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },

  getInvoicesByMemberId: async (data, token) => {
    try {
      return await Service.post({
        url: `/finance/invoice/filter`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  getFilteredInvoices: async (page, size, token) => {
    try {
      return await Service.get(
        `/finance/invoice/filter?page=${page}&size=${size}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  getInvoices: async (data, page, size, token) => {
    try {
      return await Service.post({
        url: `/finance/invoice/filter?page=${page + 1}&size=${size}`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  getProducts: async (token, search) => {
    try {
      return await Service.get(
        `/finance/prod/search${search ? `?search=${search}` : ""}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  getInvoicesById: async (token, id) => {
    try {
      return await Service.get(`/finance/invoice/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  getFilterdPaymentRequests: async (data, token) => {
    try {
      return await Service.post({
        url: `/finance/paymentreq?page=1&size=10000`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },

  //get payment methods
  getPaymentMethods: (token) => {
    try {
      return Service.get(`/setting/v1/finance/payment`, token);
    } catch (error) {
      throw error;
    }
  },
  //cards
  getCardsById: async (id, token) => {
    try {
      return await Service.get(`/finance/billing/cards?account=${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  pay: async (data, token) => {
    try {
      return await Service.post({ url: `/finance/billing/pay`, data, token });
    } catch (error) {
      throw error;
    }
  },
  checkout: (data, token) => {
    return Service.post({
      url: "finance/v1/payment/checkout",
      data,
      token,
    });
  },
  reverseCharge: (id, token) => {
    return Service.update({
      url: `finance/v1/transaction/reverse`,
      data: id,
      token,
    });
  },
  getAllReversalTypes: async (type, token) => {
    try {
      return await Service.get(
        `/setting/v1/finance/reversal?type=${type}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  createNewUserAccount: async (data, token) => {
    try {
      return await Service.post({
        url: `accounts/account/mainmember`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  ViewBatchPayment: async (
    token,
    currentPage = 1,
    items_per_page = 10,
    body = null
  ) => {
    try {
      return await Service.post({
        url: `finance/v1/transaction/all?current_page=${currentPage}&items_per_page=${items_per_page}`,
        data: body,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  addBatchPayment: async (body, token) => {
    try {
      return await Service.post({
        url: `finance/v1/batchBilling`,
        data: body,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  editBatchPayment: async (id, body, token) => {
    try {
      return Service.update({
        url: `finance/v1/batchBilling/${id}`,
        data: body,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  editBatchBillingById: async (id, token) => {
    try {
      return Service.get(`finance/v1/batchBilling/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  completeBatchPayment: async (id, token) => {
    try {
      return await Service.update({
        url: `/finance/v1/batchBilling/complete/${id}`,
        data: {},
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  cloneBatchPayment: async (id, token) => {
    try {
      return await Service.get(`finance/v1/batchBilling/clone/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  pauseBatchPayment: async (id, token) => {
    try {
      return await Service.update({
        url: `/finance/v1/batchBilling/pause/${id}`,
        data: {},
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  deleteBatchPayment: async (id, token) => {
    try {
      return await Service.remove({
        url: `finance/v1/batchBilling/${id}`,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  Unbatch: async (id, token) => {
    try {
      return await Service.update({
        url: `finance/v1/deposit/unbatch/${id}`,
        data: {},
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  Undeposit: async (id, token) => {
    try {
      return await Service.update({
        url: `finance/v1/deposit/undeposit/${id}`,
        data: {},
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  getDataFilterForEditById: async (id, token) => {
    try {
      return await Service.get(`/accounts/filter/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  // api to make donation
  makeDonation: async (data, token) => {
    try {
      return await Service.post({
        url: `finance/v1/transaction/donate`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  //Get finance Amount
  getAmountByAccountId: (token, id) => {
    try {
      return Service.get(
        `finance/v1/transaction/accounts?accountId=${id}`,
        token
      );
    } catch (err) {
      throw err;
    }
  },

  // api to get charge info for external link

  getChargeInfo: async (orgId, accountId, key, value, token) => {
    try {
      return await Service.get(`finance/v1/transaction/charges-info?accountId=${accountId}&${key}=${value}&orgId=${orgId}`, token);
    } catch (error) {
      throw error;
    }
  },

  //api to pay from externalLink
  payExternalLink: async (data, token, orgId) => {
    try {
      return await Service.post({
        url: `finance/v1/payment/checkout-external?orgId=${orgId}`,
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
    // Download document 
    downloadDocument: async (token, documentId) => {
      try {
        return await axios({
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/finance/v1/document/download?id=${documentId}`,
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
            Authorization: `Bearer ${token}`,
            "Access-Control-Expose-Headers": "Content-Disposition"
          },
        })
      } catch (error) {
        throw error;
      }
    },
     // view documents
    viewDocument: async (token, documentId) => {
      try {
        return await Service.get(
          `/finance/v1/document/view/?id=${documentId}`,
          token
        );
      } catch (error) {
        throw error;
      }
    },
};
export default FinanceService;
