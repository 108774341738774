import { ReactComponent as FailedIcon } from "assets/icons/FailedIcon.svg";
import { ReactComponent as SucessIcon } from "assets/icons/SucessIcon.svg";
import cuid from "cuid";
import { useMobileView } from "hook/mobileView/useMobileView";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { eventsSlice, setCounterTicket } from "services/api/events";
import { ButtonWrapper, CardContent, ThanksMsg, Title, Wrapper } from "./Theme";

export const PaymentResultPage = ({ result, formik, isTherePayment, showThanksMsg }) => {
	const orgWebsite = localStorage.getItem("shul_website") || "";
	const { isMobile } = useMobileView();
	const dispatch = useDispatch();
	const history = useHistory();
	const lastCardNum = formik?.values?.cardsDetails?.card_number?.slice(-4);
	const handleCancel = () => {
		dispatch(eventsSlice.actions.setTotalProducts(0));
		// formik.setFieldValue('resultPage', '')
		formik.setFieldValue("accountInfo", {});
		formik.setFieldValue("cardsDetails", {});
		dispatch(setCounterTicket([cuid()]));
		localStorage.setItem("registrationForm", JSON.stringify([]));
		localStorage.setItem("ApplayCheckBox", JSON.stringify(false));
		orgWebsite ? window.location.replace(orgWebsite) : history.push("/events");
	};

	const successTitle = isTherePayment ? "Payment Succeeded!" : "Thank You";

	return (
		<Wrapper>
			<CardContent isMobile={isMobile}>
				{result === "success" ? (
					<div>
						<SucessIcon />
						{showThanksMsg ? (
							<ThanksMsg>Thank you, your account has been billed</ThanksMsg>
						) : (
							<>
								<Title isMobile={isMobile}>{successTitle}</Title>
								{isTherePayment && <p>Thank you! Your payment has been processed successfully.</p>}
							</>
						)}
						<ButtonWrapper>
							<button onClick={handleCancel}>Done</button>
						</ButtonWrapper>
					</div>
				) : (
					<div>
						<FailedIcon />
						<Title isMobile={isMobile}>Payment Failed!</Title>
						<p>
							Unfortunately we were unable to charge your Visa ending in {lastCardNum}. Please try again to continue.
						</p>
						<ButtonWrapper>
							<button onClick={() => formik.setFieldValue("resultPage", "")}>Try Again</button>
							<button onClick={handleCancel}>Cancel</button>
						</ButtonWrapper>
					</div>
				)}
			</CardContent>
		</Wrapper>
	);
};
