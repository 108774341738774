import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  memberUrl: "",
  billing_filter: {},
  payment_request: [],
  isDonationLayout: false,    
};

export const CommonStates = createSlice({
  name: "CommonStates",
  initialState,
  reducers: {
    addMemmberUrl: (state, action) => {
      state.memberUrl = action.payload;
    },
    setMembetReset: (state) => initialState,
    savefilter: (state, action) => {
      state.billing_filter = action.payload;
    },
    addPaymentRequest: (state, action) => {
      state.payment_request = action.payload;
    },
    setIsDonationLayout: (state, action) => {
      state.isDonationLayout = action.payload; 
    }
  },
});

export const {
  addMemmberUrl,
  reset,
  savefilter,
  addPaymentRequest,
  resetFilter,
  setIsDonationLayout
} = CommonStates.actions;
export default CommonStates.reducer;
