import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import logger from "redux-logger";

//Reducer
import reducers from "./reducers";

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whiteList: ["token"], //  the blacklist property, we can specify which part of state not to persist , we don't wanna save token in localStorage
  // blacklist: ['events'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      
    }),
    logger,
  ],
});

const persistor = persistStore(store);

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./reducers", () => {
    const newReducers = require("./reducers").default;
    store.replaceReducer(newReducers);
  });
}

export { store, persistor };
