import React, { memo } from "react";
import { colors } from "theme";

const styles = {
  body: {
    color: colors.textColor,
    fontSize: 16,
    fontWeight: "400",
  },
};
export const Text = memo(({ style, children, ...rest }) => {
  return (
    <span
      style={{
        ...styles.body,
        ...style,
      }}
      {...rest}
    >
      {children}
    </span>
  );
});
