import React from 'react';
import Grid from "@material-ui/core/Grid";
import axios from "axios";

import {
    InputField,
    MyCheckbox,
} from "components";
import { Wrapper, FormWrapper, FormHeader, Title, Description, Label, LabelCheckBox } from './Theme'
import FindMyCity from "components/FindMyCity/FindMyCity";

const PersonalInfo = ({ formik }) => {
    const token = localStorage.getItem("token"); // loged in user token

    const addressHandleChange = async (address) => {
        formik.setFieldValue(`accountInfo.addresses[0].location`, {
            formattedAddress: address?.label,
            place_id: address?.value?.place_id,
        });

        const result = await axios(
            `https://maps.googleapis.com/maps/api/geocode/json?place_id=${address?.value?.place_id}&key=AIzaSyCAt0TjvU_m8ufUMYn2EXi9mteHSOUB7uA`
        );
        let zip_code = result.data.results[0]?.address_components?.filter(
            (item) => item.types[0] == "postal_code"
        );
        formik.setFieldValue(`accountInfo.addresses[0].zip_code`, zip_code?.[0]?.short_name);

        if (address?.value?.terms.length === 3) {
            formik.setFieldValue(`accountInfo.addresses[0].state`, address?.value?.terms[2]?.value);
            formik.setFieldValue(`accountInfo.addresses[0].city`, address?.value?.terms[1]?.value);
        } else {
            formik.setFieldValue(`accountInfo.addresses[0].state`, address?.value?.terms[3]?.value);
            formik.setFieldValue(`accountInfo.addresses[0].city`, address?.value?.terms[2]?.value);
        }
    };

    const preventArrows = (e) => {
        if (e.which === 38 || e.which === 40) e.preventDefault();
    };
    const handleInputFields = (e, name) => {
        let value = e.target.value;
        value = value.replace(/[^A-Za-z]/gi, "");
        formik.setFieldValue(name, value);
    }
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    return (
        <Wrapper >

            <FormHeader>
                <Title >Personal Info</Title>
                <Description >Please enter your details</Description>
            </FormHeader>
            <Grid container spacing={5} style={{marginTop:20}}>
            <Grid item xs={12} sm={6}>
            <Label>First Name<label style={{ fontWeight: "500", color: "red" }}>
                            {" *"}
                        </label></Label>

                        <div className="Input__field">
                            <InputField
                                type="text"
                                title="none"
                                {...{
                                    name: "first_name",
                                    placeholder: "First Name",
                                    value: formik?.values?.accountInfo.first_name,
                                }}
                                handleChange={(e) => {
                                    handleInputFields(e, 'accountInfo.first_name')
                                }}
                                inputClassName="donation__input"
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Label>Last Name<label style={{ fontWeight: "500", color: "red" }}>
                            {" *"}
                        </label></Label>

                        <div className="Input__field">
                            <InputField
                                type="text"
                                title="none"
                                {...{
                                    name: "last_name",
                                    placeholder: "Last Name",
                                    value: formik?.values?.accountInfo.last_name,
                                }}
                                handleChange={(e) => {
                                    handleInputFields(e, 'accountInfo.last_name')
                                }}
                                inputClassName="donation__input"
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Label>Email
                            <label style={{ fontWeight: "500", color: "red" }}>
                                {" *"}
                            </label>
                        </Label>

                        <div className="Input__field">
                            <InputField
                                type="text"
                                title="none"
                                {...{
                                    name: "primary_email_address",
                                    placeholder: "Email",
                                    value: formik?.values?.accountInfo.primary_email_address,
                                }}
                                inputClassName="donation__input"
                                handleChange={(e) => {
                                    formik.setFieldValue("accountInfo.primary_email_address", e?.target?.value);
                                }}
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <LabelCheckBox htmlFor="paper-receipt"
                            className="d-flex align-items-center h-100 checkbox__label">

                            <MyCheckbox
                                handlechange={(e) =>
                                    formik.setFieldValue("paperCheck", e.target.checked)
                                }
                                checked={formik.values?.paperCheck}
                                name="checkboxDonation"
                                id="paper-receipt"
                            />
                            Send me a paper receipt
                        </LabelCheckBox>
                    </Grid>
                    {/* Address */}
                    {formik.values?.paperCheck && (
                        <>
                        <Grid item xs={12} >
                            <FindMyCity
                          
                                handleChange={(data) => addressHandleChange(data)}
                                value={{
                                    label: `${formik?.values?.accountInfo?.addresses[0]?.location?.formattedAddress
                                        ? formik?.values?.accountInfo?.addresses[0]?.location?.formattedAddress?.split(
                                            ","
                                        )[0]
                                        : "Address 1"
                                        }`,
                                    value: `${formik?.values?.accountInfo?.addresses[0]?.location?.place_id}`,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>

                            <InputField
                                type="text"
                                title="Address 2"
                                style={{width:"100%"}}
                                {...{
                                    // formik,
                                    name: "addressLine2",
                                    placeholder: "Address 2 (Apt., Suite, Unit)",
                                    value: formik?.values?.accountInfo?.addresses[0]?.addressLine2,
                                }}
                                handleChange={(e) => {
                                    formik.setFieldValue("accountInfo.addresses[0].addressLine2", e?.target?.value);
                                }}
                                inputClassName="donation__input"
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputField
                                {...{
                                    formik,
                                    title: "City",
                                    name: "city",
                                    placeholder: "Enter City",
                                    value: formik.values?.accountInfo?.addresses[0].city,
                                }}
                                handleChange={(e) => {
                                    formik.setFieldValue("accountInfo.addresses[0].city", e?.target?.value);
                                }}
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputField
                                {...{
                                    formik,
                                    title: "State",
                                    name: "state",
                                    placeholder: "NY",
                                    checkValidation: true,
                                    value: formik.values?.accountInfo?.addresses[0].state,

                                }}
                                handleChange={(e) => {
                                    formik.setFieldValue("accountInfo.addresses[0].state", e?.target?.value);
                                }}
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputField
                                {...{
                                    formik,
                                    title: "Zip code",
                                    name: `accountInfo.addresses[0].zip_code`,
                                    placeholder: "Zip Code",
                                    value: formik.values?.accountInfo?.addresses[0].zip_code,
                                }}
                                handleChange={(event) => {
                                    formik.setFieldValue(
                                        `accountInfo.addresses[0].zip_code`,
                                        event.target.value.match(regex)[0]
                                    );
                                }}
                                onKeyDown={(e) => preventArrows(e)}
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Grid>
                        </>
                    )}
            </Grid>
            <>
             
{/*               
                {formik.values?.paperCheck && (
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <FindMyCity
                                handleChange={(data) => addressHandleChange(data)}
                                value={{
                                    label: `${formik?.values?.accountInfo?.addresses[0]?.location?.formattedAddress
                                        ? formik?.values?.accountInfo?.addresses[0]?.location?.formattedAddress?.split(
                                            ","
                                        )[0]
                                        : "Address 1"
                                        }`,
                                    value: `${formik?.values?.accountInfo?.addresses[0]?.location?.place_id}`,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>

                            <InputField
                                type="text"
                                title="Address 2"
                                {...{
                                    // formik,
                                    name: "addressLine2",
                                    placeholder: "Address 2 (Apt., Suite, Unit)",
                                    value: formik?.values?.accountInfo?.addresses[0]?.addressLine2,
                                }}
                                handleChange={(e) => {
                                    formik.setFieldValue("accountInfo.addresses[0].addressLine2", e?.target?.value);
                                }}
                                inputClassName="donation__input"
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputField
                                {...{
                                    formik,
                                    title: "City",
                                    name: "city",
                                    placeholder: "Enter City",
                                    value: formik.values?.accountInfo?.addresses[0].city,
                                }}
                                handleChange={(e) => {
                                    formik.setFieldValue("accountInfo.addresses[0].city", e?.target?.value);
                                }}
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputField
                                {...{
                                    formik,
                                    title: "State",
                                    name: "state",
                                    placeholder: "NY",
                                    checkValidation: true,
                                    value: formik.values?.accountInfo?.addresses[0].state,

                                }}
                                handleChange={(e) => {
                                    formik.setFieldValue("accountInfo.addresses[0].state", e?.target?.value);
                                }}
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputField
                                {...{
                                    formik,
                                    title: "Zip code",
                                    name: `accountInfo.addresses[0].zip_code`,
                                    placeholder: "Zip Code",
                                    value: formik.values?.accountInfo?.addresses[0].zip_code,
                                }}
                                handleChange={(event) => {
                                    formik.setFieldValue(
                                        `accountInfo.addresses[0].zip_code`,
                                        event.target.value.match(regex)[0]
                                    );
                                }}
                                onKeyDown={(e) => preventArrows(e)}
                                onKeyPress={(event) => {
                                    if (event?.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                )} */}
            </>
        </Wrapper>
    );
};

export default PersonalInfo;
