import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Arrow from "assets/icons/Arrow-down-gray-png.png";
import { Counter } from "components/Counter/Counter";
import { InputField } from "components/InputField/InputField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventsSlice } from "services/api/events";
import { SolidLine } from "../Thems";

import { useAuth0 } from "@auth0/auth0-react";
import { getCurrency, numberFormat } from "helper/numberFormat";
import {
  Amount,
  AmountField,
  AmountSign,
  FirstDiv,
  FlexItem,
  FlexItemsContainer,
  ProductContent,
  SecondDiv,
  SelectWrapper,
  Title,
  VarientAmount,
  Wrapper
} from "./Thems";

const useStyles = makeStyles(() => ({
  formControl: {
    "& .MuiInputBase-root": {
      display: "flex",
      justifyContent: "left",
      width: "100%",
      border: 0,
      marginTop: "10px",
    },

    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px",
    },
  },
  select: {
    display: "flex",
    justifyContent: "left",
    width: "100% !importent",
    fontSize: "12px",

    "&:focus": {
      border: 0,
    },
    "&:hover": {
      border: 0,
    },
  },
  selectIcon: {
    position: "relative",
    color: "#002E5C",
    fontSize: "14px",
    opacity: 0.4,
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      color: "#002E5C",
      background: "##F4F6F9",
    },
    "& li.Mui-selected:hover": {
      background: "##F4F6F9",
    },
  },
}));

export const Products = React.memo(
  ({
    productsData,
    index,
    incrementCounter,
    decrementCounter,
    formik,
    ticketUniqueId,
    totalProducts,
    handleTotalForPay
  }) => {
    const { isAuthenticated } = useAuth0();

    const { varients, selectedValue } = useSelector((state) => state?.Events);
    const [newData, setNewData] = useState([]);
    const dispatch = useDispatch();
    const formDataReservation = formik?.values?.registrationForm || [];
    const classes = useStyles();

    const currency = getCurrency();
    const totalPay =  handleTotalForPay()


    const menuProps = {
      classes: {
        list: classes.list,
        paper: classes.paper,
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      getContentAnchorEl: null,
    };

    const handleSelect = (
      e,
      ticketIndex,
      productIndex,
      dropdownIndex,
      dropdownObj,
      product
    ) => {
      const varientAmount = product.variantInfo?.filter((val)=>val.option === e.target?.value)
      // find if the selected object include the array of selected items or not
      const selectedValueIndex = selectedValue?.map((item) => {
        if (product.variant && dropdownIndex) {
          return item[product.title]?.find(
            (item) =>
              item.indexSelected === dropdownIndex &&
              item.ticketIndex === ticketIndex
          );
        } else {
          return item[product.title]?.find(
            (item) => item.ticketIndex === ticketIndex
          );
        }
      });
      // Crete array of objects for dropDown selected values ,if data exist update the object
      // Update
      if (
        selectedValueIndex?.length > 0 &&
        selectedValueIndex[0] !== undefined
      ) {
        const newArray = [...selectedValue];
        const newData = newArray[0][product.title]?.map((item, index) => {
          if (!product.variant && !dropdownIndex) {
            if (item.ticketIndex === selectedValueIndex[0].ticketIndex) {
              if (e !== 0 && product.type === "openAmount") {
                return {
                  value: e,
                  ticketIndex: item.ticketIndex,
                  amount: product?.amount,
                  productId: product?._id,
                  price: item?.price,
                  type: item?.type,
                };
              } else {
                return {
                  value: e,
                  ticketIndex: item.ticketIndex,
                  amount: product?.amount,
                  productId: product?._id,
                  type: item?.type,
                };
              }
            } else {
              return { ...item };
            }
          } else {
            if (
              item.indexSelected === selectedValueIndex[0].indexSelected &&
              item.ticketIndex === selectedValueIndex[0].ticketIndex
            ) {
              return {
                value: e.target?.value,
                indexSelected: item.indexSelected,
                previousValue: item.value,
                ticketIndex: item.ticketIndex,
                amount: varientAmount?.[0]?.amount,
                productId: product?._id,
                type: item?.type,
              };
            } else {
              return { ...item };
            }
          }
        });
        const data = [{ ...newArray[0], [product.title]: [...newData] }];
        dispatch(
          eventsSlice.actions.setSelectedValue({
            data: data,
            productIndex: productIndex,
            productTitle: product.title,
            dropdownIndex: dropdownIndex,
            type: "",
            ticketIndex: ticketIndex,
          })
        );
      }
      // Add new
      else {
        let newArray = selectedValue ? [...selectedValue] : [];
        if (newArray?.length === 0) {
          // if the array empty , in the first select we don't have selected value
          if (!product.variant && !dropdownIndex) {
            newArray.push({
              [product.title]: [
                {
                  value: e,
                  ticketIndex: ticketIndex,
                  amount: product?.amount,
                  productId: product?._id,
                  type: product?.type,
                },
              ],
            });
          } else {
            newArray.push({
              [product.title]: [
                {
                  value: e.target?.value,
                  indexSelected: dropdownIndex,
                  previousValue: "",
                  ticketIndex: ticketIndex,
                  amount: varientAmount?.[0]?.amount,
                  productId: product?._id,
                  type: product?.type,
                },
              ],
            });
          }
          dispatch(
            eventsSlice.actions.setSelectedValue({
              data: newArray,
              productIndex: productIndex,
              productTitle: product.title,
              dropdownIndex: dropdownIndex,
              type: "",
              ticketIndex: ticketIndex,
            })
          );
        } else if (!Object.keys(newArray[0]).includes(product.title)) {
          //if the obj name doesn't exist , we don't have selected value becuse it's new product with new drop and there is no previousValue
          if (!product.variant && !dropdownIndex) {
            const data = [
              {
                ...newArray[0],
                [product.title]: [
                  {
                    value: e,
                    ticketIndex: ticketIndex,
                    amount: product?.amount,
                    productId: product?._id,
                    type: product?.type,
                  },
                ],
              },
            ];
            dispatch(
              eventsSlice.actions.setSelectedValue({
                data: data,
                productIndex: productIndex,
                productTitle: product.title,
                dropdownIndex: null,
                type: "",
                ticketIndex: ticketIndex,
              })
            );
          } else {
            const data = [
              {
                ...newArray[0],
                [product.title]: [
                  {
                    value: e.target?.value,
                    indexSelected: dropdownIndex,
                    previousValue: "",
                    ticketIndex: ticketIndex,
                    amount: varientAmount?.[0]?.amount,
                    productId: product?._id,
                    type: product?.type,
                  },
                ],
              },
            ];
            dispatch(
              eventsSlice.actions.setSelectedValue({
                data: data,
                productIndex: productIndex,
                productTitle: product.title,
                dropdownIndex: dropdownIndex,
                type: "",
                ticketIndex: ticketIndex,
              })
            );
          }
        } else {
          let data;
          // if the obj name are exist we need to add inside it
          if (!product.variant && !dropdownIndex) {
            data = [
              {
                ...newArray[0],
                [product.title]: [
                  ...newArray[0][product.title],
                  {
                    value: e,
                    ticketIndex: ticketIndex,
                    amount: product?.amount,
                    productId: product?._id,
                    type: product?.type,
                  },
                ],
              },
            ];
          } else {
            data = [
              {
                ...newArray[0],
                [product.title]: [
                  ...newArray[0][product.title],
                  {
                    value: e.target?.value,
                    indexSelected: dropdownIndex,
                    previousValue: "",
                    ticketIndex: ticketIndex,
                    amount:varientAmount?.[0]?.amount,
                    productId: product?._id,
                    type: product?.type,
                  },
                ],
              },
            ];
          }
          dispatch(
            eventsSlice.actions.setSelectedValue({
              data: data,
              productIndex: productIndex,
              productTitle: product.title,
              dropdownIndex: dropdownIndex,
              type: "",
              ticketIndex: ticketIndex,
            })
          );
        }
      }

      if (product.variant) {
        formik.setFieldValue(
          `ticketsData.[${index}].[${productIndex}].dropdowns[${dropdownIndex}]`,
          { ...dropdownObj, selectedValue: e.target?.value,amount:varientAmount?.[0]?.amount }
        );
      }
    };
    useEffect(() => {
      const newArr = [];
      // Handle product data inside the local storage
      if (selectedValue !== undefined && selectedValue?.length !== 0) {
        const Data =
          selectedValue &&
          Object.keys(selectedValue[0]).map((key) => {
            const formatData = selectedValue[0][key]?.map((item) => {
              if (item.ticketIndex === ticketUniqueId) {
                if (item.hasOwnProperty("indexSelected")) {
                  const isExistObj = newArr.find((item) => item?.title === key);
                  const findProductIndex = newArr.findIndex(
                    (product) => product.title === key
                  );
                  if (isExistObj && isExistObj !== undefined) {
                    if (
                      newArr[findProductIndex]?.variant?.hasOwnProperty(
                        item.value
                      )
                    ) {
                      newArr[findProductIndex].variant[item.value] =
                        newArr[findProductIndex].variant[item.value] + 1;
                    } else {
                      newArr[findProductIndex].variant[item.value] = 1;
                    }
                  } else {
                    newArr.push({
                      id: item?.productId,
                      title: key,
                      variant: {
                        [item.value]: 1,
                      },
                    });
                  }
                } else {
                  // check if we have type openAmount to add price to object
                  const productType = productsData?.filter(
                    (val) => val._id === item.productId
                  )[0];
                  if (item.value !== 0 && productType?.type === "openAmount") {
                    newArr.push({
                      id: item?.productId,
                      title: key,
                      quantity: item.value,
                      price: item?.price,
                    });
                  } else {
                    newArr.push({
                      id: item?.productId,
                      title: key,
                      quantity: item.value,
                    });
                  }
                }
                setNewData(newArr);
              }
            });
          });
      }
    }, [productsData, selectedValue, ticketUniqueId]);

    useEffect(() => {
      let newArray = newData?.filter((item) => item.quantity !== 0);
      let result = {};
      newArray.forEach((item) => {
        result[item.title] = item;
      });

      if (index !== -1) {
        formik.setFieldValue(`registrationForm[${index}].products`, newData.length === 1 && newData[0]?.quantity === 0 ? [] : [...newArray]);
      }
    }, [newData]);

    function addPriceToObjects(e, id, title) {
      const { value } = e.target;
      let data;
      const selectedValueIndex = selectedValue?.[0]?.[title]?.map((item) => {
        return { ...item, price: value };
      });
      data = [
        {
          ...selectedValue[0],
          [title]: [
            { ...selectedValue[0]?.[title]?.[0], price: Number(value) },
          ],
        },
      ];
      dispatch(
        eventsSlice.actions.setSelectedValue({
          data: data,
          productIndex: id,
          productTitle: title,
          dropdownIndex: null,
          type: "",
          ticketIndex: null,
        })
      );
      const addPrice = Object.values(varients).map((el) =>
        el.id === id ? { ...el, price: Number(value) } : el
      );
      dispatch(eventsSlice.actions.setVarients(addPrice));
    }

    return (
      <Wrapper>
        {productsData?.map((product, productIndex) => {      
          return (
            <>
              <ProductContent>
                <FirstDiv totalProducts={totalPay?.total !== '0.00' ||  totalProducts > 0}>
                  <FlexItemsContainer>
                    <FlexItem>
                      <Title>{product?.title}</Title>
                    </FlexItem>
                    <FlexItem>
                      <Counter
                        key={product._id}
                        product={product}
                        counter={product?.counter}
                        handleClickIncrement={() => {
                          incrementCounter(
                            product._id,
                            product,
                            product?.counter,
                            productIndex
                          );
                          !product.variant &&
                            handleSelect(
                              product?.counter + 1,
                              ticketUniqueId,
                              productIndex,
                              null,
                              null,
                              product,
                              product?.title
                            );
                        }}
                        handleClickDecrement={() => {
                          if (product?.counter > 0) {
                            decrementCounter(
                              product._id,
                              product,
                              product?.counter,
                              productIndex
                            );
                            !product.variant &&
                              handleSelect(
                                product?.counter - 1,
                                ticketUniqueId,
                                productIndex,
                                null,
                                null,
                                product,
                                product?.title
                              );
                          }
                        }}
                        disabled={
                          varients?.[productIndex]?.varientCapacity !==
                            "noLimit" &&
                          (varients?.[productIndex]?.varientCapacity ===
                            varients?.[productIndex]?.numberOfDrops ||
                            varients?.[productIndex]?.varientCapacity ===
                              varients?.[productIndex]?.counterValue)
                        }
                      />
                    </FlexItem>
                  </FlexItemsContainer>
                </FirstDiv>

                <SecondDiv>
                    <Amount>
                        {product.type === "openAmount" ? (
                          <AmountField>
                            <InputField
                              type='number'
                              placeholder=""
                              isDisabled={product?.counter === 0}
                              styleWrapper={{ width: "100%" }}
                              style={{
                                width: "100%",
                                paddingLeft: "20px",
                                paddingRight: "5px",
                                color: "#002E5C",
                                fontWeight: "500",
                                lineHeight: "13px",
                                textAlign: "right",
                              }}
                              isOptional={false}
                              name="amount"
                              handleChange={(e) => {
                                addPriceToObjects(
                                  e,
                                  product._id,
                                  product.title
                                );
                              }}

                            />
                            <AmountSign>{currency}</AmountSign>
                          </AmountField>
                        ) : (
                          !product?.variant && `${numberFormat({number:product?.amount, decimal: 2, style: "currency"})}`
                        )}
                      </Amount>
                  {product?.counter > 0 && product?.variant && (                      
                    <FormControl fullWidth className={classes.formControl}>
                      {product?.dropdowns?.map((dropdown, dropdownIndex) => (
                        <SelectWrapper thereIsAmount={dropdown.amount}>
                     
                      <VarientAmount>  {dropdown.amount ? `${numberFormat({number:dropdown.amount, decimal: 2, style: "currency"})}` : ''}</VarientAmount>
                        <Select
                          displayEmpty
                          onChange={(e) => {
                            
                            handleSelect(
                              e,
                              ticketUniqueId,
                              productIndex,
                              dropdownIndex,
                              dropdown,
                              product,
                              product?.title
                            );
                          }}
                          value={dropdown?.selectedValue}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          IconComponent={() => (
                            <img
                              src={Arrow} // Path to your PNG icon in the public directory
                              alt="Custom Arrow"
                              style={{
                                width: "12px",
                                height: "6.4px",
                                marginRight: 20,
                                position: "absolute",
                                right: 0,
                              }} // Adjust the size as needed
                            />
                          )}
                          MenuProps={menuProps}
                          classes={{
                            select: classes.select,
                            icon: classes.selectIcon,
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>Select</em>
                          </MenuItem>
                          {varients?.[productIndex]?.variantsInfo?.map(
                            (option, index) => (
                              <MenuItem
                                key={index}
                                value={option?.option}
                                disabled={
                                  (option?.quantity === option?.counterValue) || (option?.forMemberOnly && !isAuthenticated)
                                }
                                className="d-flex align-items-center justify-content-between"
                              >
                                <div 
                                  className="d-inline-flex"
                                  style={{
                                    gap: "5px",
                                  }} 
                                >
                                 {option?.option} {option?.forMemberOnly && !isAuthenticated && <span>(Members Only)</span>}
                                </div>
                                <span 
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                >
                                  {numberFormat({number:option?.amount, decimal: 2, style: "currency"})}
                                </span>
                              </MenuItem>
                            )
                          )}
                        </Select>
                        </SelectWrapper>
                      ))}
                    </FormControl>
                  )}
                </SecondDiv>
              </ProductContent>
              <SolidLine />
            </>
          );
        })}
      </Wrapper>
    );
  }
);
