import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";

import { Pagination } from "@material-ui/lab";
import AuthService from "Lib/API/Auth";
import { ReactComponent as AddUserSVG } from "assets/icons/add-user.svg";
import DefaultUserOPNG from "assets/icons/default-user.png";
import { formatDate } from "helper/dateFormat";
import { SubHeader } from 'layout/SubHeader';
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { familyMembers } from "services/api/dashboard";
import Action from "../../components/ActionPopOver";
import MyModal from "../../components/MyModal";
import MyTable from "../../components/Table";
import { withUserExist } from "../CustomHooks";
import { addMemmberUrl } from "../commonSlice";
import "./index.scss";
import AddNewUser from "./newUser.js";

const styles = {
  titleCard: {
    fontSize: 20,
    color: "#464E5F",
    marginLeft: `12px`,
    fontWeight: "bold",
  },
  buttonCardBody: {
    background: "#F2F6FD",
    minHeight: 70,
    minWidth: 80,
    borderRadius: 5,
  },
};

const FamilyMembersTab = () => {
  const [loading, setLoading] = useState(false);
  const [openNewMember, setOpenNewMember] = useState(false);
  const [myData, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const family_members = useSelector(
    (state) => state?.dashboardData?.family_members
  );
  let allMembers = family_members;
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const rowsPerPage = 5;
  const enableLoading = (value) => {
    setLoading(value);
  };

  
  const {disableEditingOnMemberPortal} = localStorage.getItem("organization")
  ? JSON.parse(localStorage.getItem("organization"))
  : {};    

  useEffect(() => {
    if(localStorage.getItem("redirectUrl")){
      history.push(localStorage.getItem("redirectUrl"));
    }
  }, [])

  // const fetchMyAPI = async () => {
  //   const accountID = localStorage.getItem("accountID");
  //   console.log(accountID, "accountID");
  //   const token = await getAccessTokenSilently();
  //   await AuthService.getFamilyMember(token, accountID)
  //     .then((res) => {
  //       const data = res ? res[0]?.family_members : "" || [];
  //       data.map((item) => {
  //         item.id = item._id;
  //         return item;
  //       });
  //       console.log(data, "familyMembers");
  //       // dispatch(familyMembers(data));
  //       enableLoading(false);
  //     })
  //     .catch((err) => {
  //       enableLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   fetchMyAPI();
  // }, [refresh]);
  const MyAPI = async () => {
    const accountID = localStorage.getItem("accountID");
    console.log(accountID, "accountID");
    const token = await getAccessTokenSilently();
    await AuthService.getFamilyMember(page + 1, 9, token)
      .then((res) => {
        const data = res?.contacts ? res?.contacts : [];
        console.log(data, "Asdfasdf");
        setCount(res?.total);
        dispatch(familyMembers(data));
      })
      .catch((err) => {
        enableLoading(false);
        toastr.error('Error', err?.data?.message);
      });
  };

  useEffect(() => {
    MyAPI();
  }, [refresh, page]);

  console.log(count, "family_members");

  const familyMembersActions = (data) => {
    const member = data?.row;
    const actions = {
      ...(disableEditingOnMemberPortal ? {
        onViewClick: () => {
          history.push(`add-new-member`, { member_to_edit: member, view_only: true});
        }
       }: {
        onEditClick: () => {
          history.push(`add-new-member`, { member_to_edit: member, view_only: false});
        },
        onDeleteClick: () => handleDelete(data?.id)
      }),
    }
    return actions;
  }

  const handleDelete = async (id) => {
    if (id) {
      const input = {
        contactID: id,
      };
      const token = await getAccessTokenSilently();
      await AuthService.removeMember(input, token)
        .then((res) => {
          enableLoading(false);
          setRefresh(true);
          // fetchMyAPI();
        })
        .catch((err) => {
          enableLoading(false);
          toastr.error('Error', err?.data?.message);
        });
    }
  };
  const columns = [
    {
      field: "first_name",
      headerName: "NAME",
      resizable: true,
      minWidth: 300,
      renderCell: (data) => {
        return (
          <div className="user-cotainer-home">
            <img
              alt="profile_img"
              src={data?.row?.profile_img || DefaultUserOPNG}
              style={{ width: "50px", height: "50px", borderRadius: "3px" }}
            />
            <div className="name-container">
              <p className="id-container">
                {data?.row?.first_name + " " + data?.row?.last_name}
              </p>
              {/* <p style={{ color: "#B5B5C3" }}>{data?.row?.hebrew_name}</p>
              <p
                style={{
                  color: `${
                    data?.row?.gender === "Male" ? "#a0bcf0" : "#efa7aa"
                  }`,
                  opacity: "0.6",
                }}
              >
                {data?.row?.gender || "-"}
              </p> */}
            </div>
          </div>
        );
      },
    },
    {
      field: "account_permission",
      headerName: "FAMILY ADMIN",
      resizable: true,
      minWidth: 130,
      renderCell: (data) => (
        <div style={{ color: "#575757", marginLeft: "30px" }}>
          {data?.row?.account_permission === "admin" ? "YES" : "NO"}
        </div>
      ),
    },
    {
      field: "gender",
      headerName: "SEX",
      minWidth: 150,
      renderCell: (data) => (
        <div className="row-data-gender">
          {data.row.gender ? data.row.gender : "-"}
        </div>
      ),
    },
    {
      field: "date_of_birth",
      headerName: "DOB",
      minWidth: 150,
      renderCell: (data) => (
        <div className="row-data-gender">
           {data.row.date_of_birth
            ? formatDate({date:data.row.date_of_birth, formatType: "defaultWithUTC"})
            : "-"}
        </div>
      ),
    },
    {
      field: "relationship",
      headerName: "RELATIONSHIP",
      minWidth: 150,
      renderCell: (data) => (
        <div className="row-data-gender">
          {data?.row?.relationship?.[0] ?? "-"}
        </div>
      ),
    },
    {
      field: "action",
      headerName: `${" "}`,
      minWidth: 150,
      renderCell: (data) => (
        <div className="row-data-gender">
           <Action
              {...familyMembersActions(data)}
            />
        </div>
      ),
    },
  ];



  return (
    <div className="family-member-table pb-10 m-5">
      <div className="p-5 borderBottom m-0">
        <SubHeader
            className="class-tab subheader"
            {...{
              title: "Family Members",
            }}
          />
      </div>
      <Card className="card card-custom p-4 mt-4 family-member-cards ">
        <div className="d-flex mt-6 justify-content-end">
          {!disableEditingOnMemberPortal && (
              <button
                onClick={() => {
                  // history.push("/add-new-member");
                  // dispatch(addMemmberUrl("family_member"));
                  setOpenNewMember(true);
                }}
                type="button"
                className={`btn btn-primary font-weight-bold px-2 px-lg-5 mr-2`}
                >
                {/* <span className="svg-icon svg-icon-lg">
                  <AddUserSVG />
                </span> */}
                <span>Add a family member</span>
              </button>
          )}

          <MyModal
            className="new-charge-modal"
            open={openNewMember}
            onClose={() => setOpenNewMember(false)}
          >
            <AddNewUser
              setOpen={setOpenNewMember}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </MyModal>
        </div>
        <div className="home-table-container-dashbord-page">
          <MyTable
            className="data-table"
            rowHeight={65}
            rows={allMembers}
            columns={columns}
            pageSize={99}
            disableSelectionOnClick
            loading={loading}
          >
            Rows per page: {rowsPerPage}
          </MyTable>
          <div className="pagination-custom mt-2">
            <Pagination
              count={Math.ceil(count / 9)}
              color="primary"
              variant="outlined"
              onChange={(event, value) => {
                setPage(value - 1);
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
export default withUserExist(FamilyMembersTab);
