import * as React from "react"

function ReturnSvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.428}
      height={15.409}
      {...props}
    >
      <path
        d="M19.021.152H12.25v1.5h6.771a.754.754 0 01.752.754v6.017a.753.753 0 01-.752.752H3.092l3.442-3.442-1.064-1.06-5.258 5.26 5.266 5.266 1.064-1.066-3.45-3.45h15.929a2.259 2.259 0 002.257-2.257V2.409A2.26 2.26 0 0019.021.152z"
        fill="#fff"
        stroke="#2555ef"
        strokeWidth={0.3}
      />
    </svg>
  )
}

export default ReturnSvgComponent
