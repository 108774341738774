import React from 'react'
import './style.scss';
import Fade from '@material-ui/core/Fade';
import CrossSvg from '../assets/icons/svg/cross'
import Dialog from '@material-ui/core/Dialog';
const MyModal = ({ onClose, open, clossable = true, children }) => {
    return (
        <Dialog
            className="my-modal"
            onClose={onClose}
            open={open}
        ><Fade in={open}>
                <>
                    <div className="cross-icon">
                        <CrossSvg onClick={onClose} />
                    </div>
                    <div className="modal-child d-flex align-items-center">
                        {children}
                    </div>
                </>
            </Fade>
        </Dialog>
    )
}
export default MyModal