import { InputField, LabelField } from "components";
import { ReactComponent as AddIcon } from "assets/icons/add-plus.svg";
import React, { useState, useMemo, useEffect } from "react";
import { findLast } from "lodash";
import { toastr } from "react-redux-toastr";
import CrossSvg from "../../../../assets/icons/svg/cross";
import { check } from "prettier";
export const Check = ({ formik, ...props }) => {
  const [checks, setChecks] = useState(formik.values?.checks ?? []);

  const addNewCheck = () => {
    const validate = formik.values.checks?.every((item) => {
      const { amount, check_date, check_number } = item;
      return !!(amount && check_date && check_number);
    });
    if (validate) {
      setChecks((prev) => [
        ...formik.values.checks,
        {
          amount: 0,
          check_date: "",
          check_number: "",
          paymentMethod: props.title,
        },
      ]);
    } else {
      toastr.info("Validation", "Please fill all the required fields first");
    }
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      checks,
    });
  }, [checks]);

  const removeCheck = (idx) => {
    const checksList = [...checks];
    checksList.splice(idx, 1);
    setChecks(checksList);
  };
  const exceptThisSymbols = ["e", "E", "+", "-"];

  const preventArrows = (e) => {
    if (e.which === 38 || e.which === 40 || exceptThisSymbols.includes(e.key))
      e.preventDefault();
  };
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  return (
    <>
      {formik.values?.checks?.map((item, idx) => (
        <div className="d-flex mt-5" style={{ marginBottom: -10 }}>
          <div className="col-sm-3" key={idx}>
            <InputField
              type="number"
              {...{
                formik,
                title: "Amount",
                name: `checks.${idx}.amount`,
                placeholderIcon: (
                  <span className="align-self-center pr-5">$</span>
                ),
                value: item?.amount,
              }}
              onKeyDown={(e) => preventArrows(e)}
              handleChange={(e) => {
                formik.setFieldValue(
                  `checks.${idx}.amount`,
                  e.target.value.match(regex)[0]
                );
              }}
            />
          </div>
          <div className="col-sm-3">
            <InputField
              type="date"
              {...{
                formik,
                title: "Date",
                name: `checks.${idx}.check_date`,
                value: item?.check_date,
              }}
            />
          </div>
          <div className="col-sm-4">
            <InputField
              type="string"
              {...{
                formik,
                title: "Check Number",
                name: `checks.${idx}.check_number`,
                value: item?.check_number,
              }}
              onKeyDown={(e) => preventArrows(e)}
              handleChange={(e) => {
                formik.setFieldValue(
                  `checks.${idx}.check_number`,
                  e.target.value.match(regex)[0]
                );
              }}
            />
          </div>
          <button
            type="button"
            className="btn btn-link btn-sm"
            onClick={() => removeCheck(idx)}
          >
            Delete
          </button>

          {/* <CrossSvg onClick={() => removeCheck(idx)} /> */}
        </div>
      ))}
      <div className="add-new-payment-info col my-4" onClick={addNewCheck}>
        <span className="add-icon">
          <AddIcon />
        </span>
        <span className="add">Add Check</span>
      </div>
    </>
  );
};
export const CheckHeader = ({ formik, ...props }) => {
  const { expanded, current } = props;
  const total = useMemo(() => {
    const values = findLast(
      formik.values?.payments ?? [],
      (item) => item.paymentMethod === props.title
    );
    return values?.amount ?? formik.values?.check_payment?.amount;
  }, [formik.values.payments, props.title]);
  const checksArray = formik.values?.checks.filter(function (value) {
    return !value.amount == "";
  });
  const totalValue = checksArray?.reduce(
    (pre, curr) =>
      parseFloat(pre) +
      (curr.amount == "" ? parseFloat(curr.amount) : parseFloat(curr.amount)),
    parseInt(0)
  );
  const handlefieldClick = (e) => {
    if (e.stopPropagation && expanded === true) {
      e.stopPropagation();
    }
  };
  const handleOnChange = (event) => {
    formik.setFieldValue("check", event.target.value);
  };
  const getCommaSeperatedValue = (num) => {
    return parseFloat(num?.toString().replace(/,/g, ""))
      .toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div
      className={
        expanded === false && current?.includes("check") === true
          ? "accordion-header"
          : expanded === false
          ? "accordion-payment-header"
          : expanded === true
          ? "accordion-header"
          : "accordion-header-payment"
      }
      id="some"
    >
      <LabelField title="Check" />
      <div
        className={
          expanded === false && current?.includes("check")
            ? "custom-expand dollor-icon col-sm-3 nonexpanded-amount"
            : expanded === false &&
              current?.includes("check") === false &&
              totalValue
            ? "custom-expand dollor-icon col-sm-3 nonexpanded"
            : "col-sm-3"
        }
      >
        {expanded === false && current?.includes("check") && totalValue ? (
          <div className="cash-non-expanded">
            <span>$</span>
            <span>{getCommaSeperatedValue(totalValue)}</span>
          </div>
        ) : current?.includes("check") && expanded === true ? (
          <InputField
            {...{
              formik,
              title: "none",
              name: `val`,
              style: { background: "#ffff", border: "none" },
              placeholderIcon: (
                <span
                  className="align-self-center px-4"
                  style={{ color: "black" }}
                >
                  $
                </span>
              ),
              value: totalValue,
            }}
            paymentInput="paymentInput"
            handleFocus={(e) => handlefieldClick(e)}
            handleChange={(event) => handleOnChange(event)}
            disabled
          />
        ) : expanded === false &&
          current?.includes("check") === false &&
          totalValue ? (
          <div className="cash-non-expanded">
            <span>$</span>
            <span>{getCommaSeperatedValue(totalValue)}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
