import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MyButton from "../MyButton";
import "./index.scss";
import { PulseLoader } from "react-spinners";

export default function AlertDialog({
  content,
  onAccept,
  onDecline,
  open,
  handleClose,
  okButtonContent,
  noButtonContent,
  disable,
  isLoading
}) {
  //Read Me!
  // on the buttonContent prop I send what we should desplay on the button
  //if you don't it desplay yes /no like before
  //fixed for the fillter.
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              fontWeight: "bold",
              width: "500px",
            }}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        {!disable && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <MyButton
              onClick={onAccept}
              content={isLoading ? <PulseLoader color={'#fff'} size={'7px'} /> :okButtonContent || "Yes"}
              style={{
                border: "none",
                width: "fit-content",
                margin: "5px",
              }}
            />
            {!okButtonContent &&
              <MyButton
                onClick={onDecline}
                content={noButtonContent || "No"}
                type="secondary"
                style={{
                  width: "fit-content",
                  margin: "5px",
                }}
              />
            }

          </div>
        )}
      </Dialog>
    </div>
  );
}
