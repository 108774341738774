import * as React from "react"

function HelpPeopleComponent (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={136.322}
            height={132.049}
            {...props}
        >
            <path
                data-name="Path 4353"
                d="M85.71 0a65.145 65.145 0 00-11.853 1.079 29.643 29.643 0 018.96 6.143 60.334 60.334 0 012.893-.078c11.949 0 23.109 3.531 31.426 9.943 7.764 5.987 12.041 13.777 12.041 21.937 0 6.7-2.862 13.137-8.279 18.613l-1.639 1.657-.346 2.3-2.9 19.275-14.279-9.815-2.444-1.68-2.915.545a58.223 58.223 0 01-10.664.98 58.858 58.858 0 01-8.492-.614c-.293.053-.585.106-.885.156l-2.718 2.477c3.166 1.693 6.38 3.421 9.408 5.07q1.335.054 2.687.055a65.2 65.2 0 0011.976-1.1l19.493 13.4a3.191 3.191 0 004.962-2.154l3.837-25.529c6.488-6.56 10.344-14.752 10.344-23.637C136.321 17.471 113.663 0 85.71 0z"
                fill="#f4f6f9"
            />
            <path
                data-name="Path 4354"
                d="M95.533 89.042c-4.1-2.829-18.427-10.482-24.544-13.751l-.128-.069a2 2 0 00-2.179.191 26.022 26.022 0 01-10.441 5.044 2 2 0 00-1.436 1.244l-3.789 9.988-3.79-9.988a2 2 0 00-1.436-1.244 26.027 26.027 0 01-10.441-5.044 2 2 0 00-2.178-.19c-6.048 3.231-20.558 11.045-24.654 13.807C3.588 93.699.562 110.586.025 113.923a2 2 0 00.089.986c.248.7 6.6 17.139 52.9 17.139s52.654-16.44 52.9-17.139a1.987 1.987 0 00.09-.986c-1.452-9.061-4.453-20.733-10.471-24.881z"
                fill="#b5b5c3"
            />
            <path
                data-name="Path 4355"
                d="M14.535 59.326h6.428a4.383 4.383 0 004.382-4.383V36.376a4.377 4.377 0 00-2.181-3.785c1.435-15.2 7.3-25.739 29.853-25.739 12.95 0 21.034 3.322 25.441 10.454 2.564 4.15 3.797 9.51 4.371 15.31a4.376 4.376 0 00-2.144 3.763v18.564a4.384 4.384 0 003.418 4.273c-2.835 1.852-8.7 3.746-20.845 3.746q-1.477 0-3.079-.038A3.751 3.751 0 0056.85 60.9h-5.358a3.75 3.75 0 100 7.5h5.357a3.74 3.74 0 003.038-1.557c1.129.028 2.233.046 3.3.046 13.691 0 22.395-2.337 25.919-6.974.151-.2.287-.394.413-.589h1.98a4.383 4.383 0 004.382-4.383V36.376a4.382 4.382 0 00-4.382-4.383h-2.418c-.68-7-2.188-12.967-5.288-17.985C78.211 4.972 68.143.58 53.017.58S27.823 4.972 22.24 14.008c-3.1 5.018-4.608 10.98-5.289 17.985h-2.416a4.384 4.384 0 00-4.384 4.383v18.567a4.384 4.384 0 004.384 4.383z"
                fill="#b5b5c3"
            />
            <path
                data-name="Path 4356"
                d="M27.706 59.143c6.019 13.292 18.912 19.083 25.311 19.083 4.206 0 11.219-2.5 17.241-7.992q-3.3.231-7.069.232c-.589 0-1.208-.005-1.871-.016a7.329 7.329 0 01-4.468 1.527h-5.358a7.323 7.323 0 110-14.645h5.357a7.3 7.3 0 015.088 2.055q.67.008 1.319.007a59.472 59.472 0 0014.457-1.4 8 8 0 01-.6-3.047V36.376a7.981 7.981 0 011.956-5.213c-.661-5.15-1.862-9.08-3.652-11.98-3.693-5.976-10.811-8.759-22.4-8.759-9.616 0-16.256 2-20.3 6.1-3.135 3.183-4.951 7.726-5.8 14.585a7.975 7.975 0 012 5.265v18.57a7.9 7.9 0 01-1.211 4.199z"
                fill="#b5b5c3"
            />
        </svg>
    )
}

export default HelpPeopleComponent
