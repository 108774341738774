import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Content, ButtonActions, CloseIcon } from './Theme'
import { ReactComponent as Close } from 'assets/icons/CloseFilter.svg';

export default function NewDialog({ isOpen, handleClose, title, sx, children, content, contentStyle, buttonStyle }) {


    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={handleClose}
            sx={sx}
        >
            <CloseIcon
                onClick={handleClose}
            >
                <Close style={{ cursor: 'pointer' }} />
            </CloseIcon>
            <DialogTitle id="alert-dialog-title" sx={{
                '&.MuiTypography-h6': {
                    fontSize: '20px',
                    fontWeight: '700',
                    textAlign: 'center',
                    color: 'var(--Dark-Blue-100, #002E5C)',
                    fontStyle: 'normal',
                    lineHeight: '32px',
                    textTransform: 'capitalize',
                },
            }}>
                {title}
            </DialogTitle>

            <Content style={contentStyle}>
                {content}
            </Content>
            <ButtonActions style={buttonStyle}>
                {children}
            </ButtonActions>
        </Dialog>
    );
}