import styled from "styled-components";


export const Content = styled.div`
p{
color: var(--Dark-Blue-100, #002E5C);
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
opacity: 0.7;
padding: 10px;
padding-left: 30px;
padding-right: 30px;
}
`

export const ButtonActions = styled.div`
display: flex;
justify-content: center;
align-items: center;
width:100%;
/* padding:30px; */
`

export const CloseIcon = styled.div`
text-align: right;
cursor: "pointer";
padding-right: 27px;
margin-top: 28px;
width: 100%;
`