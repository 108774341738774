import { useAuth0 } from "@auth0/auth0-react";
import { Counter } from "components/Counter/Counter";
import { numberFormat } from 'helper/numberFormat';
import React from 'react';
import { TicketsCardContainer, TicketsCardHead, TicketsCardName, TicketsCardNameForMemberOnly, TicketsCardPrice, TicketsCardQuantity } from './Theme';

const TicketCard = ({variant,handleQuantityChange,totalTicket,allowNonMembers}) => {
  const { isAuthenticated } = useAuth0();

  return (
    <TicketsCardContainer isDisabled={(!allowNonMembers && !isAuthenticated) || (variant.forMemberOnly && !isAuthenticated)}>
      <TicketsCardHead>
        <TicketsCardName>
          {variant.name} 
          <TicketsCardNameForMemberOnly>{(!allowNonMembers && !isAuthenticated) || (variant.forMemberOnly && !isAuthenticated) ? '(Members Only)' : ''}</TicketsCardNameForMemberOnly>
        </TicketsCardName>
        <TicketsCardQuantity>
        <Counter 
          width={"auto"}
          counter={variant.selectedQuantity}
          handleClickIncrement={() => {
            if(!variant.infiniteQuantity && (variant.selectedQuantity >= variant.quantity) || (variant.forMemberOnly && !isAuthenticated) || (!allowNonMembers && !isAuthenticated)) return;
            handleQuantityChange(variant.selectedQuantity + 1, 'increment')
          }}
          handleClickDecrement={() => {
            if(variant.selectedQuantity === 0 || (variant.forMemberOnly && !isAuthenticated) || (!allowNonMembers && !isAuthenticated)) return;
            handleQuantityChange(variant.selectedQuantity - 1, 'decrement')
          }}
          disabled={(!allowNonMembers && !isAuthenticated) || totalTicket >= 20 || (variant.forMemberOnly && !isAuthenticated)}
        />
        </TicketsCardQuantity>
      </TicketsCardHead>
      <TicketsCardPrice>
        {variant.amount ? numberFormat({number:variant.amount, decimal:2, style: "currency"}) : ""}
      </TicketsCardPrice>
    </TicketsCardContainer>
  )
}

export default TicketCard