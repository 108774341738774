import styled, { css } from "styled-components";
import { ReactComponent as ValIcon } from "assets/icons/ErrorIcon.svg"


export const FormWrapper = styled.div`
width: 85%;
display: flex;
flex-direction: column;
align-items: center;

`
export const FormHeader = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
export const Title = styled.div`
width: 100%;
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
color: #002E5C;
text-align: center;
margin-bottom: 15px;
margin-top: 30px;
`
export const Description = styled.div`
width: 454px;
height: 49px;
text-align: center;
color: #002E5C;
`
export const Form = styled.form`
width: 609px;
margin-left: 10%;
margin-top: 40px;



`
export const FormContent = styled.div`
margin-right: 60px;

`
export const TimeWrapper = styled.div`
position: relative;
`
export const Icon = styled.button`
position: absolute;
top: 57%;
right: 12px;
background-color: transparent;
border: none;
`
export const CheckBoxGroup = styled.div`
  width: 600px;
  display: flex;
  flex-flow: row wrap;
  position: relative;
`
export const Item = styled.div`
  margin: 4px;
  margin-bottom: 29px;
  flex: 0 1 calc(20% - 8px); /* <-- adjusting for margin */
`


export const InputFileWrapper = styled.div`
box-sizing: border-box;
width: 531px;
height: 99px;
left: 0px;
top: 1072px;
background: rgba(0, 46, 92, 0.03);
border: 1px dashed rgba(0, 46, 92, 0.2);
border-radius: 4px;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-bottom: 20px;
`
export const InputFile = styled.input`

`
export const ChooseFile = styled.span`
background-color: transparent;
border: none;
text-decoration: underline;
color: #2555EF;
`
export const Label = styled.label`
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #002E5C;
`
export const Link = styled.a`
`
export const DatePickerWrapper = styled.div`
div{
  div.react-datepicker__input-container input{
    width: 549px;
    border: none;
    background-color: #f3f6f9;
    border-radius: 5px;
    height: 48px;
    color: #3f4254;
    padding-inline: 10px;
  }
}
`
export const FilesName = styled.ul`
list-style: none;
color:red;


li{
padding-bottom: 10px;
}
div{
  display: flex;
  justify-content: space-between;
  width:90%;
}
${(props) => (props?.fileNum === 1 &&
    css`
    display: flex;
    width:80%;
      `
  )};

`
export const TextArea = styled.textarea`
width: 100%;
height: 80px;
padding: 10px;
background-color: #F3F6F9;
    border-color: #F3F6F9;
    color: #3F4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
`
export const ValidationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Pink-20, #FFE5ED);
  background: var(--Pink-10, #FFF5F8);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 30px;
`
export const ValidationItems = styled.ul`
  color:#002E5C;
  padding-top: 8px;

  li{

  }
`
export const ValidationIcon = styled(ValIcon)`
  

`