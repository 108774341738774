import { store } from "app/store";
import get from "lodash/get";
import includes from "lodash/includes";
import { toastr } from "react-redux-toastr";
import { authAPI } from "services/api";

const AUTH_CHECK_METHODS = ["post", "put", "patch", "delete", "get"];

const tokenSelector = (state) => state.auth?.entities?.token;

export const AuthRequestInterceptor = () => {
  return [
    (config) => {
      if (includes(AUTH_CHECK_METHODS, config.method)) {
        const authToken = tokenSelector(store.getState());
        if (authToken) {
          config.headers["Authorization"] = "Bearer " + authToken;
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error.data.error.message);
    },
  ];
};

export const ErrorResponseInterceptor = () => {
  return [
    (response) => {
      return response;
    },
    (error) => {
      const responseStatus = get(error, "response.status");
      const responseUrl = get(error, "response.config.url");

      if (responseStatus === 403 || responseStatus === 401) {
        store.dispatch(authAPI.authSlice.actions.endSession());
        if (responseUrl !== "auth/login")
          toastr.error("Error", "Session has been expired!");
      }

      if (responseStatus === 409) {
        window.location = "/";
      }

      return Promise.reject(error);
    },
  ];
};
