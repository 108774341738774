import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import EventsCalenderLink from './EventsCalendarLink';
import InitOrg from './InitOrg';

import { Auth0Provider } from '@auth0/auth0-react';
import { setLocalizationMoment } from './helper/dateFormat';
import ExternalLinkDonation from './pages/ExternalLinkDonation';
import { EventLink } from './pages/ExternalLinkEvents';
import { FormLink } from './pages/ExternalLinkForms';
import { SchoolLink } from './pages/ExternalLinkSchool';
import { ChargeExternalLink } from './pages/MemberLink/PayFromExternalLink/ChargeExternalLink';
const Application = (props) => {
  const redirectUri = window.location.protocol + "//" + window.location.host;
  const onRedirectCallback = (appState) => {
    // history.push(
    //   appState && appState.returnTo
    //     ? appState.returnTo
    //     : window.location.pathname
    // );
  };

  const orgName = localStorage.getItem("orgName") || "Temple Shalom";
  const token = localStorage.getItem("token")
  const subDomain = window.location.hostname.split('.')[0];
   const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
  const donationPath = token ? `${hostName}/donation` : "/donation"
  
  const country = JSON.parse(
    localStorage.getItem("country") !== "undefined"
    ? localStorage.getItem("country")
    : "{}"
  );
  
  useEffect(() => {
    if (country?.code) {
      setLocalizationMoment(country?.local);
    }
  }, [country])


  return (
    <>
    <Helmet>
        <title>{orgName}</title>
      </Helmet>
    <Router>
      <Switch>
        <Route
          path="/donation"
          render={() => (
            <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={redirectUri}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
            issuer={process.env.REACT_APP_ISSUER}
            audience={process.env.REACT_APP_AUDIANCE}
            scope={process.env.REACT_APP_SCOPE}
          >
            <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={4000}
            style={{ fontSize: '1.3rem' }}
          >
            <ExternalLinkDonation external={true}/>
          </SnackbarProvider>
          </Auth0Provider>
          )}
        />
        <Route
          path="/school"
          render={() => (
              <SchoolLink/>
          )}
        />
        <Route
          path="/events-calendar"
          render={() => (
            <EventsCalenderLink />
          )}
        />
        <Route
          path="/charges"
          render={() => (
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={4000}
              style={{ fontSize: '1.3rem' }}
            >
              <ChargeExternalLink />
            </SnackbarProvider>
          )}
        />
         <Route 
            path='/events'
            render={() => (
                <EventLink />
            )}
        />
          <Route
          path="/form"
          render={() => (
              <FormLink />
          )}
        />
        <Route path="/">
          <InitOrg />
        </Route>
      </Switch>
    </Router>
    </>

  );
};

export default Application;