import { useAuth0 } from "@auth0/auth0-react";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import EvevntService from "Lib/API/Events";
import CardImage from "assets/icons/EventInfoImg.png";
import { ContactUs } from "components/ContactUs/ContactUs";
import { useFormik } from "formik";
import * as calculation from "helper/CalculateEvent";
import { postalCodeFormat, postalCodeLabel } from "helper/postalCodeFormat";
import { useMobileView } from "hook/mobileView/useMobileView";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { eventsSlice } from "services/api/events";
import thousands from "thousands";
import Header from "./Header/Header";
import { PaymentCard } from "./PaymentCard/PaymentCard";
import { PaymentResultPage } from "./PaymentResultPage/PaymentResultPage";
import { PersonalDetailsCard } from "./PersonalDetailsCard/PersonalDetailsCard";
import { SummaryCard } from "./SummaryCard/SummaryCard";
import {
  CardContent,
  ImageCard,
  ImageContainer,
  PaymentButtonSubmit,
  Wrapper,
} from "./Theme";
import { Ticket } from "./Tickets/Ticket";

export const ReservationEventPage = () => {
  const { isAuthenticated, getAccessTokenSilently, isLoading: isAuthenticatedLoading,logout } = useAuth0();

  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useMobileView();
  const addCardStripeRef = useRef(null);

  const params = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const { eventData, numberOfTickets, products, FormDataEvent,totalProducts,ticketTypeVariants,ticketTypeList } = useSelector(
    (state) => state?.Events
  );
  const {logoImage} = useSelector((state) => state.organization);

  const postalLabel = postalCodeLabel();
  const {provider_information} = localStorage.getItem("organization")
  ? JSON.parse(localStorage.getItem("organization"))
  : {};    
  const {provider} = provider_information || {};
  const {publicKey} = provider_information?.keys || {};

  const [isLoading, setLoading] = useState(false);
  const [isLoadingPay, setLoadingPay] = useState(false);
  const [error, setError] = useState({ value: true, message: "" });
  const [isCheckedApplay, setIsCheckedApplay] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);


  const reservationId = localStorage.getItem("reserveId");
  const logo = isAuthenticated ? logoImage : localStorage.getItem("logoImgEvent");
  const paymentMethod = JSON.parse(localStorage.getItem("paymentMethodEvents"));
  const token = localStorage.getItem("token");
  const formData = new FormData();
  const [firstRender, setFirstRender] = useState(false);
  const handleTotalForPay = () => {
    const totalforAllTicketsAfterDiscount = calculation.calculateTotalPayment(paymentMethod,eventData,ticketTypeVariants, "type",isAuthenticated) ;
      return {
        total: (+totalforAllTicketsAfterDiscount + +totalProducts).toFixed(2),
        totalcountTickets:(+totalPayforAllTickets).toFixed(2),
      };
  };
  const totalPay =  handleTotalForPay()
  const makeEventFormsFullScreen = totalPay?.total !== '0.00' ||  totalProducts > 0

  // TODO: split formik into multiple instances for each form
  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: false,
    initialValues: {
      registrationForm: [],
      errors: [],
      ticketsData: Array.from({ length: numberOfTickets?.length }, () => [
        ...products,
      ]),
      dropdowns: [],
      accountInfo: {},
      cardsDetails: {},
      resultPage: "",
      billMyAccount: false,
    },
    validationSchema: false,
    onSubmit: async (values) => {
    },
  });
  const checkIsStripe = () => {
      return provider === "Stripe" && publicKey;
  };

  useEffect(()=>{
    if(!firstRender && numberOfTickets.length >0){
    formik.setFieldValue("ticketsData",Array.from({ length: numberOfTickets?.length }, () => [
      ...products,
    ]),)
    setFirstRender(true)
      }
 
},[numberOfTickets])
  const formDataReservation = formik?.values?.registrationForm || [];

  const totalPayforAllTickets = useMemo(
    () => eventData?.eventPricing?.total_to_pay * numberOfTickets.length,
    [eventData, numberOfTickets]
  );

  const totalPayment = useMemo(
    () =>
      calculation
        ?.calculateDiscount(eventData, ticketTypeVariants, "event",isAuthenticated)
        ?.totalAfterDiscount?.replace(/,/g, ""),
    [eventData, numberOfTickets]
  );

  const thereIsFormEmpty = useMemo(
    () => formDataReservation?.some((item) => item?.formData?.length === 0),
    [formDataReservation]
  );



  const getFormById = async () => {
    // restore selected deropDown List
    dispatch(eventsSlice.actions.resetAction([]));
    //  Get data for the first time (without any update on varients) when page loads
    dispatch(
      eventsSlice.actions.setVarients(
        JSON.parse(localStorage.getItem("originVarients"))
      )
    );
    try {
      setLoading(true);
      const token = isAuthenticated ? await getAccessTokenSilently() : localStorage.getItem("eventToken")
      const res = await EvevntService.getFormById(
        token,
        eventData?.form,
        localStorage.getItem("eventOrgId")
      );
      dispatch(eventsSlice.actions.setFormReservationData(res?.task_data));
    } catch (error) {
      enqueueSnackbar(error?.data?.message, {
        variant: "error",
      });
      dispatch(eventsSlice.actions.setFormReservationData({}));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem("redirectUrl");
    return () => {
      localStorage.setItem("registrationForm", []);
      localStorage.setItem("ApplayCheckBox", JSON.stringify(false));
    };
  }, []);

 
  const convenienceFee = useMemo(
    () =>
    paymentMethod?.coverConvenienceFee ?
    paymentMethod?.convenienceFeeInfo?.type === "%" 
    ? (totalPay?.total * paymentMethod?.convenienceFeeInfo?.amount) / 100 : 
     paymentMethod?.convenienceFeeInfo?.amount : 0,
    [totalPayment, paymentMethod]
  );
  const totalPaymentWithCVFee = formik?.values?.billMyAccount ? totalPay?.total : +totalPay?.total + +convenienceFee;


  const ConvertFormToFormData = (data) => {
    const dataArray = [...data];
    if (dataArray[0] !== undefined) {
      // TODO: Replace map with forEach since there is nothing returned from the map
      const formDataArray = dataArray.map((dataItem, dataIndex) => {
        const { id, formData: formDataItem,products, ticket } = dataItem;
        // ? Check if this is in use; if not, consider deleting it.
        formData.append(
          `registrationForm[${dataIndex}][formData]`,
          JSON.stringify([...formDataItem])
        );
        formData.append(`registrationForm[${dataIndex}][id]`, `${id}`);
        formData.append(
          `registrationForm[${dataIndex}][products]`,
          JSON.stringify([...products])
        );
        if(!eventData?.freeEvent){
          formData.append(`registrationForm[${dataIndex}][ticket]`,  JSON.stringify(ticket));
        }
        // ? Check if this is in use; if not, consider deleting it.
        const formDataObj = formDataItem?.forEach((dataItem) => {
          if (dataItem?.name?.includes("file_upload")) {
            if (dataItem?.value && dataItem?.value !== null) {
              formData.append(
                `registrationForm[${dataIndex}][files][${id}]`,
                dataItem?.value
              );
            }
          }
        });
      });
    }
  };
  const handleDataForTwoFlow = (tokenId = "") => {
    formData.append(`isMember`, isAuthenticated ? true : false);
    if(!isAuthenticated) {
      formData.append(`orgId`, localStorage.getItem("eventOrgId"));
    }
    formData.append(`eventId`, params?.id);
    formData.append(`reservationId`, reservationId);

    const objectName = "cardsDetails";
    const objectData = formik?.values?.cardsDetails;
    if(!formik?.values?.billMyAccount) {
      if(isAuthenticated) {
        formData.append("cardId", selectedCard)
      } 
      else if(tokenId) { 
        formData.append("token",tokenId)
      } 
      else {
      for (const key in objectData) {
        if (key === "expiry") {
          formData.append(
            `${objectName}[exp_month]`,
            objectData[key]?.split("/")[0]
          );
          formData.append(
            `${objectName}[exp_year]`,
            objectData[key]?.split("/")[1]
          );
        } else if (key === "card_number") {
          formData.append(`${objectName}[number]`, objectData[key]);
        } else {
          formData.append(`${objectName}[${key}]`, objectData[key]);
        }
      }
    }
  } else {
    formData.append(`billMyAccount`, true)
  }
    // Account Info
    if(isAuthenticated) return;
    const objectName2 = "accountInfo";
    const objectData2 = formik?.values?.accountInfo;
    for (const key in objectData2) {
      if (key === "addresses") {
        const objeLocation = formik.values?.accountInfo?.addresses[0].location;
        if (Object.keys(objeLocation).length !== 0) {
          formik.values?.accountInfo?.addresses[0].location?.formattedAddress &&
            formData.append(
              `${objectName2}[addresses][0][location][formattedAddress]`,
              formik.values?.accountInfo?.addresses[0].location
                ?.formattedAddress
            );
          formik.values?.accountInfo?.addresses[0].location?.place_id &&
            formData.append(
              `${objectName2}[addresses][0][location][place_id]`,
              formik.values?.accountInfo?.addresses[0].location?.place_id
            );
          formik.values?.accountInfo?.addresses[0]?.city &&
            formData.append(
              `${objectName2}[addresses][0][city]`,
              formik.values?.accountInfo?.addresses[0]?.city
            );
          formik.values?.accountInfo?.addresses[0]?.state &&
            formData.append(
              `${objectName2}[addresses][0][state]`,
              formik.values?.accountInfo?.addresses[0]?.state
            );
          formik.values?.accountInfo?.addresses[0]?.zip_code &&
            formData.append(
              `${objectName2}[addresses][0][zip_code]`,
              formik.values?.accountInfo?.addresses[0]?.zip_code
            );
          formik.values?.accountInfo?.addresses[0]?.addressLine2 &&
            formData.append(
              `${objectName2}[addresses][0][addressLine2]`,
              formik.values?.accountInfo?.addresses[0]?.addressLine2
            );
        }
      } else {
        formData.append(`${objectName2}[${key}]`, objectData2[key]);
      }
    }
  };

  // get stripe token
  const handleGetStripeToken = async () => {
      const tokenId = await addCardStripeRef?.current?.handleGetToken();
      if(tokenId && tokenId !== null && token !== undefined){
        return tokenId
      }
      return "";
  }
  
  // Api For Pay
  const PayNow = async (hasStripe) => {   
    if(isLoadingPay) return;
    const thereIsFormEmpty = formDataReservation?.some((item) => item?.formData?.length === 0)
  if(thereIsFormEmpty){
    enqueueSnackbar("Please fill tickets data", {
      variant: 'error',
  });
  }else{
    const requiredFields = FormDataEvent;
    const dataValidation = formik?.values?.registrationForm?.map((item,index) => {
      return {
        ...item,
        formData: item?.formData?.map((subItem,subItemIndex) => {
          const requireds = requiredFields?.find(
            (tofinditem) => (tofinditem.id === subItem.id && tofinditem.required)
            );
            if (subItem?.name.includes('file_upload') && requireds !== undefined) {
              if(!subItem.value?.name && subItem.value?.name === undefined) {
                return {errorMessage:`The ${requireds?.label} is required`}
              } else {
                return {errorMessage: ""}
              }
            }
            if(subItem.value && subItem?.value?.length >0 ){
                if(subItem?.name.includes('email_input')) {
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  return {errorMessage: emailRegex.test(subItem.value) ? "" : `Please enter valid email for (${requiredFields[subItemIndex]?.label}) field`}
                }
                if (subItem?.name.includes('phone_input')) { 
                  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; 
                  return {errorMessage: phoneRegex.test(subItem.value) ? "" : `Please enter valid Phone Number for (${requiredFields[subItemIndex]?.label}) field`}
                } 
              
                else {
                  return {errorMessage: ""}
                }
            }
            if (requireds !== undefined) {
                return {errorMessage:`The ${requireds.label} is required`}
              }else{
                return {errorMessage:""}
              }
            
        }).filter((element)=>element.errorMessage !== ""),
      };
    })
    formik.setFieldValue("errors",dataValidation)
    const allErrorsEmpty = dataValidation.every(item => item.formData.length === 0);

    if(allErrorsEmpty){
      const validZipCode = postalCodeFormat(formik?.values?.cardsDetails?.zip);
      if (!checkIsStripe() && formik?.values?.cardsDetails?.zip?.length  && !validZipCode && !formik?.values?.billMyAccount && !isAuthenticated) {
        enqueueSnackbar(`Invalid ${postalLabel}`, { variant: "info" });
      }
      let tokenId = "";
      if(checkIsStripe() && !formik?.values?.billMyAccount && !isAuthenticated) {
        tokenId = await handleGetStripeToken()
      }

      if(isAuthenticated && !selectedCard && !formik?.values?.billMyAccount) {
          enqueueSnackbar("Please select a credit card", { variant: "error" });
          return;
      }

    if((hasStripe && tokenId) || !hasStripe || formik?.values?.billMyAccount || (isAuthenticated && selectedCard)) {
      handleDataForTwoFlow(tokenId || "")
    ConvertFormToFormData(formDataReservation)
            if (error?.message) {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
            setLoadingPay(false);
        } else {
            setLoadingPay(true);
            try {
                const token = isAuthenticated ? await getAccessTokenSilently() : localStorage.getItem("eventToken")
                await EvevntService.createParticipant(token,formData).then(res => {
                    formik.setFieldValue('resultPage', 'success');
                })

            } catch (error) {
                const message = error?.data?.message ? error?.data?.message : "There is somthing wrong"
                enqueueSnackbar(message, {
                  variant: "error",
                });
            } finally {
                setLoadingPay(false);
            }
        }
      }
    }else{
      enqueueSnackbar("You have errors in filling out the form , please check them", {
        variant: 'error',
    });
    }

    }
  };

  
  useEffect(() => {
    if(isAuthenticatedLoading) return;
    if (
      Object.keys(eventData).length !== 0 &&
      !eventData?.allowNonMembers &&
      !token
    ) {
      history.push(`/events/${params?.id}`);
    } else {
      if(isAuthenticated) {
        const accountDetails = JSON.parse(localStorage.getItem("accountInfo"));
        formik.setFieldValue("accountInfo", {
          first_name: accountDetails?.firstName,
          last_name: accountDetails?.lastName,
          primary_email_address: accountDetails?.primary_email_address,
        });
      }
      if (Object.keys(eventData).length !== 0) getFormById();
    }
  }, [eventData,isAuthenticatedLoading, isAuthenticated]);

  useEffect(() => {
    // ? why are we setting the field (cardDetails) value to the same field value (formik.values?.cardsDetails) ? (it seems that this is a bug)
    formik.setFieldValue("cardsDetails", formik.values?.cardsDetails);

    if(!isAuthenticated) {
      formik.setFieldValue("accountInfo", formik.values?.accountInfo);
    }


    let keysWithoutData = [];
    if (formDataReservation?.length === 0) {
      numberOfTickets.map((ticketId) => {
        const ticketType = ticketTypeList?.find((item) => item.id === ticketId)
        keysWithoutData.push({ id: ticketId, formData: [], products: [], ticket: ticketType ? {
          id: ticketType.ticketId,
          title: ticketType.name,
        } : {} });
      });
      formik.setFieldValue("registrationForm", keysWithoutData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, numberOfTickets]);


  const showTimer = () => {
    let show = false;
    const isThereInfiniteCapacity = ticketTypeVariants?.every(
      (item) => item?.infiniteQuantity
    );
    if (eventData?.eventDetails?.infiniteCapacity && isThereInfiniteCapacity) {
      show = false;
    }else {
     show = true;
    }
    return show;
  }


  
  const resetReservation = () => {
    formik.setFieldValue("ticketsData",Array.from({ length: numberOfTickets?.length }, () => [
      ...products,
    ]),)
    dispatch(
      eventsSlice.actions.setVarients(
        JSON.parse(localStorage.getItem("originVarients"))
      )
    );
  }

  const handleLogout = () => {
    formik.setFieldValue("accountInfo", {});
    logout({ 
       localOnly: true,
       openUrl: false
     });
     resetReservation();
  }
 
  return (
    <>
      {formik.values?.resultPage !== "" ? (
        <PaymentResultPage
          result={formik.values?.resultPage}
          formik={formik}
          isTherePayment={(totalPay?.total !== '0.00' ||  totalProducts > 0)}
          showThanksMsg={formik.values?.billMyAccount || false}

        />
      ) : (
        <>
          <Header 
            Logo={logo}
            showTimer={showTimer()}
          />
          <Wrapper>
            <Grid
              container
              spacing={2}
              sx={{ width: isMobile ? "348px" : "100%" }}
            >
              <Grid item xs={12} sm={12} md={12} lg={!makeEventFormsFullScreen ? 12 : 7} direction="column">
                <Paper
                  sx={{
                    height: isMobile ? "165px" : "227px",
                    borderRadius: "10px",
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1A2027" : "#2555EF",
                  }}
                >
                  <ImageContainer isMobile={isMobile}>
                    <CardContent isMobile={isMobile}>
                      <h1>{eventData?.eventName}</h1>
                    </CardContent>
                    <div>
                      <ImageCard src={CardImage} alt="bg" isMobile={isMobile} />
                    </div>
                  </ImageContainer>
                </Paper>

                {numberOfTickets?.map((item, index) => (
                  <>
                    <Ticket
                      key={`ticket_${isCheckedApplay}`}
                      index={index}
                      formik={formik}
                      ticketUniqueId={item}
                      setIsCheckedApplay={setIsCheckedApplay}
                      isCheckedApplay={isCheckedApplay}
                      totalProducts={totalProducts}
                      handleTotalForPay={handleTotalForPay}
                    />
                    <br />
                  </>
                ))}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={5} direction="column">
                <SummaryCard
                  handleTotalForPay={handleTotalForPay}
                  convenienceFee={convenienceFee}
                  totalPaymentWithCVFee={totalPaymentWithCVFee}
                  billMyAccount={formik?.values?.billMyAccount}
                />

                <PersonalDetailsCard
                  formik={formik}
                  isLoadingPay={isLoadingPay}
                  PayNow={PayNow}
                  error={error}
                  setError={setError}
                  handleTotalForPay={handleTotalForPay}
                  totalProducts={totalProducts}
                  isTherePayment={makeEventFormsFullScreen}
                  showZipCode={!isAuthenticated}
                  isAuthenticated={isAuthenticated}
                  handleLogout={handleLogout}
                  logout={logout}
                />

                <PaymentCard
                  TotalForPay={totalProducts}
                  formik={formik}
                  setError={setError}
                  error={error}
                  PayNow={PayNow}
                  isLoadingPay={isLoadingPay}
                  handleTotalForPay={handleTotalForPay}
                  thereIsFormEmpty={thereIsFormEmpty}
                  setLoadingPay={setLoadingPay}
                  checkIsStripe={checkIsStripe}
                  addCardStripeRef={addCardStripeRef}
                  totalPaymentWithCVFee={totalPaymentWithCVFee}
                  enableBillMyAccount={eventData?.allowBillMyAccount || false}
                  isAuthenticated={isAuthenticated}
                  setSelectedCard={setSelectedCard}
                  selectedCard={selectedCard}
                />
              </Grid>
            </Grid>

            <ContactUs eventData={eventData} />
          </Wrapper>
          {handleTotalForPay()?.total === "0.00" && totalProducts <= 0&& (
            <Grid item xs={12}>
              <PaymentButtonSubmit isDisabled={thereIsFormEmpty}>
                <button
                  disabled={thereIsFormEmpty}
                  onClick={() => {
                    PayNow();
                  }}
                >
                  {isLoadingPay ? (
                    <ClipLoader
                      color={"#ffffff"}
                      loading={isLoadingPay}
                      size={32}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </PaymentButtonSubmit>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
