import { Avatar } from "@material-ui/core";
import MemberDrawerSvg from "assets/icons/svg/memberIcon";
import ReturnSvgComponent from "assets/icons/svg/returnSvg";
import RightArrowSvg from "assets/icons/svg/myRightSvg.js";
import React from "react";
import Select, { components } from "react-select";
import { HintField } from "..";
import "./index.scss";
export const AsyncAutoCompleteField = ({
  handleInputChange,
  handleChange,
  touched,
  error,
  ...props
}) => {
  const formatOptionLabel = ({ label, id, profile_img }) => {
    return (
      <div className="dropdown-container" key={id}>
        <div className="dropdown-member-icon">
          <MemberDrawerSvg height={20} width={20} />
        </div>
        <div className="icon-name-container">
          {profile_img ? (
            <img
              height={40}
              width={40}
              className="rounded-circle"
              src={profile_img}
              alt="profileImg"
            />
          ) : (
            <Avatar style={{ width: 30, height: 30 }}>
              {label?.charAt(0)}
            </Avatar>
          )}
          <div className="name-container">
            <div>{label}</div>
            <div className="id-container">#{id}</div>
          </div>
        </div>
        <div className="return-icon">
          <ReturnSvgComponent width={24} height={24} />
        </div>
      </div>
    );
  };
  const selectControlStyles = {
    backgroundColor: "#F3F6F9",
    borderColor: "#F3F6F9",
    color: "#3F4254",
    transition:
      "color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease",
    // paddingTop: "8px",
    // paddingBottom: "8px",
    height: "48px",
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      ...selectControlStyles,
      ...props.style,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#d9e4f6",
      },
    }),
    option: (styles) => {
      return {
        "&:hover": {
          cursor: "pointer",
        },
        ...styles,
      };
    },
    menu: (styles) => {
      return {
        ...styles,
      };
    },
    IndicatorSeparator: (styles) => ({ display: "none" }),
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <RightArrowSvg label="Emoji" />
      </components.DropdownIndicator>
    );
  };
  return (
    <div>
      <Select
        onInputChange={(text) => handleInputChange(text)}
        formatOptionLabel={formatOptionLabel}
        styles={colourStyles}
        onChange={handleChange}
        components={{ DropdownIndicator }}
        {...props}
      />
      {touched && error ? <HintField error={error} /> : null}
    </div>
  );
};
