import * as React from "react";

function BottomArrowSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 15.744 9.002"
      fill="#B5B5C3"
    >
      <path
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        d="M12,13.786l5.953-5.958a1.12,1.12,0,0,1,1.589,0,1.135,1.135,0,0,1,0,1.594L12.8,16.172a1.123,1.123,0,0,1-1.552.033L4.453,9.427A1.125,1.125,0,0,1,6.042,7.833Z"
        transform="translate(-4.125 -7.498)"
      />
    </svg>
  );
}

export default BottomArrowSvg;
