import styled from "styled-components"
import { ReactComponent as CardImage } from 'assets/icons/CardImg.svg';


export const Wrapper = styled.div`
padding-top:30px ;
display: flex;
justify-content: center;
align-items: center;
  `
export const Ledger = styled.div`
display: flex;
flex-direction: column;
overflow: hidden;
white-space: nowrap;
color: var(--black, #4F4F4F);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
`
export const SubLedger = styled.span`
color: var(--dark-blue, #002E5C);
font-size: 12px;
font-weight: 400;
opacity: 0.800000011920929;
`
export const Spinner = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`
export const NoOpenCharges = styled.div`
width:100%;
height: inherit;
display: flex;
justify-content: center;
align-items: center;
font-weight: bold;
font-size: 18px;

`
export const Header = styled.div`
width: 100%;
height: 91px;
display: flex;
justify-content: left;
align-items: center;
`

export const ImageContainer = styled.div`
    height:${(props) => (props.isMobile ? '100%' : '227.966px')};
    overflow: hidden;
    display: flex;
    justify-content: space-between;
`
export const ImageCard = styled(CardImage)`
    float: right;
    margin-top: 15px;
    margin-right:${(props) => (props.isMobile ? '-147px' : '50px')};
`
export const CardContent = styled.div`
    margin-left: 35px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color:#fff;
    font-style: normal;
    line-height: 161.5%;
    width: 100%;
    h1{
    font-weight: bold;
    font-size:${(props) => (props.isMobile ? '18px' : '28px')};
    width: 100%;
    }
    span{
        width: fit-content;
    padding: 3px 5px;
        height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: var(--Blue-20, #EAEEFC); 
    color:rgba(37, 85, 239, 1);
    margin-top: ${(props) => (props.isMobile ? '6px' : '50px')};
    }
`