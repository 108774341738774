import styled from "styled-components";


export const Wrapper = styled.div`
display: flex;
justify-content: center;
text-align: left;
align-items: left;
background-color: #FFFFFF;
flex-direction: column;
position: relative;
`
export const Title = styled.h1`
margin-top: 50px;
margin-bottom: 50px;
font-weight: bolder;
width: 100%;
`
export const List = styled.ul`
list-style: none;
`
export const ListItem = styled.li`
font-size: 20px;
margin-bottom: 20px;
`
export const Content = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;
align-items: center;
background-color: #FFFFFF;
position: relative;
`
export const SpinnerForList = styled.div`
/* position: absolute;
    top: 50%;
    left: 50%; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const HeaderWrapper = styled.div`
    padding-top: 50px;
`
export const WrapperPagination = styled.div`
/* width: 100%;
position: absolute;
bottom: -7%;
display: flex;
justify-content: center;
margin-top: 10px;
padding-bottom: 10px; */

`
