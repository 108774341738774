import styled from "styled-components";


export const Wrapper=styled.div`
display: flex;
justify-content: center;
align-items: center;
font-size: 14px;
font-weight: 500;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-style: normal;
line-height: 150%;
`

export const PrimaryButton=styled.button`
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
width: ${(props)=>(props.width ? props.width : '130px')};
height: ${(props)=>(props.height ? props.height : '52px')};
color: ${(props)=>(props.isDisabled? 'var(--Gray-100, #B5B5C3)':'var(--White, var(--White-100, #FFF))')};
background: ${(props)=>(props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : 'var(--blues-blue-main, #2555EF)')};
:hover{
    background: ${(props)=>(props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : '#214DD7')};
}

cursor: ${(props)=>(props.isDisabled ? 'not-allowed !important' : 'pointer !important')};
`
export const SecondaryButton=styled.button`
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
width: ${(props)=>(props.width ? props.width : '130px')};
height: ${(props)=>(props.height ? props.height : '52px')};
color: ${(props)=>(props.isDisabled? 'var(--Gray-100, #B5B5C3)':'var(--Blue-100, #2555EF)')};
background: ${(props)=>(props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : 'rgba(37, 85, 239, 0.08)')};
:hover{
    background: ${(props)=>(props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : 'rgba(37, 85, 239, 0.20)')};
}
cursor: ${(props)=>(props.isDisabled ? 'not-allowed !important' : 'pointer !important')};
`
export const TertiaryButton = styled.button`
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;

width: ${(props)=>(props.width ? props.width : '130px')};
height: ${(props)=>(props.height ? props.height : '52px')};
color: ${(props)=>(props.isDisabled? 'var(--Gray-100, #B5B5C3)':'var(--Blue-100, #2555EF)')};
background: ${(props)=>(props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : 'transparent')};

:hover{
    background: ${(props)=>(props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : 'rgba(33, 77, 215, 0.05)')};
}
cursor: ${(props)=>(props.isDisabled ? 'not-allowed !important' : 'pointer !important')};

`