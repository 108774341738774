import styled, { css } from "styled-components";

const breakpoints = {
  small: "576px",
  medium: "767px",
  large: "992px",
  xlarge: "1200px",
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
export const CounterContent = styled.div`
  width:${(props)=>(props.width ? props.width :'22%')};
  @media only screen and (min-width: 280px) and (max-width: 767px) {
    width:100%;
  }
  
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CounterValue = styled.div`
  display: flex;
  padding: 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Gray-40, #e1e1e7);
  background: var(--White-100, #fff);
  min-width: 40px;
  height: 40px;
  ${media.medium`
  padding: 0;
  
  `}
  
`;
export const IncrementButton = styled.span`
  margin-left: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background:${(props)=>(props.disabled ? "var(--Gray-100, #B5B5C3)" :'var(--Blues-Blue-Main, #2555EF)')}; ;
  border: 0;
  color: #fff;
  font-weight: bold;
  ${media.medium`
  height: 40px;
  padding: 12px 11px ; `}
`;
export const DecrementButton = styled.span`
  margin-right: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--Blues-Blue-Main, #2555EF);
  border: 0;
  color: #fff;
  font-weight: bold;
  ${media.medium`
  height: 40px;
  padding: 12px 11px  `}
`;
