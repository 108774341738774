import { useAuth0 } from "@auth0/auth0-react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { ReactComponent as PayNowSvg } from "assets/icons/Property.svg";
import sortImg from "assets/icons/sort-able-svg.svg";
import { Loader, MyCheckbox } from "components";
import RightDrawer from "components/drawer";
import MyButton from "components/MyButton";
import { numberFormat } from "helper/numberFormat";
import useGetUserAccount from "hook/useGetUserAccount";
import { debounce } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import FinanceService from "../../Lib/API/Finance";
import FinanceDrawer from "./Drawer";
import ExpandableRow from "./TransationTableComponents/ExpandableRow";

const ChargesTable = ({
  rowsPerPage,
  page,
  setRowsPerPage,
  setPage,
  filters,
  sortData,
  sortCharged,
  list,
  batchTable,
  filter,
  paymentExpandable,
  setPaymentExpandable,
  setBatchList,
  batchList,
  resetChecks,
  setResetchecks,
  viewDocumentHandler,
  downloadFileHandler,
  finance,
}) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectionModel /* setSelectionModel */] = React.useState([]);
  const [drawerData, setDrawerData] = useState({});
  const [currentAccount, setCurrentAccount] = useState(null);
  const [financeCheckIds, setFinanceCheckIds] = useState([]);
  const [check, setCheck] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [productData, setProductData] = useState();
  const [count, setCount] = useState();
  const [checkData, setCheckData] = useState([]);
  const [savetribute, setsavetribute] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    setCheckData([]);
    setCheck([]);
    setFinanceCheckIds([]);
    setCurrentAccount(null);
  }, [batchList]);

  useEffect(() => {
    if (resetChecks == true) {
      setCheckData([]);
      setCheck([]);
      setResetchecks(false);
    }
  }, [resetChecks]);

  useEffect(() => {
    setPage(0);
  }, []);

  // useEffect(() => {
  //   fetchMyAPI();
  // }, [savetribute, batchList]);
  
  const fetchMyAPI = async (e) => {
    setLoading(true);
    const token = await getAccessTokenSilently();

    await FinanceService.getAllTransactions(token, page + 1, 10, filters).then(
      (res) => {
        const result = res?.payload?.data;
        setProductData(result);
        console.log(result?.length, "result?.length");
        setCount(res?.payload?.pagination?.count);
        setLoading(false);
      }
    ).catch((err) =>{
      toastr.error('Error', err?.data?.message);
    })
  };

  useEffect(() => {
    if(!accountData) return;
    accountID = accountData?.user?.permissions?.linked_account?._id;
    if (balance === null) {
      fetchAmountApi()
    }

  }, [accountData,balance])

  const accountID = localStorage.getItem("accountID");

  const accountData = useGetUserAccount({member: true});


  const fetchAmountApi = async (e) => {
    const token = await getAccessTokenSilently();
    setLoading(true);
    await FinanceService.getAmountByAccountId(token, accountID)
      .then((res) => {
        const result = res;
        setLoading(false);

        setBalance(result);
      })
      .catch((err) => {
        toastr.error("Error", err?.data?.message);
      });
  };

  const debounceSearch = useCallback(
    debounce(() => {
      if (filters?.search) {
        fetchMyAPI();
      }
    }, 1000),
    [filters?.search, filters]
  );
  useEffect(() => {
    if (filters?.search) {
      debounceSearch();
    } else {
      fetchMyAPI();
    }
    return () => debounceSearch.cancel();
  }, [filters?.search, filters, page, savetribute, batchList]);
  
  useEffect(() => {
    window.addEventListener("save-transaction", fetchMyAPI);
    return () => {
      window.removeEventListener("save-transaction", fetchMyAPI);
    };
  }, [filters, sortData, page, getAccessTokenSilently]);

  const onPayNowClick = (row) => {
    document.dispatchEvent(new CustomEvent("pay-now", { detail: row }));
  };

  const onReversalClick = async (id, setReversalActionTypes) => {
    const token = await getAccessTokenSilently();
    await FinanceService.reverseCharge(id, token)
      .then(() => {
        toastr.success("Success", "Charge has been reversed");
      })
      .catch((e) => {
        toastr.error(
          "Transaction can not be reversed, please select one of the payments charges record to reverse"
        );
      });
    fetchMyAPI();
    setReversalActionTypes(false);
  };

  const onPayAllClick = (row) => {
    document.dispatchEvent(new CustomEvent("pay-all", { detail: row }));
  };
  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @param {string} accountId
   */

  const onCheckboxChange = (e, row, index, page) => {
    setCheckData((prev) => [...prev, row]);

    const { checked } = e.target;
    if (!checked) {
      setCheck((prev) => {
        const checkClone = prev.filter((item) => item !== row?.charge?._id);
        if (!checkClone.length) {
          setCurrentAccount(null);
        }
        return [...checkClone];
      });

      setCheckData((prev) => {
        const checkClone = prev.filter(
          (item) => item?.charge?._id !== row?.charge?._id
        );
        if (!checkClone.length) {
          setCurrentAccount(null);
        }
        return [...checkClone];
      });
      return;
    }

    if (!currentAccount) setCurrentAccount(row?.Account?.id);
    if (currentAccount === row?.Account?.id || !currentAccount) {
      setCheck((prev) => [...prev, row?.charge?._id]);
      setFinanceCheckIds((prev) => [row?.Account?.id]);
    }
  };

  const modifyRowData = (row, item) => {
    const modified = { ...row, charge: { ...row.charge }, ...item };
    setDrawerData(modified);
    setOpenModal(true);
  };


  const intValue = balance !== null && parseInt(balance?.balance);
  const formatedBalance = numberFormat({number:intValue, decimal: 2, style: "currency"});

  const intValueCred = balance !== null && parseInt(balance?.creditOnAccount);
  const formatedCreditOnAccount = numberFormat({number:intValueCred, decimal: 2, style: "currency"});

  const intValueOver = balance !== null && parseInt(balance?.overDue);
  const formatedOverdue = numberFormat({number:intValueOver, decimal: 2, style: "currency"});

  return (
    <div className="w-100">
      {balance !== null ? (
        <div
          style={{
            border: '1px solid rgb(0 46 92 / 17%)',
            borderRadius: '9px',
            height: 'fit-content',
            padding: '12px 18px',
            width: "fit-content",
            marginBottom: '1rem'
          }}
        >
          <span className="dot" style={{ '--my-color': '#81DC91' }}></span>
          Credit on Account{' '}
          <span className="amountBold mr-3">{formatedCreditOnAccount}</span> |{' '}
          <span className="dot ml-3" style={{ '--my-color': '#2555EF' }}></span>
          Balance <span className="amountBold mr-3">{formatedBalance}</span> |{' '}
          <span className="dot ml-3" style={{ '--my-color': '#F1585E' }}></span>
          Over Due({balance?.numberOfOverDueCharges ?? 0}){' '}
          <span className="amountBold mr-3">{formatedOverdue}</span>
        </div>
      ) : (
        <p>loading...</p>
      )}
      <div className={"family-table-container pl-7 pr-6 pt-3 pb-22"}>
        <div className="home-table-container mt-0 no-box-shadow">
          <>
            <div className="d-flex justify-content-between mb-5 h-40px">
              <div className="yah-sub">Charges</div>
              {checkData?.length > 0 && (
                <MyButton
                  buttonTextStyle={{ margin: 0 }}
                  onClick={() => onPayAllClick(checkData)}
                  content={
                    <>
                      <PayNowSvg /> <span className="">Pay All</span>
                    </>
                  }
                />
              )}
            </div>
          </>

          <TableContainer component={Paper}>
            <Table class="main-table" aria-label="collapsible table">
              <TableHead className="charges-table">
                <TableRow>
                  {batchTable === "batchtable" ? (
                    <></>
                  ) : (
                    <TableCell className="main-check" padding="checkbox">
                      <MyCheckbox name={"visible"} handlechange={() => {}} />
                    </TableCell>
                  )}
                  <TableCell className="table-cell-remove" />
                  <TableCell
                    className="date-head"
                    onClick={() => sortCharged("status")}
                  >
                    STATUS
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className="status-head"
                    align="left"
                    onClick={() => sortCharged("tran_date")}
                  >
                    TRAN.DATE
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className="status-head"
                    align="left"
                    onClick={() => sortCharged("due_date")}
                  >
                    DUE DATE
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  {/* <TableCell
                    className="account-name"
                    align="left"
                    onClick={() => sortCharged("account_name")}
                  >
                    <span>{"Account Name".toUpperCase()}</span>{" "}
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell> */}
                  <TableCell
                    className="charged-value"
                    align="left"
                    onClick={() => sortCharged("charged")}
                  >
                    CHARGED
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => sortCharged("total_paid")}
                    className="paid-head"
                  >
                    PAID
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="paid-head"

                  >
                    DUE
                    <IconButton
                      style={{
                        display: "none",
                      }}
                    >
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className="ledger-head"
                    align="left"
                    onClick={() => sortCharged("ledger")}
                  >
                      LEDGER / SUB LEDGER 
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => sortCharged("transactionId")}
                    className="transaction-head"
                  >
                    TRANSACTION#
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell className="empty-head" align="left" />
                </TableRow>
              </TableHead>

              <TableBody className="tran-table-body">
                {productData?.length === 0 ? (
                  loading == true ? (
                    <div className="tran-loader">
                      <Loader />
                    </div>
                  ) : (
                    <td colSpan={12}>
                      <div className="record-text">No record found</div>
                    </td>
                  )
                ) : productData ? (
                  productData?.map((row, i) => (
                    <ExpandableRow
                      setsavetribute={setsavetribute}
                      modifyRowData={modifyRowData}
                      key={row.name}
                      row={row}
                      index={i}
                      onCheckboxChange={onCheckboxChange}
                      page={page + 1}
                      check={check}
                      onPayNowClick={onPayNowClick}
                      onReversalClick={onReversalClick}
                      setDrawerData={setDrawerData}
                      setOpenModal={setOpenModal}
                      financeCheckIds={financeCheckIds}
                      paymentExpandable={paymentExpandable}
                      setPaymentExpandable={setPaymentExpandable}
                      setBatchList={setBatchList}
                      batchList={batchList}
                      transactionId={checkData.map((item) => {
                        return item?._id?.toString();
                      })}
                      checkData={checkData ?? checkData}
                    />
                  ))
                ) : list?.length > 0 && batchTable === "batchtable" ? (
                  list?.map((row, i) => (
                    <ExpandableRow
                      modifyRowData={modifyRowData}
                      key={row.name}
                      row={row}
                      index={i}
                      onCheckboxChange={onCheckboxChange}
                      page={page}
                      check={check}
                      onPayNowClick={onPayNowClick}
                      onReversalClick={onReversalClick}
                      setDrawerData={setDrawerData}
                      setOpenModal={setOpenModal}
                      batchTable={batchTable}
                      financeCheckIds={financeCheckIds}
                      batchList={batchList}
                      setBatchList={setBatchList}
                      transactionId={checkData.map((item) => {
                        return item?._id?.toString();
                      })}
                      checkData={checkData ?? checkData}
                    />
                  ))
                ) : loading == true ? (
                  <div className="tran-loader">
                    <Loader />
                  </div>
                ) : (
                  <td colSpan={12}>
                    <div className="record-text">No record found</div>
                  </td>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {(productData?.length === 0 && list === 0) ||
          batchTable === "batchtable" ? (
            ""
          ) : (
            <>
              <div className="pagination-custom mt-2">
                <Pagination
                  count={Math.ceil(count / 10)}
                  color="primary"
                  variant="outlined"
                  onChange={(event, value) => {
                    setPage(value - 1);
                  }}
                />
              </div>
            </>
          )}
        </div>
        <RightDrawer
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <FinanceDrawer
            data={drawerData}
            setsavetribute={setsavetribute}
            handleClose={() => setOpenModal(false)}
            onReversalClick={onReversalClick}
            viewDocumentHandler={viewDocumentHandler}
            downloadFileHandler={downloadFileHandler}
          />
        </RightDrawer>
      </div>
    </div>
  );
};

export default memo(ChargesTable);
