import React, { memo } from "react";
import ReduxToastr from "react-redux-toastr";

export const Toast = memo((props) => {
  return (
    <ReduxToastr
      {...props}
      timeOut={6000}
      newestOnTop={false}
      preventDuplicates
      position="top-center"
      getState={(state) => state.toastr}
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
  );
});
