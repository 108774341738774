import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import './MyFilterButton.scss';
const MyFilterButton = ({
  icon,
  type,
  items,
  width,
  onChange,
  disabled,
  value,
  ...props
}) => {
  const [title, setTitle] = useState(props.title);
  return (
    <div className="dropdown__externalDonation">
      <div className="d-flex justify-content-between" style={{width: '157px'}}>
        <div
          className={
            disabled
              ? 'grid-view Dropdown__disabled h-50px w-100'
              : 'grid-view h-50px w-100'
          }
        >
          <Dropdown className="align-self-center">
            <Dropdown.Toggle
              id="dropdown-button-dark-example2"
              variant="red"
              style={{ width: width }}
              disabled={disabled}
            >
              <div className="filter-text" style={{ display: 'contents' }}>
                {`${type ? `${type}:` : ''} ${value || title}`}
                {icon ? icon : <KeyboardArrowDownIcon className='KeyboardArrowDownIcon'/>}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-2 w-100">
              {items.map((item, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setTitle(item.label);
                      onChange(item.value);
                    }}
                  >
                    {item.label}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default MyFilterButton;
