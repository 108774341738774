import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "en",
  isRTl: false,
};

export const localesSlice = createSlice({
  name: "locales",
  initialState,
  reducers: {
    setLang: (state, { payload: { lang } }) => {
      state.lang = lang;
      return state;
    },
  },
});

export const { setLang } = localesSlice.actions;



export default localesSlice.reducer;
