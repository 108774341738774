import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";
import closeIcon from "assets/icons/close.svg";
import FinanceService from "Lib/API/Finance";
import React, { useEffect, useState } from 'react';
import { toastr } from "react-redux-toastr";
import "./index.scss";
const ViewDocumentModal = ({documentId,closeModal}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [documentContent, setDocumentContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const getViewDocumentHandler = async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    FinanceService.viewDocument(
      token,
      documentId
    ).then((response) => {
      setDocumentContent(response?.content)
    }).catch((error) => {
      console.log('error', error)
      toastr.error(error?.data?.message || "Error while getting document");
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getViewDocumentHandler()
  }, [])

  const createMarkup = () => {
    return { __html: documentContent };
  };


  return (
    <div className="view-document">
      <div className="view-document-close"
        onClick={closeModal}
      >
        <img src={closeIcon} alt="" />
      </div>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          <CircularProgress />
        </div>
      ) : (
        <div dangerouslySetInnerHTML={createMarkup()} /> 
      )}
    </div>
  )
}

export default ViewDocumentModal