import { Tooltip } from "@material-ui/core";
import { convertUTCToLocalDate } from 'components/UTC/UtcConverter';
import React from 'react';
import { Details, EventCalendarItemContact, EventCalendarItemContactInfo, EventCalendarItemContactItem, EventCalendarItemContactItemImage, EventCalendarItemContactItemValue, EventCalendarItemContactLinkItem, EventCalendarItemContactLinks, EventCalendarItemContactWrapper, EventCalendarItemContainer, EventCalendarItemCopyBtn, EventCalendarItemCopyBtnIcon, EventCalendarItemDesc, EventCalendarItemEndDate, EventCalendarItemHead, EventCalendarItemHeadBtn, EventCalendarItemHeadLabel, EventCalendarItemHeadLink, EventCalendarItemHeadLinkIcon, EventCalendarItemHeadTop, EventCalendarItemInfo, EventCalendarItemInfoAddress, EventCalendarItemInfoDates, EventCalendarItemInfoDatesItem, EventCalendarItemInfoDatesItemValue, EventCalendarItemInfoLeft, EventCalendarItemInfoRight, EventCalendarItemInfoSeparator, EventCalendarItemInfoType, EventCalendarItemLabel, EventCalendarItemText, Separator } from './Theme';

import CopyIcon from "assets/icons/copy-gray.svg";
import FacebookIcon from "assets/icons/facebook-link.svg";
import InstagramIcon from "assets/icons/instagram-link.svg";
import PreviewIcon from "assets/icons/preview.svg";
import TwitterIcon from "assets/icons/twitter-link.svg";
import { formatDate } from "helper/dateFormat";
import { numberFormat } from "helper/numberFormat";
const EventCalendarItem = ({ event, setCopyLinkSuccess }) => {
  const checkHtmlEmpty = (html) => {
    if (!html) return '';
    var textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    return textarea.value;

  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/events/${event?.id}`
      );
      setCopyLinkSuccess(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  return (
    <EventCalendarItemContainer>
      <EventCalendarItemHead>
        <EventCalendarItemHeadTop>
          <EventCalendarItemHeadLabel>{event?.eventName}</EventCalendarItemHeadLabel>
          <EventCalendarItemHeadBtn to={`/events/${event?.id}`}
            onClick={() =>
              window.location.href = `/events/${event?.id}`
            }
          >
            <EventCalendarItemHeadLinkIcon src={PreviewIcon} alt="Preview" />
            Event Registration
          </EventCalendarItemHeadBtn>
        </EventCalendarItemHeadTop>
        <EventCalendarItemEndDate>Registration ends {event?.alwaysAvailable ? "Always Available" : formatDate({ date: event?.eventEndDate, formatType: "defaultWithUTC" })
        }</EventCalendarItemEndDate>
      </EventCalendarItemHead>
      <Separator />
      <EventCalendarItemInfo>
        <EventCalendarItemInfoLeft>
          <EventCalendarItemInfoType>{event?.eventDetails?.eventType}</EventCalendarItemInfoType>
          <EventCalendarItemLabel>Event type</EventCalendarItemLabel>
        </EventCalendarItemInfoLeft>
        <EventCalendarItemInfoSeparator />
        <EventCalendarItemInfoRight>
          <EventCalendarItemInfoDates>
            <EventCalendarItemInfoDatesItem>
              <EventCalendarItemLabel>Date</EventCalendarItemLabel>
              <EventCalendarItemInfoDatesItemValue>{formatDate({ date: event?.start, formatType: "defaultWithUTC" })}</EventCalendarItemInfoDatesItemValue>
            </EventCalendarItemInfoDatesItem>
            <EventCalendarItemInfoDatesItem>
              <EventCalendarItemLabel>Hour</EventCalendarItemLabel>
              <EventCalendarItemInfoDatesItemValue>
                {formatDate({ date: event?.start, formatType: "timeUTC" })}
              </EventCalendarItemInfoDatesItemValue>
            </EventCalendarItemInfoDatesItem>
          </EventCalendarItemInfoDates>
          <EventCalendarItemInfoAddress>
            <EventCalendarItemLabel>{event?.eventDetails?.eventType?.toLowerCase() === "online" ? "Virtual Address" : "Location"}</EventCalendarItemLabel>
            <EventCalendarItemText>{event?.eventDetails?.eventAddress?.formattedAddress}</EventCalendarItemText>
          </EventCalendarItemInfoAddress>
        </EventCalendarItemInfoRight>
        <Tooltip title="Copy Link">
          <EventCalendarItemCopyBtn onClick={copyToClipboard}>
            <EventCalendarItemCopyBtnIcon src={CopyIcon} alt="Copy" />
          </EventCalendarItemCopyBtn>
        </Tooltip>
      </EventCalendarItemInfo>
      <EventCalendarItemDesc>
        <EventCalendarItemLabel>Description</EventCalendarItemLabel>
        <p
          dangerouslySetInnerHTML={{
            __html: checkHtmlEmpty(event?.description)
          }}
        />
      </EventCalendarItemDesc>
      <EventCalendarItemContact>
        <EventCalendarItemLabel>contact info</EventCalendarItemLabel>
        <EventCalendarItemContactWrapper>
          <EventCalendarItemContactInfo>
            <EventCalendarItemContactItem>
              <EventCalendarItemLabel>Name</EventCalendarItemLabel>
              <EventCalendarItemContactItemValue>{event?.eventDetails?.contactInfo?.firstName} {event?.eventDetails?.contactInfo?.lastName}</EventCalendarItemContactItemValue>
            </EventCalendarItemContactItem>
            <EventCalendarItemContactItem>
              <EventCalendarItemLabel>Email</EventCalendarItemLabel>
              <EventCalendarItemContactItemValue>{event?.eventDetails?.contactInfo?.email}</EventCalendarItemContactItemValue>
            </EventCalendarItemContactItem>
          </EventCalendarItemContactInfo>
          <EventCalendarItemContactLinks>
            {event?.eventDetails?.contactInfo?.facebook && (
              <EventCalendarItemContactLinkItem
                href={event?.eventDetails?.contactInfo?.facebook}
                target='_blank'
              >
                <EventCalendarItemContactItemImage src={FacebookIcon} alt="Facebook" />
              </EventCalendarItemContactLinkItem>
            )}
            {event?.eventDetails?.contactInfo?.instagram && (
              <EventCalendarItemContactLinkItem
                href={event?.eventDetails?.contactInfo?.instagram}
                target='_blank'
              >
                <EventCalendarItemContactItemImage src={InstagramIcon} alt="Instagram" />
              </EventCalendarItemContactLinkItem>
            )}
            {event?.eventDetails?.contactInfo?.twitter && (
              <EventCalendarItemContactLinkItem
                href={event?.eventDetails?.contactInfo?.twitter}
                target='_blank'
              >
                <EventCalendarItemContactItemImage src={TwitterIcon} alt="Twitter" />
              </EventCalendarItemContactLinkItem>
            )}
          </EventCalendarItemContactLinks>
        </EventCalendarItemContactWrapper>
      </EventCalendarItemContact>
      {(!!event?.eventPricing?.prices?.length || !!event?.eventDiscount?.length) && (
        <Details>
          {event?.eventPricing?.prices?.map((item, index) => (item?.type === "onRegistration" || item?.type === "optional") && (
            <>
              <li key={index}>{item?.title} {item?.type === "optional" && "(Optional)"

              }<span>{numberFormat({number:+item.amount, decimal:2, style: "currency"})}</span></li>
              {index < event.eventPricing.prices.length - 1 && <hr />}
            </>
          ))}

          {event?.eventDiscount?.map((item, index) => (
            <>
              <li key={index}>{item?.discount_name} |
                {`${formatDate({ date: convertUTCToLocalDate(item?.start_date), formatType: "defaultWithUTC" })}
                -${formatDate({ date: convertUTCToLocalDate(item?.end_date), formatType: "defaultWithUTC" })}`}
                <span>
                  {item?.price_type === 'percentage' ? `${item?.value}%` : `${numberFormat({number:+item?.value, decimal:2, style: "currency"})}`}
                </span></li>
              <hr />
            </>

          ))}
        </Details>
      )}

    </EventCalendarItemContainer>
  )
}

export default EventCalendarItem