import styled from "styled-components";

export const Wrapper = styled.div`
    
`
export const ProductContent = styled.div`
    display: flex;
    @media only screen and (min-width: 280px) and (max-width: 767px) {
        flex-direction: column;
  }
    
`
export const FirstDiv = styled.div`
min-height: 50px;
padding-top: 15px;
width: ${(props) => (props.totalProducts ? '70%' : '50%')}; ;
@media only screen and (min-width: 280px) and (max-width: 767px) {
    width: 100%;
  }
`;

export const SecondDiv = styled.div`
flex-grow: 1;
padding: 10px;
margin-left: -80px;
display: flex;
justify-content: flex-start;
align-items: center;
/* padding-top:10px; */
`;

export const FlexItemsContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
margin-left: 20px;
`;

export const FlexItem = styled.div`
flex: 1;
margin-right: 10px; 
`;

export const Amount =styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
margin-top: 6px;
`
export const Title = styled.span`
    margin-bottom: 8px;
    display: block;
    width: 76%;
`
export const AmountField = styled.div`
    position: relative;
    /* width: 150px; */
    /* margin-top: 8px; */
    label {
        margin: 0;
    }
    input {
        font-size: 20px !important;
    }
`;
export const AmountSign = styled.span`
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
`;
export const SelectWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    gap: 10px;

`
export const VarientAmount  = styled.div`
    width: 50px;
`