import React, { useEffect, useState } from "react";
import { LabelField, InputField } from "components";
import { useRef } from "react";
export const Cash = () => {
  return null;
};
export const CashHeader = ({ formik, ...props }) => {
  const inputRef = useRef();
  const [state, setState] = useState(false);
  const { expanded, current, index } = props;
  const preventMinus = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;

  const handlefieldClick = (e) => {
    if (e.stopPropagation && expanded === true) {
      e.stopPropagation();
    }
  };
  const handleOnChange = (event) => {
    formik.setFieldValue("cash_payment", event.target.value.match(regex)[0]);
  };
  const getCommaSeperatedValue = (num) => {
    return parseFloat(num?.toString().replace(/,/g, ""))
      .toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div
      className={
        expanded === false && current?.includes("cash") === true
          ? "accordion-header"
          : expanded === false
          ? "accordion-payment-header"
          : expanded === true
          ? "accordion-header"
          : "accordion-header-payment"
      }
    >
      <LabelField title="Cash" />
      <div
        className={
          expanded === false && current?.includes("cash")
            ? "custom-expand dollor-icon col-sm-3 nonexpanded-amount"
            : expanded === false &&
              current?.includes("cash") === false &&
              formik?.values?.cash_payment
            ? "custom-expand dollor-icon col-sm-3 nonexpanded"
            : "col-sm-3"
        }
      >
        {expanded === false &&
        current?.includes("cash") &&
        formik?.values?.cash_payment ? (
          <div className="cash-non-expanded">
            <span>$</span>
            <span>{getCommaSeperatedValue(formik.values?.cash_payment)}</span>
          </div>
        ) : current?.includes("cash") && expanded === true ? (
          <InputField
            {...{
              formik,
              title: "none",
              name: `val`,
              style: { background: "#ffff", border: "none" },
              value: formik.values?.cash_payment,
              placeholderIcon: (
                <span
                  className="align-self-center px-4"
                  style={{ color: "black" }}
                >
                  $
                </span>
              ),
            }}
            paymentInput="paymentInput"
            // onKeyDown={preventMinus}
            handleFocus={(e) => handlefieldClick(e)}
            handleChange={(event) => handleOnChange(event)}
          />
        ) : expanded === false &&
          current?.includes("cash") === false &&
          formik?.values?.cash_payment ? (
          <div className="cash-non-expanded">
            <span>$</span>
            <span>{getCommaSeperatedValue(formik.values?.cash_payment)}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
