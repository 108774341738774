import Box from '@mui/material/Box';
import AuthService from 'Lib/API/Auth';
import MemberService from 'Lib/API/Member';
import { getLocalStorageRegistration, getLocalStorageValidateRequiredFelids, setLocalStorageRegistration } from '_metronic/_helpers';
import cuid from 'cuid';
import { useFormik } from 'formik';
import { setLocalizationMoment } from 'helper/dateFormat';
import { useSnackbar } from 'notistack';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import ClassInfo from './ClassInfo/ClassInfo';
import Header from './Header/Header';
import MemberStepper from './PayComponents/MemberStepper';
import PaymentDetails from './PayComponents/PaymentDetails/PaymentDetails';
import PersonalInfo from './PayComponents/PersonalInfo/PersonalInfo';
import RegistrationForm from './PayComponents/RegistrationForm/RegistrationForm';
import Thanks from './PayComponents/ThanksPage/Thanks';
import { Body, ButtonNext, ButtonPrev, Contant, Controlers, RightSide, StepperContent, StepperWrapper, Title, Wrapper } from './Theme';


const MemberLink = () => {
    const {provider_information} = localStorage.getItem("organization")
    ? JSON.parse(localStorage.getItem("organization"))
    : {};
    const {provider} = provider_information || "";
    const {publicKey} = provider_information?.keys || "";


    const addCardStripeRef = useRef(null);

    const { enqueueSnackbar } = useSnackbar();
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(false)
    const pathName = window.location.pathname
    const [completed, setCompleted] = React.useState({});
    const { push } = useHistory();
    const params = useParams();
    const [FileId, setFieldId] = useState()
    const { classDetails } = useSelector((state) => state?.Class)
    const isReadyToPay = classDetails?.class_pricing?.total_to_pay > 0 ? true : false
    const [formData, setFormData] = useState(new FormData());
    const [selectedFiles, setSelectedFiles] = useState([]);

    // two flow onw if we have payment to pay other one if we don't have
    const steps = isReadyToPay ? ['Registration', 'Enter Details', 'Pay'] : ['Registration', 'Enter Details'];
    const formik = useFormik({
        isInitialValid: false,
        enableReinitialize: true,
        initialValues: {
            isMember: token ? true : false,
            accountInfo: {
                addresses: [
                    {
                        location: {}
                    }
                ]
            },
            registrationForm: [
                {
                    formData: []
                }
            ],

            cardsDetails: {

            }
        },
        validationSchema: '',
        onSubmit: async (values) => { },
    });

    const handleDataForTwoFlow = (tokenId = "") => {
        formData.append(`isMember`, false);
        formData.append(`orgId`, localStorage.getItem('orgId'));
        formData.append(`classId`, params?.id);

        // Registration Form formData
        const dataArray = 'registrationForm[0]'
        formData.append(`${dataArray}[id]`, cuid());
        const registrationForm = formik?.values?.registrationForm[0]?.formData
        // Separate files from formData
        registrationForm.forEach((dataItem)=>{
        if (dataItem?.name?.includes("file_upload")) {
            if (dataItem?.value && dataItem?.value !== null) {
                formData.append(
                `registrationForm[0][files][${formData.getAll('registrationForm[0][id]')}]`,
                dataItem?.value
                );
            }
            }
        })
        const withoutFiles = formik?.values?.registrationForm[0]?.formData?.filter((val)=>!val.name.includes("file_upload"))
        formData.append(`registrationForm[0][formData]`,JSON.stringify([...withoutFiles]));
        if (isReadyToPay) {
            if(tokenId) {
                formData.append(`token`, tokenId);
            } else {
                // Cards Details
                const objectName = 'cardsDetails';
                const objectData = formik?.values?.cardsDetails
                for (const key in objectData) {
                    if (key === 'expiry') {
                        formData.append(`${objectName}[exp_month]`, objectData[key]?.split('/')[0]);
                        formData.append(`${objectName}[exp_year]`, objectData[key]?.split('/')[1]);

                    } else if (key === 'card_number') {
                        formData.append(`${objectName}[number]`, objectData[key]);
                    } else {
                        formData.append(`${objectName}[${key}]`, objectData[key]);
                    }
                }
            }
        }
        // Account Info
        const objectName2 = 'accountInfo';
        const objectData2 = formik?.values?.accountInfo
        for (const key in objectData2) {
            if (key === 'addresses') {
                const objeLocation = formik.values?.accountInfo?.addresses[0].location
                if (Object.keys(objeLocation).length !== 0) {
                    formik.values?.accountInfo?.addresses[0].location?.formattedAddress && formData.append(`${objectName2}[addresses][0][location][formattedAddress]`, formik.values?.accountInfo?.addresses[0].location?.formattedAddress);
                    formik.values?.accountInfo?.addresses[0].location?.place_id && formData.append(`${objectName2}[addresses][0][location][place_id]`, formik.values?.accountInfo?.addresses[0].location?.place_id);
                    formik.values?.accountInfo?.addresses[0]?.city && formData.append(`${objectName2}[addresses][0][city]`, formik.values?.accountInfo?.addresses[0]?.city);
                    formik.values?.accountInfo?.addresses[0]?.state && formData.append(`${objectName2}[addresses][0][state]`, formik.values?.accountInfo?.addresses[0]?.state);
                    formik.values?.accountInfo?.addresses[0]?.zip_code && formData.append(`${objectName2}[addresses][0][zip_code]`, formik.values?.accountInfo?.addresses[0]?.zip_code);
                    formik.values?.accountInfo?.addresses[0]?.addressLine2 && formData.append(`${objectName2}[addresses][0][addressLine2]`, formik.values?.accountInfo?.addresses[0]?.addressLine2);
                }
            } else {
                formData.append(`${objectName2}[${key}]`, objectData2[key]);
            }
        }
    }
    const handleClearForm = () => {
        setFormData(new FormData());
    };
    const generateTokenAndPay = async (tokenId = "") => {
        setIsLoading(true)
        const subDomain = window.location.hostname.split('.')[0];
        const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
        try {
            const res = await AuthService.generateToken(hostName);
            localStorage.setItem('externalToken', res?.token);
            localStorage.setItem('orgId', res?._id);
            localStorage.setItem('logoImgSchool', res?.logoImg);
            localStorage.setItem('orgName', res?.orgName);
            localStorage.setItem('country', JSON.stringify(res?.country || {}));

            setLocalizationMoment(res?.country?.local || "en");
            document.title = `${res?.orgName}`;
            PaymentForm(res?.token,tokenId)
        } catch (error) {
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
        }
    };
    const PaymentForm = async (externalToken,tokenId = "") => {
        handleDataForTwoFlow(tokenId)
        await MemberService.CreateParticipant(formData, externalToken)
            .then((res) => {

                formik.resetForm();
                enqueueSnackbar('Registration is Done Successfully!', {
                    variant: 'success',
                });
                setLocalStorageRegistration({
                    activeStep: getLocalStorageRegistration()?.activeStep + 1,
                    registerFormId: getLocalStorageRegistration()?.registerFormId,
                    RegisterData: getLocalStorageRegistration()?.RegisterData,
                    RegisterRequiredFelids: getLocalStorageRegistration()?.RegisterRequiredFelids,
                    classDetails: getLocalStorageRegistration()?.classDetails
                });
                handleClearForm()
                setIsLoading(false)
                if (!isReadyToPay || getLocalStorageRegistration()?.activeStep === 3) push(`/school/${params?.id}/thanks`);
            })
            .catch((err) => {
                handleClearForm()
                if (err === 'Network Error') {
                    enqueueSnackbar("Data is too large , please choose less files", { variant: 'error' });
                } else {
                    enqueueSnackbar(err?.data?.message, { variant: 'error' });
                }
                setIsLoading(false)
            });
    }


    const handleNext = useCallback((tokenId = "") => {

        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (getLocalStorageRegistration()?.activeStep + 1 === 1) {
            //validation fields
            const originalInputFields =  getLocalStorageRegistration()?.RegisterData;
            console.log('requiredFields', originalInputFields)
            const dataValidation = formik?.values?.registrationForm[0]?.formData?.map((subItem,subItemIndex) => {
                const requireds = originalInputFields?.find(
                    (tofinditem) => (tofinditem.id === subItem.id) && tofinditem.required
                  );

                  if(subItem.value && subItem?.value?.length >0 ){
                    console.log('subItem.value && subItem?.value?.length >0', subItem.value )
                    if(subItem?.name.includes('email_input')) {
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      return {errorMessage: emailRegex.test(subItem.value) ? "" : `Please enter valied email for (${originalInputFields[subItemIndex]?.label}) field`}
                    }
                    if(subItem?.name.includes('date_picker')) {
                        const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
                        return {errorMessage: dateRegex.test(subItem.value) ? "" : `Please enter valied date for (${originalInputFields[subItemIndex]?.label}) field`}
                      }
                    if (subItem?.name.includes('phone_input')) { 
                      const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; 
                      return {errorMessage: phoneRegex.test(subItem.value) ? "" : `Please enter valied Phone Number for (${originalInputFields[subItemIndex]?.label}) field`}
                    } else {
                      return {errorMessage: ""}
                    }
                }
                if (requireds !== undefined) {
                    if(subItem.value){
                        return {errorMessage:""} 
                    }else{
                        return {errorMessage:`The ${requireds.label} is required`}
                    }
                    
                  }else{
                    return {errorMessage:""}
                  }
            }).filter((element)=>element.errorMessage !== "")
            formik.setFieldValue("errors",dataValidation)
            const allErrorsEmpty = dataValidation.length !== 0

            if(formik?.values?.registrationForm[0]?.formData?.length === 0){
                enqueueSnackbar("Please fill all mandatory fields", { variant: "error" });
                    return;
            }else if (allErrorsEmpty){
                enqueueSnackbar("Please check the validation errors", { variant: "error" });
                return;
            } 
            else {
                    setLocalStorageRegistration({
                        activeStep: getLocalStorageRegistration()?.activeStep + 1,
                        registerFormId: getLocalStorageRegistration()?.registerFormId,
                        RegisterData: getLocalStorageRegistration()?.RegisterData,
                        RegisterRequiredFelids: getLocalStorageRegistration()?.RegisterRequiredFelids,
                        classDetails: getLocalStorageRegistration()?.classDetails
                    });
                    push(`/school/${params?.id}/enter-details`);
            }
        } else if (getLocalStorageRegistration()?.activeStep + 1 === 2) {

            if (
                localStorage.getItem("externalToken") &&
                (!formik?.values?.accountInfo.first_name ||
                    !formik?.values?.accountInfo.last_name ||
                    !formik?.values?.accountInfo.primary_email_address)
            ) {
                enqueueSnackbar("Please fill all mandatory fields", { variant: "error" });
                return;
            } else {
                if (reg.test(formik?.values?.accountInfo.primary_email_address) === false) {
                    enqueueSnackbar("Please enter valied email", { variant: "error" });
                    return;
                } else {
                    if (isReadyToPay) {
                        setLocalStorageRegistration({
                            activeStep: getLocalStorageRegistration()?.activeStep + 1,
                            registerFormId: getLocalStorageRegistration()?.registerFormId,
                            RegisterData: getLocalStorageRegistration()?.RegisterData,
                            RegisterRequiredFelids: getLocalStorageRegistration()?.RegisterRequiredFelids,
                            classDetails: getLocalStorageRegistration()?.classDetails
                        });
                        push(`/school/${params?.id}/pay`)
                    } else {
                        generateTokenAndPay()
                    }

                }
            }

        }
        else if (getLocalStorageRegistration()?.activeStep + 1 === 3) {
            if (
                !checkIsStripe() &&
               ( localStorage.getItem("externalToken") &&
                (!formik?.values?.cardsDetails.card_number ||
                    !formik.values?.cardsDetails.expiry ||
                    !formik.values?.cardsDetails.cvc))
            ) {
                enqueueSnackbar("Please fill all mandatory fields", { variant: "error" });
                return;
            } else {
                if (formik?.values?.cardsDetails?.card_number?.length < 16) {
                    enqueueSnackbar("the curd number must contain 16 number", { variant: "error" });
                }
                else if (formik.values?.cardsDetails?.cvc?.length < 3) {
                    enqueueSnackbar("The CVC should be 3 or 4 digits", { variant: "error" });
                    return;
                } else {
                    handlePayment()
                }
            }

        }
    },[formik.values])

    const handleBack = () => {

        if (getLocalStorageRegistration()?.activeStep - 1 === 0) {
            push(`/school/${params?.id}/registration`);
        }
        else if (getLocalStorageRegistration()?.activeStep - 1 === 1) {
            push(`/school/${params?.id}/enter-details`);
        }

        setLocalStorageRegistration({
            activeStep: getLocalStorageRegistration()?.activeStep - 1,
            registerFormId: getLocalStorageRegistration()?.registerFormId,
            RegisterData: getLocalStorageRegistration()?.RegisterData,
            RegisterRequiredFelids: getLocalStorageRegistration()?.RegisterRequiredFelids,
            classDetails: getLocalStorageRegistration()?.classDetails
        });

    };

    const handleStep = (step) => () => {
        setLocalStorageRegistration({
            activeStep: step,
            registerFormId: getLocalStorageRegistration()?.registerFormId,
            RegisterData: getLocalStorageRegistration()?.RegisterData,
            RegisterRequiredFelids: getLocalStorageRegistration()?.RegisterRequiredFelids,
            classDetails: getLocalStorageRegistration()?.classDetails
        });
    };


    const checkIsStripe = () => {
        return provider === "Stripe" && publicKey;
    }

    const handleGetStripeToken = async () => {
        const tokenId = await addCardStripeRef?.current?.handleGetToken();
        if(tokenId && tokenId !== null) {
            generateTokenAndPay(tokenId)
        }
    }
    
    const handlePayment = async () => {
        if(checkIsStripe()) {
            handleGetStripeToken()
            return
        }
        generateTokenAndPay()
    }

    const renderSwitch = () => {
        switch (getLocalStorageRegistration()?.activeStep) {
            case 0:
                return <RegistrationForm formik={formik} />;
            case 1 || window.location.pathname?.split('/')?.[3] === 'enter-details':
                return <PersonalInfo formik={formik} />;
            case 2 || window.location.pathname?.split('/')?.[3] === 'pay':
                return <PaymentDetails formik={formik} isLoading={isLoading} setIsLoading={setIsLoading} addCardStripeRef={addCardStripeRef}
                    checkIsStripe={checkIsStripe}
                />;
            case 3:
                return <Thanks formik={formik} />;
            default:
                break;
        }
    }


    return (
        <Body startedSteps={pathName !== '/school'}>
            <Header />
            <Wrapper>
                <Box sx={{ width: '100%' }}>
                    <StepperWrapper>
                        <Title>{classDetails?.class_name}  |  {classDetails?.year}</Title>
                        <StepperContent>
                            <MemberStepper activeStep={getLocalStorageRegistration()?.activeStep} steps={steps} completed={completed} handleStep={handleStep} />
                        </StepperContent>
                        <Controlers >
                            {getLocalStorageRegistration()?.activeStep !== 0 &&
                                <ButtonPrev
                                    size="medium"
                                    disabled={getLocalStorageRegistration()?.activeStep === 0}
                                    onClick={handleBack}
                                >
                                    Previous
                                </ButtonPrev>
                            }
                            <ButtonNext onClick={handleNext} disabled={isLoading}>
                                {getLocalStorageRegistration()?.activeStep === 2 ?
                                    isLoading ? <PulseLoader color={'#fff'} size={'7px'} /> : 'Pay'
                                    : !isReadyToPay && getLocalStorageRegistration()?.activeStep === 1 ? isLoading ? <PulseLoader color={'#fff'} size={'7px'} /> : 'Done' : 'Continue'}
                            </ButtonNext>

                        </Controlers>
                    </StepperWrapper>
                    <div>
                        <Contant>
                            {renderSwitch()}
                            <RightSide startedSteps={pathName !== '/school'}>
                                <ClassInfo steps={true} />
                            </RightSide>
                        </Contant>
                    </div>

                </Box >
            </Wrapper >
        </Body >

    )
};

export default MemberLink;