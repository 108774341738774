import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Avatar } from "@mui/material";
import { Text, HintField } from "components";
import { Button } from "components/NewButton/Button";
import React, { useState } from "react";
import { useMobileView } from "hook/mobileView/useMobileView"; 
const MournersList = ({
  drawerDisplay,
  handleClick,
  mourners,
  handleDelete,
  setDisable,
  isEdit,
  mourner,
  ...props
}) => {
  const [error, setError] = useState("");
  const formik = { ...props };
  const { isMobile } = useMobileView()

  return (
    <div
      className={!drawerDisplay ? "mourners-list" : "drawer-mourner-list px-8"}
    >
      {!drawerDisplay && <Text className="sub-title">Mourners list</Text>}
      <div className="list-contant">
        {mourners?.length > 0 &&
          mourners?.map((mourner, index) => {
            return (
              <div className="member-card" key={index}>
                <div className="card-content">
                  <div className="member-container">
                    <div className="member-img">
                      <Avatar
                        className="avatar"
                        style={{
                          background:
                            mourner?.member_id || mourner?._id
                              ? "#4CAF50"
                              : "#8E8E8E",
                          height: "40px",
                          borderRadius: "6px",
                          width: "40px",
                        }}
                        alt={`${mourner.first_name}`}
                        src={
                          mourner?.profile_img && mourner?.profile_img !== ""
                            ? mourner?.profile_img
                            : "/example.jpg"
                        }
                      ></Avatar>
                    </div>
                    <div className="member-name">
                      {mourner?.member_id || mourner?._id
                        ? `${mourner?.first_name} ${mourner?.last_name}`
                        : mourner?.email}
                      <div className="member-t">{`${mourner?.relation?.name ??
                        mourner?.relation}`}</div>
                    </div>
                  </div>
                  <div
                    className={`cursor-pointer ${
                      drawerDisplay ? "mr-10" : "mr-3"
                    }`}
                  >
                    <DeleteForeverIcon
                      fontSize="large"
                      onClick={() => {
                        setDisable(false);
                        handleDelete(index);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {!drawerDisplay && (
        <div className="py-10" style={{ marginLeft: 20 }}>
          {!mourners?.length > 0 ? <HintField error={error} /> : null}
          <Button
            width={isMobile? "150px":"320px"}
            height="40px"
            style={{ border: 0 }}
            children="Continue"
            onClick={() => {
              if (mourners?.length > 0 || formik?.values?.doesnt_have_mourner)
                handleClick();
              else setError("Add 1 mourner at least to continue");
            }}
            type="Primary"
          />
        </div>
      )}
    </div>
  );
};

export default MournersList;
