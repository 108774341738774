import Paper from "@mui/material/Paper";
import { convertUTCToLocalDate } from "components/UTC/UtcConverter";
import * as calculation from "helper/CalculateEvent";
import { formatDate } from "helper/dateFormat";
import { numberFormat } from "helper/numberFormat";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventsSlice } from "services/api/events";
import { DataFoundWrapper, Discount, PayDetails, PaymentAmount, SummaryItem, SummaryTitle, TotalPayment, Wrapper } from "./Theme";

export const SummaryCard = ({ handleTotalForPay, convenienceFee, totalPaymentWithCVFee, billMyAccount }) => {
	const { products, formOnlineData, varients, numberOfTickets, totalProducts,ticketTypeVariants } = useSelector((state) => state?.Events);
	const paymentMethod = JSON.parse(localStorage.getItem("paymentMethodEvents"));
	const { eventData } = useSelector((state) => state?.Events);
	const totalPay = handleTotalForPay();
	const totalPayforAllTickets = eventData?.eventPricing?.total_to_pay * numberOfTickets.length;

	const dispatch = useDispatch();
	const [newProducts, setProducts] = useState([]);

	useEffect(() => {
		// Process the input data and update the products state
		const processedData = processInputData(varients);
		const totalAmount = processedData?.reduce((sum, obj) => sum + obj.amount, 0);
		const finalAmount = Number.isNaN(totalAmount) ? 0 : totalAmount;
		dispatch(eventsSlice.actions.setTotalProducts(finalAmount));
		setProducts(processedData);
	}, [dispatch, varients]);

	const processInputData = (data) => {
		const processedProducts =
			data && Object.values(data)?.length > 0
				? Object.values(data)?.map((item, index) => {
						if (item.variantsInfo && item.variantsInfo.length > 0) {
							const totalCounterSum = item.variantsInfo.reduce((sum, obj) => sum + obj.counterValue, 0);
							let totalAmount = 0;
							if (item.variantsInfo && item.variantsInfo.length > 0) {
								item.variantsInfo.forEach((variant) => {
									totalAmount += variant.counterValue * variant.amount;
								});
								return {
									title: item.varientTitle,
									total: totalCounterSum,
									amount: totalAmount,
									type: item.type,
								};
							}
						} else {
							if (item.type === "openAmount") {
								return {
									title: item.varientTitle,
									total: item.counterValue || 0,
									amount: item.price ? item.price * item.counterValue : 0,
									type: item.type,
								};
							} else {
								return {
									title: item.varientTitle,
									total: item.counterValue || 0,
									amount: item.amount * item.counterValue,
									type: item.type,
								};
							}
						}
				  })
				: [];
		return processedProducts;
	};

	return (
		<>
			{(totalPay?.total !== "0.00" || totalProducts > 0) && (
				<Paper
					sx={{
						minHeight: "278px",
						borderRadius: "10px",
						backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#1A2027" : "#fff"),
					}}
				>
					<Wrapper>
						<SummaryTitle>
							<span>Summary</span>
						</SummaryTitle>
						<DataFoundWrapper>
							{(!eventData?.freeEvent && !!ticketTypeVariants?.length) && ticketTypeVariants?.map((item, index) => item?.selectedQuantity > 0 && (
									<SummaryItem>
										<div>
											<p>{item?.selectedQuantity} {item?.name}</p>
										</div>
										<PaymentAmount>{`${numberFormat({number:(+item?.amount * +item?.selectedQuantity), decimal: 2, style: "currency"})}`}</PaymentAmount>
									</SummaryItem>									
							))}
							{newProducts.map((product, index) => (
								<>
									{product?.total > 0 && product?.amount > 0 && (
										<SummaryItem>
											<div>
												<p>
													{product?.total} {product?.title}
												</p>
											</div>
											{`${numberFormat({number:product?.amount, decimal: 2, style: "currency"})}`}
										</SummaryItem>
									)}
								</>
							))}
              {totalPay?.totalcountTickets > "0.00" && (
                <PayDetails>
                    {eventData?.eventDiscount?.map((item, index) => (
                      <>
                        <li key={index}>
                          {item?.discount_name}
                          {item?.discount_type === "date" &&
                            ` | ${formatDate({date:item?.start_date, formatType: "defaultWithUTC"})} -${formatDate({date:item?.end_date, formatType: "defaultWithUTC"})}`}
                          <span>
                            {item?.price_type === "percentage"
                              ? `${item?.value}%`
                              : `${numberFormat({number:+item?.value, decimal: 2, style: "currency"})}`}
                          </span>
                        </li>
                      </>
                    ))}
                  </PayDetails>
              )}
							{(paymentMethod?.coverConvenienceFee && convenienceFee > 0 && !billMyAccount) && (
								<>
									<SummaryItem>
										<div>
											<span style={{ color: "#002E5C", fontSize: "14px" }}>Convenience fee</span>
										</div>
										<PaymentAmount>{numberFormat({number:+convenienceFee, decimal: 2, style: "currency"})}</PaymentAmount>
									</SummaryItem>
								</>
							)}
							<TotalPayment>
								<p>Payment Total</p>
								<span>{numberFormat({number:+totalPaymentWithCVFee, decimal: 2, style: "currency"})}</span>
							</TotalPayment>
						</DataFoundWrapper>
					</Wrapper>
				</Paper>
			)}
		</>
	);
};
