import { MyCheckbox } from "components";
import { TextareaField } from "components/textareaField";
import { getCurrency, numberFormat } from "helper/numberFormat";
import React from "react";
import { CheckFloatValue } from "../../../utils/functions";
const Review = ({ ...props }) => {
  const formik = props;
  const currency = getCurrency();
  const sumOfDiscounts = formik?.values?.allData?.reduce((a, b) => {
    if (b.discount_type === "%" && b.discount) {
      return a + (b?.charge?.amount * b.discount) / 100;
    }
    if (b.discount_type === "$" && b.discount) {
      return a + b.discount;
    } else {
      return 0;
    }
  }, 0);
  const sumOfAmounts = formik.values?.allData?.reduce((a, b) => {
    return a + parseFloat(b?.charge?.amount);
  }, 0);
  const acutalTotal = parseFloat(formik?.values?.actual_total);

  const currentTotal = parseFloat(formik?.values?.current_total);

  const splittedAmount = parseFloat(formik?.values?.split_amount);

  const splitAmount = parseFloat(formik?.values?.values?.[0].split_amount);

  const sumAmounts = parseFloat(sumOfAmounts);

  const TotalSum = sumAmounts;
  const currentAmount = parseFloat(formik?.values?.current_amount);

  // const sumOfTaxes = formik?.values?.allData?.reduce((a, b) => {
  //   if (b.tax_type === "%" && b.tax_deductible) {
  //     return a + (b?.charge?.amount * b.tax_deductible) / 100;
  //   }
  //   if (b.tax_type === "$" && b.tax_deductible) {
  //     return a + b.tax_deductible;
  //   } else {
  //     return 0;
  //   }
  // }, 0);

  const sumOfTotalAmounts = TotalSum - sumOfDiscounts;
  // ?.toString()
  // .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const methods = Object.entries(formik?.values)
    .filter(([key]) => key.includes("_payment"))
    .map(([key, value]) => {
      const split = key.split("_payment");
      if (split[0] === "check" && !value.check_number) {
        return undefined;
      }
      if (split[0].includes("credit")) {
        return {
          ...value,
          paymentMethod: split[0],
          amount: formik?.values?.creditCardAmount,
          last4: formik?.values?.last4 /* cvc: values.cvc */,
        };
      }
      if (split[0].includes("ach")) {
        return {
          ...value,
          paymentMethod: split[0].toUpperCase(),
          amount: formik?.values?.ach,
          achId: formik?.values?.payments[0]?.ACH /* cvc: values.cvc */,
        };
      }
      if (split[0].includes("cash")) {
        return {
          ...value,
          paymentMethod: split[0] === "cash" ? "Cash" : "",
          amount: formik?.values?.cash_payment,
        };
      }
      if (split[0].includes("onAccountCredit")) {
        return {
          ...value,
          paymentMethod:
            split[0] === "onAccountCredit" ? "Credit On Account" : "",
          amount: formik?.values?.onAccountCredit_payment,
        };
      }
      return {
        paymentMethod: split[0],
        amount: formik?.values?.[key],
      };
    })
    .filter((item) => item !== undefined);


  const totalSplittedAmount =
    formik?.values?.allData &&
    formik?.values?.allData?.map((item) => item?.charge?.amount);
  const sumOfSplittedAmount = totalSplittedAmount?.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  return (
    <div className="review">
      <div className="review-row">
        <div>Amount</div>
        <div>
          {formik.values?.amount_due === 0 || formik.values?.amount_due
            ? numberFormat({number:(formik.values?.amount_due), decimal: 2, style: "currency"})
            : formik?.values?.allData?.[0]?.charge?.amount
            ? numberFormat({number:(sumOfSplittedAmount), decimal: 2, style: "currency"})
            : formik?.values?.values?.[0]?.split_amount
            ? numberFormat({number:(
                formik?.values?.values?.[0]?.split_amount
              ), decimal: 2, style: "currency"})
            : formik.values?.current_total
            ? numberFormat({number:(formik.values?.current_total), decimal: 2, style: "currency"})
            : formik?.values?.values?.[0].is_splitted === false
            ? numberFormat({number:(acutalTotal ? acutalTotal : TotalSum), decimal: 2, style: "currency"})
            : splittedAmount && splitAmount
            ? numberFormat({number:(splittedAmount ? splittedAmount : TotalSum), decimal: 2, style: "currency"})
            : splitAmount
            ? numberFormat({number:(splitAmount ? splitAmount : TotalSum), decimal: 2, style: "currency"})
            : splittedAmount
            ? numberFormat({number:(splittedAmount ? splittedAmount : TotalSum),  decimal: 2, style: "currency"})
            : numberFormat({number:(currentTotal ?? TotalSum), decimal: 2, style: "currency"})}
        </div>
      </div>
      {!formik?.values?.values?.[0].split_amount ? (
        <div className="review-row mt-3">
          <div>Discount</div>
          <div>
            {formik?.values?.values?.[0]?.discount_type === "$" ? {currency} : ""}
            {formik?.values?.values?.[0]?.discount
              ? formik?.values?.values?.[0]?.discount
              : sumOfDiscounts
              ? sumOfDiscounts
              : 0}
            {formik?.values?.values?.[0]?.discount_type === "%" ? "%" : ""}
          </div>
        </div>
      ) : null}
      <div className="review-row mt-3">
        <div>Convenience Fee</div>
        <div>
          {numberFormat({number:formik?.values?.convenient_fee, decimal: 2, style: "currency"})}
        </div>
      </div>
      <hr className="hr-div" />
      <div className="review-row mt-3">
        <div className="subtotal">Subtotal</div>
        <div className="subtotal">
          {formik.values?.amount_due === 0 || formik.values?.amount_due
            ? numberFormat({number:parseFloat(formik.values?.amount_due), decimal: 2, style: "currency"})
            : formik?.values?.values?.[0].is_splitted === false
            ? numberFormat({number:(
                sumOfTotalAmounts
                  ? sumOfTotalAmounts
                  : currentTotal
                  ? currentTotal
                  : currentAmount
              ), decimal: 2, style: "currency"})
            : splittedAmount && splitAmount
            ? numberFormat({number:(splittedAmount ?? sumOfTotalAmounts), decimal: 2, style: "currency"})
            : splitAmount ?? sumOfTotalAmounts
            ? numberFormat({number:(splitAmount ?? sumOfTotalAmounts), decimal: 2, style: "currency"})
            : splittedAmount
            ? numberFormat({number:(splittedAmount ?? sumOfTotalAmounts), decimal: 2, style: "currency"})
            : currentTotal ?? sumOfTotalAmounts
            ? numberFormat({number:(currentTotal ?? sumOfTotalAmounts), decimal: 2, style: "currency"})
            : numberFormat({number:(acutalTotal ?? sumOfTotalAmounts), decimal: 2, style: "currency"})}
        </div>
      </div>
      <div className="review-row mt-6">
        <div>Total Due Today</div>
        <div>
          {formik.values?.amount_due === 0 || formik.values?.amount_due
            ? numberFormat({number:(formik.values?.amount_due), decimal: 2, style: "currency"})
            : formik?.values?.allData?.[0]?.charge?.amount
            ? numberFormat({number:(sumOfSplittedAmount), decimal: 2, style: "currency"})
            : formik?.values?.values?.[0]?.split_amount
            ? numberFormat({number:(formik?.values?.values?.[0]?.split_amount), decimal: 2, style: "currency"})
            : formik.values?.amount_due === 0 || formik.values?.amount_due
            ? numberFormat({number:(parseFloat(formik.values?.amount_due)), decimal: 2, style: "currency"})
            : formik?.values?.values?.[0].is_splitted === false
            ? numberFormat({number:(
                currentTotal ? currentTotal : currentAmount
              ), decimal: 2, style: "currency"})
            : splittedAmount && splitAmount
            ? numberFormat({number:(splittedAmount), decimal: 2, style: "currency"})
            : splitAmount
            ? numberFormat({number:(splitAmount), decimal: 2, style: "currency"})
            : splittedAmount
            ? numberFormat({number:(splittedAmount), decimal: 2, style: "currency"})
            : currentTotal
            ? numberFormat({number:(currentTotal), decimal: 2, style: "currency"})
            : numberFormat({number:(acutalTotal), decimal: 2, style: "currency"})}
        </div>
      </div>
      <div className="second-pr">
        <div className="mb-5">Payment Method</div>
        {methods?.map((item) => (
          <div className="review-row" key={item.paymentMethod}>
            <div className="">
              {item?.paymentMethod && item.amount ? item?.paymentMethod : ""}{" "}
              {item?.last4 ?? ""}
            </div>
            <div className="">{numberFormat({number:item?.amount, decimal: 2, style: "currency"})}</div>
          </div>
        ))}
        {formik?.values?.checks?.map((item) => (
          <div className="review-row" key={item.paymentMethod}>
            <div className="">
              {item?.paymentMethod} {item?.check_number ?? ""}
            </div>
            <div className="">{numberFormat({number:item?.amount, decimal: 2, style: "currency"})}</div>
          </div>
        ))}
      </div>
      <div className="col-sm-12 px-0">
        <TextareaField
          {...{
            formik,
            title: "Add Note",
            name: `receipt_note`,
            placeholder: "Note text",
            style: { resize: "none" },
            value: formik?.values?.receipt_note,
          }}
        />
      </div>
      <div className="pb-5">
        <MyCheckbox
          name="comment_receipt"
          title="Comment on receipt"
          handlechange={(event) => {
            formik.setFieldValue("comment_receipt", event.target.checked);
          }}
          checked={formik?.values?.comment_receipt}
        />
      </div>
    </div>
  );
};

export default Review;
