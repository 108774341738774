export const  filterObjectsWithPriceKey = (dataArray) => {
  console.log('dataArray', dataArray)
  const hasNullOrZeroPrice = dataArray.some(obj => {
    if (obj.hasOwnProperty('price')) {
        return obj.price === 0 || obj.price === null || obj.price === undefined;
    }
    return false;
});

console.log("hasNullOrZeroPrice",hasNullOrZeroPrice);
return hasNullOrZeroPrice
  };