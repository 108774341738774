const colorConfig = {
  light: {
    color: "#000",
    bg: "#fff",
    borderColor: "#ccc",
    placeholderColor: "#000",
    buttonBackground: "#007bff",
    textColor: "#3D4444",
    warningColor: "red",
    lockLoaderBackground: "#ffffff90",
    lockLoaderColor: "rgba(0, 0, 0, 0.54)",
    errorColor: "red",
    iconButtonBackground: "transparent",
    highlightBackground: "#00000010",
    primaryColor: "#2555ef",
    IconColor: "#808080",
    imgBackground: "#ccc",
    homeBackgroundColor: "#BEDFCC",
  },
  dark: {
    color: "#fff",
    bg: "#000",
    borderColor: "#fafafa",
    placeholderColor: "#fafafa",
    buttonBackground: "#fafafa50",
    warningColor: "red",
    lockLoaderBackground: "#00000090",
    lockLoaderColor: "#fff",
    errorColor: "red",
    textColor: "#3D4444",
    iconButtonBackground: "transparent",
    highlightBackground: "#ffffff10",
    primaryColor: "#2555ef",
    IconColor: "#808080",
    imgBackground: "#ccc",
    homeBackgroundColor: "#BEDFCC",
  },
};

export { colorConfig };
