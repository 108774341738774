import { useAuth0 } from "@auth0/auth0-react";
import FinanceService from "Lib/API/Finance";
import Action from "components/ActionPopOver";
import AlertDialog from "components/Dialog";
import { formatDate } from "helper/dateFormat.js";
import { numberFormat } from "helper/numberFormat.js";
import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { DateFormetContext } from "../../../app/App.js";

export const Payment = ({ data, onReversalClick,viewFileClickHandler,downloadFileClickHandler,hideInvoiceButtons,hideReceiptButtons }) => {
  let hitoryDetails = data?.charge?.payment?.History.map(
    (item) => item.paymnetMethod?.method ?? ""
  );
  hitoryDetails = [...new Set(hitoryDetails)];
  const dateFormet = React.useContext(DateFormetContext);
  const { getAccessTokenSilently } = useAuth0();
  const [reversalActionTypes, setReversalActionTypes] = useState(false);
  const [getTypes, setActionTypes] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const [chargeId, setchargeId] = useState();
  const [shareIncvoicePDF, setShareInvoicePDF] = useState("");
  const [shareReceiptPDF, setShareReceiptPDF] = useState("");
  const [invoiceFile, setInvoiceFile] = useState(false);
  const [receiptFile, setReceiptFile] = useState(false);
  const [invoiceDownload, setInvoiceDownload] = useState(false);
  const [receiptDownload, setReceiptDownload] = useState(false);
  const [invoiceReceiptData, setInvoiceReceiptData] = useState("");
  const [fileData, setFileData] = useState("");
  const [file, setFile] = useState([]);

  const fetchReversalTypes = async (type, Id1, Id2) => {
    setchargeId(Id1);
    setPaymentId(Id2);
    const token = await getAccessTokenSilently();
    await FinanceService.getAllReversalTypes(type, token).then((res) => {
      setActionTypes(res?.result);
    }).catch((err) => {
      toastr.error('Error', err?.data?.message);
    })

    setReversalActionTypes(true);
  };
  const handleCloseDialog = () => {
    setReversalActionTypes(false);
  };
  const onModalClose = () => {
    closeModal();
  };
  const closeModal = () => {
    setInvoiceFile(false);
    setReceiptFile(false);
    setShareInvoicePDF("");
    setShareReceiptPDF("");
    setInvoiceDownload(false);
    setReceiptDownload(false);
  };
  const invoiceClick = () => {
    setInvoiceFile(true);
    fetchMyAPI(data?._id, "invoice");
  };
  const receiptClick = () => {
    setReceiptFile(true);
    fetchMyAPI(data?.charge?._id, "receipt");
  };
  const handelInvoiceShare = () => {
    fetchMyAPI(data?._id, "invoice");
    setShareInvoicePDF("invoice");
  };
  const handelReceiptShare = () => {
    fetchMyAPI(data?.charge?._id, "receipt");
    setShareReceiptPDF("receipt");
  };

  const invoiceDownloadClick = () => {
    setInvoiceFile(false);
    setInvoiceDownload(true);
    fetchMyAPI(data?._id, "invoice");
  };
  const receiptDownloadClick = () => {
    setReceiptDownload(true);
    fetchMyAPI(data?.charge?._id, "receipt");
    setTimeout(() => {
      setReceiptFile(false);
    }, 1000);
  };
  const fetchMyAPI = async (id, type) => {
    const token = await getAccessTokenSilently();
    await FinanceService.getDocumentByTransactionId(token, id, type).then(
      (res) => {
        const result = res?.result?.[0];
        setInvoiceReceiptData(result);
      }
    ).catch((err) => {
      toastr.error('Error', err?.data?.message);
    })
  };

  return (
    <>
      {/* <MyModal
        className="new-charge-modal"
        open={
          invoiceDownload
            ? invoiceDownload
            : shareIncvoicePDF == "invoice"
            ? true
            : invoiceFile
        }
        onClose={onModalClose}
      >
        <InvoiceDocument
          setFileData={setFileData}
          data={invoiceReceiptData}
          finance="finance"
          downloadPDF={invoiceDownload}
          sharePDF={shareIncvoicePDF}
        />
      </MyModal> */}
      {/* <MyModal
        className="new-charge-modal"
        open={
          receiptDownload
            ? receiptDownload
            : shareReceiptPDF === "receipt"
            ? true
            : receiptFile
        }
        onClose={onModalClose}
      >
        <ReceiptDocument
          setFileData={setFileData}
          data={invoiceReceiptData}
          setFile={setFile}
          finance="finance"
          downloadPDF={receiptDownload}
          sharePDF={shareReceiptPDF}
        />
      </MyModal> */}

      <div className="">
        <div className="drawer-header">
          <div className="cursor-pointer">
            <Action
              style={{ width: "16px" }}
              action="finance"
              actionDrawer="drawer"
              setInvoiceFile={() => {
                viewFileClickHandler("invoice")
              }}
              setReceiptFile={() => {
                viewFileClickHandler("receipt")
              }}
              setShareInvoicePDF={handelInvoiceShare}
              setShareReceiptPDF={handelReceiptShare}
              setInvoiceDownload={() => {
                downloadFileClickHandler("invoice")
              }}
              setReceiptDownload={() => {
                downloadFileClickHandler("receipt")
              }}
              hideInvoiceButtons={hideInvoiceButtons}
              hideReceiptButtons={hideReceiptButtons}
              onReversalClick={() =>
                fetchReversalTypes(
                  data?.charge?.status === "charge"
                    ? "charge"
                    : data?.charge?.status === "payment"
                    ? "payment"
                    : "",
                  data?.charge?._id,
                  data._id
                )
              }
              row={data}
            />
            <AlertDialog
              open={reversalActionTypes}
              getTypes={getTypes}
              handleClose={handleCloseDialog}
              onDecline={() => {
                setReversalActionTypes(false);
              }}
              onAccept={(id, type, hidden) =>
                onReversalClick(
                  data?.charge?.status === "charge"
                    ? { chargeId: data?.charge?._id }
                    : data?.charge?.status === "payment"
                    ? {
                        reversal_type: {
                          id: id,
                          type: type,
                          hidden: hidden,
                        },
                        chargeId: chargeId,
                        paymentId: paymentId,
                      }
                    : "",
                  setReversalActionTypes
                )
              }
              content={"Choose a reversal type "}
            />
          </div>
        </div>
        <div className="drawer-title">Payment</div>
        <div className="drawer-content ">
          <div className="first-pr">
            <p className="drawer-row">
              <div className="">Amount</div>
              <div className="value">
                {numberFormat({number:(data?.charge?.amount ?? data?.total_amount), decimal:2, style:"currency"})}
              </div>
            </p>
            <p className="drawer-row">
              <div className="">Transaction#</div>
              <div className="value" title={data?._id}>
                {" "}
                {data._id ? data._id.slice(0, 5) + "..." : "-"}
              </div>
            </p>
            <p className="drawer-row">
              <div className="">Status</div>
              <div className="value">{data?.charge?.status}</div>
            </p>
          </div>
          <hr className="my-10" />
          <div className="second-pr">
            <p className="drawer-row ">
              <div className="">Deposited</div>
              <div className="performed-by">
                {formatDate({date:data?.createdAt, formatType: "defaultWithTimeUTC"}) ?? "-"}
              </div>
            </p>
            <p className="drawer-row">
              <div className="">Paid</div>
              <div className="performed-by">
                {formatDate({date:data?.charge?.payment?.paid_at, formatType: "defaultWithTimeUTC"}) ?? "-" }
              </div>
            </p>
            <p className="drawer-row">
              <div className="">Performed by</div>
              <div className="performed-by">{data?.created_by?.orgName}</div>
            </p>
            <p className="drawer-row mt-11 mb-18">
              <div className="">Convenience Fee</div>
              <div className="">
                {numberFormat({number:data?.charge?.payment?.convenient_fee ?? 0, decimal:2, style:"currency"})}
              </div>
            </p>
            <p className="drawer-row">
              <div className="">Payment Method</div>
              <div className="payment-method">
                {data?.paymnetMethod?.method
                  ?.replace(/_/g, " ")
                  ?.toUpperCase() ??
                  hitoryDetails.map((item) => (
                    <span
                      style={{
                        marginLeft: "10",
                        display: "flex",
                        alignItem: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {item?.replace(/_/g, " ")?.toUpperCase()}
                      <br></br>
                    </span>
                  ))}
              </div>
            </p>
            {data?.paymnetMethod?.method ? (
              <p className="drawer-row">
                <div className="">Details</div>
                <div className="">
                  {data?.paymnetMethod?.method
                    ?.replace(/_/g, " ")
                    ?.toUpperCase() ?? ""}
                  ({data?.paymnetMethod?.check_number ?? ""})
                </div>
              </p>
            ) : (
              ""
            )}
            <p className="drawer-row">
              <br />
              <div className="">
                Payment {numberFormat({number:data?.amount ?? data?.charge?.paid, decimal:2, style:"currency"})}
              </div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
