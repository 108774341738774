import React from "react";
import { useSelector } from "react-redux";

import { Container } from "layout";
import { Loader } from "components";
import { colors } from "theme";

export const LockLoader = ({ children, style, ...rest }) => {
  const { loader } = useSelector((state) => state.lockLoader);

  if (!loader) return null;
  return (
    <Container style={styles.body}>
      <Loader />
    </Container>
  );
};

const styles = {
  body: {
    color: colors.lockLoaderColor,
    background: colors.lockLoaderBackground,
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: 100000,
  },
};
