import React from "react";
import { withUserExist } from "../CustomHooks";
import DeleteIcon from "@material-ui/icons/Delete";
import "./index.scss";

const CardComponent = ({ handleSetDefault, handleDelete, ...props }) => {
  return (
    <div>
      <div className="credit-card">
        <div className="card-content w-150">
          <p className="card-name">Card# {props?.idx + 1}</p>
          <div className="card-details">
            <p className="name pb-0">
              {props?.card?.name
                ? `${props?.card?.name} (${props.card?.brand})`
                : `${props?.card?.brand}`}
            </p>
            <p className="number">************{props?.card?.last4}</p>
          </div>
          <p
            className="remove-button"
            onClick={() => handleDelete(props?.card?.id)}
          >
            <DeleteIcon /> Delete Payment Method
          </p>
        </div>
        <div
          className="set-button p-5"
          style={{ cursor: "pointer" }}
          onClick={handleSetDefault}
        >
          Set As Default
        </div>
      </div>
    </div>
  );
};

export default withUserExist(CardComponent);
