import React from 'react';
import {Content, DefaultImg, LeftSideWrapper, LogoImage, SchoolImage, Title } from './Theme';
import  {ReactComponent as EventImg} from 'assets/images/EventImg.svg';
import { useSelector } from "react-redux";

export const LeftSide = () => {
    const Logo = localStorage.getItem('logoImgForm');
    const { formOnlineData } = useSelector((state) => state?.Forms)

    return (
                    <LeftSideWrapper>
                        <LogoImage src={Logo} alt='Logo' />
                         {formOnlineData?.background? <SchoolImage src={formOnlineData?.background } alt='notFound' /> 
                         :<DefaultImg>
                            <EventImg/>
                         </DefaultImg>
                         }
                        
                        <Content haveDisc={formOnlineData?.description}>
                            {/* <Title>Join Our Event</Title> */}
                            {/* <Description>
                            {eventData?.description?.replace(/&lt;/g, '<') !== '<p></p>\n' && (
                            <div className="drawer_description">
                                <p
                                dangerouslySetInnerHTML={{
                                    __html: eventData?.description?.replace(/&lt;/g, '<'),
                                }}
                                ></p>
                            </div>
                            )}
                            </Description> */}
                        </Content>
                    </LeftSideWrapper>
    );
};

