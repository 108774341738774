import { getDateFormatForDatePicket } from "./dateFormat";


export const formatDateInFormData = (formData) => {
  if(!formData || !formData?.length) return [];
  const dateFormat = getDateFormatForDatePicket();
  const data = formData?.map((form) => {
    if(form?.element === "DatePicker") {
      return {
        ...form,
        dateFormat: dateFormat,
      }
    }
    return form;
  });
  return data;
}
