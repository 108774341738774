import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import FormService from "Lib/API/Forms";
import { ReactComponent as ArrowUp } from "assets/icons/ArrowBlueUp.svg";
import { ReactComponent as Delete } from "assets/icons/DeleteBlue.svg";
import { CheckBox } from "components/CheckBox/CheckBox";
import AlertDialog from "components/Dialog";
import FormGeneratorComponent from "helper/FormGenerator";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formSlice, setCounterTicket } from "services/api/forms";
import { Products } from './Products/Products';
import { DashLine, DeleteTicket, ProductWrapper, TicketContent, TicketName, TicketTitle, TicketWrapper, ValidationIcon, ValidationItems, ValidationWrapper } from './Thems';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderRadius: "10px",
    boxShadow: "0px 14px 60px 0px rgba(22, 16, 59, 0.05)",
    "&:not(:last-child)": {},
    "&::before": {},
    "& .css-1cgnkco-MuiButtonBase-root-MuiAccordionSummary-root": {
      minHeight: "70px",
      paddingRight: "40px",
      paddingLeft: "40px",
    },
  }));

export const Ticket = ({ index, formik,ticketUniqueId,setIsCheckedApplay,isCheckedApplay,submitButton,totalProducts}) => {
  const [expanded, setExpanded] = useState(index === 0 ? true : false);
  const { formDataOnlineform,numberOfTickets,products,varients,selectedValue } = useSelector(
    (state) => state?.Forms
    );
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const reserveId = localStorage.getItem("reserveId");
  const [isLoading, setIsLoding] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const productsData=formik.values.ticketsData[index]
  const  formDataReservation  = formik?.values?.registrationForm || [];
  



  const handleDeleteProducts = (ticketIndex) => {
    const removeTicket = numberOfTickets.filter((num)=>num !== ticketIndex)
    dispatch(setCounterTicket(removeTicket));

    const filteredTickets = formik.values.registrationForm?.filter((local)=>local.id !== ticketIndex)
    const filteredTicketsData= formik.values.ticketsData?.filter((local)=>{
      return formik.values.ticketsData.indexOf(local) !== index
    })
    formik.setFieldValue('ticketsData', filteredTicketsData);
    formik.setFieldValue('registrationForm', filteredTickets);
    // Reduce the counterValue from varients depend on number on selected values
    if (selectedValue && selectedValue.length > 0) {
      const updatedData = { ...varients };
      const NewVarientsData = Object.keys(updatedData).map((key) => {
        const selectedArray = selectedValue[0][updatedData[key]?.varientTitle];
        const selectedTicket = selectedArray?.[0]?.ticketIndex === ticketIndex;
        if (selectedArray && selectedTicket) {
          if (updatedData[key]?.variantsInfo?.length === 0) {
            return { ...updatedData[key], counterValue: updatedData[key]?.counterValue - selectedArray[0]?.value };
          } else {
            const varientInfo = updatedData[key]?.variantsInfo?.map((varInfo) => {
              const findSelectedValue = selectedArray.find((val) => val.value === varInfo.option && val.ticketIndex === ticketIndex);             
              if (findSelectedValue && findSelectedValue !== undefined) {
                return { ...varInfo, counterValue:varInfo.counterValue > 0 ?  varInfo.counterValue - 1 :0 };
              } else {
                return { ...varInfo };
              }
            });
            const dropDown =  updatedData[key]?.id === productsData[key]?._id ? updatedData[key]?.numberOfDrops-productsData[key]?.dropdowns?.length : updatedData[key]?.numberOfDrops
            return { ...updatedData[key],numberOfDrops:dropDown,  variantsInfo: varientInfo };
          }
        } else {
            return { ...updatedData[key] };
         
        }
      });
      dispatch(formSlice.actions.setVarients(NewVarientsData));
    
      const updatedForm = {};
      const form = selectedValue[0];
      for (const key in form) {
        updatedForm[key] = form[key].filter((item) => item.ticketIndex !== ticketIndex);
      }
    
      const filteredData = Object.fromEntries(Object.entries(updatedForm).filter(([k, v]) => v.length > 0));
      const finalData = Object.keys(filteredData)?.length > 0 ? [filteredData] : [];
      dispatch(
        formSlice.actions.setSelectedValue({
          data: finalData,
          productIndex: index,
          productTitle: products[index]?.title,
          dropdownIndex: selectedValue[0]?.[products[index]?.title]?.length - 1,
          type: 'remove'
        })
      );
    }

    };

  const handleDeleteTicket = async () => {
    const body = {
      reservationId: reserveId,
    };
    setIsLoding(true);
    try {
      const token = localStorage.getItem("formToken");
      await FormService.deleteTicket(token, body).then((res) => {
        setIsLoding(false);
        setOpen(!open);
        handleDeleteProducts(ticketUniqueId)
      });
    } catch (error) {
      setIsLoding(false);
      if(error?.data?.message){
      enqueueSnackbar(error?.data?.message, {
        variant: "error",
      });
    }
      throw error;
    }

  };
    useEffect(()=>{ 
      //Restractating data for radio and multi checkbox 
         const test = formDataOnlineform?.length > 0 && formDataOnlineform?.map((item,i)=>{
            if(item?.options){
                return {
                  ...item,
                  options:item?.options?.map((val)=>{
                    return{
                      ...val,
                      key:`${val.key}_${index}`,
                    }
                  })
                }
              }else{
                return {
                  ...item,
                }
              }
              })
              setData(test)
  },[formDataOnlineform])

  const handleAddDropdown = (type,id,product,ticketObjectId,varientsAfterDropDeleted) => {
    const options = []
    // Loop on variantInfo inside product to get options for each dropDown 
    const data = product?.variantInfo?.map((obj) => Object.keys(obj).map((key, index) => {
      options.push({option:key,quantity:obj[key]?.quantity || "infinit",mainQuantity:obj[key]?.quantity || "infinit"})
      
    }))
    if(type === 'increment'){
     // Add New drop down when the user click on increment button 
    const newDropdown = {
      id:id,
      options: options,
      selectedValue:""
    };
    formik.setFieldValue(`ticketsData.[${index}].[${ticketObjectId}].dropdowns`,[...formik?.values?.ticketsData[index]?.[ticketObjectId]?.dropdowns, newDropdown]);
  }else{
    
    const lastObjIndex = formik?.values?.ticketsData[index]?.[ticketObjectId]?.dropdowns.length -1 
    const lastObj =formik?.values?.ticketsData[index]?.[ticketObjectId]?.dropdowns[lastObjIndex]
    // if the user selected value inside drop down that mean we add it inside selectedValue array in this case we can remove the last obj when he click on decremnt button  
    if(lastObj.selectedValue !== ''){
      const newData = { ...selectedValue[0] }; // Create a shallow copy
      if (newData?.[product?.title]?.length >0) {
        // Remove from varients
        const VarientIndex = newData?.[product?.title]?.length -1
        const changeCounterValue = data && varients?.[ticketObjectId]?.variantsInfo?.map((varient)=>{
        if(varient.option === newData?.[product?.title]?.[VarientIndex]?.value){
              return { ...varient, counterValue : varient?.counterValue - 1}; 
          }else{
            return { ...varient}
          }
        })
        dispatch(formSlice.actions.setVarients({...varients,[ticketObjectId]:{...varientsAfterDropDeleted[ticketObjectId],variantsInfo:changeCounterValue}}));
        // Remove from selectedValue
        const arrayWithoutLastObj = newData[product?.title].slice(0, -1); // Remove the object at the last index
        const DataAfterRemoving= {...selectedValue[0],[product?.title]:arrayWithoutLastObj} // get the prevous data from array and add the new after removing
        // remove the object if there is no data inside array of thid object
        const filteredData = Object.fromEntries( Object.entries(DataAfterRemoving).filter(([k, v]) => v.length > 0));
        // chyeck if we have date add it inside array or return empty array
        const finalData = Object.keys(filteredData)?.length > 0 ? [filteredData] : []
       
        // to remove from selectedValue 
        dispatch(formSlice.actions.setSelectedValue({data:finalData,productIndex:ticketObjectId,productTitle:product.title,
          dropdownIndex:selectedValue[0]?.[product.title]?.length -1,
          type:'remove'}));
      }
    }else{
      dispatch(formSlice.actions.setVarients({...varients,[ticketObjectId]:{...varientsAfterDropDeleted[ticketObjectId]}}));
    }
    // Remove last drop down when the user click on decrement button
    const removeLastDropDown = formik?.values?.ticketsData[index]?.[ticketObjectId]?.dropdowns.slice(0, -1);
    formik.setFieldValue(`ticketsData.[${index}].[${ticketObjectId}].dropdowns`,removeLastDropDown);

  }
  };
  // Function to increment the counter for a specific object by a given value
  const incrementCounter = (id,product,productId,ticketObjectId) => {
    const increment=  productsData.map(obj =>{return obj._id === id ? { ...obj, counter: obj.counter + 1  } : obj});
    formik.setFieldValue(`ticketsData.[${index}]`, increment);
    // check if we gave variant for this product or not 
    const productVariant= {...varients}
    const newNumber = (productVariant[ticketObjectId]?.numberOfDrops || 0) + 1;
    const newCounter = (productVariant?.counterValue || 0) + 1;
    const newCounter2 = (productVariant[ticketObjectId]?.counterValue || 0) + 1;
    if(product.variant){
      const incrementNumberOfDrops = {...productVariant,[ticketObjectId] : 
        {...productVariant[ticketObjectId] ,numberOfDrops:newNumber} }
      dispatch(formSlice.actions.setVarients(incrementNumberOfDrops));
      handleAddDropdown("increment",productId,product,ticketObjectId)
    }else{
      const incrementCounterValue =productVariant[ticketObjectId].quantity !== 'noLimit'? {...productVariant,[ticketObjectId] : {...productVariant[ticketObjectId] ,
        counterValue: newCounter2
      }} : {...productVariant,
        counterValue: newCounter
      }
      dispatch(formSlice.actions.setVarients(incrementCounterValue));    
    }
    
  };
  
  // Function to decrement the counter for a specific object by a given value
  const decrementCounter = (id,product,productId,ticketObjectId) => {
    const decrement =  productsData.map(obj =>{return  obj._id === id ? { ...obj, counter: obj.counter - 1 } : obj});
    formik.setFieldValue(`ticketsData.[${index}]`, decrement);
    if(product.variant){
    const decrementNumberOfDrops = {...varients,[ticketObjectId] : 
      {...varients[ticketObjectId] ,numberOfDrops:varients[ticketObjectId]?.numberOfDrops>0 ?varients[ticketObjectId]?.numberOfDrops -1:0 
    }}
    handleAddDropdown("decrement",productId,product,ticketObjectId,decrementNumberOfDrops)
  }else{
    const decrementCounterValue = {...varients,[ticketObjectId] : {...varients[ticketObjectId] ,
      counterValue:varients[ticketObjectId]?.counterValue>0 ?  varients[ticketObjectId]?.counterValue - 1 :0
    }}
    dispatch(formSlice.actions.setVarients(decrementCounterValue));
  }

  };
 


const syncValuesWithFormik = (form) => {
     // Create a copy of the current formDataReservation
  const updatedFormDataReservation = [...formDataReservation];

  // Check if there is an existing entry with the current ticket id
  const existingEntryIndex = updatedFormDataReservation.findIndex((item) => item.id === ticketUniqueId);

  if (existingEntryIndex !== -1) {
    // If the entry exists, update its formData
    updatedFormDataReservation[existingEntryIndex] = { ...updatedFormDataReservation[existingEntryIndex], formData: form };
    formik.setFieldValue('registrationForm', updatedFormDataReservation);
  } else {
    return
  }

}

const handleApplayCheckBox = (e)=>{
  const doWeHaveValues = formDataReservation?.[0]?.formData?.filter((val)=>val.value)

  if(formDataReservation?.[0]?.formData?.length > 0 && doWeHaveValues?.length >0){
  setIsCheckedApplay(e?.target?.checked)
  localStorage.setItem("ApplayCheckBox",JSON.stringify(e?.target?.checked))

  if(!formDataReservation?.length && !formDataReservation[0]?.formData?.length) return;
  const checked = e?.target?.checked;
  const dataDistribution = formDataReservation?.map((item,index) => {
    if(index === 0) return {...item}
      return {
        ...item,
        formData: formDataReservation?.[0]?.formData?.map((formItem,index) => {
          if(index <=4 ) {
            return {
            ...formItem,
            ...(!checked ? {value: ""} : {}),
            }
          } else {
            return {
              ...formItem,
              value: item?.formData[index]?.value || "",
            }
          }
        
        })
      }
  })
    formik.setFieldValue('registrationForm', dataDistribution);
   
  }
}
  return (
    <>
    <TicketWrapper>
      <Accordion expanded={expanded}>
   
        <DashLine />
        <AccordionDetails>
          <TicketContent>
            {formik.values?.errors && formik.values?.errors?.[index]?.formData.length>0&&
            <ValidationWrapper>
              <ValidationIcon/>
              <ValidationItems>
              {formik.values?.errors?.[index]?.formData?.map((error)=>(
                <li>{error.errorMessage}</li>
              ))}
            </ValidationItems>
            
            </ValidationWrapper>
            }
            {index ===0 && numberOfTickets?.length >1 && <CheckBox text="Apply the information below to all tickets" onChange={handleApplayCheckBox} checked={isCheckedApplay}
            />}
            <br />
            {data?.length > 0 && (
              <FormGeneratorComponent
                key={index}
                formStructure={[...data]}
                syncValuesWithFormik={syncValuesWithFormik}
                isReadOnly ={isCheckedApplay && index !==0}
                formDataReservation={formDataReservation}
                index={index}
                answerData={formDataReservation?.[index]?.formData}
                button={submitButton}
              />
            )}
            {/* {products?.length >0 && 
            <ProductWrapper>
              <h3>
               Select optional add-ons below
              </h3>
              <Products index={index} productsData={productsData} formik={formik} ticketUniqueId={ticketUniqueId}
              incrementCounter={incrementCounter} decrementCounter={decrementCounter}
              dropdowns={formik?.values?.ticketsData[index]?.dropdowns}
              />
          </ProductWrapper>
        } */}
          </TicketContent>
               
        </AccordionDetails>
      
      </Accordion>
    </TicketWrapper>
     {open && <AlertDialog
      open={open}
      handleClose={() => setOpen(!open)}
      onDecline={() => {
        setOpen(!open);
      }}
      onAccept={handleDeleteTicket}
      isLoading={isLoading}
      content={
        <div className="alert-message-popup">
          <p className="alert-icon">{/* <DeleteForeverIcon /> */}</p>
          <div>
            <p>Delete Ticket</p>
            <p className="alert-message">
              Are you sure you want to remove this ticket?
            </p>
          </div>
        </div>
      }
    />} 
    </>
  );
};
