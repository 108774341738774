/* eslint-disable jsx-a11y/alt-text */
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, TableContainer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from '@mui/material';
import Action from 'components/ActionPopOver';
import { formatDate } from 'helper/dateFormat';
import { numberFormat } from 'helper/numberFormat';
import MemberService from 'Lib/API/Member';
import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import './index.scss';

const TableCellHead = withStyles((theme) => ({
  root: {
    height: '39px',
    padding: '0px 20px',
    borderBottom: 'none',
    fontFamily: 'Poppins',
  },
}))(TableCell);

const TableCellBody = withStyles((theme) => ({
  root: {
    height: '65px',
    padding: '0px 20px',
    fontFamily: 'Poppins',
  },
}))(TableCell);

const AvailableClassList = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [count, setCount] = useState();

  const [page, setPage] = useState(0);

  const enableLoading = (value) => {
    setLoading(value);
  };
  async function getClassesList() {
    enableLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const payload = {
        isMember: true
      };
      const result = await MemberService.getClassesList(
        token,
        page + 1,
        10,
        localStorage.getItem('orgId'),
        payload
      );
      setList(result?.payload?.data);
      setCount(result?.payload?.pagination?.count);
    } catch (error) {
      toastr.error('Error', error?.data?.message || 'Something went wrong!');
    } finally {
      enableLoading(false);
    }
  }
  React.useEffect(() => {
    getClassesList();
  }, [page]);

  const compareDate = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const date1 = new Date(date);
    if (date1.toDateString() === today.toDateString()) {
      return <span style={{ color: '#2555EF' }}>Today</span>;
    } else if (date1.toDateString() === tomorrow.toDateString()) {
      return <span style={{ color: '#2555EF' }}>Tomorrow</span>;
    } else {
      return formatDate({date:date, formatType: "defaultWithUTC"})
    }
  };

  const checkClassIsFull = (row) => {
    const totalParticipants = row?.participantsDetails?.total;
    const maximumParticipants = row?.class_details?.maximum_participants
    return !row?.infiniteParticipants ? totalParticipants >= maximumParticipants : false
  }

  const displayRegistrationBtnText = (row) => {
    let text = " Sign up";
    const isClassFull = checkClassIsFull(row);
    
    if (isClassFull && row?.allow_waiting_List && row?.isFull) {
        text = "Join Wait List";
    } else if (isClassFull) {
        text = "Class is Full";
    } else {
        text = "Sign up";
    }
    return text;
}

  return (
    <div
      className="w-100 school_class_list mt-4"
      style={{
        padding: '0rem 0rem 25rem 0rem',
      }}
    >
      <div className={'family-table-container'}>
        <div className="current-class-table-container">
          <div style={{ padding: '17px' }}>
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead className="charges-table">
                  <TableRow>
                    {/* <TableCellHead
                      padding="checkbox"
                      className="class__header__cell checkbox_head"
                    >
                      <MyCheckbox name={'visible'} handlechange={() => {}} />
                    </TableCellHead> */}

                    <TableCellHead className="class__header__cell className_head">
                      <div>CLASS NAME</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell year__head"
                    >
                      <div>DATE</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell registration__head"
                    >
                      <div>REGISTRATION</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell ageRane__head"
                    >
                      <div>AGE RANGE</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell nonMember__head"
                    >
                      <div>NON-MEMBER</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell total__cost"
                    >
                      <div>TOTAL COST</div>
                    </TableCellHead>
                    <TableCellHead
                      align="left"
                      className="class__header__cell"
                    />
                  </TableRow>
                </TableHead>
                <TableBody
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <div
                          className="user__loader"
                          style={{ height: '320px' }}
                        >
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : list?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <h6 style={{ height: '320px', marginTop: '100px' }}>
                          No Classes Found
                        </h6>
                      </TableCell>
                    </TableRow>
                  ) : (
                    list?.map((row, i) => (
                      <React.Fragment>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className='class_tableRow'
                          onClick={() => {
                            if(checkClassIsFull(row)) return;
                            window.location.replace(
                              `${window.location.origin}/school/${row?._id}`
                            );
                          }}
                        >
                          <TableCellBody
                            className="status-head main-width-class"
                            component="th"
                            scope="row"
                           
                          >
                            <div className="transaction-status">
                              <div style={{ width: 22 }}></div>

                              <div className="pl-0">
                                <div className="school-table-className">
                                  {row?.class_name}
                                </div>
                              </div>
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="main-width-class"
                            align="left"
                          
                          >
                            <div className="table-row">
                              <p className="school-table-year">{row?.year}</p>
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="account-header main-width-class"
                            align="left"
                            
                          >
                            {' '}
                            <div className="school-table-registration text-nowrap">
                              {compareDate(row?.class_start_date)} -{' '}
                              {row?.always_available
                                ? 'Always available'
                                : compareDate(row?.class_end_date)}
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            className="charged-head main-width-class"
                            align="left"
                           
                          >
                            <div
                              style={{
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                              }}
                              className="school-table-dateRange"
                              title={row?.ageRange ?? 'N/A'}
                            >
                              {row?.class_details?.ageStart} -{' '}
                              {row?.class_details?.ageEnd}
                            </div>
                          </TableCellBody>
                          <TableCellBody
                            style={{
                              wordBreak: 'break-all',
                              whiteSpace: 'normal',
                            }}
                            className="paid-head main-width-class"
                            align="left"
                          
                          >
                            <div
                              className="transaction-paid table-row"
                              title={row?.allow_non_members_register ?? 'N/A'}
                            >
                              {row?.allow_non_members_register === true ? (
                                <CheckCircleIcon
                                  color="primary"
                                  className="ml-8"
                                />
                              ) : (
                                ''
                              )}
                            </div>{' '}
                          </TableCellBody>
                          <TableCellBody
                            className="transaction-head main-width-class"
                            align="left"
                          
                          >
                            <div
                              className="school-table-dateRange"
                              title={row?.class_pricing?.total_cost ?? 'N/A'}
                            >
                              
                              {numberFormat({number:+row?.class_pricing?.total_cost, decimal: 2, style: 'currency'})}
                            </div>{' '}
                          </TableCellBody>
                          <TableCellBody
                            className="transaction-head main-width-class"
                            align="left"
                          >
                            <button className='btn btn-primary class-list-registration-btn' 
                              disabled={checkClassIsFull(row)}
                              onClick={(e) => {
                                e.stopPropagation();
                                window.location.replace(
                                  `${window.location.origin}/school/${row?._id}`
                                );
                              }}
                              type='button'
                            >
                              {displayRegistrationBtnText(row)}
                            </button>
                          </TableCellBody>
                        </TableRow>
                      </React.Fragment>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="pagination-custom">
            <Pagination
              count={Math.ceil(count / 10)}
              color="primary"
              variant="outlined"
              page={page + 1}
              onChange={(event, value) => {
                setPage(value - 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableClassList;
