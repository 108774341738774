import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/scss/bootstrap.scss";
import "./index.scss";

import "_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "_metronic/_assets/plugins/flaticon/flaticon.css";
import "_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
// import history from "./utils/history";
// import InitOrg from "./InitOrg";
import Application from "./application";
import { Provider } from 'react-redux';
import { store, persistor } from 'app/store';

// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Application />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
