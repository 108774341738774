import { createSlice } from "@reduxjs/toolkit";
import { generalApiTemplate, generalApiResponseTemplate } from "../apiTemplate";
const initialState = {
  loading: "idle",
  entities: {},
  currentRequestId: undefined,
  error: [],
};

export const signIn = () => {
  return generalApiTemplate({
    storeName: "auth",
    _url: `auth/login`,
    method: "POST",
  });
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, { payload: { token } }) => {
      state.entities.token = token;
      return state;
    },
    resetAction: () => {
      return initialState;
    },
    endSession: (state) => {
      state.entitise = {};
      return state;
    },
  },
  extraReducers: generalApiResponseTemplate(signIn()),
});

// export { setAccessToken, resetAction } = authSlice.actions