import React, { useEffect } from "react";
import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

// Components
import { PrivateRoute, HomeRouters } from "router";
import { AppLayout } from "layout";
import { Toast } from "components";
import { LockLoader } from "pages/lockLoader";
import { I18nProvider } from "locales";

export const DateFormetContext = React.createContext();

const App = () => {
  const { isRTL, lang } = useSelector((state) => state?.locales);

  const { isLoading } = useAuth0();
  let orgData = JSON.parse(
    localStorage.getItem("organization") !== "undefined"
      ? localStorage.getItem("organization")
      : "{}"
  );

  useEffect(() => {
    localStorage.setItem("DonationLayout", false);
  }, [])

  const formatedDate = orgData?.dateFormat ? orgData?.dateFormat : "MM/DD/YYYY";

  if (isLoading) return <div>Loading...</div>;
  return (
    <>
      <Helmet>
        <html lang={lang} dir={isRTL ? "rtl" : "ltr"} />
      </Helmet>
      <Router>
        <LockLoader />
        <Toast />
        <I18nProvider>
          <DateFormetContext.Provider value={formatedDate}>
            <AppLayout>
              <Switch>
                <PrivateRoute path="/">
                  <HomeRouters />
                </PrivateRoute>
                <Redirect path="*" to="/" />
              </Switch>
            </AppLayout>
          </DateFormetContext.Provider>
        </I18nProvider>
      </Router>
    </>
  );
};
export default App;
