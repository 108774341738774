import cuid from "cuid";
import { useMobileView } from 'hook/mobileView/useMobileView';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCounterTicket } from 'services/api/forms';
import { LeftSide } from './LeftSide';
import { RightSide } from './RightSide';
import { Body } from './Theme';

export const FormInfo = () => {
    const { isMobile } = useMobileView()
    const { formOnlineData } = useSelector((state) => state?.Forms)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(setCounterTicket([cuid()]));
        localStorage.setItem("registrationOnlineForm",JSON.stringify([]))
        localStorage.setItem("ApplayCheckBox",JSON.stringify(false))
    },[])
    
    return (
        <Body>
            {Object.keys(formOnlineData).length !== 0 &&  
            <>
             {!isMobile && <LeftSide/>}
            <RightSide />
            </>}
        </Body>
    );
};

