import Popover from "@material-ui/core/Popover";
import ActionSvgComponent from "assets/icons/svg/actionSvg";
// import DeActive from "assets/icons/svg/deActive";
import EyeSvgComponent from "assets/icons/svg/EyeSvg/Eye";
import React from "react";
import DeleteSvgComponent from "../assets/icons/svg/Delete";
import EditSvgComponent from "../assets/icons/svg/Edit/index";

import "./style.scss";

export default function PopOver({
  onEditClick,
  onDeleteClick,
  checkActive,
  onActiveClick,
  onViewClick
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <ActionSvgComponent height={20} width={20} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="action-pop-over">
          {onEditClick ? (
            <div
              className="edit-pop-over"
              onClick={() => {
                onEditClick();
                handleClose();
              }}
            >
              <EditSvgComponent className="svg-size" />
              Edit
            </div>
          ) : (
            ""
          )}
            {onViewClick ? (
            <div
              className="edit-pop-over"
              onClick={() => {
                onViewClick();
                handleClose();
              }}
            >
              <EyeSvgComponent className="svg-size" />
              View
            </div>
          ) : (
            ""
          )}
          {onActiveClick ? (
            <div
              className="center-pop-over"
              onClick={() => {
                onActiveClick();
                handleClose();
              }}
            >
              {/* <DeActive className="svg-size" /> */}
              {!checkActive ? "Activate" : " Deactivate"}
            </div>
          ) : (
            ""
          )}
          {onDeleteClick ? (
            <div
              className="delete-pop-over"
              onClick={() => {
                onDeleteClick();
                handleClose();
              }}
            >
              <DeleteSvgComponent className="svg-size" />
              Delete
            </div>
          ) : (
            ""
          )}
        </div>
      </Popover>
    </div>
  );
}
