import * as React from "react"

function UseCameraSvg (props) {
    return (
        <svg
            width={234.002}
            height={51.001}
            viewBox="0 0 61.913 13.494"
            id="prefix__svg5"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <defs id="prefix__defs2">
                <filter
                    id="prefix__a"
                    x={0}
                    y={0}
                    width={369}
                    height={318}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset id="prefix__feOffset11" />
                    <feGaussianBlur
                        stdDeviation={0.5}
                        result="b"
                        id="prefix__feGaussianBlur13"
                    />
                    <feFlood floodOpacity={0.071} id="prefix__feFlood15" />
                    <feComposite
                        operator="in"
                        in2="b"
                        id="prefix__feComposite17"
                        result="result1"
                    />
                    <feComposite
                        in="SourceGraphic"
                        in2="result1"
                        id="prefix__feComposite19"
                    />
                </filter>
                <style id="style9" />
            </defs>
            <g id="prefix__layer1">
                <g id="prefix__g1498" transform="translate(-47.43 -77.708)">
                    <path
                        id="prefix__rect40"
                        d="M49.149 77.84h58.473c.88 0 1.587.708 1.587 1.588v10.054c0 .88-.708 1.588-1.587 1.588H49.149c-.88 0-1.588-.708-1.588-1.588V79.428c0-.88.709-1.588 1.588-1.588z"
                        fill="none"
                        stroke="#2555ef"
                        strokeWidth={0.265}
                    />
                    <g
                        transform="matrix(.26458 0 0 .26458 -105.5 -1.535)"
                        id="prefix__g50"
                    >
                        <g
                            aria-label="Use Camera"
                            transform="translate(663.826 330)"
                            id="prefix__text44"
                            fontWeight={700}
                            fontSize={15}
                            fontFamily="Poppins-Bold,Poppins"
                            fill="#2555ef"
                        >
                            <path
                                d="M1.377-10.935h2.82v6.555q0 1.355.44 1.941.446.579 1.45.579 1.01 0 1.45-.579.446-.586.446-1.94v-6.556h2.82v6.555q0 2.322-1.164 3.457Q8.474.212 6.086.212q-2.38 0-3.544-1.135Q1.377-2.058 1.377-4.38z"
                                id="prefix__path1718"
                            />
                            <path
                                d="M19.849-7.947v1.992q-.843-.351-1.626-.527-.784-.176-1.48-.176-.747 0-1.113.19-.359.184-.359.572 0 .315.271.483.278.169.989.25l.461.065q2.014.257 2.71.843.696.586.696 1.838 0 1.311-.967 1.97-.967.66-2.886.66-.813 0-1.684-.132-.864-.125-1.78-.381v-1.992q.784.38 1.604.57.828.191 1.677.191.77 0 1.158-.212.388-.213.388-.63 0-.352-.271-.52-.264-.176-1.062-.271l-.462-.059q-1.75-.22-2.453-.813-.703-.593-.703-1.801 0-1.304.893-1.934.894-.63 2.74-.63.724 0 1.523.11.798.11 1.736.344z"
                                id="prefix__path1720"
                            />
                            <path
                                d="M30.557-4.124v.748h-6.13q.094.922.666 1.384.571.461 1.596.461.828 0 1.692-.241.872-.25 1.787-.748v2.022q-.93.352-1.86.527-.93.183-1.86.183-2.227 0-3.465-1.128-1.23-1.135-1.23-3.178 0-2.007 1.208-3.157 1.216-1.15 3.34-1.15 1.934 0 3.091 1.165 1.165 1.164 1.165 3.112zm-2.696-.871q0-.747-.44-1.201-.431-.462-1.134-.462-.762 0-1.238.432-.476.425-.593 1.23z"
                                id="prefix__path1722"
                            />
                            <path
                                d="M46.553-.6q-.777.402-1.619.607-.842.205-1.758.205-2.732 0-4.328-1.523-1.597-1.53-1.597-4.146 0-2.622 1.597-4.145 1.596-1.53 4.328-1.53.916 0 1.758.204.842.205 1.619.608v2.263q-.784-.534-1.546-.783-.761-.25-1.604-.25-1.508 0-2.373.967-.864.967-.864 2.666 0 1.692.864 2.66.865.966 2.373.966.843 0 1.604-.25.762-.248 1.546-.783z"
                                id="prefix__path1724"
                            />
                            <path
                                d="M52.449-3.691q-.82 0-1.238.278-.41.278-.41.82 0 .498.33.784.336.278.93.278.74 0 1.245-.527.505-.535.505-1.333v-.3zm4.006-.99V0h-2.644v-1.216q-.527.747-1.186 1.091-.66.337-1.604.337-1.275 0-2.073-.74-.791-.746-.791-1.933 0-1.443.989-2.117.996-.673 3.12-.673h1.545v-.206q0-.622-.49-.908-.491-.293-1.531-.293-.843 0-1.568.169-.725.168-1.347.505v-2q.842-.204 1.691-.307.85-.11 1.7-.11 2.219 0 3.2.879.99.872.99 2.842z"
                                id="prefix__path1726"
                            />
                            <path
                                d="M66.497-6.84q.498-.763 1.179-1.158.688-.403 1.509-.403 1.413 0 2.153.872.74.871.74 2.534V0H69.44v-4.277q.007-.096.007-.198.008-.103.008-.293 0-.872-.257-1.26-.256-.395-.827-.395-.748 0-1.158.615-.403.615-.417 1.78V0H64.16v-4.277q0-1.363-.234-1.75-.235-.396-.835-.396-.755 0-1.165.622-.41.615-.41 1.765V0H58.88v-8.203h2.637v1.201q.483-.696 1.106-1.047.63-.352 1.384-.352.85 0 1.502.41t.989 1.15z"
                                id="prefix__path1728"
                            />
                            <path
                                d="M82.712-4.124v.748h-6.13q.095.922.667 1.384.57.461 1.596.461.828 0 1.692-.241.872-.25 1.787-.748v2.022q-.93.352-1.86.527-.93.183-1.86.183-2.227 0-3.465-1.128-1.23-1.135-1.23-3.178 0-2.007 1.208-3.157 1.216-1.15 3.34-1.15 1.934 0 3.09 1.165 1.165 1.164 1.165 3.112zm-2.695-.871q0-.747-.44-1.201-.431-.462-1.135-.462-.761 0-1.237.432-.476.425-.594 1.23z"
                                id="prefix__path1730"
                            />
                            <path
                                d="M90.791-5.97q-.344-.16-.688-.234-.337-.08-.682-.08-1.01 0-1.56.652-.542.644-.542 1.853V0h-2.622v-8.203h2.622v1.348q.506-.806 1.158-1.172.659-.374 1.574-.374.132 0 .286.015.154.007.447.044z"
                                id="prefix__path1732"
                            />
                            <path
                                d="M95.771-3.691q-.82 0-1.237.278-.41.278-.41.82 0 .498.33.784.336.278.93.278.739 0 1.244-.527.506-.535.506-1.333v-.3zm4.007-.99V0h-2.644v-1.216q-.528.747-1.187 1.091-.659.337-1.604.337-1.274 0-2.072-.74-.792-.746-.792-1.933 0-1.443.99-2.117.995-.673 3.12-.673h1.545v-.206q0-.622-.49-.908-.492-.293-1.532-.293-.842 0-1.567.169-.725.168-1.348.505v-2q.843-.204 1.692-.307.85-.11 1.7-.11 2.219 0 3.2.879.989.872.989 2.842z"
                                id="prefix__path1734"
                            />
                        </g>
                        <g transform="translate(631 311.641)" id="prefix__g48">
                            <path
                                d="M15.307 14.346a3.733 3.733 0 11-3.733-3.733 3.737 3.737 0 013.733 3.733zm7.841-5.3v10.6a2.56 2.56 0 01-2.56 2.56H2.56A2.56 2.56 0 010 19.648V9.045a2.56 2.56 0 012.56-2.56h3.148V5.6a2.239 2.239 0 012.24-2.24H15.2a2.239 2.239 0 012.24 2.24v.885h3.148a2.561 2.561 0 012.56 2.56zm-5.921 5.3A5.653 5.653 0 1011.574 20a5.659 5.659 0 005.653-5.654z"
                                id="prefix__path46"
                                fill="#2555ef"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default UseCameraSvg
