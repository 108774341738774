import Grid from '@material-ui/core/Grid';
import { InputField } from 'components';
import StripeCard from 'components/StripeCard/StripeCard';
import React from 'react';
import { Description, FormHeader, PayForm, PayWrapper, Title } from './Theme';

const PaymentDetails = ({ formik, isLoading,setIsLoading,addCardStripeRef,checkIsStripe }) => {
  

    const handleExpiryChange = (text) => {
        formik.setFieldValue('cardsDetails.expiry', text);
        if (text.length > 2) {
            if (text[2] !== '/') {
                formik.setFieldValue(
                    'cardsDetails.expiry',
                    text.substring(0, 2) + '/' + text.substring(2, 4)
                );
            }
        }
    };

    return (
        <PayWrapper>
            <FormHeader>
                <Title >Payment Details</Title>
                <Description>Please enter your details</Description>
            </FormHeader>
            <PayForm>
                {checkIsStripe() ? (
                    <Grid item xs={12}>
                        <StripeCard
                            loading={isLoading}
                            setLoading={setIsLoading}
                            saveCard={false}
                            ref={addCardStripeRef}
                        />
                   </Grid>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <div className="col-sm-12">

                                <InputField
                                    title="Card Number"
                                    mandatory
                                    type="string"
                                    minLength="16"
                                    maxLength="16"
                                    {...{
                                        name: 'cardsDetails.card_number',
                                        placeholder: 'Card Number',
                                        value: formik?.values?.cardsDetails.card_number,
                                    }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    handleChange={(e) => {
                                        formik.setFieldValue('cardsDetails.card_number', e?.target?.value);
                                    }}
                                    inputClassName="donation__input"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="col-sm-12">
                                <InputField
                                    title="Expiry"
                                    mandatory
                                    type="string"
                                    maxLength="7"
                                    {...{
                                        name: 'expiry',
                                        placeholder: 'Expiry (MM/YYYY)',
                                        value: formik.values?.cardsDetails.expiry,
                                    }}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    handleChange={(e) => handleExpiryChange(e?.target?.value)}
                                    inputClassName="donation__input"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField
                                mandatory
                                {...{
                                    formik,
                                    title: 'CVC',
                                    type: 'string',
                                    minlength: '4',
                                    maxLength: '4',
                                    name: `cardDetails.cvc`,
                                    placeholder: '3 or 4 Digits',
                                    value: formik.values?.cardsDetails.cvc,
                                }}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                handleChange={(e) => {
                                    formik.setFieldValue('cardsDetails.cvc', e?.target?.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </PayForm>
        </PayWrapper >
    );
};

export default PaymentDetails;