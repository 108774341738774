import * as React from "react"

function TombSvgComponent (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25.215}
            height={25.207}
            viewBox="0 0 25.215 25.207"
            {...props}
        >
            <g data-name="Group 1779">
                <g data-name="Group 1774">
                    <path
                        data-name="Path 26165"
                        d="M20.931 21.054H4.284V7.836A7.839 7.839 0 0112.123 0h.969a7.839 7.839 0 017.839 7.836z"
                        fill={props.fillColor}
                        opacity={0.28}
                    />
                </g>
                <g data-name="Group 1778" fill={props.fillColor}>
                    <g data-name="Group 1775">
                        <path
                            data-name="Path 26167"
                            d="M15.635 8.7H9.579a.739.739 0 010-1.478h6.056a.739.739 0 010 1.478z"
                        />
                        <path
                            data-name="Path 26168"
                            d="M15.635 7.222h-3.028V8.7h3.028a.739.739 0 100-1.478z"
                        />
                    </g>
                    <g data-name="Group 1776">
                        <path
                            data-name="Path 26169"
                            d="M15.635 11.655H9.579a.739.739 0 010-1.478h6.056a.739.739 0 010 1.478z"
                        />
                        <path
                            data-name="Path 26170"
                            d="M15.635 10.177h-3.028v1.478h3.028a.739.739 0 100-1.478z"
                        />
                    </g>
                    <g data-name="Group 1777">
                        <path
                            data-name="Path 26171"
                            d="M15.635 14.611H9.579a.739.739 0 010-1.478h6.056a.739.739 0 010 1.478z"
                        />
                        <path
                            data-name="Path 26172"
                            d="M15.635 13.133h-3.028v1.478h3.028a.739.739 0 100-1.478z"
                        />
                    </g>
                </g>
            </g>
            <g data-name="Group 1782">
                <g data-name="Group 1780">
                    <path
                        data-name="Path 26173"
                        d="M23.652 22.091H1.562v-2.39a.739.739 0 01.739-.739h20.612a.739.739 0 01.739.739z"
                        fill={props.fillColor}
                    />
                </g>
                <g data-name="Group 1781">
                    <path
                        data-name="Path 26175"
                        d="M25.215 25.208H0V22.83a.739.739 0 01.739-.739h23.737a.739.739 0 01.739.739z"
                        fill={props.fillColor}
                        opacity={0.28}
                    />
                </g>
            </g>
        </svg>
    )
}

export default TombSvgComponent
