import { useAuth0 } from "@auth0/auth0-react";
import { InputField } from "components";
import MyButton from "components/MyButton";
import StripeCard from "components/StripeCard/StripeCard";
import { postalCodeFormat, postalCodeLabel } from "helper/postalCodeFormat";
import AuthService from "Lib/API/Auth";
import React, { useEffect, useRef, useState } from "react";
import CreditCardInput from "react-credit-card-input";
import { toastr } from "react-redux-toastr";
export const AddCard = ({ ...props }) => {
  const addCardStripeRef = useRef(null);

  const [cardNumber, setCardNumber] = React.useState(null);
  const [expiryMonth, setExpiryMonth] = React.useState(null);
  const [expiryYear, setExpiryYear] = React.useState(null);
  const [cvc, setCvc] = React.useState(null);
  const [zip, setZip] = React.useState(null);
  const [holderName, setHolderName] = useState("");
  const [cardTitle, setCardTitle] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const [errros, setErrors] = useState("");
  const [addCardLoading, setAddCardLoading] = useState(false);
  let accountID = localStorage.getItem("accountID")
    ? localStorage.getItem("accountID")
    : "";

  const {provider_information} = localStorage.getItem("organization")
  ? JSON.parse(localStorage.getItem("organization"))
  : {};    
  const {provider} = provider_information || {};
  const {publicKey} = provider_information?.keys || {};

  const postalLabel = postalCodeLabel();
    
  const checkIsStripe = () => {
      return provider === "Stripe" && publicKey;
  };

  const handleSubmit = async (tokenId = "") => {
    const token = await getAccessTokenSilently();
    const cardDetails = {
      object: "card",
      number: cardNumber?.replace(/\s/g, ""),
      exp_month: expiryMonth,
      exp_year: expiryYear,
      cvc: cvc,
      zip: zip,
    };
    if (!checkIsStripe() && (!cardNumber ||
      !expiryMonth ||
      !expiryYear ||
      !cvc ||
      !zip)) {
        return toastr.error("Error", "All card details fields are required")
      } 
      const validZipCode = postalCodeFormat(zip);
      if (!checkIsStripe() && !validZipCode) {
        toastr.info("Validation", `Invalid ${postalLabel}`)
      }
  

      
    const bodyData = {};
    if(checkIsStripe() && tokenId){
      bodyData.token = tokenId;
    } else {
      bodyData.cardsDetails = cardDetails;
    }
    setAddCardLoading(true);
    await AuthService.addCard(bodyData, token, accountID)
      .then((res) => {
        if (res) {
          props.setOpenModal(false);
          props.setRefresh(!props.refresh);
          toastr.success("Success", "Card has been added successfully ");
        }
      })
      .catch((err) => {
        console.log(err, "err");
        toastr.error("Error", err?.data?.message ?? "Something went wrong.");
        setErrors(err?.data?.message);
      })
      .finally(() => {
        setAddCardLoading(false);
      });
  };

  useEffect(() => {
    console.log(cardNumber?.replace(/\s/g, ""), cardNumber, "chesting");
  }, [cardNumber]);


  const handleGetStripeToken = async () => {
    const tokenId = await addCardStripeRef?.current?.handleGetToken();
    if (tokenId && tokenId !== null) {
      handleSubmit(tokenId)
    }
  }

  const handleAddCard = async () => {
    if (checkIsStripe()) {
      handleGetStripeToken()
      return
    }
    handleSubmit()
  }

  return (
    <dv className="add-card">
      <div className="form-title">Add New Card</div>
      {checkIsStripe() ? (
              <StripeCard onSaveCard={handleAddCard}
                loading={addCardLoading}
                setLoading={setAddCardLoading}
                ref={addCardStripeRef}
              />
          ) : (
      <div className="add-card-form pb-10">
          <div className="row row-sm-12 pl-0 pt-10 ">
              <div className="col-sm-4 pr-0">
                <InputField
                  mandatory
                  {...{
                    title: "Card Number",
                    name: "cardNumber",
                    type: "string",
                    minlength: "16",
                    maxLength: "16",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  handleChange={(event) => setCardNumber(event.target.value)}
                />
              </div>
              <div className="col-sm-2 pr-0">
                <InputField
                  mandatory
                  {...{
                    title: "Expiry Month",
                    name: `exp_month`,
                    placeholder: "MM",
                    minlength: "1",
                    maxLength: "2",
                    type: "string",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  handleChange={(event) => setExpiryMonth(event.target.value)}
                />
              </div>
              <div className="col-sm-2 pr-0">
                <InputField
                  mandatory
                  {...{
                    title: "Expiry Year",
                    type: "string",
                    minlength: "4",
                    maxLength: "4",
                    name: `exp_year`,
                    placeholder: "YYYY",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  handleChange={(event) => setExpiryYear(event.target.value)}
                />
              </div>
              <div className="col-sm-2 pr-0">
                <InputField
                  mandatory
                  {...{
                    title: "CVC",
                    type: "string",
                    minlength: "3",
                    maxLength: "4",
                    name: `cvc`,
                    placeholder: "3-4 digits",
                    // value: formik.values?.cardDetails.cvc,
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  handleChange={(event) => setCvc(event.target.value)}
                />
              </div>
              <div className="col-sm-2 pr-0">
                <InputField
                  mandatory
                  {...{
                    title: postalLabel,
                    name: `zip`,
                    placeholder: postalLabel,
                    type: "string",
                  }}
                  handleChange={(event) => setZip(event.target.value)}
                />
              </div>
          </div>

      </div>
          )}
      <div className="add-button">
        <MyButton
          content={"Add Card"}
          style={{ width: "150px" }}
          onClick={() => handleAddCard()}
        />
      </div>
    </dv>
  );
};
