import { useAuth0 } from "@auth0/auth0-react";
import Action from "components/ActionPopOver";
import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import FinanceService from "../../../Lib/API/Finance";
// import { Data } from "@react-google-maps/api";
import { formatDate } from "helper/dateFormat";
import { numberFormat } from "helper/numberFormat";
import { DateFormetContext } from "../../../app/App.js";
import AlertDialog from "../../../components/Dialog";

export const Charge = ({ data, onReversalClick }) => {
  const dateFormet = React.useContext(DateFormetContext);
  const { getAccessTokenSilently } = useAuth0();
  const [reversalActionTypes, setReversalActionTypes] = useState(false);
  const [getTypes, setActionTypes] = useState([]);
  const fetchReversalTypes = async (type) => {
    const token = await getAccessTokenSilently();
    await FinanceService.getAllReversalTypes(type, token).then((res) => {
      setActionTypes(res?.result);
    }).catch((err) => {
      toastr.error('Error', err?.data?.message);
    })

    setReversalActionTypes(true);
  };
  const handleCloseDialog = () => {
    setReversalActionTypes(false);
  };
  const onChargeEditClick = (row) => {
    document.dispatchEvent(new CustomEvent("edit-charge", { detail: row }));
  };
  return (
    <div className="">
      <div className="drawer-header">
        <div className="cursor-pointer">
          <AlertDialog
            open={reversalActionTypes}
            getTypes={getTypes ?? []}
            handleClose={handleCloseDialog}
            onDecline={() => {
              setReversalActionTypes(false);
            }}
            onAccept={(id, type, hidden) =>
              onReversalClick(
                data?.charge?.status === "charge"
                  ? {
                      reversal_type: {
                        id: id,
                        type: type,
                        hidden: hidden,
                      },
                      chargeId: data.charge?._id,
                    }
                  : "",
                setReversalActionTypes
              )
            }
            content={"Choose a reversal type"}
          />
        </div>
      </div>
      <div className="drawer-title">Charge</div>
      <div className="drawer-content ">
        <div className="first-pr">
          <p className="drawer-row">
            <div className="">Total Amount</div>
            <div className="value">
              {numberFormat({number:data?.charge?.amount ?? data?.total_amount, decimal: 2, style: "currency"})}
            </div>
          </p>
          <p className="drawer-row">
            <div className="">Transaction#</div>
            <div className="value" title={data?._id}>
              {" "}
              {data._id ? data._id.slice(0, 5) + "..." : "-"}
            </div>
          </p>
          <p className="drawer-row">
            <div className="">Status</div>
            <div className="value">{data?.charge?.status}</div>
          </p>
        </div>
        <hr className="my-5" />
        <div className="second-pr">
          <>
            <div className="drawer-row pb-2">Ledger Account</div>
            <div className="form-control form-control-solid h-auto py-5 px-6">
              {data?.Ledger?.name} / {data?.SubLedger?.name ?? "N/A"}{" "}
            </div>
            <div className="drawer-row pt-2 pb-2">Due Date</div>
            <div className="form-control form-control-solid h-auto py-5 px-6">
              {formatDate({date:data?.charge?.due_date, formatType: "defaultWithUTC"})}
            </div>
            <div className="w-50">
              <div className="drawer-row pt-2 pb-2 ">Amount</div>
              <div className="form-control form-control-solid h-auto py-5 px-6">
                <div className="drawer-row">
                  <span>
                    {numberFormat({number:data?.charge?.amount ?? data?.total_amount, decimal: 2, style: "currency"})}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-50">
              <div className="drawer-row pt-2 pb-2">Discount</div>
              <div className="form-control form-control-solid h-auto py-5 px-6">
                <div className="drawer-row">
                  <span>
                    {data?.discount ?? 0}
                    {data?.discount_type}
                  </span>
                  <span></span>
                </div>
              </div>
            </div>
            <p className="drawer-row mt-5">
              <div className="">Total Amount</div>
              <div className="value">
                {numberFormat({number:data?.charge?.amount, decimal: 2, style: "currency"})}
              </div>
            </p>
          </>
        </div>
      </div>
    </div>
  );
};
