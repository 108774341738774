/* eslint-disable jsx-a11y/alt-text */
import { useAuth0 } from "@auth0/auth0-react";
import { Text } from "components";
import { screens } from "constants/enums";
import { toggleDrawer } from "pages/drawer/drawerSlice";
import React from "react";
import { Dropdown, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LogOutSvg from "../assets/icons/svg/logout";
// import LogoPNG from "assets/icons/logo.png";
// import SvgComponent from "assets/icons/svg/Search";
import { ReactComponent as SearchSVG } from "assets/media/svg/icons/General/Search.svg";
import "./style.css";
import "./styles.scss";

import UserSettingsComponent from "assets/icons/svg/settingsUser";

import { useHistory } from "react-router-dom";

const styles = {
  body: {
    zIndex: 1003,
    minHeight: 70,
  },
  imgBody: {
    borderRadius: "50%",
    background: "#B5B5C3",
    width: 49,
    height: 49,
    marginInlineEnd: 10,
    cursor: "pointer",
  },
  nameProfileText: {
    color: "#464E5F",
    fontWeight: 600,
    fontSize: 14,
  },
  userNameContainer: {
    marginTop: -5,
  },
  userNameProfileText: {
    color: "#B5B5C3",
    fontSize: 14,
    lineHeight: 1.2,
  },
  tagProfileText: {
    color: "#464E5F",
    fontWeight: 600,
    fontSize: 14,
  },
};
const originUrl = window.location.origin;
const companyData = JSON.parse(
  localStorage.getItem("getOrginasitionBySubDomain")
);
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className="btn p-0 d-flex align-items-center"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
  >
    <div className="px-3" style={{ textAlign: "start" }}>
      {children}
    </div>
    <div
      className="p-3"
      style={{ borderInlineStart: "1px solid #F3F6F9", height: "100%" }}
    >
      &#x25bc;
    </div>
  </div>
));

const MenuHeader = () => {
  const { logout, user } = useAuth0();
  const history = useHistory();

  return (
    <Dropdown className="dropdown-header">
      <Dropdown.Toggle variant="outline" as={CustomToggle}>
        <div className="d-flex">
          <div
            style={{
              ...styles.imgBody,
            }}
          >
            <img
              src={user.picture || "https://via.placeholder.com/150"}
              style={{
                ...styles.imgBody,
              }}
            />
          </div>
          <div className="pr-5 align-self-center">
            <div>
              <Text
                className="text-start"
                style={{ ...styles.nameProfileText }}
              >
                {user.nickname}
              </Text>
            </div>
            <div style={{ ...styles.userNameContainer }}>
              <Text style={{ ...styles.userNameProfileText }}>
                {user.email}
              </Text>
            </div>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu">
        <Dropdown.Item
          onClick={() => {
            history.push("/settings");
          }}
        >
          <UserSettingsComponent style={{ marginRight: 15 }} />

          Settings
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => {
          logout({ returnTo: originUrl });
          localStorage.removeItem("token");
          localStorage.removeItem("hasPayment");
        }
        }>
          <LogOutSvg style={{ marginRight: 15 }} /> Logout
        </Dropdown.Item>
        {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export const Header = ({ style, ...rest }) => {
  const { isAuthenticated } = useAuth0();
  console.log('isAuthenticated :', isAuthenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  const localLogoImage = localStorage.getItem("logoImg");
  const {logoImage} = useSelector((state) => state.organization);
  const location = useLocation();
  const handelTogggleDrawer = () => {
    dispatch(toggleDrawer({ type: "left" }));
  };

  if (
    !isAuthenticated ||
    (!location.pathname.includes(screens.HOME) &&
      !location.pathname.includes(screens.ADD_NEW_MEMBER) &&
      !location.pathname.includes(screens.YAHRZEIT) &&
      !location.pathname.includes(screens.ADD_NEW_YAHRZEIT) &&
      !location.pathname.includes(screens.FINANCE) &&
      !location.pathname.includes(screens.EVENTS) &&
      !location.pathname.includes(screens.DONATION) &&
      !location.pathname.includes(screens.DIRECTORY) &&
      !location.pathname.includes(screens.SCHOOLS) &&
      !location.pathname.includes(screens.HELP) &&
      !location.pathname.includes(screens.FAMILYMEMBERSTAB) &&
      !location.pathname.includes(screens.SETTINGS)) && 
      !location.pathname.includes(screens.CLASSES)
  )
    return null;

  return (
    <header className="position-sticky top-0" style={{ zIndex: 101 }}>
    
      <nav
        className="navbar navbar-expand-lg navbar-light px-sm-4 px-xs-1 py-sm-2 bg-white"
        style={{ ...style, ...styles.body }}
        {...rest}
      >
        <div className="row d-flex align-items-center m-0 w-100">
          <div className="d-flex align-items-center d-block d-sm-none">
            <button
              onClick={handelTogggleDrawer}
              className="btn p-0 burger-icon burger-icon-left"
              id="kt_aside_mobile_toggle"
            >
              <span />
            </button>
          </div>
          <div
            className="d-none d-sm-block"
            style={{ marginInlineStart: 15, marginInlineEnd: 80 }}
          >
            <Image
              src={
                logoImage !== undefined ? logoImage : localLogoImage || ""
              }
              style={{ width:130, height: 50, cursor: "pointer" }}
              onClick={() => history.push("home")}
            />
          </div>

          {/* SEARCH BAR  
          <div className="input-icon h-40px d-none d-sm-block">
            <input
              type="text"
              className="form-control form-control-lg form-control-solid h-40px"
              placeholder="Search"
              id="generalSearch"
              autoComplete="false"
            />
            <span>
              <span className="svg-icon svg-icon-lg">
                <SearchSVG />
              </span>
            </span>
          </div> */}
          <div className=" dropdown-container">
            <MenuHeader />
          </div>
        </div>
      </nav>
    </header>
  );
};
