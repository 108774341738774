import React from "react";
import ComingSoon from "components/ComingSoon";
import HelpPeopleComponent from "assets/icons/svg/helpPeople";
import { withUserExist } from "../CustomHooks";

const Help = () => {
    return (
        <ComingSoon icon={<HelpPeopleComponent />} title={"Help"} />
    )
}

export default withUserExist(Help)