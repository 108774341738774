import React from 'react';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import './style.scss';
import StepButton from '@mui/material/StepButton';
import {
  makeStyles
} from "@material-ui/core";
const MemberStepper = ({ activeStep, steps, completed, handleStep }) => {
  const useStyles = makeStyles(() => ({
    root: {
      "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root": {
        width: 31, height: 31, color: "#9E9E9E", borderRadius: "50%"

      },

      "& .css-117w1su-MuiStepIcon-text": {
        fill: "#FFFFFF",
        fontWeight: 'normal',
        fontSize: ' 15px',
        lineHeight: 22,

      },
      "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": { color: "#2555EF", border: "none" },
      "& .css-1hv8oq8-MuiStepLabel-label": {
        fontStyle: 'normal',
        fontWeight: 100,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#9E9E9E"
      },
      "& .css-1hv8oq8-MuiStepLabel-label.Mui-active": {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#2555EF"
      },

    }
  }));

  const style = useStyles();
  return (
    <Stepper activeStep={activeStep} className={style.root} sx={{
      // '& .MuiSvgIcon-root-MuiStepIcon-root': {
      //     fill: '#FFFFFF',
      // },
      // '& .MuiStepLabel-root .MuiStepIcon-text': {
      //     fill: '#D9D9D9',
      // },

      '& .MuiStepLabel-root .Mui-completed': {
        color: '#2555EF', // circle color (COMPLETED)
      },
      // '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
      // {
      //     color: 'grey.500', // Just text label (COMPLETED)
      // },
      // '& .MuiStepLabel-root .Mui-active': {
      //     color: "#D9D9D9", border: "none", // circle color (ACTIVE)
      // },
      '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
      {
        color: '#2555EF', // Just text label (ACTIVE)
      },
      '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
        fill: '#FFFFFF', // circle's number (ACTIVE)
        fontWeight: 700,
      },


    }}>
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepButton color="red" onClick={handleStep(index)}>
            {label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default MemberStepper;
