export const screens = {
  HOME: "home",
  ADD_NEW_MEMBER: "add-new-member",
  YAHRZEIT: "yahrzeit",
  ADD_NEW_YAHRZEIT: "add-new-yahrzeit",
  FINANCE: "finance",
  EVENTS: "events-view",
  DONATION: "donation",
  DIRECTORY: "directory",
  SCHOOLS: "schools",
  HELP: "help",
  FAMILYMEMBERSTAB: "family-member-table",
  SETTINGS: "settings",
  CLASSES: "classes",
};

export const variant = {
  DEFAULT: "default",
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
};

export const OverDueDaysCalculator = {
  day: 1,
  week: 7,
  month: 30,
  quarter: 182,
  year: 365,
};
