import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    step_one: {
        first_name: "",
        middle_name: "",
        last_name: "",
        hebrew_name: "",
        title: "",
    },
    step_two: {
        profile_img: "",
        gender: "",
        date_of_birth: "",
        phone_number: "",
        email_address: "",
        image_name: "",
    },
    step_three: {
        pronounce: "",
        occupation: "",
        address: "",
        city: "",
        zip_code: "",
        appear_in_members_directory: false,
    },
};

export const stepForms = createSlice({
    name: "formSteps",
    initialState,
    reducers: {
        setStepOne: (state, action) => {
            state.step_one = action.payload;
            return state;
        },
        setStepTwo: (state, action) => {
            state.step_two = action.payload;
        },
        setStepThree: (state, action) => {
            state.step_three = action.payload;
        },
        reset: (state) => initialState
    },
});

export const { reset, setStepOne, setStepTwo, setStepThree } = stepForms.actions;

export default stepForms.reducer;
