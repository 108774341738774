import React, { memo } from "react";
import { HintField, LabelField } from "components";

export const TextareaField = memo(
  ({
    title,
    name,
    style,
    type,
    rules,
    error,
    labelFieldClass,
    formik,
    checkValidation = false,
    mandatory = false,
    ...rest
  }) => {
    const getInputClasses = () => {
      if (!checkValidation) {
        return "";
      }

      if (formik.touched[name] && formik.errors[name]) {
        return "is-invalid";
      }

      if (formik.touched[name] && !formik.errors[name]) {
        return "is-valid";
      }

      return "";
    };

    return (
      <div className="form-group fv-plugins-icon-container">
        {title && <LabelField htmlFor={name} title={title} mandatory={mandatory} labelClassName={labelFieldClass} />}
        <textarea
          id={name}
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses()}`}
          {...rest}
          {...formik.getFieldProps(name)}
        />
        {formik.touched[name] && formik.errors[name] ? (
          <HintField error={formik.errors[name]} />
        ) : null}
      </div>
    );
  }
);
