import React from "react";
import PopOver from "../components/Popover";
import FinancePopover from "./FinancePopover";

const Action = ({
  action,
  setReceiptFile,
  setInvoiceFile,
  setReceiptDownload,
  setInvoiceDownload,
  onEditClick,
  onViewClick,
  onDeleteClick,
  checkActive,
  onActiveClick,
  style,
  id,
  status,
  page,
  handleClick,
  onEditNowClick,
  generateExcel,
  row,
  onEdit,
  Unbatch,
  Undeposit,
  generatePdf,
  check,
  actionDrawer,
  rowId,
  onChargeEditClick,
  deleteFilter,
  item,
  setDuolicatetModal,
  setDuolicatetData,
  generateExcelReport,
  handleDownloadPdf,
  setExpandRow,
  setOpen,
  setShareInvoicePDF,
  setShareReceiptPDF,
  handleRunReport,
  toggle,
  onPayClick,
  chargeStatus,
  mainChargeStatus,
  hideInvoiceButtons,
  hideReceiptButtons,
  ...rest
}) => {
  return (
    <>
      {!action ? (
        <PopOver
          style={style}
          onEditClick={onEditClick}
          onViewClick={onViewClick}
          onDeleteClick={onDeleteClick}
          checkActive={checkActive}
          onActiveClick={onActiveClick}
          {...rest}
        />
      ) : action === "finance" ? (
        <FinancePopover
          style={style}
          {...rest}
          action={action}
          row={row}
          setReceiptFile={setReceiptFile}
          setShareInvoicePDF={setShareInvoicePDF}
          setShareReceiptPDF={setShareReceiptPDF}
          onChargeEditClick={onChargeEditClick}
          setReceiptDownload={setReceiptDownload}
          setInvoiceDownload={setInvoiceDownload}
          setInvoiceFile={setInvoiceFile}
          actionDrawer={actionDrawer}
          rowId={rowId}
          onPayClick={onPayClick}
          setExpandRow={setExpandRow}
          chargeStatus={chargeStatus}
          mainChargeStatus={mainChargeStatus}
          hideInvoiceButtons={hideInvoiceButtons}
          hideReceiptButtons={hideReceiptButtons}
        />
      ) : action === "tribute" ? (
        <FinancePopover
          style={style}
          {...rest}
          action={action}
          row={row}
          onEditNowClick={onEditClick}
          onViewClick={onViewClick}
        />
      ) : action === "BatchBilling" ? (
        <FinancePopover
          style={style}
          {...rest}
          action={action}
          id={id}
          status={status}
          page={page}
          setOpen={setOpen}
          handleClick={handleClick}
          onEditNowClick={onEditNowClick}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Action;
