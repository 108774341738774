import { useAuth0 } from "@auth0/auth0-react";
import Popover from "@material-ui/core/Popover";
import { ReactComponent as Download } from "assets/icons/DownloadIcon.svg";
import { ReactComponent as Visibilit } from "assets/icons/eyeIcon.svg";
import { ReactComponent as Invoice } from "assets/icons/invoice.svg";
import { ReactComponent as PayNowSvg } from "assets/icons/Property.svg";
import { ReactComponent as Reciept } from "assets/icons/receipt.svg";
import { ReactComponent as ReversalSvg } from "assets/icons/reversal.svg";
import EditSvgComponent from "assets/icons/svg/Edit/index";
import EyeSvgComponent from "assets/icons/svg/EyeSvg/Eye";
import MyButton from "components/MyButton";
import FinanceService from "Lib/API/Finance";
import React from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router";
import exportImg, { ReactComponent as ShareSvgComponent } from "../assets/icons/Share.svg";
import ActionSvgComponent from "../assets/icons/svg/actionSvg";
import { IconButton } from "./IconButton";
import MyModal from "./MyModal";
import "./style.scss";
// import { showBatchBillingTable } from "../store/redux/actions";

export default function FinancePopover({
  onEdit,
  setInvoiceFile,
  setShareInvoicePDF,
  setShareReceiptPDF,
  setReceiptFile,
  setInvoiceDownload,
  setReceiptDownload,
  onViewClick,
  onPayClick,
  onChargeEditClick,
  onReversalClick,
  style,
  action,
  id: batch_id,
  status,
  page,
  handleClick,
  onEditNowClick,
  actionDrawer,
  rowId,
  row,
  setExpandRow,
  setOpen,
  chargeStatus,
  mainChargeStatus,
  hideInvoiceButtons,
  hideReceiptButtons,
}) {
  const { push } = useHistory();
  const dispatch = useDispatch();
  console.log(mainChargeStatus, "mainChargeStatus");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { getAccessTokenSilently } = useAuth0();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onCompleteBatchPayment = async () => {
    const token = await getAccessTokenSilently();
    FinanceService.completeBatchPayment(batch_id, token).then((res) => {
      handleClose();
      toastr.success("Success", "Successfully Completed");
      window.dispatchEvent(new CustomEvent("refresh"));
    });
  };

  const onCloneBatchPayment = async () => {
    const token = await getAccessTokenSilently();
    FinanceService.cloneBatchPayment(batch_id, token).then((res) => {
      handleClose();
      toastr.success("Success", "Successfully Cloned");
      window.dispatchEvent(new CustomEvent("refresh"));
    });
  };
  const onPauseBatchPayment = async () => {
    const token = await getAccessTokenSilently();
    FinanceService.pauseBatchPayment(batch_id, token).then((res) => {
      handleClose();
      toastr.success("Success", "Successfully Paused");

      window.dispatchEvent(new CustomEvent("refresh"));
    });
  };
  const onDeleteBatchPayment = async () => {
    const token = await getAccessTokenSilently();
    FinanceService.deleteBatchPayment(batch_id, token).then((res) => {
      handleClose();
      toastr.success("Success", "Successfully Deleted");
      window.dispatchEvent(new CustomEvent("refresh"));
    });
  };
  return (
    <div>
      <ActionSvgComponent
        height={style ? style.height : 14}
        width={style ? style.width : 14}
        onClick={handleOnClick}
        color={style && style.color}
      />
      {action === "finance" ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {actionDrawer === "drawer" && row?.charge?.status === "payment" && (
            <>
              <p className="drawer-row my-2 mx-6 d-flex py-3">
                <Reciept className="" />
                <div className="mr-4 pr-4 mt-2">Receipt</div>
                {!hideReceiptButtons && (
                <div className="d-flex">
                  <Visibilit
                    className="cursor-pointer mx-2"
                    onClick={setReceiptFile}
                  />
                  <Download
                    className="cursor-pointer mx-2"
                    onClick={setReceiptDownload}
                  />

                </div>
                )}
              </p>
              <p className="drawer-row my-2 mx-6 d-flex py-3">
                <Invoice />

                <div className="mr-4 pr-4 mt-2">Invoice</div>
                {!hideInvoiceButtons && (
                <div className="d-flex">
                  <Visibilit
                    className="cursor-pointer mx-2"
                    onClick={setInvoiceFile}
                  />
                  <Download
                    className="cursor-pointer mx-2"
                    onClick={setInvoiceDownload}
                  />

                </div>
                )}
              </p>
            </>
          ) }
          <div className="action-pop-over">
            {onPayClick ? (
              mainChargeStatus !== "payment" ? (
                <div
                  className={
                    chargeStatus === "close"
                      ? "disabled-action-payNow finance-pop-over"
                      : "finance-pop-over"
                  }
                  onClick={() => {
                    onPayClick();
                    handleClose();
                  }}
                >
                  <MyButton
                    disabled={chargeStatus === "close" ? true : false}
                    buttonTextStyle={{ margin: 0 }}
                    content={
                      <>
                        <PayNowSvg /> <span className="">Pay Now</span>
                      </>
                    }
                  />
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          {/* <div className="action-pop-over">
            {onReversalClick &&
            row?.charge?.sub_status !== "reversal" &&
            rowId !== true &&
            row?.charge?.payment?.status !== "reversal" ? (
              <div
                className="reversal-pop-over pl-0 mr-5"
                onClick={() => {
                  onReversalClick();
                  handleClose();
                }}
              >
                <ReversalSvg /> Reversal
              </div>
            ) : (
              ""
            )}
          </div> */}
        </Popover>
      ) : action === "tribute" ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className="billing-action-pop-over eye">
            <div className="finance-pop-over" onClick={onViewClick}>
              <EyeSvgComponent className="mr-6" /> View
            </div>
          </div>

          <div className="billing-action-pop-over">
            <div className="finance-pop-over" onClick={onEditNowClick}>
              <EditSvgComponent className="mr-6" /> Edit
            </div>
          </div>

          {/* <div className="action-pop-over">
            <div
              className="finance-pop-over p-0"
              // onClick={() => {
              //   onReversalClick();
              //   handleClose();
              // }}
            >
              <span className="add-icon">
                <AddIcon />
              </span>{" "}
              Edit
            </div>
          </div> */}
        </Popover>
      ) : (
        <div>
          {" "}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {status === "draft" ? null : (
              <div className="billing-action-pop-over">
                <div
                  className="finance-pop-over"
                  onClick={() => {
                    handleClick("Batch Billing", batch_id);
                    // dispatch(showBatchBillingTable(true));
                    setOpen(true);
                    handleClose();
                  }}
                >
                  View Transactions
                </div>
              </div>
            )}
            {status === "draft" ? (
              <div className="billing-action-pop-over">
                <div className="finance-pop-over " onClick={onEditNowClick}>
                  Edit
                </div>
              </div>
            ) : null}
            <div className="billing-action-pop-over">
              <div className="finance-pop-over " onClick={onCloneBatchPayment}>
                Clone
              </div>
            </div>
            {status === "completed" ? (
              <div className="billing-action-pop-over">
                <div
                  className="finance-pop-over "
                  onClick={onPauseBatchPayment}
                >
                  Pause
                </div>
              </div>
            ) : null}
            {status === "completed" || status === "draft" ? null : (
              <div className="billing-action-pop-over">
                <div
                  className="finance-pop-over "
                  onClick={onCompleteBatchPayment}
                >
                  Complete
                </div>
              </div>
            )}
            {status === "completed" || status === "paused" ? null : (
              <div className="billing-action-pop-over">
                <div
                  className="finance-pop-over "
                  style={{ color: "red" }}
                  onClick={onDeleteBatchPayment}
                >
                  Delete
                </div>
              </div>
            )}
          </Popover>
        </div>
      )}
    </div>
  );
}
