import Service from "../../service";

const AuthService = {
  getAccountSetting: async (token, Linked_contact) => {
    try {
      return await Service.get(`/accounts/contact/${Linked_contact}`, token);
    } catch (error) {
      throw error;
    }
  },

  updateAccountSetting: async (data, token) => {
    try {
      return await Service.update({ url: `/accounts/contact`, data, token });
    } catch (error) {
      throw error;
    }
  },
  updateAccount: async (data, token) => {
    try {
      return await Service.update({ url: `/accounts/account`, data, token });
    } catch (error) {
      throw error;
    }
  },
  getUserExist: async (token) => {
    try {
      return await Service.get(`/accounts/user/checkaccount`, token);
    } catch (error) {
      throw error;
    }
  },

  postFirstStep: async (data, token) => {
    try {
      return await Service.post({ url: "/accounts/setup", data, token });
    } catch (error) {
      throw error;
    }
  },
  updateStep: async (data, token) => {
    try {
      return await Service.update({ url: "/accounts/setup", data, token });
    } catch (error) {
      throw error;
    }
  },

  //handle Family members
  addNewMember: async (data, token) => {
    try {
      return await Service.post({
        url: "/accounts/contact",
        data,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  // getFamilyMember: async (token, accountID) => {
  //   try {
  //     return await Service.get(`/accounts/account/${accountID}`, token);
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  getAllFamilyMember: async (token) => {
    try {
      return await Service.get(`/accounts/contact`, token);
    } catch (error) {
      throw error;
    }
  },
  getFamilyMember: async (page, size, token) => {
    try {
      return await Service.get(
        `accounts/contact?page=${page}&size=${size}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  postAddFamilyNewMember: async (data, token) => {
    try {
      return await Service.post({ url: "/accounts/contact/", data, token });
    } catch (error) {
      throw error;
    }
  },
  updateMember: async (data, token) => {
    try {
      return await Service.update({ url: "/accounts/contact", data, token });
    } catch (error) {
      throw error;
    }
  },
  removeMember: async (data, token) => {
    try {
      return await Service.remove(`/accounts/contact`, data, token);
    } catch (error) {
      throw error;
    }
  },
  getExportDeceased: async (token) => {
    try {
      return await Service.get(`accounts/deceased/export/deceased`, token);
    } catch (error) {
      throw error;
    }
  },

  //handle Deceased
  getDeceased: async (page, size, sort, token) => {
    try {
      return await Service.get(
        `/accounts/deceased?page=${page}&size=${size}${sort}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  getFilterdDeceased: async (page, size, filters, sortBy, token) => {
    try {
      return await Service.post({
        url: `/accounts/deceased/list/deceased?page=${page}&size=${size}&filter=${sortBy}`,
        data: filters,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  postAddDeceased: async (data, token) => {
    try {
      return await Service.post({ url: "/accounts/deceased", data, token });
    } catch (error) {
      throw error;
    }
  },
  updateDeceased: async (data, token) => {
    try {
      return await Service.update({ url: "/accounts/deceased/", data, token });
    } catch (error) {
      throw error;
    }
  },
  removeDeceased: async (data, token) => {
    try {
      return await Service.remove(`/accounts/deceased/`, data, token);
    } catch (error) {
      throw error;
    }
  },
  getClasses: async (token) => {
    try {
      return await Service.get("/schools/class", token);
    } catch (error) {
      throw error;
    }
  },
  getClassById: async (token, id) => {
    try {
      return await Service.get(`/schools/class/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  sendFormData: async (token, data) => {
    try {
      return await Service.post({ url: "/schools/participant", token, data });
    } catch (error) {
      throw error;
    }
  },
  getFormType: async (token, data) => {
    try {
      return await Service.get("/schools/form/field", token);
    } catch (error) {
      throw error;
    }
  },
  getArchiveClas: async (token, data) => {
    try {
      return await Service.get("schools/class/archive/notarchive", token);
    } catch (error) {
      throw error;
    }
  },
  getOrganisationByKey: async (key) => {
    try {
      return await Service.get(`public/orgkey/${key}`);
    } catch (error) {
      throw error;
    }
  },
  getClassesByAccount: async (token) => {
    try {
      return await Service.get(`/schools/class/accountclass`, token);
    } catch (error) {
      throw error;
    }
  },
  getClassesParticipants: async (id, token) => {
    try {
      return await Service.get(`/schools/participant/account/${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  //////////////////////////////////FINANCE//////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  getFilterdPaymentRequests: async (data, token) => {
    try {
      return await Service.post({ url: `/finance/paymentreq`, data, token });
    } catch (error) {
      throw error;
    }
  },
  addCard: async (bodyData, token, account_id) => {
    try {
      return await Service.post({
        url: `finance/v1/payment/card?accountId=${account_id}`,
        token,
        data:bodyData,
      });
    } catch (error) {
      throw error;
    }
  },
  getCards: async (token) => {
    try {
      return await Service.get("/finance/billing/cards", token);
    } catch (error) {
      throw error;
    }
  },
  getPaymentreq: async (token) => {
    try {
      return await Service.get(
        `/finance/paymentreq?page=${1}&size=${1000}`,
        token
      );
    } catch (error) {
      throw error;
    }
  },
  pay: async (data, token) => {
    try {
      return await Service.post({ url: `/finance/billing/pay`, data, token });
    } catch (error) {
      throw error;
    }
  },

  getDeceasedById: async (id, token) => {
    try {
      return await Service.post({ url: `/accounts/deceased/${id}`, token });
    } catch (error) {
      throw error;
    }
  },
  // api to generate token with params
  generateToken: async (param) => {
    try {
      return await Service.get(`/accounts/org/orgkey/${param}`);
    } catch (error) {
      throw error;
    }
  },
  // Directory api's

  getDirectoryList: async (token, current_page, items_per_page, body = null) => {
    try {
      return await Service.post({
        url: `/accounts/account/directory?current_page=${current_page}&items_per_page=${items_per_page}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
};


export default AuthService;
