import { useEffect, useState } from 'react';

export const useMobileView = () => {
    const [isMobile, setisMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth > 600) {
            setisMobile(false);
        } else if (window.innerWidth < 600) {
            setisMobile(true);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return (_) => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return { isMobile };
};
