import React, { memo } from "react";
import { Loader } from "components";


const styles = {
  body: {
    backgroundColor: '#2555EF',
    borderRadius: 5,
    height: 43,
    color: 'white',
    width: '122px'
  },
};
export const Button = memo(
  ({ style, disabled, loading, children, ...rest }) => {
    return (
      <button
        className="btn"
        style={{
          ...styles.body,
          ...style,
        }}
        {...rest}
        disabled={loading || disabled}
      >
        {loading ? <Loader height={10} color="#fff" /> : children}
      </button>
    );
  }
);
