import { useAuth0 } from "@auth0/auth0-react";
import { Box, Step, StepLabel, Stepper } from "@material-ui/core";
import { Text } from "components";
import MyButton from "components/MyButton";
import { useFormik } from "formik";
import { FormikWizard } from "formik-wizard-form";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import FinanceService from "../../../Lib/API/Finance";
import { DateFormetContext } from "../../../app/App.js";
import UserContext from "../NewChargeContext";
import ChargeDetails from "./ChargeDetails";
import PaymentInformation from "./PaymentInformation";
import Review from "./Review";
import "./index.scss";
import NewChargeSchema from "./validationSchema";
const NewCharge = ({
  setStepOne,
  setStepper,
  activeIndex,
  handleClose,
  data,
  setBatchList,
  batchList,
  ...props
}) => {
  const dateFormet = React.useContext(DateFormetContext);
  const [/* finalValues */ setFinalValues] = useState({});
  const { hfinish, setHFinish } = useContext(UserContext);
  const [title, setTitle] = useState("New Charge");
  const [transactionIds, setTransactionIds] = useState([]);
  const [chargeId, setChargeId] = useState("");
  const [paidAmount, setPaidAmount] = useState(false);
  const [inputValue, setInputValues] = useState("");
  const [disable, setDisabled] = useState(true);
  const [creditOnAccountAmount, setCreditOnAccountAmount] = useState(0);

  const [btnContent, setBtnContent] = useState("llll");
  const { getAccessTokenSilently } = useAuth0();
  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: {},
    validationSchema: "",
    onSubmit: async (values) => {},
  });

  const sumOfAmounts = data?.allData?.reduce((a, b) => {
    return a + b.total_amount;
  }, 0);

  const initialValues = data.hasOwnProperty("_id")
    ? {
        values: [
          {
            Ledger: data?.Ledger ?? {},
            SubLedger:
              data.SubLedger ?? formik.values?.values?.[0]?.SubLedger?.id,
            discount: data?.discount ?? 0,
            discount_type: data?.discount_type ?? "%",
            due_date: data?.charge?.due_date,
            charge: data?.charge,
            is_splitted: data?.is_splitted ?? false,
            payment_date: data.createdAt,
            tax: data.tax_deductible ?? 0,
            tax_type: data.tax_type ?? "%",
            charge_type: data.charge_type,
            total_amount: data.total_amount,
            split_duration: data?.split_duration,
            split_amount: data?.charge?.amount,
            number_of_split: formik?.values?.number_of_split,
          },
        ],
        actual_total: data.total_amount,
        current_amount: data.total_amount,
        account_name: data.Account,
        isTransactionSave: true,
        Ledger: data?.Ledger ?? {},
        SubLedger: data.SubLedger ?? formik.values?.values?.[0]?.SubLedger?.id,
        checks: [],
        type: data.type,
        amount_due: data?.Charges?.amount_due,
        note: data?.comment_invoice?.note,
        comment_checkbox: data?.comment_invoice?.visible,
        receipt_note: data?.comment_receipt?.note,
        comment_receipt: data?.comment_receipt?.visible,
      }
    : data?.allData
    ? {
        allData: data?.allData,
        chargesID: data?.allData?.map((item) => {
          return item?._id;
        }),
        accountIds: data?.allData[0]?.Account?.id,

        actual_total: sumOfAmounts,
        current_amount: sumOfAmounts,
        account_name: data.Account,
        isTransactionSave: true,
        receipt_note: data?.comment_receipt?.note,
        comment_receipt: data?.comment_receipt?.visible,
        note: data?.comment_invoice?.note,
        comment_checkbox: data?.comment_invoice?.visible,
        checks: [],
        values: [
          {
            Ledger: data.Ledger ?? formik.values?.values?.[0]?.Ledger?.id,
            SubLedger:
              data.SubLedger ?? formik.values?.values?.[0]?.SubLedger?.id,
            discount: data?.discount ?? 0,
            discount_type: data?.discount_type ?? "%",
            due_date: data?.charge?.due_date,
            charge: data?.charge,
            is_splitted: data?.is_splitted ?? false,
            payment_date: data.createdAt,
            tax: data.tax ?? 0,
            tax_type: data.tax_type ?? "%",
            charge_type: data.charge_type,
            total_amount: data.total_amount,
            split_duration: data?.split_duration,
            split_amount: data?.charge?.amount,
            number_of_split: formik?.values?.number_of_split,
          },
        ],
      }
    : {
        payments: [],
        paymentMethods: [],
        nextPayment: false,

        values: [
          {
            tax_type: "%",
            discount_type: "%",
            payment_date: moment(new Date())
              .utcOffset("+0100")
              .format("YYYY-MM-DD"),
            is_splitted: false,
            SubLedger: "",
            SubLedgerName: "",
            charge_type: "charge",
          },
        ],
        checks: [],
      };
  /**
   *
   * @param {Record<any, any>} values
   * @param {() => void} handleNext
   */

  useEffect(() => {
    if (data) {
      if (data?._id) setTransactionIds([data._id]);
      setChargeId(data?.charge?._id);
    }
  }, [data]);

  useEffect(() => {
    getCreditOnAccountAmount(
      data.Account?.id ||
        formik?.values?.accountIds ||
        data?.allData[0]?.Account?.id
    );
    console.log(data, "account-id");
  }, []);
  const getCreditOnAccountAmount = async (id) => {
    const token = await getAccessTokenSilently();
    await FinanceService.getCreditOnAccountAmount(id, token).then((res) => {
      setCreditOnAccountAmount(res?.result?.balance);
    }).catch((err) => {
      toastr.error('Error', err?.data?.message);
    });
  };

  const onStepClick = (values, handleNext, setFieldValue, currentStep) => {
    const { account_name, values: transactions, checks } = values;

    const enableHandleNext = transactions?.every((item) => {
      const {
        Ledger,
        due_date,
        payment_date,
        total_amount,
        charge_type,
      } = item;
      return !!(
        Ledger &&
        (charge_type === "credit_on_account" ||
          charge_type === "credit on account" ||
          due_date) &&
        payment_date &&
        total_amount
      );
    });

    if (values?.require_notes === true) {
      if (values?.type_of_charge === "charge" && !values?.note) {
        return toastr.info(
          "Validation",
          "Please fill the required note fields "
        );
      }
    }
    if (values?.onAccountCredit_payment > values?.creditOnAccountAmount) {
      return toastr.info(
        "Validation",
        "Amount must be less than or equal to wallet balance"
      );
    }
    if (values?.require_notes === true) {
      if (
        (values?.type_of_charge == "credit_on_account" ||
          values?.type_of_charge == "credit on account") &&
        !values?.values?.[0]?.note
      ) {
        return toastr.info(
          "Validation",
          "Please fill the required note fields "
        );
      }
    }
    if (values?.values?.[0]?.total_amount === 0) {
      return toastr.info("Validation", "Amount will not be 0");
    }
    if (
      values?.values?.[0]?.discount_type === "%" &&
      values?.values?.[0]?.discount > 100
    ) {
      return toastr.info("Validation", "Discount cannot be more than 100%");
    }
    if (
      values?.values?.[0]?.tax_type === "%" &&
      values?.values?.[0]?.tax > 100
    ) {
      return toastr.info("Validation", "Tax cannot be more than 100%");
    }
    if (
      values?.values?.[0]?.discount_type === "$" &&
      values?.values?.[0]?.discount > values?.values?.[0]?.total_amount
    ) {
      return toastr.info("Validation", "Discount must be smaller than amount");
    }

    if (values?.allData) {
      return handleNext();
    }
    if (account_name && enableHandleNext) {
      if (!values?.isTransactionSave) {
        saveTransaction(
          {
            ...values,
            transactions: values.values,
          },
          setFieldValue
        );
      }
      if (checks.length > 0) {
        const checkValidate = checks.every((item) => {
          const { amount, check_date, check_number } = item;
          return !!(amount && check_date && check_number);
        });
        if (currentStep === 1 && !checkValidate) {
          return toastr.info(
            "Validation",
            "Please fill all the required fields in check payment method"
          );
        }
      }
      const methods = Object.entries(values)
        .filter(([key]) => key.includes("_payment"))
        .some(([, value]) => !!value);

      if (
        currentStep === 1 &&
        values?.checks?.length === 0 &&
        !values?.creditCardAmount &&
        !methods &&
        !values?.selectedACH
      ) {
        return toastr.info(
          "Validation",
          "Please select at least one payment method"
        );
      }
      if (
        currentStep === 1 &&
        values?.checks?.length === 0 &&
        !values?.creditCardAmount &&
        !methods
      ) {
        return toastr.info(
          "Validation",
          "Please select at least one payment method"
        );
      }

      if (currentStep === 1 && values?.payments?.length) {
        const creditCardSelected = values?.payments?.filter((item) =>
          item.paymentMethod.includes("credit")
        );
        if (creditCardSelected.length) {
          const isCvcAttached = values.cvc?.length === 3;
          const creditAmount = values.creditCardAmount;
          let hasErrors = false;
          if (!creditAmount) {
            toastr.info("Validation", "Please enter amount for credit card");
            hasErrors = true;
          }
          // if (!isCvcAttached) {
          //   toastr.info(
          //     "Validation",
          //     "CVC must be 3 digits and is required for payment through credit card"
          //   );
          //   hasErrors = true;
          // }
          if (hasErrors) {
            return;
          }
        }
      }

      if (
        currentStep === 1 &&
        values?.is_third_party &&
        values?.third_party?.name === values?.account_name?.name
      ) {
        return toastr.info(
          "Validation",
          "3rd party account will not be same as account name"
        );
      }

      handleNext();
    } else {
      toastr.info("Validation", "Please fill all the required ");
    }
  };
  useEffect(() => {
    onStepClickvalues(inputValue, 1);
  });

  const saveTransaction = (values, setFieldValue, currentStepIndex) => {
    const subledger = values?.values?.map((item) => {
      return item?.SubLedger;
    })[0];

    async function fetchMyAPI() {
      const data = values.transactions?.map((item) => {
        let baseObj = {
          Account: values.account_name,
          Ledger: item.Ledger,
          charge_type:
            item.charge_type == "credit on account"
              ? "credit_on_account"
              : item.charge_type || "charge",
          total_amount: item.total_amount,
          is_splitted: item.is_splitted ?? false,
          number_of_split: item?.number_of_split?.toString() ?? "0",
          split_duration: item.split_duration ?? "month",
          discount: item?.discount === "" ? 0 : item?.discount,
          tax_deductible: item?.tax === "" ? 0 : item?.tax,
          tax_type: item.tax_type,
          discount_type: item.discount_type,
          note: item?.note,
          account_receivable: values?.account_receivable,
        };
        if (subledger?.id) {
          baseObj = {
            ...baseObj,
            SubLedger: values?.values?.map((item) => {
              return item?.SubLedger;
            })[0],
          };
        }
        if (
          item.due_date &&
          (item.charge_type === "charge" || item.charge_type === "donation")
        ) {
          return (baseObj = {
            ...baseObj,
            due_date: item.due_date,
          });
        }
        if (item.charge_type === "donation" && values?.tribute_type?.name) {
          baseObj = {
            ...baseObj,
            tribute_info: {
              type: values?.tribute_type?.name ?? " ",
            },
          };
        }
        if (
          item.charge_type === "donation" &&
          values?.donation_recipient_name
        ) {
          baseObj = {
            ...baseObj,
            tribute_info: {
              recipient_name: values?.donation_recipient_name ?? " ",
            },
          };
        }
        if (item.charge_type === "donation" && values?.donor_name) {
          baseObj = {
            ...baseObj,
            tribute_info: {
              donor_name: values?.donor_name ?? " ",
            },
          };
        }
        if (item.charge_type === "donation") {
          baseObj = {
            ...baseObj,
            tribute_info: {
              assign_tribute: values?.assign_email,
              receipt: {
                id: values?.tribute_information?.id,
                name: values?.tribute_information?.name,
              },
              message: values?.note,
              recipient_salutation:
                values?.donation_recipient_salutatio3ns?.name,
              donor_salutation: values?.donor_salutation?.name,
              send_letter_to: {
                email: values?.email,
                address: values?.address,
              },
            },
          };
        }
        if (values?.comment_checkbox || values?.note) {
          baseObj = {
            ...baseObj,
            comment_invoice: {
              visible: values?.comment_checkbox ?? "false",
              note: values?.note ?? " ",
            },
          };
        }
        if (
          item.charge_type === "credit_on_account" ||
          item.charge_type === "donation"
        ) {
          baseObj = {
            ...baseObj,
          };
        }
        // if (values) {
        //   baseObj = {
        //     ...baseObj,
        //     SubLedger: values?.values?.map((item) => {
        //       return item?.SubLedger;
        //     })[0],
        //   };
        // }

        return baseObj;
      });

      const token = await getAccessTokenSilently();

      if (
        values?.type_of_charge === "charge" &&
        values?.require_notes === true &&
        !values?.note
      ) {
        return toastr.info("Validation", "Please fill the required note field");
      }
      if (
        (values?.type_of_charge === "credit_on_account" ||
          values?.type_of_charge === "credit on account") &&
        values?.require_notes === true &&
        !values?.values?.[0]?.note
      ) {
        return toastr.info("Validation", "Please fill the required note field");
      }

      if (
        values?.values?.[0]?.discount_type === "%" &&
        values?.values?.[0]?.discount > 100
      ) {
        return toastr.info("Validation", "Discount cannot be more than 100%");
      }
      if (
        values?.values?.[0]?.tax_type === "%" &&
        values?.values?.[0]?.tax > 100
      ) {
        return toastr.info("Validation", "Tax cannot be more than 100%");
      }
      if (
        values?.values?.[0]?.discount_type === "$" &&
        values?.values?.[0]?.discount > values?.values?.[0]?.total_amount
      ) {
        return toastr.info(
          "Validation",
          "Discount must be smaller than amount"
        );
      }

      if (!transactionIds.length) {
        setDisabled(false);
        await FinanceService.createTransaction({ transactions: data }, token)
          .then((res) => {
            setTransactionIds(res.result?.map((item) => item._id));
            setChargeId(
              res.result?.[0]?.Charges?.[0]?._id ?? res.result?.[0]?._id
            );

            window.dispatchEvent(
              new CustomEvent("save-transaction", {
                detail: res?.result,
              })
            );
            if (currentStepIndex === 0) {
              handleClose();
            }
            setInputValues(values);

            toastr.success("Success", "Transaction Saved Successfully");
            setFieldValue("isTransactionSave", true);
          })
          .catch((err) => {
            toastr.error("Error", err?.data?.message);
          });
      }
      if (transactionIds.length) {
        await FinanceService.updateTransaction(
          {
            transactions: data.map((item, idx) => ({
              ...item,
              transactionId: transactionIds[idx],
            })),
          },
          token
        )
          .then((res) => {
            window.dispatchEvent(
              new CustomEvent("save-transaction", {
                detail: res?.result,
              })
            );
            if (currentStepIndex === 0) {
              handleClose();
            }
            setInputValues(values);

            toastr.success("Success", "Transaction Updated Successfully");
          })
          .catch((err) => {
            toastr.error("Error", err?.data?.message);
          });
      }
    }
    const { account_name, values: transactions } = values;
    const enableHandleNext = transactions.every((item) => {
      const {
        Ledger,
        due_date,
        payment_date,
        total_amount,
        charge_type,
      } = item;
      return !!(
        Ledger &&
        (charge_type === "credit on account" || due_date) &&
        payment_date &&
        total_amount
      );
    });
    if (account_name && enableHandleNext) {
      fetchMyAPI();
    } else if (values?.values?.[0]?.total_amount === 0) {
      return toastr.info("Validation", "Amount will not be 0");
    } else {
      toastr.info("Validation", "Please fill all the required fields");
    }
  };

  const paymentCalculation = (paymentMethods,values) => {
    const payments = paymentMethods?.map((item) => {
        const paymentMethod = values?.allPaymentsMethods?.find(
          (method) => (method?.type?.toLowerCase() === item?.paymentMethod?.toLowerCase() || (method?.type === "credit on account" && item?.paymentMethod === "credit_on_account") || (
            method?.type === "credit card" && item?.paymentMethod === "credit_card"
          ))
        );
        return {
          ...item,
          paymentMethodId: paymentMethod?._id,
        };
      });
      
    return payments?.length ? payments : paymentMethods;
  };

  const onCheckout = async (values, currentStepIndex) => {
    setBatchList(false);
    if (!values?.isTransactionSave) {
      toastr.info("Validation", "Please save the transaction first");
      return;
    }
    const paymentMethods = Object.entries(values)
      .filter(([key]) => key.includes("_payment"))
      .map(([key, value]) => {
        const split = key.split("_payment");
        if (
          split[0].includes("credit") &&
          parseFloat(values.creditCardAmount)
        ) {
          return {
            ...value,
            paymentMethod:
              split[0] === "credit card" ? "credit_card" : split[0],
            amount: values?.creditCardAmount,
            /* cvc: values.cvc */
          };
        }
        if (split[0].includes("ach") && parseFloat(values.ach)) {
          return {
            paymentMethod: "ACH",
            amount: values?.ach,
            achId: values?.payments[0]?.ACH,
          };
        }
        if (split[0].includes("cash") && parseFloat(values.cash_payment) > 0) {
          return {
            paymentMethod: split[0] === "cash" ? "cash" : split[0],
            amount: values.cash_payment,
          };
        }
        if (
          split[0].includes("onAccountCredit") &&
          parseFloat(values?.onAccountCredit_payment)
        ) {
          return {
            paymentMethod:
              split[0] === "onAccountCredit" ? "credit_on_account" : split[0],
            amount: values?.onAccountCredit_payment,
          };
        }
        if (parseFloat(value) > 0) {
          return { amount: value, paymentMethod: split[0] };
        }
        return;
      })
      .filter((item) => item !== undefined);
      const payments = paymentCalculation(paymentMethods,values);
    const token = await getAccessTokenSilently();
    if (data?.allData) {
      await FinanceService.payAllSelectedCharges(
        {
          payments: [...payments, ...values?.checks],
          convenient_fee: 0,
          convenient_fee_by: "admin",
          charges: values?.allData?.map((item) => {
            return item?.charge?._id;
          }),

          account: values?.accountIds,
          comment_receipt: {
            visible: values?.comment_receipt ?? "false",
            note: values.receipt_note ?? "test",
          },
        },
        token
      )
        .then((res) => {
          if (res) {
            window.dispatchEvent(new CustomEvent("save-transaction"));
            toastr.success("Success", "Transaction successful");
            handleClose();
            setBatchList(true);
          }
        })
        .catch((err) => {
          toastr.error("Error", err?.data?.message);
        });
    } else {
      setBatchList(false);
      await FinanceService.checkout(
        {
          payments: [...payments, ...values?.checks],
          convenient_fee: +values?.convenient_fee,
          convenient_fee_by: values?.convenient_fee_by,
          is_third_party: values?.is_third_party,
          third_party_id: values?.third_party?.id,
          chargeId,
          account: values.account_name?.id,
          total_amount: values?.current_total ?? values?.actual_total,
          comment_receipt: {
            visible: values?.comment_receipt ?? "false",
            note: values.receipt_note ?? " ",
          },
        },
        token
      )
        .then((res) => {
          if (res) {
            window.dispatchEvent(new CustomEvent("save-transaction"));
            toastr.success("Success", "Transaction successful");
            setBatchList(true);
          }
          if (currentStepIndex === 2) {
            handleClose();
          }
        })
        .catch((err) => {
          toastr.error("Error", err?.data?.message);
        });
    }
  };
  const onStepClickvalues = (values, currentStep) => {
    const methods = Object.entries(values)
      .filter(([key]) => key.includes("_payment"))
      .some(([, value]) => !!value);
    if (
      currentStep === 1 &&
      (values?.checks?.length > 0 || values?.creditCardAmount || methods)
    ) {
      setPaidAmount(true);
    }
    if (
      currentStep === 1 &&
      values?.checks?.length === 0 &&
      !values?.creditCardAmount &&
      !methods
    ) {
      setPaidAmount(false);
    }
  };
  const onClose = () => {
    handleClose();
  };

  useEffect(() => {
    if (data) {
      formik.setFieldValue("all_data", data);
    }
  }, [data]);

  return (
    <div
      className={
        title === "New Charge" ? "new-charge modal-background" : "new-charge"
      }
    >
      <div className="new-charge-header pt-5">{title}</div>
      <FormikWizard
        initialValues={{
          ...initialValues,
          convenient_fee_by: "admin",
          convenient_fee: 0,
        }}
        onSubmit={(values) => {
          setFinalValues(values);
          handleClose();
        }}
        validationSchema={NewChargeSchema}
        validateOnNext
        activeStepIndex={activeIndex}
        steps={[
          {
            component: ChargeDetails,
          },
          {
            component: PaymentInformation,
          },
          {
            component: Review,
          },
        ]}
      >
        {({
          currentStepIndex,
          renderComponent,
          handlePrev,
          handleNext,
          isNextDisabled,
          isPrevDisabled,
          values,
          setFieldValue,
        }) => {
          if (currentStepIndex === 1 || currentStepIndex === 2) {
            // setStepper("white");
          }

          console.log(currentStepIndex, "currentStepIndex");
          return (
            <div className="charge-content-pay">
              <div className="charge-content-header py-15">
                <div className="fom-steps">
                  <Stepper activeStep={currentStepIndex}>
                    <Step
                      completed={currentStepIndex > 0}
                      className="step pl-0"
                    >
                      <StepLabel className="step-label">
                        Charges Details
                      </StepLabel>
                    </Step>
                    <Step completed={currentStepIndex > 1}>
                      <StepLabel>Payment information</StepLabel>
                    </Step>
                    <Step completed={hfinish}>
                      <StepLabel>Review</StepLabel>
                    </Step>
                  </Stepper>
                </div>
              </div>
              <div className="">
                {currentStepIndex !== 0
                  ? setTitle("New Payment")
                  : setTitle("New Charge")}
                <div className="form-content">
                  <Box my="2rem" className="w-100 mb-0">
                    {renderComponent()}
                  </Box>
                  <div className="form-content-bottom">
                    {currentStepIndex !== 0 && <hr />}
                    <div className="form-footer">
                      {currentStepIndex == 2 ? (
                        <MyButton
                        style={{ marginBottom:'20px',marginLeft:'10px' }}
                          type="secondary"
                          content={
                            <span>
                              <span className="mr-2"> {"<"}</span>
                              <span>{"Payment informaion"}</span>
                            </span>
                          }
                          onClick={() => {
                            if (currentStepIndex - 1 === 0) {
                              setFieldValue(`nextPayment`, true);
                              setStepper(0);
                            }

                            setHFinish(false);
                            handlePrev();
                          }}
                          disabled={isPrevDisabled}
                        />
                      ) : (
                        <br />
                      )}
                      <div className="d-flex align-items-center pay-buttons">
                        <MyButton
                          style={{ marginRight: 10,width:'50%' }}
                          type="secondary"
                          content={
                            <span>
                              <span>{hfinish ? "Cancel " : "Save Charge"}</span>
                            </span>
                          }
                          onClick={() => {
                            setBtnContent("");
                            hfinish
                              ? onClose()
                              : saveTransaction(
                                  {
                                    ...values,
                                    transactions: values.values,
                                  },
                                  setFieldValue,
                                  currentStepIndex
                                );
                          }}
                        />
                        <MyButton
                          id={
                            currentStepIndex !== 2
                              ? `Next: ${
                                  currentStepIndex === 0
                                    ? "Payment information"
                                    : "Review"
                                }`
                              : "Pay Now"
                          }
                          type={currentStepIndex === 1 ? "primary" : "primary"}
                          content={
                            <p
                            style={{marginBottom:0}}
                              onClick={(e) => {
                                setBtnContent(e.target.id);
                              }}
                              id={
                                currentStepIndex !== 2
                                  ? `Next: ${
                                      currentStepIndex === 0
                                        ? "Payment information"
                                        : "Review"
                                    }`
                                  : "Pay Now"
                              }
                            >
                              {currentStepIndex !== 2
                                ? `Next: ${
                                    currentStepIndex === 0
                                      ? "Payment information"
                                      : "Review"
                                  }`
                                : "Pay Now"}
                            </p>
                          }
                          onClick={(e) => {
                            setBtnContent(e.target.id);
                            if (currentStepIndex === 2) {
                              onCheckout(values, currentStepIndex);
                            } else if (currentStepIndex === 0) {
                              setInputValues(values);

                              onStepClick(
                                values,
                                handleNext,
                                setFieldValue,
                                currentStepIndex
                              );
                            } else {
                              onStepClick(
                                values,
                                handleNext,
                                setFieldValue,
                                currentStepIndex
                              );
                            }
                          }}
                          disabled={
                            currentStepIndex !== 2
                              ? currentStepIndex === 0 &&
                                (values?.type_of_charge === "charge" ||
                                  values?.type_of_charge ===
                                    "credit_on_account") &&
                                values?.require_notes === true
                                ? values?.values
                                    .map((item) => {
                                      return item?.total_amount &&
                                        item?.Ledger &&
                                        item?.payment_date &&
                                        values?.account_name &&
                                        item?.Ledger?.account_receivable
                                        ? false
                                        : true;
                                    })
                                    .includes(true)
                                : currentStepIndex === 0 &&
                                  (values?.type_of_charge === "charge" ||
                                    values?.type_of_charge ===
                                      "credit_on_account") &&
                                  values?.require_notes === false
                                ? values?.values
                                    .map((item) => {
                                      return item?.Ledger &&
                                        item?.total_amount &&
                                        item?.payment_date &&
                                        values?.account_name
                                        ? false
                                        : true;
                                    })
                                    .includes(true)
                                : currentStepIndex === 0 &&
                                  (values?.type_of_charge === "charge" ||
                                    values?.type_of_charge ===
                                      "credit_on_account") &&
                                  values.type === "pay-now"
                                ? values?.values
                                    .map((item) => {
                                      return item?.Ledger &&
                                        item?.total_amount &&
                                        item?.payment_date &&
                                        values?.account_name
                                        ? false
                                        : true;
                                    })
                                    .includes(true)
                                : values?.cash_payment
                                ? false
                                : values?.selectedACH
                                ? values?.ach
                                  ? false
                                  : true
                                : values?.checks.length !== 0
                                ? values?.checks
                                    .map((item) => {
                                      return item.amount &&
                                        item.check_date &&
                                        item?.check_number
                                        ? false
                                        : true;
                                    })
                                    .includes(true)
                                : currentStepIndex === 0 &&
                                  values?.type_of_charge === "donation"
                                ? values?.values
                                    .map((item) => {
                                      return item?.Ledger &&
                                        item?.total_amount &&
                                        item?.payment_date &&
                                        values?.account_name
                                        ? false
                                        : true;
                                    })
                                    .includes(true)
                                : values?.creditCardAmount
                                ? values?.cardDetail?.length !== 0
                                  ? false
                                  : true
                                : values?.onAccountCredit_payment
                                ? false
                                : Object.entries(values)
                                    .filter(([key]) => key.includes("_payment"))
                                    .some(([, value]) => !!value)
                                ? false
                                : true
                              : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </FormikWizard>
    </div>
  );
};
export default NewCharge;
