import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./index.scss";
const MyFilterButton = ({ icon, type, items, width, onChange, ...props }) => {
  const [title, setTitle] = useState(props.title);
  return (
    <div className="d-flex justify-content-between">
      <div className="grid-view mr-5 h-50px">
        <Dropdown className="align-self-center">
          <Dropdown.Toggle
            id="dropdown-button-dark-example2"
            variant="red"
            style={{ width: width }}
          >
            <div className="filter-text" style={{ display: "contents" }}>
              {`${type}: ${title}`}
              {<KeyboardArrowDownIcon />}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="mt-2">
            {items.map((item, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    setTitle(item.label);
                    onChange(item.value);
                  }}
                >
                  {item.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export default MyFilterButton;
