import { useAuth0 } from "@auth0/auth0-react";
import MemberService from "Lib/API/Member";
import BottomArrowSvg from "assets/icons/svg/BottomArrowSvg";
import {
  HintField,
  InputField,
  LabelField,
  MyCheckbox,
  MyRadioGroup,
  Text,
} from "components";
import { Button } from "components/NewButton/Button";
import { AsyncAutoCompleteField } from "components/autoCompleteField/AsyncAutoField";
import SwitchComp from "components/switch";
import { Formik } from "formik";
import { useSearch } from "hook";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import Select, { components } from "react-select";

const deceased = [
  {
    label: "Mourner's Parent",
    value: "Parent-1",
  },
  {
    label: "Mourner's Child",
    value: "Child",
  },
  {
    label: "Mourner's Father",
    value: "Father",
  },
  {
    label: "Mourner's Mother",
    value: "Mother",
  },
  {
    label: "Mourner's Grandchild",
    value: "Grandchild",
  },
  {
    label: "Mourner's Grandparent",
    value: "Grandparent",
  },
  {
    label: "Mourner's Brother",
    value: "Brother",
  },
  {
    label: "Mourner's Sister",
    value: "Sister",
  },
  {
    label: "Mourner's Son",
    value: "Son",
  },
  {
    label: "Mourner's Daughter",
    value: "Daughter",
  },
  {
    label: "Mourner's Wife",
    value: "Wife",
  },
  {
    label: "Mourner's Husband",
    value: "Husband",
  },
  {
    label: "Mourner's Partner",
    value: "Partner",
  },
  {
    label: "Mourner's Father-in-Law",
    value: "Father-in-law",
  },
  {
    label: "Mourner's Mother-in-law",
    value: "Mother-in-law",
  },
  {
    label: "Mourner's Brother-in-Law",
    value: "Brother-in-law",
  },
  {
    label: "Mourner's Sister-in-law",
    value: "Sister-in-law",
  },
  {
    label: "Mourner's Uncle",
    value: "Uncle",
  },
  {
    label: "Mourner's Aunt",
    value: "Aunt",
  },
  {
    label: "Mourner's Nephew",
    value: "Nephew",
  },
  {
    label: "Mourner's Niece",
    value: "Niece",
  },
  {
    label: "Mourner's Grandmother (Paternal)",
    value: "Grandmother",
  },
  {
    label: "Mourner's Grandfather (Paternal)",
    value: "Grandfather",
  },
  {
    label: "Mourner's Grandmother (Maternal)",
    value: "Grandmother",
  },
  {
    label: "Mourner's Grandfather (Maternal)",
    value: "Grandfather",
  },
  {
    label: "Mourner's Step Mother",
    value: "Step-mother",
  },
  {
    label: "Mourner's Step Father",
    value: "Step-father",
  },
  {
    label: "Mourner's Cousin",
    value: "Cousin",
  },
  {
    label: "Mourner's Friend",
    value: "Friend",
  },
  {
    label: "Mourner’s Relative",
    value: "Relative",
  }
];

const AddMourners = ({ drawerDisplay, isEdit, setDisable, ...props }) => {
  const { setMourners } = { ...props?.values?.functions };
  const formik = { ...props };
  const [search, setSearch] = useState(undefined);
  const [searchSource, setSearchSource] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const { searchAction } = useSearch({ action: setSearch });
  const [loading, setLoading] = useState(false);
  const [contactsList, setContactsList] = React.useState([
    {
      id: "",
      label: "Type 3 letters to start searching",
    },
  ]);
  const [email, setEmail] = useState(null);
  const [deceasedValue, setDeceasedValue] = useState(false);
  const [mourner, setMourner] = useState(false);
  const formatOptionLabel = ({ label, account_id }) => {
    return (
      <div className="dropdown-container" key={account_id}>
        <div className="icon-name-container d-flex align-items-center justify-content-start">
          <div className="name-container" style={{ marginInlineStart: 10 }}>
            <div>{label}</div>
          </div>
        </div>
      </div>
    );
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      ...selectControlStyles,
    }),
  };
  const selectControlStyles = {
    backgroundColor: "#F3F6F9",
    borderColor: "#F3F6F9",
    color: "#3F4254",
    transition:
      "color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease",
    paddingTop: "6px",
    paddingBottom: "6px",
    cursor: "pointer",
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <BottomArrowSvg label="Emoji" />
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);
      const token = await getAccessTokenSilently();
      await MemberService.getContacts(token, search)
        .then((res) => {
          if (res?.contacts?.length > 0) {
            //if res.contact = [] will add the search value to guest email address
            //should handle it later
            res.contacts.map((item) => {
              item.id = item.member_id;
              item.label = item?.first_name + " " + item?.last_name;
              item.value = item._id;
              return item;
            });
            setContactsList(res?.contacts);
          } else setEmail(search);
          setLoading(false);
        })
        .catch((err) => {
          toastr.error("Error", err?.data?.message);
        });
    }
    fetchMyAPI();
  }, [search]);

  return (
    <div
      className={
        drawerDisplay ? "add-mourners px-8 pt-8" : "add-mourners pt-12 pb-10"
      }
    >
      {!drawerDisplay && (
        <div className="title">
          <Text className="sub-title">Add Mourners</Text>
          <p className="yah-note">Please complete the details below</p>
        </div>
      )}
      <Formik
        initialValues={{
          account: null,
          relation: "",
          observance: "english",
          first_adar_observance: false,
          second_adar_observance: false,
          address: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.account && !email) {
            errors.account = "Required";
          }
          if (!values.relation) {
            errors.relation = "Required";
          }
          if (!values.address && email) {
            errors.address = "Required";
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          setDeceasedValue(false);
          if (drawerDisplay === true) {
            setDisable(false);
          }
          const input = {
            relation: values?.relation?.id,
            observance: values?.observance,
            first_adar_observance: values?.first_adar_observance,
            second_adar_observance: values?.second_adar_observance,
          };
          if (!email) {
            input.first_name = values?.account?.first_name;
            input.last_name = values?.account?.last_name;
            input.member_id = values?.account?._id;
          } else {
            input.address = values?.address;
          }
          setMourners((prev) => [...prev, input]);
          actions.resetForm();
          actions.setFieldValue("relation", "");
          formik.setFieldValue("relation", "");

          setDeceasedValue(true);

          formik.setFieldTouched("mourners_list", true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div className={drawerDisplay ? "" : "my-10"}>
            <div className="row">
              <div
                className={
                  !drawerDisplay
                    ? "col-sm-6 select-mou"
                    : "col-sm-12 mb-5 select-mou"
                }
              >
                <LabelField title="Select Mourner" />
                <AsyncAutoCompleteField
                  {...{
                    name: "account",
                  }}
                  error={errors["account"]}
                  touched={touched["account"]}
                  onInputChange={(text) => {
                    searchAction(text);
                    setSearchSource("account");
                  }}
                  classNamePrefix="react-select"
                  options={contactsList}
                  isLoading={loading}
                  placeholder={"Type 3 letters to start searching"}
                  handleChange={(e) => {
                    localStorage.setItem("SelectedMourner", e.value);

                    setFieldValue("account", e);
                  }}
                  value={values.account}
                  onFocus={() => setEmail(null)}
                />
              </div>
              <div
                className={
                  !drawerDisplay ? "col-sm-6" : `col-sm-12 ${!email && "pb-5"}`
                }
              >
                <LabelField title="Mourner's Relationship to Deceased" />
                <Select
                  onInputChange={(text) => {
                    searchAction(text);
                    setSearchSource("relationship");
                  }}
                  classNamePrefix="react-select"
                  formatOptionLabel={formatOptionLabel}
                  options={deceased}
                  isLoading={loading}
                  styles={colourStyles}
                  onChange={(value) => {
                    setFieldValue(`relation`, {
                      id: value.value,
                      name: value.label,
                    });
                  }}
                  value={
                    values?.relation
                      ? {
                          label: values?.relation?.name,
                          value: values?.relation?.id,
                        }
                      : ""
                  }
                  components={{ DropdownIndicator }}
                />

                {touched["relation"] && errors["relation"] ? (
                  <HintField error={errors["relation"]} />
                ) : null}
              </div>
            </div>
            {email && (
              <div className="row pt-5">
                <div className={!drawerDisplay ? "col-sm-6" : "col-sm-12"}>
                  <InputField
                    {...{
                      handleChange,
                      title: "Email",
                      name: "email",
                      value: values?.email,
                      placeholder: "cohen22@gmail.com",
                    }}
                  />
                </div>
                <div className={!drawerDisplay ? "col-sm-6" : "col-sm-12"}>
                  <InputField
                    {...{
                      handleChange,
                      title: "Address",
                      name: "address",
                      placeholder: "New York",
                      value: values?.address,
                    }}
                  />
                  {touched["address"] && errors["address"] ? (
                    <HintField error={errors["address"]} />
                  ) : null}
                </div>
              </div>
            )}

            <div className={drawerDisplay ? "" : "pt-10"}>
              <LabelField title="Observance" />
              <MyRadioGroup
                row
                handleChange={(e) => {
                  setFieldValue("observance", e.target.value);
                }}
                value={values.observance}
                name="observance"
                className=""
                formsControls={[
                  { value: "hebrew", label: "Hebrew date" },
                  { value: "english", label: "English date" },
                ]}
              />
            </div>
            {values.observance == "english" ? (
              ""
            ) : (
              <div className="py-5">
                <div className="d-flex h-20px">
                  <SwitchComp
                    checked={values?.first_adar_observance}
                    handleChange={(e) => {
                      if (values?.second_adar_observance) {
                        return;
                      }
                      setFieldValue("first_adar_observance", e.target.checked);
                    }}
                    className="mr-0"
                  />
                  <LabelField
                    title="1 Adar Observance"
                    style={{ alignSelf: "center" }}
                  />
                </div>
                <div className="d-flex ">
                  <SwitchComp
                    checked={values?.second_adar_observance}
                    handleChange={(e) => {
                      if (values?.first_adar_observance) {
                        return;
                      }
                      setFieldValue("second_adar_observance", e.target.checked);
                    }}
                    className="mr-0"
                  />
                  <LabelField
                    title="2 Adar Observance"
                    style={{ alignSelf: "center" }}
                  />
                </div>
              </div>
            )}
            <div className="d-flex align-items-center">
              <Button
                width="130px"
                height="40px"
                style={{ border: 0 }}
                children="Add Mourner"
                onClick={handleSubmit}
                type="Primary"
              />
              <div className="d-flex ml-5">
                <MyCheckbox
                  name={`doesnt_have_mourner`}
                  handlechange={(event) => {
                    formik.setFieldValue(
                      "doesnt_have_mourner",
                      event.target.checked
                    );
                  }}
                  checked={formik?.values?.doesnt_have_mourner}
                  value={formik?.values?.doesnt_have_mourner}
                />
                <LabelField
                  title="Doesn’t have mourner "
                  style={{ alignSelf: "center", margin: "0px" }}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddMourners;
