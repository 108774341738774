import React from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <Route
      {...rest}
      render={
        () =>
          isAuthenticated ? (
            children
          ) : loginWithRedirect()
      }
    />
  );
};
