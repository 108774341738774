import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
padding-left: 30px;
padding-right: 30px;
padding-top: 30px;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
max-height: 500px;
overflow-y: auto;
`
export const SummaryTitle = styled.h3`
color: var(--Dark-Blue-100, #002E5C);
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px;
text-align: left;
padding-bottom: 8px;
display: flex;
justify-content: flex-start;
align-items: center;
span{
padding-right: 8px;
}
`
export const DataNotFoundWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #F8F8F9;
width: 100%;
height: 104px;
border-top: 1px solid rgba(0, 46, 92, 0.10);
border-bottom: 1px solid rgba(0, 46, 92, 0.10);

span{
color: #B5B5C3;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; 
}
`

export const DataFoundWrapper = styled.div`
width: 100%;
max-height: 500px;
/* overflow-y: scroll; */
`
export const NumberOfSelections = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 37.96px;
color: #70A0EE;
background: #EAEEFC;
border-bottom: 1px solid rgba(0, 46, 92, 0.10);
margin-bottom: 29px;
span{
    padding-top: 6px;
    padding-bottom: 6px;
}

`
export const SummaryItem = styled.div`
width: 100%;
display: flex;
justify-content: space-between;

div{
  margin-bottom: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  
    span{
        color: var(--Dark-Blue-100, #002E5C);
        font-size: 15px;
        font-weight: 500;
        line-height: 13px; 
        padding-bottom: 12px;
    }
    p{
        color: var(--Dark-Blue-100, #002E5C);
        font-size: 14px;
    }

}
`
export const PaymentAmount = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #002e5c;

`
export const TotalPayment = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
color: var(--Dark-Blue-100, #002E5C);
border-top: 1px dashed rgba(0, 46, 92, 0.10);
padding-top:24px;
padding-bottom:24px;

p{
font-size: 14px;
font-weight: 600;
line-height: 13px;
}
span{
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 13px;
}
`
export const TotalPay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  span {
    color: var(--dark-blue, #002e5c);
    text-align: right;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
`;
export const Discount = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
color: #5B7792;
margin-bottom: 5px;
`

export const PayDetails = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  color: #002e5c;
  overflow-y:auto ;
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 20px;

  li {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
  }
  span {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    span {
      color: var(--dark-blue, #002e5c);
      text-align: right;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  p{
    opacity: 0.7;
    color: #002e5c;
    text-align: left;
  }
`;
