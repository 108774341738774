import React from 'react';
import {
    FormWrapper, FormHeader, Title, Description, Form,
    FormContent,ValidationWrapper,ValidationIcon,ValidationItems} from './Theme'
import Grid from '@material-ui/core/Grid';
import { getLocalStorageRegistration } from '_metronic/_helpers';
import { useSelector } from 'react-redux';

import FormGeneratorComponent from "helper/FormGenerator";

const RegistrationForm = React.memo(({ formik}) => {
    const { classDetails } = useSelector((state) => state?.Class)
      
    const syncValuesWithFormik = (form) => {
        console.log('form', form)
        formik.setFieldValue('registrationForm[0].formData',form)
   }
   console.log('formik', formik.values.registrationForm[0].formData)
    return (
        <FormWrapper >
            <FormHeader>
                <Title >Registration Form</Title>
                <Description >Thank you for your interest on {classDetails?.class_name} </Description>
            </FormHeader>
            <Form>
            { formik.values?.errors?.length >0&&
            <ValidationWrapper>
              <ValidationIcon/>
              <ValidationItems>
              {formik.values?.errors?.map((error)=>(
                <li>{error.errorMessage}</li>
              ))}
            </ValidationItems>
            </ValidationWrapper>
            }
                <FormContent>
                    <Grid container spacing={3}>
                        {getLocalStorageRegistration()?.RegisterData?.length > 0 && (
                        <FormGeneratorComponent
                            formStructure={[...getLocalStorageRegistration()?.RegisterData]}
                            syncValuesWithFormik={syncValuesWithFormik}
                            formDataReservation={getLocalStorageRegistration()?.RegisterData}
                            answerData={formik?.values?.registrationForm[0].formData}
                        />
                        )}
                      
                    </Grid>
                </FormContent>
            </Form>
        </FormWrapper >
    );
});


export default RegistrationForm;