import { Col, Row } from "react-bootstrap";
import { InputField, LabelField, MyCheckbox, SelectField } from "components";
import React, { useEffect, useMemo, useState } from "react";
import Select, { components } from "react-select";

import BottomArrowSvg from "assets/icons/svg/BottomArrowSvg";
import { DatePickerField } from "_metronic/_partials/controls";
import FinanceService from "../../../../Lib/API/Finance";
// import FinanceSettingService from "../../../../Lib/API/FinanceSetting/FinanceSetting";
import { PriceType } from "./PercentageFixed";
import { TextareaField } from "components/textareaField";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearch } from "hook";
import moment from "moment";
import { toastr } from "react-redux-toastr";

const selectControlStyles = {
  backgroundColor: "#F3F6F9",
  borderColor: "#F3F6F9",
  color: "#3F4254",
  transition:
    "color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease",
  paddingTop: "6px",
  paddingBottom: "6px",
  cursor: "pointer",
};
const colourStyles = {
  control: (styles) => ({
    ...styles,
    ...selectControlStyles,
  }),
};

const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;

const Charge = ({ types, setShow, show, index, removeCharge, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  // const [split, setSplit] = useState(false);
  const [discountType, setDiscountType] = useState("%");
  const [taxType, setTaxType] = useState("%");
  const [data, setData] = useState();
  const [ledgers, setLedgers] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const [search, setSearch] = useState("");
  const { searchAction } = useSearch({ action: setSearch });
  const [subLedgerOptions, setSubLedgerOptions] = useState();
  const formik = props;
  const enableLoading = (value) => {
    setLoading(value);
  };
  const exceptThisSymbols = ["e", "E", "+", "-"];

  const preventArrows = (e) => {
    if (e.which === 38 || e.which === 40 || exceptThisSymbols.includes(e.key))
      e.preventDefault();
  };
  const getDiscountValue = formik.values.values[index]?.discount ?? 0;

  useEffect(() => {
    const index = 0;
    formik.setFieldValue("type_of_charge", "charge");
    if (formik.values?.nextPayment === false) {
      formik.setFieldValue("note", "");
      formik.setFieldValue(`account_name`, {
        id: "",
        name: "",
      });
      formik.setFieldValue(`values.${index}.Ledger`, {
        id: "",
        name: "",
        account_receviable: "",
      });
      formik.setFieldValue(`values.${index}.SubLedger`, {
        id: "",
        name: "",
        account_receviable: "",
      });
      formik.setFieldValue(`values.${index}.due_date`, "");
      formik.setFieldValue(`values.${index}.tax`, "");
      formik.setFieldValue(`values.${index}.total_amount`, "");
      formik.setFieldValue(`require_notes`, "");
      formik.setFieldValue(`values.${index}.discount`, "");
      // formik.setFieldValue(`account_receviable`, "");
      formik.setFieldValue(`values.${index}.is_splitted`, false);
      formik.setFieldValue("comment_checkbox", false);
    }
  }, []);

  const total = useMemo(() => {
    const acutalTotal = formik.values.values.reduce((a, b) => {
      return a + (parseFloat(b?.total_amount) ?? 0);
    }, 0);
    const totalAmount = formik.values.values.reduce((a, b) => {
      const getTotalAmount = a + (b?.total_amount ?? 0);
      const getValueAfterDiscount =
        b?.discount_type === "%"
          ? getTotalAmount - (getDiscountValue / 100) * getTotalAmount
          : getTotalAmount - getDiscountValue;
      return getValueAfterDiscount;
    }, 0);
    formik.setFieldValue("current_total", totalAmount);
    formik.setFieldValue("actual_total", acutalTotal);

    return Number.isNaN(totalAmount) ? 0 : totalAmount;
  }, [formik.values.values]);
  const changeAmount = useMemo(() => {
    const formikValues = formik.values?.values[0];
    const newAmount =
      formikValues.split_duration === "day"
        ? total / formikValues.number_of_split
        : formikValues.split_duration === "week"
        ? total / (formikValues.number_of_split * 7)
        : formikValues.split_duration === "month"
        ? total / (formikValues.number_of_split * 30)
        : formikValues.split_duration === "quarter"
        ? total / (formikValues.number_of_split * 90)
        : formikValues.split_duration === "year"
        ? total / (formikValues.number_of_split * 365)
        : "";

    formik.setFieldValue("split_amount", newAmount);
    return Number.isNaN(newAmount) ? 0 : newAmount;
  }, [formik.values.values]);
  useEffect(() => {
    async function fetchMyAPI() {
      enableLoading(true);
      const token = await getAccessTokenSilently();
      await FinanceService.getACcountNames(token, search).then((res) => {
        if (res?.accounts) {
          res.accounts.map((item) => {
            item.label = item.family_name;
            item.value = item.account_id;
            return item;
          });
          setData(res?.accounts);
          console.log();
        }
        enableLoading(false);
      }).catch((err) => {
        enableLoading(false);
        toastr.error('Error', err?.data?.message);
      })
    }
    async function getLedgeraccounts() {
      enableLoading(true);
      const token = await getAccessTokenSilently();
      const res = await FinanceService.getLedgerSubLedgerList(token);
      if (res?.ledgers) {
        setLedgers(res?.ledgers);
      }

      enableLoading(false);
    }
    getLedgeraccounts();
    fetchMyAPI();
  }, [search, getAccessTokenSilently]);
  const onSubledgerChange = async (e) => {
    formik.setFieldValue(`values.${index}.SubLedger`, {
      id: e.value,
      name: e.label,
    });
    formik.setFieldValue(`values.${index}.tax`, e?.tex);
    formik.setFieldValue(`values.${index}.total_amount`, e?.default_amount);
    formik.setFieldValue(`convenient_fee`, e?.gateway_fee);
    formik.setFieldValue(`require_notes`, e?.notes);
    formik.setFieldValue(`values.${index}.SubLedgerName`, e?.label);

    // const ars = ledgers?.find((item) => event.value === item._id);
  };
  const getLedgerSubLedgerListById = async (event) => {
    const token = await getAccessTokenSilently();
    formik.setFieldValue(`values.${index}.Ledger`, {
      id: event.value,
      name: event.label,
      account_receivable: event?.account_receivable,
    });
    const ksubLedgers = ledgers?.find((item) => event.value === item._id);
    setSubLedgerOptions(ksubLedgers?.sub_ledgers);
    formik.setFieldValue(`values.${index}.SubLedger`, {
      id: "",
      name: "",
    });

    formik.setFieldValue(`values.${index}.tax`, event?.tex);
    formik.setFieldValue(`values.${index}.total_amount`, event?.default_amount);
    formik.setFieldValue(`convenient_fee`, event?.gateway_fee);
    formik.setFieldValue(`require_notes`, event?.notes);
  };

  const options =
    ledgers &&
    ledgers?.map((item) => {
      return {
        label: item?.ledger_name,
        value: item?._id,
        tex: item?.amount_detuctible,
        default_amount: item?.default_amount,
        gateway_fee: item?.gateway_fee,
        notes: item?.notes,
        account_receivable: item?.account_receivable,
      };
    });
  const subLedgersList =
    subLedgerOptions &&
    subLedgerOptions?.map((item) => {
      return {
        label: item.sub_ledger_name,
        value: item._id,
        tex: item?.amount_detuctible,
        default_amount: item?.default_amount,
        gateway_fee: item?.gateway_fee,
        notes: item?.notes,
      };
    });

  const formatOptionLabel = ({ label, account_id }) => {
    return (
      <div className="dropdown-container" key={account_id}>
        <div className="icon-name-container d-flex align-items-center justify-content-start">
          <div className="name-container" style={{ marginInlineStart: 10 }}>
            <div>{label}</div>
          </div>
        </div>
      </div>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <BottomArrowSvg label="Emoji" />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    if (formik?.values?.allData) {
      formik.setFieldValue(`account_name`, {
        id: formik?.values?.allData[0].Account?.id,
        name: formik?.values?.allData[0].Account?.name,
      });

      formik.setFieldValue(
        `values.${index}.charge_type`,
        formik.values?.allData[0]?.charge_type.toLowerCase()
      );
      formik.setFieldValue(
        `values.${index}.payment_date`,
        formik?.values?.allData[0]?.createdAt
      );
      formik.setFieldValue(
        `values.${index}.due_date`,
        formik?.values?.allData[0]?.charge?.due_date
      );

      formik.setFieldValue(`values.${index}.Ledger`, {
        id: formik?.values?.allData[0]?.Ledger?.id,
        name: formik?.values?.allData[0]?.Ledger?.name,
      });
      formik.setFieldValue(`values.${index}.SubLedger`, {
        id: formik?.values?.allData[0]?.SubLedger?.id,
        name: formik?.values?.allData[0]?.SubLedger?.name,
      });
    }
  }, []);
  const getCommaSeperatedValue = (num) => {
    return (
      parseFloat(num?.toString().replace(/,/g, ""))
        .toFixed(2)
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0.0
    );
  };

  console.log(
    moment(formik.values.values?.[index]?.payment_date)
      .utcOffset("+0100")
      .format("YYYY-MM-DD"),
    "llllllllll"
  );
  return (
    <div className="renderd-comp">
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="charge-tab col-sm-12 px-sm-15 px-3 pt-10 pb-3 ">
          <Row className="form-row sm-12 mb-4">
            <Col className="col-12 col-md-5">
              <div className="form-group">
                <LabelField mandatory title={"Account Name"} />
                <Select
                  {...{
                    formik,
                    name: `account_name`,
                    checkValidation: false,
                  }}
                  onInputChange={(text) => searchAction(text)}
                  classNamePrefix="react-select"
                  formatOptionLabel={formatOptionLabel}
                  options={data}
                  isLoading={loading}
                  styles={colourStyles}
                  onChange={(value) => {
                    formik.setFieldValue(`account_name`, {
                      id: value._id,
                      name: value.label,
                    });
                  }}
                  value={
                    formik.values?.account_name
                      ? {
                          label: formik.values?.account_name?.name,
                          value: formik.values?.account_name?.id,
                        }
                      : ""
                  }
                  components={{ DropdownIndicator }}
                  isDisabled={index > 0}
                />
              </div>
            </Col>
            <Col className="col-12 col-md-5">
              <div className="form-group">
                <LabelField mandatory title="Charge Type" />
                <div className="charge-type">
                  {types.map((type, idx) => (
                    <span
                      className={
                        type === "Credit On Account" ? "col-sm-6" : "col-sm-3"
                      }
                      style={
                        type === show
                          ? { background: "#2555ef", color: "white" }
                          : {}
                      }
                      key={idx}
                      onClick={() => {
                        setShow(type);
                        formik.setFieldValue(
                          `values.${index}.charge_type`,
                          type ? type.toLowerCase() : "charge"
                        );
                      }}
                      {...{
                        formik,
                        value: formik.values.values?.[index]?.charge_type
                          ? formik.values.values?.[index]?.charge_type
                          : "",
                      }}
                    >
                      {type}
                    </span>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="form-row sm-12 mb-4">
            <Col className="col-12 col-md-5">
              <DatePickerField
                disabled={true}
                mandatory
                {...{
                  formik,
                  name: `values.${index}.payment_date`,
                  title: "Date",
                  checkValidation: false,
                  value: formik.values.values?.[index]?.payment_date
                    ? moment(formik.values.values?.[index]?.payment_date)
                        .utcOffset("+0100")
                        .format("YYYY-MM-DD")
                    : "",
                }}
                financeDateFields="finance_datefield"
              />
            </Col>
            <Col className="col-12 col-md-5">
              <DatePickerField
                mandatory
                {...{
                  formik,
                  name: `values.${index}.due_date`,
                  title: "Due Date",
                  checkValidation: false,
                  minDate: formik.values.values?.[index]?.payment_date
                    ? formik.values.values?.[index]?.payment_date
                    : formik?.values?.allData
                    ? formik?.values?.allData[index]?.createdAt
                    : "",
                  value: formik.values.values?.[index]?.due_date
                    ? moment(formik.values.values?.[index]?.due_date)
                        .utcOffset("+0100")
                        .format("YYYY-MM-DD")
                    : "",
                }}
                financeDateFields="finance_datefield"
              />
            </Col>
          </Row>
          <Row className="form-row sm-12 mb-4">
            <Col className="col-12 col-md-5">
              <div className="form-group">
                <LabelField title={"Ledger Account"} mandatory />
                <Select
                  {...{
                    formik,
                    name: `values.${index}.Ledger`,
                    checkValidation: false,
                  }}
                  onInputChange={(text) => searchAction(text)}
                  classNamePrefix="react-select"
                  formatOptionLabel={formatOptionLabel}
                  options={options}
                  isLoading={loading}
                  styles={colourStyles}
                  onChange={getLedgerSubLedgerListById}
                  value={{
                    label: formik.values?.values?.[index]?.Ledger ? (
                      formik.values?.values?.[index]?.Ledger?.name
                    ) : (
                      <span style={{ color: "#b5b5c3" }}> Select</span>
                    ),
                    value: formik.values?.values?.[index]?.Ledger?.id
                      ? formik.values?.values?.[index]?.Ledger?.id
                      : "",
                  }}
                  components={{ DropdownIndicator }}
                />
              </div>
            </Col>
            <Col className="col-12 col-md-5">
              <div className="form-group">
                <LabelField title={"Sub-Ledger Account"} mandatory />
                <Select
                  {...{
                    formik,
                    name: `values.${index}.SubLedger`,
                    checkValidation: false,
                  }}
                  onInputChange={(text) => searchAction(text)}
                  classNamePrefix="react-select"
                  formatOptionLabel={formatOptionLabel}
                  options={subLedgersList}
                  isLoading={loading}
                  styles={colourStyles}
                  onChange={onSubledgerChange}
                  value={{
                    label: formik.values?.values?.[index]?.SubLedger ? (
                      formik.values?.values?.[index]?.SubLedger?.name
                    ) : (
                      <span style={{ color: "#b5b5c3" }}> Select</span>
                    ),
                    value: formik.values?.values?.[index]?.SubLedger?.id
                      ? formik.values?.values?.[index]?.SubLedger?.id
                      : "",
                  }}
                  components={{ DropdownIndicator }}
                />
              </div>
            </Col>
          </Row>
          <Row className="form-row sm-12 mb-4">
            <Col className="col-12 col-md-5 d-flex">
              <Col className="col-sm-6 px-0">
                <InputField
                  type="number"
                  {...{
                    formik,
                    title: "Discount",
                    name: `values.${index}.discount`,
                    placeholderIcon: (
                      <span className="amount-placeholder-icon">
                        {formik.values?.values?.[index]?.discount_type ||
                        formik?.values?.allData[0].discount_type === "%"
                          ? "%"
                          : "$"}
                      </span>
                    ),
                    placeholder: "Discount",
                    value: formik.values?.values?.[index]?.discount
                      ? formik.values?.values?.[index]?.discount
                      : formik?.values?.allData
                      ? Math.round(formik?.values?.allData[0]?.discount * 1e2) /
                        1e2
                      : "",
                    checkValidation: true,
                  }}
                  style={{
                    backgroundColor: "#F3F6F9",
                    border: "none",
                  }}
                  onKeyDown={(e) => preventArrows(e)}
                  // min="0"
                  handleChange={(e) => {
                    formik.setFieldValue(
                      `values.${index}.discount`,
                      e.target.value.match(regex)[0]
                    );
                  }}
                />
              </Col>
              <Col className="col-sm-6 price-type">
                <PriceType
                  value={
                    formik.values?.values?.[index]?.discount_type
                      ? formik.values?.values?.[index]?.discount_type
                      : formik?.values?.allData[0]?.discount_type
                  }
                  handleClick={setDiscountType}
                  setFieldValue={formik.setFieldValue}
                  index={index}
                  type={`values.${index}.discount_type`}
                />
              </Col>
            </Col>
            <Col className="col-12 col-md-5 d-flex">
              <Col className="col-sm-6 px-0">
                <InputField
                  type="number"
                  {...{
                    formik,
                    title: "Tax Deductible",
                    name: `values.${index}.tax`,
                    placeholderIcon: (
                      <span className="placeholder-icon">
                        {formik.values?.values?.[index]?.tax_type ||
                        formik?.values?.allData[0]?.discount_type === "%"
                          ? "%"
                          : "$"}
                      </span>
                    ),
                    placeholder: "Tax",
                    value: formik.values?.values?.[index]?.tax
                      ? formik.values?.values?.[index]?.tax
                      : formik?.values?.allData
                      ? Math.round(formik.values?.values?.[index]?.tax * 1e2) /
                        1e2
                      : "",
                    checkValidation: true,
                  }}
                  onKeyDown={(e) => preventArrows(e)}
                  style={{
                    backgroundColor: "#F3F6F9",
                    border: "none",
                  }}
                  min="0"
                  handleChange={(e) => {
                    formik.setFieldValue(
                      `values.${index}.tax`,
                      e.target.value.match(regex)[0]
                    );
                  }}
                />
              </Col>
              <Col className="col-12 col-md-5 price-type">
                <PriceType
                  value={
                    formik.values?.values?.[index]?.tax_type
                      ? formik.values?.values?.[index]?.tax_type
                      : formik?.values?.allData[0].tax_type
                  }
                  handleClick={setTaxType}
                  setFieldValue={formik.setFieldValue}
                  index={index}
                  type={`values.${index}.tax_type`}
                />
              </Col>
            </Col>
          </Row>

          <Row className="form-row sm-12 mb-4">
            <Col className="col-12 col-md-5">
              <InputField
                mandatory
                type="number"
                step="any"
                {...{
                  formik,
                  title: "Amount",
                  name: `values.${index}.total_amount`,
                  placeholder: "Charge Amount $",

                  value: formik.values?.values?.[index]?.total_amount
                    ? formik.values?.values?.[index]?.total_amount
                    : formik?.values?.allData
                    ? Math.round(formik?.values?.allData[0].total_amount)
                    : formik.values?.values?.[index]?.total_amount
                    ? Math.round(formik.values?.values?.[index]?.total_amount)
                    : "",
                  checkValidation: true,
                }}
                onKeyDown={(e) => preventArrows(e)}
                handleChange={(e) => {
                  formik.setFieldValue(
                    `values.${index}.total_amount`,
                    e.target.value.match(regex)[0]
                  );
                }}
                min="0"
                // onKeyDown={(e) =>
                //   exceptThisSymbols.includes(e.key) && e.preventDefault()
                // }
              />
            </Col>
          </Row>
          <Col className="col mb-10 px-0">
            <div className="">
              <MyCheckbox
                name={`values.${index}.is_splitted`}
                title="Split"
                handlechange={(event) => {
                  formik.setFieldValue(
                    `values.${index}.is_splitted`,
                    event.target.checked
                  );
                }}
                value={formik.values.values[index]?.is_splitted}
                checked={formik.values?.values[index]?.is_splitted}
              />
            </div>
            {formik.values.values[index]?.is_splitted && (
              <Row>
                <div className="col-sm-5">
                  <SelectField
                    {...{
                      formik,
                      name: `values.${index}.split_duration`,
                      items: ["day", "week", "month", "quarter", "year"],
                      placeholder: "Select",
                      value: formik.values.values?.[index]?.split_duration ? (
                        <span style={{ color: "black" }}>
                          {formik.values.values?.[index]?.split_duration}
                        </span>
                      ) : (
                        ""
                      ),
                    }}
                    onClick={(data) =>
                      formik.setFieldValue(
                        `values.${index}.split_duration`,
                        data
                      )
                    }
                  />
                </div>
                {formik.values.values?.[index]?.split_duration ? (
                  <div className="col-sm-3">
                    <InputField
                      type="number"
                      {...{
                        formik,
                        title: "",
                        name: `values.${index}.number_of_split`,
                        placeholder: "0",
                        value: formik.values.values?.[index]?.number_of_split,
                        checkValidation: true,
                      }}
                      onKeyDown={(e) => preventArrows(e)}
                    />
                    <div className="placeholder-div">
                      <p>{formik.values.values?.[index]?.split_duration}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Row>
            )}
          </Col>
          <Row className="form-row sm-12 mb-0">
            <Col className="col-sm-12 px-0">
              <TextareaField
                {...{
                  formik,
                  title: "Add Note",
                  name: `note`,
                  placeholder: "Note text",
                  style: { resize: "none" },
                  value: formik?.values?.note,
                }}
              />
            </Col>
          </Row>
          {/* <p className="drawer-row visible-icon">
            <div className="visible-icon-area">
              <EyeIcon className="cursor-pointer" />
            </div>
            <div className="pl-2 visible-text">Visible to others</div>
          </p> */}
          <MyCheckbox
            {...{
              formik,
              title: "Add Note",
              name: `values.${index}.comment_checkbox`,
            }}
            title="Comment on invoice"
            handleSubmit
            handlechange={(event) => {
              formik.setFieldValue("comment_checkbox", event.target.checked);
            }}
            checked={formik?.values?.comment_checkbox}
          />
          <hr className="mt-10 mb-7" />
          <div className="d-flex justify-content-end ">
            <p className="charge-total">
              Total: $ {/* {calculatedAmount == "NaN"
                ?  */}
              {formik.values?.values?.[index]?.charge?.amount
                ? getCommaSeperatedValue(
                    formik.values?.current_amount ??
                      formik.values?.current_total
                  )
                : formik?.values?.allData
                ? getCommaSeperatedValue(
                    formik?.values?.allData[0].total_amount
                  )
                : formik.values?.values?.[index]?.total_amount
                ? getCommaSeperatedValue(
                    formik.values?.current_amount ??
                      formik.values?.current_total
                  )
                : "0.00"}
              {/* : calculatedAmount} */}
              {/* {Math.round(formik.values?.values?.[index]?.total_amount ?? "")} */}
            </p>
            {formik.values?.values?.length === 1 ? (
              ""
            ) : (
              <a className="text-primary" onClick={() => removeCharge(index)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
                <span className="ml-2">Remove Charge</span>
              </a>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default Charge;
