import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "@material-ui/core";
import { AvatarGroup, Pagination } from "@material-ui/lab";
import sortImg from "assets/icons/sort-able-svg.svg";
import AlertDialog from "components/Dialog";
import RightDrawer from "components/drawer";
import MyFilterButton from "components/MyFilterButton";
import NewDialog from "components/NewDialog/Dialog";
import { formatDate } from "helper/dateFormat";
import { SubHeader } from "layout/SubHeader";
import AuthService from "Lib/API/Auth";
import * as _ from "lodash";
import moment from "moment";
import AddYahrzeit from "pages/Yahrzeit/NewYahrzeit";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { DateFormetContext } from "../../app/App.js";
import Action from "../../components/ActionPopOver";
import MyTable from "../../components/Table";
import { withUserExist } from "../CustomHooks";
import "./style.scss";
import YahrzietDrawer from "./YahrzietDrawer";

const initialFilter = {};
const Yahrzeit = () => {
  const [loading, setLoading] = useState(false);
  const dateFormet = React.useContext(DateFormetContext);
  const [filters, setFilters] = useState(initialFilter);
  const [list, setList] = useState([]);
  const history = useHistory();
  const [openModal, setOpenModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deceasedId, setDeceasedId] = React.useState("");
  const [total, setTotal] = useState();
  const [refresh, setRefresh] = React.useState("");
  const [openDiscardChanges, setOpenDiscardChanges] = React.useState(false);
  const [sortBy, setSortBy] = useState("");
  const [touched, setTouched] = React.useState({});
  const [show, setShow] = useState("");
  const [csvData, setExportData] = React.useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [count, setCount] = useState();
  const [loadingYahirzeit, setLoadingYahirzeit] = useState(false);
  console.log(count, "setCount");
  const [page, setPage] = useState(0);
  const [isOpenMergePopUp, setIsOpenMergePopUp] = useState(false);

  const enableLoading = (value) => {
    setLoading(value);
  };
  const handleClose = () => {
    if (_.isEmpty(touched)) setOpenModal(false);
    else setOpenDiscardChanges(true);
  };

  const handleDelete = async () => {
    setOpenModal(false);
    const token = await getAccessTokenSilently();
    await AuthService.removeDeceased({ deceasedID: deceasedId }, token)
      .then((res) => {
        if (res) {
          setOpen(false);
          setRefresh(!refresh);
        }
      })
      .catch((err) => {
        setOpen(false);
        toastr.error("Error", err?.data?.message);
      });
  };

  const getFilterdDeceased = async () => {
    const token = await getAccessTokenSilently();
    AuthService.getFilterdDeceased(page + 1, 6, filters, sortBy, token)
      .then((res) => {
        console.log(page, "pagepage");
        if (res?.deceaseds?.length) {
          const result = res?.deceaseds.map((item) => {
            item.id = item._id;
            return item;
          });
          console.log(result, "resultresult");
          setTotal(res.total);
          setList(result);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toastr.error("Error", err?.data?.message);
      });
  };

  console.log(list, "resultresult");

  useEffect(() => {
    getFilterdDeceased();
  }, [page, refresh, filters, sortBy]);

  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.replaceAll("\\s", "").length >= length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  const columns_to_export = [
    {
      id: "name",
      displayName: "NAME OF DECEASED",
      headerName: "NAME OF DECEASED",
      field: "name",
      flex: 1,
    },
    {
      id: "eng_date",
      displayName: "DATE OF DEATH ",
      headerName: "DATE OF DEATH ",
      field: "eng_date",
      flex: 1,
    },
    {
      id: "hebrew_date",
      displayName: "DATE OF DEATH (HEBREW)",
      headerName: "DATE OF DEATH (HEBREW)",
      field: "hebrew_date",
      flex: 1,
    },
    {
      id: "eng_mourner",
      displayName: "OBSERVERS",
      headerName: "OBSERVERS",
      field: "eng_mourner",
      flex: 1,
    },
    {
      id: "relationship",
      displayName: "RELATIONSHIP",
      headerName: "RELATIONSHIP",
      field: "relationship",
      flex: 1,
    },

    {
      id: "hebrew_mourner_english_date",
      displayName: "OBSERVERS (ENGLISH DATE)",
      headerName: "OBSERVERS (ENGLISH DATE)",
      field: "hebrew_mourner",
      minWidth: 50,
    },
    {
      id: "hebrew_mourner_hebrew_date",
      displayName: "OBSERVERS (HEBREW DATE)",
      headerName: "OBSERVERS (HEBREW DATE)",
      field: "hebrew_mourner",
      flex: 1,
    },
    {
      id: "has_plaque",
      displayName: "Has Plaque",
      headerName: "Has Plaque",
      field: "has_plaque",
      flex: 1,
    },
    {
      id: "yhartzeit_number",
      displayName: "YAHRTZEIT NUMBER",
      headerName: "YAHRTZEIT NUMBER",
      field: "yhartzeit_number",
      flex: 1,
    },
    {
      id: "mobile_phone",
      displayName: "MOBILE PHONE",
      headerName: "MOBILE PHONE",
      field: "mobile_phone",
      flex: 1,
    },
    {
      id: "home_phone",
      displayName: "HOME PHONE",
      headerName: "HOME PHONE",
      field: "home_phone",
      flex: 1,
    },
    {
      id: "email",
      displayName: "EMAIL",
      headerName: "EMAIL",
      field: "email",
      flex: 1,
    },
    {
      id: "address",
      displayName: "ADDRESS",
      headerName: "ADDRESS",
      field: "address",
      flex: 1,
    },
    {
      id: "city",
      displayName: "CITY",
      headerName: "CITY",
      field: "city",
      flex: 1,
    },
    {
      id: "state",
      displayName: "STATE",
      headerName: "STATE",
      field: "state",
      flex: 1,
    },
    {
      id: "zip",
      displayName: "ZIP CODE",
      headerName: "ZIP CODE",
      field: "zip",
      flex: 1,
    },

    {
      id: "type",
      displayName: "TYPE",
      headerName: "TYPE",
      field: "type",
      flex: 1,
    },
    {
      id: "doesnt_have_mourner",
      displayName: "HAVE MOURNERS",
      headerName: "HAVE MOURNERS",
      field: "doesnt_have_mourner",
      flex: 1,
    },
  ];
  const columns = [
    {
      field: "firstName",

      resizable: true,
      headerName: (
        <div
          onClick={() => {
            setFilters((prev) => ({
              sort: {
                firstName: prev?.sort?.firstName == 1 ? -1 : 1,
              },
            }));
          }}
        >
          DECEASED FIRST NAME{" "}
          <img src={sortImg} style={{ marginLeft: "5px" }} />
        </div>
      ),
      minWidth: 210,
      maxWidth: 210,
      sortable: false,
      headerClassName: "yahrziet-name-table view-table-margin pointer",
      renderCell: (data) => {
        return (
          <div className="user-cotainer" title={data?.row?.firstName || "-"}>
            <div className="name-container d-flex">
              <Avatar
                className="avatar"
                style={{
                  height: "50px",
                  borderRadius: "6px",
                  width: "50px",
                }}
                alt={`yahrziet`}
              >
                <div className="name">
                  {`${data?.row?.firstName[0]}`.toUpperCase()}
                </div>
              </Avatar>
              <p className="id-container">
                {text_truncate(data?.row?.firstName, 15) || "-"}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      field: "lastName",
      headerName: (
        <div
          onClick={() => {
            setFilters((prev) => ({
              ...prev,
              sort: {
                lastName: prev?.sort?.lastName == 1 ? -1 : 1,
              },
            }));
          }}
        >
          DECEASED LAST NAME <img src={sortImg} style={{ marginLeft: "5px" }} />
        </div>
      ),
      minWidth: 200,
      flex: 1,
      headerClassName: "yahrziet-name-table view-table-margin pointer",
      sortable: false,
      renderCell: (data) => {
        return (
          <div className="user-cotainer-home ml-0 -head pl-0">
            <div className="name-container flex-row-reverse">
              <p
                className="id-container w-100 table-field-hover-data text-left"
                style={{ wordBreak: "break-word", whiteSpace: "normal" }}
              >
                {data?.row?.lastName && data?.row?.lastName}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      field: "date_of_death",
      resizable: true,
      headerName: (
        <div
          onClick={() => {
            setFilters((prev) => ({
              sort: {
                date_of_death: prev?.sort?.date_of_death == 1 ? -1 : 1,
              },
            }));
          }}
        >
          DATE OF DEATH <img src={sortImg} style={{ marginLeft: "5px" }} />
        </div>
      ),
      sortable: false,
      minWidth: 150,
      flex: 1,
      headerClassName: "pointer",
      renderCell: (data) => (
        <div style={{ color: "grey" }}>
          {data?.row?.date_of_death ? formatDate({date:data?.row?.date_of_death, formatType: "defaultWithUTC"}) : "-"}
        </div>
      ),
    },
    {
      field: "eng-mourners",
      resizable: true,
      headerName: "ENGLISH MOURNER",
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (data) => (
        <div style={{ color: "grey" }}>
          <div className="avatar-container my-0 mx-5">
            <AvatarGroup max={4}>
              {data?.row?.mourners_list?.map((mourner, index) => {
                if (mourner.observance === "english")
                  return (
                    <div title={mourner?.member_id?.first_name}>
                      <Avatar
                        key={index}
                        alt={mourner?.member_id?.first_name}
                        src={mourner?.member_id?.profile_img}
                      >
                        {mourner?.member_id?.first_name &&
                          mourner?.member_id?.last_name &&
                          (
                            mourner?.member_id.first_name[0] +
                            mourner?.member_id.last_name[0]
                          ).toUpperCase()}
                      </Avatar>
                    </div>
                  );
              })}
            </AvatarGroup>
          </div>
        </div>
      ),
    },
    {
      field: "hebrew_date",
      headerName: "HEBREW DEATH DATE",
      minWidth: 180,
      flex: 1,
      headerClassName: "yahrziet-hebrewdate-head",
      sortable: false,
      renderCell: (data) => {
        return (
          <div className="user-cotainer-home pl-0 w-100 ml-0 justify-content-center">
            <div style={{ color: "grey" }}>
              {data?.row?.hebrew_date_of_death
                ? data?.row?.hebrew_date_of_death
                : "-"}
            </div>
          </div>
        );
      },
    },
    {
      field: "heb-mourners",
      resizable: true,
      headerName: "HEBREW MOURNER",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (data) => (
        <div className="w-100">
          <div className="avatar-container my-0 mx-5">
            <AvatarGroup max={4}>
              {data?.row?.mourners_list?.map((mourner, index) => {
                if (mourner.observance === "hebrew")
                  return (
                    <div
                      title={
                        mourner?.member_id?.first_name
                          ? `${mourner?.member_id?.first_name} ${mourner?.member_id?.last_name}`
                          : "Guest"
                      }
                    >
                      <Avatar
                        key={index}
                        alt={mourner?.member_id?.first_name}
                        src={mourner?.member_id?.profile_img}
                      >
                        {mourner?.member_id?.first_name &&
                          mourner?.member_id?.last_name &&
                          (
                            mourner?.member_id.first_name[0] +
                            mourner?.member_id.last_name[0]
                          ).toUpperCase()}
                      </Avatar>
                    </div>
                  );
              })}
            </AvatarGroup>
          </div>
        </div>
      ),
    },
    {
      field: "plaque_number",
      resizable: true,
      headerName: (
        <div
          onClick={() => {
            setFilters((prev) => ({
              sort: {
                plaque_number: prev?.sort?.plaque_number == 1 ? -1 : 1,
              },
            }));
          }}
        >
          PLAQUE NUMBER
          <img src={sortImg} style={{ marginLeft: "5px" }} />
        </div>
      ),
      sortable: false,
      minWidth: 200,
      headerClassName: "yahrziet-date-head pointer",
      flex: 1,
      renderCell: (data) => (
        <div style={{ color: "grey" }}>
          <div style={{ color: "grey" }}>{data?.row?.plaque_number ?? "-"}</div>
        </div>
      ),
    },
    {
      field: "action",
      resizable: true,
      headerName: `${" "}`,
      sortable: false,
      minWidth: 10,
      renderCell: (data) => {
        return (
          <div style={{ cursor: "pointer", paddingRight: "0px" }}>
            <Action
              style={{ color: "#4D5565", width: "16px" }}
              onEditClick={() => setOpenModal(true)}
              onDeleteClick={() => {
                setOpen(true);
              }}
            />
          </div>
        );
      },
    },
  ];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getRows = () => {
    const arr = [];

    if (csvData) {
      csvData.map((item) => {
        const d = {
          name: arr
            ?.map((item) => {
              return item?.name;
            })
            .includes(item?.name)
            ? ""
            : item?.name,
          eng_date: item?.date_of_death
            ? moment(item?.date_of_death).format(dateFormet)
            : "",
          hebrew_date: item?.hebrew_date_of_death,
          eng_mourner: item?.mourners_list?.member_id?.first_name,
          relationship: item?.mourners_list?.relation,
          hebrew_mourner_english_date:
            item?.mourners_list?.observance == "english"
              ? item?.date_of_death
                ? moment(item?.date_of_death).format(dateFormet)
                : ""
              : "",
          hebrew_mourner_hebrew_date:
            item?.mourners_list?.observance == "hebrew"
              ? item?.date_of_death
                ? item?.hebrew_date_of_death
                : ""
              : "",
          has_plaque: item?.has_plaque ? item?.has_plaque : "No",
          yhartzeit_number:
            item?.date_of_death !== undefined || null
              ? new Date().getFullYear() -
                new Date(item?.date_of_death).getFullYear()
              : "",

          mobile_phone: item?.mourners_list?.member_id?.mobile_number,
          home_phone: item?.mourners_list?.member_id?.home_phone,
          email: item?.mourners_list?.member_id?.email_address,
          address: item?.mourners_list?.member_id?.location?.formattedAddress?.replace(
            /,/g,
            " "
          ),
          city: item?.mourners_list?.member_id?.city,
          state: item?.mourners_list?.member_id?.state,
          zip: item?.mourners_list?.member_id?.zip_code,
          type: item?.mourners_list?.member_id?.account_type,
          have_mourners: item?.doesnt_have_mourner,
        };
        arr.push(d);
      });
    }
    return arr;
  };

  const getExportData = async () => {
    const token = await getAccessTokenSilently();
    AuthService.getExportDeceased(token)
      .then((res) => {
        setExportData(res?.deceaseds);
        console.log(res?.deceaseds, "res?.deceaseds");
      })
      .catch((err) => {
        toastr.error("Error", err?.data?.message);
      });
  };

  React.useEffect(() => {
    getExportData();
  }, [page, refresh]);

  return (
    <>
      <div className="m-0 p-5  w-100 all-Yahrzeit">
        <div className="m-0 w-100 h-100 ">
          <div className="col-12 m-0 d-flex justify-content-between align-items-center borderBottom py-5">
            <div className="header-tittle">
              <SubHeader
                className="class-tab subheader"
                {...{
                  title: "Yahrzeit",
                }}
              />
            </div>
            <div className="d-flex  justify-content-end">
              <button
                onClick={() => setIsOpenMergePopUp(true)}
                type="button"
                className={`btn btn-primary font-weight-bold px-2 px-lg-5 mr-2`}
              >
                <span>Add New Yahrzeit</span>
              </button>
            </div>
          </div>
          <div className="d-flex py-5">
            <div className="allTime">
              <MyFilterButton
                title="All time"
                type="Date"
                items={[
                  { label: "This year", value: "year" },
                  { label: "This month", value: "month" },
                  { label: "This week", value: "week" },
                  { label: "All time", value: "all" },
                ]}
                // icon={}
                onChange={setSortBy}
              />
            </div>
          </div>
          <Card className="h-100 card card-custom p-4 mt-4 ">
            <div className="d-flex justify-content-between">
              <div className="yah-sub">Upcoming yahrzeit</div>
            </div>
            <div className="mt-5 yah-table-page-container">
              <MyTable
                className="family-table yehrzeit-table"
                rows={list}
                columns={columns}
                rowsPerPageOptions={[6]}
                pageSize={6}
                loading={loading}
                rowHeight={70}
                onClickEvent={(params, event) => {
                  event.defaultMuiPrevented = true;
                  setDeceasedId(params?.row?.id);
                  if (params?.field !== "action") setOpenModal(true);
                }}
              />
              <div className="pagination-custom mt-2">
                <Pagination
                  count={Math.ceil(total / 10)}
                  color="primary"
                  variant="outlined"
                  onChange={(event, value) => {
                    setPage(value - 1);
                  }}
                />
              </div>
            </div>
            <RightDrawer open={openModal} onClose={handleClose}>
              <YahrzietDrawer
                open={openDiscardChanges}
                setOpen={setOpenDiscardChanges}
                setOpenAlert={setOpen}
                setTouched={setTouched}
                setOpenDrawer={setOpenModal}
                deceasedId={deceasedId}
                refresh={refresh}
                setRefresh={setRefresh}
                isEdit={"true"}
              />
            </RightDrawer>
          </Card>
        </div>
        <AlertDialog
          open={open}
          handleClose={() => setOpen(false)}
          onDecline={() => {
            setOpen(false);
          }}
          onAccept={handleDelete}
          content={
            <div className="alert-message-popup">
              <p className="alert-icon">{/* <DeleteForeverIcon /> */}</p>
              <div>
                <p>Delete Item</p>
                <p className="alert-message">
                  Are you sure you want to Delete this item?
                </p>
              </div>
            </div>
          }
        />

        <div className="d-none">
          <table ref={componentRef} style={{ padding: 10, margin: 10 }}>
            <thead>
              {columns_to_export?.map((column, index) => (
                <th
                  style={{ border: "1px solid", textAlign: "center" }}
                  key={index}
                >
                  {column?.headerName}
                </th>
              ))}
            </thead>
            <tbody>
              {getRows()?.map((item, index) => {
                const d = {
                  name: item?.name,
                  eng_date: item?.eng_dateh
                    ? moment(item?.eng_date).format(dateFormet)
                    : "",
                  hebrew_date: item?.hebrew_date,
                  eng_mourner: item?.eng_mourner ? item?.eng_mourner : "",
                  relationship: item?.relationship ? item?.relationship : "-",
                  hebrew_mourner_english_date: item?.hebrew_mourner_english_date
                    ? moment(item?.hebrew_mourner_english_date).format(
                        dateFormet
                      )
                    : "-",
                  hebrew_mourner_hebrew_date: item?.hebrew_mourner_hebrew_date
                    ? moment(item?.hebrew_mourner_hebrew_date).format(
                        dateFormet
                      )
                    : "-",
                  has_plaque: item?.has_plaque ? item?.has_plaque : "No",
                  yhartzeit_number: item?.yhartzeit_number
                    ? item?.yhartzeit_number
                    : "-",
                  mobile_phone: item?.mobile_phone ? item?.mobile_phone : "-",
                  home_phone: item?.home_phone ? item?.home_phone : "-",
                  email: item?.email ? item?.email : "-",
                  address: item?.address ? item?.address : "-",
                  city: item?.city ? item?.city : "-",
                  state: item?.state ? item?.state : "-",
                  zip: item?.zip ? item?.zip : "-",
                  type: item?.type ? item?.type : "-",
                  doesnt_have_mourner: item?.doesnt_have_mourner
                    ? item?.doesnt_have_mourner
                    : "-",
                };

                return (
                  <tr key={index}>
                    {Object.values(d)?.map((objectValue, index) => (
                      <td
                        style={{ border: "1px solid", padding: 5 }}
                        key={index}
                      >
                        {objectValue}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <NewDialog
          isOpen={isOpenMergePopUp}
          handleClose={() => {
            setIsOpenMergePopUp(!isOpenMergePopUp);
          }}
          loading={loadingYahirzeit}
          title={"New Yahrzeit"}
          sx={{
            "& .MuiDialog-paperWidthSm": {
              minWidth: "997.01px",
              height: "97%",
            },
          }}
          content={
            <div className="dialog-wrapper">
              <AddYahrzeit
                setIsOpenMergePopUp={setIsOpenMergePopUp}
                isOpenMergePopUp={isOpenMergePopUp}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </div>
          }
          contentStyle={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
          buttonStyle={{ justifyContent: "right" }}
        ></NewDialog>
      </div>
    </>
  );
};
export default withUserExist(Yahrzeit);
