import React from "react";
import ComingSoon from "components/ComingSoon";
import ComingSoonDonation from "assets/icons/svg/comingSoonDonation";
import { withUserExist } from "../CustomHooks";

const Donation = () => {
    return (
        <ComingSoon icon={<ComingSoonDonation />} title={"Donation"} />
    )
}

export default withUserExist(Donation)