import { useAuth0 } from "@auth0/auth0-react";
import KeyboardIcon from "@material-ui/icons/Keyboard";
// import HelpSvgComponent from "assets/icons/svg/helpSvg";
import DefaultUserOPNG from "assets/icons/default-user.png";
import { ReactComponent as PencilSVG } from "assets/icons/pencil.svg";
import ArrowSVG from "assets/icons/svg/BackArrow";
import UploadImageSvg from "assets/icons/svg/uploadImage";
import UseCameraSvg from "assets/icons/svg/useCamera";
import getCroppedImg from "components/cropImage";
import FindMyCity from "components/FindMyCity";
import { VirtualKeyboard } from "components/Keyboard";
import { toastr } from "react-redux-toastr";
//components
import ImageCropper from "components/MyImageCropper";
import MyModal from "components/MyModal";
import { variant } from "constants/enums";
import { useFormik } from "formik";
import { SubHeader } from "layout/SubHeader";
import AuthService from "Lib/API/Auth";

import { DatePickerField } from "_metronic/_partials/controls";
import BottomArrowSvg from "assets/icons/svg/BottomArrowSvg";
import axios from "axios";
import { PhonePicker } from "components/PhonePicker";
import { getDateFormat, getDateFormatForDatePicket, maskDate } from "helper/dateFormat";
import { postalCodeFormat, postalCodeLabel, stateLabel } from "helper/postalCodeFormat";
import { useSearch } from "hook";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { familyMembers } from "services/api/dashboard";
import { colors } from "theme";
import {
  HintField,
  InputField,
  LabelField,
  MyRadioGroup,
  SelectField,
  Text,
} from "../../components";
import { uploadFile } from "../auth/Presigned";
import { withUserExist } from "../CustomHooks";
import { AddNewMemberSchema } from "../ValidationSchema";
import "./style.scss";

const styles = {
  circleButtonBody: {
    background: "#FFFFFF",
    borderRadius: "50%",
    width: 20,
    height: 20,
    right: 0,
    top: 3,
    cursor: "pointer",
  },
  textIconButton: {
    color: colors.primaryColor,
    fontSize: 17,
    fontWeight: "bolder",
  },
};

const formatOptionLabel = ({ label, _id }) => {
  return (
    <div className="dropdown-container" key={_id}>
      <div className="icon-name-container d-flex align-items-center justify-content-start">
        <div className="name-container" style={{ marginInlineStart: 10 }}>
          <div>{label}</div>
        </div>
      </div>
    </div>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <BottomArrowSvg label="Emoji" />
    </components.DropdownIndicator>
  );
};

const deceased = [
  {
    label: "Parent-1",
    value: "Parent-1",
  },
  {
    label: "Parent-2",
    value: "Parent-2",
  },
  {
    label: "Parent-3",
    value: "Parent-3",
  },
  {
    label: "Adult-1",
    value: "Adult-1",
  },
  {
    label: "Adult-2",
    value: "Adult-2",
  },
  {
    label: "Adult-3",
    value: "Adult-3",
  },
  {
    label: "Child",
    value: "Child",
  },
  {
    label: "Father",
    value: "Father",
  },
  {
    label: "Mother",
    value: "Mother",
  },
  {
    label: "Brother",
    value: "Brother",
  },
  {
    label: "Sister",
    value: "Sister",
  },
  {
    label: "Wife",
    value: "Wife",
  },
  {
    label: "Husband",
    value: "Husband",
  },
  {
    label: "Father-in-law",
    value: "Father-in-law",
  },
  {
    label: "Mother-in-law",
    value: "Mother-in-law",
  },
  {
    label: "Brother-in-law",
    value: "Brother-in-law",
  },
  {
    label: "Sister-in-law",
    value: "Sister-in-law",
  },
  {
    label: "Uncle",
    value: "Uncle",
  },
  {
    label: "Aunt",
    value: "Aunt",
  },
  {
    label: "Nephew",
    value: "Nephew",
  },
  {
    label: "Niece",
    value: "Niece",
  },
  {
    label: "Grandmother",
    value: "Grandmother",
  },
  {
    label: "Grandfather",
    value: "Grandfather",
  },
  {
    label: "Great-grandmother",
    value: "Great-grandmother",
  },
  {
    label: "Great-grandfather",
    value: "Great-grandfather",
  },
  {
    label: "Cousin",
    value: "Cousin",
  },
  {
    label: "Friend",
    value: "Friend",
  },
  {
    label: "Other",
    value: "Other",
  },
];
const AddNewMember = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {disableEditingOnMemberPortal} = localStorage.getItem("organization")
  ? JSON.parse(localStorage.getItem("organization"))
  : {};    

  const [open, setOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [image, setImage] = useState(
    history.location.state?.member_to_edit?.profile_img
      ? history.location.state?.member_to_edit?.profile_img
      : DefaultUserOPNG
  );
  const [search, setSearch] = useState("");
  const { searchAction } = useSearch({ action: setSearch });
  const [imageData, setImageData] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [openCropper, setOpenCropper] = useState(false);
  const [selectfieldColor, setSelectfieldColor] = useState(true);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [member, setMember] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);
  const [openKeyboard, setOpenKeyboard] = useState(false);

  const [localFormat, setLocalFormat] = useState(false);

  const [placeID, setPlaceID] = useState("");

  const [loading, setLoading] = useState(false);
  const revertLocation = useSelector((state) => state?.CommonStates?.memberUrl);
  const { enqueueSnackbar } = useSnackbar();

  const postalLabel = postalCodeLabel();
  const stateTitle = stateLabel()
  const dateMask = maskDate();
  const placeholderFormat = getDateFormat();
  const dateFormat = getDateFormatForDatePicket();



  const checkViewOnlyHandler = () => {
    return disableEditingOnMemberPortal;
  };

  const enableLoading = (value) => {
    setLoading(value);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      image,
      croppedAreaPixels,
      rotation
    );
    setCroppedImage(croppedImage);
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const closeAllModal = async () => {
    setOpenCropper(false);
    setOpen(false);
    formik.setFieldValue && formik.setFieldValue("profile_img", croppedImage);
  };

  const handleChange = async (e) => {
    if (e.target.files.length) {
      if (e.target.files[0]?.name) setImageData(e.target.files[0]);
      let imageDataUrl = await readFile(e.target.files[0]);
      setImage(imageDataUrl);

      setTimeout(() => {
        setOpenCropper(true);
      }, 1000);
    }
  };
  useEffect(() => {
    if (history.location.state?.member_to_edit)
      setMember(history.location.state?.member_to_edit);
  }, []);

  async function getMyFamily() {
    const token = await getAccessTokenSilently();
    await AuthService.getFamilyMember(1, 9, token)
      .then((resList) => {
        const data = resList?.contacts ? resList?.contacts : [];
        console.log(data, "Asdfasdf");
        dispatch(familyMembers(data));
        history.push(
          revertLocation !== "home" ? "/family-member-table" : "/home"
        );
      })
      .catch((err) => {
        enqueueSnackbar(err?.message || "", { variant: variant.ERROR });
      });
  }

  const initialValues = {
    first_name: member?.first_name ? member?.first_name : "",
    middle_name: member?.middle_name ? member?.middle_name : undefined,
    last_name: member?.last_name ? member?.last_name : "",
    hebrew_name: member?.hebrew_name ? member?.hebrew_name : "",
    nickname: member?.nickname ? member?.nickname : "",
    email_address: member?.email_address ? member?.email_address : "",
    gender: member?.gender ? member?.gender : "",
    date_of_birth: member?.date_of_birth ? member?.date_of_birth : "",
    anniversary_date: member?.anniversary_date ? member?.anniversary_date : "",
    metva_date: member?.metva_date ? member?.metva_date : "",
    phone_number: member?.mobile_number ? member?.mobile_number : undefined,
    home_phone: member?.home_phone ? member?.home_phone : "",
    status: member?.social_status ? member?.social_status : "",
    pronounce: member?.pronounce ? member?.pronounce : "",
    state: member?.state ? member?.state : "",
    account_permission: member?.account_permission
      ? member?.account_permission
      : "",
    emergency_contact_name: member?.emergency_contact_name 
      ? member?.emergency_contact_name
      : "",
    emergency_contact_number: member?.emergency_contact_number
      ? member?.emergency_contact_number
      : "",
    title: member?.title ? member?.title : "",
    occupation: member?.occupation ? member?.occupation : "",
    religion: member?.religion ? member?.religion : "",
    location: member?.location ? member?.location : "",
    addressLine2: member?.addressLine2 ? member?.addressLine2 : "",
    city: member?.city ? member?.city : "",
    zip_code: member?.zip_code ? member?.zip_code : "",
    profile_img: member?.profile_img ? member?.profile_img : "",
    relationship: {
      id: member?.relationship?.[0],
      name: member?.relationship?.[0],
    },
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddNewMemberSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setFieldValue }) => {
      if(checkViewOnlyHandler()) return;
      const token = await getAccessTokenSilently();
      const newUrl = croppedImage
        ? await uploadFile(croppedImage, imageData, token)
        : "";
      const input = {
        contactID: member?._id,
      };

      if (values.first_name) {
        input.first_name = values.first_name;
      } else {
        input.first_name = " ";
      }
      if (newUrl.fileName) {
        input.profile_img = newUrl.fileName;
      }
      if (values.last_name) {
        input.last_name = values.last_name;
      } else {
        input.last_name = "";
      }
      if (values.account_permission) {
        input.account_permission = values.account_permission;
      }
      if (values.relationship) {
        input.relationship = [values?.relationship?.id ?? " "];
      }
      if (values.middle_name) {
        input.middle_name = values.middle_name;
      } else {
        input.middle_name = "";
      }
      if (values.hebrew_name) {
        input.hebrew_name = values.hebrew_name;
      } else {
        input.hebrew_name = "";
      }
      if (values.nickname) {
        input.nickname = values.nickname;
      } else {
        input.nickname = "";
      }
      if (values.email_address) {
        input.email_address = formik.values.email_address;
      } else {
        input.email_address = "";
      }
      if (values.gender) {
        input.gender = values.gender;
      }
      if (values.date_of_birth) {
        input.date_of_birth = values.date_of_birth;
      }
      if (values.date_of_birth === "") {
        input.date_of_birth = "";
      }
      if (values.phone_number) {
        input.mobile_number = values.phone_number;
      } else {
        input.mobile_number = "";
      }
      if (values.home_phone) {
        input.home_phone = values.home_phone;
      } else {
        input.home_phone = "";
      }
      if (values.emergency_contact_name) {
        input.emergency_contact_name = values.emergency_contact_name;
      } else {
        input.emergency_contact_name = "";
      }
      if (values.emergency_contact_number) {
        input.emergency_contact_number = values.emergency_contact_number;
      } else {
        input.emergency_contact_number = "";
      }
      if (values.title) {
        input.title = values.title;
      } else {
        input.title = "";
      }
      if (values.pronounce) {
        input.pronounce = values.pronounce;
      } else {
        input.pronounce = "";
      }
      if (values.occupation) {
        input.occupation = values.occupation;
      } else {
        input.occupation = "";
      }
      if (values.religion) {
        input.religion = values.religion;
      } else {
        input.religion = "";
      }
      if (values.city) {
        input.city = values.city;
      } else {
        input.city = "";
      }
      if (values.location) {
        input.location = values.location;
      } else {
        input.location = "";
      }
      if (values.zip_code || !values.zip_code) {
        input.zip_code = values.zip_code;
      } else {
        input.zip_code = "";
      }
      if (values.addressLine2) {
        input.addressLine2 = values.addressLine2;
      } else {
        input.addressLine2 = "";
      }
      if (values.status) {
        input.social_status = values.status;
      } else {
        input.social_status = "";
      }
      if (values.state) {
        input.state = values.state;
      } else {
        input.state = "";
      }
      if (values.metva_date) {
        input.metva_date = values.metva_date;
      }
      if (values.metva_date === "") {
        input.metva_date = "";
      }
      if (values.anniversary_date) {
        input.anniversary_date = values.anniversary_date;
      }
      if (values.anniversary_date === "") {
        input.anniversary_date = "";
      }
      if (
        formik?.values.email_address &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          formik?.values.email_address
        )
      ) {
        toastr.info("Validation", "You have entered an invalid email address!");
      }

      const validMobilePhone = isValidPhoneNumber(input.mobile_number || "");
      if (input.mobile_number && !validMobilePhone) {
        toastr.info("Validation", "Invalid Mobile phone");
      }
      const validHomePhone = isValidPhoneNumber(input.home_phone || "");
      if (input.home_phone && !validHomePhone) {
        toastr.info("Validation", "Invalid Home phone");
      }
      const validEmergencyPhone = isValidPhoneNumber(input.emergency_contact_number || "");
      if (input.emergency_contact_number && !validEmergencyPhone) {
        toastr.info("Validation", "Invalid Emergency contact number");
      }
      const validZipCode = postalCodeFormat(input.zip_code || "");
      if (input.zip_code && !validZipCode) {
        toastr.info("Validation", `Invalid ${postalLabel}`);
      }


      if (
        (formik?.values.email_address &&
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            formik?.values.email_address
          )) ||
        !formik?.values.email_address
      ) {
        enableLoading(true);
        await AuthService.updateMember(input, token)
          .then(async (res) => {
            if (res.message) {
              enqueueSnackbar(res.message, { variant: variant.ERROR });
              enableLoading(false);
            } else {
              getMyFamily();
              enableLoading(false);
            }
          })
          .catch((err) => {
            enableLoading(false);
            enqueueSnackbar(err?.message || "", { variant: variant.ERROR });
          });
      }
    },
  });

  const addressHandleChange = (address) => {
    formik.setFieldValue("location", {
      formattedAddress: address?.label,
      place_id: address?.value?.place_id,
    });
    setPlaceID(address?.value?.place_id);

    const sortedaddress = address?.label.split(",");
    if (sortedaddress.length == 2) {
      formik.setFieldValue("state", sortedaddress.at(-1));
      formik.setFieldValue("city", sortedaddress.at(-2));
    } else {
      formik.setFieldValue("state", sortedaddress.at(-2));
      formik.setFieldValue("city", sortedaddress.at(-3));
    }
  };
  const getZipCode = async () => {
    if (placeID) {
      const result = await axios(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeID}&key=AIzaSyCAt0TjvU_m8ufUMYn2EXi9mteHSOUB7uA`
      );      
      result?.data?.results?.[0]?.address_components?.length && result.data.results[0].address_components.map((item) => {
        if (item.types[0] == "postal_code") {
          formik.setFieldValue("zip_code", item?.short_name);
        }
      });
    }
  };

  useEffect(() => {
    getZipCode();
  }, [placeID]);
  console.log(formik?.values, "myy");
  
  useEffect(() => {
    calculate_age();
  }, [formik?.values?.date_of_birth]);

  const calculate_age = () => {
    var today = new Date();
    var birthDate = new Date(formik?.values?.date_of_birth); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }

    console.log(age_now);
    if (isNaN(age_now)) {
      formik.setFieldValue("age", "");
    } else {
      formik.setFieldValue("age", `${age_now} Years`);
    }
    // return age_now;
  };


  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
    return date;
  }

  function convertLocalToUTCDate(date, name) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    formik.setFieldValue(name, date);
    return date;
  }


  return (
    <>
      <div className="first-time add-member" style={{ margin: "0 30px" }}>
        <div className="w-100">
          <div
            className="col-12 d-flex align-items-center"
            style={{ marginTop: "15px", padding: "0px" }}
          >
            <ArrowSVG
              style={{ cursor: "pointer" }}
              onClick={() => history.goBack()}
            />
            <SubHeader
              {...{
                title: !member?.id ? "Add new family member" : "Edit Member",
              }}
            />
          </div>

          <div className="first-time w-100">
            <Card className="card card-custom pl-8 my-10">
              <div className="login-form login-signin" style={{
                 pointerEvents: checkViewOnlyHandler() ? "none" : "auto",
              }}>
                <form
                  onSubmit={formik.handleSubmit}
                  className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                  <div
                    className="pt-8 pb-4 d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <LabelField title="Account permission" className="mb-0" />
                    <MyRadioGroup
                      row
                      // disabled={account?.main_contact?._id === user?._id}
                      handleChange={(val) =>
                        formik.setFieldValue &&
                        formik.setFieldValue(
                          "account_permission",
                          val.target.value
                        )
                      }
                      value={formik.values.account_permission}
                      name="account_permission"
                      className="ml-5"
                      formsControls={[
                        { value: "family_member", label: "Member" },
                        { value: "admin", label: "Admin" },
                      ]}
                    />
                  </div>
                  <div className="sub-title">
                    <Text style={styles.textIconButton}>General info</Text>

                    <hr />
                  </div>
                  <div
                    className="d-flex w-75 justify-content-between"
                    style={{ paddingRight: "14%" }}
                  >
                    <div className="pb-5">
                      <div className="row mb-5">
                        <div className="col-sm-12">
                          <label
                            style={{
                              fontSize: 15,
                              fontWeight: "500",
                              marginBottom: 1,
                            }}
                          >
                            Profile Image
                          </label>
                        </div>
                        <div className="position-relative">
                          <div
                            className={
                              image !== DefaultUserOPNG
                                ? "image-container"
                                : undefined
                            }
                          >
                            <img
                              alt="profile"
                              src={
                                (croppedImage &&
                                  URL.createObjectURL(croppedImage)) ||
                                image
                              }
                              style={{ objectFit: "fill" }}
                              height="102"
                              width="99"
                              className={
                                image !== DefaultUserOPNG ? "image" : undefined
                              }
                              name="profile_img"
                            />
                          </div>
                          <div
                            className="shadow text-center justify-content-center align-items-center position-absolute"
                            style={{
                              ...styles.circleButtonBody,
                              right: image !== DefaultUserOPNG ? -7 : 0,
                            }}
                            onClick={handleOpen}
                          >
                            <PencilSVG />
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <Text style={{ color: "#B5B5C3", fontSize: 12 }}>
                            Allowed file types: png, jpg, jpeg.
                          </Text>
                        </div>
                        {formik.touched["profile_img"] &&
                          formik.errors["profile_img"] ? (
                          <HintField
                            error={formik.errors["profile_img"]}
                            className="col-sm-12"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col className="col-sm-3">
                      <InputField
                        {...{
                          formik,
                          title: "First Name",
                          name: "first_name",
                          placeholder: "First name",
                          checkValidation: true,
                          value: formik.values?.first_name,
                        }}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <InputField
                        {...{
                          formik,
                          title: "Middle Name",
                          name: "middle_name",
                          placeholder: "Middle name",
                          value: formik.values?.middle_name,
                        }}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <InputField
                        {...{
                          formik,
                          title: "Last Name",
                          name: "last_name",
                          placeholder: "Last name",
                          checkValidation: true,
                          value: formik.values?.last_name,
                        }}
                      />
                    </Col>
                    {openKeyboard && (
                      <VirtualKeyboard
                        handleChange={(e) =>
                          formik.setFieldValue("hebrew_name", e)
                        }
                        value={formik.values?.hebrew_name}
                        setOpenKeyboard={setOpenKeyboard}
                      />
                    )}
                    <Col className="col-sm-3">
                      <InputField
                        setOpenKeyboard={setOpenKeyboard}
                        openKeyboard={openKeyboard}
                        {...{
                          formik,
                          title: "Hebrew Name",
                          name: "hebrew_name",
                          placeholder: "Click on icon to type",
                          value: formik.values?.hebrew_name,
                        }}
                        // onKeyDown={validateHebrew}
                        handleChange={(e) => {
                          formik.setFieldValue("hebrew_name", e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-sm-3">
                      <InputField
                        {...{
                            formik,
                            title: "Nickname",
                            name: "nickname",
                            placeholder: "Nickname",
                            checkValidation: true,
                            value: formik.values?.nickname,
                        }}
                      />
                    </Col> 
                  </Row>
                  <Row>
                    <Col className="col-sm-3">
                      <SelectField
                        {...{
                          formik,
                          title: "Title",
                          name: "title",
                          items: ["Mr", "Miss", "Mrs", "Ms", "Dr", "other"],
                          value: formik.values.title,
                          placeholder: "Select Title",
                        }}
                        selectfieldColor={selectfieldColor}
                        onClick={(data) => formik.setFieldValue("title", data)}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <SelectField
                        {...{
                          formik,
                          title: "Sex",
                          name: "gender",
                          items: ["Male", "Female", "Other"],
                          value: formik.values.gender,
                          placeholder: "Select Sex",
                        }}
                        selectfieldColor={selectfieldColor}
                        onClick={(data) => formik.setFieldValue("gender", data)}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <SelectField
                        {...{
                          formik,
                          title: "Pronouns",
                          name: "pronounce",
                          items: ["He/Him", "She/Her", "They/Them"],
                          value: formik?.values?.pronounce,
                        }}
                        selectfieldColor={selectfieldColor}
                        onClick={(data) =>
                          formik.setFieldValue("pronounce", data)
                        }
                      />
                    </Col>

                    <Col className="col-sm-3">
                      <SelectField
                        {...{
                          formik,
                          title: "Status",
                          name: "status",
                          items: ["Single", "Married", "Life Partner"],
                          value: formik.values.status,
                          placeholder: "Select Status",
                        }}
                        selectfieldColor={selectfieldColor}
                        onClick={(data) => formik.setFieldValue("status", data)}
                      />
                    </Col>
                  </Row>
                  <div className="sub-title">
                    <Text style={styles.textIconButton}>Contact info</Text>
                    <hr />
                  </div>
                  <Row>
                    <Col className="col-sm-3">
                      <LabelField title={"Mobile Phone"} />
                      <PhonePicker
                        value={formik.values.phone_number}
                        handleChange={(e) => {
                          formik.setFieldValue("phone_number", e);
                        }}
                        placeholder="Mobile Phone"
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <LabelField title={"Home Phone"} />
                      <PhonePicker
                        value={formik.values.home_phone}
                        handleChange={(e) => {
                          formik.setFieldValue("home_phone", e);
                        }}
                        placeholder="Home Phone"
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <InputField
                        type="email"
                        {...{
                          formik,
                          title: "Email Address",
                          name: "email_address",
                          placeholder: "abc@xyz.com",
                          // checkValidation: !!formik.values.email_address,
                          value: formik.values?.email_address,
                        }}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <InputField
                        type="text"
                        {...{
                          formik,
                          title: "Emergency Contact Name",
                          name: "emergency_contact_name",
                          placeholder: "Emergency Contact Name",
                          checkValidation: !!formik.values.emergency_contact_name,
                          value: formik.values?.emergency_contact_name,
                        }}
                        handleChange={(e) => {
                          formik.setFieldValue("emergency_contact_name", e.target.value);
                        }}
                      />
                    </Col>
                  
                  </Row>
                  <Row>
                    <Col className="col-sm-3">
                      <LabelField title={"Emergency contact number"} />
                      <PhonePicker
                        value={formik.values.emergency_contact_number}
                        handleChange={(e) => {
                          formik.setFieldValue("emergency_contact_number", e);
                        }}
                        placeholder="Emergency contact number"
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <FindMyCity
                        handleChange={addressHandleChange}
                        title="Address"
                        value={{
                          label: `${formik.values?.location?.formattedAddress
                            ? formik.values?.location?.formattedAddress
                            : "Address 1"
                            }`,
                          value: `${formik.values?.location?.place_id}`,
                        }}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <InputField
                        {...{
                          formik,
                          title: "Address line 2 (Optional)",
                          name: `addressLine2`,
                          placeholder: "Apt, suite, unit, floor, company",
                          value: formik.values?.addressLine2,
                        }}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <InputField
                        {...{
                          formik,
                          title: "City",
                          name: "city",
                          placeholder: "Enter City",
                          value: formik.values?.city,
                        }}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <InputField
                        {...{
                          formik,
                          title: stateTitle,
                          name: "state",
                          placeholder: "NY",
                          checkValidation: true,
                          value: formik.values?.state,
                        }}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <InputField
                        {...{
                          formik,
                          title: postalLabel,
                          type: "text",
                          name: "zip_code",
                          placeholder: "Zip Code",
                          value: formik.values?.zip_code,
                        }}
                      />
                    </Col>
                  </Row>
                  <div className="sub-title">
                    <Text style={styles.textIconButton}>Additional info</Text>
                    <hr />
                  </div>
                  <Row>
                    <Col className="col-sm-3 cityField">
                      <InputField
                        {...{
                          formik,
                          title: "Occupation",
                          name: "occupation",
                          placeholder: "Occupation",
                          value: formik.values?.occupation,
                        }}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <SelectField
                        {...{
                          formik,
                          title: "Religion",
                          name: "religion",
                          items: ["Jewish", "Converting", "Other"],
                          value: formik.values.religion,
                          placeholder: "Select Religion",
                        }}
                        selectfieldColor={selectfieldColor}
                        onClick={(data) =>
                          formik.setFieldValue("religion", data)
                        }
                      />
                    </Col>
                    <Col className="col-sm-3 select RelationshipField">
                      <LabelField title="Relationship" />

                      <Select
                        // {...{
                        //   formik,
                        //   name: `memberName`,
                        //   checkValidation: false,
                        // }}
                        onInputChange={(text) => searchAction(text)}
                        classNamePrefix="react-select"
                        formatOptionLabel={formatOptionLabel}
                        options={deceased}
                        isLoading={loading}
                        // styles={colourStyles}
                        onChange={(value) => {
                          formik.setFieldValue(`relationship`, {
                            id: value.value,
                            name: value.label,
                          });
                        }}
                        value={
                          formik?.values?.relationship
                            ? {
                              label: formik?.values?.relationship?.name,
                              value: formik?.values?.relationship?.id,
                            }
                            : ""
                        }
                        components={{ DropdownIndicator }}
                      // isDisabled={index > 0}
                      />
                    </Col>
                    {/* <MyCheckbox title={"ahmad"} name={"ahmad"} /> */}
                  </Row>
                  <div className="sub-title ">
                    <Text style={styles.textIconButton}>Life cycle events</Text>
                    <hr />
                  </div>
                  <Row>
                    <Col className="col-sm-3">
                    <LabelField title={"Date of Birth"} />
                    <DatePicker
                        isClearable
                        showIcon
                        placeholderText={placeholderFormat}
                        dateFormat={dateFormat}
                        selected={
                          formik?.values?.date_of_birth &&
                          convertUTCToLocalDate(formik?.values?.date_of_birth)
                        }
                        onChange={(e) => {
                          formik.setFieldValue(`date_of_birth`, e);
                          setLocalFormat(true);
                          convertLocalToUTCDate(e, "date_of_birth");
                        }}
                        
                      />
                     
                    </Col>
                    <Col className="col-sm-3">
                      <InputField
                        {...{
                          formik,
                          title: "Age",
                          name: "age",
                          placeholder: "Age",
                          // type: "number",
                          disabled: true,
                          value:
                            formik?.values?.age === NaN ? "" : formik?.values?.age,
                        }}
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <LabelField title={"Anniversary"} />
                      <DatePicker
                          isClearable
                          showIcon
                          placeholderText={placeholderFormat}
                          dateFormat={dateFormat}
                          selected={
                            formik?.values?.anniversary_date &&
                            convertUTCToLocalDate(formik?.values?.anniversary_date)
                          }
                          onChange={(e) => {
                            formik.setFieldValue(`anniversary_date`, e);
                            setLocalFormat(true);
                            convertLocalToUTCDate(e, "anniversary_date");
                          }}
                          
                      />
                    </Col>
                    <Col className="col-sm-3">
                      <LabelField title={"Bar/Bat Mitzvah"} />
                      <DatePicker
                          isClearable
                          showIcon
                          placeholderText={placeholderFormat}
                          dateFormat={dateFormat}
                          selected={
                            formik?.values?.metva_date &&
                            convertUTCToLocalDate(formik?.values?.metva_date)
                          }
                          onChange={(e) => {
                            formik.setFieldValue(`metva_date`, e);
                            setLocalFormat(true);
                            convertLocalToUTCDate(e, "metva_date");
                          }}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center justify-content-end flex-wrap mr-5">
                    <div className="form-group my-4">
                      <button
                        onClick={() => history.push("/home")}
                        type="button"
                        className={`btn btn-transparent text-primary px-2 py-4 my-3 w-100`}
                      >
                        <span className="button-style">Cancel</span>
                      </button>
                    </div>
                    <div className="form-group my-4">
                      <button
                        onClick={formik.handleSubmit}
                        type="submit"
                        disabled={checkViewOnlyHandler()}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3 w-100 ml-5`}
                      >
                        <span style={{ fontSize: 13, fontWeight: 700 }}>
                          Save
                        </span>
                        {loading && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Card>
          </div>
        </div>
        <MyModal open={open} onClose={handleClose}>
          <div className="modal-body text-center">
            <Text style={{ fontWeight: "bolder", fontSize: 18 }}>
              Add/Change Profile
            </Text>
          </div>
          <div>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleChange}
            />
            <label htmlFor="raised-button-file">
              <div className="mt-12">
                <UploadImageSvg />
              </div>
            </label>
          </div>
        </MyModal>

        {/* <div className="main-div-model"> */}
        <div className="personal-info-image">
          <ImageCropper
            modelclassName="upload-photo"
            open={openCropper}
            handleClose={closeAllModal}
            title="Upload Photo"
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1 / 1}
            closeAllModal={closeAllModal}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            saveButton="Save"
            cancelButton="Cancel"
          />
        </div>
        {/* </div> */}
      </div>
      {/* <div className="bottom-help-svg">
        <HelpSvgComponent />
      </div> */}
    </>
  );
};
export default withUserExist(AddNewMember);
