import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import './style.scss';

const DonationStepper = ({ activeStep = 0 }) => {
  return (
    <div className="donation__stepper">
      <Stepper activeStep={activeStep}>
        {['Select a Cause', 'Enter Details', 'Pay'].map((step) => (
          <Step key={step}>
            <StepLabel className="step_label_donation">{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default DonationStepper;
 