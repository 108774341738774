import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import { AlertMessage } from 'components/Alert';
import moment from "moment/min/moment-with-locales";
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventCalendarItem from './EventCalendarItem';
import { CopyAlert, EventsCalendarContainer, EventsCalendarHeader, EventsCalendarTitle, EventsCalendarWrapper } from './Theme';

const localizer = momentLocalizer(moment);


const EventsCalendar = ({events,withoutTitle,width}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [copyLinkSuccess, setCopyLinkSuccess] = React.useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopyLinkSuccess(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [copyLinkSuccess]);



  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsOpen(true);
  }

  return (
    <EventsCalendarContainer>
      <EventsCalendarWrapper width={width}>
        <EventsCalendarHeader>
          {!withoutTitle && <EventsCalendarTitle>Events Calendar</EventsCalendarTitle>}
        </EventsCalendarHeader>
        <Calendar
        views={["day","week", "month"]}
          selectable
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={events}
          onSelectEvent={handleEventClick}
          // onSelectSlot={handleSelect}
          eventPropGetter={
            (event, start, end, isSelected) => {
              let newStyle = {
                backgroundColor: "#2555EF",
                color: '#FFFFFF',
                border: "none"
              };
        
              if (event.isMine){
                newStyle.backgroundColor = "lightgreen"
              }
        
              return {
                className: "",
                style: newStyle
              };
            }
          }
        />
      </EventsCalendarWrapper>
      <Dialog
        className="my-modal"
        onClose={
          () => {
            setIsOpen(false);
            setSelectedEvent(null);
          }
        }
        open={isOpen}
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      ><Fade in={isOpen}>
          <EventCalendarItem
            event={selectedEvent}
            setCopyLinkSuccess={setCopyLinkSuccess}
          />
        </Fade>
      </Dialog>
      {copyLinkSuccess && (
        <CopyAlert>
          <AlertMessage type="success" content="URL Copied Successfully" />
        </CopyAlert>
      )}
    </EventsCalendarContainer>

  )
}

export default EventsCalendar