import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLeftDrawer: false,
  showRightDrawer: false,
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    toggleDrawer: (state, { payload: { type, show } }) => {
      switch (type) {
        case "left": {
          state.showLeftDrawer =
            show !== undefined ? show : !state.showLeftDrawer;
          return state;
        }
        case "right": {
          state.showRightDrawer =
            show !== undefined ? show : !state.showRightDrawer;
          return state;
        }
        default: {
          return state;
        }
      }
    },
  },
});

export const { toggleDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
