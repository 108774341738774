import LocationSvg from "assets/icons/svg/localtion";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "./index.scss";

const FindMyCity = ({ rowTitle, title, value, handleChange }) => {
  const country = JSON.parse(
    localStorage.getItem("country") !== "undefined"
    ? localStorage.getItem("country")
    : "{}"
  );
  const DropdownIndicator = (props) => {
    return (
      <div className="px-2">
        <LocationSvg label="Emoji" />
      </div>
    );
  };

  const countryCode = useMemo(() => {
    return country?.code || "us";
  }, [country]);


  return (
    <div className="map-title">
      <div style={{ fontSize: "14px", marginBottom: "10px" }}>{title}</div>
      <div>
        <Row>
          {rowTitle && (
            <Col xs={4}>
              <div className="container-title">{rowTitle}</div>
            </Col>
          )}
          <Col>
            <GooglePlacesAutocomplete
              className="d-flex form-control form-control-solid px-6 pt-8 pb-9 align-items-center"
              apiKey={"AIzaSyCAt0TjvU_m8ufUMYn2EXi9mteHSOUB7uA"}
              selectProps={{
                value,
                onChange: handleChange,
                components: {
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                },
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: [countryCode?.toLowerCase()],
                },
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FindMyCity;
