import styled, { css } from "styled-components";

export const Tabs = styled.div`
	display: flex;
`;
export const TabItem = styled.div`
	font-size: 16px;
	font-weight: 400;
  text-align: center;
  width: ${(props) => (props.width ? props.width : "9rem")};
  font-size: 15px;
  cursor: pointer;
  margin: 0;
	color: ${(props) => (props.selected ? "#3f4255" : "#b9b9c6")};
	${(props) =>
		props.selected &&
		css`
			&:after {
				content: "";
				display: block;
				margin: 0 auto;
				margin-top: 5px;
				width: 100%;
				border-bottom: 4px solid #2555ef;
				border-radius: 6px;
			}
		`}
`;
