/* eslint-disable jsx-a11y/alt-text */
import MenuTabs from 'components/MenuTabs/MenuTabs';
import { SubHeader } from 'layout/SubHeader';
import React from 'react';
import { withUserExist } from '../CustomHooks';
import AvailableClassList from './AvailableClassList';
import ClassList from './ClassList';
import './index.scss';


const tabs = [
  {
    label: "My Classes",
    value: "my-classes",
  },
  {
    label: " Available Classes",
    value: "available-classes",
  }, 
]

const School = () => {
  const [activeTab, setActiveTab] = React.useState("my-classes");

  const onTabsChange = (tab) => {
    setActiveTab(tab);
  };



  return (
    <div className="school-classes w-100 m-0 p-5">
      <div className="p-5 borderBottom m-0">
        <SubHeader
          className="class-tab subheader"
          {...{
            title: "Education",
          }}
        />
      </div>
      <div className='education-tabs mt-10'>
        <MenuTabs 
          tabs={tabs}
          activeTab={activeTab}
          onTabsChange={onTabsChange}
          tabWidth='15rem'
        />
      </div>
      {activeTab === 'my-classes' ? <ClassList /> : <AvailableClassList />}
    </div>
  );
};

export default withUserExist(School);

