import React from "react";
import { LabelField } from ".";
export const MyCheckbox = ({
  title,
  checked,
  name,
  handlechange,
  ...props
}) => {
  return (
    <div className="d-flex" style={{ alignItems: "baseline" }}>
      <label className="checkbox checkbox-lg checkbox-single mr-4" style={props.style}>
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handlechange}

        />
        <span />
      </label>
      <span>
        <LabelField title={title} />
      </span>
    </div>
  );
};
