import React, { useState } from "react";
import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { colors } from "theme";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import AuthService from "Lib/API/Auth";
import { setStepTwo } from "../../services/api/formSteps";
import MyModal from "components/Modal";
import ImageCropper from "components/ImageCropper";
import getCroppedImg from "components/cropImage";
import { DatePickerField } from "_metronic/_partials/controls";
import {
  Text,
  SelectField,
  InputField,
  LabelField,
  IconButton,
} from "components";
import { ReactComponent as PencilSVG } from "assets/icons/pencil.svg";
import { ReactComponent as ArrowRightSVG } from "assets/icons/arrow-right.svg";
import UseCameraSvg from "assets/icons/svg/useCamera";
import UploadImageSvg from "assets/icons/svg/uploadImage";
import DefaultUserOPNG from "assets/icons/default-user.png";
import { PhonePicker } from "components/PhonePicker";
import { Step2Schema } from "../ValidationSchema/index";
import { downloadFile, uploadFile } from "./Presigned";

import "./style.scss";
import { toastr } from "react-redux-toastr";

const styles = {
  circleButtonBody: {
    background: "#FFFFFF",
    borderRadius: "50%",
    width: 20,
    height: 20,
    right: 0,
    top: 3,
    cursor: "pointer",
  },
  textIconButton: {
    color: colors.primaryColor,
    fontWeight: "600",
    fontSize: 13,
  },
};

export const FirstTime_2 = (props) => {
  const dispatch = useDispatch();
  const stepTwoData = useSelector((state) => state.stepForms.step_two);
  const { getAccessTokenSilently, user } = useAuth0();

  const [open, setOpen] = useState(false);
  const [openCropper, setOpenCropper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);

  const fetchImage = async () => {
    if (stepTwoData?.image_name && stepTwoData.profile_img === image) {
      const token = await getAccessTokenSilently();
      const img = await downloadFile(stepTwoData.image_name, token);
      return img.link;
    }
    return DefaultUserOPNG;
  };
  const [image, setImage] = useState(
    stepTwoData?.profile_img || DefaultUserOPNG
  );
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const enableLoading = (value) => {
    setLoading(value);
  };

  const handelNextPage = () => {
    props.setCurrentStepCheck({ type: "next" });
  };
  // const handelBackPage = () => {
  //   props.setCurrentStepCheck({ type: "back" });
  // };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const handleChange = async (e) => {
    if (e.target.files.length) {
      if (e.target.files[0]?.name) setImageData(e.target.files[0]);
      let imageDataUrl = await readFile(e.target.files[0]);
      setImage(imageDataUrl);
      setOpen(false);
      setTimeout(() => {
        setOpenCropper(true);
      }, 100);
    }
  };

  const initialValues = {
    profile_img: stepTwoData.profile_img || "",
    gender: stepTwoData.gender || "",
    date_of_birth: stepTwoData.date_of_birth
      ? moment(stepTwoData.date_of_birth)
          .utcOffset("+0100")
          .format("DD/MM/YYYY")
      : "",
    phone_number: stepTwoData.phone_number || "",
    email_address: stepTwoData.email_address || user.email || "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Step2Schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading(true);
      const token = await getAccessTokenSilently();
      const newUrl = croppedImage
        ? await uploadFile(croppedImage, imageData, token)
        : "";
      // const fetBlob = () => {
      //   fetch.remote(newUrl).then((data) => {
      //     return data[1]
      //   }).catch()
      // }
      const input = {};

      if (typeof newUrl.link === "string" || stepTwoData.image_name) {
        input.profile_img = newUrl.fileName || stepTwoData.image_name;
      }
      if (values.gender) {
        input.gender = values.gender;
      }
      if (values.date_of_birth) {
        input.date_of_birth = moment(values.date_of_birth)
          .utcOffset("+0100")
          .format("YYYY/MM/DD");
      }
      if (values.phone_number) {
        input.phone_number = values.phone_number;
      }
      if (values.email_address) {
        input.email_address = values.email_address;
      }
      dispatch(
        setStepTwo({
          ...input,
          date_of_birth: values.date_of_birth,
          profile_img:
            typeof newUrl?.link === "string"
              ? newUrl?.link
              : stepTwoData.profile_img
              ? stepTwoData.profile_img
              : "",
          image_name: newUrl?.fileName || stepTwoData.image_name || "",
        })
      );
      await AuthService.updateStep(input, token)
        .then((res) => {
          enableLoading(false);
          handelNextPage();
        })
        .catch((err) => {
          enableLoading(false);
          toastr.error('Error', err?.data?.message);
        });
    },
  });

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      image,
      croppedAreaPixels,
      rotation
    );
    setCroppedImage(croppedImage);
  };

  const closeAllModal = async () => {
    setOpenCropper(false);
    setOpen(false);
    // const token = await getAccessTokenSilently();
  };

  return (
    <>
      <div>
        <div className="login-form login-signin login-signin-d">
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            <div className="row text-center justify-content-center mb-5">
              <div className="col-sm-12">
                <Text>Profile Photo</Text>
              </div>
              <div className="position-relative">
                <div
                  className={
                    image !== DefaultUserOPNG || stepTwoData?.profile_img
                      ? "image-container"
                      : undefined
                  }
                >
                  <img
                    alt=""
                    src={
                      (croppedImage && URL.createObjectURL(croppedImage)) ||
                      image
                    }
                    style={{ objectFit: "fill" }}
                    height="102"
                    width="99"
                    className={
                      image !== DefaultUserOPNG || stepTwoData?.profile_img
                        ? "image"
                        : undefined
                    }
                    name="profile_img"
                  />
                </div>
                <div
                  className="shadow justify-content-center align-align-items-center position-absolute"
                  style={{
                    ...styles.circleButtonBody,
                    right:
                      image !== DefaultUserOPNG || stepTwoData?.profile_img
                        ? -7
                        : 0,
                  }}
                  onClick={handleOpen}
                >
                  <PencilSVG />
                </div>
              </div>

              <div className="col-sm-12">
                <Text style={{ color: "#B5B5C3", fontSize: 13 }}>
                  Allowed file types: png, jpg, jpeg.
                </Text>
              </div>
            </div>
            <SelectField
              {...{
                formik,
                title: "Gender",
                name: "gender",
                items: ["Male", "Female", "Other"],
                value: formik.values.gender,
                placeholder: "Select gender",
              }}
              onClick={(data) => formik.setFieldValue("gender", data)}
            />
            <DatePickerField
              {...{ formik, title: "Date of Birth", name: "date_of_birth" }}
            />
            <InputField
              {...{
                formik,
                title: "Email Address",
                name: "email_address",
                checkValidation: !!formik.values.email_address,
                placeholder: "Enter email address",
              }}
            />
            {/* <InputField
              {...{
                formik,
                title: "Phone Number",
                name: "phone_number",
                checkValidation: !!formik.values.phone_number,
                placeholder: "Enter phone number",
              }}
            /> */}
            <LabelField title={"Phone Number"} />
            <PhonePicker
              value={formik.values.phone_number}
              handleChange={(e) => {
                formik.setFieldValue("phone_number", e);
              }}
            />
            <div className="d-flex align-items-center justify-content-center">
              {/* <IconButton onClick={handelBackPage}>
                <ArrowLeftSVG className="mx-2" />
                <Text
                  style={{
                    ...styles.textIconButton,
                  }}
                >
                  Back
                </Text>
              </IconButton> */}
              <div className="form-group mx-5 my-4 col-sm-6 col-lg-6">
                <button
                  type="submit"
                  disabled={!formik.isValid}
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3 w-100 mx-auto`}
                >
                  <span style={{ fontSize: 13, fontWeight: "600" }}>Next</span>
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
              <IconButton type="submit">
                <Text
                  style={{
                    ...styles.textIconButton,
                  }}
                >
                  Skip
                </Text>
                <ArrowRightSVG className="mx-2" />
              </IconButton>
            </div>
          </form>
        </div>
      </div>

      <MyModal open={open} onClose={handleClose}>
        <div className="modal-body text-center">
          <Text style={{ fontWeight: "bolder", fontSize: 18 }}>
            Add/Change Profile
          </Text>
        </div>
        <div>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleChange}
          />
          <label htmlFor="raised-button-file">
            <div className="mt-12">
              <UploadImageSvg />
            </div>
          </label>
          <div className="mt-2 mb-2">
            <UseCameraSvg />
          </div>
        </div>
      </MyModal>

      {openCropper && (
        <div className="main-div-model">
          <div className="main-image-cropper">
            <ImageCropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1 / 1}
              closeAllModal={closeAllModal}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        </div>
      )}
    </>
  );
};
