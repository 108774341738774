import React, { useEffect } from "react";
import { useHistory } from "react-router";

export function withUserExist(Component) {
  const Restriction = (props) => {
    const history = useHistory();

    const isUserExist = JSON.parse(
      localStorage.getItem("checkUser") !== undefined
        ? localStorage.getItem("checkUser")
        : "{}"
    );
    const isUserCheck = () => {
      if (!isUserExist) {
        return history.push("/");
      }
    };
    useEffect(() => {
      isUserCheck();
    }, []);
    return <Component {...props} />;
  };
  return Restriction;
}
