import { Grid } from '@material-ui/core';
import { ReactComponent as InfoIcon } from 'assets/icons/InfoIcon.svg';
import { InputField } from 'components/InputField/InputField';
import { postalCodeLabel } from 'helper/postalCodeFormat';
import React from 'react';
import { GridItem, PersonalDetailsTitle, PersonalDetailsWrapper } from './Theme';

export const PersonalDetailsCard = ({ formik,checkIsStripe }) => {
    const postalLabel = postalCodeLabel();
    const isFirstNameExist = !formik?.values?.accountInfo?.first_name || formik?.values?.accountInfo?.first_name === ""
    const isLasttNameExist = !formik?.values?.accountInfo?.last_name || formik?.values?.accountInfo?.last_name === ""
    const isEmailExist = !formik?.values?.accountInfo?.email || formik?.values?.accountInfo?.email === ""
    const isZipExist = !formik?.values?.cardsDetails?.zip || formik?.values?.cardsDetails?.zip === ""


    const handleInputFields = (e, name) => {
        let value = e.target.value;
        value = value.replace(/[^A-Za-z]/gi, "");
        formik.setFieldValue(name, value);
    }
    return (

        <PersonalDetailsWrapper>
            <PersonalDetailsTitle><span>Billing Details </span><InfoIcon /></PersonalDetailsTitle>
            <Grid container spacing={3} >
                <Grid item xs={6}>
                    <GridItem>

                        <InputField
                            isRequired={true}
                            labelStyle={{ color: isFirstNameExist && 'var(--Gray-100, #B5B5C3)', fontSize: '14px', fontWeight: 500 }}
                            placeholder='First Name' label='First Name'
                            styleWrapper={{ width: '100%' }}
                            style={{ width: '100%' }}
                            isOptional={false}
                            value={formik?.values?.accountInfo?.first_name}
                            name="first_name"
                            handleChange={(e) => {
                                handleInputFields(e, 'accountInfo.first_name')
                            }}
                        />

                    </GridItem>
                </Grid>
                <Grid item xs={6}>
                    <InputField
                        isRequired={true}
                        labelStyle={{ color: isLasttNameExist && 'var(--Gray-100, #B5B5C3)', fontSize: '14px', fontWeight: 500 }}
                        placeholder='Last Name' label='Last Name' styleWrapper={{ width: '100%' }}
                        style={{ width: '100%' }}
                        isOptional={false}
                        value={formik?.values?.accountInfo?.last_name}
                        name="last_name"
                        handleChange={(e) => {
                            handleInputFields(e, 'accountInfo.last_name')
                        }}
                    />
                </Grid>
                <Grid item xs={checkIsStripe() ? 12 : 6}>
                    <GridItem>
                        <InputField
                            isRequired={true}
                            labelStyle={{ color: isEmailExist && 'var(--Gray-100, #B5B5C3)', fontSize: '14px', fontWeight: 500 }}
                            placeholder='Email' label='Email'
                            styleWrapper={{ width: '100%' }}
                            style={{ width: '100%' }}
                            isOptional={false}
                            handleChange={(e) => {
                                formik.setFieldValue("accountInfo.email", e?.target?.value);
                            }}
                            value={formik?.values?.accountInfo?.email}
                        />
                    </GridItem>
                </Grid>
                {!checkIsStripe() && (
                    <Grid item xs={6}>
                        <GridItem>
                            <InputField
                                isRequired={true}
                                labelStyle={{ color: isZipExist && 'var(--Gray-100, #B5B5C3)', fontSize: '14px', fontWeight: 500 }}
                                placeholder={postalLabel}
                                label={postalLabel}
                                styleWrapper={{ width: '100%' }}
                                style={{ width: '100%' }}
                                type="string"
                                isOptional={false}
                                handleChange={(e) => {
                                    formik.setFieldValue("cardsDetails.zip", e?.target?.value);
                                }}
                                value={formik?.values?.cardsDetails?.zip}
                            />
                        </GridItem>
                    </Grid>
                )}
            </Grid>
        </PersonalDetailsWrapper>

    );
};
