import TextField from "@material-ui/core/TextField";
import { HintField, LabelField } from "components";
import { formatDate, getDateFormat, getDateFormatForDatePicket } from "helper/dateFormat.js";
import moment from "moment";
import React, { memo, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { DateFormetContext } from "../../../../app/App.js";
import "./dateStyle.scss";

export const DatePickerField = memo(
  ({
    title,
    name,
    style,
    type,
    rules,
    value,
    error,
    onChange,
    datePickerClass,
    formik,
    checkValidation = true,
    index,
    objName,
    minDate,
    disabled = false,
    mandatory,
    financeDateFields,
    localFormat,
    financeFilterDate,
    newDate,
    ...rest
  }) => {
    const placeholderFormat = getDateFormat();
    const dateFormat = getDateFormatForDatePicket();

    const splitedName = name?.split(".");
    const selectedValue = formik?.values?.register
      ? formik?.values?.register[Number(splitedName[1])]?.[splitedName[2]]
      : formik?.values[name]
        ? formik?.values[name]
        : value
          ? value
          : null;

    const dateRef = useRef(null);
    const getInputClasses = () => {
      let classes = `form-control form-control-solid h-auto py-3 pl-6 pr-8 common-data-picker ${selectedValue ? "black-color" : "placeholder-color"
        }`;

      if (checkValidation && formik?.touched[name] && formik?.errors[name])
        classes += " is-invalid";
      if (checkValidation && formik?.touched[name] && !formik?.errors[name])
        classes += " is-valid";

      return classes;
    };
    const getValue = () => selectedValue;

    return (
      <div
        className={
          financeFilterDate === "financeFilterDate"
            ? "form-group fv-plugins-icon-container datepicker-caontainer finance-filter-date custom-date-picker"
            : "form-group fv-plugins-icon-container datepicker-caontainer custom-date-picker"
        }
        style={style}
      >
        {getValue() !== "Invalid date" ? (
          <p className="custom-date-picker-value">
            {getValue()
              ? newDate === "newDate"
                ? moment(value).format(dateFormat)
                : localFormat !== true
                ? formatDate({date:value, formatType: "defaultWithUTC"})
                : formatDate({date:value,formatType: "default"})
              : ""}
          </p>
        ) : (
          <p className="custom-date-picker-placeHolder">
            {placeholderFormat ? placeholderFormat : "mm/dd/yyyy"}
          </p>
        )}

        <LabelField
          mandatory={mandatory}
          htmlFor={name}
          title={title}
          labelClassName={datePickerClass}
        />
        {/* {mandatory ? <span className="text-danger">*</span> : null} */}
        <div className="d-flex">
          <TextField
            ref={dateRef}
            // style={
            //   financeDateFields === "finance_datefield"
            //     ? { backgroundColor: "#F3F6F9" }
            //     : { backgroundColor: "#fff" }
            // }
            id="date"
            label=""
            min={new Date()}
            type="date"
            InputProps={{
              inputProps: {
                min: minDate,
                disabled,
              },
            }}
            placeholder={rest?.placeholder}
            defaultValue=""
            className={getInputClasses()}
            {...rest}
            {...formik?.getFieldProps(name)}
            InputLabelProps={{
              shrink: true,
            }}
            value={getValue() ? getValue() : ""}
            onChange={onChange ?? formik?.handleChange}
          />
        </div>

        {formik?.touched[name] && formik?.errors[name] ? (
          <HintField error={formik?.errors[name]} />
        ) : null}
      </div>
    );
  }
);
