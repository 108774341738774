import React, {  useState } from 'react';
import { CheckBox } from "components/CheckBox/CheckBox";
import {CheckBoxWrapper,Wrapper,Header} from './Theme'
import MyButton from "components/MyButton";
import AuthService from "../../Lib/API/Auth";
import { toastr } from "react-redux-toastr";
import "./index.scss";

export const DirectorySetting = React.memo(() => {
  const token = localStorage.getItem('token');
  const accountCheckBoxes = JSON.parse(localStorage.getItem('linkedAccount'));
    const [loading, setLoading] = useState(false);
    const [disable,setDisable] = useState(true)
    const [checkBoxList,setCheckBoxList] = useState([{title:"Hide me on member directory",
    value:"hideDirectory",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideDirectory : false},
    {title:"Hide my email address",value:"hideEmail",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideEmail : false},
    {title:"Hide my mobile number",value:"hideMobile",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideMobile: false},
    {title:"Hide my home number",value:"hideHomeNumber",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideHomeNumber: false},
    {title:"Hide my address",value:"hideAddress",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideAddress: false}])
    const hideButton =  checkBoxList.filter((val)=> val.isChecked !== accountCheckBoxes[val.value])

    const handleCheckboxChange = (value) => {
      setCheckBoxList(prevList => {
        return prevList.map(item => {
          if (item.value === value) {
            setDisable(false)
            return { ...item, isChecked: !item.isChecked };
          }
          return item;
        });
      });

    };
  const handleCancelButton =()=>{
    setCheckBoxList([{title:"Hide me on member directory",
    value:"hideDirectory",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideDirectory : false},
    {title:"Hide my email address",value:"hideEmail",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideEmail : false},
    {title:"Hide my mobile number",value:"hideMobile",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideMobile: false},
    {title:"Hide my home number",value:"hideHomeNumber",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideHomeNumber: false},
    {title:"Hide my address",value:"hideAddress",isChecked:accountCheckBoxes ? accountCheckBoxes?.hideAddress: false}]);    
    setDisable(true)
  }
    const handleSaveApi =async ()=>{
      const checkboxValues = checkBoxList.reduce((acc, curr) => {
        acc[curr.value] = curr.isChecked;
        return acc;
      }, {});
    
      setLoading(true);
       await AuthService.updateAccount(
          checkboxValues,
          token
        ).then((res) => {
          setLoading(false);
          setDisable(true)
          })
          .catch((err) => {
            toastr.error('Error', err?.data?.message);
          });
      
    }
    console.log('disable', disable)
    return (
        <div className="profile-directory-container">
        <div className="edit-profile-container">
          <Header>
            <h1>Member Portal Directory</h1>
            <span></span>
          <hr/>
        </Header>
        </div>
        <Wrapper>
          <CheckBoxWrapper>
            {checkBoxList.map((item)=>(
            <li>
                <CheckBox text={item.title} onChange={(e)=>handleCheckboxChange(item.value)} checked={item.isChecked}/><br/>
            </li>
            ))}
            </CheckBoxWrapper>
          </Wrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div className="save-button">
              <MyButton 
                 content={loading ? "loading..." : "Save"}
                 disabled={loading || disable || hideButton?.length ===0 ? true : false}
                type="primary" 
                onClick={handleSaveApi}
                buttonTextStyle={{
                  margin: 0,
                  
                }}
              />
            </div>
            <div className="cancel-button">
              <MyButton 
                content="Cancel" 
                type="secondary" 
                onClick={handleCancelButton}
                buttonTextStyle={{
                  margin: 0,
                }}
              />
            </div>
          </div>
        </div>
    );
})

