import * as React from "react";

const ArrowSVG = (props) => {
  return (
    <svg
      className=""
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      viewBox="0 0 39.5 25.262"
      {...props}
    >
      <g id="arrow-right" transform="translate(1 1.414)">
        <line
          id="Line_329"
          data-name="Line 329"
          x1="16"
          transform="translate(0 7.633)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_26214"
          data-name="Path 26214"
          d="M19.816,5,12,12.816l7.816,7.816"
          transform="translate(-12 -5)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default ArrowSVG;
