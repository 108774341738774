
import Paper from "@mui/material/Paper";
import { convertUTCToLocalDate } from "components/UTC/UtcConverter";
import * as calculation from "helper/Calculate";
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formSlice } from "services/api/forms";

import { formatDate } from "helper/dateFormat";
import { numberFormat } from "helper/numberFormat";
import { DataFoundWrapper, Discount, Note, PayDetails, PaymentAmount, SummaryItem, SummaryTitle, TotalPayment, Wrapper } from './Theme';
export const SummaryCard = ({handleTotalForPay,convenienceFee,totalPaymentWithCVFee,isAuthenticated}) => {
  const { products,varients,numberOfTickets,totalProducts } = useSelector((state) => state?.Forms);
  const [totalCounterSum,setTotalCounterSum] = useState(0)
  const { classDetails } = useSelector((state) => state?.Class)

  const paymentMethod = JSON.parse(localStorage.getItem("paymentMethodSchool"))
    const totalPay =  handleTotalForPay()
    const dispatch = useDispatch();

     const countAmount = (numberOfProduct,amount)=>{
      const result = numberOfProduct * amount;
      return numberFormat({number:+result, decimal: 2, style: "currency"})
  }
    const [newProducts, setProducts] = useState([]);

    useEffect(() => {
      // Process the input data and update the products state
      const processedData = processInputData(varients);
      const totalCounterSum = processedData?.reduce((sum, obj) => sum + obj.total, 0);
      const totalAmount = processedData?.reduce((sum, obj) => sum + (obj.total * obj.amount) , 0);
      setTotalCounterSum(totalCounterSum)
      const finalAmount = Number.isNaN(totalAmount) ? 0 :totalAmount 
      dispatch(formSlice.actions.setTotalProducts(finalAmount));
      setProducts(processedData);
    }, [dispatch, varients]);
  
    const processInputData = (data) => {
      const processedProducts = data && Object.values(data)?.length >0 ? Object.values(data)?.map((item) => {
        if (item.variantsInfo && item.variantsInfo.length > 0) {
            const totalCounterSum = item.variantsInfo.reduce((sum, obj) => sum + obj.counterValue, 0);
          return {
            title: item.varientTitle,
            total: totalCounterSum,
            amount:item.amount
          };
        } else {
          return {
            title: item.varientTitle,
            total: item.counterValue || 0,
            amount:item.amount
          };
        }
      }) : [] ;
      console.log('processedProducts', processedProducts)
      return processedProducts ;
    };
    return (    
      <>
        {(totalPay?.total !== '0.00' ||  totalProducts > 0) && <Paper
        sx={{
          minHeight: "278px",
          // marginTop: "33px",
          borderRadius: "10px",
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
        >
        <Wrapper>
            <SummaryTitle><span>Summary</span></SummaryTitle>
                <DataFoundWrapper>
                    {totalPay?.totalcountTickets > '0.00' && 
                     <PayDetails>
                {classDetails?.class_pricing
                    ?.prices?.map((item, index) => item?.type === "onRegistration" ? ( <>
                    <li key={index}>{item?.title}<span>{numberFormat({number:+item.amount, decimal: 2, style: "currency"})}</span></li>
                    <p>{item?.note}</p>
                </>
                )
                : <>
                <li key={index}>{item?.title} {item?.type === 'optional' && `(optional)`}<span>{numberFormat({number:+item.amount, decimal: 2, style: "currency"})}
                </span></li>
                <p>{item?.note}</p>
                </>
                )}
                { classDetails?.class_discount?.map((item, index) => (
                    <>
                        <li 
                          key={index}
                          className={`d-flex flex-column gap-2 ${(item?.forMemberOnly && !isAuthenticated) ? 'opacity-50' : ''}`}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                            {item?.discount_name} 
                            {item?.discount_type === 'date' && ` | ${formatDate({date:item?.start_date, formatType: "default"})} - 
                            ${formatDate({date:item?.end_date, formatType: "default"})}`} 
                            <span>
                                {item?.price_type === 'percentage' ? `${item?.value}%` : `${numberFormat({number:+item?.value, decimal: 2, style: "currency"})}`}
                            </span>
                            </div>
                            {(item?.forMemberOnly  && !isAuthenticated) && (
                                  <span style={{ fontSize: '12px' }}>
                                      (Members Only)
                                  </span>
                              )}
                            </li>
                    </>

                ))}

                    </PayDetails> } 
                    {newProducts.map((product, index) => (
                        <>
                        {product?.total > 0 &&  
                        <SummaryItem>
                            <div>
                                <p>{product?.total} {product?.title}</p>
                            </div>
                            <PaymentAmount>
                            {`${products?.[index]?.tax_type}
                            ${countAmount(product?.total,product?.amount)}`}
                            </PaymentAmount>
                        </SummaryItem> }
                      
                        </>
                    ))}<br/>
                            {(paymentMethod?.coverConvenienceFee && convenienceFee > 0) &&(
                             <>
                             <SummaryItem>
                                <div>
                                    <span style={{color:'#002e5c',fontSize:'14px'}}>Convenience fee</span>
                                </div>
                                <PaymentAmount>
                                    {numberFormat({number:+convenienceFee, decimal: 2, style: "currency"})}
                                </PaymentAmount>
                            </SummaryItem>
                            </>
                        )}
                        {/* {calculateDiscountResult?.discountTotal !== '-$0.00'  && totalPay?.total !== '0.00'&&
                        <Discount><span>Discount</span>{thousands(calculateDiscountResult?.discountTotal)}</Discount>
                        }
                     */}
                    <TotalPayment>
                        <p>Payment Total</p>
                        <span>{numberFormat({number:+totalPaymentWithCVFee, decimal: 2, style: "currency"})}</span>
                    </TotalPayment>
                </DataFoundWrapper>
        </Wrapper>
        </Paper>}
        </>
    );
};
