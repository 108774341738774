import * as React from "react"

function CustomerSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24.955}
      height={21.738}
      {...props}
    >
      <g transform="translate(0 -32.667)">
        <circle
          data-name="Ellipse 100"
          cx={4.5}
          cy={4.5}
          r={4.5}
          transform="translate(8 32.667)"
          fill="#b5b5c3"
        />
        <circle
          data-name="Ellipse 101"
          cx={3}
          cy={3}
          r={3}
          transform="translate(18 35.667)"
          fill="#e7e7eb"
        />
        <circle
          data-name="Ellipse 102"
          cx={3}
          cy={3}
          r={3}
          transform="translate(1 35.667)"
          fill="#e7e7eb"
        />
        <path
          data-name="Path 26466"
          d="M6.54 43.519c-1.055-.865-2.011-.75-3.231-.75A3.3 3.3 0 000 46.059v5.323a1.432 1.432 0 001.433 1.428c3.413 0 3 .062 3-.147.002-3.772-.444-6.538 2.107-9.144z"
          fill="#e7e7eb"
        />
        <path
          data-name="Path 26467"
          d="M13.638 42.789c-2.131-.178-3.984 0-5.581 1.321-2.674 2.142-2.159 5.025-2.159 8.553a1.71 1.71 0 001.707 1.707c10.288 0 10.7.332 11.308-1.019.2-.457.145-.312.145-4.682a5.909 5.909 0 00-5.42-5.88z"
          fill="#b5b5c3"
        />
        <path
          data-name="Path 26468"
          d="M21.646 42.768c-1.227 0-2.177-.113-3.231.75 2.533 2.586 2.105 5.163 2.105 9.143 0 .21-.341.147 2.951.147a1.483 1.483 0 001.485-1.479v-5.272a3.3 3.3 0 00-3.31-3.289z"
          fill="#e7e7eb"
        />
      </g>
    </svg>
  )
}

export default CustomerSvg
