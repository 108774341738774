import Service from "../../service";

  const EventsService = {
  // Event Api's
  getEventList: async (token, current_page, items_per_page, orgId, body = null) => {
    try {
      return await Service.post({
        url: `/schools/v1/event/list?current_page=${current_page}&items_per_page=${items_per_page}&orgId=${orgId}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  getEventById: async (token, id) => {
    try {
      return await Service.get(`/schools/v1/event?id=${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  deleteEvent: async (token, id) => {
    try {
      return await Service.remove({ url: `/schools/v1/event?id=${id}`, token });
    } catch (error) {
      throw error;
    }
  },

  getEventParticipants: async (
    token,
    current_page,
    items_per_page,
    body = null
  ) => {
    try {
      return await Service.post({
        url: `/schools/v1/event/participant/list?current_page=${current_page}&items_per_page=${items_per_page}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  getEventParticipantById: async (token, id) => {
    try {
      return await Service.get(`/schools/v1/event/participant?id=${id}`, token);
    } catch (error) {
      throw error;
    }
  },
  updateEventParticipant: async (token, body, id) => {
    try {
      return await Service.update({
        url: `/schools/v1/event/participant?id=${id}`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  deleteEventParticipantById: async (token, id) => {
    try {
      return await Service.remove({
        url: `/schools/v1/event/participant?id=${id}`,
        token,
      });
    } catch (error) {
      throw error;
    }
  },
  exportParticipant: async (token, body) => {
    try {
      return await Service.post({
        url: `/schools/v1/event/participant/export`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  createParticipant: async (token, body) => {
    try {
      return await Service.post({
        url: `/schools/v1/event/participant`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  reserveSeats: async (token, body) => {
    try {
      return await Service.post({
        url: `/schools/v1/event/participant/reserve`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },
  deleteTicket: async (token, body) => {
    try {
      return await Service.remove({
        url: `/schools/v1/event/participant/reserve`,
        token,
        data: body,
      });
    } catch (error) {
      throw error;
    }
  },

  getFormById: async (token, id, orgId) => {
    try {
      return await Service.get(`schools/v1/form?id=${id}&orgId=${orgId}`, token);
    } catch (error) {
      throw error;
    }
  },
}
export default EventsService;
