import React, { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

export const CheckValidUser = () => {
  const originUrl = window.location.origin;
  const { logout } = useAuth0();
  useEffect(() => {
    if(localStorage.getItem("donationLogout") && localStorage.getItem("donationLogout") === "true") {
      logout({ returnTo: originUrl });
      localStorage.removeItem("donationLogout");
      return;
    }
    setTimeout(() => {
      logout({ returnTo: originUrl });
    }, [5000]);
  });

  return (
    <>
      {localStorage.getItem('donationLogout') &&
      localStorage.getItem('donationLogout') === 'true' ? null : (
        <div
          style={{
            position: 'fixed',
            background: 'azure',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '2rem',
          }}
        >
          {`You are not allowed to access this area`}
        </div>
      )}
    </>
  );
};
