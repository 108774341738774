import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import sortImg from "assets/icons/sort-able-svg.svg";
import { Loader } from "components";
import AuthService from "Lib/API/Auth";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from 'react';
import "./index.scss";
export const DirectoryTable = ({sortCharged,filters,page,setPage}) => {
  const [directoryListData,setDirectoryListData] = useState([])
  const [count, setCount] = useState([])
  const { enqueueSnackbar } = useSnackbar();
  const [loading,setLoading] =useState(false)
  const token = localStorage.getItem('token');
 
  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters from the phone number
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    // Apply phone number format
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return formatted;
  };
  useEffect(() => {
    setPage(0);
  }, []);
  const getDirectoryList = async () => {
      setLoading(true);
      try {
        const res = await AuthService.getDirectoryList(
          token,
          page + 1,
          10,
          {
            sort: filters?.sort,
            search: filters?.search,
          }
        );
  
        setDirectoryListData(res.payload.data);
        setCount(res?.payload?.pagination?.count);
      } catch (error) {
        if(error?.data?.message) enqueueSnackbar(error?.data?.message, {
          variant: "error",
        });
        
      } finally {
        setLoading(false);
      }
    };
   

    const debounceSearch = useCallback(
      debounce(() => {
        if (filters?.search) {
          getDirectoryList();
        }
      }, 100),
      [filters.search, filters, page]
    );
    useEffect(() => {
      if (filters?.search) {
        debounceSearch();
      } else {
        getDirectoryList();
      }
      return () => debounceSearch.cancel();
    }, [filters.search, filters, page]);
    const handleClickMail = () => {
      window.open('mailto:templeshalon@gmail.com');
  };
    return (
      <div className="w-100">
        <div className={"family-table-container pl-7 pr-6 pt-3 pb-22"}>
        <div className="home-table-container mt-0 no-box-shadow p-0">
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="charges-table">
                <TableRow>
                  <TableCell
                    className="date-head"
                    onClick={() => sortCharged("firstName")}
                  >
                    FIRST NAME
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className="status-head"
                    align="left"
                    onClick={() => sortCharged("lastName")}
                  >
                    LAST NAME
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className="status-head"
                    align="left"
                    onClick={() => sortCharged("mobileNumber")}
                  >
                    MOBILE
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className="account-name"
                    align="left"
                    onClick={() => sortCharged("homeNumber")}
                  >
                    <span>HOME PHONE</span>
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className="charged-value"
                    align="left"
                    onClick={() => sortCharged("email")}
                  >
                    EMAIL
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => sortCharged("address")}
                    className="paid-head"
                  >
                    ADDRESS 
                    <IconButton>
                      <img src={sortImg} alt="sort" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
            <TableBody className="tran-table-body">
              {directoryListData?.length === 0 ? loading === true ? (<div className="tran-loader">
                      <Loader />
                    </div>):(<td colSpan={12}>
                      <div className="record-text">No record found</div>
                    </td>):(directoryListData && directoryListData?.map((item)=>(
                    <TableRow className="myexpendRow">
                       <TableCell
                            component="th"
                            scope="row"
                            className="exp-status-cell"
                        align="left"
                      >
                        {item?.firstName}
                    </TableCell>
                    <TableCell
                         component="th"
                         scope="row"
                         className="exp-status-cell"
                        align="left"
                      >
                        {item?.lastName}
                    </TableCell>
                    <TableCell
                         component="th"
                         scope="row"
                        //  className="exp-status-cell"
                        align="left"
                      >
                         <a href={`tel:${item?.mobileNumber}`}>
                        {formatPhoneNumber(item?.mobileNumber)}
                        </a>
                    </TableCell>
                    <TableCell
                         component="th"
                         scope="row"
                         className="exp-status-cell"
                        align="left"
                      >
                        <a href={`tel:${item?.homeNumber}`}>
                        {formatPhoneNumber(item?.homeNumber)}
                        </a>
                    </TableCell>
                    <TableCell
                         component="th"
                         scope="row"
                         className="exp-status-cell"
                        align="left"
                      >
                            <div onClick={handleClickMail} style={{  color: '#2555EF', cursor: 'pointer' }}>
                          {item?.emailAddress}
                          </div>
                        
                    </TableCell>
                    <TableCell
                         component="th"
                         scope="row"
                         className="exp-status-cell"
                        align="left"
                       
                      >
                        <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item?.address?.formattedAddress)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                      {item?.address?.formattedAddress}
                    </a>
                    </TableCell>
                    </TableRow>
                )))}
            </TableBody>
            </Table>
          </TableContainer>
          {directoryListData?.length !== 0 && (
                        <div className="pagination-custom mt-2">
                             <Pagination
                              count={Math.ceil(count / 10)}
                              color="primary"
                              variant="outlined"
                              page={page + 1}
                              onChange={(event, value) => {
                                setPage(value - 1);
                              }}
                            />
                        </div>
                    )}
             {/* <>
              <div className="pagination-custom mt-2">
                <Pagination
                  count={Math.ceil(count / 10)}
                  color="primary"
                  variant="outlined"
                  onChange={(event, value) => {
                    setPage(value - 1);
                  }}
                />
              </div>
            </> */}
          
        </div>
      </div>
      </div>
    );
};

