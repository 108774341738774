import React, { useState } from 'react';
import { Wrapper } from './Theme';
import Timer from 'helper/Timer/Timer';
import AlertDialog from "components/Dialog";

const Header = ({Logo}) => {
    const [openPopup,setOpenPopup]=useState(false)
    const [hideTimer,setHideTimer]=useState(false)

    const time = new Date();
    time.setSeconds(time.getSeconds() + 600); 
    const handlePopUp =()=>{
        setOpenPopup(!openPopup)
    }
    const handleHideTimer = ()=>{
        setHideTimer(!hideTimer)
        setOpenPopup(false);
    }
    return (
        <>
        
        
     
        <Wrapper>
            <header><img src={Logo} alt='Logo' /></header>
           {!hideTimer && <div><Timer expiryTimestamp={time} expiryFunction={handlePopUp}/></div>} 

        </Wrapper >
        {openPopup &&  <AlertDialog
          open={openPopup}
          handleClose={() => setOpenPopup(false)}
          onDecline={() => {
            setOpenPopup(false);
          }}
          onAccept={handleHideTimer}
          okButtonContent='Ok'
          content={
            <div className="alert-message-popup">
              <p className="alert-icon"></p>
              <div>
                <p>Timer</p>
                <p className="alert-message">
                The timer has run out. Please refresh the page to purchase tickets.
                </p>
              </div>
            </div>
          }
        />}
        </>
    );
};

export default Header;