import React from 'react';
import { ContactUsWrapper, ContactUsContainer, ContactUsText, Phone, Email, Wrapper } from './Theme'
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/EmailIcon.svg'
import { ReactComponent as ContactUsClicked } from 'assets/icons/ContactUs.svg';
import { ReactComponent as ContactUsUnclicked } from 'assets/icons/ContactUsUnclicked.svg';
import { useMobileView } from 'hook/mobileView/useMobileView';
import { ReactComponent as PhoneActive } from 'assets/icons/PhoneActive.svg';
import { ReactComponent as PhoneUnActive } from 'assets/icons/PhoneUnActive.svg';

export const ContactUs = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const { isMobile } = useMobileView()
  
    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const handleClickMail = () => {
        window.open('mailto:templeshalon@gmail.com');
    };
const phoneNumber = `tel:${localStorage.getItem('phone')}`
    return (
        <ContactUsWrapper >
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ContactUsContainer>
                                <ContactUsText>
                                    Contact Us
                                </ContactUsText>
                                <div>
                                    <Phone><PhoneIcon />{isMobile ? <a href={phoneNumber}>{localStorage.getItem('phone')}</a> : <span>{localStorage.getItem('phone')}</span>}</Phone>
                                    <Email><EmailIcon /><span onClick={handleClickMail}>{localStorage.getItem('email')}</span></Email>
                                </div>

                            </ContactUsContainer>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Wrapper onClick={handleClick('top-end')} isMobile={isMobile}>
                {!isMobile ?
                    open ? <ContactUsClicked /> : <ContactUsUnclicked /> :
                    <div>{open ? <PhoneActive /> : <PhoneUnActive />}</div>
                }
            </Wrapper>

        </ContactUsWrapper >
    );
};
