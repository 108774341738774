import { useAuth0 } from "@auth0/auth0-react";
import AlertDialog from "components/Dialog";
import MyButton from "components/MyButton";
import MyModal from "components/MyModal";
import Spinner from "components/Spinner";
import withUserAccount from "hoc/withUserAccount";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import FinanceService from "../../Lib/API/Finance";
import { withUserExist } from "../CustomHooks";
import { AddCard } from "./AddCard";
import CardComponent from "./CardComponent";
import "./index.scss";

const PaymentMethods = ({accountId}) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [creditId, setCreditId] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);


  async function fetchMyAPI() {
    const token = await getAccessTokenSilently();
    setLoading(true);
    await FinanceService.getCreditCardsByAccountId(token, accountId)
      .then((res) => {
        if (res) {
          setLoading(false);
          console.log(res?.card?.data, "accountID");
          setList(res?.card?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        toastr.error('Error', err?.data?.message);
      });
  }


  useEffect(() => {
    if(!accountId) return;
    fetchMyAPI();
  }, [refresh,accountId]);

  const handleDelete = async (cardID) => {
    const token = await getAccessTokenSilently();
    FinanceService.deleteCreditCard(token, cardID, accountId)
      .then((res) => {
        if (res?.card?.messages?.resultCode == "Ok") {
          toastr.success("Success", "Card has been delete successfully ");
        }
        fetchMyAPI();
      })
      .catch((err) => {
        console.log(err, "response");
        toastr.error('Error', err?.data?.message);
      }).finally(() => {
        closeConfirmDialog();
      });
  };

  const openConfirmDialog = (id) => {
    setCreditId(id);
    setOpenAlert(true);
  };


  const closeConfirmDialog = () => {
    setOpenAlert(false);
    setCreditId(null);
  };


  return (
    <div className="profile-container">
      <div className="edit-profile-container">
        <div className="profile-header">
          <div>
            <div className="main-text">Payment Methods</div>
            <div className="body-text">Manage your Payment Methods</div>
          </div>
          <MyButton
            content={
              <span>
                <span className="mr-2">+</span>
                <span>Add New Card</span>
              </span>
            }
            onClick={() => {
              setOpenModal(true);
            }}
          />
        </div>
        <hr />
        <div className="profile-body">
          {list?.length === 0 && !loading && (
            <h1 className="no-cards">NO CARDS</h1>
          )}
          {loading && <Spinner />}
          {list?.map((card, idx) => {
            return (
              <CardComponent
                card={card}
                idx={idx}
                handleDelete={openConfirmDialog}
              />
            );
          })}
        </div>
      </div>
      <AlertDialog
          open={openAlert}
          handleClose={() => {
            closeConfirmDialog();
          }}
          onDecline={() => {
            setOpenAlert(false);
          }}
          onAccept={() => {
            handleDelete(creditId);
          }}
          content={"Are you sure you want to delete this credit card?"}
          contentStyle={{
            fontSize: "18px",
          }}
      />
      <MyModal
        className="add-card-modal"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <AddCard
          setOpenModal={setOpenModal}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </MyModal>
    </div>
  );
};

export default withUserExist(withUserAccount(PaymentMethods));
