import React, { useState,useEffect } from 'react';
import { DirectoryTable } from './DirectoryTable/DirectoryTable';
import {Title,SearchWrapper} from './Theme'
import SearchComp from "components/Searchcomponent";
import {useHistory} from 'react-router-dom';
export const Directory = () => {
    const initialFilters = {
        sort: {},
        // filter: {
        //   "hidden": true,
        // },
        search: undefined,
      };
      const [page, setPage] = useState(0)

    const [searchValue, setSearchValue] = React.useState("");
    const [filters, setFilters] = useState(initialFilters);
    const { push } = useHistory();
    const  memberPortalDirectoryPermission=  JSON.parse(localStorage.getItem("organization"))?.memberPortalDirectory

    const sortCharged = (e) => {
      setFilters((prev) => ({
        ...prev,
        sort: {
          [e]: prev.sort[e] === 1 ? -1 : 1,
        },
      }));
    };
    const onSearchChange = (e) => {
      const name = e.target.value;
      setPage(0)
      setFilters((prev) => ({
        ...prev,
        search: name ? name : undefined,
      }));
    };
      useEffect(() => {
        setFilters((prev) => ({
          ...prev,
          search: searchValue ? searchValue : undefined,
        }));
      }, [searchValue]);

      useEffect(()=>{
        if(!memberPortalDirectoryPermission){
          push("/family-member-table")
        }
        
      },[memberPortalDirectoryPermission])
    return (
        <div className="school-classes w-100 m-0 p-5">
            <Title >Directory List</Title><hr/>
          <SearchWrapper>
            <SearchComp
              value={filters?.search ? filters?.search : ""}
              setSearchText={onSearchChange}
              placeholder="Search by first name, last name"
              style={{background: '#ffff'}}
            />
          </SearchWrapper>
          <div className="charges-table w-100">
            <DirectoryTable filters={filters}  sortCharged={sortCharged} page={page} setPage={setPage}/>
           </div>
        </div>
    );
};

