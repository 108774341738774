import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
padding-left: 30px;
padding-right: 30px;
padding-top: 30px;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
max-height: 500px;
overflow-y: auto;
`
export const SummaryTitle = styled.h3`
color: var(--Dark-Blue-100, #002E5C);
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px;
text-align: left;
padding-bottom: 8px;
display: flex;
justify-content: flex-start;
align-items: center;
span{
padding-right: 8px;
}
`
export const DataNotFoundWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #F8F8F9;
width: 100%;
height: 104px;
border-top: 1px solid rgba(0, 46, 92, 0.10);
border-bottom: 1px solid rgba(0, 46, 92, 0.10);

span{
color: #B5B5C3;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; 
}
`

export const DataFoundWrapper = styled.div`
width: 100%;
max-height: 500px;
`
export const NumberOfSelections = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 37.96px;
color: #70A0EE;
background: #EAEEFC;
border-bottom: 1px solid rgba(0, 46, 92, 0.10);
margin-bottom: 29px;
span{
    padding-top: 6px;
    padding-bottom: 6px;
}

`
export const SummaryItem = styled.div`
width: 100%;
display: flex;
justify-content: space-between;

div{
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  
    span{
        color: var(--Dark-Blue-100, #002E5C);
        font-size: 15px;
        font-weight: 500;
        line-height: 13px; 
        padding-bottom: 12px;
    }
    p{
        color: #5B7792;
font-size: 14px;
    }

}
`
export const PaymentAmount = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`
export const TotalPayment = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
color: var(--Dark-Blue-100, #002E5C);
border-top: 1px dashed rgba(0, 46, 92, 0.10);
padding-top:24px;
padding-bottom:24px;
min-height: 100px;
p{
font-size: 14px;
font-weight: 600;
line-height: 13px;
margin-bottom: 0;
}
span{
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 13px;
}
`
export const TotalPaymentLeftSide = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;
export const TotalPaymentField = styled.div`
    position: relative;
    width: 150px;

    label {
        margin: 0;
    }
    input {
        font-size: 20px !important;
    }
`;

export const TotalPaymentFieldSign = styled.span`
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
`;
export const TotalPaymentEditIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const TotalPaymentEditBtn = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`;
