import React from "react";
import { LeftDrawer } from "pages/drawer";
const styles = {
  body: {},
};
export const Main = ({ children, ...props }) => {
  return (
    <main className="d-flex flex-grow-1 " style={{ ...styles.body ,background:'#F3F6F9'}} {...props}>
      <LeftDrawer />
      <div className="d-flex flex-grow-1 overflow-hidden">{children}</div>
    </main>
  );
};

