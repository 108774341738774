import * as React from "react"

function BackSvgComponent (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={21}
            viewBox="0 0 21 21"
            {...props}
        >
            <g data-name="Group 2144" transform="translate(.315)">
                <circle
                    data-name="Ellipse 93"
                    cx={10.5}
                    cy={10.5}
                    r={10.5}
                    transform="translate(-.315)"
                    fill="#f1585e"
                />
                <g data-name="Group 690">
                    <path
                        data-name="Line 48"
                        fill="none"
                        stroke="#fff"
                        d="M5.101 10.5h10.245"
                    />
                </g>
            </g>
        </svg>
    )
}

export default BackSvgComponent
