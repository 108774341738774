import styled from "styled-components";

export const Wrapper = styled.div`
    
`
export const ProductContent = styled.div`
    display: flex;
    @media only screen and (min-width: 280px) and (max-width: 767px) {
        flex-direction: column;
  }
    
`
export const FirstDiv = styled.div`
min-height: 50px;
padding-top: 20px;
width: 50%;
@media only screen and (min-width: 280px) and (max-width: 767px) {
    width: 100%;
  }
`;

export const SecondDiv = styled.div`
flex-grow: 1;
padding: 10px;
`;

export const FlexItemsContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
margin-left: 20px;
`;

export const FlexItem = styled.div`
flex: 1;
margin-right: 10px; 
`;

export const Amount =styled.span`
`
export const Title = styled.span`
    margin-bottom: 8px;
    margin-left: -30px;
`
