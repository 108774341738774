
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import thousands from "thousands";
import { Boxes, ClassBody, InfinitySymbol } from './Theme';

const ClassBox = ({ ...props }) => {
    const { classDetails } = useSelector((state) => state?.Class)
    const [age, setAgeRange] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const token = localStorage.getItem('token');


    const isExpiredClass = () => {
        const startDateDiff = moment().diff(moment(classDetails?.class_start_date), 'days');
        const endDateDiff = moment().diff(classDetails?.class_end_date ? moment(classDetails?.class_end_date) : moment(), 'days');
        
        return startDateDiff < 0 || endDateDiff > 0
    }

    const calculateDiscount = () => {
        let discountTotal = classDetails?.class_pricing?.total_to_pay
        const discount = classDetails.class_discount?.map((item) => {
            if (item?.discount_type === 'date') {
                const endDate = moment(item?.end_date).format("MM/DD/YYYY")
                const startDate = moment(item?.start_date).format("MM/DD/YYYY")
                const currentDate = moment(new Date()).format("MM/DD/YYYY")
                if (startDate <= currentDate && endDate >= currentDate) {
                    if (item?.price_type === "fixed") {
                        discountTotal = discountTotal - item?.value
                    } else {
                        const discountAmount = (discountTotal * item?.value) / 100;
                        discountTotal = discountTotal - discountAmount;
                    }
                } else {
                    console.log("The discount date is expired ");
                }
            } else if (item?.discount_type === 'amount') {
                if (item?.price_type === "fixed") {
                    discountTotal = discountTotal - item?.value
                } else {
                    const discountAmount = (discountTotal * item?.value) / 100;
                    discountTotal = discountTotal - discountAmount;
                }

            } else if (item?.discount_type === 'quantity') {
                if (item?.quantity && item?.quantity > 4) {
                    if (item?.price_type === "fixed") {
                        discountTotal = discountTotal - item?.value
                    } else {
                        const discountAmount = (discountTotal * item?.value) / 100;
                        discountTotal = discountTotal - discountAmount;
                    }
                }
            }
        })
        
        const totalAfterDiscount = discountTotal > 0 ? thousands((+discountTotal).toFixed(2)) : "0.00";
        return totalAfterDiscount
    }

    useEffect(() => {
        calculateDiscount()
        setAgeRange([{ value: classDetails.class_details?.maximum_participants, desc: 'Max participants', infinity:classDetails?.class_details?.infiniteParticipants }, {
            value: `${classDetails.class_details?.ageStart}${classDetails.class_details?.ageEnd ? "-" + classDetails.class_details?.ageEnd : ''}`, desc: 'Max Age Range'
        }, { value: classDetails.class_details?.gender, desc: 'Sex' }])
    }, [classDetails])


    return (
        <ClassBody steps={props?.steps}>
  

            <Boxes>

                {age.map((item, index) => (
                    <div key={index}>
                        {item.infinity ? 
                            <InfinitySymbol>&#8734;</InfinitySymbol>
                        : item.value}
                        <span>{item.desc}</span>
                    </div>

                ))}

            </Boxes>
        </ClassBody >
    );
};

export default ClassBox;