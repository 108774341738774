import * as React from "react"

function LogOutSvg (props) {
    return (
        <svg
            width={28.792}
            height={19.761}
            viewBox="0 0 23.493 16.87"
            id="prefix__svg5"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <defs id="prefix__defs2">
                <filter
                    id="prefix__a"
                    x={0}
                    y={0}
                    width={206}
                    height={106}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset id="prefix__feOffset71" />
                    <feGaussianBlur result="b" id="prefix__feGaussianBlur73" />
                    <feFlood floodOpacity={0.231} id="prefix__feFlood75" />
                    <feComposite
                        operator="in"
                        in2="b"
                        id="prefix__feComposite77"
                        result="result1"
                    />
                    <feComposite
                        in="SourceGraphic"
                        in2="result1"
                        id="prefix__feComposite79"
                    />
                </filter>
                <filter
                    id="prefix__a-3"
                    x={0}
                    y={0}
                    width={206}
                    height={106}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset id="prefix__feOffset71-6" />
                    <feGaussianBlur result="b" id="prefix__feGaussianBlur73-7" />
                    <feFlood floodOpacity={0.231} id="prefix__feFlood75-5" />
                    <feComposite
                        operator="in"
                        in2="b"
                        id="prefix__feComposite77-3"
                        result="result1"
                    />
                    <feComposite
                        in="SourceGraphic"
                        in2="result1"
                        id="prefix__feComposite79-5"
                    />
                </filter>
                <style id="style69">{".prefix__f{fill:#b5b5c3}"}</style>
            </defs>
            <g id="prefix__layer1">
                <g
                    transform="matrix(.97268 0 0 .4279 -33.263 196.788)"
                    id="prefix__g86"
                    filter="url(#prefix__a)"
                >
                    <rect
                        width={206}
                        height={106}
                        rx={5}
                        id="prefix__rect84"
                        x={0}
                        y={0}
                        fill="#fff"
                    />
                </g>
                <g transform="scale(.97268 .91967)" id="prefix__g112">
                    <path
                        className="prefix__f"
                        d="M0 62.009v12.226a3.065 3.065 0 003.059 3.059h9.882A3.065 3.065 0 0016 74.235v-1.98a.606.606 0 10-1.212 0v1.98a1.856 1.856 0 01-1.852 1.852H3.059a1.856 1.856 0 01-1.852-1.852V62.009a1.856 1.856 0 011.852-1.852h9.882a1.856 1.856 0 011.852 1.852v1.98a.606.606 0 101.212 0v-1.98a3.065 3.065 0 00-3.059-3.059H3.059A3.062 3.062 0 000 62.009z"
                        transform="translate(0 -58.95)"
                        id="prefix__path108"
                    />
                    <path
                        className="prefix__f"
                        d="M215.123 158.245a.607.607 0 00.857 0l4.133-4.133a.6.6 0 000-.852l-4.133-4.133a.602.602 0 10-.852.852l3.1 3.1h-11.322a.606.606 0 100 1.212h11.32l-3.1 3.1a.6.6 0 00-.003.854z"
                        transform="translate(-196.138 -144.517)"
                        id="prefix__path110"
                    />
                </g>
            </g>
        </svg>
    )
}

export default LogOutSvg
