import { combineReducers } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import { persistReducer } from "redux-persist";
import Events from '../services/api/events';
import Forms from '../services/api/forms';

//Reducers
import localesReducer from "locales/localesSlice";
import localforage from "localforage";
import DrawerReducer from "pages/drawer/drawerSlice";
import lockLoaderReducer from "pages/lockLoader/lockLoaderSlice";
import organizationReducer from "services/api/organization";
import getRegisterState from '../pages/ReligiousSchool/preSchoolSlice';
import CommonStates from '../pages/commonSlice';
import Class from '../services/api/class';
import dashboardData from '../services/api/dashboard';
import stepForms from '../services/api/formSteps';

// const eventPersistConfig = {
//   key: 'events',
//   storage: localforage,
// };
// const eventPersistReducer = persistReducer(eventPersistConfig, events);

const reducers = combineReducers({
  toastr: toastrReducer,
  lockLoader: lockLoaderReducer,
  locales: localesReducer,
  //auth: authAPI.authSlice.reducer,
  drawer: DrawerReducer,
  stepForms,
  dashboardData,
  CommonStates,
  getRegisterState,
  Class: Class,
  Events:Events,
  Forms:Forms,
  organization:organizationReducer
});

export default reducers;
