import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    formOnlineData:{},
    numberOfTickets: [],
    formDataOnlineform:[],
    FormDataRequiredFields:[],
    registrationForm:[{}],
    products:[],
    varients:{},
    selectedValue:[],
    previousSelectedValue:[],
    currentSelectIndex: "",
    FormDataReservation:[]
    
};

export const formSlice = createSlice({
    name: "forms",
    initialState,
    reducers: {
        setCounterTicket: (state, payload ) => {
            state.numberOfTickets =  payload.payload;
            return state;
        },
        setFormOnlineData: (state,  {payload} ) => {
            state.formOnlineData = payload;
            return state;
        },
        setFormReservationData: (state,  {payload} ) => {
            state.formDataOnlineform = payload;
            return state;
        },
        setForms: (state,  {payload} ) => {
            state.registrationForm =payload;
            return state;
        },
        setResetForms: (state,  {payload} ) => {
            state.registrationForm = [];
            return state;
        },
        setFormDataRequiredFields: (state,  {payload} ) => {
            state.FormDataRequiredFields = payload;
            return state;
        },
        setProducts: (state,  {payload} ) => {
            console.log('payload state', payload)
            return {...state ,products :payload};
            
        },
        setTotalProducts: (state,  {payload} ) => {
            return {...state ,totalProducts :payload};
        },
        setVarients: (state, action) => {
            return {...state ,varients :action.payload};
      
         },
        setSelectedValue: (state,  action ) => {
            const {data,productIndex,productTitle,dropdownIndex,type,ticketIndex} = action.payload
            state.selectedValue = data;
            // find the object depends on dropdownIndex and ticketIndex we need it to change on varients counter value depends on productIndex
            const selectedValueIndex =  state.selectedValue?.map((item)=>{
                return item[productTitle]?.find((item)=>item.indexSelected === dropdownIndex && item.ticketIndex === ticketIndex)
            })
            const changeQuantity = data && state?.varients?.[productIndex]?.variantsInfo?.map((varient)=>{
                console.log('QWQWQWQvarient', varient)
                // check if the object value  is equal to the option one and update counterValue 
                if(varient.option === selectedValueIndex[0]?.value && type !== 'remove'){
                    return { ...varient, counterValue : varient?.counterValue + 1};
                    // minas from counterValue if the type is remove (came from ticket inside decrement button) if the user remove one of the dropdowns
                }else if(varient.option === selectedValueIndex[0]?.value && type === 'remove'){
                    return { ...varient, counterValue : varient?.counterValue - 1}; 
                }
                // if we have previousValue inside selectedValueIndex object 
                else if (selectedValueIndex[0]?.previousValue){
                    // check if this previousValue  is same as Varient Option then mines counterValue value from array from object
                    if(selectedValueIndex[0]?.previousValue === varient?.option ){
                        return { ...varient, counterValue :varient?.counterValue >0 ? varient?.counterValue - 1:varient?.counterValue}; 
                    }else{    
                        return { ...varient}; 
                    }
                    // if we have no match just return the item as it is
                    }else{    
                        return { ...varient}; 
                    }
                }
            )
            // Replace the product with new array of products after adding/removing a variant
            const replaceOldData ={...state?.varients,[productIndex] : {...state.varients?.[productIndex],variantsInfo:changeQuantity}}
            state.varients = replaceOldData;
            return state;
        },
        setFormDataReservation:(state,action)=>{
            console.log("payloadpayloadpayload",action);
            return {...state ,FormDataReservation :action.payload};
        },
        resetAction: (state) => {
            return {...state,selectedValue:[],FormDataReservation:[]};
        },
        setCurrentSelectIndex: (state, payload) => {
            state.currentSelectIndex = payload.payload;
            return state;
        }
    },
});

export const { setCounterTicket,setCurrentSelectIndex } = formSlice.actions;
export default formSlice.reducer;
