
import { useAuth0 } from "@auth0/auth0-react";
import { setLocalizationMoment } from "helper/dateFormat";
import AuthService from "Lib/API/Auth";
import FormService from "Lib/API/Forms";
import moment from 'moment';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { formSlice } from "services/api/forms";
import { FormInfo } from "./FormComponents/FormInfo/FormInfo";
import { Spinner } from "./Theme";



export const Form = () => {
  const { getAccessTokenSilently,isAuthenticated, isLoading: isAuthenticatedLoading } = useAuth0();

  const params = useParams();
  const [isLoading, setIsLoding] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const token = localStorage.getItem('token');


  const getOnlineFormsById = async () => {
    const subDomain = window.location.hostname.split(".")[0];
    const hostName = subDomain === "localhost" ? "shalom" : subDomain;
    setIsLoding(true);
    try {
      const auth = await AuthService.generateToken(hostName);
      localStorage.setItem("formToken", auth?.token);
      localStorage.setItem("logoImgForm", auth?.logoImg);
      localStorage.setItem("formOrgId", auth?._id);
      localStorage.setItem("email", auth?.contactInfo?.email);
      localStorage.setItem("phone", auth?.contactInfo?.phone);
      localStorage.setItem('orgName', auth?.orgName);
      document.title = `${auth?.orgName}`;
      localStorage.setItem("paymentMethodForms",
        JSON.stringify(auth?.paymentMethod)
      );
      localStorage.setItem('country', JSON.stringify(auth?.country || {}));
      localStorage.setItem('shul_website', auth?.shul_website || '');
      localStorage.setItem('memberPortal', auth?.memberPortal || false);

      setLocalizationMoment(auth?.country?.local || "en");
      const organization = {
        provider_information: {
          provider: auth?.provider,
          keys: {
            ...(auth?.publicKey ? { publicKey: auth?.publicKey } : {})
          }
        }
      }
      localStorage.setItem("organization", JSON.stringify(organization));
      const token = isAuthenticated ? await getAccessTokenSilently() : auth?.token
      await FormService.getOnlineFormsById(token, params?.id).then((res) => {
        dispatch(formSlice.actions.setFormOnlineData(res?.result));
        if (res?.result?.formPricing?.prices.length > 0) {
          // Filter data to save products that have an optinal type 
          const products = res?.result?.formPricing?.prices?.filter((item) => {
            if (item?.type === "optional" || item?.type  ==="openAmount") {
              return item;
            }
          })
          const varients = []
          let varientCapacity = 0
          // Add New Fiels to variantInfo to manage products counter 
          console.log('products aaa', products)
          const productRest = products?.map((val) => {
            if (val?.variant) {
              const variantInfodata = val?.variantInfo && Object.keys(val?.variantInfo).map(key => ({
                [key]: { ...val.variantInfo[key], counterValue: 0, selectedValue: '' },

              }));
              const variantsInfo = val?.variantInfo && Object.keys(val?.variantInfo).map(key => {
                varientCapacity += val.variantInfo[key]?.quantity;
                return { option: key, counterValue: 0, selectedValue: '',
                 ...val.variantInfo[key] }
              })
              varients.push({
                variantsInfo: variantsInfo, varientCapacity: isNaN(varientCapacity) ? 'noLimit' :
                  varientCapacity, numberOfDrops: 0, varientTitle: val.title, id: val._id,type:val?.type
              })
              varientCapacity = 0
              return { ...val, variantInfo: variantsInfo, counter: 0, dropdowns: [] }
            } else {
              varients.push({
                numberOfDrops: 0, quantity: 0,
                varientCapacity: !val.infiniteQuantity ? val.quantity : 'noLimit', counterValue: 0, variantsInfo: []
                , varientTitle: val.title, id: val._id, amount: val.amount,type:val?.type
              })
              return { ...val, counter: 0, dropdowns: [] }
            }
          })
          console.log('varientspush', varients)
          localStorage.setItem("originVarients", JSON.stringify({ ...varients }))
          dispatch(formSlice.actions.setVarients({ ...varients }));
          dispatch(formSlice.actions.resetAction([]));
          dispatch(formSlice.actions.setProducts(productRest));
        }
        const startDateDiff = moment().diff(moment(res?.result?.formStartDate), 'days');
        const endDateDiff = moment().diff(res?.result?.formEndDate ? moment(res?.result?.formEndDate) : moment(), 'days');

        if (!res?.result?.allowNonMembers && !token) {
          enqueueSnackbar('This form is available exclusively for members', {
            variant: 'error',
          });
        }
        else if (endDateDiff > 0) {
          enqueueSnackbar('This form is no longer available due to its expiration date', {
            variant: 'error',
          });
        }
        else if (startDateDiff < 0) {
          enqueueSnackbar(`This form has not yet begun. Please check back closer to the start date for access`, {
            variant: 'error',
          });
        }
        setIsLoding(false);
      });
    } catch (error) {
      console.log("errorerror", error);
      setIsLoding(false);
      throw error;
    }
  };
  useEffect(() => {
    dispatch(formSlice.actions.setProducts([]));
    localStorage.removeItem('redirectUrl');
  }, []);

  useEffect(() => {
    if(isAuthenticatedLoading) return;
    getOnlineFormsById();
  },[isAuthenticatedLoading, isAuthenticated]);

  return (
    <>
      {isLoading ? (
        <Spinner>
          <PulseLoader color={"#2555EF"} size={"30px"} />
        </Spinner>
      ) : (
        <FormInfo />
      )}
    </>
  );
};
