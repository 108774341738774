import { useAuth0 } from "@auth0/auth0-react";
import BottomArrowSvg from "assets/icons/svg/BottomArrowSvg";
import { InputField, LabelField, MyCheckbox } from "components";
import { getCurrency, numberFormat } from "helper/numberFormat";
import { useSearch } from "hook";
import React, { createContext, useContext, useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import Select, { components } from "react-select-v2";
import FinanceService from "../../../Lib/API/Finance";
import { CheckFloatValue } from "../../../utils/functions";
import FinanceAccordion from "./Accordion";
import {
  ACH,
  ACHHeader,
  Cash,
  CashHeader,
  Check,
  CheckHeader,
  CreditCard,
  CreditCardHeader,
  CreditOnAccount,
  CreditOnAccountHeader,
  NewPaymentMethod,
  NewPaymentMethodHeader,
} from "./PaymentMethods";
const PaymentInformation = ({ index, ...props }) => {
  const [expandVIew, setExpandView] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = useState("");
  const { searchAction } = useSearch({ action: setSearch });
  const [data, setData] = useState();
  const [expanded, setExpanded] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [createNewAccount, setCreateNewAccount] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [current, setCurrent] = useState([]);
  const [paymentMethodTitle, setPaymentMethodTitle] = useState("");
  const [creditOnAccountAmount, setCreditOnAccountAmount] = useState("");

  const currency = getCurrency();

  const enableLoading = (value) => {
    setLoading(value);
  };
  const selectControlStyles = {
    backgroundColor: "#F3F6F9",
    borderColor: "#F3F6F9",
    color: "#3F4254",
    transition:
      "color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease",
    paddingTop: "6px",
    paddingBottom: "6px",
    cursor: "pointer",
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      ...selectControlStyles,
    }),
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <BottomArrowSvg label="Emoji" />
      </components.DropdownIndicator>
    );
  };
  const formatOptionLabel = ({ label, account_id }, e) => {
    return (
      <div className="dropdown-container" key={account_id}>
        <div className="icon-name-container d-flex align-items-center justify-content-start">
          <div className="name-container" style={{ marginInlineStart: 10 }}>
            <div>{label}</div>
          </div>
        </div>
      </div>
    );
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const formik = props;
  const sumOfAmounts = formik.values?.allData?.reduce((a, b) => {
    return a + b?.charge?.amount;
  }, 0);
  const setMethods = {
    check: {
      content: <Check formik={formik} />,
      header: <CheckHeader formik={formik} />,
    },
    "credit card": {
      content: <CreditCard formik={formik} />,
      header: <CreditCardHeader formik={formik} />,
    },
    ach: {
      content: <ACH formik={formik} />,
      header: <ACHHeader formik={formik} />,
    },
    cash: {
      content: <Cash formik={formik} />,
      header: <CashHeader formik={formik} />,
    },
    "credit on account": {
      content: <CreditOnAccount formik={formik} />,
      header: <CreditOnAccountHeader formik={formik} />,
    },
    default: {
      content: <NewPaymentMethod formik={formik} />,
      header: (
        <NewPaymentMethodHeader
          formik={formik}
          setPaymentMethodTitle={setPaymentMethodTitle}
        />
      ),
    },
  };


  useEffect(() => {
    const getMethods = async () => {
      const token = await getAccessTokenSilently();
      const methods = await FinanceService.getPaymentMethods(token);
      setPaymentMethods(
        methods.result
          .map((item) => {
            const validPaymentMethods = item?.type?.toLowerCase() === "ach" || item?.type?.toLowerCase() === "credit card";
            if (validPaymentMethods && item?.allow_member === true) {
              const findMethod = setMethods[item.type];
              if (findMethod) {
                return {
                  title: item.type,
                  is_default: item.is_default,
                  type: item.type,
                  _id: item._id,
                  ...findMethod,
                };
              }
              return {
                ...setMethods["default"],
                ...item,
                title: item.type,
                nonExpandable: true,
              };
            }
          })
          .filter((item) => item !== undefined)
      );
    };
    getMethods();
  }, [getAccessTokenSilently]);
  useEffect(() => {
    if(!paymentMethods?.length) return;
    formik.setFieldValue("allPaymentsMethods", paymentMethods);
  }, [paymentMethods]);
  const onPaymentValueChange = (id, event) => {
    const { value, name } = event.target;
    const paymentKey = `${id}_payment`;
    formik.setFieldValue(paymentKey, {
      ...formik.values[paymentKey],
      [name]: value,
    });
    const numberValue = name === "amount" ? +value : value;
    const paymentObject = formik.values?.payments?.find(
      (item) => item.paymentMethod === id
    );
    if (paymentObject) {
      const index = formik.values?.payments?.findIndex(
        (item) => item.paymentMethod === id
      );
      if (index !== -1) {
        formik.setFieldValue("payments", [
          ...formik.values?.payments?.splice(index, 1),
          {
            ...formik.values?.payments?.[index],
            paymentMethod: id,
            [name]: numberValue,
          },
        ]);
      }
    } else {
      if (formik.values?.payments) {
        formik.setFieldValue("payments", [
          ...formik.values?.payments,
          { paymentMethod: id, [name]: numberValue },
        ]);
      }
      formik.setFieldValue("payments", [
        { paymentMethod: id, [name]: numberValue },
      ]);
    }
    const paymentName =
      paymentMethods.find((item) => item.title === id)?.title ?? "N/A";
    const paymentNameIndex = formik.values?.paymentMethods?.findIndex(
      (item) => item.name === paymentName
    );
    if (paymentNameIndex === -1) {
      formik.setFieldValue("paymentMethods", [
        ...(formik.values?.paymentMethods ?? []),
        {
          name: paymentName,
          [name]: numberValue,
        },
      ]);
    } else {
      formik.setFieldValue("paymentMethods", [
        ...(formik.values?.paymentMethods?.splice(paymentNameIndex, 1) ?? []),
        {
          name: paymentName,
          [name]: numberValue,
        },
      ]);
    }
  };
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;

  const newAccount = async () => {
    const token = await getAccessTokenSilently();
    await FinanceService.createNewUserAccount(
      {
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        payment: {
          account_type: formik.values.new_account_name.value,
        },
      },
      token
    )
      .then(() => {
        toastr.success("Success", "New Account has been created");
      })
      .catch((e) => {
        toastr.error('Error', e?.data?.message);
      });
  };
  // const methods = Object.entries(formik?.values)
  //   .filter(
  //     ([key]) =>
  //       key.includes("_payment") &&
  //       key !== "onAccountCredit_payment" &&
  //       key !== "cash_payment"
  //   )
  //   .map(([key, value]) => key);
  const methods = Object.entries(formik?.values)
    .filter(
      ([key]) =>
        key.includes("_payment") &&
        key !== "onAccountCredit_payment" &&
        key !== "cash_payment"
    )
    .map(([key, value]) => {
      const split = key.split("_payment");
      return {
        paymentMethod: split[0],
        amount: formik?.values?.[key] === "" ? 0 : formik?.values?.[key],
      };
    })
    .filter((item) => item !== undefined);

  const newPaymentMethod = paymentMethodTitle;
  const totalValue =
    formik.values?.checks &&
    formik.values?.checks?.reduce(function(pre, curr) {
      return pre + curr.amount;
    }, 0);
  const totalPayment = [];
  if (formik?.values?.cash_payment) {
    totalPayment.push(Number(formik?.values?.cash_payment));
  }
  if (formik.values?.checks) {
    totalPayment.push(Number(totalValue));
  }
  if (formik?.values?.onAccountCredit_payment) {
    totalPayment.push(Number(formik?.values?.onAccountCredit_payment));
  }
  if (formik?.values?.creditCardAmount) {
    totalPayment.push(Number(formik?.values?.creditCardAmount));
  }
  if (formik?.values?.ach) {
    totalPayment.push(Number(formik?.values?.ach));
  }

  const paymentValues =
    methods && methods?.map((item) => parseFloat(item?.amount));
  const newPaymentsValues = paymentValues.filter(function(value) {
    return !Number.isNaN(value);
  });

  const sumofNewPayments = newPaymentsValues?.reduce((sum, a) => sum + a, 0);
  const sumofPayments = totalPayment?.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  const totalSumofValues = methods?.length
    ? +sumofNewPayments + sumofPayments
    : sumofPayments;

  const totalSplittedAmount =
    formik?.values?.allData &&
    formik?.values?.allData?.map((item) => item?.charge?.amount);
  const sumOfSplittedAmount = totalSplittedAmount?.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  const [calculatedAmount, setCalculatedAmount] = useState("00");
  let commingAmount =
    formik.values?.amount_due === 0 || formik.values?.amount_due
      ? formik.values?.amount_due
      : formik.values?.values?.[0]?.split_amount
      ? formik.values?.values?.[0]?.split_amount
      : formik.values?.values?.[0]?.total_amount ?? sumOfAmounts;
  useEffect(() => {
    let finalDiscount =
      formik?.values?.values?.[0]?.discount_type == "%"
        ? (formik?.values?.values?.[0]?.discount / 100) * commingAmount
        : // .replace("-", "")
          formik?.values?.values?.[0]?.discount;
    let finalTax =
      formik?.values?.values?.[0]?.tax_type == "%"
        ? (formik?.values?.values?.[0]?.tax / 100) * commingAmount
        : // .replace("-", "")
          formik?.values?.values?.[0]?.tax;

    let finalAmount = (
      (formik?.values?.values?.[0]?.discount ? Number(finalDiscount) : 0) +
      -(
        commingAmount +
        (formik?.values?.values?.[0]?.tax ? Number(finalTax) : 0)
      )
    )
      .toFixed(2)
      .replace("-", "");

    setCalculatedAmount(finalAmount);
  }, [formik.values]);
  return (
    <div className="payment-main" style={{ margin: "0px 115px" }}>
      {paymentMethods.map((method, index) => {
        return (
          <div key={index} className="mb-1 cash-accordian">
            <FinanceAccordion
              body={method.content}
              header={method.header}
              title={method?.title}
              type={method?.type}
              // isDefault={method?.is_default}
              addToMember={method?.allow_member}
              expanded={expanded === index}
              onChange={handleChange(index)}
              onPaymentValueChange={onPaymentValueChange}
              id={method.id}
              formik={formik}
              current={current}
              setCurrent={setCurrent}
              setExpanded={setExpanded}
              index={index}
            />
          </div>
        );
      })}

      <div className="payment-information">
        <div className="total">
          Total:
          <span className="total_payment_amount">
            {numberFormat({number:totalSumofValues, decimal: 2, style: "currency"})}
          </span>
          /
          {formik.values?.amount_due === 0 || formik.values?.amount_due
            ? numberFormat({number:parseFloat(formik.values?.amount_due), decimal: 2, style: "currency"})
            : formik?.values?.allData?.[0]?.charge?.amount
            ? numberFormat({number:sumOfSplittedAmount, decimal: 2, style: "currency"})
            : formik?.values?.values?.[0]?.split_amount
            ? numberFormat({number:formik?.values?.values?.[0]?.split_amount, decimal: 2, style: "currency"})
            : formik.values?.current_total
            ? numberFormat({number:formik.values?.current_total, decimal: 2, style: "currency"})
            : formik.values?.current_amount
            ? numberFormat({number:formik.values?.current_amount, decimal: 2, style: "currency"})
            : formik.values?.values?.[0]?.split_amount
            ? numberFormat({number:(
                parseFloat(formik.values?.values?.[0]?.split_amount)
              ), decimal: 2, style: "currency"})
            : numberFormat({number:(parseFloat(formik.values?.values?.[0]?.total_amount) ?? parseFloat(sumOfAmounts)), decimal: 2, style: "currency"})
          }
          
        </div>
        <div className="d-flex align-items-baseline">
          <LabelField title={"Convenience Fee"} />
          <div className="col-sm-3 convinience-input-icon input-icon">
            <InputField
              type="number"
              {...{
                formik,
                title: "none",
                name: "convenient_fee",
                placeholder: "100",
                value: formik.values?.convenient_fee ?? 0,
              }}
              handleChange={(e) => {
                formik.setFieldValue(
                  `convenient_fee`,
                  parseFloat(e.target.value.match(regex)[0])
                );
              }}
            />
            <span className="mb-7">{currency}</span>
          </div>
          <LabelField
            className={
              formik.values?.convenient_fee_by === "member" ? "paid-by" : "mr-5"
            }
            title={"Paid by Member"}
            onClick={() => {
              formik.setFieldValue("convenient_fee_by", "member");
            }}
          />
          <LabelField
            className={
              formik.values?.convenient_fee_by !== "member" ? "paid-by" : ""
            }
            title={"Paid by Shul/Synagogue"}
            onClick={() => {
              formik.setFieldValue("convenient_fee_by", "admin");
            }}
          />
        </div>
        <div className="party-payment py-10">
          <MyCheckbox
            name={"third_party"}
            title="3rd Party Payment"
            handlechange={(event) => {
              formik.setFieldValue("is_third_party", event.target.checked);
            }}
            checked={formik.values?.is_third_party}
          />
          {formik.values?.is_third_party && (
            <div className="pl-10 pt-5 ">
              <div className="col-sm-6 px-0 party-payment-icon ">
                <Select
                  {...{
                    formik,
                    name: `third_party`,
                    checkValidation: false,
                  }}
                  onInputChange={(text) => searchAction(text)}
                  classNamePrefix="react-select"
                  formatOptionLabel={formatOptionLabel}
                  options={data}
                  isLoading={loading}
                  styles={colourStyles}
                  onChange={(value) => {
                    formik.setFieldValue(`third_party`, {
                      id: value._id,
                      name: value.label,
                    });
                  }}
                  value={
                    formik.values?.third_party
                      ? {
                          label: formik.values?.third_party?.name,
                          value: formik.values?.third_party?.id,
                        }
                      : ""
                  }
                  components={{ DropdownIndicator }}
                />
              </div>
              <div
                className="create-new-account"
                style={createNewAccount ? { opacity: 0.3 } : {}}
              >
                <span
                  className="new-add-icon"
                  onClick={() => setCreateNewAccount(true)}
                >
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 25H35"
                      stroke="#2555EF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M25 15V35"
                      stroke="#2555EF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="add" onClick={() => setCreateNewAccount(true)}>
                  Create New Account
                </span>
              </div>
              {createNewAccount && (
                <div className="">
                  <div className="col-sm-12 create-account">
                    <div className="col-sm-3 px-0 mr-20">
                      <InputField
                        {...{
                          formik,
                          title: "First Name",
                          name: "first_name",
                          placeholder: "",
                        }}
                      />
                    </div>
                    <div className="col-sm-3 px-0">
                      <InputField
                        {...{
                          formik,
                          title: "Last Name",
                          name: "last_name",
                          placeholder: "",
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-sm-6 pt-5 pl-0">
                      <LabelField title={"Account Type"} />
                      <Select
                        {...{
                          formik,
                          name: `new_account_name`,
                          checkValidation: false,
                        }}
                        classNamePrefix="react-select"
                        options={[
                          { label: "Member", value: "member" },
                          { label: "Not A Member", value: "non-member" },
                          { label: "Organization", value: "organization" },
                        ]}
                        isLoading={loading}
                        styles={colourStyles}
                        onChange={(value) => {
                          formik.setFieldValue(`new_account_name`, {
                            value: value.value,
                            label: value.label,
                          });
                        }}
                        value={
                          formik.values?.new_account_name
                            ? {
                                label: formik.values?.new_account_name?.label,
                                value: formik.values?.new_account_name?.value,
                              }
                            : ""
                        }
                        components={{ DropdownIndicator }}
                      />
                    </div>
                    <div className="pt-5 create-account">
                      <span className="blue" onClick={newAccount}>
                        Create Account
                      </span>
                      <span onClick={() => setCreateNewAccount(false)}>
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentInformation;
