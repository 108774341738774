import styled, { css } from "styled-components";

export const ClassBody = styled.div`
position: relative;
${(props) => (props?.steps ?
    css`
    padding-right: 20px;
    padding: 31px 40px;
      @media only screen and (min-width: 1920px) and (min-height: 1081px) {
      max-height:76vh;
      }
      `
    : css`
      padding-right:51px ;
      overflow-x: hidden;
      `

  )};

@media only screen and (max-width: 1920px) and (max-height: 1596px) {
  height: 100%;
}

`
export const Title = styled.div`
text-align: center;
`
export const ClassTitle = styled.h1`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #002E5C;
`
export const ClassInformation = styled.h1`
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
color: #002E5C;
text-align: left;
margin-bottom: 42px;
`

export const ClassCaption = styled.p`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 40px;
text-transform: capitalize;
color: #002E5C;
div{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px;
gap: 10px;
font-size: 12px;
font-weight: bold;
height: 24px;
background: rgba(250, 51, 114, 0.2);
border-radius: 5px;
margin-left: 8px;
color: #FA3372;
}
`
export const ClassCaptionDetails = styled.div`
  display: flex;
  text-align: left;
  color:#002E5C;
  margin-bottom: 20px;
  div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    
  }
  div:last-child{
    align-items: flex-start;
    width: 75%;  }
  span{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}
`
export const Boxes = styled.div`
        width: revert;
         display:flex;
         justify-content:space-between;
         text-align: center;
         height: 106px;
         background: rgba(37, 85, 239, 0.04);
        border-radius: 4px;
      div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        color: #2555EF;

        span{
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #002E5C;
        }
        }
    
`

export const ClassDescription = styled.div`
margin-top: 20px;
font-family: 'Poppins';
font-style: normal;
color: #002E5C;
span{
padding-bottom: 5px;
font-weight: 400;
font-size: 12px;
line-height: 50px;
}
p{
  margin-top: 20px;
font-weight: 500;
font-size: 16px;
line-height: 30px;
word-break: break-word;
p{
  span{
    font-size: 20px !important;
  }
}
}
`
export const ClassSchedule = styled.div`
font-family: 'Poppins';
font-style: normal;
color: #002E5C;
span{
font-weight: 400;
font-size: 12px;
line-height: 18px;
text-transform: capitalize; 
 padding-bottom: 5px;
}
`
export const ScheduleList = styled.ul`
color: #002E5C;

list-style: none;
margin: 0;
padding: 0;
font-weight: 500;
font-size: 16px;
line-height: 26px;
li{
  span{
    font-weight:bolder;
    font-size: 17px;

  }
}
`
export const Details = styled.ul`
list-style: none;
margin: 0;
padding: 0;
color: #002E5C;

li{
  display: flex;
  justify-content: space-between; 
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
span{
  display: flex;
  justify-content: space-between; 
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  span{
    color: var(--dark-blue, #002E5C);
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
 
}
`
export const TotalPay = styled.div`
  width: 543px;
    height: 59px;
    background: #EAEFF1;
    display: flex;
  justify-content: space-between; 
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 48px;
  padding-right: 48px;
  margin-top: 33px;
  span{
    color: var(--dark-blue, #002E5C);
    text-align: right;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
`

export const Button = styled.div`
width: 100%;
display: flex;
justify-content: center;
position: absolute;
bottom: 0;

`
export const ClassButton = styled.button`
border: none;
background-color:${(props) => (props?.isAvailableClass ? 'gray' : '#2555EF')};
color: #FFFFFF;
margin-bottom: 20px;
margin-top: 20px;
width: 100%;
height: 39px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
padding: 0;
border-radius: 5px;
`
export const AlreadyMember = styled.p`
text-align: center;
  color: #404253;
span{
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: #2555EF;
}
`

export const InfinitySymbol = styled.span`
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 32px !important;
    color: #2555EF !important;
    font-family: math !important;
`

export const Note = styled.div`
  opacity: 0.7;
  color: #002e5c;
`