import moment from "moment";
import thousands from "thousands";


const totalTicketAmount = (ticketTypeVariants) => {
    let totalAmount = 0;
    const amounts = ticketTypeVariants?.forEach((item) => {
        totalAmount += item?.amount * item?.selectedQuantity || 0;
    });
    return totalAmount;
};

const calculateNumberOfTickets = (ticketTypeVariants) => {
    let numberOfTickets = 0;
    const tickets = ticketTypeVariants?.forEach((item) => {
        numberOfTickets += item?.selectedQuantity || 0;
    });
    return numberOfTickets;
};


export const calculateDiscount = (data, ticketTypeVariants, type = "event", isAuthenticated) => {
    const pricing = data?.eventPricing;
    const discount = data?.eventDiscount;
    const allTicketsAmount = totalTicketAmount(ticketTypeVariants);
    const totalTickets = calculateNumberOfTickets(ticketTypeVariants);
    let discountAmount = 0;
   const dataforEach = discount?.forEach((item) => {
        if(item?.forMemberOnly && !isAuthenticated) return;
        if (item?.discount_type === 'date') {
            const endDate = moment(item?.end_date).format("MM/DD/YYYY");
            const startDate = moment(item?.start_date).format("MM/DD/YYYY");
            const currentDate = moment(new Date()).format("MM/DD/YYYY");

            if (startDate <= currentDate && endDate >= currentDate) {
                if (item?.price_type === "fixed") {
                    discountAmount += item?.value;
                } else {
                    discountAmount = ((allTicketsAmount * item?.value) / 100) + discountAmount ; 
                }
            } else {
                console.log("The discount date is expired ");
            }
        } else if (item?.discount_type === 'amount' && allTicketsAmount >= item?.amount) {
            if (item?.price_type === "fixed") {
                discountAmount += item?.value;
            } else {
                discountAmount = (allTicketsAmount * item?.value) / 100 + discountAmount;
            }
        } else if (item?.discount_type === 'quantity' && item?.quantity && totalTickets >= item?.quantity) {
            if (item?.price_type === "fixed") {
                discountAmount += item?.value;
            } else {
                discountAmount = (allTicketsAmount * item?.value) / 100 + discountAmount;
            }
        }
    });


    const totalAfterDiscount = allTicketsAmount > 0 ? (+allTicketsAmount - +discountAmount).toFixed(2) : "0.00"
    return {
        totalAfterDiscount,
        discountTotal: discountAmount < 0 ? `-$${(Math.abs(discountAmount)).toFixed(2)}` : `-$${(discountAmount).toFixed(2)}`
    };
};
export const calculateTotalPayment = (paymentMethod,data,ticketTypeVariants,type, isAuthenticated) => {
    const totalPayment = calculateDiscount(data,ticketTypeVariants, type,isAuthenticated)?.totalAfterDiscount?.replace(/,/g, '')
    return (+totalPayment).toFixed(2)
}    

export const calculateTotalPaymentWithCVFee = (paymentMethod,data,ticketTypeVariants,type,isAuthenticated) => {
    const totalPayment = calculateDiscount(data,ticketTypeVariants,type,isAuthenticated)?.totalAfterDiscount?.replace(/,/g, '')
    if(paymentMethod?.coverConvenienceFee && totalPayment > 0) {
        const convenienceFeeAmount = paymentMethod?.convenienceFeeInfo?.type === "%" 
        ? (totalPayment * paymentMethod?.convenienceFeeInfo?.amount) / 100 : 
        paymentMethod?.convenienceFeeInfo?.amount
        return (+totalPayment + +convenienceFeeAmount).toFixed(2)
    } else {
        return (+totalPayment).toFixed(2)
    }
}   

