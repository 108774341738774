import * as React from "react"

function MemberDrawerSvg (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25.788}
            height={21.876}
            viewBox="0 0 25.788 21.876"
            {...props}
        >
            <g data-name="Group 1396">
                <g data-name="Group 1395">
                    <path
                        data-name="Path 26152"
                        d="M22.066 11.81h-1.994a5.187 5.187 0 01.314 1.783v7.538a2.223 2.223 0 01-.128.745h3.3a2.236 2.236 0 002.234-2.234v-4.109a3.727 3.727 0 00-3.726-3.723z"
                        fill="#99b7ef"
                    />
                </g>
            </g>
            <g data-name="Group 1398">
                <g data-name="Group 1397">
                    <path
                        data-name="Path 26153"
                        d="M5.4 13.589a5.188 5.188 0 01.314-1.783H3.723A3.727 3.727 0 000 15.533v4.109a2.236 2.236 0 002.234 2.234h3.3a2.223 2.223 0 01-.128-.745z"
                        fill="#99b7ef"
                    />
                </g>
            </g>
            <g data-name="Group 1400">
                <g data-name="Group 1399">
                    <path
                        data-name="Path 26154"
                        d="M15.174 9.871h-4.559a3.727 3.727 0 00-3.723 3.723v7.538a.745.745 0 00.745.745h10.516a.745.745 0 00.745-.745v-7.538a3.727 3.727 0 00-3.724-3.723z"
                        fill="#2555ef"
                    />
                </g>
            </g>
            <g data-name="Group 1402">
                <g data-name="Group 1401">
                    <path
                        data-name="Path 26155"
                        d="M12.894 0a4.476 4.476 0 104.477 4.477A4.482 4.482 0 0012.894 0z"
                        fill="#2555ef"
                    />
                </g>
            </g>
            <g data-name="Group 1404">
                <g data-name="Group 1403">
                    <path
                        data-name="Path 26156"
                        d="M5.032 4.173A3.347 3.347 0 108.38 7.521a3.352 3.352 0 00-3.348-3.348z"
                        fill="#99b7ef"
                    />
                </g>
            </g>
            <g data-name="Group 1406">
                <g data-name="Group 1405">
                    <path
                        data-name="Path 26157"
                        d="M20.756 4.173a3.348 3.348 0 103.348 3.348 3.352 3.352 0 00-3.348-3.348z"
                        fill="#99b7ef"
                    />
                </g>
            </g>
        </svg>
    )
}

export default MemberDrawerSvg
