import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
};

export const lockLoaderSlice = createSlice({
  name: "lockLoader",
  initialState,
  reducers: {
    loader(state, action) {
      state.loader = action.payload === "pending";
    },
  },
});

export const { loader } = lockLoaderSlice.actions;

// Loader selector
export const selectLoader = (state) => state.loader;

export default lockLoaderSlice.reducer;
