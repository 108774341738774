import styled from "styled-components"

export const ContactUsWrapper = styled.div`
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-style: normal;
    background: var(--White-100, #FFF);
`

export const ContactUsContainer = styled.div`
    width: 238px;
    height: 249px;
    color: var(--Dark-Blue-100, #002E5C);
  
    font-size: 14px;
    font-weight: 500;
    line-height: 13px; 
 div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }
`
export const ContactUsText = styled.div`
    height: 56px;
    background-color: #2555EF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    
    font-weight: 600;
    line-height: 13px;
`
export const Phone = styled.div`
    margin-bottom: 38px;
    margin-top: 25px;
    a{
        padding-top:12px;
        cursor: pointer;
        color:black;
    }
  
`
export const Email = styled.div`
 span{
        padding-top:12px;
        cursor: pointer;
    }
`

export const Wrapper = styled.div`
 cursor: pointer;
    display:flex;
    top: ${(props) => (props.isMobile ? '21px' : '10px')};
    right: ${(props) => (props.isMobile ? '85px' : '72px')};
    position: fixed;
    /* overflow-x: scroll; */
    justify-content: flex-end;
div{
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
    }
`
export const ContactUsContent = styled.div`
    position: absolute;
    right: 6%;
    bottom: -22%;
`