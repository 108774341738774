import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  InputField,
  LabelField,
  MyCheckbox,
} from "components";
import FindMyCity from "components/FindMyCity/FindMyCity";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DonationStepper from "../DonationStepper";
import "./DonationDetails.scss";
const DonationDetails = ({ formik,external }) => {
  const [checked, setChecked] = useState(false);
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (!external) {
      formik.setFieldValue(
        "first_name",
        JSON.parse(localStorage.getItem("accountInfo"))?.firstName
      );
      formik.setFieldValue(
        "last_name",
        JSON.parse(localStorage.getItem("accountInfo"))?.lastName
      );
      formik.setFieldValue(
        "receipt_email",
        JSON.parse(localStorage.getItem("accountInfo"))?.primary_email_address
      );
    }
    window.onload = function() {
      push("/donation");
    };
    return () => {
      window.onload = null;
    };
  }, []);

  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const handleNext = () => {
    if (
      localStorage.getItem("externalToken") &&
      (!formik?.values?.first_name ||
        !formik?.values?.last_name ||
        !formik?.values?.receipt_email)
    ) {
      enqueueSnackbar("please fill all mandatory fields", { variant: "error" });
      // toastr.info('Validation', 'please fill all mandatory fields', {
      //   position: 'top-center',
      // });
      return;
    }
    if (
      localStorage.getItem("externalToken") &&
      !formik?.values?.receipt_email.match(mailformat)
    ) {
      enqueueSnackbar("please enter a valid email", { variant: "error" });
      // toastr.info('Validation', 'please enter a valid email', {
      //   position: 'top-center',
      // });
      return;
    }
    if (checked) {
      if (
        !formik?.values?.addressInfo?.formattedAddress ||
        !formik?.values?.city ||
        !formik?.values?.state ||
        !formik?.values?.zip_code
      ) {
        enqueueSnackbar("please fill all mandatory fields", {
          variant: "error",
        });
        // toastr.info('Validation', 'please fill all mandatory fields', {
        //   position: 'top-center',
        // });
        return;
      }
    }
    push(external ? "/donation/pay" : "/donation-view/pay");
  };

  const addressHandleChange = async (address) => {
    console.log("address: ", address);
    formik.setFieldValue(`addressInfo`, {
      formattedAddress: address?.label,
      place_id: address?.value?.place_id,
    });
    // setPlaceID(address?.value?.place_id);
    console.log(" address?.value?.terms", address?.value?.terms);
    const sortedaddress = address?.label.split(",");

    const result = await axios(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${address?.value?.place_id}&key=AIzaSyAJx9g0BQSBzSKKzchtx1OFIaOzt6-_qN0`
    );
    let zip_code = result.data.results[0]?.address_components?.filter(
      (item) => item.types[0] === "postal_code"
    );
    console.log("zip_code", zip_code);
    formik.setFieldValue(`zip_code`, zip_code?.[0]?.short_name);
    let countryData = result.data.results[0]?.address_components?.filter(
      (item) => item.types[0] === "country"
    );
    console.log("countryData", countryData);

    if (address?.value?.terms.length === 3) {
      formik.setFieldValue(`state`, address?.value?.terms[2]?.value);
      formik.setFieldValue(`city`, address?.value?.terms[1]?.value);
    } else {
      formik.setFieldValue(`state`, address?.value?.terms[3]?.value);
      formik.setFieldValue(`city`, address?.value?.terms[2]?.value);
    }
  };

  const changeHandler = (e,name) => {
    const { value } = e.target;
    if(!value?.trim()?.length) return formik.setFieldValue(name, "");
    formik.setFieldValue(name, value);
  }

  return (
    <>
      <div className="donation__content">
        <DonationStepper activeStep={1} />
        <div className="DonationDetails__content">
          <Grid container spacing={9}>
            <Grid item xs={12} sm={6}>
              <div className="amount__cause form-group">
                <InputField
                  type="text"
                  title="First Name"
                  mandatory
                  readOnly={!external}
                  {...{
                    // formik,
                    name: "first_name",
                    placeholder: "First Name",
                    value: formik?.values?.first_name || "",
                    // value:formik.values.account_type ?? "Select Account Type...",
                  }}
                  handleChange={(e) => {
                    changeHandler(e,"first_name");

                  }}
                  inputClassName="donation__input"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="amount__cause">
                <InputField
                  type="text"
                  title="Last Name"
                  mandatory
                  readOnly={!external}
                  {...{
                    // formik,
                    name: "last_name",
                    placeholder: "Last Name",
                    value: formik?.values?.last_name || "",
                    // value:formik.values.account_type ?? "Select Account Type...",
                  }}
                  handleChange={(e) => {
                    changeHandler(e,"last_name");
                  }}
                  inputClassName="donation__input"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={9} style={{    display: 'flex',
            alignItems: 'center'}} >
            <Grid item xs={12} sm={6}>
              <div className="amount__cause">
                <InputField
                  type="text"
                  title="Email"
                  mandatory
                  readOnly={!external}
                  {...{
                    // formik,
                    name: "email",
                    placeholder: "Email",
                    value: formik?.values?.receipt_email || "",
                  }}
                  inputClassName="donation__input"
                  handleChange={(e) => {
                    changeHandler(e,"receipt_email");
                  }}

                />
              </div>
            </Grid>
          </Grid>
          {formik.values?.paperCheck && (
            <Grid container spacing={3} className="location-wrapper">
              <Grid item xs={12}>
                <LabelField title="Address" mandatory />
                <FindMyCity
                  handleChange={(data) => addressHandleChange(data)}
                  value={{
                    label: `${
                      formik?.values?.addressInfo?.formattedAddress
                        ? formik?.values?.addressInfo?.formattedAddress.split(
                            ","
                          )[0]
                        : "Enter Address"
                    }`,
                    value: `${formik?.value?.addressInfo?.place_id}`,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  type="text"
                  title="Address 2"
                  {...{
                    // formik,
                    name: "address2",
                    placeholder: "Address 2 (Apt., Suite, Unit)",
                    value: formik?.values?.address2,
                  }}
                  handleChange={(e) => {
                    formik.setFieldValue("address2", e?.target?.value);
                  }}
                  inputClassName="donation__input"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputField
                  type="text"
                  title="City"
                  mandatory
                  {...{
                    name: "city",
                    placeholder: "City",
                    value: formik?.values?.city,
                  }}
                  handleChange={(e) => {
                    formik.setFieldValue("city", e?.target?.value);
                  }}
                  inputClassName="donation__input"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputField
                  type="text"
                  title="State"
                  mandatory
                  {...{
                    // formik,
                    name: "state",
                    placeholder: "State",
                    value: formik?.values?.state,
                  }}
                  handleChange={(e) => {
                    formik.setFieldValue("state", e?.target?.value);
                  }}
                  inputClassName="donation__input"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputField
                  title="Zip code"
                  mandatory
                  {...{
                    // formik,
                    name: "zip_code",
                    placeholder: "Zip",
                    value: formik?.values?.zip_code,
                  }}
                  handleChange={(e) => {
                    formik.setFieldValue("zip_code", e?.target?.value);
                  }}
                  inputClassName="donation__input"
                />
              </Grid>
            </Grid>
          )}
        </div>
      <div className="donation-details-buttons">
        <button
          className="btn btn-primary prev__btn"
          onClick={() => {
            push(external ? "/donation" : "/donation-view");
          }}
        >
          Previous
        </button>
        <button className="btn btn-primary next__btn" onClick={handleNext}>
          Next
        </button>
    
      </div>
      </div>
    </>
  );
};

export default DonationDetails;
