import styled, { css } from "styled-components";

export const Status = styled.div`
	padding-left: 9.08px;
	padding-top: 5.58px;
	padding-bottom: 5.58px;
	padding-right: 8.5px;
	border-radius: 5px;
	font-size: 13px;
	font-weight: 600;
	line-height: 20px;
	width: fit-content;
	${(props) =>
		props.type === "paid"
			? css`
					color: #2555ef;
					background: rgba(37, 85, 239, 0.2);
			  `
			: css`
					color: #fa3372;
					background: rgba(250, 51, 114, 0.2);
			  `}
`;
