
import { Grid } from '@material-ui/core';
import Paper from "@mui/material/Paper";
import { InputField } from 'components/InputField/InputField';
import ManageCreditsCard from 'components/ManageCreditsCard/ManageCreditsCard';
import StripeCard from 'components/StripeCard/StripeCard';
import { numberFormat } from 'helper/numberFormat';
import React, { useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import thousands from 'thousands';
import { PaymentButton, PaymentCardTitle, PaymentCardWrapper } from './Theme';
export const PaymentCard = ({ TotalForPay, formik, setError, error, PayNow, isLoadingPay,handleTotalForPay,submitButton,setLoadingPay,checkIsStripe,addCardStripeRef,totalPaymentWithCVFee,isAuthenticated, setSelectedCard, selectedCard }) => {   

    const totalPay =  handleTotalForPay()

    const handleExpiryChange = (text) => {
        formik.setFieldValue('cardsDetails.expiry', text);
        if (text.length > 2) {
            if (text[2] !== '/') {
                formik.setFieldValue(
                    'cardsDetails.expiry',
                    text.substring(0, 2) + '/' + text.substring(2, 4)
                );
            }
        }
    };


    const handlePayment = async () => {
        PayNow(checkIsStripe() || false)
    }


    useEffect(() => {
        if(isAuthenticated) return setError({ value: false, message: "" });
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const emailValidation = reg.test(formik?.values?.accountInfo?.primary_email_address)

        if(totalPay?.total !== '0.00' ||  TotalForPay > 0){
        if (!formik?.values?.accountInfo?.first_name ||
            !formik?.values?.accountInfo?.last_name ||
            !formik?.values?.accountInfo?.primary_email_address || 
            (!checkIsStripe() && !formik?.values?.cardsDetails?.zip)) {
            setError({ value: true, message: "Please fill all mandatory fields" });
            return;
        }
        if (!checkIsStripe() && (!formik?.values?.cardsDetails?.card_number ||
            !formik.values?.cardsDetails?.expiry ||
            (!formik.values?.cardsDetails?.cvc || formik.values?.cardsDetails?.cvc?.length < 3))) {
            setError({ value: true, message: "All card details fields are required" })
            return;
        }
        else if (!emailValidation) {
            setError({ value: false, message: "Please enter valid email", type: 'emailError' })
            return;
        }


        else if (!checkIsStripe() && formik?.values?.cardsDetails?.card_number?.length < 14) {
            setError({ value: false, message: "The card number must contain 14 number", type: 'CardNumberError' })
            return;
        } 
        // else if (!checkIsStripe() && formik.values?.cardsDetails?.cvc?.length < 3) {
        //     console.log('formik.values.cardsDetails.cvc', formik.values.cardsDetails,formik.values?.cardsDetails?.cvc )

        //     setError({ value: false, message: "The CVC should be 3 or 4 digits", type: 'CardNumberError' })
        //     return;
        // }}
        else {
            setError({ value: false, message: "" })
            return;
        }
    }else {
        if (!formik?.values?.accountInfo?.first_name ||
            !formik?.values?.accountInfo?.last_name ||
            !formik?.values?.accountInfo?.primary_email_address) {
            setError({ value: true, message: "Please fill all mandatory fields" });
            return;
        }
        else if (!emailValidation) {
            setError({ value: false, message: "Please enter valid email", type: 'emailError' })
            return;
        }
        else {
            setError({ value: false, message: "" })
            return;
        }
    }
    }, [formik.values])
    return (
        <>
        {(totalPay?.total !== '0.00' ||  TotalForPay > 0) && <Paper
        
        sx={{
            minHeight: "278px",
            marginTop: "33px",
            borderRadius: "10px",
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
        >


        <PaymentCardWrapper>
            <PaymentCardTitle><span>Payment</span></PaymentCardTitle>
            <Grid container spacing={3}>
                {isAuthenticated ? (
                    <Grid item xs={12} className='d-flex align-items-center'>
                        <ManageCreditsCard 
                            checkIsStripe={checkIsStripe} 
                            setSelectedCard={setSelectedCard}
                            selectedCard={selectedCard}
                        />
                    </Grid>
                )
                : checkIsStripe() ? (
                    <Grid item xs={12}>
                    <StripeCard
                    loading={isLoadingPay}
                    setLoading={setLoadingPay}
                    saveCard={false}
                    ref={addCardStripeRef}
                    />
                </Grid>
                ) :(
                <>
                    <Grid item xs={12} sm={12} md={6}>
                        <InputField placeholder='XXXX XXXX XXXX XXXX' label='Card Number'
                            isRequired={true}
                            styleWrapper={{ width: '100%' }}
                            style={{ width: '100%', background: TotalForPay === 0 && "var(--Gray-10, #F8F8F9)" }}
                            isOptional={false}
                            labelStyle={{
                                color: TotalForPay === 0 ? "var(--Gray-100, #B5B5C3)" : "var(--Dark-Blue-100, #002E5C)",
                                fontSize: '14px', fontWeight: 500,
                                fontFamily: 'Poppins',
                            }}
                            value={formik.values?.cardsDetails.card_number}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            handleChange={(e) => {
                                formik.setFieldValue('cardsDetails.card_number', e?.target?.value);
                            }}
                            minLength="16"
                            maxLength="16"
                            isGray={TotalForPay === 0}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputField placeholder='MM/YY' label='Expire Date'
                            isRequired={true}
                            styleWrapper={{ width: '100%' }}
                            style={{ width: '100%', background: TotalForPay === 0 && "var(--Gray-10, #F8F8F9)" }}
                            isOptional={false}
                            labelStyle={{
                                color: TotalForPay === 0 ? "var(--Gray-100, #B5B5C3)" : "var(--Dark-Blue-100, #002E5C)",
                                fontSize: '14px', fontWeight: 500,
                                fontFamily: 'Poppins',
                            }}
                            isGray={TotalForPay === 0}
                            value={formik.values?.cardsDetails.expiry}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            handleChange={(e) => handleExpiryChange(e?.target?.value)}

                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputField placeholder='3-4 digits' label='CVC'
                            isRequired={true}
                            minLength="4"
                            maxLength="4"
                            styleWrapper={{ width: '100%' }}
                            style={{ width: '100%', background: TotalForPay === 0 && "var(--Gray-10, #F8F8F9)" }}
                            isOptional={false}
                            labelStyle={{
                                color: TotalForPay === 0 ? "var(--Gray-100, #B5B5C3)" : "var(--Dark-Blue-100, #002E5C)",
                                fontSize: '14px', fontWeight: 500,
                                fontFamily: 'Poppins',
                            }}
                            value={formik.values?.cardsDetails.cvc}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            handleChange={(e) => {
                                formik.setFieldValue('cardsDetails.cvc', e?.target?.value);
                            }}
                            isGray={TotalForPay === 0}
                        />
                    </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <PaymentButton isDisabled={
                        error?.value ||
                        totalPay?.total === 0 
                        || isLoadingPay
                        }>
                        {submitButton}
                        <button  
                        disabled={
                            error?.value ||
                            totalPay?.total === 0 
                        }
                         onClick={() => {
                            if (!error?.value || totalPay?.total > 0
                            ) handlePayment(); 
                        }}>
                            {isLoadingPay ? <ClipLoader
                                color={"#ffffff"}
                                loading={isLoadingPay}
                                size={32}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            /> : totalPay?.total > 0 ? `PAY ${numberFormat({number:+totalPaymentWithCVFee,decimal: 2, style: "currency"})}` : 'PAY'}
                        </button>
                    </PaymentButton>
                </Grid>
            </Grid>
        </PaymentCardWrapper>
        </Paper>
        }
        </>
    );
};
