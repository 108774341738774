// import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ReactComponent as Download } from "assets/icons/DownloadIcon.svg";
import { ReactComponent as Visibilit } from "assets/icons/eyeIcon.svg";
import EditSvgComponent from "assets/icons/svg/Edit/index";
import Action from "components/ActionPopOver";
import MyModal from "components/MyModal";
import React, { useEffect, useState } from "react";
// import UpdateTributeInformation from "../NewCharge/ChargeType/UpdateTributeInformation";
import { useAuth0 } from "@auth0/auth0-react";
import AlertDialog from "components/Dialog";
import FinanceService from "Lib/API/Finance";
import { useRef } from "react";
// import ThankYouLetter from "pages/Donation/ThankYouLetter/ThankYouLetter";
// import TributeInformation from "pages/Donation/TributeInfo";
import { useFormik } from "formik";
import { DateFormetContext } from "../../../app/App.js";
import fbIcon from "../../../assets/icons/facebookpng.png";
import instaIcon from "../../../assets/icons/instapng.png";
import twitterIcon from "../../../assets/icons/twitterpng.png";

import {
  Document,
  Image,
  Link,
  Note,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import { formatDate } from "helper/dateFormat.js";
import { numberFormat } from "helper/numberFormat.js";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";

export const Donation = ({
  setsavetribute,
  setTributeList,
  data,
  onReversalClick,
}) => {
  const dateFormet = React.useContext(DateFormetContext);
  const [open, setOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [reversalActionTypes, setReversalActionTypes] = useState(false);
  const [getTypes, setActionTypes] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const [chargeId, setchargeId] = useState();
  const [pdfLetter, setPdfLetter] = useState(false);
  const [viewDocument, setViewDoc] = useState(false);
  const [thankYouLetterView, setThankYouLetterView] = useState(false);
  const [editData, setEditData] = useState("");
  const [openAttributeInfo, setOpenAttributeInfo] = useState(false);
  const [editTributeData, setEditTributeData] = useState({});
  const [editAction, setEditAction] = useState("");

  const fetchReversalTypes = async (type, Id1, Id2) => {
    setchargeId(Id1);
    setPaymentId(Id2);
    const token = await getAccessTokenSilently();
    await FinanceService.getAllReversalTypes(type, token).then((res) => {
      setActionTypes(res?.result);
    }).catch((err) => {
      toastr.error('Error', err?.data?.message);
    })

    setReversalActionTypes(true);
  };
  const handleCloseDialog = () => {
    setReversalActionTypes(false);
  };
  const inputRef = useRef(null);

  const printDocument = () => {
    setViewDoc(true);
    setPdfLetter(true);
  };
  const onModalClose = () => {
    setOpenAttributeInfo(false);
  };
  const ViewDocument = () => {
    setThankYouLetterView(true);
  };
  const onEditClick = (row) => {
    setOpenAttributeInfo(true);
    setEditTributeData(row);
    // setEditTribute(true);
    setEditData(row);
    setEditAction("edit_tribute");
    // setViewClick(false);
  };
  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: {
      assign_email: editTributeData?.assign_tribute,
      tribute_type: editTributeData?.type ?? editTributeData?.type?.name,
      note: editTributeData?.message,
      donation_recipient_salutations:
        editTributeData?.recipient_salutation ??
        editTributeData?.recipient_salutation?.name,
      donation_recipient_name: editTributeData?.recipient_name,
      donor_salutation: editTributeData?.donor_salutation,
      donor_name: editTributeData?.donor_name,

      address: editTributeData?.send_letter_to?.address,
      email: editTributeData?.send_letter_to?.email,
    },
    // validationSchema: "",
    // onSubmit: async (values) => {},
  });
  const companyLogo = useSelector((state) => state?.homeReducer?.headerLogo || "");

  const styles = StyleSheet.create({
    page: {
      height: "60vh",
    },
    letterMain: {
      width: "100%",
      paddingLeft: "20px",
      paddingRight: "20px",
      fontSize: "16px",
    },
    container: { textAlign: "start", maxWidth: "900px" },
    LetterFlex: {
      marginTop: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    bord: {
      borderTop: "5px solid rgb(36, 85, 239)",
      marginTop: "8",
      paddingTop: "15",
    },
    image: {
      marginTop: "10px",
      height: "100px",
      display: "block",
      width: "200px",
    },
    text: {
      marginRight: "20px",
      color: "rgb(36, 85, 239)",
      fontSize: "24px",
      fontWeight: "bold",
      // fontWeight: 900,
      fontStyle: "normal",
      fontSize: "13",
    },
    donorTitle: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
    },
    donorDescription: {
      marginBottom: "48px",
    },

    donorName: {
      marginBottom: "10px",
      fontWeight: "bold",
      fontSize: "20px",
    },

    donorDescriptionpara: {
      fontSize: "16px",
      textAlign: "start",
      fontFamily: "sans-serif",
    },
    donorAmount: {
      color: "rgb(36, 85, 239)",
      fontWeight: "500",
      fontStyle: "normal",
    },
    donorDescriptionAlign: {
      textAlign: "start",
      fontSize: "16px",
      fontFamily: "sans-serif",
    },
    donorTag: {
      textDecoration: "underline",
      color: "rgb(36, 85, 239)",
    },
    thankyoutitle: {
      marginRight: "40px",
      color: "#2455ef",
      fontSize: "24px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    thankyoutext: {
      color: "black",
      fontWeight: "bold",
      fontStyle: "normal",
    },

    footer: {
      backgroundColor: "#2555ef",
      padding: "30px 40px",
      marginTop: "65px",
    },
    // footerSection: {
    //   tableLayout: "fixed",
    //   width: "100%",
    //   fontFamily: "sans-serif",
    // },
    logosection: {
      width: "50%",
      align: "left",
    },
    socialIcons: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    section: { color: "white", textAlign: "center", margin: "30" },
  });
  const pdf = (
    <Document>
      <Page style={styles.letterMain} wrap>
        <View style={styles.container}>
          <View style={styles.bord}></View>
          <View style={styles.LetterFlex}>
            <Image
              style={{
                width: "150px",
              }}
              // src={companyLogo}
            />
            <Text style={styles.thankyoutitle}>Thank You</Text>
          </View>

          <View style={styles.donorTitle}>
            <Text style={styles.donorName}>
              {data?.tribute_info?.donor_salutation
                ? data?.tribute_info?.donor_salutation + "."
                : ""}
              {data?.tribute_info?.donor_name}
            </Text>
            <Text style={styles.donorDescription}>
              you recieved a donation of
              <Text style={styles.donorAmount}> {numberFormat({number:data?.total_amount, decimal:2, style: "currency"})}</Text>
              <Text>{` from our donors.\n\n`}</Text>
              <Text>
                {`you can collect them just by clicking the button below\n\n`}
              </Text>
              <Text>
                {` In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.\n\n`}
              </Text>
              <Text style={styles.thankyoutext}>{`Thank you\n`}</Text>
              <Text>
                TEMPLE SHALOM by <Text style={styles.donorTag}>Eazytample</Text>
              </Text>
            </Text>
          </View>
          <View style={styles.footer}>
            <View style={styles.socialIcons}>
              <Image
                style={{
                  width: "40px",
                  marginRight: " 5px",
                }}
                src={fbIcon}
              />
              <Image
                style={{
                  width: "40px",

                  marginRight: " 5px",
                }}
                src={instaIcon}
              />
              <Image
                style={{
                  width: "40px",

                  marginRight: " 5px",
                }}
                src={twitterIcon}
              />
            </View>

            <Text
              style={{
                color: "white",
                fontSize: "14px",
                marginTop: "15px",
                borderBottom: "1px solid #fff",
              }}
            >
              This is not a tax invoice. This is a payment receipt for the
              donation service provided to you by{" "}
              <Link
                src="www.google.com"
                style={{
                  color: "white",
                }}
              >
                TEMPLE SHALOM
              </Link>{" "}
              , &nbsp;and{" "}
              <Link
                src="www.google.com"
                style={{
                  color: "white",
                }}
              >
                Eazytemple
              </Link>{" "}
              {`is acting as a payment agent on behalf of the TEMPLE SHALOM.\n\n`}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Link
                src="www.google.com"
                style={{
                  fontSize: "14px",
                  color: "white",
                  marginRight: "10px",
                }}
              >
                Privacy Policy
              </Link>
              <Link
                src="www.google.com"
                style={{
                  fontSize: "14px",
                  color: "white",
                  marginRight: "10px",
                }}
              >
                Unsubscribe
              </Link>
              <Link
                src="www.google.com"
                style={{
                  fontSize: "14px",
                  color: "white",
                  marginRight: "10px",
                }}
              >
                Help
              </Link>
              <Link
                src="www.google.com"
                style={{
                  fontSize: "14px",
                  color: "white",
                }}
              >
                Contact us
              </Link>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="">
      <div className="drawer-header">
        <div className="cursor-pointer">

          <AlertDialog
            open={reversalActionTypes}
            getTypes={getTypes}
            handleClose={handleCloseDialog}
            onDecline={() => {
              setReversalActionTypes(false);
            }}
            onAccept={(id, type, hidden) =>
              onReversalClick(
                data?.charge?.status === "charge"
                  ? { chargeId: data?.charge?._id }
                  : data?.charge?.status === "payment"
                  ? {
                      reversal_type: {
                        id: id,
                        type: type,
                        hidden: hidden,
                      },
                      chargeId: chargeId,
                      paymentId: paymentId,
                    }
                  : "",
                setReversalActionTypes
              )
            }
            content={"Choose a reversal type "}
          />
        </div>
      </div>
      <div className="drawer-title">
        {data?.charge_type === "credit_on_account"
          ? "Credit On Account"
          : "Donation"}
      </div>
      <div className="drawer-content ">
        <div className="first-pr">
          <p className="drawer-row mb-1">
            <div className="">Total Amount</div>
            <div className="value">
              {numberFormat({number:(data?.charge?.amount ?? data?.total_amount), decimal:2, style: "currency"})}
            </div>
          </p>
          <p className="drawer-row mb-1">
            <div className="">Transaction#</div>
            <div className="value" title={data?._id}>
              {" "}
              {data._id ? data._id.slice(0, 5) + "..." : "-"}
            </div>
          </p>
          <p className="drawer-row mb-1">
            <div className="">Status</div>
            <div className="value">{data?.charge?.status}</div>
          </p>
        </div>
        <hr className="my-3" />
        <div className="second-pr">
          <>
            <div className="drawer-row pb-2">Ledger Account</div>
            <div className="form-control form-control-solid h-auto py-2 px-6">
              {data?.Ledger?.name} / {data?.SubLedger?.name ?? "N/A"}{" "}
            </div>
            <div className="drawer-row pt-5 pb-2">Due Date</div>
            <div className="form-control form-control-solid h-auto py-2 px-6">
              {formatDate({date:data?.charge?.due_date, formatType: "defaultWithUTC"})}
            </div>
            <div className="w-50">
              <div className="drawer-row pt-5 pb-2 ">Amount</div>
              <div className="form-control form-control-solid h-auto py-2 px-6">
                <div className="drawer-row">
                  <span>
                    {numberFormat({number:(data?.charge?.amount ?? data?.total_amount), decimal:2, style: "currency"})}
                  </span>
                </div>
              </div>
            </div>
            <p className="drawer-row mt-5">
              <div className="">Total Amount</div>
              <div className="value">
                {numberFormat({number:(data?.charge?.amount ?? data?.total_amount), decimal:2, style: "currency"})}
              </div>
            </p>
            <hr className="mt-3 mb-3" />
            <div className="drawer-row pb-2">In honor of</div>
            <div className="form-control form-control-solid h-auto py-2 px-6">
              {data?.Account?.name}
            </div>
            <p className="drawer-row my-5">
              <div className="">Thank you Letter</div>
              <div className="">
                <Visibilit className="cursor-pointer" onClick={ViewDocument} />
                <PDFDownloadLink
                  document={pdf}
                  fileName={"Thank You Letter" + ".pdf"}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading . . ."
                    ) : (
                      <Download className="cursor-pointer mx-5" />
                    )
                  }
                </PDFDownloadLink>
                <EditSvgComponent
                  className="cursor-pointer"
                  onClick={() => onEditClick(data.tribute_info)}
                />
              </div>
            </p>
          </>
        </div>
      </div>
      {/* <MyModal
        className={"update-tribute-information-model"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <UpdateTributeInformation handleClose={() => setOpen(false)} />
      </MyModal> */}
    </div>
  );
};
