import React from 'react';
import {Content,LeftSideWrapper, LogoImage, SchoolImage } from './Theme';
import { useSelector } from "react-redux";
import schoolImg from 'assets/images/SchoolImag.png';

export const LeftSide = () => {
    const Logo = localStorage.getItem('logoImgSchool');
    const { classDetails } = useSelector((state) => state?.Class)

    return (
                    <LeftSideWrapper>
                        <LogoImage src={Logo} alt='Logo' />
                          <SchoolImage src={classDetails?.background ||schoolImg} alt='notFound' /> 
                        <Content haveDisc={classDetails?.description}>
                            {/* <Title>Join Our Event</Title> */}
                            {/* <Description>
                            {eventData?.description?.replace(/&lt;/g, '<') !== '<p></p>\n' && (
                            <div className="drawer_description">
                                <p
                                dangerouslySetInnerHTML={{
                                    __html: eventData?.description?.replace(/&lt;/g, '<'),
                                }}
                                ></p>
                            </div>
                            )}
                            </Description> */}
                        </Content>
                    </LeftSideWrapper>
    );
};

