import { default as axiosDefault } from "axios";
import { withVersioning, VersioningStrategy } from "axios-api-versioning";

import {
  AuthRequestInterceptor,
  ErrorResponseInterceptor,
} from "./interceptors";

import { BACKEND_API_ENDPOINT } from "config";

if (process.env.NODE_ENV === "development" && !BACKEND_API_ENDPOINT) {
  console.log(
    "BACKEND_API_ENDPOINT config value not found. Please check your environment variables."
  );
}

const baseClient = axiosDefault.create({
  baseURL: `${BACKEND_API_ENDPOINT}v{apiVersion}`,
  withCredentials: false,
});

export const clientAction = withVersioning(baseClient, {
  apiVersion: "1",
  versioningStrategy: VersioningStrategy.UrlPath,
});

clientAction.CancelToken = axiosDefault.CancelToken;
clientAction.isCancel = axiosDefault.isCancel;

clientAction.interceptors.request.use(...AuthRequestInterceptor());
clientAction.interceptors.response.use(...ErrorResponseInterceptor());

export const axios = async ({ url, method, params = {}, ...rest }) => {
  const config = {
    url,
    method,
  };

  if (method === "POST" || method === "PATCH") {
    config.data = params;
  }

  return await clientAction({
    ...config,
    ...rest,
  });
};
