import { ellipsis } from "polished";
import styled, { css } from "styled-components";

const breakpoints = {
  small: "576px",
  medium: "767px",
  large: "992px",
  xlarge: "1200px",
};


const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});


export const Body = styled.div`
  width: 100%;
  background-color: #ffff;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  ${media.medium`
  width: 100%;
  padding-left: 10px;
  `}
`;

export const ImageContainber = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SchoolImage = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
  border-top-right-radius: 5%;
  border-bottom-right-radius: 5%;
`;
export const DefaultImg = styled.div`
  background-color: #2555ef;
  width: 100%;
  height: 100%;
  border-top-right-radius: 5%;
  border-bottom-right-radius: 13%;
  svg {
    padding-left: 20%;
    margin-left: 4%;
    fill: #2555ef;
    filter: drop-shadow(7px 4px 78px rgba(44, 52, 104, 0.16));
    border-bottom-right-radius: 5%;
  }
`;
export const LogoImage = styled.img`
  position: absolute;
  top: 32px;
  left: 53px;
  z-index: 5;
  width: 10%;
  background-color: #ffff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);

`;
export const Content = styled.div`
  position: absolute;
  top: 75%;
  left: 4%;
  width: fit-content;
  line-break: auto;
`;
export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
`;

export const AboutUs = styled.button`
  color: #2555ef;
  border-radius: 5px;
  background: var(--white, #fff);
  border: none;
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
`;

// First Page Right Side
export const RightSideWrappr = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 1920px) and (max-height: 1080px) {
    margin: 31px 40px;
    margin-left: 61px;
    width: 40%;
  }
  ${media.medium`
    margin: 31px 40px;
    margin-left: 50px;
    width: 100%;
    padding-right: 10px;
  `}
  width: 45%;
  margin-top: 77px;
`;
export const RightSideContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 50px;
  height: 100%;
  ${media.medium`
    padding-right: 10px;
  `}
`;
export const LeftSideWrapper = styled.div`
  flex: 1;
  width: 60%;
  margin-right: 30px;
  ${media.medium`
    width: 100%;
    margin-right: -30px;
  `}
`;

export const Spinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const EventType = styled.div`
  width: 100%;
  height: 123px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(37, 85, 239, 0.04);
  padding-top: 23px;
  padding-bottom: 23px;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 16px;
`;
export const EventTypeName = styled.div`
  width: 166px;
  height: 77px;
  flex-shrink: 0;
  padding-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--blue, #2555ef);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 123.077% */
  text-transform: capitalize;
  border-right: 1px solid rgba(0, 46, 92, 0.05);
  span {
    color: #002e5c;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
`;
export const EventTypeDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 40px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;

  div {
    flex-direction: row;
  }
  span {
    color: #002e5c;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    opacity: 0.7;
  }
`;

export const FormTitle = styled.div`
  text-align: center;
`;
export const FormName = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #002e5c;
`;

export const FormCaption = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 40px; */
  text-transform: capitalize;
  color: #002e5c;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-size: 12px;
    font-weight: bold;
    height: 24px;
    background: rgba(250, 51, 114, 0.2);
    border-radius: 5px;
    margin-left: 8px;
    color: #fa3372;
    ${media.medium`
    height: auto;
  `}
    
  }
`;
export const Description = styled.div`
  flex: 1;
  span {
    color: #002e5c;
    opacity: 0.7;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
  p {
    color: var(--dark-blue, #002e5c);
    font-size: 16px;
    font-weight: 400;
    line-height:normal;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
  }
`;
export const DescriptionText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  margin-top: 13px;
  ${({ showMore }) => showMore && ellipsis(undefined, 3)}
`;

export const ShowMoreText = styled.div`
  cursor: pointer;
  font-size: 14px;
  /* margin-top: 20px; */
  margin-bottom: 30px;
  font-weight: bold;
`;
export const ContactTitle = styled.div`
  font-size: 12px;
  opacity: 0.7;
  color: #002e5c;
  padding-bottom: 5px;
`;
export const ContactInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;

  span {
    color: #002e5c;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    opacity: 0.7;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100px;
`;
export const DashLine = styled.hr`
  border-top: dashed 2px #002e5c;
  opacity: 0.2;
  width: 100%;
`;
export const PayDetails = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  color: #002e5c;

  li {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  span {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    span {
      color: var(--dark-blue, #002e5c);
      text-align: right;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
  }
`;
export const TotalPay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  span {
    color: var(--dark-blue, #002e5c);
    text-align: right;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
`;
export const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;
export const CounterDetails = styled.div`
  

`
export const ReserveAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  padding-top: 10px;
  gap: 15px;
  ${media.medium`
      margin-right: 10px;
  `}

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
`;

export const FormLogin = styled.div`
 display: flex;
 justify-content: flex-end;
`;

export const ReserveLoginButton = styled.button`
  background: transparent !important;
  color: #2555EF;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
`;
export const ReserveButton = styled.button`
  display: flex;
  height: 48px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background:${(props) => (props.disabledButton ? '#F0F0F3' : '#2555EF')};
  color: ${(props) => (props.disabledButton ? '#B5B5C3' : 'var(--White-100, #fff)')};
  cursor: ${(props) => (props.disabledButton ? 'not-allowed !important' : 'pointer')};
  font-feature-settings: "clig" off, "liga" off;
  /* Small Medium 13 */
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 246.154% */
  text-transform: capitalize;
  border: 0;
  /* margin-top: 5%; */
  ${media.medium`
  height: 42px;

  `
}

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
`;

export const Note = styled.div`
      opacity: 0.7;
    color: #002e5c;

`
export const ContactInfoDetails =styled.div`
     display: flex;
    align-items: baseline;
    flex-direction: row;
    width: 71%;
    ${media.medium`
    flex-direction:column ;
    `}


`