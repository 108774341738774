import { useAuth0 } from "@auth0/auth0-react";
import { setLocalizationMoment } from "helper/dateFormat";
import AuthService from "Lib/API/Auth";
import EvevntService from "Lib/API/Events";
import moment from 'moment';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { eventsSlice } from "services/api/events";
import { EventInfo } from "./EventComponents/EventInfo/EventInfo";
import { Spinner } from "./Theme";

export const Event = () => {
  const { getAccessTokenSilently,isAuthenticated, isLoading: isAuthenticatedLoading } = useAuth0();

  const params = useParams();
  const [isLoading, setIsLoding] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const token = localStorage.getItem('token');

  const getEventById = async () => {
    const subDomain = window.location.hostname.split(".")[0];
    const hostName = subDomain === "localhost" ? "shalom" : subDomain;
    setIsLoding(true);
    try {
      const auth = await AuthService.generateToken(hostName);
      const token = isAuthenticated ? await getAccessTokenSilently() : auth?.token
      localStorage.setItem("eventToken", auth?.token);
      localStorage.setItem("logoImgEvent", auth?.logoImg);
      localStorage.setItem("eventOrgId", auth?._id);
      localStorage.setItem("email", auth?.contactInfo?.email);
      localStorage.setItem("phone", auth?.contactInfo?.phone);
      localStorage.setItem('orgName', auth?.orgName);
      localStorage.setItem('country', JSON.stringify(auth?.country || {}));
      localStorage.setItem('shul_website', auth?.shul_website || '');
      localStorage.setItem('memberPortal', auth?.memberPortal || false);


      setLocalizationMoment(auth?.country?.local || "en");
      document.title = `${auth?.orgName}`;
      localStorage.setItem("paymentMethodEvents",
        JSON.stringify(auth?.paymentMethod)
      );
      
      const organization = {
        provider_information: {
            provider: auth?.provider,
            keys: {
            ...(auth?.publicKey ? {publicKey: auth?.publicKey} : {}) 
            }
        }
      }
      localStorage.setItem("organization",JSON.stringify(organization));

      await EvevntService.getEventById(token, params?.id).then((res) => {
        dispatch(eventsSlice.actions.setEventData(res?.result));
        if(res?.result?.eventPricing?.prices.length > 0){
          // Filter data to save products that have an optinal type 
          const products = res?.result?.eventPricing?.prices?.filter((item)=>{
            if(item?.type === "optional"){
                return item;
            }
         })
         const varients = []
         let varientCapacity = 0
        // Add New Fiels to variantInfo to manage products counter 
        const productRest = products?.map((val)=>{
            if(val?.variant){
            const variantInfodata = val?.variantInfo && Object.keys(val?.variantInfo).map(key => ({
                [key]: {...val.variantInfo[key],counterValue:0,selectedValue:''},
               
            }));
            const variantsInfo= val?.variantInfo && Object.keys(val?.variantInfo).map(key => {
              varientCapacity += val.variantInfo[key]?.quantity;
               return {option:key,counterValue:0,selectedValue:'',...val.variantInfo[key]}
            })
              varients.push({variantsInfo:variantsInfo,varientCapacity:isNaN(varientCapacity) ? 'noLimit' :
              varientCapacity,numberOfDrops:0,varientTitle:val.title,id:val._id})
              varientCapacity = 0
              return {...val,variantInfo:variantsInfo,counter:0,dropdowns:[]}
            }else{
              varients.push({numberOfDrops:0,quantity:0 ,
              varientCapacity:!val.infiniteQuantity? val.quantity:'noLimit',counterValue:0,variantsInfo:[]
              ,varientTitle:val.title,id:val._id,amount:val.amount})
              return {...val,counter:0,dropdowns:[]}
            }
        })
        localStorage.setItem("originVarients",JSON.stringify({...varients}))
        dispatch(eventsSlice.actions.setVarients({...varients}));
        dispatch(eventsSlice.actions.resetAction([]));
        dispatch(eventsSlice.actions.setProducts(productRest));
        }
        const startDateDiff = moment().diff(moment(res?.result?.eventStartDate), 'days');
        const endDateDiff = moment().diff(res?.result?.eventEndDate ? moment(res?.result?.eventEndDate) : moment(), 'days');
     
        if (!res?.result?.allowNonMembers && !token) {
          enqueueSnackbar('This event is available exclusively for members', {
              variant: 'error',
          });
      }
      else if (endDateDiff > 0) {
          enqueueSnackbar('This event is no longer available due to its expiration date', {
              variant: 'error',
          });
      }
      else if (startDateDiff < 0) {
          enqueueSnackbar(`This event has not yet begun. Please check back closer to the start date for access`, {
              variant: 'error',
          });
      }
        setIsLoding(false);
      });
    } catch (error) {
      console.log("errorerror", error);
      setIsLoding(false);
      throw error;
    }
  };
  useEffect(() => {
    localStorage.removeItem('redirectUrl');
    dispatch(eventsSlice.actions.setProducts([]));
  }, []);
  
  useEffect(() => {
    if(isAuthenticatedLoading) return;
    getEventById();
  }, [isAuthenticatedLoading, isAuthenticated]);
  return (
    <>
      {isLoading ? (
        <Spinner>
          <PulseLoader color={"#2555EF"} size={"30px"} />
        </Spinner>
      ) : (
        <EventInfo />
      )}
    </>
  );
};
