import styled from "styled-components";


export const PersonalDetailsWrapper = styled.div`
padding:28px 33px 41px 33px;
height: 100%;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
`
export const PersonalDetailsTitle = styled.span`
color: var(--Dark-Blue-100, #002E5C);
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px;
padding-bottom: 8px;
display: flex;
justify-content: flex-start;
align-items: center;
border-bottom: 1px dashed rgba(0, 46, 92, 0.10);
width: 100%;
margin-bottom: 24px;
span{
padding-right: 8px;
}
`
export const GridItem = styled.div`

`