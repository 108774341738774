import Classes from "pages/Classes";
import { Directory } from "pages/Directory/Directory";
import Donation from "pages/Donation";
import Events from "pages/Events";
import { EventLink } from "pages/ExternalLinkEvents";
import { Event } from "pages/ExternalLinkEvents/Event";
import { ReservationEventPage } from "pages/ExternalLinkEvents/EventComponents/ReservationEventPage/ReservationEventPage";
import { FormLink } from "pages/ExternalLinkForms";
import { Form } from "pages/ExternalLinkForms/Form";
import { ReservationFormPage } from "pages/ExternalLinkForms/FormComponents/ReservationFormPage/ReservationFormPage";
import { SchoolLink } from "pages/ExternalLinkSchool";
import FamilyMembersTab from "pages/FamilyMembers";
import Help from "pages/Help";
import JoinSchool from "pages/MemberLink/JoinSchool/JoinSchool";
import MemberLink from "pages/MemberLink/MemberLink";
import SettingsPage from "pages/Settings";
import AddYahrzeit from "pages/Yahrzeit/NewYahrzeit";
import Yahrzeit from "pages/Yahrzeit/index";
import { InitialMemberPage } from "pages/auth";
import { CheckValidUser } from "pages/auth/LogoutWrapper";
import Billing from "pages/billing";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ExternalLinkDonation from "../pages/ExternalLinkDonation";
import AddNewMember from "../pages/home/AddNewMember";
const routes = [
  {
    path: "/",
    name: "Setup",
    exact: true,
    page: <InitialMemberPage />,
  },
  {
    path: "/check-valid-user",
    name: "CheckValidUser",
    page: <CheckValidUser />,
  },
  {
    path: "/settings",
    name: "SettingsPage",
    page: <SettingsPage />,
  },
  {
    path: "/home",
    name: "Home",
    page: <FamilyMembersTab />,
  },
  {
    path: "/family-member-table",
    name: "FamilyMembersTab",
    page: <FamilyMembersTab />,
  },
  {
    path: "/yahrzeit",
    name: "Yahrzeit",
    page: <Yahrzeit />,
  },
  {
    path: "/add-yahrzeit",
    name: "AddYahrzeit",
    page: <AddYahrzeit />,
  },
  {
    path: "/finance",
    name: "Finance",
    page: <Billing />,
  },
  {
    path: "/events-view",
    name: "Events",
    page: <Events />,
  },
  {
    path: "/donationPage",
    name: "Donation",
    page: <Donation />,
  },
  {
    path: "/directory",
    name: "Directory",
    page: <Directory />,
  },
  // {
  //   path: "/schools-part",
  //   name: "school",
  //   page: <Schools />,
  // },
  {
    path: "/classes",
    name: "classes",
    page: <Classes />,
  },
  {
    path: "/add-new-member",
    name: "AddNewMember",
    page: <AddNewMember />,
  },
  {
    path: "/help",
    name: "Help",
    page: <Help />,
  },
  {
    path: "/donation-view",
    name: "ExternalLinkDonation",
    page: <ExternalLinkDonation external={false}/>,
  },

  {
    path: "/school",
    name: "ListOfClasses",
    page: <SchoolLink />,
  },
  {
    path: "/school/:id",
    name: "JoinSchool",
    page: <JoinSchool />,
  },
  {
    path: "/school/:id/register",
    name: "MemberLink",
    page: <MemberLink />,
  },
  {
    path: "/events",
    name: "events",
    page: <EventLink />,
  },
  {
    path: "/events/:id",
    name: "events",
    page: <Event />,
  },
  {
    path: "/events/:id/reservation",
    name: "ReservationEventPage",
    page: <ReservationEventPage />,
  },
  {
    path: "/form",
    name: "forms",
    page: <FormLink />,
  },
  {
    path: "/form/:id",
    name: "forms",
    page: <Form />,
  },
  {
    path: "/form/:id/reservation",
    name: "ReservationFormPage",
    page: <ReservationFormPage />,
  },
  {
    path: "404",
    page: () => null,
  },
];

export const HomeRouters = () => {
  console.log("HomeRouters");
  return (
    <>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={route.page}
          />
        ))}
        <Redirect path="*" to="/" />
      </Switch>
    </>
  );
};
