import { LabelField, MyCheckbox, Text } from "components";
import React from "react";
import MyButton from "components/MyButton";
import { TextField } from "@material-ui/core";

const AddittionalInformation = ({
  drawerDisplay,
  errors,
  values,
  handleChange,
  onSubmit,
  ...formik
}) => {
  const [prevFormikValues] = React.useState(values);
  return (
    <div
      className={
        drawerDisplay
          ? "additional-information p-8"
          : "additional-information pt-12"
      }
    >
      {!drawerDisplay && (
        <div className="title">
          <Text className="sub-title">Additional information</Text>
          <p className="yah-note">Please complete the details below</p>
        </div>
      )}
      <div className={drawerDisplay ? "" : "my-10"}>
        <div className={drawerDisplay ? "mb-5" : "my-5"}>
          <LabelField title={"About the deceased"} color="black" />
          <TextField
            multiline
            rows={drawerDisplay ? 4 : 2}
            onClick={(e) => {
              formik.setFieldTouched("about_deceased", true);
            }}
            name="about_deceased"
            placeholder="Type here"
            value={values.about_deceased}
            onChange={(e) => {
              handleChange(e);
            }}
            error={!!errors.about_deceased}
            helperText={errors.about_deceased}
            fullWidth
          />
        </div>
        <div className="my-5">
          <LabelField title={"Cemetery"} color="black" />
          <TextField
            multiline
            rows={drawerDisplay ? 4 : 2}
            name="cemetery"
            onClick={() => formik.setFieldTouched("cemetery", true)}
            value={values.cemetery}
            placeholder="Type here"
            onChange={handleChange}
            error={!!errors.cemetery}
            helperText={errors.cemetery}
            fullWidth
          />
        </div>
        <div className="my-5">
          <LabelField title={"Plaque numbers"} color="black" />
          <TextField
            multiline
            onClick={() => formik.setFieldTouched("plaques_number", true)}
            rows={drawerDisplay ? 4 : 2}
            name="plaques_number"
            value={values.plaques_number}
            placeholder="Type here"
            onChange={handleChange}
            error={!!errors.plaques_number}
            helperText={errors.plaques_number}
            fullWidth
          />
        </div>
      </div>

      {drawerDisplay && (
        <div className="mt-4 btn-flex">
          <MyButton
            type={"primary"}
            content={"Save"}
            onClick={onSubmit}
            disabled={
              values.about_deceased === prevFormikValues.about_deceased &&
              values.cemetery === prevFormikValues.cemetery &&
              values.plaques_number === prevFormikValues.plaques_number
            }
          />
        </div>
      )}
    </div>
  );
};

export default AddittionalInformation;
