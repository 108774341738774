/* eslint-disable no-unused-expressions */
import { useAuth0 } from "@auth0/auth0-react";
import PowerbyPNG from "assets/icons/power-by.png";
import CheckSvg from "assets/icons/svg/CheckSvg";
import RightArrowSvg from "assets/icons/svg/myRightSvg";
import { IconButton, Text } from "components";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AuthService from "../../Lib/API/Auth";
import { FirstTime } from "./FirstTime";
import { FirstTime_2 } from "./FirstTime_2";
import { FirstTime_3 } from "./FirstTime_3";
import { FirstTime_4 } from "./FirstTime_4";
// import { AlertMessage } from "../../components/Alert/index";
// import { organisationState } from "./authSlice";
// import { CheckValidUser } from "./LogoutWrapper";
import LogOutSvg from "assets/icons/svg/logout";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { setLogoImage } from "services/api/organization";
const styles = {
  body: { background: "#F4F6F9 0% 0% no-repeat padding-box" },
  imgBody: {
    height: 66,
    width: 227,
  },
};

const StepBox = ({ step, totalSteps, currentStep }) => {
  const colored =
    step <= currentStep
      ? { color: "#2555EF", background: "#99b7ef 0% 0% no-repeat padding-box" }
      : {};
  const setColor =
    step === currentStep
      ? { color: "#2555EF" }
      : step < currentStep
        ? { color: "#B5B5C3" }
        : {};
  return (
    <div
      style={totalSteps === step ? { flex: "0 0 auto" } : {}}
      className="step-box-container"
    >
      <div className="step-block">
        <div style={colored} className="step-box">
          {step < currentStep ? (
            <div className="step-box-content">
              <CheckSvg color={colored.color} />
            </div>
          ) : (
            <p>{step}</p>
          )}
        </div>

        <div className="step-box-text">
          <p style={setColor}>Step {step}</p>
        </div>
      </div>

      {totalSteps === step ? (
        ""
      ) : (
        <div className="arrow-div">
          {/* <span className="arrow arrow-bar is-right"></span> */}
          <RightArrowSvg color={step === currentStep ? "#2555EF" : "#B5B5C3"} />
        </div>
      )}
    </div>
  );
};
const Header = ({ stepsArray, currentStep }) => {
  return (
    <div className="header">
      {stepsArray.map((step, i) => (
        <StepBox
          key={i}
          step={step.stepNumber}
          totalSteps={stepsArray.length}
          currentStep={currentStep}
        />
      ))}
    </div>
  );
};
export const StepsContainer = ({
  stepsArray,
  currentStep,
  setCurrentStepCheck,
}) => {
  const Step = stepsArray[currentStep - 1].component;
  const statePackage = stepsArray[currentStep - 1].package;
  return (
    <div className="steps-container">
      <div
        className="text-center my-auto"
        style={{ minHeight: 40, paddingTop: 18 }}
      >
        <Text style={{ fontSize: 22, fontWeight: "600", color: "#2555EF" }}>
          First time setup
        </Text>
      </div>
      <div className="step-container-wrap">
        <Step
          setCurrentStepCheck={setCurrentStepCheck}
          currentStep={currentStep}
          package={statePackage}
        />
      </div>
    </div>
  );
};
export const InitialMemberPage = (props) => {
  const { logout } = useAuth0();
  const dispatch = useDispatch()
  const [origin] = useState(
    JSON.parse(localStorage.getItem("getOrginasitionBySubDomain"))
  );
  const originUrl = window.location.origin;
  const [checkMember, setMember] = useState();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  

  // localStorage.setItem("subDomain", "shalom.eztmpl.dev".split(".")[0]);
  localStorage.setItem("subDomain", window.location.host.split(".")[0]);
  const [loading, setLoading] = useState(false);
  // const organisationKey = localStorage.getItem("subDomain");

  useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);
      const token = await getAccessTokenSilently();
      AuthService.getUserExist(token)
        .then((res) => {
          setMember(res);
          localStorage.setItem("paymentMethod", JSON.stringify(res?.user?.paymentMethod));
          localStorage.setItem("checkUser", JSON.stringify(res?.exists));
          localStorage.setItem("accountName", res?.user?.permissions?.linked_account?.family_name);
          localStorage.setItem("accountInfo", JSON.stringify({
            firstName: res?.user?.permissions?.linked_contact?.first_name,
            lastName: res?.user?.permissions?.linked_contact?.last_name,
            primary_email_address: res?.user?.permissions?.linked_account?.primary_email_address,
          }));
          localStorage.setItem(
            "organization",
            JSON.stringify(res?.user?.permissions?.organization)
          );
          localStorage.setItem(
            "linkedAccount",
            JSON.stringify(res?.user?.permissions?.linked_account)
          );
          localStorage.setItem("hasPayment", res?.user?.hasPayment);
          localStorage.setItem("logoImg", res?.user?.permissions?.organization?.logoImg);
          res?.user?.permissions?.organization?.logoImg && dispatch(setLogoImage(res?.user?.permissions?.organization?.logoImg))
          localStorage.setItem('orgName', res?.user?.permissions?.organization?.orgName);
          document.title = `${res?.user?.permissions?.organization?.orgName}`;
          setLoading(false);
        })
        .catch((err) => {
          // setMember(err.data.exists)
          localStorage.setItem("checkUser", err?.data?.exists);
          setLoading(false);
          toastr.error('Error', err?.data?.message);
        });
    }
    fetchMyAPI();
  }, [getAccessTokenSilently, history, checkMember]);
  const [currentStep, setCurrentStep] = useState(1);
  const userId = checkMember?.user?.permissions?.linked_contact;
  // const accountID = checkMember?.user?.permissions?.linked_account;
  const accountID = checkMember?.user?.permissions?.linked_account?._id;
  localStorage.setItem("userId", userId);
  localStorage.setItem("accountID", accountID);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullHebrewName, setFullHebrewName] = useState("");
  const [preferredTitle, setPreferredTitle] = useState("");
  // const redirectToDonation = () => {
  //   history.push(localStorage.getItem("redirectUrl"));
  // }



  const FistPageDisplay =
    Boolean(checkMember?.user?.permissions?.permissionType) &&
      checkMember?.user?.permissions?.permissionType !== "member"
      ? history.push("/check-valid-user")
      // : localStorage.getItem("redirectUrl")
      // ? redirectToDonation()
      : checkMember?.exists
        ? history.push("/family-member-table")
        : FirstTime

  const stepsArray = [
    {
      stepNumber: 1,
      component: FistPageDisplay,
      package: {
        firstName,
        setFirstName,
        middleName,
        setMiddleName,
        lastName,
        setLastName,
        fullHebrewName,
        setFullHebrewName,
        preferredTitle,
        setPreferredTitle,
      },
    },
    { stepNumber: 2, component: FirstTime_2 },
    { stepNumber: 3, component: FirstTime_3 },
    { stepNumber: 4, component: FirstTime_4 },
  ];
  const setCurrentStepCheck = ({ type }) => {
    if (currentStep < stepsArray.length && type === "next") {
      setCurrentStep(currentStep + 1);
    } else if (currentStep !== 1 && type === "back") {
      setCurrentStep(currentStep - 1);
    }
  };
  console.log(loading, "loading");
  if (loading) return <div>Loading...</div>;
  return (
    <div className="first-time">
      <div className="d-flex headerContainer">
        <div className="headerImage">
          <Image
            src={origin?.logoImg !== undefined ? origin?.logoImg : ""}
            style={{ ...styles.imgBody }}
          />
        </div>
        <div className="headerLogout">
          <IconButton onClick={() => logout({ returnTo: originUrl })}>
            <LogOutSvg />
            <Text
              style={{
                ...styles.textIconButton,
              }}
            >
              Logout
            </Text>
            {/* <ArrowRightSVG className="mx-2" /> */}
          </IconButton>
        </div>
      </div>
      <div className="col-sm-7 mx-auto">
        <Header stepsArray={stepsArray} currentStep={currentStep} />
        <StepsContainer
          stepsArray={stepsArray}
          currentStep={currentStep}
          setCurrentStepCheck={setCurrentStepCheck}
        />
        <div class="col-12 mt-2 text-center">
          <h3>
            <span class="mr-1 powered-text"> Powered by </span>
            <span>
              <a href="">
                <img src="https://easyshul.com/assets/images/logos/Logo.svg" className="powered-logo" alt="powered-logo" />
              </a>
            </span>
          </h3>
        </div>
        {/* <div className="row m-0 p-0 mb-3">
          <img
            alt=""
            className="col-sm-5 col-lg-3 mx-auto"
            src={PowerbyPNG}
            style={{ objectFit: "contain", marginTop: 17 }}
          />
        </div> */}
      </div>
    </div>
  );
};

