import MemberService from 'Lib/API/Member';
import { getLocalStorageRegistration, setLocalStorageRegistration } from '_metronic/_helpers';
import { convertUTCToLocalDate } from 'components/UTC/UtcConverter';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Class } from 'services/api/class';
import thousands from "thousands";
import { Boxes, ClassBody, ClassButton, ClassCaption, ClassCaptionDetails, ClassDescription, ClassInformation, ClassTitle, Details, InfinitySymbol, Note, Title, TotalPay } from './Theme';

const ClassInfo = ({ ...props }) => {
    const { classDetails } = useSelector((state) => state?.Class)
    const dispatch = useDispatch()
    const [age, setAgeRange] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const token = localStorage.getItem('token');

    const paymentMethod = JSON.parse(localStorage.getItem('paymentMethod')) || {};

    const isExpiredClass = () => {
        const startDateDiff = moment().diff(moment(classDetails?.class_start_date), 'days');
        const endDateDiff = moment().diff(classDetails?.class_end_date ? moment(classDetails?.class_end_date) : moment(), 'days');

        return startDateDiff < 0 || endDateDiff > 0
    }
    const handleDifferanceDate = () => {
        const date1 = new Date();
        const date2 = new Date(classDetails?.class_end_date);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays === 1 ? <span>{diffDays} Day  left!</span>
            : <span>{diffDays} Days  left!</span>
    }
    const getFormById = async () => {
        try {
            setLoading(true)
            const res = await MemberService.getFormById(localStorage.getItem('externalToken'),
                getLocalStorageRegistration()?.registerFormId, localStorage.getItem('orgId'))
            dispatch(Class.actions.setRegistartionDetails(res?.task_data));
            setLocalStorageRegistration({
                activeStep: 0,
                registerFormId: getLocalStorageRegistration()?.registerFormId,
                RegisterData: res?.task_data,
                RegisterRequiredFelids: res?.requiredFelids,
                classDetails: getLocalStorageRegistration()?.classDetails

            });
            history.push(`/school/${params?.id}/registration`);
            setLoading(false);

        } catch (error) {
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
            dispatch(Class.actions.setRegistartionDetails({}));
            setLoading(false);

        } finally {
            setLoading(false);
        }
    };
    const handleAboutUs = () => {
        getFormById()
    }
    const calculateDiscount = () => {
        let discountTotal = classDetails?.class_pricing?.total_to_pay
        const discount = classDetails.class_discount?.map((item) => {
            if (item?.discount_type === 'date') {
                const endDate = moment(item?.end_date).format("MM/DD/YYYY")
                const startDate = moment(item?.start_date).format("MM/DD/YYYY")
                const currentDate = moment(new Date()).format("MM/DD/YYYY")
                if (startDate <= currentDate && endDate >= currentDate) {
                    if (item?.price_type === "fixed") {
                        discountTotal = discountTotal - item?.value
                    } else {
                        const discountAmount = (discountTotal * item?.value) / 100;
                        discountTotal = discountTotal - discountAmount;
                    }
                } else {
                    console.log("The discount date is expired ");
                }
            } else if (item?.discount_type === 'amount') {
                if (item?.price_type === "fixed") {
                    discountTotal = discountTotal - item?.value
                } else {
                    const discountAmount = (discountTotal * item?.value) / 100;
                    discountTotal = discountTotal - discountAmount;
                }

            } else if (item?.discount_type === 'quantity') {
                if (item?.quantity && item?.quantity > 4) {
                    if (item?.price_type === "fixed") {
                        discountTotal = discountTotal - item?.value
                    } else {
                        const discountAmount = (discountTotal * item?.value) / 100;
                        discountTotal = discountTotal - discountAmount;
                    }
                }
            }
        })

        const totalAfterDiscount = discountTotal > 0 ? thousands((+discountTotal).toFixed(2)) : "0.00";
        return totalAfterDiscount
    }

    const replaceDescription = classDetails.description?.replace(/&lt;/g, '<')
    useEffect(() => {
        calculateDiscount()
        setAgeRange([{ value: classDetails.class_details?.maximum_participants, desc: 'Max participants', infinity: classDetails?.class_details?.infiniteParticipants }, {
            value: `${classDetails.class_details?.ageStart}${classDetails.class_details?.ageEnd ? "-" + classDetails.class_details?.ageEnd : ''}`, desc: 'Max Age Range'
        }, { value: classDetails.class_details?.gender, desc: 'Sex' }])


    }, [classDetails])

    const calculateTotalPayment = () => {
        const totalPayment = calculateDiscount().replace(/,/g, '')
        if (paymentMethod?.coverConvenienceFee && totalPayment > 0) {
            const convenienceFeeAmount = paymentMethod?.convenienceFeeInfo?.type === "%"
                ? (totalPayment * paymentMethod?.convenienceFeeInfo?.amount) / 100 :
                paymentMethod?.convenienceFeeInfo?.amount
            return thousands((+totalPayment + +convenienceFeeAmount).toFixed(2))
        } else {
            return thousands((+totalPayment).toFixed(2))
        }
    }
    const convenienceFeeAmount = paymentMethod?.convenienceFeeInfo?.type === "%"
        ? (calculateDiscount().replace(/,/g, '') * paymentMethod?.convenienceFeeInfo?.amount) / 100 :
        paymentMethod?.convenienceFeeInfo?.amount
    const displayRegistrationStartDate = () => {
        const showRegistrationStartDate = classDetails?.always_available && moment(classDetails?.class_start_date).isAfter(moment(), 'day');
        return showRegistrationStartDate ? `Registration starts on ${moment(classDetails?.class_start_date).local().format('MM/DD/YYYY')} ` : '';
    }

    return (
        <ClassBody steps={props?.steps}>
            <>
                {props?.steps ?
                    <>
                        <ClassInformation>Class Information</ClassInformation>
                        <ClassCaptionDetails>

                            <div>Class Name<span>{classDetails?.class_name}  |  {classDetails?.year}</span></div>
                            <div>Registration Starts-ends<br /><span>{classDetails?.always_available ? 'always' :
                                `${moment(convertUTCToLocalDate(classDetails?.class_start_date)).format("MM/DD/YYYY")} - ${moment(convertUTCToLocalDate(classDetails?.class_end_date)).format("MM/DD/YYYY")}`}</span></div>
                        </ClassCaptionDetails>
                    </>
                    :
                    <Title>
                        <ClassTitle>{classDetails?.class_name} | {classDetails?.year}</ClassTitle>
                        <ClassCaption>
                            {displayRegistrationStartDate()}
                            {!classDetails?.always_available && <span>Registration ends  {moment(convertUTCToLocalDate(classDetails?.class_end_date)).format("MM/DD/YYYY")}</span>}

                            {isExpiredClass() ? '' : !classDetails?.always_available && <div>{handleDifferanceDate()} </div>}

                        </ClassCaption>
                    </Title>
                }

            </>
            <hr />

            <Boxes>

                {age.map((item, index) => (
                    <div key={index}>
                        {item.infinity ?
                            <InfinitySymbol>&#8734;</InfinitySymbol>
                            : item.value}
                        <span>{item.desc}</span>
                    </div>

                ))}

            </Boxes>
            <ClassDescription>
                <span>Description</span>
                <p
                    dangerouslySetInnerHTML={{ __html: replaceDescription }}
                />

            </ClassDescription>
            <hr style={{ borderTop: "1px dashed #002E5C", opacity: '0.10000000149011612' }} />
            {calculateTotalPayment() !== '0.00' &&
                <Details>
                    {classDetails?.class_pricing?.prices?.map((item, index) => item?.type === "onRegistration" ? (
                        <>
                            <li key={index}>{item?.title}<span>${item.amount > 0 ? thousands((+item.amount).toFixed(2)) : "0.00"}</span></li>
                            {item?.note && <Note>{item?.note}</Note>}
                            <hr />
                        </>
                    ) : <>
                        <li key={index}>{item?.title}{item?.type === 'onApprove' && ` (On Approve)`}<span>${item.amount > 0 ? thousands((+item.amount).toFixed(2)) : "0.00"}</span></li>
                        {item?.note && <Note>{item?.note}</Note>}
                        <hr />
                    </>


                    )}
                    {classDetails.class_discount?.map((item, index) => (
                        <>
                            <li key={index}>{item?.discount_name} |
                                {`${moment(convertUTCToLocalDate(item?.start_date)).format("MM/DD/YYYY")}-${moment(convertUTCToLocalDate(item?.end_date)).format("MM/DD/YYYY")}`}<span>
                                    {item?.price_type === 'percentage' ? `${item?.value}%` : `$${item?.value > 0 ? thousands((+item?.value).toFixed(2)) : "0.00"}`}
                                </span></li>
                            <hr />
                        </>

                    ))}
                    {paymentMethod?.coverConvenienceFee && calculateDiscount() > 0 && (
                        <>
                            <li>Convenience fee
                                <span>

                                    ${thousands((+convenienceFeeAmount).toFixed(2))}
                                </span>
                            </li>
                            <hr />
                        </>
                    )}
                    {props?.steps && <><TotalPay>TOTAL PAYMENT<span>${calculateTotalPayment()}</span></TotalPay><hr /></>}
                    {!props?.steps && <><span>Total Cost <span>${calculateTotalPayment()}</span></span></>}
                </Details>
            }

            {
                !props?.steps &&
                <>
                    {/* <Button> */}
                    <ClassButton onClick={handleAboutUs} disabled={isLoading || (!classDetails?.allow_non_members_register && !token) || isExpiredClass()} isAvailableClass={(!classDetails?.allow_non_members_register && !token) || isExpiredClass()}>
                        {isLoading ? <PulseLoader color={'#fff'} size={'7px'} /> : 'Start Registration'}
                    </ClassButton>
                    {/* </Button> */}
                    {/* <AlreadyMember>Already a member? <span>Log In</span></AlreadyMember> */}
                </>
            }
        </ClassBody >
    );
};

export default ClassInfo;