import styled from "styled-components";


export const Wrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
background: var(--White-100, #FFF);
padding: 20px;
padding-left: 58px;
width: 100%;
header{
    width: 100%;
    img{
        height: 50px;
        width: 130px;
        object-fit: fill;
    }
}
`

export const RightSide = styled.div`
display: flex;
`

export const LoginLink = styled.div`
    cursor: pointer;
    color: #2555EF;
    font-weight: bold;
`
