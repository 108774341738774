import React from 'react';
import { TabItem, Tabs } from './Theme';

const MenuTabs = ({
  activeTab,
  onTabsChange,
  tabs,
  tabWidth
}) => {
  return (
    <Tabs>
      {tabs.map((tab) => (
        <TabItem
          selected={activeTab === tab.value}
          onClick={() => onTabsChange(tab.value)}
          width={tabWidth}
        >
          {tab.label}
        </TabItem>
      ))}
  </Tabs>
  )
}

export default MenuTabs