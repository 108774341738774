import styled from "styled-components";

export const Header = styled.div`
    h1{
        color: #002e5c;
        font-size: 20px;
        font-weight: bolder; 
    }
    span{
        font-size: 15px;
        color: #b5b5c3;  
    }
`
export const Wrapper = styled.div`
    

`
export const CheckBoxWrapper = styled.ul`
list-style-type: none;
li{
    font-size: 16px;
    color: #002e5c;
}
`