import { Link } from "react-router-dom";
import styled, { css } from "styled-components";


export const EventsCalendarContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`; 
export const EventsCalendarWrapper = styled.div`
  width: ${(props)=>(props.width ? props.width:'600px')};
  height: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    width: 95%;
  }


`

export const EventsCalendarHeader = styled.div``;

export const EventsCalendarTitle = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #002E5C;
`;




export const EventCalendarItemContainer = styled.div`
  width: 600px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const EventCalendarItemHeadTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const EventCalendarItemHeadLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #3F4254;
`;
export const EventCalendarItemHeadBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #3F4254;
`;
export const EventCalendarItemHeadLinkIcon = styled.img``;

export const EventCalendarItemHead = styled.div`
  display: flex;
  flex-direction: column;
`;
export const EventCalendarItemHeadLabel = styled.div`
  color:  #002E5C;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const EventCalendarItemEndDate = styled.div`
  color: #002E5C;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40.348px; 
  text-transform: capitalize;

`;
export const EventCalendarItemLabel = styled.div`
  color: #002E5C;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;
export const EventCalendarItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 4px;
  background: rgba(37, 85, 239, 0.04);
  padding: 10px;
  height: 120px;
  position: relative;
`;
export const EventCalendarItemCopyBtn = styled.div`
  cursor: pointer;
`;
export const EventCalendarItemCopyBtnIcon = styled.img``;
export const EventCalendarItemInfoLeft = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;
export const EventCalendarItemInfoType = styled.div`
    font-size: 20px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    font-weight: 700;
    font-size: 20px;
    color: #2555ef;
    white-space: nowrap;
    line-height: 22px;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    text-transform: capitalize;
`;
export const EventCalendarItemInfoRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const EventCalendarItemInfoDates = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const EventCalendarItemInfoDatesItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const EventCalendarItemInfoDatesItemValue = styled.div`
  color: #002E5C;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;
export const EventCalendarItemDesc = styled.div``;
export const EventCalendarItemText = styled.div``;
export const EventCalendarItemInfoAddress = styled.div``;
export const EventCalendarItemContact = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const EventCalendarItemContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem
`;

export const EventCalendarItemContactInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const EventCalendarItemContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const EventCalendarItemContactItemValue = styled.div`
  color: #002E5C;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const EventCalendarItemContactLinks = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
export const EventCalendarItemContactLinkItem = styled.a`
  cursor: pointer;
`;
export const EventCalendarItemContactItemImage = styled.img`
`;
export const Separator = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.1;
  background: #002E5C;
`;



export const EventCalendarItemInfoSeparator = styled.div`
  background: rgba(0, 46, 92, 0.05);
  width: 1px;
  height: 100%;
`;



export const Details = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  color: #002E5C;
  border-top: 1px dashed #002E5C;
  padding-top: 15px;

li{
  display: flex;
  justify-content: space-between; 
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
span{
  display: flex;
  justify-content: space-between; 
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  span{
    color: var(--dark-blue, #002E5C);
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
 
}
`
export const CopyAlert = styled.div`
  position: fixed;
  top: 2rem;
  z-index: 10000;
`;
