import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  p {
    width: 100%;
  }
`;
export const Label = styled.label`
  color: var(--Dark-Blue-100, #002e5c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  span:nth-child(2) {
    color: var(--Dark-Blue-40, #99abbe);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;
export const Input = styled.input`
  width: 312px;
  border-radius: 5px;
  border: ${(props) =>
    props.error
      ? "1px solid rgba(241, 88, 94, 1)"
      : "1px solid var(--Gray-40, #E1E1E7)"};
  background: var(--White-100, #fff);
  height: 48.13px;
  padding-left: 16px;
  color: #002e5c !important;
  ::placeholder {
    color: ${(props) =>
      props.isDisabled
        ? "var(--Gray-100, #9e9ead) !important"
        : "#99ABBE !important"};
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    padding-left: 5px;
  }
  :focus {
    border-radius: 5px;
    border: ${(props) =>
      props.error
        ? "1px solid rgba(241, 88, 94, 1)"
        : "1px solid var(--blues-blue-3, #99B7EF)"};
    background: var(--Blue-10, #f3f6f9);
  }
`;
export const Error = styled.p`
  display: flex;
  text-align: left !important;
  color: red !important;
  font-size: 12px !important;
  font-weight: 400;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  width: 100%;
  padding: 0 !important;
`;
export const CustomIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
`;
