// import React from "react";
// import BillingTable from "./BillingTable";
// import { withUserExist } from "../CustomHooks";

// const Billing = () => {
//   return <BillingTable />;
// };

// export default withUserExist(Billing);

import "./index.scss";

import Dialog from "@material-ui/core/Dialog";
import React, { useState } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import FinanceService from "Lib/API/Finance";
import CrossSvg from "assets/icons/svg/cross";
import MyButton from "components/MyButton";
import ViewDocumentModal from "components/ViewDocumentModal";
import { SubHeader } from "layout/SubHeader";
import { debounce } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { toastr } from "react-redux-toastr";
import MyModal from "../../components/MyModal";
import { OverDueDaysCalculator } from "../../constants/enums";
import { FinanceFilter } from "./FinanceFilter";
import NewCharge from "./NewCharge";
import UserContext from "./NewChargeContext";
import ChargesTable from "./TransactionsTable";
const initialFilters = {
  sort: {},
  filter: {
    "hidden": true,
  },
  search: undefined,
};

const Finance = () => {
  const [toggle, setToggle] = useState("Transaction");
  const [loading /* setLoading */] = useState(false);
  const [list /* setList */] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTaxStatement, setOpenTaxStatement] = useState(false);
  const [openTaxReport, setOpenTaxReport] = useState(false);
  const [fileData, setFileData] = useState("");
  const [file, setFile] = useState([]);

  const [viewDocument, setviewDocument] = useState("");
  const [documentData, setdocumentData] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [downloadPDF, setDownloadPDF] = useState("");
  const [sharePDF, setSharePDF] = useState("");
  const [closeNewTaxReport, setCloseNewTaxReport] = useState(false);
  const [closeNewStatementReport, setCloseNewStatementReport] = useState(false);
  const [paymentExpandable, setPaymentExpandable] = useState(true);
  const [closeCheck, setCloseCheck] = useState(false);

  const [stepOne, setStepOne] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [sortData, setSortData] = useState(initialFilters);
  const [searchOptions, setsearchoptions] = useState();
  const [data, setData] = useState({});
  const [isSaved, setSaved] = useState(false);
  const [hfinish, setHFinish] = useState(false);
  const [openBatch, setOpenBatch] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [productData, setProductData] = useState();
  const [count, setCount] = useState();
  const [page, setPage] = useState(0);
  const [batchId, setBatchId] = useState(null);
  const [editData, setEditData] = useState({});
  const [rowData, setRowData] = useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const [stepper, setStepper] = useState("New Charge");
  const [financeTablePage, setFinanceTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modalOpen, setModalOpen] = React.useState("");
  const [stepIndex, setStepIndex] = useState("");
  const [batchList, setBatchList] = useState(false);

  const [resetChecks, setResetchecks] = useState(false);
  const [viewDocumentId, setViewDocumentId] = React.useState(null);


  const option2 =
    productData &&
    productData?.map((item, index) => ({
      id: item?._id,
      name: item?.batch_name,
    }));

  const sortCharged = (e) => {
    setFilters((prev) => ({
      ...prev,
      sort: {
        [e]: prev.sort[e] === 1 ? -1 : 1,
      },
    }));
  };
  const onSearchChange = (e) => {
    const name = e.target.value;
    setFilters((prev) => ({
      ...prev,
      search: name ? name : undefined,
    }));
  };
  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      search: searchValue ? searchValue : undefined,
    }));
  }, [searchValue]);

  const onChargeDateChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        charge_date: e ? e?.target?.value : undefined,
      },
    }));
  };

  const onPaidDateChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        paid_at: e ? e?.target?.value : undefined,
      },
    }));
  };

  const onLedgerAccountChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        ledger: (typeof e === "string" ? e : e?.label) ?? undefined,
      },
    }));
  };

  const onAmountChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        amount: e ? e : undefined,
      },
    }));
  };

  const onChargedStatusChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        charge_status:
          e === "partially paid"
            ? "partially_paid"
            : e !== "partially paid"
              ? e
              : undefined,
      },
    }));
  };

  const onPaymentStatusChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        payment_status:
          e === "partialy applied"
            ? "partially_applied"
            : e !== "partialy applied"
              ? e
              : undefined,
      },
    }));
  };

  const onOverdueDaysChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        overdue: e ? OverDueDaysCalculator[e] : undefined,
      },
    }));
  };

  const onStatusChange = (status) => {
    setFilters((prev) => ({
      ...prev,
      status: status !== "All" ? status.toLowerCase() : undefined,
    }));
    setFinanceTablePage(0);
    setPaymentExpandable(false);
  };
  // const handleChangePage = (_, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setFinanceTablePage(0);
  // };
  const onClear = () => {
    setFilters({ ...initialFilters });
  };

  const onPayNowClick = ({ detail }) => {
    // alert("index.js");
    setData({
      ...detail,
      type: "pay-now",
    });
    setOpen(true);
  };
  const onChargeEditClick = ({ detail }) => {
    setData({
      ...detail,
      type: "edit-charge",
    });
    setOpen(true);
  };

  useEffect(() => {
    // alert("index.js");
    document.addEventListener("pay-now", onPayNowClick);
    return () => document.removeEventListener("pay-now", onPayNowClick);
  }, []);

  useEffect(() => {
    document.addEventListener("edit-charge", onChargeEditClick);
    return () => document.removeEventListener("edit-charge", onChargeEditClick);
  }, []);

  const onPayAllClick = ({ detail }) => {
    setData({
      allData: detail,
      type: "pay-all",
    });
    setOpen(true);
  };
  useEffect(() => {
    document.addEventListener("pay-all", onPayAllClick);
    return () => document.removeEventListener("pay-all", onPayAllClick);
  }, []);
  useEffect(() => {
    if (data?.type === "pay-now" || data?.type === "pay-all") {
      setStepIndex(1);
    } else {
      setStepIndex(0);
    }
  });
  const onModalClose = () => {
    if (isSaved || hfinish) {
      closeModal();
    } else {
      closeModal();
    }
  };

  const closeModal = () => {
    setStepOne(true);
    setOpen(false);
    setOpenBatch(false);
  };

  const addNewTransaction = () => {
    setData({});
    setResetchecks(true);
    setOpen(true);
  };
  const addNewBatchCharge = () => {
    setOpenBatch(true);
  };

  const addNewTaxStatement = () => {
    setOpenTaxStatement(true);
  };
  const { push } = useHistory();
  const handleClick = (tab, batch_id = null) => {
    if (batch_id) {
      setBatchId(batch_id);
      setModalOpen(true);
    } else {
      setBatchId(null);
    }
    setToggle(tab);

    push({
      pathname: "/finance",
    });
  };
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/finance") {
      setOpenBatch(false);

      setToggle("Transaction");
    }
  });

  const tabOptions = ["Transaction"];

  useEffect(() => {
    if (closeNewTaxReport == true) {
      setOpenTaxReport(false);
    } else if (closeNewStatementReport == true) {
      setOpenTaxStatement(false);
    }
    setCloseNewTaxReport(false);
    setCloseNewStatementReport(false);
  }, [closeNewTaxReport, closeNewStatementReport]);



  const viewDocumentHandler = (documentId) => {
  console.log('documentId :', documentId);
    setViewDocumentId(documentId);
  }



  const downloadFileHandler = async (row) => {
    const { _id, name, type } = row;
    const token = await getAccessTokenSilently();
    await FinanceService.downloadDocument(token, _id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const currentDate = moment().format('MM_DD_YYYY');
      const fileType = type === "tax_statement" ? "Statement" : type.charAt(0).toUpperCase() + type.slice(1);
      const fileName = `${fileType}_${name.replaceAll(" ", "_")}_${currentDate}`
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }



  return (
    <div
      className="members financeTran pr-sm-12 p-5 w-100"
      style={{ paddingLeft: "1rem" }}
    >
      {openBatch ? (
        ""
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center p-5">
            <SubHeader
              className="subheader"
              {...{
                title: "Finance",
                color: "#2455EF",
              }}
            />
            {/* <div className="d-flex">
              <MyButton
                buttonTextStyle={{ margin: 0, paddingBlock: 4.3 }}
                type={"primary"}
                content={
                  <div className="new-charge-button mx-5">
                    <span className="plus-icon">+</span>

                    <span>New Transaction</span>
                  </div>
                }
                onClick={addNewTransaction}
              />
            </div> */}
          </div>
          {/* <div className="toggle-bar mt-5">
            {tabOptions.map((tab, i) => (
              <p
                className={
                  toggle === tab
                    ? "selected tab-title w-120px"
                    : "unselected tab-title w-120px"
                }
                onClick={() => handleClick(tab)}
              >
                {tab}
              </p>
            ))}
          </div> */}
        </>
      )}
      {!openBatch ? <div className="border-line" /> : ""}

      <div className="mt-5 tran-filter">
        <FinanceFilter
          onSearchChange={onSearchChange}
          filter={filters}
          onChargeDateChange={onChargeDateChange}
          onLedgerAccountChange={onLedgerAccountChange}
          onPaidDateChange={onPaidDateChange}
          onAmountChange={onAmountChange}
          onChargedStatusChange={onChargedStatusChange}
          onPaymentStatusChange={onPaymentStatusChange}
          onOverdueDaysChange={onOverdueDaysChange}
          onClear={onClear}
          onStatusChange={onStatusChange}
          setFilters={setFilters}
          initialFilters={initialFilters}
          toggle={toggle}
        />
        <div className="charges-table w-100">
          <ChargesTable
            page={financeTablePage}
            filter={filters}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setFinanceTablePage}
            // handleChangePage={handleChangePage}
            // handleChangeRowsPerPage={handleChangeRowsPerPage}
            list={productData}
            loading={loading}
            filters={filters}
            sortData={sortData}
            sortCharged={sortCharged}
            paymentExpandable={paymentExpandable}
            setPaymentExpandable={setPaymentExpandable}
            batchList={batchList}
            setBatchList={setBatchList}
            resetChecks={resetChecks}
            setResetchecks={setResetchecks}
            viewDocumentHandler={viewDocumentHandler}
            downloadFileHandler={downloadFileHandler}

          // batch_id={batchId}
          />
        </div>
      </div>

      <MyModal
        className={
          stepOne
            ? "new-charge-modal"
            : stepper === "white"
              ? "new-charge-modal modal-step-one "
              : "new-charge-modal-w modal-step-two "
        }
        open={open}
        onClose={onModalClose}
        activeIndex={stepIndex}
      >
        <UserContext.Provider value={{ hfinish, setHFinish }}>
          <NewCharge
            setStepper={setStepper}
            setStepOne={setStepOne}
            handleClose={onModalClose}
            data={data}
            activeIndex={
              data?.type === "pay-now" || data?.type === "pay-all" ? 1 : 0
            }
            setSaved={setSaved}
            setBatchList={setBatchList}
          />
        </UserContext.Provider>
      </MyModal>
      <Dialog
        open={viewDocumentId !== null}
        onClose={() => {
          setViewDocumentId(null);
        }}
        PaperProps={{
          style: {
            width: "888px",
            height: "90vh",
            overflow: "hidden",
            borderRadius: "22px",
          },
        }}
        fullWidth
        maxWidth={"md"}
      >
        <ViewDocumentModal
          documentId={viewDocumentId}
          closeModal={() => {
            setViewDocumentId(null);
          }}
        />
      </Dialog>
    </div>
  );
};

export default Finance;
