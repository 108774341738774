import styled, { css } from "styled-components";


export const Body = styled.div`
width: 100%;
background-color: #FFFF;
overflow-x: hidden;
height: 100%;
${(props) => (props?.startedSteps ?
    css`
      `
    : css`
display: flex;
              `
  )};


`
export const LeftSide = styled.div`
  flex: 1;
`
export const RightSide = styled.div`
 width:45%;

 ${(props) => (props?.startedSteps ?
    css`
     width:55%;   
     @media only screen and (max-width: 1920px) and (max-height: 1596px) {
    /* height: 1300px; */
    background: rgba(0,46,92,0.03);
    margin: 31px 40px ;
    margin-left:61px ;

  }

    `
    : css`
   margin-top: 77px;
    `
  )};
  
`


export const Title = styled.h1`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color:#002E5C;
`
export const Wrapper = styled.div`
display: flex;
margin: 47px;
border: 1.5px solid rgba(0, 0, 0, 0.2);
height: ${(props) => (props?.payExternial && '100vh')};

/* height: 100vh; */
/* height: 100vh;

    @media only screen and (width: 1920px) and (height: 1596px) {
      height: 90%;

} */
`

export const StepperWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
padding: 33px 46px 34px 30px;
border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
background-color: #FFFF;
`
export const StepperContent = styled.div`
width: 50%;
`

export const Contant = styled.div`
display: flex;

`
export const Controlers = styled.div`
width: 222px;
font-weight: 400;
font-size: 16px;
line-height: 19px;
`
export const ButtonPrev = styled.button`
width: 109px;
border: none;
background-color: transparent;
font-style: normal;
color: #2555EF;
cursor: pointer;
`
export const ButtonNext = styled.button`
width: 113px;
height: 39px;
background: #2555EF;
font-style: normal;
color: #FFFFFF;
border-radius: 5px;
padding: 8px 20px;
gap: 8px;
border: none;
`
