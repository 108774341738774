import { InputField, LabelField, SelectField } from "components";
import { TextareaField } from "components/textareaField";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Select, { components } from "react-select-v2";
import { useSearch } from "hook";
import BottomArrowSvg from "assets/icons/svg/BottomArrowSvg";
import { useAuth0 } from "@auth0/auth0-react";
import FinanceService from "../../../../Lib/API/Finance";

const TributeInformation = ({ formik, data }) => {
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = useState("");
  const { searchAction } = useSearch({ action: setSearch });
  // const [data, setData] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const enableLoading = (value) => {
    setLoading(value);
  };
  const selectControlStyles = {
    backgroundColor: "#F3F6F9",
    borderColor: "#F3F6F9",
    color: "#3F4254",
    transition:
      "color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease",
    paddingTop: "6px",
    paddingBottom: "6px",
    cursor: "pointer",
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      ...selectControlStyles,
    }),
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <BottomArrowSvg label="Emoji" />
      </components.DropdownIndicator>
    );
  };
  const formatOptionLabel = ({ label, account_id }) => {
    return (
      <div className="dropdown-container" key={account_id}>
        <div className="icon-name-container d-flex align-items-center justify-content-start">
          <div className="name-container" style={{ marginInlineStart: 10 }}>
            <div>{label}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="">
      <form
        onSubmit={formik?.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className=" col-sm-12 pr-10 pt-5">
          <Row className="form-row">
            <Col className="col-sm-6 pr-5">
              <div className="d-flex justify-content-between">
                <LabelField title="Recipient" />
                <LabelField
                  className="cursor-pointer"
                  title="Not a Member"
                  style={{ color: "#2555ef" }}
                />
              </div>
              <div className="">
                <Select
                  {...{
                    formik,
                    name: `tribute_information`,
                    checkValidation: false,
                  }}
                  onInputChange={(text) => searchAction(text)}
                  classNamePrefix="react-select"
                  formatOptionLabel={formatOptionLabel}
                  options={data}
                  isLoading={loading}
                  styles={colourStyles}
                  onChange={(value) => {
                    formik.setFieldValue(`tribute_information`, {
                      id: value._id,
                      name: value.label,
                    });
                  }}
                  // value={
                  //   formik.values?.tribute_information
                  //     ? {
                  //         label: formik.values?.tribute_information?.name,
                  //         value: formik.values?.tribute_information?.id,
                  //       }
                  //     : ""
                  // }
                  components={{ DropdownIndicator }}
                />
              </div>
            </Col>
            <Col className="col-sm-6  tribute-text">
              <LabelField title="Tribute Type" />
              <Select
                {...{
                  formik,
                  name: `tribute_type`,
                  checkValidation: true,
                }}
                onInputChange={(text) => searchAction(text)}
                classNamePrefix="react-select"
                formatOptionLabel={formatOptionLabel}
                options={[
                  { id: "honor-of", label: "In honor of" },
                  { id: "memory-of", label: "In memory of" },
                ]}
                isLoading={loading}
                styles={colourStyles}
                onChange={(value) => {
                  formik.setFieldValue(`tribute_type`, {
                    id: value.id,
                    name: value.label,
                  });
                }}
                value={
                  formik.values?.tribute_type
                    ? {
                        label: formik.values?.tribute_type?.name,
                        value: formik.values?.tribute_type?.id,
                      }
                    : ""
                }
                components={{ DropdownIndicator }}
              />
            </Col>
          </Row>
          <Col className="col-sm-12 pt-8 pl-0">
            <TextareaField
              {...{
                formik,
                rows: 4,
                title: "Tribute Message",
                name: "note",
                placeholder: "Tribute text",
                style: { resize: "none" },
              }}
            />
          </Col>
          <p className="py-5 tribute-sub-title">
            <LabelField title="Acknowledgement" />
          </p>
          <Row className="form-row sm-12 mb-4">
            <Col className="col-sm-6" style={{ marginBottom: "23px" }}>
              <LabelField title="Donation Recipient Salutation" />
              <Select
                {...{
                  formik,
                  name: `donation_recipient_salutation`,
                  checkValidation: true,
                }}
                onInputChange={(text) => searchAction(text)}
                classNamePrefix="react-select"
                formatOptionLabel={formatOptionLabel}
                options={[
                  { id: "mr", label: "Mr" },
                  { id: "mrs", label: "Mrs" },
                  { id: "miss", label: "Miss" },
                  { id: "dr", label: "Dr" },
                  { id: "other", label: "Other" },
                ]}
                isLoading={loading}
                styles={colourStyles}
                onChange={(value) => {
                  formik.setFieldValue(`donation_recipient_salutations`, {
                    id: value.id,
                    name: value.label,
                  });
                }}
                value={
                  formik.values?.donation_recipient_salutations
                    ? {
                        label:
                          formik.values?.donation_recipient_salutations?.name,
                        value:
                          formik.values?.donation_recipient_salutations?.id,
                      }
                    : ""
                }
                components={{ DropdownIndicator }}
              />
            </Col>
            <Col className="col-sm-6">
              <InputField
                {...{
                  formik,
                  title: "Donation Recipient Name",
                  name: "donation_recipient_name",
                  placeholder: "",
                }}
              />
            </Col>
          </Row>
          <Row className="form-row sm-12 mb-4">
            <Col className="col-sm-6 pr-5 mb-7">
              <LabelField title="Donor Solutation" />
              <Select
                {...{
                  formik,
                  name: `donor_salutation`,
                  checkValidation: true,
                }}
                onInputChange={(text) => searchAction(text)}
                classNamePrefix="react-select"
                formatOptionLabel={formatOptionLabel}
                options={[
                  { id: "mr", label: "Mr" },
                  { id: "mrs", label: "Mrs" },
                  { id: "miss", label: "Miss" },
                  { id: "dr", label: "Dr" },
                  { id: "other", label: "Other" },
                ]}
                isLoading={loading}
                styles={colourStyles}
                onChange={(value) => {
                  formik.setFieldValue(`donor_salutation`, {
                    id: value.id,
                    name: value.label,
                  });
                }}
                value={
                  formik.values?.donor_salutation
                    ? {
                        label: formik.values?.donor_salutation?.name,
                        value: formik.values?.donor_salutation?.id,
                      }
                    : ""
                }
                components={{ DropdownIndicator }}
              />
            </Col>
            <Col className="col-sm-6">
              <InputField
                {...{
                  formik,
                  title: "Donor Name",
                  name: "donor_name",
                  placeholder: "",
                }}
              />
            </Col>
          </Row>
          <p className="">
            <LabelField title="Send Letter to:" />
          </p>
          <Row className="form-row sm-12 mb-4">
            <Col className="col-sm-6 pr-5 tribute-text">
              <InputField
                {...{
                  formik,
                  title: "Email",
                  name: "email",
                  placeholder: "",
                }}
              />
              <LabelField color="#2555ef" />
            </Col>
            <Col className="col-sm-6 ">
              <InputField
                {...{
                  formik,
                  title: "Address",
                  name: "address",
                  placeholder: "",
                }}
              />
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default TributeInformation;
