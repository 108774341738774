import React, { useEffect } from "react";

// Components
import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "./Container";
import { Header } from "./Header";
import { Main } from "./Main";
export const AppLayout = ({ children, isDonationLayout,  ...rest }) => {
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      localStorage.removeItem("externalToken");
      localStorage.setItem('token', token);
    })();
  }, []);
  
  if (
    (localStorage.getItem('DonationLayout') &&
      localStorage.getItem('DonationLayout') === true) ||
    (localStorage.getItem('redirectUrl') && localStorage.getItem('redirectUrl') !== '/donation-view')
  ) {
    return (
      <Container {...rest}>
        <>{children}</>
      </Container>
    );
  }
  return (
    <Container {...rest}>
      <Header />
      <Main>{children}</Main>
    </Container>
  );
};
