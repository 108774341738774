/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { HintField, LabelField } from "components";
import React, { memo, useRef, useEffect } from "react";
import "./style.scss";
import KeyboardIcon from "@material-ui/icons/Keyboard";

export const InputField = memo(
  ({
    icon,
    setFieldsData,
    memberInfo,
    title,
    name,
    useref,
    style,
    formStyle,
    type,
    step,
    rules,
    placeholderIcon,
    error,
    labelFieldClass,
    formik,
    checkValidation = false,
    mandatory = false,
    handleChange,
    setOpenKeyboard,
    openKeyboard,
    inputClassName,
    min,
    onClick,
    onKeyDown,
    batchreview,
    className,
    paymentInput,
    onBlur,
    ...rest
  }) => {
    const ref = useRef(null);
    useEffect(() => {
      if (useref) ref.current.focus();
    }, []);

    const getInputClasses = () => {
      if (!checkValidation) {
        return "";
      }

      if (formik?.touched[name] && formik?.errors[name]) {
        return "is-invalid";
      }

      if (formik?.touched[name] && !formik?.errors[name]) {
        return "is-valid";
      }

      return "";
    };
    return (
      <div
        className={
          memberInfo
            ? `form-group fv-plugins-icon-container  member-info-form`
            : batchreview
              ? `form-group fv-plugins-icon-container batchreview `
              : paymentInput === "paymentInput"
                ? `form-group fv-plugins-icon-container payment-methods-input`
                : title === `none`
                  ? `form-group fv-plugins-icon-container ledger-name-input`
                  : `form-group fv-plugins-icon-container `
        }
        style={{ ...formStyle }}
      >
        {title === "none" ? (
          ""
        ) : (
          <LabelField
            className={memberInfo ? "member-info-form-lable" : ""}
            htmlFor={name}
            title={title}
            mandatory={mandatory}
          />
        )}
        {icon}
        <form
          className="d-flex"
          style={{
            background: style?.background ? style?.background : "#F3F6F9",
            borderRadius: "6px",
          }}
        >
          <input
            ref={ref}
            style={
              paymentInput
                ? {
                  ...style,
                  height: "48px",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: "700",
                }
                : { ...style, height: "48px" }
            }
            id={name}
            type={type}
            min={min}
            step={step}
            onKeyDown={onKeyDown}
            disabled={rest?.disabled}
            className={`form-control form-control-solid py-5 px-6 ${getInputClasses()} ${inputClassName} `}
            {...rest}
            {...formik?.getFieldProps(name)}
            onChange={handleChange ?? formik?.handleChange}
            onBlur={onBlur}
            value={rest.value}
            autoComplete="off"
            onClick={rest?.handleFocus}
          />
          {name === "hebrew_name" && (
            <KeyboardIcon
              fontSize="large"
              style={{
                cursor: "pointer",
                alignSelf: "center",
                marginRight: "5px",
              }}
              onClick={() => {
                setOpenKeyboard(!openKeyboard);
              }}
            />
          )}
          {placeholderIcon}
        </form>

        {formik?.touched[name] && formik?.errors[name] ? (
          <HintField error={formik?.errors[name]} />
        ) : null}
      </div>
    );
  }
);
