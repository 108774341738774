import styled from "styled-components";

export const PayWrapper = styled.div`
width: 85%;
display: flex;
flex-direction: column;
align-items: center;
`
export const FormHeader = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-bottom: 61px;
`
export const Title = styled.div`
width: 100%;
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
color: #002E5C;
text-align: center;
margin-bottom: 15px;
margin-top: 30px;
`
export const PayForm = styled.div`
width: 80%;

`
export const Description = styled.div`
width: 454px;
text-align: center;
color: #002E5C;
`
export const Label = styled.label`
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #002E5C;
padding-left: 11px;
`
export const RadioWrapper = styled.div`
display: flex;
justify-content: left;
text-align: left;
align-items: center;
margin-left: 11px;

`
export const RadioLabel = styled.label`
color: #002E5C;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
padding-right: 32px;

`
