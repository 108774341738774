import moment from "moment";
import * as Yup from "yup";

export const AddMournersSchema = Yup.object().shape({
  mourners_list: Yup.string().required("Add one mourner at least"),
});
export const AddittionalInformationSchema = Yup.object().shape({
  // plaques_number: Yup.string().required(""),
  // cemetery: Yup.string().required(""),
  // about_deceased: Yup.string().required(""),
});
export const DeceacedDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Name is required")
    .matches(/^(?![\s-])[\w\s-]+$/, "Name can only contain letters.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  lastName: Yup.string()
    .required("Name is required")
    .matches(/^(?![\s-])[\w\s-]+$/, "Name can only contain letters.")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  // hebrew_name: Yup.string().required("Hebrew name is required"),
  date_of_death: Yup.string()
    .required("Required")
    .test("DOB", "Please select valid date of death", (value) => {
      return moment().diff(moment(value), "days") + 1 > 0;
    }),
});
