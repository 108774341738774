import { useAuth0 } from "@auth0/auth0-react";
import AuthService from "Lib/API/Auth";
import FormService from "Lib/API/Forms";
import { ReactComponent as Instagram } from "assets/icons/InstagramColorful.svg";
import { ReactComponent as Twitter } from "assets/icons/TwitterColorful.svg";
import { ReactComponent as Facebook } from "assets/icons/facebookColorful.svg";
import { Counter } from "components/Counter/Counter";
import { convertUTCToLocalDate } from "components/UTC/UtcConverter";
import cuid from "cuid";
import * as calculation from "helper/Calculate";
import { formatDate, setLocalizationMoment } from "helper/dateFormat";
import { getCurrency, numberFormat } from "helper/numberFormat";
import { useMobileView } from 'hook/mobileView/useMobileView';
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { setCounterTicket } from "services/api/forms";
import thousands from "thousands";
import {
    ContactInfo,
    ContactInfoDetails,
    ContactTitle,
    DashLine, Description, DescriptionText, FormCaption, FormLogin, FormName, FormTitle,
    Note,
    PayDetails,
    ReserveAction,
    ReserveButton,
    ReserveLoginButton,
    RightSideContent,
    RightSideWrappr, ShowMoreText, SocialIcons, TotalPay
} from "./Theme";

export const RightSide = () => {
    const { isAuthenticated, logout,getAccessTokenSilently } = useAuth0();
    const currency = getCurrency();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { numberOfTickets } = useSelector((state) => state?.Forms);
    const { formOnlineData } = useSelector((state) => state?.Forms);
    const totalPayforAllTickets = formOnlineData?.formPricing?.total_to_pay * numberOfTickets.length
    const paymentMethod = JSON.parse(localStorage.getItem('paymentMethodForms')) || {};
    const token = localStorage.getItem('token');
    const { isMobile } = useMobileView()

    const memberPortal = localStorage.getItem('memberPortal') === 'true' || false;



    const viewPayDeatils = thousands(calculation.calculateTotalPaymentWithCVFee(paymentMethod, formOnlineData, numberOfTickets, "form",isAuthenticated)) !== '0.00'
        && !formOnlineData.freeForm
    const isExpiredClass = () => {
        const startDateDiff = moment().diff(moment(formOnlineData?.formStartDate), 'days');
        const endDateDiff = moment().diff(formOnlineData?.formEndDate ? moment(formOnlineData?.formEndDate) : moment(), 'days');
        console.log('startDateDiff', startDateDiff, "endDateDiff", endDateDiff, startDateDiff < 0 || endDateDiff > 0)

        return startDateDiff < 0 || endDateDiff > 0
    }
    const params = useParams();
    const dispatch = useDispatch();

    const handleDifferanceDate = () => {
        const date1 = new Date();
        const date2 = new Date(formOnlineData?.formEndDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays === 1 ? <span>{diffDays} Day  left!</span>
            : <span>{diffDays} Days  left!</span>
    }
    const [isShowMore, setIsShowMore] = useState(true);
    const toggleReadMore = () => setIsShowMore((show) => !show);
    const isExpiredEvent = () => {
        const startDateDiff = moment().diff(moment(formOnlineData?.formStartDate), 'days');
        const endDateDiff = moment().diff(formOnlineData?.formEndDate ? moment(formOnlineData?.formEndDate) : moment(), 'days');

        return startDateDiff < 0 || endDateDiff > 0
    }

    const convenienceFeeAmount = paymentMethod?.convenienceFeeInfo?.type === "%"
        ? (calculation.calculateDiscount(formOnlineData, numberOfTickets, "form",isAuthenticated)?.totalAfterDiscount * paymentMethod?.convenienceFeeInfo?.amount) / 100 :
        paymentMethod?.convenienceFeeInfo?.amount
    const getToken = async () => {
        let token = "";
        try {
            const subDomain = window.location.hostname.split('.')[0];
            const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
            await AuthService.generateToken(hostName).then(res => {
                localStorage.setItem('formToken', res?.token);
                localStorage.setItem('logoImgForm', res?.logoImg);
                localStorage.setItem('paymentMethodForms', JSON.stringify(res?.paymentMethod))
                localStorage.setItem('orgName', res?.orgName);
                localStorage.setItem('country', JSON.stringify(res?.country || {}));
                localStorage.setItem('memberPortal', res?.memberPortal || false);

                setLocalizationMoment(res?.country?.local || "en");

                document.title = `${res?.orgName}`;
                token = res?.token
            })
        } catch (error) {
            enqueueSnackbar(error?.formOnlineData?.message, {
                variant: 'error',
            });
        }
        return token;
    }
    const handleReserveButton = async () => {
        if(!formOnlineData?.allowNonMembers && !isAuthenticated) return;
        const body = {
            formId: formOnlineData?._id,
            numberOfTicket: numberOfTickets?.length
        }
        setLoading(true)
        const token = isAuthenticated ? await getAccessTokenSilently() : localStorage.getItem("formToken") || await getToken()
        if (!token) return;
        try {
            await FormService.reserveSeats(token, body).then((res) => {
                setLoading(false)
                localStorage.setItem("reserveId", res?.result?._id)
                history.push(`/form/${params?.id}/reservation`);
            })
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
            throw error;
        }

    }
    useEffect(() => {
        if (!localStorage.getItem("formToken")) getToken()

    }, [])
    console.log('Check', loading || (!formOnlineData?.allowNonMembers && !token) || isExpiredClass(), (!formOnlineData?.allowNonMembers && !token)
        , formOnlineData?.allowNonMembers, token)


    const displayRegistrationStartDate = () => {
        const showRegistrationStartDate = formOnlineData?.alwaysAvailable && moment(formOnlineData?.formStartDate).isAfter(moment(), 'day');
        return showRegistrationStartDate ? `submissions starts on ${formatDate({ date: formOnlineData?.formStartDate, formatType: "defaultWithUTC" })}` : '';
    }


    useEffect(() => {
        localStorage.removeItem('redirectUrl');
      }, []);

    const handleReserveLogin = () => {
        if(isAuthenticated)  {
            handleReserveLogout();
            return;
        }
        localStorage.setItem("redirectUrl", `/form/${params?.id}`);
        const originUrl = window.location.origin;
        logout({ returnTo: originUrl });
    }

    const handleReserveLogout =  () => {
     logout({ 
        localOnly: true,
        openUrl: false
      });
    }
    


    const reserveLoginButton = useMemo(() => isAuthenticated ? "Logout" : formOnlineData?.allowNonMembers ? "Log in for members pricing" : "Log in to sign up" , [isAuthenticated, formOnlineData?.allowNonMembers]);


    const eventLogin = useMemo(() => isAuthenticated ? "Logout" : "Login" , [isAuthenticated]);
    

    return (
        <RightSideWrappr>
            <RightSideContent>
                {memberPortal && (
                    <FormLogin>
                        <ReserveLoginButton onClick={handleReserveLogin}>{eventLogin}</ReserveLoginButton>
                    </FormLogin>
                )}
                <FormTitle>
                    <FormName>{formOnlineData?.formName}</FormName>
                    <FormCaption>
                        {displayRegistrationStartDate()}
                        {!formOnlineData?.alwaysAvailable && <span>Submissions Ends  {formatDate({ date: formOnlineData?.formEndDate, formatType: "defaultWithUTC" })}
                        </span>}
                        {isExpiredEvent() ? '' : !formOnlineData?.alwaysAvailable && <div>{handleDifferanceDate()} </div>}
                        {(!formOnlineData?.allowNonMembers && !isAuthenticated) && <div className="members-only-indicator">Members Only</div>}
                        </FormCaption>
                </FormTitle>

                <Description>
                    <span>Description</span>
                    <DescriptionText>
                        {formOnlineData?.description?.replace(/&lt;/g, '<') !== '<p></p>\n' && (
                            <div className="drawer_description">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: formOnlineData?.description?.replace(/&lt;/g, '<'),
                                    }}
                                ></p>
                            </div>
                        )}

                    </DescriptionText>
                </Description>
                <ContactTitle>Contact Info</ContactTitle>
                <ContactInfo>
                    <ContactInfoDetails>
                        <span style={{ paddingRight: 10 }}>Name</span>{formOnlineData?.formDetails?.contactInfo?.firstName} {formOnlineData?.formDetails?.contactInfo?.lastName}
                        {formOnlineData?.formDetails?.contactInfo?.showEmail &&
                            <><span style={{ paddingRight: 10, paddingLeft: !isMobile && 20 }}>Email</span><p>{formOnlineData?.formDetails?.contactInfo?.email}</p></>
                        }
                    </ContactInfoDetails>
                    <SocialIcons>
                        {formOnlineData?.formDetails?.contactInfo?.facebook &&
                            <a href={`${formOnlineData?.formDetails?.contactInfo?.facebook}`} rel="noreferrer" >
                                <Facebook />
                            </a>
                        }
                        {formOnlineData?.formDetails?.contactInfo?.instagram &&
                            <a href={`${formOnlineData?.formDetails?.contactInfo?.instagram}`} rel="noreferrer" >
                                <Instagram />
                            </a>
                        }
                        {formOnlineData?.formDetails?.contactInfo?.twitter &&
                            <a href={`${formOnlineData?.formDetails?.contactInfo?.twitter}`} rel="noreferrer" >
                                <Twitter />
                            </a>
                        }
                    </SocialIcons>
                </ContactInfo>
                <DashLine />
                {!formOnlineData?.freeForm && <PayDetails>
                    {formOnlineData?.formPricing?.prices?.map((item, index) => item?.type === "onRegistration" ? (
                        viewPayDeatils && <>
                            <li key={index}>{item?.title}<span>{numberFormat({ number: +item.amount, decimal: 2, style: "currency" })}</span></li>
                            <Note>{item?.note}</Note>
                            <hr />
                        </>
                    )
                        : <>
                            <li key={index}>{item?.title} {item?.type === 'optional' && `(optional)`}
                                {item.type !== 'openAmount' && <span>{item?.amount > 0 ? `${numberFormat({ number: +item.amount, decimal: 2, style: "currency" })}` :
                                    item?.variantAmountRange ? item?.variantAmountRange?.replaceAll("$", currency) : "0.00"}</span>}</li>
                            <Note>{item?.note}</Note>
                            <hr />
                        </>
                    )}
                    {viewPayDeatils && formOnlineData?.formDiscount?.map((item, index) => (
                        <>
                            <li 
                                key={index}
                                className={`d-flex flex-column gap-2 ${(item?.forMemberOnly && !isAuthenticated) ? 'opacity-50' : ''}`}
                            >
                                 <div className="d-flex align-items-center justify-content-between">
                                {item?.discount_name}
                                {item?.discount_type === 'date' && ` | ${formatDate({ date: item?.start_date, formatType: "defaultWithUTC" })} -
                                ${formatDate({ date: item?.end_date, formatType: "defaultWithUTC" })}`}
                                <span>
                                    {item?.price_type === 'percentage' ? `${item?.value}%` : `${numberFormat({ number: +item?.value, decimal: 2, style: "currency" })}`}
                                </span>
                                </div>
                                {(item?.forMemberOnly  && !isAuthenticated) && (
                                    <span style={{ fontSize: '12px' }}>
                                        (Members Only)
                                    </span>
                                )}
                            </li>
                            <hr />
                        </>

                    ))}
                    {viewPayDeatils && paymentMethod?.coverConvenienceFee && calculation.calculateDiscount(formOnlineData, numberOfTickets, "form", isAuthenticated)?.totalAfterDiscount > 0 && (
                        <>
                            <li>Convenience fee
                                <span>
                                    {numberFormat({ number: +convenienceFeeAmount, decimal: 2, style: "currency" })}
                                </span>
                            </li>
                            <hr />
                        </>
                    )}
                    {viewPayDeatils &&
                        <>
                            <TotalPay>TOTAL PAYMENT<span>{numberFormat({ number: calculation.calculateTotalPaymentWithCVFee(paymentMethod, formOnlineData, numberOfTickets, "form",isAuthenticated), decimal: 2, style: "currency" })}
                            </span></TotalPay><hr />
                        </>
                    }

                </PayDetails>}


                <br />
                {/* <CounterWrapper>
                <span>Amount of tickets</span>
                <Counter counter={numberOfTickets?.length} handleClickIncrement={handleClickIncrement}
                handleClickDecrement={handleClickDecrement} 
                />
            </CounterWrapper> */}

            </RightSideContent>
            <ReserveAction>
                {memberPortal ? (
                    <ReserveLoginButton onClick={handleReserveLogin}>
                        {reserveLoginButton}
                    </ReserveLoginButton>
                ) : <div></div>}
                <ReserveButton
                    onClick={handleReserveButton}
                    disabledButton={(!formOnlineData?.allowNonMembers && !isAuthenticated) || isExpiredClass() || loading}
                >
                    {loading ? <PulseLoader color={'#fff'} size={'7px'} /> : 'Start'}
                </ReserveButton>
            </ReserveAction>
        </RightSideWrappr>
    );
};
