import { useAuth0 } from "@auth0/auth0-react";
import { DatePickerField } from "_metronic/_partials/controls";
import MyButton from "components/MyButton";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { InputField } from "../../components";
import Spinner from "../../components/Spinner";
import AuthService from "../../Lib/API/Auth";
import "./index.scss";

import { PhonePicker } from "components/PhonePicker";
import { formatDate, getDateFormat, getDateFormatForDatePicket } from "helper/dateFormat";
import "react-datepicker/dist/react-datepicker.css";
import { isValidPhoneNumber } from "react-phone-number-input";

const PersonalInfoEdit = ({ userInfo }) => {
  const { family_members } = useSelector((state) => state.dashboardData);
  const [data, setData] = useState();
  const [localFormat, setLocalFormat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [checked, setChecked] = useState(false);


  const placeholderFormat = getDateFormat();
  const dateFormat = getDateFormatForDatePicket();

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    console.log(userInfo, "userInfo");
    async function fetchMyAPI() {
      const token = await getAccessTokenSilently();
      AuthService.getAccountSetting(
        token,
        userInfo?.user?.permissions?.linked_contact?._id
      )
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          setData(err);
          toastr.error('Error', err?.data?.message);
        });
    }
    if (userInfo?.user?.permissions?.linked_contact) {
      fetchMyAPI();
    }
  }, [userInfo]);

  // useEffect(() => {
  //   if (data?.[0]?.date_of_birth) {
  //     console.log(
  //       moment(data?.[0]?.date_of_birth).format("MM/DD/YYYY"),
  //       "data"
  //     );

  //     setStartDate(moment(data?.[0]?.date_of_birth).format("MM/DD/YYYY"));
  //   }
  // }, [data]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      profile_img: family_members[family_members.length - 1]?.profile_img,
      emailAddress: family_members[family_members.length - 1]?.email_address,
      address: family_members[family_members.length - 1]?.address,
      phone_number: data?.[0]?.mobile_number,
      first_name: data?.[0]?.first_name ?? " ",
      middle_name: data?.[0]?.middle_name ?? " ",
      last_name: data?.[0]?.last_name ?? " ",
      date_of_birth:
        data?.[0]?.date_of_birth === null
          ? "MM/DD/YYYY"
          : formatDate({date:data?.[0]?.date_of_birth, formatType: "defaultWithUTC"}),
      occupation: data?.[0]?.occupation ?? "",
    },
    validationSchema: "",
    onSubmit: async (values) => {
      const token = await getAccessTokenSilently();

      const validMobilePhone = isValidPhoneNumber(values.phone_number || "");
      if (values.phone_number && !validMobilePhone) {
        toastr.info("Validation", "Invalid Mobile phone");
      }


      setLoading(true);
      const input = {
        mobile_number: values.phone_number,
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        date_of_birth: values.date_of_birth,
        occupation: values.occupation,
        contactID: userInfo?.user?.permissions?.linked_contact?._id,
      };

      AuthService.updateAccountSetting(input, token)
        .then((res) => {
          if (res) {
            toastr.success("Success", "Successfully Completed");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          setLoading(false);
          toastr.error(
            "Error",
            err?.data?.validation?.[0]?.message ?? err?.data?.message
          );
        });
    },
  });

  return (
    <div className="profile-container">
      {userInfo ? (
        <>
          <div className="edit-profile-container">
            <div className="profile-header">
              <div>
                <div className="main-text">Personal information</div>
                <div className="body-text">
                  Update your personal information
                </div>
              </div>
              {/* <MyButton content="Edit" /> */}
            </div>
            <hr />
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework"
            >
              <div className="profile-body">
                <Row>
                  <Col className="col-sm-3">
                    <div className="form-name"> First Name</div>
                  </Col>
                  <Col className="col-sm-6">
                    <InputField
                      mandatory={false}
                      {...{
                        formik,
                        name: "first_name",
                        placeholder: "First name",
                        checkValidation: true,
                        value: formik.values?.first_name,
                      }}
                      handleChange={(e) => {
                        formik.setFieldValue("first_name", e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-3">
                    <div className="form-name">Midde Name</div>
                  </Col>
                  <Col className="col-sm-6">
                    <InputField
                      mandatory={false}
                      {...{
                        formik,
                        name: "middle_name",
                        placeholder: "Middle name",
                        checkValidation: true,
                        value: formik.values?.middle_name,
                      }}
                      handleChange={(e) => {
                        formik.setFieldValue("middle_name", e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-3">
                    <div className="form-name">Last Name</div>
                  </Col>
                  <Col className="col-sm-6">
                    <InputField
                      mandatory={false}
                      {...{
                        formik,
                        name: "last_name",
                        placeholder: "Last Name",
                        checkValidation: true,
                        value: formik.values?.last_name,
                      }}
                      handleChange={(e) => {
                        formik.setFieldValue("last_name", e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                {/* <Row>
            <Col className="col-sm-3">
              <div className="form-name">Suffix</div>
            </Col>
            <Col className="col-sm-6">
              <InputField
                mandatory={false}
                {...{
                  formik,
                  name: "suffix",
                  placeholder: "David",
                  checkValidation: true,
                }}
              />
            </Col>
          </Row> */}
                <Row>
                  <Col className="col-sm-3">
                    <div className="form-name">Date of Birth</div>
                  </Col>
                  <Col
                    className="col-sm-6 date_width"
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <DatePicker
                        {...{
                          formik,
                          name: "date_of_birth",
                        }}
                        value={formik?.values?.date_of_birth}
                        open={checked}
                        onChange={(date) => {
                          formik.setFieldValue(
                            "date_of_birth",
                            formatDate({date:date, formatType: "defaultWith"})
                          );
                        }}
                        isClearable
                        placeholderText={placeholderFormat}
                        dateFormat={dateFormat}
                      />
                      <i
                        style={{
                          position: "absolute",
                          top: "17px",
                          right: "16%",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          formik.setFieldValue("date_of_birth", "");
                        }}
                        title={"Remove date"}
                        className="fa fa-times"
                        aria-hidden="true"
                      ></i>
                      <i
                        style={{
                          position: "absolute",
                          top: "17px",
                          right: "6%",
                        }}
                        onClick={() => setChecked(!checked)}
                        title={"Calender"}
                        className="fa fa-calendar"
                        aria-hidden="true"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-6">
                    <div className="form-name">Professional Occupation</div>
                  </Col>
                  <Col className="col-sm-6">
                    <InputField
                      mandatory={false}
                      {...{
                        formik,
                        name: "occupation",
                        placeholder: "Professional Occupation",
                        checkValidation: true,
                        value: formik?.values?.occupation,
                      }}
                      handleChange={(e) => {
                        formik.setFieldValue("occupation", e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-sm-3">
                    <div className="form-name">Phone Number</div>
                  </Col>
                  <Col className="col-sm-6">
                    <div className="form-group mt-7">
                    <PhonePicker
                      value={formik.values.phone_number}
                      handleChange={(e) => {
                        formik.setFieldValue("phone_number", e);
                      }}
                      placeholder="Phone Number"
                    />
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div className="cancel-button">
              <MyButton
                content={loading ? "loading..." : "Save"}
                disabled={loading ? true : false}
                onClick={() => {
                  formik.handleSubmit();
                }}
                buttonTextStyle={{
                  margin: 0,
                }}
              />
            </div>
            <div className="cancel-button">
              <MyButton 
                content="Cancel" 
                type="secondary" 
                buttonTextStyle={{
                  margin: 0,
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default PersonalInfoEdit;
