import React from "react";
import { ReactFormGenerator } from "react-form-builder2";
import { Wrapper } from './Theme';
import { formatDateInFormData } from "./formDataHelper";
import './style.css';
const FormGeneratorComponent = ({ formStructure, syncValuesWithFormik, answerData }) => {

  const formData = formatDateInFormData(formStructure);
  return (
    <Wrapper>
    <ReactFormGenerator
    
      data={formData} // Question data
      answer_data={answerData || []} // Answer data, only used if loading a pre-existing form with values.
      task_id={12} // Used to submit a hidden variable with the id to the form from the database.
      onBlur={(event) => {
        syncValuesWithFormik(event)
      }}
      hide_actions
    />
    </Wrapper>
  );
};

export default FormGeneratorComponent;