import React from "react";
import { GridOverlay, DataGrid } from "@material-ui/data-grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./style.scss";

const MyTable = ({
  rowHeight,
  rows,
  columns,
  pageSize,
  checkboxSelection,
  disableSelectionOnClick,
  loading,
  page,
  className,
  onClickEvent,
  onSelectionModelChange,
  selectionModel,
  onPageChange,
  ...props
}) => {
  function CustomLoadingOverlay() {
    return (
      <GridOverlay>
        <div>
          <CircularProgress
            style={{ color: "#2555EF", width: "80px", height: "80px" }}
          />
        </div>
      </GridOverlay>
    );
  }

  console.log(rows, "reows");

  return (
    <DataGrid
      loading={loading}
      components={{
        LoadingOverlay: CustomLoadingOverlay,
      }}
      className={`${className} data-table`}
      rows={rows}
      rowHeight={rowHeight}
      columns={columns}
      pageSize={pageSize}
      onPageChange={onPageChange}
      page={page}
      onCellClick={onClickEvent}
      checkboxSelection={checkboxSelection}
      disableSelectionOnClick={disableSelectionOnClick}
      selectionModel={selectionModel}
      onSelectionModelChange={onSelectionModelChange}
      hideFooterSelectedRowCount
      getRowId={(row) => row._id}
      {...props}
    />
  );
};

export default MyTable;
