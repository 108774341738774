import React, { memo, useEffect } from "react";
import { HintField, LabelField } from "components";
import ArrowdownSVG from "assets/icons/arrow-down-gray.svg";
import { useSelect } from "downshift";
import { Button, ListItem, ListItemText } from "@material-ui/core";
import "./style.css";

export const SelectField = memo(
  ({
    title,
    name,
    style,
    type,
    label,
    error,
    formik,
    items,
    onClick,
    className,
    placeholder,
    mandatory,
    checkValidation = false,
    value,
    selectedItemColor = "#000",
    fromExternalLink,
    ...rest
  }) => {
    const itemToString = (item) => item || "";
    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
    } = useSelect({ items: items, itemToString });

    useEffect(() => {
      if (selectedItem) onClick(selectedItem);
    }, [selectedItem]);

    const getInputClasses = () => {
      if (!checkValidation) {
        return "";
      }

      if (formik.touched[name] && formik.errors[name]) {
        return "is-invalid";
      }

      if (formik.touched[name] && !formik.errors[name]) {
        return "is-valid";
      }

      return "";
    };

    return (
      <div className="form-group fv-plugins-icon-container">
        {title && <LabelField
          htmlFor={name}
          title={title}
          labelClassName={className}
          mandatory={mandatory}
          {...getLabelProps()}
        />}
        <Button
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses()}`}
          {...getToggleButtonProps()}
        >
          <div
            className="flex-grow-1"
            style={{
              color: 'rgb(181, 181, 195)',
              fontSize: 14,
              textTransform: "capitalize",
              textAlign: "left",
              fontFamily: "Poppins",
              letterSpacing: 0.1,
            }}
          >
            {/* {selectedItem ? value : placeholder
              ? placeholder : items[0]} */}
            {selectedItem
              ? value
              : value
                ? value
                : placeholder
                  ? placeholder
                  : items?.[0]}
          </div>
          {/* <ArrowdownSVG
            style={{ height: 15, width: 15, marginRight: 10 }}
            fill={selectedItemColor || "#B5B5C3"}
          /> */}
          <img src={ArrowdownSVG} alt="arrow"
              style={{ height: 9, width: 15, marginRight: 10 }}
              fill={selectedItemColor || "#B5B5C3"}
          />
        </Button>
        <div className="dropdown">
          <div {...getMenuProps()} className="dropdown-content">
            {isOpen &&
              items.map((item, index) => {
                console.log("item selectFiels=>", item);
                return (
                  <ListItem
                    key={`${fromExternalLink ? item.value : item}-${index}`}
                    {...getItemProps({
                      item,
                      index,
                    })}
                  >
                    <ListItemText
                      primary={fromExternalLink ? item.value : item}
                      className={"dropdown-content-text cursor-pointer"}
                    />
                  </ListItem>
                );
              })}
          </div>
        </div>
        {formik.touched[name] && formik.errors[name] ? (
          <HintField error={formik.errors[name]} />
        ) : null}
      </div>
    );
  }
);
