import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { setStepOne } from "../../services/api/formSteps";
import { InputField, SelectField } from "components";
import AuthService from "../../Lib/API/Auth";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginSchema } from "../ValidationSchema/index";
import { toastr } from "react-redux-toastr";

export const FirstTime = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const { logout, user } = useAuth0();
  // const originUrl = process.env.LOG_OUT_URL
  const stepOneData = useSelector((state) => state.stepForms.step_one);

  const enableLoading = (value) => {
    setLoading(value);
  };

  const initialValues = {
    first_name: stepOneData.first_name || "",
    middle_name: stepOneData.middle_name || "",
    last_name: stepOneData.last_name || "",
    hebrew_name: stepOneData.hebrew_name || "",
    title: stepOneData.title || "",
  };

  const { getAccessTokenSilently } = useAuth0();
  const organization = JSON.parse(
    localStorage.getItem("getOrginasitionBySubDomain")
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      dispatch(setStepOne(values));
      enableLoading(true);
      const token = await getAccessTokenSilently();
      const input = {
        first_name: values.first_name,
        last_name: values.last_name,
        // organization: organization?.orgkey,
        organization: organization?._id,
      };
      if (values.middle_name) {
        input.middle_name = values.middle_name;
      }
      if (values.hebrew_name) {
        input.hebrew_name = values.hebrew_name;
      }
      if (values.title) {
        input.title = values.title;
      }

      await AuthService.postFirstStep(input, token)
        .then((res) => {
          enableLoading(false);
          props.setCurrentStepCheck({ type: "next" });
          localStorage.setItem("checkUser", true);
        })
        .catch((err) => {
          enableLoading(false);
          toastr.error('Error', err?.data?.message);
        });
    },
  });

  return (
    <div className="login-form login-signin login-signin-d">
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <InputField
          {...{
            formik,
            title: "First Name",
            name: "first_name",
            checkValidation: !!formik.values.first_name,
            mandatory: true,
            placeholder: "Enter first name",
          }}
        />
        <InputField
          {...{
            formik,
            title: "Middle Name",
            name: "middle_name",
            placeholder: "Enter middle name",
          }}
        />
        <InputField
          {...{
            formik,
            title: "Last Name",
            name: "last_name",
            checkValidation: !!formik.values.last_name,
            mandatory: true,
            placeholder: "Enter last name",
          }}
        />
        <InputField
          {...{
            formik,
            title: "Full Hebrew Name",
            name: "hebrew_name",
            placeholder: "Enter hebrew name",
          }}
        />
        <SelectField
          {...{
            formik,
            title: "Title",
            name: "title",
            items: ["Mr", "Ms", "Miss", "Mrs", "Dr"],
            placeholder: "Select title",
          }}
          onClick={(data) => formik.setFieldValue("title", data)}
          value={formik.values.title}
        />

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            type="submit"
            disabled={!formik.isValid || loading}
            // disabled={!_.isEmpty(formik.errors)}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 w-50 mx-auto`}
          >
            <span style={{ fontSize: 13, fontWeight: "600" }}>Next</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          {/* <button
            onClick={() => logout({ returnTo: originUrl })}
            className={`btn font-weight-bold px-9 py-4 my-3`}
          >
            <span style={{ fontSize: 13, fontWeight: "600" }}>Log Out</span>
          </button> */}
        </div>
      </form>
    </div>
  );
};
