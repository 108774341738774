import { useAuth0 } from "@auth0/auth0-react";
import AuthService from "Lib/API/Auth";
import MemberService from 'Lib/API/Member';
import { convertUTCToLocalDate } from "components/UTC/UtcConverter";
import * as calculation from "helper/Calculate";
import { formatDate, setLocalizationMoment } from "helper/dateFormat";
import { numberFormat } from "helper/numberFormat";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import thousands from "thousands";
import ClassBox from "../ClassBox/ClassBox";
import {
    DashLine, Description, DescriptionText,
    EventTitle,
    Note,
    PayDetails, ReserveAction, ReserveButton,
    ReserveLoginButton,
    RightSideContent,
    RightSideWrappr,
    SchoolCaption, SchoolLogin, SchoolName,
    ShowMoreText, SocialIcons, TotalPay
} from "./Theme";
export const RightSide = () => {
    const { isAuthenticated, logout,getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { classDetails, numberOfTickets } = useSelector((state) => state?.Class)
    const paymentMethod = JSON.parse(localStorage.getItem('paymentMethodSchool')) || {};
    const token = localStorage.getItem('token');

    const memberPortal = localStorage.getItem('memberPortal') === 'true' || false;

    const convenienceFeeAmount = paymentMethod?.convenienceFeeInfo?.type === "%"
        ? (calculation.calculateDiscount(classDetails, numberOfTickets, "school",isAuthenticated)?.totalAfterDiscount?.replace(/,/g, '') * paymentMethod?.convenienceFeeInfo?.amount) / 100 :
        paymentMethod?.convenienceFeeInfo?.amount

    const isExpiredClass = () => {
        const startDateDiff = moment().diff(moment(classDetails?.class_start_date), 'days');
        const endDateDiff = moment().diff(classDetails?.class_end_date ? moment(classDetails?.class_end_date) : moment(), 'days');

        return startDateDiff < 0 || endDateDiff > 0
    }
    const params = useParams();

    const handleDifferanceDate = () => {
        const date1 = new Date();
        const date2 = new Date(classDetails?.class_end_date);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays === 1 ? <span>{diffDays} Day  left!</span>
            : <span>{diffDays} Days  left!</span>
    }
    const [isShowMore, setIsShowMore] = useState(true);
    const toggleReadMore = () => setIsShowMore((show) => !show);
    const isExpiredEvent = () => {
        const startDateDiff = moment().diff(moment(classDetails?.class_start_date), 'days');
        const endDateDiff = moment().diff(classDetails?.class_end_date ? moment(classDetails?.class_end_date) : moment(), 'days');

        return startDateDiff < 0 || endDateDiff > 0
    }

    const getToken = async () => {
        try {
            const subDomain = window.location.hostname.split('.')[0];
            const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
            await AuthService.generateToken(hostName).then(res => {
                localStorage.setItem('schoolToken', res?.token);
                localStorage.setItem('logoImgSchool', res?.logoImg);
                localStorage.setItem('paymentMethodSchool', JSON.stringify(res?.paymentMethod))
                localStorage.setItem('orgNameSchool', res?.orgName);
                localStorage.setItem('country', JSON.stringify(res?.country || {}));
                localStorage.setItem('memberPortal', res?.memberPortal || false);

                setLocalizationMoment(res?.country?.local || "en");
                document.title = `${res?.orgName}`;
            })
        } catch (error) {
            enqueueSnackbar(error?.classDetails?.message, {
                variant: 'error',
            });
        }
    }
    const handleRegistrationButton = async () => {
        if(!classDetails?.allow_non_members_register && !isAuthenticated) return;
        const body = {
            eventId: classDetails?._id,
            numberOfTicket: 1
        }
        setLoading(true)
        try {
            const token = isAuthenticated ? await getAccessTokenSilently() : localStorage.getItem("schoolToken")
            const res = await MemberService.getFormById(token,
                classDetails?.form, localStorage.getItem('orgId')).then((res) => {
                    setLoading(false)
                    history.push(`/school/${params?.id}/registration`);
                })
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
            throw error;
        }

    }
    useEffect(() => {
        if (!localStorage.getItem("eventToken")) getToken()
    }, [])


    const handleReserveLogin = () => {
        if(isAuthenticated)  {
            handleReserveLogout();
            return;
        }
        localStorage.setItem("redirectUrl", `/school/${params?.id}`);
        const originUrl = window.location.origin;
        logout({ returnTo: originUrl });
    }

    const handleReserveLogout =  () => {
     logout({ 
        localOnly: true,
        openUrl: false
      });
    }


    const reserveLoginButton = useMemo(() => isAuthenticated ? "Logout" : classDetails?.allow_non_members_register ? "Log in for members pricing" : "Log in to sign up" , [isAuthenticated, classDetails?.allow_non_members_register]);


    const schoolLogion = useMemo(() => isAuthenticated ? "Logout" : "Login" , [isAuthenticated]);




    return (
        <RightSideWrappr>
            <RightSideContent>
                {memberPortal && (
                    <SchoolLogin>
                        <ReserveLoginButton onClick={handleReserveLogin}>{schoolLogion}</ReserveLoginButton>
                    </SchoolLogin>
                )}
                <EventTitle>
                    <SchoolName>{classDetails?.class_name}</SchoolName>
                    <SchoolCaption>
                        {classDetails?.always_available ? 'Always Available' : <span>Registration ends  {formatDate({ date: classDetails?.class_end_date, formatType: "default" })}</span>}
                        {isExpiredEvent() ? '' : !classDetails?.always_available && <div>{handleDifferanceDate()} </div>}
                        {(!classDetails?.allow_non_members_register && !isAuthenticated) && <div className="members-only-indicator">Members Only</div>}
                        </SchoolCaption>
                </EventTitle>
               
                <ClassBox /><br />
                <Description>
                    <span>Description</span>
                    <DescriptionText>
                        {classDetails?.description?.replace(/&lt;/g, '<') !== '<p></p>\n' && (
                            <div className="drawer_description">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: classDetails?.description?.replace(/&lt;/g, '<'),
                                    }}
                                ></p>
                            </div>
                        )}

                    </DescriptionText>
                </Description>

                <DashLine />
                <PayDetails>
                    {classDetails?.class_pricing
                        ?.prices?.map((item, index) => item?.type === "onRegistration" ? (<>
                            <li key={index}>{item?.title}<span>{numberFormat({ number: +item.amount, decimal: 2, style: "currency" })}</span></li>
                            <Note>{item?.note}</Note>
                            <hr />
                        </>
                        )
                            : <>
                                <li key={index}>{item?.title} {item?.type === 'optional' && `(optional)`}<span>{numberFormat({ number: +item.amount, decimal: 2, style: "currency" })}</span></li>
                                <Note>{item?.note}</Note>
                                <hr />
                            </>
                        )}
                    {classDetails?.class_discount?.map((item, index) => (
                        <>
                            <li 
                                key={index}
                                className={`d-flex flex-column gap-2 ${(item?.forMemberOnly && !isAuthenticated) ? 'opacity-50' : ''}`}
                            >
                                 <div className="d-flex align-items-center justify-content-between">
                                {item?.discount_name}
                                {item?.discount_type === 'date' && ` | ${formatDate({ date: item?.start_date, formatType: "default" })}
                                -
                                ${formatDate({ date: item?.end_date, formatType: "default" })}`}
                                <span>
                                {item?.price_type === 'percentage' ? `${item?.value}%` : `${numberFormat({ number: +item?.value, decimal: 2, style: "currency" })}`}
                                </span>
                                </div>
                                {(item?.forMemberOnly  && !isAuthenticated) && (
                                    <span style={{ fontSize: '12px' }}>
                                        (Members Only)
                                    </span>
                                )}
                            </li>
                            <hr />
                        </>

                    ))}
                    {paymentMethod?.coverConvenienceFee &&
                        calculation.calculateDiscount(classDetails, numberOfTickets, "school",isAuthenticated)?.totalAfterDiscount > 0 && (
                            <>
                                <li>Convenience fee
                                    <span>

                                        {numberFormat({ number: +convenienceFeeAmount, decimal: 2, style: "currency" })}
                                    </span>
                                </li>
                                <hr />
                            </>
                        )}
                    {thousands(calculation.calculateTotalPaymentWithCVFee(paymentMethod, classDetails, numberOfTickets, 'school',isAuthenticated)) !== '0.00' &&
                        <>
                            <TotalPay>TOTAL PAYMENT
                                <span>{numberFormat({ number: calculation.calculateTotalPaymentWithCVFee(paymentMethod, classDetails, numberOfTickets, 'school',isAuthenticated), decimal: 2, style: "currency" })}</span></TotalPay><hr />
                        </>
                    }

                </PayDetails>
                <br />
            </RightSideContent>

            <ReserveAction>
                {memberPortal ? (
                    <ReserveLoginButton onClick={handleReserveLogin}>
                        {reserveLoginButton}
                    </ReserveLoginButton>
                ) : <div></div>}
                <ReserveButton onClick={handleRegistrationButton} 
                    disabledButton={(!classDetails?.allow_non_members_register && !isAuthenticated) || isExpiredClass() || loading}
                >
                    {loading ? <PulseLoader color={'#fff'} size={'7px'} /> : 'Start Registration'}
                </ReserveButton>
            </ReserveAction>
        </RightSideWrappr>
    );
};
