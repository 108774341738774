import React, { memo } from "react";

const styles = {
  body: {},
};
export const IconButton = memo(
  ({ style, loading, className, children, ...rest }) => {
    return (
      <button
        className={`btn p-0 m-0 d-flex align-items-center ${className}`}
        style={{
          ...styles.body,
          ...style,
        }}
        type="button"
        {...rest}
      >
        {children}
      </button>
    );
  }
);
