import Grid from "@mui/material/Grid";
import { LabelField, Text } from "components";
import HebrewDatePicker from "components/DatePicker/HebrewDatePicker";
import { InputField } from "components/InputField/InputField";
import { VirtualKeyboard } from "components/Keyboard";
import MyButton from "components/MyButton";
import SwitchComp from "components/switch";
import { getDateFormat, getDateFormatForDatePicket, maskDate } from "helper/dateFormat.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { DateFormetContext } from "../../../app/App.js";

const DeceacedDetails = ({
  drawerDisplay,
  setOpenDrawer,
  setFieldValue,
  onSubmit,
  ...props
}) => {
  const [openKeyboard, setOpenKeyboard] = useState(false);
  const [date, setDate] = useState("");
  const dateFormet = React.useContext(DateFormetContext);
  const { id } = useParams();
  const [hebrewDate, setHebrewDate] = useState("");
  const formik = { ...props };
  const [prevFormikValues] = useState(formik?.values);

  const dateMask = maskDate();
  const placeholderFormat = getDateFormat();
  const dateFormat = getDateFormatForDatePicket();

  const handleDisabledSaveButton = () => {
    let result;
    if (
      formik.values?.firstName === prevFormikValues?.firstName &&
      formik.values?.lastName === prevFormikValues?.lastName &&
      formik.values?.date_of_death === prevFormikValues?.date_of_death &&
      formik.values?.hebrew_name === prevFormikValues.hebrew_name &&
      formik.values?.before_sunset === prevFormikValues.before_sunset &&
      formik.values?.has_plaque === prevFormikValues.has_plaque &&
      formik.values?.hebrew_date === prevFormikValues.hebrew_date
    ) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  const addDays = (date, days = 1) => {
    if (date) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }
    return "";
  };
  useEffect(() => {
    handleDisabledSaveButton();
  }, [formik.values]);

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
    return date;
  }
  function convertLocalToUTCDate(date, name) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    setFieldValue(name, date);
    return date;
  }
  const HebrewDate = (checkEnabel) => {
    return (
      <HebrewDatePicker
        handleChange={(e) => setHebrewDate(e)}
        date={moment(date)
          .utcOffset("+0100")
          .format(dateFormet)}
        name="hebrew_date"
        checkEnabel={checkEnabel}
        title="Hebrew date of death"
        setHebrewDate={setHebrewDate}
      />
    );
  };
  function contains_heb(str) {
    return /[\u0590-\u05FF]/.test(str.slice(-1));
  }

  useEffect(() => {
    setFieldValue("hebrew_date", hebrewDate);
  }, [hebrewDate]);
  useEffect(() => {
    if (id) {
      setFieldValue("account", id);
    }
  }, [id]);

  useEffect(() => {
    const hebrew =
      props.values.before_sunset == true && props.values.date_of_death
        ? addDays(props.values.date_of_death)
        : props.values.date_of_death;
    setDate(new Date(hebrew).toUTCString());
  }, [props.values.date_of_death]);
  const handleKeyboardChange = (e) => {
    setFieldValue("hebrew_name", e);
  };

  let newDate = moment(newDate).format("YYYY-MM-DD");

  console.log(formik?.values?.date_of_death, date, "test");
  return (
    <div
      className={
        drawerDisplay ? "deceaced-details p-8" : "deceaced-details pt-12"
      }
    >
      {!drawerDisplay && (
        <div className="title">
          <Text className="sub-title">Deceased details</Text>
          <p className="yah-note">Please complete the details below</p>
        </div>
      )}
      <div className={drawerDisplay ? "mb-10" : "my-10"}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="deceased-details">
              <InputField
                Wrapper={{width:'100%'}}
                style={{width:'100%'}}
                styleWrapper={{width:'100%'}}
                placeholder="First Name"
                label="First Name"
                name="firstName"
                isOptional={false}
                type="text"
                formik={formik}
                value={formik.values?.firstName}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                flexDirection="column"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="deceased-details">
              <InputField
               Wrapper={{width:'100%'}}
               style={{width:'100%'}}
               styleWrapper={{width:'100%'}}
                placeholder="Last Name"
                label="Last Name"
                name="lastName"
                isOptional={false}
                type="text"
                formik={formik}
                value={formik.values?.lastName}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                flexDirection="column"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="deceased-details">
              {openKeyboard && (
                <VirtualKeyboard
                  handleChange={handleKeyboardChange}
                  setOpenKeyboard={setOpenKeyboard}
                  value={formik.values?.hebrew_name}
                />
              )}
              <InputField
               Wrapper={{width:'100%'}}
               style={{width:'100%'}}
                setOpenKeyboard={setOpenKeyboard}
                openKeyboard={openKeyboard}
                placeholder="Click on icon to type"
                label="Deceased Hebrew name"
                name="hebrew_name"
                isOptional={false}
                type="text"
                formik={formik}
                styleWrapper={{ position: "relative",width:'100%'}}
                value={formik.values?.hebrew_name}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                flexDirection="column"
                onChange={(e) => {
                  let result = contains_heb(e.target.value);
                  setFieldValue("hebrew_name", e.target.value);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="deceased-date">
              <DatePicker
                showIcon
                placeholderText={placeholderFormat}
                dateFormat={dateFormat}
                maxDate={new Date()}
                customInput={
                  <InputField
                  Wrapper={{width:'100%'}}
                  style={{width:'100%'}}
                    label="Date of death"
                    name="date"
                    isDate={true}
                    isOptional={false}
                    type="text"
                    formik={formik}
                    styleWrapper={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                      
                    }}
                    flexDirection="column"
                  />
                }
                selected={
                  formik?.values?.date_of_death &&
                  convertUTCToLocalDate(formik?.values?.date_of_death)
                }
                onChange={(e) => {
                  setFieldValue(`date_of_death`, e);
                  convertLocalToUTCDate(e, `date_of_death`);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="deceased-details">
              <SwitchComp
                checked={props.values.before_sunset}
                handleChange={() => {
                  setFieldValue("before_sunset", !props.values.before_sunset);
                  props.setFieldTouched("before_sunset", true);
                  let update = new Date(date);
                  if (!props.values.before_sunset == true) {
                    update.setDate(update.getDate() + 1);
                    setDate(update);
                  }
                  if (!props.values.before_sunset == false) {
                    update.setDate(update.getDate() - 1);
                    setDate(update);
                  }
                }}
                className="mr-0"
              />
              <LabelField
                title="After sunset"
                style={{ alignSelf: "center", marginRight: "15px" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div
              className={`hebDate`}
            >
              <HebrewDate />
            </div>
          </Grid>
        </Grid>
        {drawerDisplay && (
          <div className="mt-4 btn-flex">
            <MyButton
              type={"primary"}
              content={"Save"}
              onClick={onSubmit}
              disabled={handleDisabledSaveButton()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeceacedDetails;
