import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logoImage: "",
};


export const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        setLogoImage: (state, action) => {
            state.logoImage = action.payload
        }
    },
});

export const { setLogoImage } = organizationSlice.actions

export default organizationSlice.reducer;
