import * as React from "react";

function DeleteSvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.225}
      height={18.225}
      {...props}
    >
      <g
        data-name="Icon"
        fill="none"
        stroke="#f1585e"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.278 5.309h13.668" />
        <path
          data-name="Path"
          d="M14.43 5.305V15.94a1.518 1.518 0 01-1.518 1.518H5.315a1.518 1.518 0 01-1.518-1.518V5.305m2.278 0V3.79a1.518 1.518 0 011.518-1.518h3.037a1.518 1.518 0 011.518 1.518v1.515"
        />
        <path d="M7.594 9.106v4.556" />
        <path data-name="Line" d="M10.631 9.106v4.556" />
      </g>
    </svg>
  );
}

export default DeleteSvgComponent;
