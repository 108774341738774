import styled from "styled-components"

export const Wrapper = styled.div`
width: 100%;
height:  100%;
background-color: blue;
background-color: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: #002E5C;
`
export const ThanksContent = styled.div`
background-color: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top:3%;

@media only screen and (min-width: 1920px) and (min-height: 1081px) {
    margin-top: 182.05px;
  }
`
export const ThanksImage = styled.img`
margin-bottom: 75px;
`
export const ThanksTitle = styled.div`
margin-bottom: 25px;
font-weight: 600;
font-size: 36px;
line-height: 54px;
`
export const ThanksDescription = styled.div`
font-weight: 400;
font-size: 16px;
line-height: 26px;
width: 454px;
height: 53px;
text-align: center;
margin-bottom: 139px;
@media only screen and (min-width: 1920px) and (min-height: 1081px) {
    margin-bottom: 139px;
    
  }
`
export const ThanksButtonGroups = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-bottom:3%;

@media only screen and (min-width: 1920px) and (min-height: 1081px) {
    margin-bottom: 282px;
  }


`
export const RegisterButton = styled.button`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 20px;
gap: 8px;
width: 185px;
height: 48px;
background: #2555EF;
border-radius: 5px;
border: none;
color: #ffff;
margin-bottom: 13px;

`
export const DontReciveButton = styled.button`
font-weight: 500;
font-size: 13px;
line-height: 32px;
display: flex;
align-items: center;
color: #2555EF;
border: none;
background-color: transparent;
text-decoration: underline;
`