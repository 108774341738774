import { useAuth0 } from '@auth0/auth0-react';
import AuthService from 'Lib/API/Auth';
import { useFormik } from 'formik';
import { setLocalizationMoment } from 'helper/dateFormat';
import { SubHeader } from 'layout/SubHeader';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import FinanceService from '../../Lib/API/Finance';
import DonationCause from './DonationCause/DonationCause';
import DonationDetails from './DonationDetails/DonationDetails';
import DonationPay from './DonationPay/DonationPay';
import PageNotFound from './PageNotFound';
import './style.scss';

const ExternalLinkDonation = ({external}) => {
  
  const {
    getAccessTokenSilently,isAuthenticated,logout
  } = useAuth0();

  const [ledgers, setLedgers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [convenienceFeeInfo, setConvenienceFeeInfo] = useState({});
  const [coverConvenienceFee, setCoverConvenienceFee] = useState(false);
  const [enableCoverConvenienceFee, setEnableCoverConvenienceFee] = useState(false);
  const [enableBillMyAccount, setEnableBillMyAccount] = useState(false);
  const [billMyAccount, setBillMyAccount] = useState(false);
  const { push } = useHistory();
  let { url } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = window.location;


  const memberPortal = localStorage.getItem('memberPortal') === 'true' || false;



  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: false,
    initialValues: {
      showAcknowledgement: false,
      acknowledgements: [{
        recipient_name: '',
        email: '',
      }],
    },
    validationSchema: '',
    onSubmit: async (values) => { },
  });

  useEffect(() => {
    formik.setFieldValue('resultPage', '');
    // dispatch(setIsDonationLayout(true));
    localStorage.setItem('DonationLayout', true);
    document.body.style.backgroundColor = '#fff';
    document.body.style.overflowX = 'hidden';
    localStorage.removeItem('redirectUrl');
    if (external) {
      const generateTokenAndGetLedgers = async () => {
        const subDomain = window.location.hostname.split('.')[0];
        const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
        setLoading(true);
        try {
          const res = await AuthService.generateToken(hostName);
          if (!res.hasPayment) {
            window.location.replace(res?.shul_website);
            return;
          }
          const organization = {
            provider_information: {
              provider: res?.provider,
              keys: {
                ...(res?.publicKey ? {publicKey: res?.publicKey} : {}) 
              }
            }
          }
          setCoverConvenienceFee(res?.paymentMethod?.coverConvenienceFee || false);
          setEnableCoverConvenienceFee(res?.paymentMethod?.coverConvenienceFee || false);
          setConvenienceFeeInfo(res?.paymentMethod?.convenienceFeeInfo);
          setEnableBillMyAccount(res?.allowBillMyAccountOnDonation || false);
          localStorage.setItem('externalToken', res?.token);
          localStorage.setItem('orgId', res?._id);
          localStorage.setItem('shul_website', res?.shul_website);
          localStorage.setItem('hasPayment', res?.hasPayment);
          localStorage.setItem('logoImg', res?.logoImg);
          localStorage.setItem('orgName', res?.orgName);
          document.title = `${res?.orgName}`;
          localStorage.setItem('country', JSON.stringify(res?.country || {}));
          localStorage.setItem('memberPortal', res?.memberPortal || false);


          setLocalizationMoment(res?.country?.local || "en");

          localStorage.setItem("organization",JSON.stringify(organization));

          const led = await FinanceService.getLedgersForDonations(
            res?.token,
            res?._id
          );
          const LedgerWithSubLedger = []

          if (led?.ledgers) {
            const ledAndSub = led?.ledgers?.map(item => {
              if(item?.donation) {
                LedgerWithSubLedger.push(item)
              }
              const subLedger = item?.sub_ledgers?.map((sub) => {
                LedgerWithSubLedger.push({
                  name: item?.ledger_name, ledger_id: item?._id, ledger_account_receivable: item?.account_receivable,
                  sub_ledger_name: sub?.sub_ledger_name, sub_ledger_id: sub?._id, sub_ledger_amount_detuctible: sub?.amount_detuctible, default_amount: sub?.default_amount,
                  sub_account_receivable: sub?.account_receivable
                })
              })

            })
            setLedgers(LedgerWithSubLedger);
          }
        } catch (error) {
          enqueueSnackbar(error?.data?.message, {
            variant: 'error',
          });
        } finally {
          setLoading(false);
        }
      };
      generateTokenAndGetLedgers();
    } else {
      if (localStorage.getItem('hasPayment') === 'false') {
        push('/family-member-table');
      }

      const getLedgeraccounts = async () => {
        const token = await getAccessTokenSilently();
        setLoading(true);
        await FinanceService.getLedgerAccountsForDonation(
          'donation',
          token
        )
          .then((res) => {
            if (res?.ledgers) {
              setLedgers(res?.ledgers);
            }
          })
          .catch((err) => {
            enqueueSnackbar(err?.data?.message, {
              variant: 'error',
            });
          });
        setLoading(false);
      };
      getLedgeraccounts();
    }
    if (localStorage.getItem('donaitonFormik')) {
      formik.setValues(JSON.parse(localStorage.getItem('donaitonFormik')));
      localStorage.removeItem('donaitonFormik');
    }
    if (!external) {
      if (localStorage.getItem('accountInfo')) {
        formik.setFieldValue(
          'first_name',
          JSON.parse(localStorage.getItem('accountInfo'))?.firstName
        );
        formik.setFieldValue(
          'last_name',
          JSON.parse(localStorage.getItem('accountInfo'))?.lastName
        );
        formik.setFieldValue(
          'receipt_email',
          JSON.parse(localStorage.getItem('accountInfo'))?.primary_email_address
        );
      }

      if(localStorage.getItem("allowBillMyAccountOnDonation")) {
        const allowBillMyAccountOnDonation = JSON.parse(localStorage.getItem("allowBillMyAccountOnDonation"));
        setEnableBillMyAccount(allowBillMyAccountOnDonation || false);
      }
      
    }
    if(localStorage.getItem("paymentMethod")) {
      const paymentMethod = JSON.parse(localStorage.getItem("paymentMethod"));
      setCoverConvenienceFee(paymentMethod?.coverConvenienceFee || false);
      setEnableCoverConvenienceFee(paymentMethod?.coverConvenienceFee || false);
      setConvenienceFeeInfo(paymentMethod?.convenienceFeeInfo);
    }
 
  }, []);

  const handleLogout = () => {
    localStorage.setItem('donationLogout', true);
    localStorage.removeItem('token');
    localStorage.removeItem('externalToken');
    localStorage.removeItem('orgId');
    localStorage.removeItem('donaitonFormik');
    localStorage.removeItem('DonationLayout');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('hasPayment');
    localStorage.removeItem('logoImg');
    if (
      localStorage.getItem('donationLogout') &&
      localStorage.getItem('donationLogout') === 'true'
    ) {
      push('/check-valid-user');
    }
  };

  useEffect(() => {
      localStorage.removeItem('redirectUrl');
  }, [])


  const handleReserveLogin = () => {
        if(isAuthenticated)  {
            push("/donation-view");
            return;
        }
        localStorage.setItem("redirectUrl", `/donation-view`);
        const originUrl = window.location.origin;
        logout({ returnTo: originUrl });
    }

  return (
    <div
      className="p-5"
      style={{
        width: '100%',
      }}
    >
    {external &&localStorage.getItem('logoImg') && (
        <div className="donation__header d-flex justify-content-between align-items-center">
          <div>
              <img
                src={localStorage.getItem('logoImg')}
                alt="logo"
                style={{
                  height: '50px',
                  width: '130px',
                  objectFit: 'fill',
                }}
              />
          </div>
          {memberPortal && (
            <div>
                <button className='donation-login' onClick={handleReserveLogin}>{"Login"}</button>
            </div>
          )}

        </div>
      )}
      <div className='borderBottom m-0 p-5'>
          <SubHeader
              className="class-tab subheader"
              {...{
                title: "Make a Donation",
              }}
          />
        </div>
        <div className={`container_border ${!external ? "pt-10 pb-10" : ""}`}>
        {external  && <div className="makeDonation">Make a Donation</div>}
        <div>
          <Switch>
            <Route exact path={url}>
              <DonationCause
                formik={formik}
                ledgers={ledgers}
                setLedgers={setLedgers}
                loading={loading}
                external={external}
                setBillMyAccount={setBillMyAccount}
              />
            </Route>

            <Route exact path={`${url}/details`}>
              <DonationDetails formik={formik} 
                external={external}
              />
            </Route>

            <Route exact path={`${url}/pay`}>
              <DonationPay formik={formik} 
                convenienceFeeInfo={convenienceFeeInfo}
                coverConvenienceFee={coverConvenienceFee}
                enableCoverConvenienceFee={enableCoverConvenienceFee}
                enableBillMyAccount={enableBillMyAccount}
                setEnableCoverConvenienceFee={setEnableCoverConvenienceFee}
                external={external}
                setBillMyAccount={setBillMyAccount}
                billMyAccount={billMyAccount}
              />
            </Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default ExternalLinkDonation;
