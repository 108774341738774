import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import React from "react";
import "./index.scss";
const SwitchComp = ({ checked, handleChange, className }) => {
  return (
    <FormControlLabel
      className={className ? className : ""}
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      }
    />
  );
};
export default SwitchComp;
