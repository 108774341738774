import React from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";

const Body = styled.div`
  width: 201px;
  height: 44px;
  background: #f3f6f9;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 20px 20px;
`;

const SearchBody = styled.div`
  padding-inline-end: 10px;
`;
export default function SearchBar({ value, setValue }) {
  return (
    <Body>
      <SearchBody>
        <SearchIcon style={{ color: "#b5b5c3" }} />
      </SearchBody>
      <div className="search-input">
        <InputBase
          value={value}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          onChange={(event) => {
            setValue(event.target.value);
          }}
        />
      </div>
    </Body>
  );
}
