import styled from 'styled-components'

export const Wrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;

`
export const CardContent = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height:${(props) => (props.isMobile ? '90%' : '80%')};
width:${(props) => (props.isMobile ? '95%' : '670px')};
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
color: var(--dark-blue, #002E5C);
background-color: #fff;
border-radius: 10px;
div{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

}

p{
text-align: center;
font-size: 16px;
font-weight: 400;
line-height: 26.348px; 
width: 83%;
}
`
export const Title = styled.h1`
font-size: ${(props) => (props.isMobile ? '24px' : '36px')};
font-weight: 600;
padding-bottom: 22px;
padding-top: ${(props) => (props.isMobile ? '20px' : '45px')};
`

export const ThanksMsg = styled.div`
font-size: 18px;
font-weight: 600;
line-height: 26.348px; 
width: 83%;
text-align: center;
margin-top: 20px;
`


export const ButtonWrapper = styled.div`
color: var(--White-100, #FFF);
display: flex;
justify-content: center;
align-items: center;

font-size: 13px;
font-weight: 500;
margin-top: 120px;
button{
    border: 0;
    width: 185px;
    height: 48px;
}
button:nth-child(1){
    display: flex;
    width: 185px;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    background: rgba(37, 85, 239, 1);
    color: var(--White-100, #FFF);
    margin-bottom: 10px;
 
}
button:nth-child(2){
    color: var(--blue, #2555EF);
    line-height: 32px;
    background-color: transparent;
   
}
`