import cuid from "cuid";
import { useMobileView } from 'hook/mobileView/useMobileView';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCounterTicket } from 'services/api/events';
import { LeftSide } from './LeftSide';
import { RightSide } from './RightSide';
import { Body } from './Theme';

export const EventInfo = () => {
    const { isMobile } = useMobileView()
    const { eventData } = useSelector((state) => state?.Events)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(setCounterTicket([]));
        localStorage.setItem("registrationForm",JSON.stringify([]))
        localStorage.setItem("ApplayCheckBox",JSON.stringify(false))
    },[])
    
    return (
        <Body>
            {Object.keys(eventData).length !== 0 &&  
            <>
            {!isMobile && <LeftSide/>}
            <RightSide />
            </>}
           
        </Body>
    );
};

