import styled from "styled-components";

export const PaymentCardWrapper = styled.div`
padding:28px 33px 41px 33px;
height: 100%;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
`

export const PaymentCardTitle = styled.span`
color: var(--Dark-Blue-100, #002E5C);
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px;
padding-bottom: 8px;
display: flex;
justify-content: flex-start;
align-items: center;
border-bottom: 1px dashed rgba(0, 46, 92, 0.10);
width: 100%;
margin-bottom: 24px;
span{
padding-right: 8px;
}
`
export const PaymentButton = styled.div`
height: 44px;
button{
display: flex;
border-radius: 5px;
justify-content: center;
align-items: center;
background:${(props) => (props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : 'var(--blues-blue-main, #2555EF)')};
padding: 8px 20px;
width:100% ;
height: 100%;
border: 0;
color: ${(props) => (props.isDisabled ? 'var(--Gray-100, #B5B5C3)' : 'var(--White, var(--White-100, #FFF))')};
font-size: 14px;
font-weight: 700;
line-height: 32px; 
:hover{
    background:${(props) => (props.isDisabled ? 'var(--Gray-20, #F0F0F3)' : '#214DD7')};
}
}


`