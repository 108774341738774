import React, { memo } from "react";

const styles = {
  body: {
    fontSize: 14,
    textAlign: "start",
  },
};

export const LabelField = memo(
  ({
    className,
    title,
    htmlFor,
    style,
    mandatory = false,
    children,
    labelClassName,
    ...rest
  }) => {
    return (
      <div
        className={`d-felx justify-content-start text-truncate mb-1 ${className}`}
        style={{ ...style }}
      >
        <label
          style={{ ...styles.body, fontWeight: "500" }}
          {...{ htmlFor }}
          {...rest}
          className={labelClassName}
        >
          {title || children}
        </label>
        {mandatory && (
          <label
            style={{ ...styles.body, fontWeight: "500", color: "red" }}
            {...{ htmlFor }}
            {...rest}
          >
            {" *"}
          </label>
        )}
      </div>
    );
  }
);
