import React from 'react';
import { useHistory, history } from 'react-router-dom';
import { Button } from '@mui/material';
import { ArrowBack } from '@material-ui/icons';

const PageNotFound = () => {
  const { push } = useHistory();

  return (
    <div className="page__not__found donation__content">
      <div className="page__not__found__content text-center">
        <h1 text-center>404</h1>
        <h2 text-center>Page Not Found</h2>
        <h3 className="mb-5">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </h3>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBack />}
          sx={{ backgroundColor: '#2555ef', marginTop: '20px' }}
          onClick={() => push('/external-link-donation')}
        >
          Back to Home
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
 