import React, { useEffect, useState, useRef } from "react";
import { ReactJewishDatePicker } from "react-jewish-datepicker";
import "./HebrewDatePicker.scss";
import { LabelField } from "..";

const HebrewDatePicker = ({ title, name, date, ...props }) => {
  const hebrewDate = useRef(null);
  console.log("date", date);
  useEffect(() => {
    const element = hebrewDate.current.children[1].children[0].textContent;
    props.handleChange(element);
  }, []);
  return (
    <>
      <div className="customHebrewDate" ref={hebrewDate}>
        <label className="label-style">{title}</label>
        <ReactJewishDatePicker
          value={date ? new Date(date) : new Date()}
          // isHebrew
          tite={title}
          name={name}
          className="hebrew-date-picker hebrew-date-of-dath"
          {...props}
        />
      </div>
    </>
  );
};

export default HebrewDatePicker;
