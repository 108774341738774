import moment from "moment/min/moment-with-locales";


const getCurrentCountry = () => {
  let country = JSON.parse(
    localStorage.getItem("country") !== "undefined"
    ? localStorage.getItem("country")
    : "{}"
  );
  return country;
}

export const setLocalizationMoment = (local = "en") => {
	moment.locale(local);
};

export const getDateFormat = () => {
  return moment.localeData().longDateFormat("L") || "MM/DD/YYYY"
}


export const getDateTimeFormat = () => {
  return `${moment.localeData().longDateFormat("L")} ${moment.localeData().longDateFormat("LT")}` || "MM/DD/YYYY hh:mm a"
}


export const getDateFormatForDatePicket = () => {
  const dataFormat = getDateFormat();
  return dataFormat.replace(/Y/g, "y").replace(/D/g, "d");
}

export const getDateTimeFormatForDatePicket = () => {
  const dataTimeFormat = getDateTimeFormat();
  if(dataTimeFormat.match(/h/g)?.length === 1) {
    return dataTimeFormat.replace(/Y/g, "y").replace(/D/g, "d").replace(/h/g, "hh").replace(/A/g, "a");
  }
  return dataTimeFormat.replace(/Y/g, "y").replace(/D/g, "d").replace(/A/g, "a");
}

export const formatDate = ({date, formatType = "default"}) => {
  if(!date) return "";
	switch (formatType) {
		case "default":
			return moment(date).format("L");
    case "defaultWithUTC":
      return moment.utc(date).format("L");
    case "defaultWithTime":
      return `${moment(date).format("L")} ${moment(date).format("LT")}`;
    case "defaultWithTimeUTC":
      return `${moment.utc(date).format("L")} ${moment.utc(date).format("LT")}`;
    case "time":
      return moment(date).format("LT");
    case "timeUTC":
      return moment.utc(date).format("LT");
		default:
			return moment(date).format("L");
	}
};



export const maskDate = () => {
  const dateFormate = getDateFormat();
  const mask = dateFormate.split("").map((item) => {
    const itemChar = item.toLowerCase();
    if (itemChar === "m") {
      return /\d/;
    } else if (itemChar === "d") {
      return /\d/;
    } else if (itemChar === "y") {
      return /\d/;
    } else {
      return item;
    }
  });
  return mask;
}

export const maskDateTime = () => {
  let dateTimeFormate = getDateTimeFormat()?.toLowerCase();

  if(dateTimeFormate?.match(/h/g)?.length === 1) {
    dateTimeFormate = dateTimeFormate.replace(/h/g, "hh");
  } else if(dateTimeFormate?.match(/H/g)?.length === 1) {
    dateTimeFormate = dateTimeFormate.replace(/H/g, "hh");
  }


  if(dateTimeFormate?.match(/a/g)) {
    dateTimeFormate = dateTimeFormate.replace(/a/g, "at");
  } else if(dateTimeFormate?.match(/A/g)) {
    dateTimeFormate = dateTimeFormate.replace(/A/g, "AT");
  }

  const mask = dateTimeFormate.split("").map((item) => {
    const itemChar = item.toLowerCase();
    if (itemChar === "m") {
      return /\d/;
    } else if (itemChar === "d") {
      return /\d/;
    } else if (itemChar === "y") {
      return /\d/;
    } else if (itemChar === "h") {
      return /\d/;
    } else if (itemChar === "m") {
      return /\d/;
    }  else if (itemChar === "a") {
      return /a|p/i;
    } else if (itemChar === "t") {
      return /m/i;
    } else {
      return item;
    }
  });
  return mask;
}