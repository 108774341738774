import validator from 'validator';

export const postalCodeFormat = (postalCode) => {
  let country = JSON.parse(
    localStorage.getItem("country") !== "undefined"
    ? localStorage.getItem("country")
    : "{}"
  );
  const code = country?.code || 'US';
  return validator.isPostalCode(postalCode ?? "", code);
}

export const postalCodeLabel = () => {
  let country = JSON.parse(
    localStorage.getItem("country") !== "undefined"
    ? localStorage.getItem("country")
    : "{}"
  );
  const postalLabel = country?.postalLabel || 'Zip Code';
  return postalLabel;
}


export const stateLabel = () => {
  let country = JSON.parse(
    localStorage.getItem("country") !== "undefined"
    ? localStorage.getItem("country")
    : "{}"
  );
  const state = country?.stateLabel || 'State';
  return state;
}