import Drawer from "@material-ui/core/Drawer";
import React from "react";

const RightDrawer = ({ open, onClose, children, id }) => {
  return (
    <div>
      <Drawer
        style={{ background: "rgba(0,0,0,0.5)" }}
        anchor={"right"}
        open={open}
        onClose={onClose}
      >
        {children}
      </Drawer>
    </div>
  );
};

export default RightDrawer;
