
export const convertUTCToLocalDate = (date) => {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
    );
    return date;
}
export const convertLocalToUTCDate = (date, name, formik) => {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    formik.setFieldValue(name, date);
    return date;
}