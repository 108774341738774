import React, { useEffect } from "react";
 import {CounterContent,CounterValue,IncrementButton,DecrementButton} from './Theme'
export const Counter = ({width,handleClickIncrement,handleClickDecrement,counter,disabled}) => {
 
    return (
        <CounterContent width={width}>
                <DecrementButton onClick={handleClickDecrement}>-</DecrementButton>
                <CounterValue>
                    {counter}
                </CounterValue>
                <IncrementButton onClick={()=>!disabled && handleClickIncrement()} disabled={disabled}>
                    +
                </IncrementButton>
        </CounterContent>
    );
};
