import KeyboardIcon from "@material-ui/icons/Keyboard";
import { ReactComponent as DateIconUnActive } from "assets/icons/CustomDateIconGray.svg";
import React, { memo } from "react";
import { CustomIcon, Error, Input, InputWrapper, Label } from "./Theme";

export const InputField = memo(
  ({
    type,
    placeholder,
    label,
    isOptional,
    formik,
    message,
    ref,
    isDisabled,
    style,
    styleWrapper,
    labelStyle,
    error,
    flexDirection,
    Wrapper,
    name,
    setOpenKeyboard,
    openKeyboard,
    isDate,
    handleChange,
    isRequired,
    ...rest
  }) => {
    const isName = name && formik?.getFieldProps(name);
    return (
      <InputWrapper style={Wrapper}>
        <Label style={labelStyle}>
          <span>{label}</span>
          {isOptional && <span>{`(optional)`}</span>}
          {isRequired && 
          <label style={{ fontWeight: "500", color: "red" }} >
            {" *"}
          </label> }
        </Label>
        <div style={styleWrapper}>
          <Input
            ref={ref}
            type={type}
            placeholder={placeholder}
            error={error}
            {...rest}
            disabled={isDisabled}
            style={style}
            isDisabled={isDisabled}
            onChange={handleChange}
            {...isName}
          />
          {/* If we wanna open the windows keybord */}
          {name === "hebrew_name" && (
            <KeyboardIcon
              fontSize="large"
              style={{
                cursor: "pointer",
                alignSelf: "center",
                marginRight: "5px",
                position: "absolute",
                right: "2%",
                top: "26%",
              }}
              onClick={() => {
                setOpenKeyboard(!openKeyboard);
              }}
            />
          )}
          {isDate && (
            <CustomIcon role="img" aria-label="calendar">
              <DateIconUnActive />
            </CustomIcon>
          )}

          {error && <Error>{message}</Error>}
        </div>
      </InputWrapper>
    );
  }
);
