import LocationSvg from "assets/icons/svg/localtion";
import React from "react";
import { Col, Row } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "./index.scss";
const FindMyCity = ({ rowTitle, title, value, handleChange }) => {
  const DropdownIndicator = (props) => {
    return (
      <div className="px-2">
        <LocationSvg label="Emoji" />
      </div>
    );
  };
  return (
    <div className="map-title">
      <div style={{ fontSize: "14px", marginBottom: "10px" }}>{title}</div>
      <div >
        <Row >
          {rowTitle && (
            <Col xs={4}>
              <div className="container-title">{rowTitle}</div>
            </Col>
          )}
          <Col>
            <GooglePlacesAutocomplete
              className="d-flex form-control form-control-solid px-6 pt-8 pb-9 align-items-center"
              apiKey={"AIzaSyCAt0TjvU_m8ufUMYn2EXi9mteHSOUB7uA"}
              
              selectProps={{
                value,
                onChange: handleChange,
                components: {
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                },
                placeholder: "Enter Address",
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["us"],
                },
              }}
              apiOptions={{ language: "en", region: "us" }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FindMyCity;
