import React from "react";
import Cropper from "react-easy-crop";
import { Button } from "./Button";
import "./style.scss";
const ImageCropper = ({
  image,
  crop,
  rotation,
  aspect,
  onCropChange,
  onCropComplete,
  onZoomChange,
  zoom,
  onRotationChange,
  title,
  closeAllModal,
}) => {
  return (
    <div style={{ backgroundColor: "white" }} className="common-image-cropper">
      {title && <h1>{title}</h1>}
      <div className="cropper">
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          aspect={aspect}
          zoom={zoom}
          onRotationChange={onRotationChange}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
        />
      </div>
      <div className="cropper-img-save-btn">
        <label className="cropper-message">
          {"Zoom & Drag to reposition your photo"}
        </label>
        <Button onClick={closeAllModal}>Save</Button>
        <button
          onClick={closeAllModal}
          type="button"
          className={`btn btn-transparent text-primary px-2 py-4 w-100`}
        >
          <label>Cancel</label>
        </button>
      </div>
    </div>
  );
};
export default ImageCropper;
