import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import AuthService from 'Lib/API/Auth';
import Finance from 'Lib/API/Finance';
import { ContactUs } from "components/ContactUs/ContactUs";
import { useFormik } from 'formik';
import { setLocalizationMoment } from 'helper/dateFormat';
import { postalCodeFormat, postalCodeLabel } from 'helper/postalCodeFormat';
import { useMobileView } from 'hook/mobileView/useMobileView';
import { useSnackbar } from 'notistack';
import { MemberPersonalDetailsSchema } from 'pages/ValidationSchema/index';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from "react-router";
import Header from '../Header/Header';
import { ChargeTable } from './ChargeTableToPay/ChargeTable';
import { PaymentCard } from './PaymentCard/PaymentCard';
import { PaymentResultPage } from './PaymentResultPage/PaymentResultPage';
import { PersonalDetailsCard } from './PersonalDetailsCard/PersonalDetailsCard';
import { SummaryCard } from './SummaryCard/SummaryCard';
import { CardContent, ImageCard, ImageContainer, Wrapper } from './Theme';

export const ChargeExternalLink = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [isLoadingPay, setLoadingPay] = useState(false);
    const [checked, setChecked] = useState([]);
    const [chargeSelections, setChargeSelections] = useState([]);
    const [TotalForPay, setTotalForPay] = useState(0)
    const [dataForPay, setDataForPay] = useState([])
    const queryParameters = new URLSearchParams(window.location.search)
    const participantId = queryParameters.get("participantId")
    const chargeId = queryParameters.get("chargeId")
    const accountId = queryParameters.get("accountId")
    const [error, setError] = useState({ value: true, message: '' })
    const [convenienceFeeInfo, setConvenienceFeeInfo] = useState(null);
    const [coverConvenienceFee, setCoverConvenienceFee] = useState(false);
    const [editedTotalPayment, setEditedTotalPayment] = useState(0);
    const { isMobile } = useMobileView()
    const history = useHistory();
    const [allowEditTotalPayment, setAllowEditTotalPayment] = useState(false);
    const Logo = localStorage.getItem('logoImgSchool');


    const postalLabel = postalCodeLabel();


    const { provider_information } = localStorage.getItem("organization")
        ? JSON.parse(localStorage.getItem("organization"))
        : {};
    const { provider } = provider_information || {};
    const { publicKey } = provider_information?.keys || {};

    const formik = useFormik({
        isInitialValid: false,
        enableReinitialize: true,
        initialValues: {
            accountInfo: {

            },
            cardsDetails: {

            },
            resultPage: '',
            totalPayment: 0
        },
        validationSchema: MemberPersonalDetailsSchema,
        onSubmit: async (values) => { console.log("values", values); },
    });


    const handleCheck = useCallback((event, value, amount, item) => {
        let updatedListItem = [...chargeSelections];
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, value];
            updatedListItem = [...chargeSelections, item]
            setTotalForPay(TotalForPay + amount)
        } else {
            updatedList = updatedList?.filter(item => item !== value)
            updatedListItem = updatedListItem?.filter(val => val.chargeId !== item.chargeId && item)
            setTotalForPay(TotalForPay - amount)
        }
        setChecked(updatedList);
        setChargeSelections(updatedListItem)


    }, [chargeSelections, checked, TotalForPay]);
    const handleCheckAll = useCallback((data) => {
        if (data?.length > 0) {
            const updatedList = data?.map(val => val.chargeId)
            setChecked(updatedList);
            const updatedListItem = [...data]
            setChargeSelections(updatedListItem)
            const total = data?.map((val) => val.amount)
            const sum = total.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setTotalForPay(sum)
        } else {
            setChecked([])
            setChargeSelections([])
            setTotalForPay(0)

        }
    }, []);

    const getChargeInformation = useCallback(async (externalToken, orgID) => {
        const key = chargeId !== null ? 'chargeId' : 'participantId'
        const value = chargeId !== null ? chargeId : participantId
        try {
            const res = await Finance.getChargeInfo(orgID, accountId, key, value, externalToken)
            setDataForPay(res)
            setLoading(false);
        } catch (error) {
            if (error?.data?.message === "There is no payment needed now please contact admin") {
                setDataForPay([])
                setTotalForPay(0)
            } else {
                history.push(localStorage.getItem('shul_website'))
            }
        } finally {
            setLoading(false);
        }
    }, [chargeId, participantId, accountId, history]);


    const PayNow = useCallback(async (tokenId = "") => {
        const validZipCode = postalCodeFormat(formik?.values?.cardsDetails?.zip);
        if (!tokenId && !validZipCode) {
            enqueueSnackbar(`Invalid ${postalLabel}`, { variant: "info" });
        }
        const data = {
            cardsDetails: !tokenId ? {
                object: "card",
                number: formik?.values?.cardsDetails?.card_number,
                exp_month: formik?.values?.cardsDetails?.expiry?.split('/')[0],
                exp_year: formik?.values?.cardsDetails?.expiry?.split('/')[1],
                cvc: formik?.values?.cardsDetails?.cvc,
                zip: formik?.values?.cardsDetails?.zip,
            } : undefined,
            token: tokenId ? tokenId : undefined,
            personalDetails: {
                firstName: formik?.values?.accountInfo?.first_name,
                lastName: formik?.values?.accountInfo?.last_name,
                email: formik?.values?.accountInfo?.email,
            },
            charges:
                checked
            ,
            accountId: accountId,
            totalPaid: editedTotalPayment ? +formik?.values?.totalPayment : undefined,
        }

        if (TotalForPay > 0) {
            if (error?.message) {
                enqueueSnackbar(error?.message, {
                    variant: 'error',
                });
            } else {
                setLoadingPay(true);
                try {
                    await Finance.payExternalLink(data, localStorage.getItem('externalToken'), localStorage.getItem('orgId')).then(res => {
                        formik.setFieldValue('resultPage', 'success');
                        formik.setFieldValue('email', formik?.values?.accountInfo?.email);
                        setTotalForPay(0)
                        setChecked([])
                        formik.setFieldValue('accountInfo', {});
                        formik.setFieldValue('cardsDetails', {});
                        getChargeInformation(localStorage.getItem('externalToken'), localStorage.getItem('orgId'))
                    })

                } catch (error) {
                    formik.setFieldValue('resultPage', 'failed');
                    formik.setFieldValue('email', '');
                } finally {
                    setLoadingPay(false);
                }
            }
        }
        else {
            enqueueSnackbar("There is no payment to pay", {
                variant: 'error',
            });
        }
    }, [checked, TotalForPay, error, formik, enqueueSnackbar, getChargeInformation, editedTotalPayment, accountId]);
    const generateToken = useCallback(async () => {
        setLoading(true);
        const subDomain = window.location.hostname.split('.')[0];
        const hostName = subDomain === 'localhost' ? 'shalom' : subDomain
        try {
            const res = await AuthService.generateToken(hostName);
            setCoverConvenienceFee(res?.paymentMethod?.coverConvenienceFee || false);
            setConvenienceFeeInfo(res?.paymentMethod?.convenienceFeeInfo);
            setAllowEditTotalPayment(res?.allowEditOnExternal);
            localStorage.setItem('externalToken', res?.token);
            localStorage.setItem('shul_website', res?.shul_website);
            localStorage.setItem('email', res?.contactInfo?.email);
            localStorage.setItem('phone', res?.contactInfo?.phone);
            localStorage.setItem('orgId', res?._id);
            localStorage.setItem('orgName', res?.orgName);
            document.title = `${res?.orgName}`;
            localStorage.setItem('logoImgSchool', res?.logoImg);
            getChargeInformation(res?.token, res?._id);
            localStorage.setItem('country', JSON.stringify(res?.country || {}));
            const organization = {
                provider_information: {
                    provider: res?.provider,
                    keys: {
                        ...(res?.publicKey ? { publicKey: res?.publicKey } : {})
                    }
                }
            }
            localStorage.setItem("organization", JSON.stringify(organization));

            setLocalizationMoment(res?.country?.local || "en");

        } catch (error) {
            enqueueSnackbar(error?.data?.message, {
                variant: 'error',
            });
        }
    }, [enqueueSnackbar, getChargeInformation]);

    useEffect(() => {
        generateToken();
    }, []);


    const calculateConvenienceFeeAmount = useCallback((total) => {
        const convenienceFeeAmount = convenienceFeeInfo?.type === "%" ? (total * convenienceFeeInfo?.amount) / 100 : convenienceFeeInfo?.amount
        return convenienceFeeAmount;
    }, [convenienceFeeInfo]);


    const reverseCalculationConvenienceFee = useCallback((value) => {
        const totalWithoutConvenience = convenienceFeeInfo?.type === "%"
            ? value / (1 + convenienceFeeInfo?.amount / 100)
            : value - convenienceFeeInfo?.amount;
        return totalWithoutConvenience;
    }, [convenienceFeeInfo]);

    const totalPaymentForCV = useMemo(() => editedTotalPayment || TotalForPay, [editedTotalPayment, TotalForPay]);
    const convenienceFeeAmount = useMemo(() => calculateConvenienceFeeAmount(totalPaymentForCV), [calculateConvenienceFeeAmount, totalPaymentForCV]);
    const calculationPayment = useCallback(() => {
        let totalPayment = calculationTotalPaymentWithCV();
        formik.setFieldValue('totalPayment', +totalPayment.toFixed(2))
        return totalPayment
    }, [calculationTotalPaymentWithCV, formik]);


    const calculationTotalPaymentWithCV = useCallback(() => {
        let totalPayment = TotalForPay && TotalForPay > 0 ? TotalForPay : 0;
        totalPayment += coverConvenienceFee ? convenienceFeeAmount : 0;
        return totalPayment
    }, [TotalForPay, coverConvenienceFee, convenienceFeeAmount]);



    useEffect(() => {
        setEditedTotalPayment(0);
        calculationPayment();
    }, [checked]);

    useEffect(() => {
        if (editedTotalPayment === 0) {
            calculationPayment();
        }
    }, [editedTotalPayment]);


    const checkIsStripe = () => {
        return provider === "Stripe" && publicKey;
    };


    return (
        <>
            {formik.values?.resultPage !== '' ?
                <PaymentResultPage result={formik.values?.resultPage} formik={formik}
                    setTotalForPay={setTotalForPay} setChecked={setChecked} setChargeSelections={setChargeSelections}
                />
                :
                <>
                    <Header Logo={Logo} />
                    <Wrapper>
                        <Grid container spacing={2} sx={{ width: isMobile ? '348px' : '100%' }} >
                            <Grid item xs={12} sm={12} md={12} lg={7} direction="column" >
                                <Paper
                                    sx={{
                                        height: isMobile ? '165px' : '227px',
                                        borderRadius: '10px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#2555EF',
                                    }}
                                >
                                    <ImageContainer isMobile={isMobile}>
                                        <CardContent isMobile={isMobile}>
                                            <h1>{dataForPay?.accountInfo?.family_name}</h1>
                                            <p>Please see open charges below</p>
                                            <span>ID #{dataForPay?.accountInfo?.account_id}</span>
                                        </CardContent>
                                        <div>
                                            <ImageCard isMobile={isMobile} />
                                        </div>
                                    </ImageContainer>
                                </Paper>
                                <Paper
                                    sx={{
                                        height: isMobile ? dataForPay?.charges?.length === 0 ? '200px' : '649px' : '649px',
                                        marginTop: '33px',
                                        borderRadius: '10px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <ChargeTable loading={isLoading} list={dataForPay}
                                        handleCheck={handleCheck} checkedList={checked}
                                        handleCheckAll={handleCheckAll}
                                        TotalForPay={TotalForPay}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={5} direction="column" >
                                <Paper
                                    sx={{
                                        minHeight: '201px',
                                        maxHeight: '500px',
                                        borderRadius: '10px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <SummaryCard chargeSelections={chargeSelections} TotalForPay={TotalForPay}
                                        convenienceFeeInfo={convenienceFeeInfo}
                                        coverConvenienceFee={coverConvenienceFee}
                                        formik={formik}
                                        setEditedTotalPayment={setEditedTotalPayment}
                                        editedTotalPayment={editedTotalPayment}
                                        calculateConvenienceFeeAmount={calculateConvenienceFeeAmount}
                                        reverseCalculationConvenienceFee={reverseCalculationConvenienceFee}
                                        calculationTotalPaymentWithCV={calculationTotalPaymentWithCV}
                                        allowEditTotalPayment={allowEditTotalPayment}
                                    />
                                </Paper>
                                <Paper
                                    sx={{
                                        minHeight: '278px',
                                        marginTop: '33px',
                                        borderRadius: '10px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <PersonalDetailsCard formik={formik}
                                        checkIsStripe={checkIsStripe}
                                    />
                                </Paper>
                                <Paper
                                    sx={{
                                        minHeight: '278px',
                                        marginTop: '33px',
                                        borderRadius: '10px',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                    }}
                                >
                                    <PaymentCard
                                        TotalForPay={TotalForPay} formik={formik}
                                        setError={setError} error={error} PayNow={PayNow}
                                        isLoadingPay={isLoadingPay}
                                        calculationPayment={calculationPayment}
                                        setLoadingPay={setLoadingPay}
                                        checkIsStripe={checkIsStripe}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <ContactUs />
                    </Wrapper >
                </>
            }

        </>
    );
};
