import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "@material-ui/core";
import Action from "components/ActionPopOver";
import AlertDialog from "components/Dialog";
import Spinner from "components/Spinner";
import { useFormik } from "formik";
import { formatDate } from "helper/dateFormat";
import AuthService from "Lib/API/Auth";
import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import AddittionalInformation from "../NewYahrzeit/AddittionalInformation";
import DeceacedDetails from "../NewYahrzeit/DeceacedDetails";
import "./index.scss";
import DrawerMournerList from "./MournersList";

const YahrzietDrawer = ({
  open,
  setOpen,
  deceasedId,
  setTouched,
  setOpenDrawer,
  refresh,
  setRefresh,
  isEdit,
  ...props
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [selected, setSelected] = useState("details");
  const [mourners, setMourners] = useState([]);
  const [yahrziet, setYahrziet] = useState({});
  const [loading, setLoading] = useState(false);
  const [toastCheck, setToastCheck] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const [defaultCheck, setDefaultCheck] = useState(1);

  React.useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true);
      const token = await getAccessTokenSilently();
      AuthService.getDeceasedById(deceasedId, token)
        .then((res) => {
          if (res) {
            setYahrziet(res[0]);
            setMemberId(deceasedId);
            if (res[0]?.mourners_list?.length === 0) {
              setToastCheck(true);
              setDefaultCheck(2);
            }

            setMourners(
              res[0]?.mourners_list?.map((mourner) => {
                const data = {
                  relation: mourner?.relation,
                  first_adar_observance: mourner?.first_adar_observance,
                  second_adar_observance: mourner?.second_adar_observance,
                  observance: mourner?.observance,
                };

                if (mourner?.member_id?._id)
                  return {
                    member_id: mourner?.member_id?._id,
                    ...mourner?.member_id,
                    ...data,
                  };
                else
                  return {
                    ...data,
                    email: mourner?.email,
                    address: mourner?.address,
                  };
              })
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toastr.error("Error", err?.data?.message);
        });
    }
    if (deceasedId) fetchMyAPI();
  }, [deceasedId]);
  const initialValues = {
    functions: { setMourners: setMourners },
    firstName: yahrziet?.firstName ? yahrziet?.firstName : "",
    lastName: yahrziet?.lastName ? yahrziet?.lastName : "",
    hebrew_name: yahrziet?.hebrew_name_of_Death
      ? yahrziet?.hebrew_name_of_Death
      : "",
    doesnt_have_mourner: yahrziet?.doesnt_have_mourner === "Yes" ? true : false,
    date_of_death: yahrziet?.date_of_death ? yahrziet?.date_of_death : "",
    hebrew_date: yahrziet?.hebrew_date_of_death
      ? yahrziet?.hebrew_date_of_death
      : "",
    before_sunset: yahrziet?.before_sunset ? yahrziet?.before_sunset : false,
    has_plaque: yahrziet?.has_plaque === "Yes" ? true : false,

    mourners_list: [],
    plaques_number: yahrziet?.plaque_number ? yahrziet?.plaque_number : "",
    cemetery: yahrziet?.cemetery ? yahrziet?.cemetery : "",
    about_deceased: yahrziet?.about_the_deceased
      ? yahrziet?.about_the_deceased
      : "",
  };

  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      setRefresh(false);
      const token = await getAccessTokenSilently();
      const input = {
        deceasedID: deceasedId,
        firstName: values?.firstName,
        lastName: values?.lastName,
        date_of_death: values?.date_of_death,
        hebrew_date_of_death: values?.hebrew_date,
        before_sunset: values?.before_sunset,
        has_plaque: values?.has_plaque === true ? "Yes" : "No",
        doesnt_have_mourner:
          values?.doesnt_have_mourner === true ? "Yes" : "No",
      };
      if (values?.plaques_number) input.plaque_number = values?.plaques_number;
      if (values?.cemetery) input.cemetery = values?.cemetery;
      if (values?.hebrew_name) input.hebrew_name_of_Death = values?.hebrew_name;
      if (values?.about_deceased)
        input.about_the_deceased = values?.about_deceased;

      input.mourners_list = mourners?.map((mourner) => {
        const data = {
          relation: mourner?.relation,
          observance: mourner?.observance,
          first_adar_observance: mourner?.first_adar_observance,
          second_adar_observance: mourner?.second_adar_observance,
        };
        if (mourner.member_id) {
          data.firstName = mourner?.firstName;
          data.lastName = mourner?.lastName;
          data.member_id = mourner?._id
            ? mourner?._id
            : typeof mourner.member_id === "string"
            ? mourner.member_id
            : mourner?._id;
        } else {
          data.email = mourner?.email;
          data.address = mourner?.address;
        }
        return data;
      });

      let toast = "Yahrzeit has been edited successfully";

      if (toastCheck) {
        toast = " Yahrzeit has been added successfully";
      }

      await AuthService.updateDeceased(input, token)

        .then((res) => {
          setRefresh(true);
          toastr.success("Success", toast);
          setOpenDrawer(false);
        })
        .catch((err) => {
          toastr.error("Error", err?.data?.message);
        });
    },
  });

  const handleDeleteMourner = (index) => {
    var array = [...mourners];
    if (index !== -1) {
      array.splice(index, 1);
      setMourners(array);
    }
    formik.setFieldTouched("mourners_list", true);
  };

  React.useEffect(() => {
    setTouched(formik.touched);
  }, [formik.touched]);

  return (
    <div className="account-drawer">
      {loading &&
      Object.keys(typeof yahrziet === "object" && yahrziet).length === 0 ? (
        <Spinner />
      ) : (
        <>
          <div className="account-drawer-header p-5">
            <div className="account-header" style={{ justifyContent: "right" }}>
              <div className="cursor-pointer">
                <Action
                  onDeleteClick={() => {
                    props.setOpenAlert(true);
                  }}
                  checkActive={true}
                  style={{
                    color: "#3f4255",
                    width: "16px",
                    height: "16px",
                  }}
                />
              </div>
            </div>
            <div className="yahrziet-drawer-header">
              <Avatar
                className="avatar"
                style={{ marginRight: "17px", width: "80px", height: "80px" }}
                alt={`${yahrziet?.firstName}`}
                // src={member.profile_img}
              >
                <div className="name">
                  {`${yahrziet?.firstName?.[0]}`.toUpperCase()}
                </div>
              </Avatar>
              <div className="name-container">
                <p className="dceaced-name">{`${yahrziet?.firstName} ${yahrziet?.lastName}`}</p>
                <p className="dceaced-date">{`${formatDate({date:yahrziet?.date_of_death, formatType: "defaultWithUTC"})} - ${yahrziet?.hebrew_date_of_death}`}</p>
              </div>
            </div>
          </div>
          <div className="account-drawer-content">
            <div className="account-drawer-tabs">
              <div className="toggle-bar">
                <p
                  className={selected === "details" ? "selected" : "unselected"}
                  onClick={() => setSelected("details")}
                >
                  <span className="title">Details</span>
                </p>
                <p
                  className={
                    selected === "mourners" ? "selected" : "unselected"
                  }
                  onClick={() => setSelected("mourners")}
                >
                  <span className="title">Mourners list</span>
                </p>
                <p
                  className={selected === "notes" ? "selected" : "unselected"}
                  onClick={() => setSelected("notes")}
                >
                  <span className="title notes_text">Notes</span>
                </p>
              </div>
            </div>
            <div className="tab-content">
              {selected === "details" ? (
                <DeceacedDetails
                  drawerDisplay={true}
                  setOpenDrawer={setOpenDrawer}
                  setFieldValue={formik.setFieldValue}
                  yahrziet={yahrziet}
                  onSubmit={() => {
                    setOpen(false);
                    formik.handleSubmit();
                    setOpenDrawer(false);
                  }}
                  {...formik}
                />
              ) : selected === "mourners" ? (
                <DrawerMournerList
                  {...formik}
                  drawerDisplay={true}
                  handleDelete={handleDeleteMourner}
                  mourners={mourners}
                  isEdit={isEdit}
                  onSubmit={() => {
                    formik.handleSubmit();
                  }}
                />
              ) : (
                <AddittionalInformation
                  drawerDisplay={true}
                  onSubmit={() => {
                    formik.handleSubmit();
                  }}
                  {...formik}
                />
              )}
            </div>
          </div>
        </>
      )}
      <AlertDialog
        open={open}
        handleClose={() => setOpen(false)}
        onAccept={() => {
          setOpenDrawer(false);
          setOpen(false);
        }}
        onDecline={() => {
          setOpenDrawer(open);
          setOpen(false);
        }}
        content={
          <div className="alert-message-popup">
            <p className="alert-icon">{/* <ReportProblemRoundedIcon /> */}</p>
            <div>
              <p>Discard Changes</p>
              <p className="alert-message">
                Are you sure you want to discard the changes?
              </p>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default YahrzietDrawer;
