import "./index.scss";

import { InputField, LabelField, MyCheckbox } from "components";
import React, { useCallback, useEffect, useState } from "react";

import { ReactComponent as AddIcon } from "assets/icons/add-plus.svg";
// import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { useAuth0 } from "@auth0/auth0-react";
import DeleteSvgComponent from "assets/icons/svg/DeleteSvg/Delete";
import AlertDialog from "components/Dialog";
import MyButton from "components/MyButton";
import StripeCard from "components/StripeCard/StripeCard";
import { getCurrency, numberFormat } from "helper/numberFormat";
import { postalCodeFormat, postalCodeLabel } from "helper/postalCodeFormat";
import useGetUserAccount from "hook/useGetUserAccount";
import { toastr } from "react-redux-toastr";
import FinanceService from "../../../../Lib/API/Finance";

export const CreditCard = ({
  formik,
  title,
  onPaymentValueChange,
  selected,
  setSelected,
}) => {
  const [addNewCard, setAddNewCard] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [cards, setCards] = useState([]);
  const [cardDetail, setCardDetail] = useState({});
  const [addCardLoading, setAddCardLoading] = useState(false);
  const [creditId, setCreditId] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);

  const {provider_information} = localStorage.getItem("organization")
  ? JSON.parse(localStorage.getItem("organization"))
  : {};    

  let accountID = localStorage.getItem("accountID")
  ? localStorage.getItem("accountID")
  : "";

  const {provider} = provider_information || {};
  const {publicKey} = provider_information?.keys || {};

  const postalLabel = postalCodeLabel();


  const customStyles = {
    width: 73,
    marginInline: "auto",
  };

  const accountData = useGetUserAccount({member: true});

  const checkIsStripe = () => {
      return provider === "Stripe" && publicKey;
  };


  const getCreditCards = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const res = await FinanceService.getCreditCardsByAccountId(
      token,
      accountID ?? ""
    );
    setCards(res.card.data);
    formik.setFieldValue("cardDetail", res.card.data);
  }, [getAccessTokenSilently, accountID]);

  const onCardDetailChange = (event, name) => {
    setCardDetail({
      ...cardDetail,
      [name]: event.target.value,
    });
    formik.setFieldValue(`cardDetails.${name}`, event.target.value);
  };

  useEffect(() => {
    if (accountData) {
      accountID = accountData?.user?.permissions?.linked_account?._id;
      getCreditCards();
    } else {
      getCreditCards();
    }
  }, [getCreditCards,accountData]);

  useEffect(() => {
    if (selected) {
      onPaymentValueChange(title, {
        target: {
          value: title,
          name: "paymentMethod",
        },
      });
      onPaymentValueChange(title, {
        target: {
          value: selected.id,
          name: "cardId",
        },
      });

      formik.setFieldValue("last4", selected.last4);
    }
    //eslint-disable-next-line
  }, [selected]);

  const onSaveCard = async (tokenId = "") => {
    const cardsDetails = {
      object: "card",
      ...cardDetail,
    };

    if ((!cardDetail?.cvc || cardDetail?.cvc === "") && !tokenId) {
      return toastr.error("Error", "Please add CVC.")
    }
    if (cardDetail?.cvc?.length < 3 && !tokenId) {
      return toastr.error("Error", "Please enter valid CVC.")
    }

    if(!tokenId && (!cardDetail?.zip || !cardDetail?.zip?.length)) {
      return toastr.error("Error", `Please enter ${postalCodeLabel()}.`)
    }

    const validZipCode = postalCodeFormat(cardDetail?.zip);
    if (!tokenId && !validZipCode) {
      toastr.info("Validation", `Invalid ${postalLabel}`)
    }

    const bodyData = {};
    if(checkIsStripe() && tokenId){
      bodyData.token = tokenId;
    } else {
      bodyData.cardsDetails = cardsDetails;
    }
    const token = await getAccessTokenSilently();

    FinanceService.addCreditCard(
      token,
      accountID ?? "",
      bodyData
    )
      .then(() => {
        getCreditCards();
        formik.setFieldValue(`cardDetails.exp_month`, "");
        formik.setFieldValue("cardDetails.exp_year", "");
        formik.setFieldValue("cardDetails.cvc", "");
        formik.setFieldValue("cardDetails.number", "");
        formik.setFieldValue("cardDetails.zip", "");
        setCardDetail({});
        toastr.success("Success", "Card Added Successfully");
      })
      .catch((err) => {
        toastr.error("Error", err?.data?.message || "Something went wrong");
      })
      .finally(() => {
        setAddCardLoading(false);
      });
  };

  const onDelete = async (idx) => {
    const token = await getAccessTokenSilently();
    FinanceService.deleteCreditCard(
      token,
      idx,
      accountID ?? ""
    ).then((res) => {
      if (res?.card?.messages?.resultCode == "Ok") {
        toastr.success("Success", "Card has been delete successfully ");
      }
      getCreditCards();
      setSelected(null);
    }).catch((err) =>{
      toastr.error('Error', err?.data?.message);
    }).finally(() => {
      closeConfirmDialog();
    });
  };

  const onSelect = (data, e) => {
    formik.setFieldValue("cvc", "");

    setSelected({ ...data });
  };
  useEffect(() => {
    const already = formik?.values?.payments?.find(
      (item) => item.paymentMethod === title
    );
    if (already) {
      const current = cards?.find((item) => item.id === already.cardId);
      setSelected(current);
    }
  }, [cards]);


  const openConfirmDialog = (id) => {
    setCreditId(id);
    setOpenAlert(true);
  };


  const closeConfirmDialog = () => {
    setOpenAlert(false);
    setCreditId(null);
  };


  return (
    <div className="credit-card mt-5">
      <div className="form-row col-sm-12 px-0">
        <div className="d-flex col-sm-7 ">
          <div className="cards-radio font-16">
            {cards?.map((item) => (
              <div
                data-id={item.id}
                key={item.id}
                onClick={() => onSelect(item)}
                class={selected?.id === item.id ? "selected-card" : ""}
              >
                <p>
                  {`${item.country ? item.country : ""} ${item.brand ? item.brand : ""
                    } (${item.last4 ? item.last4 : ""}), Exp: ${item.exp_month}/${item.exp_year
                    }`}
        
                    <DeleteSvgComponent
                      color={"gray"}
                      onClick={() => {
                        openConfirmDialog(item.id);
                      }}
                    />
              
                    {((item.hasOwnProperty("address_zip") && !item?.address_zip) || ((item.hasOwnProperty("zip") && item?.zip === "zip is missing"))) && (
                      <span className="text-danger missing-zip">{postalLabel} is missing</span>
                    )}
                </p>
              </div>
            ))}
          </div>
          {/* <MyRadioGroup
            handleChange={(val) => {
              setSelected(val);
            }}
            name="cards"
            className="cards-radio"
            formsControls={[]}
          /> */}
        </div>
        <div className="right-side px-0">
          <div className="d-flex default-card">
            <LabelField title="This is your default card" />
            {/* <span className="cursor-pointer ml-10">
              <DeleteSvgComponent color={"gray"} />
            </span> */}
          </div>
          <div className="d-flex">
            <MyCheckbox name={"default"} title="" handlechange={() => { }} />
            <LabelField title="Set as default credit card" />
            {/* <span className="cursor-pointer ml-10">
              <DeleteSvgComponent color={"gray"} />
            </span> */}
          </div>
        </div>
        <div
          className="add-new-payment-info my-4"
          onClick={() => setAddNewCard(!addNewCard)}
        >
          <span className="add-icon">
            <AddIcon />
          </span>
          <span className="add">Add New Card</span>
        </div>
      </div>
      {addNewCard && (
        <>
          {checkIsStripe() ? (
              <StripeCard onSaveCard={onSaveCard}
                loading={addCardLoading}
                setLoading={setAddCardLoading}
                saveCard={true}
              />
          ) : (
            <div className="row">
              <div className="col-sm-4 pr-0">
                <InputField
                  mandatory
                  {...{
                    formik,
                    title: "Card Number",
                    name: "cardDetails.number",
                    type: "string",
                    minlength: "16",
                    maxLength: "16",
                    // placeholderIcon: (
                    //   <CreditCardOutlinedIcon
                    //     className="card-icon"
                    //     fontSize="large"
                    //   />
                    // ),
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  handleChange={(event) => onCardDetailChange(event, "number")}
                />
              </div>
              <div className="col-sm-2">
                <InputField
                  mandatory
                  {...{
                    formik,
                    title: "Expiry Month",
                    name: `cardDetails.exp_month`,
                    placeholder: "MM",
                    minlength: "1",
                    maxLength: "2",
                    type: "string",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  handleChange={(event) => onCardDetailChange(event, "exp_month")}
                />
              </div>
              <div className="col-sm-2">
                <InputField
                  mandatory
                  {...{
                    formik,
                    title: "Expiry Year",
                    type: "string",
                    minlength: "4",
                    maxLength: "4",
                    name: `cardDetails.exp_year`,
                    placeholder: "YYYY",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  handleChange={(event) => onCardDetailChange(event, "exp_year")}
                />
              </div>
              <div className="col-sm-2 px-0">
                <InputField
                  mandatory
                  {...{
                    formik,
                    title: "CVC",
                    type: "string",
                    minlength: "3",
                    maxLength: "4",
                    name: `cardDetails.cvc`,
                    placeholder: "3-4 digits",
                    // value: formik.values?.cardDetails.cvc,
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  handleChange={(event) => onCardDetailChange(event, "cvc")}
                />
              </div>
              <div className="col-sm-2">
                <InputField
                  mandatory
                  {...{
                    formik,
                    title: postalLabel,
                    name: `cardDetails.zip`,
                    placeholder: postalLabel,
                    type: "string",
                  }}
                  handleChange={(event) => onCardDetailChange(event, "zip")}
                />
              </div>
              <div className="col-sm-4 mb-5 pb-5">
                <MyButton
                  content={"Save Card"}
                  style={{ width: 120 }}
                  onClick={() => {
                    onSaveCard();
                  }}
                />
              </div>
            </div>
          )}
        </>

      )}
       <AlertDialog
          open={openAlert}
          handleClose={() => {
            closeConfirmDialog();
          }}
          onDecline={() => {
            setOpenAlert(false);
          }}
          onAccept={() => {
            onDelete(creditId);
          }}
          content={"Are you sure you want to delete this credit card?"}
          contentStyle={{
            fontSize: "18px",
          }}
      />
    </div>
  );
};
export const CreditCardHeader = ({ selected, formik, ...props }) => {
  const { expanded, current } = props;
  const [disableInput, setDisableInput] = useState(true);
  const currency = getCurrency();

  const preventMinus = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  const handlefieldClick = (e) => {
    if (e.stopPropagation && expanded === true) {
      e.stopPropagation();
    }
    if (!selected?.id && e.stopPropagation && expanded === true) {
      e.stopPropagation();
    }
  };

  const getCommaSeperatedValue = (num) => {
    return parseFloat(num?.toString().replace(/,/g, ""))
      .toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div
      className={
        expanded === false && !formik?.values?.creditCardAmount
          ? "accordion-payment-header"
          : expanded === false &&
            current?.includes("credit card") === false &&
            formik?.values?.creditCardAmount
            ? "accordion-header"
            : expanded === false
              ? "accordion-header"
              : expanded === false
                ? "accordion-payment-header accordion-header "
                : expanded === true
                  ? "accordion-header"
                  : "accordion-header-payment"
      }
    >
      <LabelField title="Credit Card" />

      <div
        className={
          expanded === false && formik?.values?.creditCardAmount
            ? "custom-expand dollor-icon col-sm-3 nonexpanded-amount"
            : "col-sm-3"
        }
      >
        {expanded === false && formik?.values?.creditCardAmount ? (
          <div className="cash-non-expanded">
            <span>{currency}</span>
            <span>
              {getCommaSeperatedValue(formik?.values?.creditCardAmount)}
            </span>
          </div>
        ) : current?.includes("credit card") && expanded === true ? (
          <InputField
            {...{
              formik,
              title: "none",
              name: `amount`,
              style: { background: "#ffff", border: "none" },
              placeholderIcon: (
                <span
                  className="align-self-center px-4"
                  style={{ color: "black" }}
                >
                  {currency}
                </span>
              ),
              value: formik?.values?.creditCardAmount,
            }}
            // disabled={
            //   selected?.id && formik?.values?.cvc
            //     ? setDisableInput === false
            //     : true
            // }
            // onKeyDown={preventMinus}
            handleChange={props.onCreditCardAmountChange}
            paymentInput="paymentInput"
            handleFocus={(e) => handlefieldClick(e)}
          // disabled
          />
        ) : expanded === false &&
          current?.includes("credit card") === false &&
          formik?.values?.creditCardAmount ? (
          <div className="cash-non-expanded">
            <span>
              {numberFormat({number:formik?.values?.creditCardAmount, decimal:2, style: "currency"})}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
