import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@material-ui/core/Grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PaymentIcon from "@material-ui/icons/Payment";
import DefaultUserOPNG from "assets/icons/default-user.png";
import { ReactComponent as DirectoryIcon } from "assets/icons/DirectoryIcon.svg";
import { ReactComponent as DisabledDirectoryIcon } from "assets/icons/DisableDirectoryIcon.svg";
import { SubHeader } from "layout/SubHeader";
import AuthService from "Lib/API/Auth";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { DirectorySetting } from "./DirectorySetting";
import PersonalInfoEdit from "./editInfo";
import PaymentMethods from "./paymentMethod";

import "./index.scss";
const SettingsPage = () => {
  const [selected, setSelected] = useState(0);
  const { user } = useAuth0();
  const  memberPortalDirectoryPermission=  JSON.parse(localStorage.getItem("organization"))?.memberPortalDirectory

  const [userInfo, setUserInfo] = useState();
  const { getAccessTokenSilently } = useAuth0();
  React.useEffect(() => {
    async function fetchMyAPI() {
      const token = await getAccessTokenSilently();
      AuthService.getUserExist(token)
        .then((res) => {
          localStorage.setItem(
            "organization",
            JSON.stringify(res?.user?.permissions?.organization)
          );
          console.log('res?.user', res?.user)
          localStorage.setItem(
            "linkedAccount",
            JSON.stringify(res?.user?.permissions?.linked_account)
          );
          console.log(res, "res");
          setUserInfo(res);
        })
        .catch((err) => {
          setUserInfo(err);
          toastr.error('Error', err?.data?.message);
        });
    }
    fetchMyAPI();
  }, [getAccessTokenSilently]);

  return (
    <>
      <div className="m-0 w-100 p-5">
        <div className="m-0 w-100 ">
          <div className="col-12 m-0 borderBottom p-5">
            <SubHeader
              className="class-tab subheader"
              {...{
                title: "Settings",
              }}
            />
          </div>
          <div className="settings-container">
          <Grid container spacing={9} className="wrapper">
          <Grid item xs={12} sm={6} className="profile-data">
              <Card className="card card-custom p-4 mt-4 ">
                <div className="profile-container">
                  <div className="user-info">
                    <div>
                      <img
                        alt="profiel img"
                        src={userInfo?.user?.permissions?.linked_contact?.profile_img ?? DefaultUserOPNG}
                        style={{ objectFit: "fill" }}
                        height="102"
                        width="99"
                        className="with-border rounded"
                        name="profile_img"
                      />
                    </div>
                    <div className="user">
                      <div className="user-name">{user?.nickname}</div>
                      <div className="user-email">{user?.email}</div>
                    </div>
                  </div>
                  <div>
                    <div className="user-data pt-10">
                      <div className="info-head">Email:</div>
                      <div className="info-data ml-5">
                        <div>
                          {
                            user?.email
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="personal-information">
                    <div>
                      <div
                        className={
                          selected === 0 ? "active container" : "container"
                        }
                        onClick={() => {
                          setSelected(0);
                        }}
                      >
                        <AccountCircleIcon fontSize="large" className="mx-10" />
                        Personal Information
                      </div>
                    </div>
                    <div>
                      <div
                        className={
                          selected === 1 ? "active container" : "container"
                        }
                        onClick={() => {
                          setSelected(1);
                        }}
                      >
                        <PaymentIcon fontSize="large" className="mx-10" />
                        Payment Methods
                      </div>
                    </div>
                    {memberPortalDirectoryPermission && <div>
                      <div
                        className={
                          selected === 2 ? "active container" : "container"
                        }
                        onClick={() => {
                          setSelected(2);
                        }}
                      >
                        {selected === 2 ? 
                        <DirectoryIcon fontSize="large" className="mx-10" />:
                        <DisabledDirectoryIcon fontSize="large" className="mx-10" />
                        }
                        Directory
                      </div>
                    </div>}
                    
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} className="profile-information">
              <Card className="card card-custom p-4 mt-4 ">
                {selected === 0 ? (
                  <PersonalInfoEdit
                    userInfo={userInfo}
                    userId={userInfo?.user?._id}
                  />
                ) : selected === 1 ? (
                  <PaymentMethods />
                ):<DirectorySetting />}
              </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
export default SettingsPage;
