
import { Grid } from '@material-ui/core';
import Paper from "@mui/material/Paper";
import { InputField } from 'components/InputField/InputField';
import { postalCodeLabel } from 'helper/postalCodeFormat';
import React, { useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { ReserveLoginButton } from '../../FormInfo/Theme';
import { GridItem, PersonalDetailsTitle, PersonalDetailsWrapper } from './Theme';
export const PersonalDetailsCard = ({ formik,setError,handleTotalForPay,totalProducts,isTherePayment,showZipCode,isAuthenticated,handleLogout,logout}) => {

    const memberPortal = localStorage.getItem('memberPortal') === 'true' || false;


    const postalLabel = postalCodeLabel();
    const params = useParams();

    const isFirstNameExist = !formik?.values?.accountInfo?.first_name || formik?.values?.accountInfo?.first_name === ""
    const isLasttNameExist = !formik?.values?.accountInfo?.last_name || formik?.values?.accountInfo?.last_name === ""
    const isEmailExist = !formik?.values?.accountInfo?.primary_email_address || formik?.values?.accountInfo?.primary_email_address === ""
    const isZipExist = !formik?.values?.cardsDetails?.zip || formik?.values?.cardsDetails?.zip === ""

    const handleInputFields = (e, name) => {
        let value = e.target.value;
        value = value.replace(/[^A-Za-z]/gi, "");
        formik.setFieldValue(name, value);
    }
    useEffect(() => {
        if(handleTotalForPay()?.total !== '0.00' ||  totalProducts > 0 ){
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        const emailValidation = reg.test(formik?.values?.accountInfo?.primary_email_address)
        if (!formik?.values?.accountInfo?.first_name ||
            !formik?.values?.accountInfo?.last_name ||
            !formik?.values?.accountInfo?.primary_email_address) {
            setError({ value: true, message: "Please fill all mandatory fields" });
            return;
        }
        else {
            setError({ value: false, message: "" })
            return;
        }
    }
    }, [formik.values])


    const handleReserveLogin = () => {
        if(isAuthenticated)  {
            handleLogout();
            return;
        }
        localStorage.setItem("redirectUrl", `/form/${params?.id}/reservation`);
        const originUrl = window.location.origin;
        logout({ returnTo: originUrl });
    }

    const formLogin = useMemo(() => isAuthenticated ? "Logout" : "Login" , [isAuthenticated]);


    return (
        <>
        {(handleTotalForPay()?.total !== '0.00' ||  totalProducts > 0 ) && <Paper
        sx={{
          minHeight: "278px",
          marginTop: "33px",
          borderRadius: "10px",
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
        >
        <PersonalDetailsWrapper>
            <PersonalDetailsTitle>
                <span>Billing Details </span>
                {memberPortal && (
                    <ReserveLoginButton onClick={handleReserveLogin}>{formLogin}</ReserveLoginButton>    
                )}
            </PersonalDetailsTitle>
            
            <Grid container spacing={3} >
                <Grid item xs={6}>
                    <GridItem>

                        <InputField
                            isRequired={true}
                            labelStyle={{ color: isFirstNameExist && 'var(--Gray-100, #B5B5C3)', fontSize: '14px', fontWeight: 500 }}
                            placeholder='First Name' label='First Name'
                            styleWrapper={{ width: '100%' }}
                            style={{ width: '100%' }}
                            isOptional={false}
                            value={formik?.values?.accountInfo?.first_name}
                            name="first_name"
                            handleChange={(e) => {
                                handleInputFields(e, 'accountInfo.first_name')
                            }}
                            readOnly={isAuthenticated}
                        />

                    </GridItem>
                </Grid>
                <Grid item xs={6}>
                    <InputField
                        isRequired={true}
                        labelStyle={{ color: isLasttNameExist && 'var(--Gray-100, #B5B5C3)', fontSize: '14px', fontWeight: 500 }}
                        placeholder='Last Name' label='Last Name' styleWrapper={{ width: '100%' }}
                        style={{ width: '100%' }}
                        isOptional={false}
                        value={formik?.values?.accountInfo?.last_name}
                        name="last_name"
                        handleChange={(e) => {
                            handleInputFields(e, 'accountInfo.last_name')
                        }}
                        readOnly={isAuthenticated}
                    />
                </Grid>
                <Grid item xs={(isTherePayment && showZipCode) ? 6 : 12}>
                    <GridItem>
                        <InputField
                            isRequired={true}
                            labelStyle={{ color: isEmailExist && 'var(--Gray-100, #B5B5C3)', fontSize: '14px', fontWeight: 500 }}
                            placeholder='Email' label='Email'
                            styleWrapper={{ width: '100%' }}
                            style={{ width: '100%' }}
                            isOptional={false}
                            handleChange={(e) => {
                                formik.setFieldValue("accountInfo.primary_email_address", e?.target?.value);
                            }}
                            value={formik?.values?.accountInfo?.primary_email_address}
                            readOnly={isAuthenticated}
                        />
                    </GridItem>
                </Grid>
                {(isTherePayment && showZipCode) && (
                    <Grid item xs={6}>
                        <GridItem>
                            <InputField
                                isRequired={true}
                                labelStyle={{ color: isZipExist && 'var(--Gray-100, #B5B5C3)', fontSize: '14px', fontWeight: 500 }}
                                placeholder={postalLabel} 
                                label={postalLabel}
                                styleWrapper={{ width: '100%' }}
                                style={{ width: '100%' }}
                                type="string"
                                isOptional={false}
                                handleChange={(e) => {
                                    formik.setFieldValue("cardsDetails.zip", e?.target?.value);
                                }}
                                value={formik?.values?.cardsDetails?.zip}
                            />
                        </GridItem>
                    </Grid>
                )}
            </Grid>
        </PersonalDetailsWrapper>
        </Paper>}
        </>
        
    );
};
