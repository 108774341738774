import * as React from "react"

function ComingSoonDonation(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={135.411}
      height={132.059}
      {...props}
    >
      <g data-name="Group 2458">
        <path
          data-name="Path 4318"
          d="M78.781 12.465A28.82 28.82 0 0193.645 1.389c10.725-3.5 23.2-.225 29.7 7.889 3.565 4.445 4.932 9.732 5.585 15.268 1.251 10.615-3.459 18.812-10.857 25.674-12.14 11.262-24.491 22.3-36.757 33.425-1.859 1.687-3.318 1.667-5.173-.017-12.185-11.07-24.415-22.091-36.539-33.227-5.418-4.977-9.7-10.818-10.846-18.276-1.208-7.832.161-15.354 4.735-21.912C37.58 4.353 43.64 1.261 50.631.348 62.007-1.138 71.29 2.727 78.275 11.896c.121.152.257.287.506.569z"
          fill="#b5b5c3"
        />
        <path
          data-name="Path 4319"
          d="M.009 108.595V92.537c0-3.367.892-4.271 4.258-4.723q9.646-1.3 19.271-2.745a12.681 12.681 0 002.907-1.014c2.943-1.234 5.866-2.518 8.806-3.759a20.277 20.277 0 0117.138.291c7.116 3.329 14.22 6.689 21.23 10.23a11.545 11.545 0 014.073 3.68 5.885 5.885 0 01-3.83 8.952 9.9 9.9 0 01-5.646-.446q-11.193-4.125-22.4-8.215c-2.237-.818-3.94-.2-4.618 1.67-.669 1.842.271 3.459 2.506 4.248 8.194 2.892 16.364 5.853 24.6 8.63a14.676 14.676 0 0016.093-4.7 3.972 3.972 0 011.712-1.247c11.073-3.88 22.166-7.7 33.243-11.571a12 12 0 019.052.248c7.742 3.363 9.4 12.947 3.316 19.255a16.556 16.556 0 01-6.517 4.174q-19.241 6.769-38.477 13.553a64.242 64.242 0 01-8.532 2.659c-4.677.932-9.176-.075-13.456-2.114-6.383-3.04-12.785-6.041-19.156-9.108a5.522 5.522 0 00-3.842-.426q-18.514 4.293-37.04 8.53c-3.071.705-4.659-.5-4.68-3.645-.036-5.448-.011-10.899-.011-16.349z"
          fill="#e7e7eb"
        />
      </g>
    </svg>
  )
}

export default ComingSoonDonation
