import * as React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export const AlertMessage = ({ type, content, head }) => {
    return (
        <div sx={{
            width: '100%', zIndex: "2"
        }} spacing={2} >
            <Alert severity={type}>
                <AlertTitle>{head}</AlertTitle>
                {content}
            </Alert>
        </div >
    );
}