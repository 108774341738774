import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    family_members: [],
};


export const dashboardData = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        familyMembers: (state, action) => {
            state.family_members = action.payload
            // return state
        }
    },
});

export const { familyMembers } = dashboardData.actions

export default dashboardData.reducer;
