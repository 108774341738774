/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios from "axios"
/**
 * Create an Axios Client with defaults
 */

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

client.interceptors.request.use((req) => {
    if (req?.token) {
        req.headers.Authorization = `Bearer ${req?.token}`;
    }
    return req;
})

/**
 * Request Wrapper with default success/error actions
 */
const request = (options) => {
    const onSuccess = (response) => {
        return response.data;
    };

    const onError = (error) => {

        if (error.response) {
            if (error.response.status === 401) {
                // console.log("here the status ", error.response.status);
                // Router.push(redirectTo);
            }
        } else {
            // Something else happened while setting up the request
            // triggered the error
            // console.error("Error Message:", error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default request;
