import styled from "styled-components";


export const Title = styled.h1`
padding: 15px;
font-size: 28px;
`
export  const SearchWrapper = styled.div`
width: 30%;
@media (max-width: 991.98px){
    width: 60%;
}
padding-top:10px;
padding-bottom: 15px;
`