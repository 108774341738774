import React, { useMemo } from "react";
import {
  isValidPhoneNumber,
} from "react-phone-number-input";
import Input from "react-phone-number-input/input";
// import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./index.scss";

export const PhonePicker = ({ value, handleChange, className,placeholder }) => {
  const country = JSON.parse(
    localStorage.getItem("country") !== "undefined"
    ? localStorage.getItem("country")
    : "{}"
  );

  const code = useMemo(() => {
    return country?.code || "US";
  }, [country]);


  return (
    <div className={`phone-picker ${className} `}>
      <Input
        country={code}
        placeholder={placeholder ?? "Phone"}
        value={value}
        onChange={handleChange}
        maxLength="14"
        error={
          value
            ? isValidPhoneNumber(value) === true
              ? undefined
              : "Invalid phone number"
            : "Phone number required"
        }
      />
    </div>
  );
};
