import React from "react";
import { LabelField, InputField } from "components";
export const NewPaymentMethod = () => {
  return null;
};
export const NewPaymentMethodHeader = ({
  formik,
  setPaymentMethodTitle,
  ...props
}) => {
  setPaymentMethodTitle(props.title + "_payment");
  const newPaymentMethod = props.title + "_payment";
  const { expanded, current, title } = props;
  const preventMinus = (e) => {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;
    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  const handlefieldClick = (e) => {
    if (e.stopPropagation && expanded === true) {
      e.stopPropagation();
    }
  };
  const exceptThisSymbols = ["e", "E", "+", "-"];

  const preventArrows = (e) => {
    if (e.which === 38 || e.which === 40 || exceptThisSymbols.includes(e.key))
      e.preventDefault();
  };
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  const getCommaSeperatedValue = (num) => {
    return parseFloat(num?.toString().replace(/,/g, ""))
      .toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div
      className={
        expanded === false && current?.includes("title") === true
          ? "accordion-header"
          : expanded === false
          ? "accordion-payment-header"
          : expanded === true
          ? "accordion-header"
          : "accordion-header-payment"
      }
    >
      <LabelField title={props.title} />
      <div
        className={
          expanded === false && current.includes(title)
            ? "custom-expand dollor-icon col-sm-3 newpayment-nonexpanded-amount"
            : expanded === false &&
              current?.includes(title) === false &&
              formik.values?.[newPaymentMethod]
            ? "custom-expand dollor-icon col-sm-3 nonexpanded"
            : "col-sm-3"
        }
      >
        {expanded === false &&
        current?.includes(title) &&
        formik.values?.[newPaymentMethod] ? (
          <div className="cash-non-expanded">
            <span>$</span>
            <span>
              {getCommaSeperatedValue(formik.values?.[newPaymentMethod])}
            </span>
          </div>
        ) : current?.includes(title) && expanded === true ? (
          <InputField
            {...{
              formik,
              title: "none",
              name: `val`,
              style: { background: "#ffff", border: "none" },
              value: formik.values?.[newPaymentMethod],
              placeholderIcon: (
                <span
                  className="align-self-center px-4"
                  style={{ color: "black" }}
                >
                  $
                </span>
              ),
            }}
            handleChange={(event) => {
              formik.setFieldValue(
                newPaymentMethod,
                event.target.value.match(regex)[0]
              );
            }}
            onKeyDown={(e) => preventArrows(e)}
            paymentInput="paymentInput"
            handleFocus={(e) => handlefieldClick(e)}
            // onKeyDown={preventMinus}
          />
        ) : expanded === false &&
          current?.includes(title) === false &&
          formik.values?.[newPaymentMethod] ? (
          <div className="cash-non-expanded">
            <span>$</span>
            <span>
              {getCommaSeperatedValue(formik.values?.[newPaymentMethod])}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
